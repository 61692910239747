/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

import { ValidateFunction } from "ajv";
// Import the json:api resource interface.
import { Resource } from "@andromeda/json-api";
// Import the json schema compiler.
import { compileSync } from "@andromeda/validation";

// Import the schema.
import * as Schema from "./schema.json";


/** Interface used to describe an asset's metadata. */
export type AssetRequest = Omit<Resource<AssetRequest.Type>, "attributes" | "relationships">;

/** Augment the {@link AssetRequest} interface. */
export namespace AssetRequest {
    /** Type of {@link AssetRequest} resources. */
    export const Type = "asset-conversion_asset-request" as const;
    export type Type = typeof Type;

    export const validate: ValidateFunction<AssetRequest> = compileSync(Schema);
}
