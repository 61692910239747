/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json:api error class.
import { JsonApiError, Error } from "@andromeda/json-api";


/** Error thrown in an asset has a non-supported MIME type. */
export class UnsupportedAssetTypeError extends JsonApiError {
    /** @inheritDoc */
    public override get status(): "400" { return "400"; }

    /** @inheritDoc */
    public constructor(contentType: string, options?: Error) {
        super(`Cannot convert an asset with type ${contentType}`, options);
    }
}
