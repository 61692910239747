/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the search filter type.
import type { SearchFilter } from "../../../hooks";
// Import the tab component.
import Tab from "./tab";

// Import the css.
import css from "./index.module.scss";


/** Component used to render the tabs of the search results. */
export default function SearchTabs(props: SearchTabsProps): ReactElement {
    return <ul className={classNames(css["list"], props.className)}>
        <Tab
            filterType="all"
            currentFilter={props.currentFilter}
            onSelected={props.onTabChanged}
            loading={props.loading}
        />
        <Tab
            filterType="zaq-wiki"
            currentFilter={props.currentFilter}
            onSelected={props.onTabChanged}
            loading={props.loading}
        />
        <Tab
            filterType="zaq-tuto"
            currentFilter={props.currentFilter}
            onSelected={props.onTabChanged}
            loading={props.loading}
        />
        <Tab
            filterType="course"
            currentFilter={props.currentFilter}
            onSelected={props.onTabChanged}
            loading={props.loading}
        />
    </ul>;
}

/** Props passed down to the {@link SearchTabs} component. */
export interface SearchTabsProps {
    /** Currently active filtering type. */
    currentFilter: SearchFilter;
    /** Flag set if the current tab is loading. */
    loading: boolean;
    /** Optional class name added to the root list. */
    className?: string;

    /** Callback invoked when a new filter was requested. */
    onTabChanged(filter: SearchFilter): void;
}
