/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the search filter tools.
import { getNameFromSearchFilter, SearchFilter } from "../../../hooks";

// Import the icons.
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
// Import the css.
import css from "./tab.module.scss";


/** Component used to render a search filter tab. */
export default function Tab(props: TabProps): ReactElement {
    return <button
        role="listitem"
        className={classNames(css["tab"], { [css["tab--loading"]]: props.loading })}
        onClick={() => props.onSelected(props.filterType)}
        disabled={props.filterType === props.currentFilter}
    >
        <FontAwesomeIcon className={css["loader"]} icon={faSpinner} />
        <p className={css["text"]} children={getNameFromSearchFilter(props.filterType)} />
    </button>;
}

/** Props passed down to the {@link Tab} component */
export interface TabProps {
    /** The filtering type represented by this component. */
    filterType: SearchFilter;
    /** The currently active filter type. */
    currentFilter: SearchFilter;
    /** Set if the resuts are currently being loaded. */
    loading: boolean;

    /**
     * Callback invoked when the tab is selected and the filter type should change.
     *
     * @param {SearchFilter} filter The type that was selected.
     * Aka. the value passed via {@link filterType}.
     */
    onSelected(filter: SearchFilter): void;
}
