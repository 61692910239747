/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the legacy interfaces.
import type { LegacyResource } from "../interfaces";
// Import the legacy resource helpers.
import { isValidLegacyResource } from "./legacy-resource";


/**
 * Loads the "data" property from a given object.
 *
 * @param from The object to query the data from.
 * @param {true} asArray If true, expects the "data" property to be an array.
 * @return {LegacyResource[]} The list of resources found in the object.
 */
export function getData(from: unknown, asArray: true): LegacyResource[];

/**
 * Loads the "data" property from a given object.
 *
 * @param from The object to query the data from.
 * @param {false} [asArray=false] If true, expects the "data" property to be an array.
 * @return {LegacyResource | null} The value of the "data" property.
 */
export function getData(from: unknown, asArray?: false): LegacyResource | null;

/** Implementation. */
export function getData(from: unknown, asArray = false): LegacyResource[] | LegacyResource | null {
    if (!hasProperty(from, "data")) {
        throw new TypeError("Object has no \"data\" property");
    }
    if (asArray) {
        if (!Array.isArray(from.data)) {
            throw new TypeError("\"data\" property is not an array");
        }
        return from.data.filter(resource => isValidLegacyResource(resource));
    } else {
        if (Array.isArray(from.data)) {
            throw new TypeError("\"data\" property is an array");
        }
        if (from.data !== null && !isValidLegacyResource(from.data)) {
            throw new TypeError("\"data\" property is not a valid resource");
        }
        return from.data;
    }
}

/**
 * Finds the "included" data from the provided object.
 *
 * @template {string} T
 * @template {string} I
 * @param from The object to load the data from.
 * @param {T} [type=undefined] The type of the requested resource(s).
 * @return {LegacyResource<T, I>[]} The resources found in the object's included data that match the parameters.
 */
export function getIncluded<T extends string = string>(from: unknown, type?: T): LegacyResource<T>[];

/**
 * Finds the "included" data from the provided object.
 *
 * @template {string} T
 * @template {string} I
 * @param from The object to load the data from.
 * @param {T} [type=undefined] The type of the requested resource(s).
 * @param {I} [id=undefined] The id of the requested resource.
 * @return {LegacyResource<T, I>[]} The resources found in the object's included data that match the parameters.
 */
export function getIncluded<T extends string = string, I extends string = string>(
    from: unknown,
    type: T,
    id: I
): LegacyResource<T, I>;

/** Implementation */
export function getIncluded(from: unknown, type?: string, id?: string): LegacyResource[] | LegacyResource {
    if (!hasProperty(from, "included")) {
        throw new TypeError("Object has no \"included\" property");
    }
    if (!Array.isArray(from.included)) {
        throw new TypeError("\"included\" property is not an array");
    }
    const items = from.included.filter(resource => isValidLegacyResource(resource, type, id));
    if (typeof id === "string") {
        if (typeof items[0] === "undefined") {
            throw new ReferenceError(
                `Could not find an included resource with type ${type} and id ${id}`,
                { cause: from }
            );
        }
        return items[0];
    }
    return items;
}

/** Simple helper used to check if something is both an object and has the given property. */
function hasProperty<T, P extends string>(object: T, property: P): object is T & Record<P, unknown> {
    return typeof object === "object" && object !== null && property in object;
}
