/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
// Import the common tools.
import { makeContextInvalidObject, makeContextNoop } from "@andromeda/tools";
// Import RxJS.
import { Observable, Subject } from "rxjs";

// Import the pdf context.
import { PDFContext } from "./pdf";


/** Object used to manipulate the PDF display mode. */
export interface PDFActivePageContext {
    /** The index of the page that is currently active. */
    activePageIndex: number;
    /** The number of pages in this document. */
    pageCount: number;

    /** Observable used to trigger events when {@link activePageIndex} changes. */
    $activePageObservable: Observable<number>;

    /**
     * Updates the page that is currently active.
     *
     * @param {number} index The index of the page that has become active.
     * @param {boolean} [event=true] If false, will not trigger an update of the {@link $activePageObservable}.
     */
    setActivePage(index: number, event?: boolean): void;
}

/** Context used to manipulate the fullscreen state of a PDF document. */
export const PDFActivePageContext = createContext<PDFActivePageContext>({
    activePageIndex: 0, pageCount: 0,
    $activePageObservable: makeContextInvalidObject("PDFActivePageContext", "$activePageObservable"),
    setActivePage: makeContextNoop("PDFActivePageContext", "setActivePage")
});
PDFActivePageContext.displayName = "PDFActivePageContext";


/** Component used to provide the {@link PDFActivePageContext} to the tree. */
export default function PDFActivePageProvider(props: PDFActivePageProviderProps): ReactElement {
    // Get the number of pages from the PDF context.
    const { pageCount } = useContext(PDFContext);

    // Store the status of the context.
    const [activePageIndex, setActivePageIndex] = useState(0);
    const $activePageObservable = useRef(new Subject<number>());
    const setActivePage = useCallback(function setActivePage(newActivePageIndex: number, event = true): void {
        if (newActivePageIndex < 0 || newActivePageIndex >= pageCount) {
            return;
        }
        setActivePageIndex(newActivePageIndex);
        if (event) {
            $activePageObservable.current.next(newActivePageIndex);
        }
    }, [pageCount]);

    // Update the page when the subject is updated.
    useEffect(function transmitActivePageChange(): void | (() => void) {
        // Check if a subject was provided.
        if (typeof props.$pageChange === "undefined") {
            return;
        }

        // Listen for updates.
        const subscription = props.$pageChange.subscribe(setActivePage);
        return subscription.unsubscribe.bind(subscription);
    }, [props.$pageChange, setActivePage]);

    return <PDFActivePageContext.Provider
        value={{ activePageIndex, pageCount, setActivePage, $activePageObservable: $activePageObservable.current }}
        children={props.children}
    />;
}

/** Props passed down to the {@link PDFActivePageProvider} component. */
export interface PDFActivePageProviderProps {
    /** Children elements that will have access to the component. */
    children?: ReactNode;

    /** Observable used to transmit page change events. */
    $pageChange?: Observable<number>;
}
