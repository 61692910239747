/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useState } from "react";

// Import the subcomponents.
import Page, { PageType } from "./pages";
import PageSelector from "./page-selector";


/** Tool used to render the mobile version of the home page. */
export default function HomeMobile(): ReactElement {
    // Stores the currently selected page.
    const [page, setPage] = useState<PageType>("favourites");

    // Render the selector.
    return <div>
        <PageSelector current={page} onChange={setPage} />
        <Page type={page} />
    </div>;
}
