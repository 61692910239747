/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the resource identifier schema.
import ResourceIdentifier from "./resource-identifier";
// Import the links schema
import LinksSchema from "./links";
// Import the relationship schema.
import RelationshipSchema from "./relationship";

/** Schema used to represent a single resource. */
const Schema = {
    title: "Resource",
    description: "Schema used to describe a single resource.",
    type: "object",
    properties: {
        ...ResourceIdentifier.properties,
        attributes: {
            title: "Resource Attributes",
            description: "Attributes of the described resource.",
            type: "object",
            patternProperties: {
                "^[a-zA-Z0-9](?:[-\\w]*[a-zA-Z0-9])?$": {
                    anyOf: [
                        { type: "number" },
                        { type: "string" },
                        { type: "boolean" },
                        { type: "object" },
                        { type: "array" },
                        { type: "null" }
                    ]
                }
            },
            not: {
                anyOf: [
                    { required: [ "type" ] },
                    { required: [ "id" ] },
                    { required: [ "links" ] },
                    { required: [ "relationships" ] },
                ]
            }
        },
        relationships: {
            title: "Resource relationships",
            description: "Relationships of the described resource.",
            type: "object",
            patternProperties: {
                "^[a-zA-Z0-9](?:[-\\w]*[a-zA-Z0-9])?$": RelationshipSchema
            },
            not: {
                anyOf: [
                    { required: [ "type" ] },
                    { required: [ "id" ] },
                    { required: [ "links" ] },
                    { required: [ "relationships" ] },
                ]
            }
        },
        links: LinksSchema
    },
    additionalProperties: false,
    required: [ ...ResourceIdentifier.required ]
} as const;

// Export the schema.
export default Schema;
