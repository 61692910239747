/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useId } from "react";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the icon filling.
import LevelIconFill from "./fill";

// Import the css.
import css from "./index.module.scss";


/** Component used to render a level icon. */
export default function LevelIcon(props: LevelIconProps): ReactElement {
    // Get a unique id for this icon.
    const id = useId();

    // Render the SVG.
    return <svg viewBox={`0 0 32 32`} className={props.className}>
        <mask id={`level-icon-${id}`}>
            <rect fill="white" x={0} y={0} width={32} height={32} />
            <circle fill="black" cx={32 / 2} cy={32 / 2} r={3 * 32 / 16} />
        </mask>
        <rect
            className={classNames(css["outline"], { [css["outline--visible"]]: props.outline })}
            width={32}
            height={32}
            strokeDasharray="2, 2"
            strokeWidth={1}
        />
        <circle
            className={css["background"]}
            cx={32 / 2}
            cy={32 / 2}
            r={32 / 2}
            mask={`url(#level-icon-${id})`}
        />
        <LevelIconFill
            amount={Math.PI / 2 * props.level}
            mask={`url(#level-icon-${id})`}
            time={props.time ?? 1000}
            delay={props.delay ?? 0}
        />
    </svg>;
}

/** Props passed down to the {@link LevelIcon} component. */
export interface LevelIconProps {
    /** The level that the user managed to reach. */
    level: 0 | 1 | 2 | 3 | 4;

    /** If set, draws an outline around the element. */
    outline?: boolean;

    /**
     * The amount of time that the animation should take, in milliseconds.
     *
     * @default 1000
     */
    time?: number;
    /**
     * The delay applied before the animation is started, in milliseconds.
     *
     * @default 0
     */
    delay?: number;

    /** Optional class name added to the root <svg> element. */
    className?: string;
}
