/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
// Import the font awesome component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the router module.
import { useNavigate } from "react-router-dom";
// Import the storybook components.
import {
    Button,
    CardContainer,
    ToggleableButton,
    ToggleableButtonContextProvider
} from "@andromeda/storybook";
// Import the stores.
import { OrganisationStore, ZaqWikiFileStore, ZaqWikiStore } from "@andromeda/store";
// Import the file resource.
import { ZaqWikiFile } from "@andromeda/resources";

// Import the edit context.
import { ZaqWikiEditContext } from "../toggleable-context";
// Import the title component.
import ZaqWikiTitle from "./title";
// Import the module component.
import Module from "./module";
// Import the Summary component.
import Summary from "./summary";
// Import the summary context.
import { SummaryContext } from "./toggleable-context";
// Import the share button.
import ShareButton from "../components/share";

// Import the icons.
import { faChevronLeft, faBars, faEdit, faExpand, faCompress, faDownload } from "@fortawesome/free-solid-svg-icons";
import zimproov from "@andromeda/assets/logos/zimproov-wide.png";
// Import the css.
import css from "./index.module.scss";
import { FullscreenModalContext } from "../fullscreen-modal";
import { ZaqWikiSessionContextProvider } from "./session";


/** Component used to read a given ZaqWiki. */
export default function Reader(props: ReaderProps): ReactElement {
    // Load the navigator.
    const navigate = useNavigate();
    // Store the currently drawn asset.
    const [asset, setAsset] = useState<File | null>(null);
    // Generate a URL for the asset.
    const [assetUrl, setAssetUrl] = useState<string>();
    useEffect(function buildAssetUrl(): void | (() => void) {
        // If the asset is not set, do nothing.
        if (!asset) {
            return setAssetUrl(undefined);
        }

        // Build the url.
        const url = URL.createObjectURL(asset);
        setAssetUrl(url);
        return () => URL.revokeObjectURL(url);
    }, [asset]);

    // Store a reference to the element that should go fullscreen.
    const [fullscreenTarget, setFullscreenTarget] = useState<HTMLDivElement | null>(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    useEffect(function attachFullscreenChangeListener(): () => void {
        document.addEventListener("fullscreenchange", fullscreenListener);
        return () => document.removeEventListener("fullscreenchange", fullscreenListener);

        /** Listener that updates the fullscreen state. */
        function fullscreenListener(): void {
            setIsFullscreen(document.fullscreenElement !== null);
        }
    }, []);

    // Load the identifier of the requested wiki.
    const wiki = ZaqWikiStore.useSelector(({ resources }) => resources.find(r => r.id === props.id));

    // Load all the wiki's modules.
    const modules = ZaqWikiFileStore.useSelector(function findAllModules(state): ZaqWikiFile[] {
        // If the wiki was not loaded, return an empty list.
        if (!wiki) {
            return [];
        }

        // Find all the modules and return them.
        return wiki.relationships.files.data
            .map((module): ZaqWikiFile | undefined => state.resources.find(r => r.id === module.id))
            .filter((module): module is ZaqWikiFile => typeof module !== "undefined");
    });

    // Load the organisation icon.
    const icon = OrganisationStore.useSelector(function findOrganisationIcon(state): string | null | undefined {
        // Seek the id of the primary organisation for this wiki.
        const primaryOrg = wiki?.relationships.organisations.data[0]?.id;
        if (!primaryOrg) {
            return undefined;
        }

        // Return the organisation's icon.
        return state.resources.find(org => org.id === primaryOrg)?.attributes.icon;
    });

    // Render all the module cards.
    const cards = useMemo(() => {
        return [
            <ZaqWikiTitle
                key="title"
                title={wiki?.attributes.name ?? ""}
                description={wiki?.attributes.body ?? ""}
                icon={icon ?? zimproov}
            />,
            ...modules.map(function renderModule(module: ZaqWikiFile, index: number): ReactElement {
                return <Module index={index} updateDownloadable={setAsset} module={module} key={module.id} />;
            })
        ];
    }, [icon, modules, wiki?.attributes.body, wiki?.attributes.name]);

    // Render the toolbar buttons.
    const toolbar = useMemo(function renderToolbarButtons(): ReactNode[] {
        const toolbar: ReactNode[] = [
            <ToggleableButton context={SummaryContext} key="summary" className={css["button"]}>
                <FontAwesomeIcon icon={faBars} className={css["button__icon"]} />
            </ToggleableButton>,
            props.organisation ? <ShareButton key="share" id={props.id} /> : null
        ];

        // Add the fullscreen button.
        if (document.fullscreenEnabled) {
            // Check if we are in fullscreen.
            if (isFullscreen) {
                // Add the button to exit fullscreen.
                const exitFullscreen = function exitFullscreen(): void {
                    const promise = document.exitFullscreen();
                    if (promise) {
                        promise.then(() => setIsFullscreen(false));
                    } else {
                        setIsFullscreen(false);
                    }
                };

                toolbar.push(<Button key="fs" className={css["button"]} onClick={exitFullscreen}>
                    <FontAwesomeIcon icon={faCompress} className={css["button__icon"]} />
                </Button>);
            } else {
                // Load the method.
                const requestFullscreen = fullscreenTarget
                    && function requestFullscreen(): void | Promise<void> {
                        const event = new CustomEvent("request-fullscreen", { cancelable: true });
                        if (window.dispatchEvent(event)) {
                            return fullscreenTarget.requestFullscreen();
                        }
                    };
                if (requestFullscreen) {
                    // Add the button to enter fullscreen.
                    toolbar.push(<Button key="fs" className={css["button"]} onClick={requestFullscreen}>
                        <FontAwesomeIcon icon={faExpand} className={css["button__icon"]} />
                    </Button>);
                }
            }
        }

        // Add the edit button if we are not in readonly mode.
        if (typeof props.organisation !== "undefined") {
            toolbar.push(
                <ToggleableButton key="edit" context={ZaqWikiEditContext} className={css["button"]}>
                    <FontAwesomeIcon icon={faEdit} className={css["button__icon"]} />
                </ToggleableButton>
            );
        }

        // Add the download button.
        if (asset && assetUrl) {
            toolbar.push(
                <Button key="download" className={css["button"]}>
                    <a href={assetUrl} download={asset.name}>
                        <FontAwesomeIcon icon={faDownload} className={css["button__icon"]} />
                    </a>
                </Button>
            );
        }

        return toolbar;
    }, [asset, assetUrl, fullscreenTarget, props.id, isFullscreen, props.organisation]);

    // Build the callback invoked to leave the wiki.
    const leave = useCallback(function leaveZaqWiki(): void {
        navigate(-1);
    }, [navigate]);

    // Render the exit button.
    const exit = useMemo(function renderExitButton(): ReactElement | null {
        return <div
            className={
                `${css["button__container"]} `
                + `${css["button__container--exit"]} `
                + `${css["button__container--vertical"]}`
            }
        >
            <Button
                disabled={typeof props.organisation === "undefined"}
                className={`${css["button"]} ${css["button--large"]}`}
                variant="secondary"
                onClick={leave}
            >
                <FontAwesomeIcon icon={faChevronLeft} className={css["button__icon"]} />
                <p className={css["button__text"]}>Sortir du ZaqWiki</p>
            </Button>
            <Button
                disabled={typeof props.organisation === "undefined"}
                className={`${css["button"]} ${css["button--small"]}`}
                variant="secondary"
                onClick={leave}
            >
                <FontAwesomeIcon icon={faChevronLeft} className={css["button__icon"]} />
            </Button>
        </div>;
    }, [leave, props.organisation]);

    // Render the component.
    return <ZaqWikiSessionContextProvider wiki={props.id}>
        <ToggleableButtonContextProvider context={SummaryContext}>
            <FullscreenModalContext.Provider value={{ container: fullscreenTarget }}>
                <div
                    className={
                        `${css["container"]} `
                        + `${typeof props.organisation === "undefined" ? css["container--no-navbar"] : ""}`
                    }
                    ref={setFullscreenTarget}
                >
                    {/* Render the top bar. */}
                    <div
                        className={
                            `${css["button__container"]} `
                            + `${css["button__container--top"]} `
                            + `${css["button__container--horizontal"]}`
                        }
                    >
                        {exit}

                        {/* Render the top toolbar. */}
                        <div
                            className={
                                `${css["button__container"]} `
                                + `${css["button__container--toolbar"]} `
                                + `${css["button__container--horizontal"]}`
                            }
                            children={toolbar}
                        />
                    </div>

                    <CardContainer className={css["card-container"]}>
                        {cards}
                        <Summary id={props.id} />
                    </CardContainer>

                    {/* Render the right bar. */}
                    <div
                        className={
                            `${css["button__container"]} `
                            + `${css["button__container--right"]} `
                            + `${css["button__container--horizontal"]}`
                        }
                    >
                        {/* Render the right toolbar. */}
                        <div
                            className={
                                `${css["button__container"]} `
                                + `${css["button__container--toolbar"]} `
                                + `${css["button__container--vertical"]}`
                            }
                            children={toolbar}
                        />
                    </div>
                </div>
            </FullscreenModalContext.Provider>
        </ToggleableButtonContextProvider>
    </ZaqWikiSessionContextProvider>;
}

/** Props passed down to the {@link Reader} component. */
interface ReaderProps {
    /** The identifier of the edited {@link ZaqWiki}. */
    id: string;
    /** The identifier of the current organisation, if applicable. */
    organisation?: string;
}
