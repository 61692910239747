/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


import { ReactElement } from "react";
import css from "./activity.module.scss";
import classNames from "classnames";


export default function LinkActivity(props: LinkActivityProps): ReactElement {
    return <label className={classNames(css["activity"], props.className)}>
        <span className={css["label"]}>Actif</span>
        <span className={css["toggle"]} />
        <input type="checkbox" checked={props.state} onChange={props.onToggle} className={css["input"]} />
    </label>
}

export interface LinkActivityProps {
    state: boolean;
    className?: string;

    onToggle(): void;
}
