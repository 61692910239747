/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext, useMemo } from "react";

// Import the container context.
import { CardContainerContext } from "../container";

// Import the css.
import css from "./index.module.scss";


/** Component used to render the current progression in the list of cards. */
export function CardProgression(): ReactElement {
    const { count, current } = useContext(CardContainerContext);

    // Compute the width of the progress state.
    const width = useMemo(function computeProgressionWidth(): `${number}%` {
        const progress = current / Math.max(1, count - 1);
        return `${Math.floor(progress * 100)}%`;
    }, [current, count]);

    // Render the progress bar.
    return <div className={css["progress-bar"]} role="progressbar">
        <div className={css["progress-bar__state"]} style={{ width }}></div>
    </div>;
}
