/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, ReactNode, useState, useEffect, useContext, useMemo } from "react";

// Import the container context.
import { CardContainerContext } from "../container";
// Import the navigator button.
import CardNavigator from "./navigator";

// Import the css.
import css from "./index.module.scss";


/**
 * Component used to render a card on the screen.
 * Cards use the {@link CardContainerContext} to register/unregister themselves and check if they should be rendered.
 */
export default function Card(props: CardProps): ReactElement | null {
    // Store the index of the current card.
    const [index, setIndex] = useState<number | null>(null);
    // Load the card container context.
    const { current, register, unregister } = useContext(CardContainerContext);

    // Register the card when the component is mounted.
    useEffect(function registerCard(): () => void {
        register(index => {
            // NOTE: Use a timeout because otherwise React whines about us calling setIndex from another component.
            setTimeout(() => setIndex(index), 0);
        });
        return unregister;
    }, [register, unregister]);

    // Render the previous and next buttons.
    const buttons = useMemo(function renderButtons(): ReactElement | null {
        // Render nothing if the button should not be shown.
        if (!props.withNavigation) {
            return null;
        }

        // Render the buttons.
        return <div className={css["card__button-container"]}>
            <CardNavigator direction="prev" />
            <CardNavigator direction="next" />
        </div>;
    }, [props.withNavigation]);

    // If the card should not be rendered, do nothing.
    if (index === null || index !== current) {
        return null;
    }

    // Render the card.
    return <div className={css["card"]}>{props.children}{buttons}</div>;
}

/** Props passed to the {@link Card} component. */
export interface CardProps {
    /** Children that will be rendered inside the card if it is visible. */
    children?: ReactNode;

    /** If set, renders a previous and next button at the botton. */
    withNavigation?: boolean;
}
