/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useContext, useMemo } from "react";
// Import the Font-Awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";
// Import the resources.
import { Requirement } from "@andromeda/resources";
// Import the store.
import { useRequirement } from "@andromeda/store";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the custom contexts.
import { EditorSwitchContext, ValidatorSwitchContext } from "../../../context";
// Import the level icon.
import LevelIcon from "../../level-icon";

// Import the icons.
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
// Import the css.
import css from "./item-requirements.module.scss";
import classNames from "classnames";


/** Component used to render the requirement status of any given item to a user. */
export default function ItemRequirements(props: ItemRequirementsProps): ReactElement | null {
    const { state: editing } = useContext(EditorSwitchContext);
    const { state: validating } = useContext(ValidatorSwitchContext);

    // Load the requirements of the current item.
    const requirement = useRequirement(props.step, useContext(LoginContext).self.id);
    const detail = useMemo(() => requirement.data?.attributes.detail?.find(
        detail => detail.item.type === props.item.type && detail.item.id === props.item.id
    ), [props.item.id, props.item.type, requirement.data?.attributes.detail]);

    // Pre-render the status.
    const status = useMemo(function renderCurrentStatus(): ReactNode[] | null {
        // Wait for the status to be loaded.
        if (!requirement.isSuccess || !detail) {
            return null;
        }

        const status: ReactNode[] = [];
        if (Requirement.isZaqDetail(detail)) {
            if (detail.level !== null) {
                status.push(<LevelIcon
                    className={`${css["icon"]} ${css["icon--black"]}`}
                    key="level"
                    level={detail.level}
                />);
                status.push("niveau atteint");

                if (!detail.validated && detail.level >= requirement.data.attributes.requiredLevel) {
                    status.push(<FontAwesomeIcon
                        icon={faCheck}
                        className={classNames(css["icon"], css["icon--objective-met"])}
                        key="objective-met"
                    />);
                    status.push("objectif atteint");
                }
            }
            if (detail.validated) {
                status.push(<FontAwesomeIcon
                    icon={faCheck}
                    className={classNames(css["icon"], css["icon--validated"])}
                    key="validated"
                />);
                status.push("validé par un formateur");
            }
        } else if (Requirement.isExternalDetail(detail)) {
            if (detail.level !== null) {
                status.push(<LevelIcon
                    className={`${css["icon"]} ${css["icon--black"]}`}
                    key="level"
                    level={detail.level}
                />);
                status.push("niveau atteint");

                if (detail.level >= requirement.data.attributes.requiredLevel) {
                    status.push(<FontAwesomeIcon
                        icon={faCheck}
                        className={classNames(css["icon"], css["icon--validated"])}
                        key="validated"
                    />);
                    status.push("validé par un formateur");
                }
            }
        } else if (Requirement.isTutoDetail(detail) || Requirement.isWikiDetail(detail)) {
            if (detail.viewed) {
                status.push(<FontAwesomeIcon
                    icon={faCheck}
                    className={classNames(css["icon"], css["icon--objective-met"])}
                    key="objective-met"
                />);
                status.push("objectif atteint");
            }
        }
        return status;
    }, [detail, requirement.data?.attributes.requiredLevel, requirement.isSuccess]);

    // Render the requirement status.
    return <div className={css["container"]} children={editing || validating ? null : status} />;
}

/** Props passed down to the {@link ItemRequirements} component. */
export interface ItemRequirementsProps {
    /** The item to render the requirements of. */
    item: ResourceIdentifier<"zaq" | "zaq-tuto" | "zaq-wiki" | "external">;
    /** The step of the current item. */
    step: string;
}
