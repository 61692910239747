/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API interfaces.
import {
    Resource,
    InferResourceIdentifier,
    SingleMessageHelper,
    ResourceIdentifier,
} from "@andromeda/json-api";

// Import the CRUD operations.
import { ReadOneOptions, readOne, PromisableValidateFunction } from "../crud";

// Helper type used to cast "readOne" to its implementation signature.
type ImplementationInvoker<R extends Resource> = (
    type: R["type"],
    id: string,
    validator?: PromisableValidateFunction<R> | ReadOneOptions,
    options?: ReadOneOptions
) => Promise<SingleMessageHelper<R>>;

/**
 * Method used to read a resource from a given resource identifier.
 *
 * @template {ResourceIdentifier} R
 * The type of the resource identifier to read.
 * @param {R} identifier The resource identifier to read.
 * @param {ReadOneOptions} readOptions The options of the read operation.
 * @returns {Promise<SingleMessageHelper<Resource<R["type"]>>>} A promise that resolves with the parsed message.
 */
export function readByIdentifier<R extends ResourceIdentifier>(
    identifier: R,
    readOptions?: ReadOneOptions
): Promise<SingleMessageHelper<Resource<R["type"]>>>;

/**
 * Method used to read a resource from a given resource identifier.
 * Validates the received resource with the given validator.
 *
 * @template {Resource} R
 * The resource to read.
 * @param {InferResourceIdentifier<R>} identifier The resource identifier to read.
 * @param {PromisableValidateFunction<R>} validator The validator for the resource.
 * @param {ReadOneOptions} readOptions The options for the read operation.
 * @returns {Promise<SingleMessageHelper<R>>} A promise that resolves with the parsed message.
 */
export function readByIdentifier<R extends Resource>(
    identifier: InferResourceIdentifier<R>,
    validator: PromisableValidateFunction<R>,
    readOptions?: ReadOneOptions
): Promise<SingleMessageHelper<R>>;

/** Implementation ! */
export async function readByIdentifier<R extends Resource>(
    identifier: InferResourceIdentifier<R>,
    validatorOrOptions?: PromisableValidateFunction<R> | ReadOneOptions,
    readOptions?: ReadOneOptions
): Promise<SingleMessageHelper<R>> {
    // Wrap the readOne method.
    return (readOne as ImplementationInvoker<R>)(
        identifier.type,
        identifier.id,
        validatorOrOptions,
        readOptions
    );
}
