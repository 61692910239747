/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the stores.
import { AssetStore, EncryptionSourceStore } from "@andromeda/store";
// Import the resources.
import { EncryptionSource } from "@andromeda/resources";
// Import JSON:API tools.
import { extractURL } from "@andromeda/json-api";
// Import the web asset interface.
import { WebAsset } from "@andromeda/converter-lib";

// Import the web asset interface.
import { loadWebAsset, storeWebAsset, subscribeToWebAsset } from "./index";
// Import the asset store helper.
import { getAssetStore } from "./redux-helper";
import { firstValueFrom } from "rxjs";


/**
 * Helper method used to download a web asset from the API.
 *
 * @param {string} id The identifier of the requested asset.
 * @return {Promise<WebAsset>} A promise that resolves with the downloaded asset.
 */
export async function downloadWebAsset(id: string): Promise<WebAsset> {
    // Check if the asset was already requested.
    if (downloadedAssets.includes(id)) {
        return await firstValueFrom(subscribeToWebAsset(id));
    }
    downloadedAssets.push(id);
    // Try to load the asset from the database.
    const local = await loadWebAsset(id);
    if (local) {
        return local;
    }

    // Try to get the asset resource from the redux store.
    const store = getAssetStore();
    const stored = await store.dispatch(AssetStore.generator.readOne(id));

    // Try to get the encryption source from the redux store.
    let encryption: EncryptionSource | undefined = undefined;
    if (stored.relationships.encryptionSource.data) {
        const encryptionId = stored.relationships.encryptionSource.data.id;
        encryption = await store.getState()["encryption-source"].resources.find(source => source.id === encryptionId);
        if (!encryption) {
            // Try to load the source from the API.
            encryption = await store.dispatch(EncryptionSourceStore.generator.readOne(encryptionId));
        }
    }

    // Prepare to download the data from the S3 storage.
    if (!stored.links?.get) {
        throw new Error("No download link !");
    }
    const url = extractURL(stored.links.get);
    const headers: HeadersInit = {};
    if (encryption) {
        headers["X-Amz-Server-Side-Encryption-Customer-Algorithm"] = "AES256";
        headers["X-Amz-Server-Side-Encryption-Customer-Key"] = encryption.attributes.key;
        headers["X-Amz-Server-Side-Encryption-Customer-Key-MD5"] = encryption.attributes.md5;
    }

    // Download the asset from the S3 bucket.
    const data = await fetch(url, { headers }).then(r => r.arrayBuffer());

    // Create the web asset.
    const asset: WebAsset = { resource: stored, data };

    // Store the asset.
    await storeWebAsset(asset);
    return asset;
}

/** Memoized list of all the already downloaded assets. */
const downloadedAssets: string[] = [];
