/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";

// Import the css.
import css from "./index.module.scss";


/** Component used to render a custom select object. */
export function Select<T>(props: SelectProps<T>): React.ReactElement | null {
    // Compute the select id.
    const id = React.useId();
    const selectId = React.useMemo(() => props.selectId ?? `${id}-select`, [id, props.selectId]);

    // Render the options.
    const options = React.useMemo(function renderOptions(): React.ReactElement[] {
        return props.options.map((option: Option<T>, index: number) =>
            <option key={index} value={index} children={option.name} />
        );
    }, [props.options]);

    // Find the value of the select.
    const value = React.useMemo(function findOptionValue(): number | undefined {
        if (typeof props.value === "undefined") return undefined;
        const index = props.options.findIndex(option => option.value === props.value);
        return index === -1 ? undefined : index;
    }, [props.options, props.value]);

    // Compute the class name of the component.
    const className = React.useMemo(function computeClassName(): string {
        let className = css["select"];
        if (props.className) className += " " + props.className;
        if (props.options.length <= 1) className += " " + css["select--disabled"];
        return className;
    }, [props.className, props.options.length]);

    // Render the object.
    if (props.options.length <= 0) return null;
    return <label className={className} htmlFor={selectId}>
        <select
            id={selectId}
            value={value}
            disabled={props.options.length <= 1}
            onChange={ev => props.onChange(props.options[parseInt(ev.currentTarget.value)].value)}
            children={options}
        />
    </label>;
}

/** Props passed down to the {@link Select} component. */
interface SelectProps<T> {
    /** The list of select options to render. */
    options: Option<T>[];
    /** A callback invoked when the value of the option changes. */
    onChange(value: T): void;
    /** Override for the value of the select. */
    value?: T;

    /** A class name added to the component. */
    className?: string;
    /** An identifier passed down to the select. */
    selectId?: string;
}

/** Interface used to describe an option. */
export interface Option<T> {
    /** The rendered name of the option. */
    name: string;
    /** The value of the option. */
    value: T;
}
