/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useEffect, useState } from "react";
// Import React-Router.
import { useLocation, useNavigate, useParams } from "react-router-dom";
// Import React-Bootstrap.
import { Container } from "react-bootstrap";
// Import the resources.
import type { Step } from "@andromeda/resources";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";

// Import the store tools.
import { useIsSaving, useWasModified, withValidationReducer } from "../../store";
// Import the subcomponents.
import ValidationTitle from "./validation-title";
import RequirementRenderer from "./requirement-renderer";
import Selector from "./selector";
import External from "./external";
import ZaqTuto from "./zaq-tuto";
import Save from "./save";

// Import the css.
import css from "./index.module.scss";


/** Renders the validation interface. */
const Validation = withValidationReducer(
    function Validation(): ReactElement | null {
        // Load the course id from the url path.
        const { course } = useParams();
        // Store the selected user and item.
        const { pathname } = useLocation();
        const [user, setUser] = useState<string | undefined>(extractUserFromPath(pathname));
        const [item, setItem] = useState<Step.Item | undefined>(extractItemFromPath(pathname));

        // Check if the validations are currently saving.
        const saving = useIsSaving();
        const modified = useWasModified();

        // Try to prevent a page reload.
        useEffect(function preventPageReload(): void | (() => void) {
            if (modified) {
                window.addEventListener("beforeunload", preventUnload);
                return function clearListener(): void {
                    window.removeEventListener("beforeunload", preventUnload);
                }
            }

            function preventUnload(e: Event): void {
                e.preventDefault();
            }
        }, [modified]);

        // Wrappers for setUser and setItem.
        const navigate = useNavigate();
        const setUserWrapper = useCallback(function setUserWrapper(user: string): void {
            navigate(`/zaq-training/${course}/validation/${user}/${item?.type}/${item?.id}`); setUser(user);
        }, [course, item?.id, item?.type, navigate]);
        const setItemWrapper = useCallback(function setUserWrapper(item: Step.Item): void {
            navigate(`/zaq-training/${course}/validation/${user}/${item.type}/${item.id}`); setItem(item);
        }, [course, navigate, user]);

        // Render the component.
        if (typeof course === "undefined") {
            return null;
        }
        if (saving) {
            return <Loader containerClassName={css["loader"]} text="Sauvegarde de vos changements ..." transparent />;
        }
        return <Container className={css["container"]}>
            <ValidationTitle course={course} />
            <Selector course={course} user={user} setUser={setUserWrapper} item={item} setItem={setItemWrapper} />
            <RequirementRenderer course={course} user={user} item={item} />
            {item?.type === "external" && user ? <External user={user} external={item.id} /> : null}
            {item?.type === "zaq" && user ? <ZaqTuto user={user} zaq-tuto={item.id} /> : null}
            <Save />
        </Container>;
    }
);
export default Validation;

/**
 * Helper used to extract the user id from the current path.
 *
 * @param {string} path The path to extract from.
 * @return {string | undefined} The id of the current user.
 */
function extractUserFromPath(path: string): string | undefined {
    return /zaq-training\/.*\/validation\/([^/]*)/.exec(path)?.[1];
}

/**
 * Helper used to extract the item from the current path.
 *
 * @param {string} path The path to extract from.
 * @return {string | undefined} The item found in the path.
 */
function extractItemFromPath(path: string): Step.Item | undefined {
    const [, type, id] = /zaq-training\/.*\/validation\/.*\/(zaq|external)\/(.*)/.exec(path) ?? [];
    if (type !== "zaq" && type !== "external") {
        return undefined;
    }
    if (typeof id === "undefined") {
        return undefined;
    }
    return { type, id };
}
