/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */


import debug from "debug";


/** Type alias for a task that can be run by the {@link ParallelPromiseQueue}. */
export type IndexedPromiseTask<T = unknown> = (index: number) => Promise<T>;

/**
 * Interface used to describe a parallel promise queue.
 * This object runs every task passed via {@link enqueue}, in order.
 * Tasks can be executed in parallel.
 */
export class ParallelPromiseQueue {
    /** Number of jobs that can run in parallel. */
    public readonly parallelJobs: number;
    /** State of all the inner runners. */
    private readonly _runnerStates: boolean[];
    /** Queue used to store all the jobs that will need to be run. */
    protected readonly jobs: IndexedPromiseJob[] = [];

    /**
     * Class constructor.
     * Stores the number of jobs to run in parallel.
     *
     * @param {number} [parallelJobs=4] The number of jobs that can run in parallel.
     */
    public constructor(parallelJobs = 4) {
        this.parallelJobs = parallelJobs;
        this._runnerStates = new Array<boolean>(parallelJobs);
        for (let i = 0; i < parallelJobs; i++) this._runnerStates[i] = false;
    }

    /** @inheritDoc */
    private _wakeUp(): void {
        for (let i = 0; i < this.parallelJobs; i++) this._startRunner(i);
    }

    /** @inheritDoc */
    public enqueue<T = unknown>(task: IndexedPromiseTask<T>): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.jobs.push({
                task,
                resolve(error: Error | null, result?: T): void {
                    if (error) reject(error);
                    else resolve(result as T);
                },
            });
            this._wakeUp();
        });
    }

    /** Reads an executes the next job(s) in the queue. */
    private _startRunner(index: number): void {
        // Check if the runner is already started.
        if (this._runnerStates[index]) return;
        // Pick up a job from the queue.
        const job = this.jobs.shift();
        if (!job) return;

        // Start the runner.
        log("Runner #%d is picking up a new job", index);
        this._runnerStates[index] = true;
        try {
            job.task(index).then(
                result => resolve.bind(this)(job, result),
                error => reject.bind(this)(job, error)
            )
        } catch (error: unknown) {
            reject.bind(this)(job, error);
        }

        function resolve(this: ParallelPromiseQueue, runner: IndexedPromiseJob, value: unknown): void {
            log("Runner #%d finished its job successfully", index);
            runner.resolve(undefined, value);
            this._runnerStates[index] = false;
            this._startRunner(index);
        }
        function reject(this: ParallelPromiseQueue, runner: IndexedPromiseJob, error: unknown): void {
            log("Runner #%d finished its job with an error", index);
            runner.resolve(error);
            this._runnerStates[index] = false;
            this._startRunner(index);
        }
    }
}


/**
 * Interface used to represent a job. Used internally by {@link ParallelPromiseQueue}.
 *
 * @template T
 */
export interface IndexedPromiseJob<T = unknown> {
    /** The task to run in this job. */
    task: IndexedPromiseTask<T>;

    /**
     * Callback invoked when the job is complete.
     *
     * @param {unknown | undefined} error The error that arose when running the task.
     * @param {T} result The result of the task.
     */
    resolve(error: unknown | undefined, result?: T): void;
}

const log = debug("queue:parallel");
