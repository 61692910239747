/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import Redux.
import type { Action } from "redux";
// Import Redux-Thunk.
import { ThunkAction, ThunkDispatch } from "redux-thunk";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the resources.
import { Step, User, Validation } from "@andromeda/resources";
// Import the store.
import { CRUDAction, store, ValidationStore, WithResourceStore } from "@andromeda/store";
// Import the legacy tools.
import { RequestZaqAction, LegacyZaqReducerStore } from "@andromeda/legacy-zaq";

// Import the reducer.
import { VALIDATION_REDUCER_KEY, ValidationReducerStore } from "./reducer";
import { downloadZaq } from "@andromeda/legacy-zaq";


/** Action used to update the status of a validation request. */
export interface UpdateValidationRequestAction extends Action<"validation/update-request"> {
    /** The identifier of the validated user. */
    user: string;
    /** The validated item. */
    item: Step.Item;
    /** The status of the validation. */
    payload: RequestStatus<Validation.Create>;
}

/** Action used to update the status of a validation. */
export interface UpdateValidationAction extends Action<"validation/update"> {
    /** The identifier of the validated user. */
    user: string;
    /** The validated item. */
    item: Step.Item;
    /** The status of the validation. */
    payload: Validation.Create;
}

/** Action used to update the saving status of the validations. */
export interface SaveValidationsAction extends Action<"validation/save"> {
    /** The status of the request. */
    payload: RequestStatus;
}

/** Union of the {@link UpdateValidationAction} and {@link SaveValidationsAction} types. */
export type ValidationActions =
    | UpdateValidationAction
    | UpdateValidationRequestAction
    | SaveValidationsAction
    | CRUDAction<[Validation, Validation.Update, Validation.Create]>
    | RequestZaqAction;

// Helper type for the actions.
type ValidationStore = ValidationReducerStore & WithResourceStore<Validation> & LegacyZaqReducerStore;


/**
 * Action generator used to download a validation from the API.
 *
 * @param {string} user The user to download the validation for.
 * @param {Step.Item} item The item to get the validation of.
 * @return {ThunkAction<Promise<void>, ValidationStore, never, ValidationActions>}
 * An action that can be dispatched to the store.
 */
export function downloadValidation(
    user: string,
    item: Step.Item
): ThunkAction<Promise<void>, ValidationStore, never, ValidationActions> {
    return async function downloadValidation(
        dispatch: ThunkDispatch<ValidationStore, never, ValidationActions>,
        getState: () => ValidationStore
    ): Promise<void> {
        // Check if the validation is already loading.
        const currentValidation = getState()[VALIDATION_REDUCER_KEY].validations.find(
            ([id]) => id.item.type === item.type && id.item.id === item.id && id.user === user
        );
        if (typeof currentValidation !== "undefined") {
            return;
        }

        // Dispatch a loading.
        dispatch({ type: "validation/update-request", user, item, payload: RequestStatus.loading() });

        // Wait for the current save to be complete.
        const saving = getState()[VALIDATION_REDUCER_KEY].saving;
        if (saving?.isLoading) {
            await new Promise<void>((resolve) => {
                const unsubscribe = store.subscribe(function onStoreUpdate(): void {
                    const saving = getState()[VALIDATION_REDUCER_KEY].saving;
                    if (!saving?.isLoading) {
                        unsubscribe();
                        resolve();
                    }
                });
            });
        }

        // Download the validation from the store.
        try {
            const filter = {
                validation: {
                    [item.type]: { $eq: item.id },
                    user: { $eq: user }
                },
            };
            const [existing] = await dispatch(ValidationStore.generator.readMany({
                filter, limit: 1, sort: ["-date"]
            }));

            // Create a new validation.
            let validation: Validation.Create;
            if (item.type === "zaq") {
                validation = {
                    type: Validation.Type,
                    attributes: { detail: [] },
                    relationships: {
                        user: { data: { type: User.Type, id: user } },
                        zaq: { data: { type: "zaq", id: item.id } }
                    }
                };
                // Rebuild the details from the data.
                const tuto = await dispatch(downloadZaq(item.id, true));
                const details: Validation.Detail[][] = [];
                for (let majorStep = 0; majorStep < tuto.majorSteps.length; majorStep++){
                    const chapterDetails: Validation.Detail[] = [];
                    for (let minorStep = 0; minorStep < tuto.majorSteps[majorStep].minorSteps.length; minorStep++){
                        if (existing && "detail" in existing.attributes) {
                            const existingDetail = existing.attributes.detail[majorStep]?.[minorStep];
                            if (typeof existingDetail !== "undefined") {
                                chapterDetails.push(existingDetail);
                            } else {
                                chapterDetails.push({});
                            }
                        } else {
                            chapterDetails.push({});
                        }
                    }
                    details.push(chapterDetails);
                }
                validation.attributes.detail = details;
            } else {
                validation = {
                    type: Validation.Type,
                    attributes: { level: null },
                    relationships: {
                        user: { data: { type: User.Type, id: user } },
                        external: { data: { type: "external", id: item.id } }
                    }
                };
                if (existing && "level" in existing.attributes) {
                    validation.attributes.level = existing.attributes.level;
                }
            }

            // Dispatch the update.
            dispatch({ type: "validation/update-request", user, item, payload: RequestStatus.success(validation) });
        } catch (error: unknown) {
            dispatch({ type: "validation/update-request", user, item, payload: RequestStatus.error(error) });
        }
    };
}

/**
 * Action generator used to save all the validations.
 *
 * @return {ThunkAction<Promise<void>, ValidationStore, never, ValidationActions>}
 * An action that can be dispatched to the store.
 */
export function saveValidations(): ThunkAction<Promise<void>, ValidationStore, never, ValidationActions> {
    return async function saveValidations(
        dispatch: ThunkDispatch<ValidationStore, never, ValidationActions>,
        getState: () => ValidationStore
    ): Promise<void> {
        // Check if a save request is already running.
        if (getState()[VALIDATION_REDUCER_KEY].saving?.isLoading) {
            return;
        }

        // Dispatch a save event.
        const { validations } = getState()[VALIDATION_REDUCER_KEY];
        dispatch({ type: "validation/save", payload: RequestStatus.loading() });

        // Save all the validations.
        await Promise.all(validations.map(
            async function saveValidations([, validation]): Promise<void> {
                if (validation?.isSuccess) {
                    await dispatch(ValidationStore.generator.create(validation.data));
                }
            }
        ));

        // Dispatch a save event.
        dispatch({ type: "validation/save", payload: RequestStatus.success() });
    };
}
