/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the json:api resource interface.
import { Resource } from "@andromeda/json-api";

// Import the base interface.
import { AssetBase } from "./base";

// Import the validation schema.
import Schema from "../schema/video";
import { ValidateFunction } from "ajv";

/** Interface used to represent a video/* asset. */
export interface VideoAsset extends AssetBase {
    /** @inheritDoc */
    attributes: VideoAsset.Attributes;
}

/** Namespace used to augment the {@link VideoAsset} interface. */
export namespace VideoAsset {
    export const Type = AssetBase.Type;
    export type Type = typeof Type;

    export interface Attributes extends AssetBase.Attributes {
        /** @inheritDoc */
        contentType: `video/${string}`;
        /** Width of the video stream, in pixels. */
        width: number;
        /** Height of the video stream, in pixels. */
        height: number;
        /** Length of the video stream, in seconds. */
        length: number;
        /** Combined bitrate of the audio/video streams, in bits/seconds. */
        bitrate: number;
        /** Framerate of the video streams, in images/seconds. */
        framerate: number;
    }

    export const validate: ValidateFunction<VideoAsset> = compileSync(Schema.ResourceSchema);

    export interface Update extends Resource<VideoAsset.Type> {
        /** @inheritDoc */
        attributes?: Partial<Attributes>;
        /** @inheritDoc */
        relationships?: Update.Relationships;
    }

    export namespace Update {
        export interface Relationships {
            /** @borrows {AssetBase.Relationships.alternatives} */
            alternatives?: AssetBase.Relationships["alternatives"];
        }

        export const validate: ValidateFunction<Update>  = compileSync(Schema.UpdateSchema);
    }

    export type Create = VideoAsset;

    export namespace Create {
        export const validate: ValidateFunction<Create>  = compileSync(Schema.CreateSchema);
    }
}
