/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */


// Import react.
import React from "react";
// Import the RxJS module.
import { BehaviorSubject } from "rxjs";
// Import the noop tool.
import { makeContextNoop } from "@andromeda/tools";

// Import the status class.
import { ConversionInfo } from "../../lib";


/**
 * Context used to store the state of all the application's conversions.
 * Should not be exposed publicly !
 */
export interface ConversionStatusContext {
    /** Flag set if this is the default context. Used to only mount a single instance in the DOM tree. */
    _isDefault: boolean;
    /** Map of all the conversions. */
    conversions: ReadonlyMap<string, BehaviorSubject<ConversionInfo>>;

    /** Pushes a new conversion to the map. */
    push(id: string, observable: BehaviorSubject<ConversionInfo>): void;
}

// Default state of the context.
const DEFAULT_STATE: ConversionStatusContext = {
    _isDefault: true,
    conversions: new Map(),
    push: makeContextNoop("ConversionStatusContext", "push")
};

/** Context used to get a list of all the running conversions. */
export const ConversionStatusContext = React.createContext<ConversionStatusContext>(DEFAULT_STATE);
