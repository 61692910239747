/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement, ReactNode } from "react";
// Import React-Router.
import { Link } from "react-router-dom";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the css.
import css from "./text.module.scss";


/** Component used to render an item's title, description and tags. */
export default function ItemText(props: ItemTextProps): ReactElement {
    // Render all the tags.
    const tags = props.tags.map(function renderTag(tag: string, index: number): ReactNode {
        return <li key={index} className={css["tag"]} children={tag} />;
    });

    // Render the text area.
    return <div className={classNames(css["container"], props.className)}>
        <Link to={props.to} reloadDocument className={css["title"]} children={props.title} />
        <p className={css["description"]} children={props.description} />
        <ul className={css["tag-container"]} children={tags} />
    </div>;
}

/** Props passed down to the {@link ItemText} component. */
export interface ItemTextProps {
    /** The title of the item. */
    title: string;
    /** The description of the item. */
    description: string;
    /** The tags of the item. */
    tags: string[];

    /** The destination of redirection for this item. */
    to: string;
    /** Optional class name added to the root <div> element. */
    className?: string;
}
