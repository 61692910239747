/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";
// Import the login module.
import { LoginContext } from "@andromeda/login";

// Import the helper tools.
import { useIsValidator } from "../../../helpers";
// Import the contexts.
import { ValidatorSwitchContext } from "../../../context";
// Import the subcomponents.
import Title from "../../title";
import Legend from "./legend";
import OwnProgression from "./own";
import TeamProgression from "./team";
import Validate from "./validate";

// Import the css.
import css from "./index.module.scss";



/** Component used to render the progress section on the page. */
export default function Progress(props: ProgressProps): ReactElement {
    // Check if the current user can validate this course.
    const isValidator = useIsValidator(props.course)(useContext(LoginContext).self.id);
    const { state: validating } = useContext(ValidatorSwitchContext);

    return <section className={css["container"]}>
        <Title
            title={validating ? "Progression de mon équipe" : "Ma progression"}
            toggleContext={ValidatorSwitchContext}
            toggleName="formateur"
            showToggle={isValidator}
        />
        {validating ? <TeamProgression course={props.course} /> : <OwnProgression course={props.course} />}
        <Legend />
        <Validate />
    </section>;
}

/** Props passed down to the {@link Progress} component. */
export interface ProgressProps {
    /** The identifier of the currently rendered course. */
    course: string;
}
