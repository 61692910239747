/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React, { useEffect } from "react";
// Import the RxJS module.
import { Observable } from "rxjs";

// Import the conversion info.
import { ConversionInfo, ConversionStatus } from "../../lib";
// Import the subcomponents.
import { LoadingBar } from "./loading-bar";
import { LoadingIcon } from "./icon";

// Import the css.
import css from "./index.module.scss";


/** Indicator used to show the current conversion status of a given asset. */
export function ConversionStatusIndicator(props: StatusIndicatorProps): React.ReactElement | null {
    const [ status, setStatus ] = React.useState<ConversionInfo>({ status: ConversionStatus.uninitialized });
    useEffect(() => {
        const subscription = props.conversion.subscribe(setStatus);
        return () => subscription.unsubscribe();
    }, [props.conversion]);

    // Generate the class name.
    const className = React.useMemo(function generateIndicatorClassName(): string {
        let className = css["status-indicator"];
        if (props.className) className += ` ${props.className}`;
        return className;
    }, [props.className]);


    // Render the component.
    if (status.status === ConversionStatus.uninitialized) return null;
    return <span className={className}>
        <LoadingIcon status={status} />
        <LoadingBar status={status} />
    </span>;
}

/** Props passed down to the {@link ConversionStatusIndicator} component. */
interface StatusIndicatorProps {
    /** The conversion to represent. */
    conversion: Observable<ConversionInfo>;

    /** Class name applied to the conversion indicator. */
    className?: string;
}

export { LoadingIcon as ConversionStatusLoadingIcon, LoadingBar as ConversionStatusLoadingBar };
