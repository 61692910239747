/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


// Import the workbox tools.
import { Workbox } from "workbox-window";
// Import the logging tool.
import debug from "debug";


// Create the logging tool.
const log = debug("andromeda:worker:service-worker");

// Load the worker url.
const url = process.env["NODE_ENV"] === "development" ? "/dev-sw.js?dev-sw" : "/service-worker.js";
const type = process.env["NODE_ENV"] === "development" ? "module" : "classic";

// Create the service worker wrapper.
const worker = new Workbox(url, { type, updateViaCache: "none" });
log("Created a Workbox worker for url \"%s\"", new URL(url, window.location.origin).toString());


// Send a "SKIP_WAITING" message when a worker update is ready.
worker.addEventListener("waiting", function skipWaitingWhenUpdating(): void {
    // Wait for the current frame to be over before updating the service worker.
    new Promise<void>(r => setTimeout(r)).then(function updateServiceWorker(): void {
        log("Found a service worker update");
        // Send the message to skip waiting for the update.
        worker.messageSkipWaiting();

        // Wait for the worker to be controlling before resetting the ready flag.
        worker.addEventListener("controlling", function resetReadyFlagWhenControlling(): void {
            log("New worker has taken control");
        });
    });
});

(async function initialiseSW(): Promise<void> {
    // Register the worker.
    log("Initialising the ServiceWorker");
    await worker.register();

    // Wait for the worker to control this page.
    log("Service worker registered. Waiting for it to control this client ...");
    await worker.controlling;

    log("ServiceWorker is ready");
})().catch(console.error);
