/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useEffect } from "react";
// Import React-Bootstrap.
import { Navbar as BootstrapNavbar } from "react-bootstrap";

// Import the subcomponents.
import Home from "./home";
import User from "./user";
import Search from "./search";

// Import the css.
import css from "./index.module.scss";


// Re-export the search context tools.
export { NavbarSearchContextProvider, useHideNavbarSearchButton, HideNavbarSearchButton } from "./search";

/** Component used to render the top navbar on the page. */
export default function Navbar(): ReactElement {
    // Add the with-navbar class to the body when the bar is mounted.
    useEffect(function addNavbarPaddingToBodyWhenMounted(): VoidFunction {
        document.body.classList.add("with-navbar");

        // Return a callback used to remove the class when unmounted.
        return function removeNavbarPaddingFromBodyOnUnmount(): void {
            document.body.classList.remove("with-navbar");
        }
    }, []);

    // Render the component.
    return <BootstrapNavbar className={css["navbar"]}>
        <Home />
        <div className={css["separator"]} />
        <Search />
        <User />
    </BootstrapNavbar>;
}
