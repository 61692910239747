/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useContext, useEffect, useMemo, useState } from "react";
// Import the text normaliser.
import { normalise } from "@andromeda/components";
// Import the login tools.
import { LoginContext } from "@andromeda/login";
// Import the legacy zaq tools.
import { FullLegacyZaq, useLegacyZaqFromOrganisation } from "@andromeda/legacy-zaq";

// Import the search bar.
import { ZaqTutoSearchBar } from "./search-bar";

// Import the chevron icon.
import chevron from "@andromeda/assets/images/chevron.svg";
// Import the css.
import css from "./category.module.scss";


/** Component used to render a category of the tuto popup. */
export function Category(props: Props): ReactElement | null {
    // Load all the ZaqTuto.
    const organisation = useContext(LoginContext).organisations.current.id;
    const tuto = useLegacyZaqFromOrganisation(organisation);
    // Get the ZaqTuto search bar context.
    const text = normalise(useContext(ZaqTutoSearchBar.context).text);
    // Store the visible state of the category.
    const [visible, setVisible] = useState(false);

    // Load all the ZaqTuto with the current tag.
    const tutoWithTag = useMemo(function findNoticesWithTag(): FullLegacyZaq[] {
        // Wait for the tuto to load.
        if (!tuto.isSuccess) {
            return [];
        }

        // Find all the matching ZaqTuto.
        const matching = tuto.data.filter(ZaqTuto => {
            if (props.category === "___empty" && ZaqTuto.tags.length <= 0) {
                return true;
            }
            return ZaqTuto.tags.includes(props.category);
        });

        // Check if the tag passes the filter.
        if (normalise(props.category).includes(text)) {
            return matching;
        }
        return matching.filter(ZaqTuto => normalise(ZaqTuto.name).includes(text));
    }, [tuto, props.category, text]);

    useEffect(function setVisibleIfSelected(): void {
        if (tutoWithTag.find(ZaqTuto => ZaqTuto.id === props.selected?.id) !== undefined) {
            setVisible(true);
        }
    }, [tutoWithTag, props.selected]);

    // Render the ZaqTuto.
    const rendered = useMemo(function renderNotices(): ReactElement[] {
        if (!visible) {
            return [];
        }
        return tutoWithTag.map(ZaqTuto =>
            <li
                key={ZaqTuto.id}
                className={
                    `${css["category__list__item"]} ` +
                    `${ZaqTuto.id === props.selected?.id ? css["category__list__item--selected"] : ""}`
                }
                children={ZaqTuto.name}
                onClick={() => props.onSelected(ZaqTuto)}
            />
        );
    }, [tutoWithTag, props, visible]);

    // Render the category.
    if (tutoWithTag.length <= 0) {
        return null;
    }
    return <div className={`${css["category"]}`}>
        <div className={css["category__title"]}>
            <button
                className={`${css["category__expand"]} ${visible ? css["category__expand--expanded"] : ""}`}
                onClick={() => setVisible(!visible)}
            >
                <img src={chevron} alt="expand" />
                <p>{props.category === "___empty" ? "Sans catégorie" : props.category}</p>
            </button>
        </div>
        <ul className={css["category__list"]} children={rendered} />
    </div>;
}

/** Props passed down to the {@link Category} component. */
interface Props {
    /** Category element to render. */
    category: string;
    /** Method invoked when an element is being selected. */
    onSelected: (tuto: FullLegacyZaq) => void;
    /** Tuto element currently selected. */
    selected: FullLegacyZaq | undefined;
}
