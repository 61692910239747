/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ComponentType, createElement, ReactElement, ReactNode, useCallback, useContext, useState } from "react";
// Import RxJS.
import { BehaviorSubject } from "rxjs";

// Import the info interface.
import { ConversionInfo } from "../../lib";
// Import the context.
import { ConversionStatusContext } from "./context";

// Re-export the context.
export { ConversionStatusContext };


/** Provider for the {@link ConversionStatusContext}. */
export function ConversionStatusProvider(props: ConversionStatusProviderProps): ReactElement {
    // Ensures that there is no parent context.
    const parent = useContext(ConversionStatusContext);
    const [ conversions, setConversions ] = useState<Map<string, BehaviorSubject<ConversionInfo>>>(new Map());

    const push = useCallback(function pushNewConversion(id: string, conversion: BehaviorSubject<ConversionInfo>): void {
        setConversions(map => new Map([ ...map.entries(), [ id, conversion ]]));
        const subscription = conversion.subscribe({
            complete(): void {
                subscription.unsubscribe();
                setConversions(map => new Map(Array.from(map.entries()).filter((conversion) => conversion[0] !== id)));
            }
        });
    }, [])

    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (!parent._isDefault) return <>{props.children}</>;
    return <ConversionStatusContext.Provider
        value={{ conversions, _isDefault: false, push }}
        children={props.children}
    />;
}

/** Wraps the provided component into a {@link ConversionStatusProvider}. */
export function withConversionStatusProvider<P extends object>(component: ComponentType<P>): ComponentType<P> {
    return function WithConversionStatusProvider(props: P): ReactElement {
        return <ConversionStatusProvider children={createElement(component, props)} />;
    }
}

interface ConversionStatusProviderProps {
    /** Children that will gain access to the context. */
    children?: ReactNode;
}
