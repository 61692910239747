/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useContext, useMemo } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the store.
import { useRequirements, useUsers } from "@andromeda/store";
// Import the login module.
import { LoginContext } from "@andromeda/login";
// Import the resources.
import type { User } from "@andromeda/resources";

// Import the icons.
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./index.module.scss";


/** Component used to render the <select> item that allows to change the current user. */
export default function UserSelector(props: UserSelectorProps): ReactElement {
    // Load all the users and their requirements.
    const users = useUsers(useContext(LoginContext).organisations.current.id);
    const requirements = useRequirements(props.course, users.data?.map(({ id }) => id));

    // Filter out users that have no requirements.
    const usersWithRequirements = useMemo(function filterOutUsersWithoutRequirements(): User[] {
        // Wait for the users to be loaded.
        if (!users.isSuccess || !requirements.isSuccess) {
            return [];
        }

        // Filter out all the users that have no requirements.
        return users.data.filter(function shouldIncludeUser(user: User): boolean {
            return requirements.data.some(requirement => requirement.relationships.user.data.id === user.id);
        });
    }, [requirements.data, requirements.isSuccess, users.data, users.isSuccess]);

    // Render all the user options.
    const options = useMemo(function renderUserOptions(): ReactNode {
        return usersWithRequirements.map(function renderUserOption(user: User): ReactNode {
            return <option key={user.id} value={user.id} children={user.attributes.givenName} />;
        });
    }, [usersWithRequirements]);

    // Render the selector.
    return <div className={css["selector__container"]}>
        <select
            value={props.user}
            onChange={e => props.setUser(e.currentTarget.value)}
            children={options}
            className={css["selector"]}
        />
        <FontAwesomeIcon icon={faChevronDown} className={css["selector__icon"]} />
    </div>;
}

/** Props passed down to the {@link UserSelector} component. */
export interface UserSelectorProps {
    /** Identifier of the current course. */
    course: string;
    /** Identifier of the currently selected user. */
    user: string | undefined;

    /**
     * Callback used to change the current user.
     *
     * @param {string} user The identifier of the newly selected user.
     */
    setUser(user: string): void;
}
