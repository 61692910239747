/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback } from "react";
// Import the store.
import { useResourceDispatch, CourseStore, useCourse } from "@andromeda/store";
// Import the resources.
import type { Course } from "@andromeda/resources";
// Import the custom components.
import { useDeleteConfirm, useNotify } from "@andromeda/components";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";

// Import the hooks.
import { useCanDeleteZaqTraining, useCanEditZaqTraining } from "../../hooks";
// Import the item renderer.
import ItemRenderer from "./item";
// Import the icon renderer.
import ZaqTrainingIcon from "./icons/training";


/** Component used to render a ZaqTraining item. */
export default function ZaqTrainingItem(props: ZaqTrainingItemProps): ReactElement | null {
    // Check if the user can edit/delete the tuto.
    const canEdit = useCanEditZaqTraining() && props.withButtons;
    const canDelete = useCanDeleteZaqTraining() && props.withButtons;

    // Get the notification tool.
    const { fatal } = useNotify();
    // Get the legacy ZaqTraining dispatch.
    const dispatch = useResourceDispatch();
    // Get the deletion confirmation tool.
    const confirmDeletion = useDeleteConfirm("Êtes vous sûr de vouloir supprimer ce ZaqTraining ?");

    // Callback used to delete the ZaqTraining.
    const deleteTraining = useCallback(function deleteCurrentZaqTraining(): void {
        confirmDeletion().then(function deleteZaqTraining(canDelete: boolean): void | Promise<void> {
            if (canDelete) {
                return dispatch(CourseStore.generator.delete(props.resource.id));
            }
        }).catch(fatal);
    }, [confirmDeletion, dispatch, fatal, props.resource.id]);

    // Render the item.
    return <ItemRenderer
        resource={props.resource}
        title={props.resource.attributes.name ?? "Sans nom"}
        description=""
        icon={ZaqTrainingIcon}
        iconProps={{ id: props.resource.id, icon: props.resource.relationships.icon.data?.id }}
        tags={[]}
        play={`/zaq-training/${props.resource.id}`}
        edit={canEdit ? `/zaq-training/${props.resource.id}?created` : undefined}
        delete={canDelete ? deleteTraining : undefined}
    />;
}

/** Props passed down to the {@link ZaqTrainingItem} component. */
export interface ZaqTrainingItemProps {
    /** ZaqTraining resource being rendered. */
    resource: Course;
    /** Flag set if the edit and delete button should be shown. */
    withButtons?: boolean;
}

/**
 * Wrapper component used to download the {@link Course} item from the store,
 * before rendering the {@link ZaqTrainingItem}.
 */
export function ZaqTrainingItemWrapper(props: ZaqTrainingItemWrapperProps): ReactElement | null {
    // Load the training from the store.
    const course = useCourse(props.id, false, true);

    // Render nothing if the loading failed.
    if (course.isError) {
        return null;
    }

    // Render a loader while the course is loading.
    if (!course.isSuccess) {
        return <Loader containerClassName={props.loaderClassName} text="Chargement du ZaqTraining ..." />;
    }

    // Render the item.
    return <ZaqTrainingItem {...props} resource={course.data} />;
}

/** Props passed down to the {@link ZaqTrainingItemWrapper} component. */
export interface ZaqTrainingItemWrapperProps extends Omit<ZaqTrainingItemProps, "resource"> {
    /** Identifier of the {@link Course} to render. */
    id: string;
    /** CSS class name added to the loader container. */
    loaderClassName?: string;
}
