/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { createElement, Fragment, PropsWithChildren, ReactElement, useMemo } from "react";
// Import the font-awesome component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import React-Router.
import { Link, Route, Routes, useLocation } from "react-router-dom";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the store.
import { useShareLink } from "@andromeda/store";
// Import the ZaqWiki renderer.
import ZaqWiki from "@andromeda/zaq-wiki";

// Import the player route.
import Player from "./player/player";

// Import the icons.
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons/faCircleXmark";
import { faHourglassEnd } from "@fortawesome/free-solid-svg-icons/faHourglassEnd";
import { ReactComponent as ZaqTiv } from "@andromeda/assets/images/zaqtiv.svg";
import { ReactComponent as ZaqTivGray } from "@andromeda/assets/images/zaqtiv-coloured.svg";
// Import the css.
import css from "./share-link-parser.module.scss";


/** HOC used to parse shared links from the current URL pathname. */
export default function ShareLinkParser(props: PropsWithChildren): ReactElement {
    // Load the path from the url.
    const { pathname } = useLocation();

    // Check if the pathname matches a share link.
    const linkIdentifier = pathname.match(/^\/([a-zA-Z0-9-_]{22})(?:==)?/)?.[1];
    if (typeof linkIdentifier === "string") {
        window.location.assign(`/preview/${linkIdentifier}`);
        // return <ShareLinkComponent link={linkIdentifier} />;
    }

    // Render the children.
    return createElement(Fragment, props);
}

/** Component used to parse a share link. */
function ShareLinkComponent(props: ShareLinkComponentProps): ReactElement {
    // Fetch the share link from the store.
    const link = useShareLink(props.link);

    // Check if the link is still valid.
    const isValid = useMemo(function checkIfLinkIsValid(): true | "loading" | "disabled" | "expired" {
        // Wait for the link to be loaded.
        if (!link.isSuccess) {
            return "loading";
        }

        // Check if the link is active.
        if (!link.data.attributes.active) {
            return "disabled";
        }

        // Check if the link is still valid.
        if (link.data.attributes.expires === null) {
            return true;
        }
        if (new Date(link.data.attributes.expires) < new Date()) {
            return "expired";
        }
        return true;
    }, [link.data?.attributes, link.isSuccess]);

    // Wait for the link to load.
    if (link.isUninitialised || link.isLoading || isValid === "loading") {
        return <Loader containerClassName={css["loader"]} text="Validation du lien de partage ..." />;
    }
    if (link.isError || isValid === "disabled") {
        return <div className={css["error"]}>
            <FontAwesomeIcon className={css["icon"]} icon={faCircleXmark} />
            <h1 className={css["title"]}>Ce lien a été désactivé</h1>
            <Link className={css["link"]} to="https://zaqtiv.com/">
                Powered by
                <ZaqTiv className={css["zaqtiv"]} />
                <ZaqTivGray className={`${css["zaqtiv"]} ${css["zaqtiv--gray"]}`} />
            </Link>
        </div>;
    }
    if (isValid === "expired") {
        return <div className={css["error"]}>
            <FontAwesomeIcon className={css["icon"]} icon={faHourglassEnd} />
            <h1 className={css["title"]}>Ce lien a expiré</h1>
            <Link className={css["link"]} to="https://zaqtiv.com/">
                Powered by
                <ZaqTiv className={css["zaqtiv"]} />
                <ZaqTivGray className={`${css["zaqtiv"]} ${css["zaqtiv--gray"]}`} />
            </Link>
        </div>;
    }
    return <Routes>
        <Route path=":key/player/:gameId" element={<Player />} />
        <Route path="*" element={<ZaqWiki id={link.data.relationships.zaq.data.id} />} />
    </Routes>;
}

interface ShareLinkComponentProps {
    link: string;
}
