/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the xhr response interface.
import { XhrResponse } from "@andromeda/xhr";

/**
 * Error thrown if the xhr source is not valid.
 * Thrown by the Message constructor.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class InvalidSourceError extends Error {
    /** The status code of the failed response. */
    public status: number;
    /** The body of the failed response. */
    public body: string | undefined;

    /**
     * Class constructor.
     * Decodes the invalid source and generates a new error message.
     *
     * @param {XhrResponse} source The bad source.
     */
    public constructor(source: XhrResponse) {
        // Check the status code.
        if (source.status !== 200) {
            super(
                `Tried to build a message from a failed response. (Expected 200, got ${source.status}).`
            );
        } else {
            super(`The message is not a valid JSON:API message.`);
        }

        // Decode the body of the message.
        this.body = undefined;
        if (typeof source.body === "string") this.body = source.body;
        if (typeof source.body === "object")
            this.body = JSON.stringify(source.body);
        this.status = source.status;
    }
}

/**
 * Error thrown if the xhr source is empty.
 * Thrown by the Message constructor.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class SourceIsEmptyError extends Error {
    /** Error constructor. */
    public constructor() {
        super("The source for this message is empty !");
    }
}
