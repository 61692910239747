/*
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";

// Import the css.
import css from "./progress.module.scss";


/** Component used to render the progress of the current loader. */
export default function Progress(props: ProgressProps): ReactElement | null {
    // If the value is undefined, render nothing.
    if (typeof props.value === "undefined") {
        return null;
    }

    // Clamp the value in the [0, 1] interval.
    const clampedValue = Math.min(Math.max(props.value, 0), 1);

    // Compute the progress value.
    const value = `${Math.floor(clampedValue * 100)}%`;

    // Render the component.
    return <div className={css["progress__container"]}>
        <p className={`${css["progress__text"]} ${css["progress__text--spacer"]}`} children={value} />
        <div role="progressbar" className={css["progress"]}>
            <div className={css["progress__value"]} style={{ width: value }} />
        </div>
        <p className={css["progress__text"]} children={value} />
    </div>
}

/** Props passed down to the {@link Progress} component. */
export interface ProgressProps {
    /** The value of the progress bar. */
    value?: number;
}
