/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useCallback, useContext, useMemo } from "react";
// Import the user resource.
import { User, Organisation } from "@andromeda/resources";
// Import the user store.
import { useIsSaving, useResourceDispatch, UserStore } from "@andromeda/store";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the notification context.
import { Notification, useNotify } from "@andromeda/components";

// Import the email regexp.
import { EMAIL_REGEX } from "./hooks";
// Import the creator context.
import { UserCreationRejection, UserCreatorContext } from "./context";

// Import the add icon.
import check from "@andromeda/assets/images/check.svg";
import loader from "@andromeda/assets/images/loading.svg";
// Import the css.
import css from "./footer.module.scss";


/**
 * Component rendered as a footer to the creation modal.
 * Commits all staged creation requests.
 */
export function Commit(): ReactElement {
    const notify = useNotify();
    // Check if there are changes to commit.
    const context = useContext(UserCreatorContext);
    const invalidChanges = useMemo(function findInvalidChanges(): boolean {
        if (context.staged.length <= 0) return false;
        for (const user of context.staged) {
            if (user.rejectionReason) return false;
            if (!user.email.match(EMAIL_REGEX)) return false;
        }
        return true;
    }, [context.staged]);

    // Commits all the staged changes.
    const id = useContext(LoginContext).organisations.current.id;
    const dispatch = useResourceDispatch();
    const commit = useCallback(async function commit(): Promise<void> {
        const results = await Promise.all(
            context.staged.map((staged): Promise<true | UserCreationRejection.Reason> => {
                return dispatch(
                    UserStore.generator.create({
                        type: User.Type,
                        attributes: {
                            email: staged.email,
                            username: staged.username,
                            givenName: staged.givenName,
                        },
                        relationships: {
                            organisation: { data: { type: Organisation.Type, id }}
                        }
                    })
                ).then(
                    () => {
                        notify.push({
                            type: Notification.Type.info,
                            title: "Nouveau compte créé",
                            text: `Un mail a été envoyé à "${staged.email}" pour terminer la création du compte.`
                        });
                        return true;
                    },
                    error => {
                        notify.push({
                            type: Notification.Type.error,
                            title: "Impossible de créer un compte",
                            error
                        });
                        return UserCreationRejection.Reason.unknown
                    }
                );
            })
        );

        const updatedStages = context.staged
            .map(
                function mapInvalidResults(
                    staged: User.Create.Attributes & UserCreationRejection,
                    index: number
                ): null | (User.Create.Attributes & UserCreationRejection) {
                if (results[index] === true) {
                    return null;
                } else {
                    return {
                        ...staged,
                        rejectionReason: results[index] as UserCreationRejection.Reason
                    };
                }
            })
            .filter(
                function clearValidStages(
                    stage: null | (User.Create.Attributes & UserCreationRejection)
                ): stage is User.Create.Attributes & UserCreationRejection {
                    return stage !== null;
                }
            );
        context.setStaged([ ...updatedStages ]);
    }, [context, dispatch, id, notify]);

    // Check if the user is being created.
    const isSavingUser = useIsSaving(User.Type);
    const buttonContents = useMemo(function renderButtonContents(): ReactElement[] {
        if (isSavingUser) {
            if (context.staged.length > 1) {
                return [
                    <img key="image" src={loader} alt="loading" />,
                    <p key="text">Création des comptes ...</p>
                ];
            } else {
                return [
                    <img key="image" src={loader} alt="loading" />,
                    <p key="text">Création du compte ...</p>
                ];
            }
        } else {
            return [
                <img key="image" src={check} alt="commit" />,
                <p key="text">Valider</p>
            ];
        }
    }, [context.staged.length, isSavingUser]);

    // Render the component.
    return <footer className={css["footer"]}>
        <button
            disabled={!invalidChanges || isSavingUser}
            className={css["footer__button"]}
            onClick={commit}
            children={buttonContents}
        />
    </footer>
}
