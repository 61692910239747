/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ComponentType, createElement, ReactElement, useCallback, useMemo, useRef, useState } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the icons.
import { faQuestion } from "@fortawesome/free-solid-svg-icons/faQuestion";
// Import the css.
import css from "./icon.module.scss";


/** Component used to render the icon of a given search result. */
export default function ItemIcon<P extends object>(props: ItemIconProps<P>): ReactElement {
    // Lazy-load the icon only when the component is rendered on screen.
    const [shouldIconLoad, setShouldIconLoad] = useState(false);
    const observer = useRef<IntersectionObserver>();
    const lazyLoadIcon = useCallback(function watchForLazyLoadedIcon(element: HTMLDivElement | null): void {
        // Delete any existing observer.
        if (observer.current) {
            observer.current.disconnect();
            delete observer.current;
        }

        // If the element is not set, stop here.
        if (element === null) {
            return;
        }

        // Create a new observer.
        observer.current = new IntersectionObserver(onIntersecting);
        observer.current.observe(element);

        // Callback attached to the intersection observer.
        function onIntersecting(entries: IntersectionObserverEntry[]): void {
            for (const entry of entries) {
                setShouldIconLoad(entry.intersectionRatio > 0);
            }
        }
    }, []);

    // Render the icon component.
    const icon = useMemo(function renderIcon(): ReactElement | null {
        // If the icon should not load, render nothing.
        if (!shouldIconLoad) {
            return null;
        }

        // If no components were provided, default to a question mark.
        if (!props.component) {
            return <FontAwesomeIcon className={css["question-mark"]} icon={faQuestion} />;
        }

        return createElement(props.component, props.props);
    }, [props.component, props.props, shouldIconLoad]);

    // Render the component.
    return <div className={classNames(css["icon"], props.className)} ref={lazyLoadIcon} children={icon} />;
}

/** Props passed down to the {@link ItemIcon} component. */
export interface ItemIconProps<P extends object> {
    /** The component used to render the icon. */
    component?: ComponentType<P>;
    /** Additional props passed down to the component. */
    props: P;
    /** Class name added to the container of the icon. */
    className?: string;
}
