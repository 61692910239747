/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";

// Import the conversion types.
import { ConversionInfo, ConversionStatus } from "../../lib";

// Import the css.
import css from "./loading-bar.module.scss";


/** Component used to render the loading bar of an asset conversion. */
export function LoadingBar(props: LoadingBarProps): React.ReactElement {
    // Generate the class name.
    const className = React.useMemo(function generateIndicatorClassName(): string {
        let className = css["loading-bar"];
        switch (props.status.status) {
        case ConversionStatus.preparing:     className += ` ${css["loading-bar--preparing"]}`; break;
        case ConversionStatus.queued:        className += ` ${css["loading-bar--queued"]}`; break;
        case ConversionStatus.uploading:     className += ` ${css["loading-bar--uploading"]}`; break;
        case ConversionStatus.converting:    className += ` ${css["loading-bar--converting"]}`; break;
        case ConversionStatus.downloading:   className += ` ${css["loading-bar--downloading"]}`; break;
        case ConversionStatus.storing:       className += ` ${css["loading-bar--storing"]}`; break;
        case ConversionStatus.done:          className += ` ${css["loading-bar--done"]}`; break;
        }

        return className;
    }, [props.status.status]);

    // Compute the value of the progress bar.
    const value = React.useMemo(function renderValue(): number {
        switch (props.status.status) {
        case ConversionStatus.uploading:
        case ConversionStatus.downloading:
            return props.status.progress;
        default:
            return 1;
        }
    }, [props.status]);

    // Render the component.
    return <span className={className}>
        <span className={css["loading-bar__bar"]} style={{ width: `${value * 100}%` }} />
    </span>;
}

/** Props passed down to the {@link LoadingBar} component. */
interface LoadingBarProps {
    /** Status of the current conversion. */
    status: ConversionInfo;
}
