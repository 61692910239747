/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useState, useMemo, useEffect } from "react";


/** Union of all the open box props interfaces. */
export type OpenBoxProps = NotOpenableBoxProps | InternalOpenBoxStateProps | ExternalOpenBoxStateProps;

/**
 * Helper hook used to load the state of the box.
 *
 * @param {OpenBoxProps} props The props of the box opening/closing logic.
 * @return {BoxState} The state of the box.
 */
export default function useBoxState(props: OpenBoxProps): BoxState {
    // Store the internal state of the box.
    const [internalState, setInternalState] = useState("startsOpen" in props ? props.startsOpen === true : false);

    // Invoke the handlers when the state changes.
    const [previousState, setPreviousState] = useState(internalState);
    useEffect(function handleStateChanges(): void {
        // Check if the state has changed.
        let currentState;
        if ("isOpen" in props) {
            currentState = props.isOpen === true;
        } else {
            currentState = internalState;
        }
        if (currentState !== previousState) {
            props.onToggled?.(currentState);
            props[currentState ? "onOpened" : "onClosed"]?.();
            setPreviousState(currentState);
        }
    }, [internalState, previousState, props]);

    // Return the handler.
    return useMemo(function computeBoxState(): BoxState {
        // Check if the box can be opened.
        if ("canOpen" in props && props.canOpen === false) {
            return {
                state: props.isOpen === true, toggle() {
                    return;
                }
            };
        } else if ("isOpen" in props) {
            return {
                state: props.isOpen,
                toggle() {
                    if (canToggle(!props.isOpen)) {
                        props.onToggle?.();
                    }
                }
            };
        } else {
            return {
                state: internalState,
                toggle() {
                    if (canToggle(internalState)) {
                        setInternalState(!internalState);
                    }
                }
            };
        }

        /** Helper used to check if a toggle can occur. */
        function canToggle(status: boolean): boolean {
            if (typeof props.onToggleRequest === "function" && !props.onToggleRequest(status)) {
                return false;
            }
            const specificCallback = props[status ? "onOpenRequest" : "onCloseRequest"];
            return typeof specificCallback === "undefined" || specificCallback();

        }
    }, [internalState, props]);
}

/** Interface used for the return value of {@link useBoxState}. */
interface BoxState {
    /** Current state of the box. */
    state: boolean;

    /** Method used to toggle the state of the box. */
    toggle(): void;
}

/** Interface shared by all the props interfaces. */
interface OpenBoxStateProps {
    /**
     * Callback that will be invoked when the user requests the box to be opened.
     *
     * @returns {boolean} If false, the request will be denied and the box will stay closed.
     */
    onOpenRequest?(): boolean;

    /** Callback invoked once the box was opened. */
    onOpened?(): void;

    /**
     * Callback that will be invoked when the user requests the box to be closed.
     *
     * @returns {boolean} If false, the request will be denied and the box will stay open.
     */
    onCloseRequest?(): boolean;

    /** Callback invoked once the box was opened. */
    onClosed?(): void;

    /**
     * Callback that will be invoked when the user requests the box state to change.
     *
     * @param {boolean} current The current state of the box.
     * @returns {boolean} If false, the request will be denied and the state will stay the same.
     */
    onToggleRequest?(current: boolean): boolean;

    /**
     * Callback that will be invoked when the state of the box has changed.
     *
     * @param {boolean} status The state of the box.
     */
    onToggled?(status: boolean): void;
}

/** Interface used to describe a box that stays in a fixed state. */
interface NotOpenableBoxProps extends OpenBoxStateProps {
    /** If set to {@code true}, the box can be opened/closed by the user by clicking on the header. */
    canOpen: false;

    /**
     * If set to {@code true}, the box's body will be visible.
     *
     * @default true
     */
    isOpen?: boolean;
}

/**
 * Interface used to describe a box that can be opened.
 * The state of the box will be stored internally.
 */
interface InternalOpenBoxStateProps extends OpenBoxStateProps {
    /** @borrows NotOpenableBoxProps.canOpen */
    canOpen?: true;

    /** Initial state of the box. */
    startsOpen?: boolean;
}

/**
 * Interface used to describe a box that can be opened.
 * The state of the box will be stored externally and must be provided through a prop.
 */
interface ExternalOpenBoxStateProps extends OpenBoxStateProps {
    /** If set to {@code true}, the box's body will be visible. */
    isOpen: boolean;

    /** Callback invoked when the user toggles the box state. */
    onToggle?(): void;
}
