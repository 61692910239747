/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API module.
import type { Filter } from "@andromeda/json-api";
// Import the Tag resource.
import { Tag } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the store.
import { TagStore, ResourceStore } from "../../stores";
// Import the resource hooks.
import useResources from "./util/use-resources";
import useResource from "./util/use-resource";


/**
 * Hook used to download a Tag from the store.
 *
 * @param {string} id The identifier of the Tag.
 * @param {boolean} [cache=true] Try to read from the cache if possible.
 * @return {RequestStatus<Tag>} The status of the request.
 */
export function useTag(id: string, cache = true): RequestStatus<Tag> {
    return useResource(TagStore, Tag.Type, id, cache);
}

/**
 * Downloads all the Tags of the specified organisation.
 *
 * @param {string} organisation The organisation to load the Tag for.
 * @return {RequestStatus<Tag[]>} The status of the request.
 */
export function useTags(organisation: string): RequestStatus<Tag[]> {
    // Prepare the filter used for the request.
    const filter: Filter = { organisation: { id: { $eq: organisation }}, };

    // Prepare the selector used to load the Tags.
    const selector = function selector(state: ResourceStore<Tag>): Tag[] {
        return state.resources.filter(function TagBelongsToOrg(tag: Tag): boolean {
            return tag.relationships.owner.data.id === organisation;
        });
    };

    // Return the resources.
    return useResources(TagStore, Tag.Type, { filter }, selector);
}
