/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useEffect, useMemo, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoginConfig } from "./config/config";
import { readMany } from "@andromeda/resource-helper";
import { PasswordReset } from "@andromeda/resources";
import { extractURL } from "@andromeda/json-api";
import { Loader, useNotify } from "@andromeda/components";


export function PasswordResetHandler(): ReactElement {
    const config = useLoginConfig();
    const navigate = useNavigate();
    const [ params ] = useSearchParams();
    const userEmail = useMemo(() => params.get("user_email"), [params]);
    const notify = useNotify();

    const isGenerating = useRef(false);
    useEffect(function generatePasswordResetLink(): void {
        if (!config) return;
        if (isGenerating.current) return;
        isGenerating.current = true;

        (async function generatePasswordResetLink(): Promise<void> {
            if (!userEmail) throw new Error("No user email");

            // Load the link.
            await readMany(
                PasswordReset.Type,
                PasswordReset.validate,
                { searchParams: { client_id: config.clientId, user_email: userEmail }}
            )
                .then(link => link.data[0].links?.related)
                .then(link => { if (link) return link; throw new Error(); })
                .then(extractURL)
                .then(window.location.assign.bind(window.location));
        })().catch(notify.fatal);
    }, [config, navigate, notify.fatal, userEmail]);

    return <Loader text="Génération du formulaire de réinitialisation de mot de passe ..." />;
}

