/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import AJV.
import type { ValidateFunction } from "ajv";
// Import the JSON:API interfaces.
import {
    Resource,
    ResourceIdentifier,
    Relationship
} from "@andromeda/json-api";
import { CreatableResource } from "@andromeda/resource-helper";
// Import the json schema compiler.
import { compileSync } from "@andromeda/validation";

// Import the user resource.
import { User } from "../../organisation";
// Import the step resource.
import { Step } from "../step";

// Import the schemas.
import { RequirementSchema } from "./schema";


/**
 * Interface used to represent a user's requirement on a given course step.
 */
export interface Requirement extends Resource<Requirement.Type> {
    /** @inheritDoc */
    attributes: Requirement.Attributes;
    /** @inheritDoc */
    relationships: Requirement.Relationships;
}

/** Augment the {@link Requirement} interface. */
export namespace Requirement {
    export const Type = "requirement" as const;
    export type Type = typeof Type;

    /** Exhaustive list of all the possible requirement levels. */
    export enum Level {
        novice = 0, apprentice = 1, master = 2, expert = 3
    }

    /** Augment the {@link Level} enum. */
    export namespace Level {
        /** Converts a {@link Level} enum to its string representation. */
        export function toString(level: Level): string {
            switch (level) {
            case Requirement.Level.novice:
                return "novice";
            case Requirement.Level.apprentice:
                return "apprenti";
            case Requirement.Level.master:
                return "maîtrise";
            case Requirement.Level.expert:
                return "expert";
            }
        }
    }

    /** Base interface for all the details. */
    interface BaseDetail<T extends string> {
        /** Reference to the described item. */
        item: ResourceIdentifier<T>;
        /** The date at which the detail was made. */
        date: string | null;
    }

    /** Additional details regarding a given wiki requirement. */
    export interface WikiDetail extends BaseDetail<"zaq-wiki"> {
        /** Flag set if all the files were viewed and opened. */
        viewed: boolean;
    }

    /** Additional details regarding a given tuto requirement. */
    export interface TutoDetail extends BaseDetail<"zaq-tuto"> {
        /** Flag set if the tuto was viewed in full. */
        viewed: boolean;
    }

    /** Additional details regarding a given zaq requirement. */
    export interface ZaqDetail extends BaseDetail<"zaq"> {
        /** Level that was reached for the described zaq. */
        level: Level | null;
        /** Is true if the item was validated. */
        validated: boolean;
    }

    /** Additional details regarding a given external requirement. */
    export interface ExternalDetail extends BaseDetail<"external"> {
        /** Level that was reached for the described element. */
        level: Level | null;
    }

    /** Union of all the detail info. */
    export type Detail = WikiDetail | TutoDetail | ZaqDetail | ExternalDetail;

    /** Type guard for a {@link WikiDetail}. */
    export function isWikiDetail(detail: Detail): detail is WikiDetail {
        return detail.item.type === "zaq-wiki";
    }

    /** Type guard for a {@link TutoDetail}. */
    export function isTutoDetail(detail: Detail): detail is TutoDetail {
        return detail.item.type === "zaq-tuto";
    }

    /** Type guard for a {@link ZaqDetail}. */
    export function isZaqDetail(detail: Detail): detail is ZaqDetail {
        return detail.item.type === "zaq";
    }

    /** Type guard for a {@link ZaqDetail}. */
    export function isExternalDetail(detail: Detail): detail is ExternalDetail {
        return detail.item.type === "external";
    }

    /** Attributes of a {@link Requirement} */
    export interface Attributes {
        /** The level that the user must reach to consider this steps as valid. */
        requiredLevel: Level;
        /** Requirement detail regarding all the items of this requirement's step. */
        detail: Detail[];
    }

    /** Relationships of a {@link Requirement} */
    export interface Relationships {
        /** Reference to the step that has the requirement. */
        step: Relationship<Step>;
        /** Reference to the user that has the requirement. */
        user: Relationship<ResourceIdentifier<User.Type>>;
    }

    export const validate: ValidateFunction<Requirement> = compileSync<Requirement>(RequirementSchema.ResourceSchema);


    /** Interface used to update a {@link Requirement}. */
    export interface Update extends Resource<Requirement.Type> {
        /** @inheritDoc */
        attributes?: Update.Attributes;
    }

    /** Augments the {@link Update} interface. */
    export namespace Update {
        export type Attributes = Pick<Requirement.Attributes, "requiredLevel">;
        export const validate: ValidateFunction<Requirement.Update> =
            compileSync<Requirement.Update>(RequirementSchema.UpdateSchema);
    }


    /** Interface used to create new {@link Requirement}. */
    export interface Create extends CreatableResource<Requirement.Type> {
        /** @inheritDoc */
        attributes: Create.Attributes;
        /** @inheritDoc */
        relationships: Requirement.Relationships;
    }

    /** Augments the {@link Create} interface. */
    export namespace Create {
        export type Attributes = Pick<Requirement.Attributes, "requiredLevel">;
        export const validate: ValidateFunction<Requirement.Create> =
            compileSync<Requirement.Create>(RequirementSchema.CreateSchema);
    }
}
