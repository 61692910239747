/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, SyntheticEvent, useCallback, useContext, useMemo } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the store.
import { ExternalStore, useExternals, useResourceDispatch } from "@andromeda/store";
// Import the resources.
import { External, Organisation } from "@andromeda/resources";
// Import the login module.
import { LoginContext } from "@andromeda/login";
// Import the custom components.
import { useDeleteConfirm, useNotify } from "@andromeda/components";

// Import the local contexts.
import { ExternalModalContext } from "../../../../../context";
// Import the list component.
import ItemList, { Item } from "./item-list";

// Import the icons.
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./zaq-tuto.module.scss";


/** Component used to add a new External to the current step. */
export default function AddExternal(props: AddExternalProps): ReactElement {
    // Download all the External from the current organisation.
    const organisation = useContext(LoginContext).organisations.current.id;
    const externals = useExternals(organisation);

    // Get the external modal.
    const modal = useContext(ExternalModalContext);

    // Callback used to delete an external.
    const confirm = useDeleteConfirm("Êtes vous sûr de vouloir supprimer cette formation externe ?");
    const dispatch = useResourceDispatch();
    const { error } = useNotify();
    const makeDeleterFor = useCallback(function makeDeleterFor(external: string) {
        return function deleteExternal(event: SyntheticEvent): void {
            event.stopPropagation();
            confirm().then(shouldDelete => {
                if (!shouldDelete) {
                    return;
                }

                return dispatch(ExternalStore.generator.delete(external));
            }).catch(error);
        };
    }, [confirm, dispatch, error]);

    // Callback used to create a new external.
    const createExternal = useCallback(function createExternal(): void {
        dispatch(ExternalStore.generator.create({
            type: External.Type,
            attributes: { name: "" },
            relationships: {
                organisation: { data: { type: Organisation.Type, id: organisation } },
                asset: { data: null }
            }
        }))
            .then(({ id }) => modal.show(id))
            .catch(error);
    }, [dispatch, error, modal, organisation]);

    // Build the category list from the externals.
    const items = useMemo(function buildCategoryList(): Item[] {
        // Wait for the externals to load.
        if (!externals.isSuccess) {
            return [];
        }

        // Reduce the category list into a list.
        return externals.data.map(function externalToItem(external) {
            // Render the controls.
            const controls: ReactNode = [
                <button
                    key="edit"
                    onClickCapture={e => {
                        e.stopPropagation();
                        modal.show(external.id);
                    }}
                    className={`${css["button"]} ${css["button--edit"]}`}
                >
                    <FontAwesomeIcon className={css["button__icon"]} icon={faEdit} />
                </button>,
                <button
                    key="delete"
                    onClickCapture={makeDeleterFor(external.id)}
                    className={`${css["button"]} ${css["button--delete"]}`}
                >
                    <FontAwesomeIcon className={css["button__icon"]} icon={faTrash} />
                </button>
            ];

            return {
                type: "external",
                id: external.id,
                name: external.attributes.name.length === 0 ? "Sans nom" : external.attributes.name,
                controls
            };
        });
    }, [externals.data, externals.isSuccess, makeDeleterFor, modal]);

    // Render the component.
    if (!externals.isSuccess) {
        return <Loader containerClassName={css["loader"]} text="Chargement des formations ..." />;
    }
    return <>
        <ItemList
            className={css["list"]}
            items={items}
            selected={props.selected}
            select={props.select}
        />
        <button
            onClick={createExternal}
            className={`${css["button"]} ${css["button--add"]}`}
            style={{ padding: "0.25rem 0.5rem" }}
        >
            <FontAwesomeIcon icon={faPlus} />
            Ajouter une nouvelle formation externe
        </button>
    </>;
}

/** Props passed down to the {@link AddExternal} component. */
export interface AddExternalProps {
    /** Currently selected item. */
    selected: ResourceIdentifier | undefined;

    /** Callback invoked to select a new component. */
    select(item: ResourceIdentifier): void;
}
