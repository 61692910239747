/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext, useEffect } from "react";
// Import the store.
import { useRequirements, useSteps, useUsers } from "@andromeda/store";
// Import the login module.
import { LoginContext } from "@andromeda/login";
// Import the resources.
import type { Requirement, Step } from "@andromeda/resources";

// Import the subcomponents.
import UserSelector from "./user-selector";
import ItemSelector from "./item-selector";

// Import the css.
import css from "./index.module.scss";


/** Component used to select a user and an item from the list. */
export default function Selector(props: SelectorProps): ReactElement {
    // Load all the users and their requirements.
    const users = useUsers(useContext(LoginContext).organisations.current.id);
    const steps = useSteps(props.course);
    const requirements = useRequirements(props.course, users.data?.map(({ id }) => id));

    // Select the first user if it was undefined.
    useEffect(function selectFirstUserIfNeeded(): void {
        if (users.isSuccess && typeof props.user === "undefined") {
            props.setUser(users.data[0].id);
        }
    }, [props, users.data, users.isSuccess]);

    // Select the first item if it was undefined.
    useEffect(function selectFirstItemIfNeeded(): void {
        // Wait for the user to be set.
        if (typeof props.user === "undefined" || !requirements.isSuccess || !steps.data) {
            return;
        }

        // Check if the current item is required for the current user.
        const requirement = requirements.data.find(function findRequirementForUser(requirement: Requirement): boolean {
            // Check if the user matches the requirement.
            if (requirement.relationships.user.data.id !== props.user) {
                return false;
            }

            // Check if the relationship's step contains the current item.
            const step = steps.data.find(step => step.id === requirement.relationships.step.data.id);
            return !!step?.relationships.items.data.some(
                item => item.type === props.item?.type && item.id === props.item?.id
            );
        });
        if (requirement) {
            return;
        }

        // Load the first item for the current user.
        for (const step of steps.data) {
            // If the step is not required, ignore it.
            if (!requirements.data.some(requirement =>
                requirement.relationships.user.data.id === props.user
                && requirement.relationships.step.data.id === step.id
            )) {
                continue;
            }

            // Get the first valid item from the step.
            const firstItem = step.relationships.items.data.find(
                item => item.type === "zaq" || item.type === "external"
            );
            if (typeof firstItem !== "undefined") {
                props.setItem(firstItem);
                break;
            }
        }
    }, [props, requirements.data, requirements.isSuccess, steps.data, users.data, users.isSuccess]);

    return <div className={css["container"]}>
        <span className={css["title"]}>Utilisateur :</span>
        <UserSelector course={props.course} user={props.user} setUser={props.setUser} />
        <span className={css["separator"]} />
        <span className={css["title"]}>Élément :</span>
        <ItemSelector course={props.course} user={props.user} item={props.item} setItem={props.setItem} />
    </div>;
}

/** Props passed down to the {@link Selector} component. */
export interface SelectorProps {
    /** The identifier of the current course. */
    course: string;

    /** The identifier of the currently selected user. */
    user: string | undefined;

    /**
     * Callback invoked to choose a new user in the list.
     *
     * @param {string} user The user to choose.
     */
    setUser(user: string): void;

    /** The currently selected item. */
    item: Step.Item | undefined;

    /**
     * Callback invoked to choose a new item in the list.
     *
     * @param {Step.Item} item The item to choose.
     */
    setItem(item: Step.Item): void;
}
