/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the level icon.
import LevelIcon from "../level-icon";

// Import the icons.
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
// Import the css.
import css from "./footer.module.scss";


/** Component used to render the footer of the training status. */
export default function TrainingStatusFooter(props: TrainingStatusFooterProps): ReactElement {
    return <div className={classNames(css["list"], css[`list--${props.mode}`], props.className)}>
        <div className={css["row"]}>
            <span className={css["item"]}>
                <LevelIcon className={css["icon"]} level={1} time={0} />
                Novice
            </span>
            <span className={css["item"]}>
                <LevelIcon className={css["icon"]} level={2} time={0} />
                Apprenti
            </span>
            <span className={css["item"]}>
                <LevelIcon className={css["icon"]} level={3} time={0} />
                Maîtrise
            </span>
            <span className={css["item"]}>
                <LevelIcon className={css["icon"]} level={4} time={0} />
                Expert
            </span>
        </div>
        <div className={classNames(css["row"], css["row--current"])}>
            <span className={css["item"]}>
                <FontAwesomeIcon className={classNames(css["icon"], css["icon--danger"])} icon={faExclamationCircle} />
                En retard
            </span>
                <span className={css["item"]}>
                <FontAwesomeIcon className={classNames(css["icon"], css["icon--needs-validation"])} icon={faCheck} />
                À valider
            </span>
        </div>
        <div className={classNames(css["row"], css["row--validated"])}>
            <span className={css["item"]}>
                <FontAwesomeIcon className={classNames(css["icon"], css["icon--done"])} icon={faCheck} />
                Validé
            </span>
        </div>
    </div>
}

/** Props passed down to the {@link TrainingStatusFooter} component. */
export interface TrainingStatusFooterProps {
    /** The rendering mode of the footer. */
    mode: "current" | "validated" | "both";
    /** Optional class name added to the root <div> element. */
    className?: string;
}
