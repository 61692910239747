/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the schema compiler.
import { compileSync } from "@andromeda/validation";

// Import all the types.
import { AssetBase } from "./base";
import { ApplicationAsset } from "./application";
import { AudioAsset } from "./audio";
import { ImageAsset } from "./image";
import { FontAsset } from "./font";
import { ModelAsset } from "./model";
import { TextAsset } from "./text";
import { VideoAsset } from "./video";

// Import the asset schema.
import Schema from "../schema";

/** Union of all the possible asset types. */
export type Asset =
    | ApplicationAsset
    | AudioAsset
    | FontAsset
    | ImageAsset
    | ModelAsset
    | TextAsset
    | VideoAsset;

/** Namespace used to augment the {@link Asset} interface. */
export namespace Asset {
    export const Type = AssetBase.Type;
    export type Type = typeof Type;

    export const validate = compileSync<Asset>(Schema.ResourceSchema);

    export type Update =
        | ApplicationAsset.Update
        | AudioAsset.Update
        | FontAsset.Update
        | ImageAsset.Update
        | ModelAsset.Update
        | TextAsset.Update
        | VideoAsset.Update;
    export namespace Update {
        export const validate = compileSync<Update>(Schema.UpdateSchema);
    }

    export type Create =
        | ApplicationAsset.Create
        | AudioAsset.Create
        | FontAsset.Create
        | ImageAsset.Create
        | ModelAsset.Create
        | TextAsset.Create
        | VideoAsset.Create;
    export namespace Create {
        export const validate = compileSync<Create>(Schema.CreateSchema);
    }
}

// Re-export the subtypes.
export {
    ApplicationAsset,
    AudioAsset,
    FontAsset,
    ImageAsset,
    ModelAsset,
    TextAsset,
    VideoAsset,
};
