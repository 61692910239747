/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";

// Import the confirmation hook
import { useConfirm, ConfirmBodyProps } from "..";

// Import the css.
import css from "./confirm.module.scss";


/**
 * Helper hook used to render a warning confirmation modal.
 *
 * @param {string} text The text to render in the modal.
 * @returns {() => Promise<boolean>} An invocable callback that returns a boolean promise.
 */
export function useWarningConfirm(text: string): () => Promise<boolean> {
    return useConfirm({
        title: "Attention",
        centered: true,
        cancel: false,
        body: WarningConfirmBody
    });

    /** Internal component used to render the body of the confirmation modal. */
    function WarningConfirmBody(props: ConfirmBodyProps<boolean>): React.ReactElement {
        return <div className={css["confirm"]}>
            <p className={css["confirm__text"]} children={text} />
            <div className={css["confirm__buttons"]}>
                <button
                    className={`${css["confirm__button"]} ${css["confirm__button--validate"]}`}
                    onClick={() => props.resolve(true)}
                    children="Confirmer"
                />
                <button
                    className={`${css["confirm__button"]} ${css["confirm__button--cancel"]}`}
                    onClick={() => props.resolve(false)}
                    children="Annuler"
                />
            </div>
        </div>;
    }
}
