/*
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the button.
import { Button } from "../../../../index";
// Import the context.
import { PDFRenderModeContext } from "../../context";

// Import the icons.
import { fa1, faInfinity } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./mode-selector.module.scss";


/** Component used to render the mode selector element of the controller bar.  */
export default function ModeSelector(): ReactElement | null {
    // Load the controller context.
    const { mode, fixed, change } = useContext(PDFRenderModeContext);

    // If the rendering mode is fixed, do nothing.
    if (fixed) {
        return null;
    }

    /** Callback used to toggle the current rendering mode. */
    function toggle(): void {
        switch (mode) {
        case "continuous": return change("one-by-one");
        case "one-by-one": return change("continuous");
        }
    }

    // Render the component.
    return <div className={css["section"]}>
        <Button className={css["button"]} onClick={toggle}>
            <FontAwesomeIcon icon={mode === "continuous" ? faInfinity : fa1} className={css["button__icon"]} />
        </Button>
    </div>;
}
