/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the bootstrap components.
import { Form } from "react-bootstrap";

// Import the creator context.
import { UserCreatorContext } from "../context";
// Import the subcomponents.
import { User } from "./user";

// Import the icons.
import add from "@andromeda/assets/images/add.svg";
// Import the css.
import css from "./manual.module.scss";

/** Component used to add new user manually to the list of staged changes. */
export function Manual(): React.ReactElement {
    const context = React.useContext(UserCreatorContext);

    // Handle focus.
    const [ focused, setFocused ] = React.useState<number>(0);
    const [ list, setList ] = React.useState<HTMLUListElement | null>(null);
    React.useEffect(function updateFocus(): void {
        if (!list) return;
        list.querySelectorAll("input")?.[focused]?.focus({ preventScroll: false });
    }, [ list, focused ]);

    // Render all the staged users.
    const users = React.useMemo(function renderStaged(): React.ReactElement[] {
        const items: React.ReactElement[] = [];
        for (let i = 0; i < context.staged.length; i++) {
            items.push(<User index={i} key={i} changeFocusedIndex={setFocused} />);
        }
        return items;
    }, [context.staged.length]);

    // Callback invoked to add a new user.
    const addUser = React.useCallback(function addUser(ev: React.SyntheticEvent): void {
        ev.preventDefault();
        context.pushStaged({ email: "" });
        setFocused(context.staged.length - 1);
    }, [context]);

    // Render the components.
    return <Form className={css["manual"]} onSubmit={e => e.preventDefault()} validated>
        <h4>Entrez les adresses mail des utilisateurs à inviter</h4>
        <p>Vous pouvez coller plusieurs adresses mail en même temps.</p>
        <ul ref={setList} children={users} />
        <button className={css["manual__add"]} onClick={addUser}>
            <img src={add} alt="add" />
        </button>
    </Form>;
}
