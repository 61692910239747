/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useMemo } from "react";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the hooks.
import type { AnyTrainingProgress, RequiredStep } from "../../hooks";
// Import the subcomponents.
import TrainingStatusHeader from "./header";
import TrainingStatusFooter from "./footer";
import TrainingTitle from "./title";
import TrainingDueDate from "./date";
import TrainingLevel from "./level";
import TrainingStatusIcon from "./status";
import TrainingProgress from "./progress";

// Import the css.
import css from "./index.module.scss";


// Re-export the header.
export { TrainingStatusHeader, TrainingStatusFooter };

/** Component used to render the status of a given {@link ZaqTraining}. */
export default function TrainingStatus(props: TrainingStatusProps): ReactElement | null {
    // Build the flag offsets from the list of steps.
    const flagOffsets = useMemo(function buildFlagOffsets(): number[] {
        // Get the number of items in the entire training.
        const itemCount = props.progress.steps.reduce(function countItems(counter: number, info: RequiredStep): number {
            return counter + info.length;
        }, 0);

        // Build the list of flag offsets.
        let itemCounter = 0;
        const flagOffsets = new Array<number>(props.progress.steps.length - 1);
        for (let i = 0; i < props.progress.steps.length - 1; i++) {
            // Add the number of items to the counter.
            itemCounter += props.progress.steps[i].length;

            // Add the offset.
            flagOffsets[i] = Math.min(0.95, itemCounter / itemCount);
        }

        return flagOffsets;
    }, [props.progress.steps]);

    // Check if the training is past due.
    const isValidated = props.progress.validated;
    const isCompleted = !isValidated && props.progress.progress >= 1;
    const isPastDue = !isValidated && props.progress.due !== null && props.progress.due < new Date();

    // Build the class name of the status.
    const className = classNames({
        [css["item"]]: true,
        "zaq-training__training-status--validated": isValidated,
        "zaq-training__training-status--completed": isCompleted,
        "zaq-training__training-status--past-due": isPastDue
    });

    // Render the component.
    return <div className={className}>
        <TrainingTitle
            className={css["title"]}
            training={props.progress.training}
            user={props.progress.user}
            showUserName={Boolean(props.showUserName)}
        />
        <TrainingDueDate className={css["due-date"]} due={props.progress.due} />
        <TrainingLevel
            expected={props.progress.expected}
            expectedClassName={css["expected"]}
            achieved={props.progress.achieved}
            achievedClassName={css["achieved"]}
        />
        <TrainingStatusIcon className={css["status"]} />
        <TrainingProgress
            className={css["progress"]}
            progress={props.progress.progress}
            flagOffsets={flagOffsets}
        />
    </div>;
}

/** Props passed down to the {@link TrainingStatus} component. */
export interface TrainingStatusProps {
    /** The request that resolves with the progress of the training. */
    progress: AnyTrainingProgress;
    /** If true, shows the username rather than the training name. */
    showUserName?: boolean;
}
