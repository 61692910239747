/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";
// Import the store.
import { useDueDate } from "@andromeda/store";
// Import the resources.
import { DueDate } from "@andromeda/resources";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the contexts.
import { DueDateModalContext } from "../../../context";

// Import the css.
import css from "./due-date-cell.module.scss";


/** Component used to render the due-date of a given course. */
export default function DueDateCell(props: DueDateCellProps): ReactElement {
    // Load the due-date for the current cell.
    const { show } = useContext(DueDateModalContext);
    const dueDate = useDueDate(props.course, props.user);

    // Render the component.
    return <button className={css["button"]} onClick={() => show(props.course, props.user)}>
        <DueDateValue due-date={dueDate} />
    </button>;
}

/** Props passed down to the {@link DueDateCell} component. */
export interface DueDateCellProps {
    /** The id of the course to render. */
    course: string;
    /** The id of the user to render. */
    user: string;
}

/** Helper component used to render the value of a due date. */
function DueDateValue(props: DueDateValueProps): ReactElement {
    if (!props["due-date"].isSuccess) {
        return <Loader containerClassName={css["cell"]} />;
    }

    if (props["due-date"].data === null) {
        return <span className={css["value"]}>N/A</span>;
    }

    // Render the date.
    return <span className={css["value"]} children={DueDate.getDate(props["due-date"].data).toLocaleDateString()} />;
}

/** Props passed down to the {@link DueDateValue} component. */
interface DueDateValueProps {
    /** The request used to load the rendered due-date. */
    "due-date": RequestStatus<DueDate | null>;
}
