/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the search bar generator.
import { createSearchBar, SearchBarSource } from "@andromeda/components";
// Import the legacy zaq tools.
import type { FullLegacyZaq } from "@andromeda/legacy-zaq";


// Alias for the source of the notice search bar.
export type ZaqTutoSearchBarSource = SearchBarSource<FullLegacyZaq>;

// Search bar that can be used to find notices in the modal.
export const ZaqTutoSearchBar = createSearchBar<FullLegacyZaq>();
