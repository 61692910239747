/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
import { UserCreatorContext, UserCreationRejection } from "./context";


/** Helper hook used to validate an email address on an input. */
export function useValidateEmail(index: number): (element: HTMLInputElement) => boolean {
    // Get the user from the context.
    const user = React.useContext(UserCreatorContext).staged[index];
    return React.useCallback(function validateEmail(element: HTMLInputElement): boolean {
        const feedback = element.parentElement?.querySelector(".invalid-feedback") as HTMLElement;

        if (!element.checkValidity() && !element.validity.customError) {
            if (element.validity.valueMissing) updateFeedback("Vous devez spécifier une addresse mail !");
            else if (element.validity.typeMismatch) updateFeedback("Cette adresse mail n'est pas valide !");
            return false;
        } else if (!element.value.match(EMAIL_REGEX)) {
            element.setCustomValidity("invalid");
            updateFeedback("Cette adresse mail n'est pas valide !");
            return false;
        } else if (user.rejectionReason === UserCreationRejection.Reason.duplicateEmail) {
            element.setCustomValidity("disabled");
            updateFeedback("Cette adresse mail est déjà utilisée");
            return false;
        } else if (typeof user.rejectionReason !== "undefined") {
            element.setCustomValidity("generic-error");
            updateFeedback("Une erreur est survenue");
            return false;
        }
        element.setCustomValidity("");
        return true;

        function updateFeedback(text: string): void {
            if (feedback) feedback.innerText = text;
        }
    }, [user.rejectionReason]);
}

/**
 * RegExp used to validate email addresses.
 * Shamelessly stolen from {@link https://emailregex.com}
 */
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
