/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the validate function interface.
import { ValidateFunction } from "ajv";
// Import the json:api interfaces.
import { Resource, AnyLinks, Link } from "@andromeda/json-api";
// Import the schema compiler.
import { compileSync } from "@andromeda/validation";

// Import the schema.
import { PasswordResetSchema } from "./schema";


/** Interface used to represent a user. */
export interface PasswordReset extends Resource<PasswordReset.Type> {
    links: PasswordReset.Links;
}

export namespace PasswordReset {
    export const Type = "password-reset" as const;
    export type Type = typeof Type;

    export interface Links extends AnyLinks { related: Link; }

    export const validate: ValidateFunction<PasswordReset> = compileSync(PasswordResetSchema.ResourceSchema);
    export const Schema = PasswordResetSchema;
}
