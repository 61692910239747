/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useState } from "react";
// Import the css classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import Zod.
import type z from "zod";
// Import the resources.
import { ShareLink, UpdateShareLink, ShareLinkType } from "@andromeda/resources";
// Import the store.
import { ShareLinkStore, useResourceDispatch } from "@andromeda/store";
// Import the custom components.
import { useNotify } from "@andromeda/components";

// Import the subcomponents.
import LinkTitle from "./title";
import LinkActivity from "./activity";
import LinkExpiration from "./expiration";
import LinkButtons from "./buttons";

// Import the icons.
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
// Import the css.
import css from "./index.module.scss";


/** Type alias for the attributes of a {@link ShareLink}. */
type ShareLinkAttributes = Exclude<z.infer<typeof UpdateShareLink.shape.attributes>, undefined>;

/** Component used to render and edit a given {@link ShareLink}. */
export default function Link(props: LinkProps): ReactElement {
    // Get the notification tool.
    const { error } = useNotify();

    // State used to store the update promise.
    const [updatePromise, setUpdatePromise] = useState<Promise<unknown>>();

    // Callback used to update the link in the store.
    const dispatch = useResourceDispatch();
    const update = useCallback(
        function updateShareLink<K extends keyof ShareLinkAttributes, V extends ShareLinkAttributes[K]>(
            key: K,
            value: V
        ): void {
            // Build the share link update.
            const update: UpdateShareLink = {
                type: ShareLinkType,
                id: props.link.id,
                attributes: { [key]: value }
            };

            // Run the update.
            const updatePromise = dispatch(ShareLinkStore.generator.update(update)).catch(error);
            setUpdatePromise(async function queueUpdatePromise(prevState?: Promise<unknown>): Promise<void> {
                await prevState;
                await updatePromise;
                setUpdatePromise(undefined);
            });
        },
        [dispatch, error, props.link.id]
    );

    // Check if the link is active.
    const inactive = !props.link.attributes.active;
    const expired = isExpired(props.link.attributes.expires);

    // Build the class name of the link.
    const className = classNames({
        [css["link"]]: true,
        "share-link--expired": expired,
        "share-link--inactive": inactive
    });

    // Build the class name of the loader.
    const loaderClassName = classNames({
        [css["loader"]]: true,
        [css["loader--visible"]]: typeof updatePromise !== "undefined"
    })

    // Render the component.
    return <div className={className}>
        <FontAwesomeIcon className={loaderClassName} icon={faSpinner} />
        <LinkTitle
            className={css["title"]}
            defaultValue={props.link.attributes.name}
            onChange={title => update("name", title)}
        />
        <LinkActivity
            className={css["activity"]}
            state={props.link.attributes.active}
            onToggle={() => update("active", !props.link.attributes.active)}
        />
        <LinkExpiration
            className={css["expires"]}
            id={props.link.id}
            expires={props.link.attributes.expires}
            onChange={date => update("expires", date ? date.toISOString() : null)}
        />
        <LinkButtons
            className={css["buttons"]}
            id={props.id}
            link={props.link}
        />
    </div>;
}

/** Props passed down to the {@link Link} component. */
export interface LinkProps {
    /** The id of the current ZaqWiki. */
    id: string;
    /** Share link object being rendered. */
    link: ShareLink;
}

/**
 * Helper used to check if the given ISO date string is expired.
 *
 * @param {string | null} date The date to check.
 * @returns {boolean} True if the date is expired.
 */
function isExpired(date: string | null): boolean {
    // Check if the date is set.
    if (date === null) {
        return false;
    }

    // Check if the date is expired.
    return new Date() > new Date(date);
}
