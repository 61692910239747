/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement, ReactNode } from "react";
// Import the legacy-zaq helper tool.
import { useLegacyZaq } from "@andromeda/legacy-zaq";

// Import the item container.
import ItemContainer from "./item";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Step } from "@andromeda/resources";


/** Component used to render a ZaqTuto item on the screen. */
export default function TutoItem(props: TutoItemProps): ReactElement {
    // Load the zaq from the store.
    const zaq = useLegacyZaq(props.item.id);

    // Prepare the redirection callback.
    const navigate = useNavigate();
    const redirect = useCallback(function redirect(): void {
        navigate(`player/${props.item.id}?lite`);
    }, [navigate, props.item.id]);

    // Render the step.
    return <ItemContainer
        item={props.item}
        step={props.step}
        title={zaq.data?.name}
        icon={zaq.data?.icon?.url}
        loading={!zaq.isSuccess}
        kind="Tuto"
        onClick={redirect}
        children={props.children}
    />;
}

/** Props passed down to the {@link TutoItem} component. */
export interface TutoItemProps {
    /** Identifier of the tuto to render. */
    item: Step.Item;
    /** Identifier of the current step. */
    step: string;

    /** Children that should be rendered below the title of the item. */
    children?: ReactNode;
}
