/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the redux thunk dispatch.
import { ThunkDispatch } from "redux-thunk";
// Import the dispatch hook.
import { useDispatch, useStore } from "react-redux";
// Import the CRUD tuple helper.
import { CRUDTuple } from "@andromeda/resource-helper";
// Import the resource interface.
import { Resource } from "@andromeda/json-api";

// Import the reducer methods.
import {
    WithResourceStore,
    ResourceStore,
    buildResourceReducer
} from "./reducer";
// Import the CRUD actions.
import { CRUDAction } from "./actions";
// Import the selector hook.
import { useSelector } from "../../hooks";
// Import the reducer HOC.
import { withReducer } from "../reducer";


/** Helper method used to build a resource reducer HOC helper. */
export function buildResourceReducerHOC<R extends CRUDTuple, P extends object = object>(
    name: R[0]["type"]
): (component: React.ComponentType<P>) => React.ComponentType<P> {
    return function withResourceReducer(component) {
        return withReducer(component, name, buildResourceReducer<R>(name));
    };
}

/** Interface used to describe the first parameter of the useSelector hook. */
export interface Selector<R extends Resource, Return> {
    (state: ResourceStore<R>, store: any): Return;
}

/** Interface used to describe the second parameter of the useSelector hook. */
export interface Comparator<Return> {
    (a: Return, b: Return): boolean;
}

/** Interface used to describe the useSelector hook. */
export interface UseSelector<R extends Resource> {
    (): ResourceStore<R>;

    <Return>(
        selector: Selector<R, Return>,
        comparator?: Comparator<Return>
    ): Return;
}

/** Helper method used to build a resource selector. */
export function buildUseResourceSelector<R extends Resource>(
    name: R["type"]
): UseSelector<R> {
    return function useResourceSelector<Return>(
        selector?: Selector<R, Return>,
        comparator?: Comparator<Return>
    ): ResourceStore<R> | Return {
        if (typeof selector === "function") {
            return useSelector<ResourceStore<R>, Return>(
                name,
                selector,
                comparator
            );
        } else {
            return useSelector<ResourceStore<R>>(name);
        }
    };
}

/** Helper method used to build a resource dispatch. */
export function buildUseResourceDispatch<
    R extends CRUDTuple
>(): () => ThunkDispatch<WithResourceStore<R[0]>, never, CRUDAction<R>> {
    return function useResourceDispatch() {
        return useDispatch();
    };
}

/** Checks if the provided store (or stores) are saving. */
export function useIsSaving(watch: StoreReference): boolean {
    // Get a reference to the store.
    const store =
        useStore<Record<string, ResourceStore<Resource>>>().getState();

    // Get a list of all the stores to watch, and their keys.
    const watched = React.useMemo(
        function findStoresToWatch(): Map<
            string,
            WatchedState[]
        > {
            // Check the type of the reference.
            const watched = new Map<string, WatchedState[]>();
            if (Array.isArray(watch)) {
                for (const item of watch) {
                    if (typeof item === "string") {
                        findWatchedStore(item);
                    } else {
                        findWatchedStore(item.name, item.keys);
                    }
                }
            } else {
                if (typeof watch === "string") {
                    findWatchedStore(watch);
                } else {
                    findWatchedStore(watch.name, watch.keys);
                }
            }
            return watched;

            function findWatchedStore(
                name: string,
                keys: WatchedState | WatchedState[] = [
                    "creating",
                    "reading",
                    "updating",
                    "deleting"
                ]
            ): void {
                watched.set(name, Array.isArray(keys) ? keys : [keys]);
            }
        },
        []
    );

    // Check the state of all the watched stores.
    return React.useMemo(
        function checkWatchedStores(): boolean {
            for (const [name, keys] of watched.entries()) {
                if (!(name in store)) {
                    continue;
                }
                for (const key of keys) {
                    if (store[name][key]) {
                        return true;
                    }
                }
            }
            return false;
        },
        [store, watched]
    );
}

/** List of keys that can be watched throguh a {@link WatchedStore}. */
type WatchedState = "creating" | "reading" | "updating" | "deleting";

/** Interface used to describe how to watch a given store. */
interface WatchedStore {
    /** The name of the watched store. */
    name: string;
    /** The key(s) to watch in the store. */
    keys?: WatchedState | WatchedState[];
}

/** Store reference used by {@link useIsSaving}. */
type StoreReference = string | string[] | WatchedStore | WatchedStore[];
