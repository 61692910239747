/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the resources.
import { Step, Validation } from "@andromeda/resources";

// Import the action type.
import type { ValidationActions } from "./actions";


/** Helper object used for the {@link ValidationReducerState}. */
interface ValidationIdentifier {
    /** The identifier of the user that is being validated. */
    user: string;
    /** The item that is being validated. */
    item: Step.Item;
}

/** State of the {@link validationReducer}. */
export interface ValidationReducerState {
    /** List of all the validations found in the store. */
    validations: [ValidationIdentifier, RequestStatus<Validation.Create>][];
    /** Flag that is set if the validations of the store were modified. */
    modified: boolean;
    /** Saving status of the store. */
    saving: RequestStatus | undefined;
}

/** Key used to mount the {@link validationReducer} in the store. */
export const VALIDATION_REDUCER_KEY = "validation-reducer";

/** Alias for the store with the {@link validationReducer} mounted. */
export interface ValidationReducerStore {
    [VALIDATION_REDUCER_KEY]: ValidationReducerState;
}

/** Default state of the {@link validationReducer}. */
const DEFAULT_STATE: ValidationReducerState = { saving: undefined, validations: [], modified: false };

/**
 * Reducer used to update the state of the validations.
 *
 * @param {ValidationReducerState} [currentState=DEFAULT_STATE] The current state of the reducer.
 * @param {ValidationActions} action The action that was dispatched to the store.
 * @return {ValidationReducerState} The new state of the reducer.
 */
export function validationReducer(
    currentState: ValidationReducerState = DEFAULT_STATE,
    action: ValidationActions
): ValidationReducerState {
    switch (action.type) {
    case "validation/update": {
        // Check if the validation exists in the store.
        const existing = currentState.validations.findIndex(([id]) =>
            id.item.type === action.item.type
            && id.item.id === action.item.id
            && id.user === action.user
        );
        if (existing >= 0) {
            // Update the validation.
            currentState.validations[existing] = [
                { item: action.item, user: action.user },
                RequestStatus.success(action.payload)
            ];
        }

        return { ...currentState, validations: [...currentState.validations], modified: true };
    }
    case "validation/update-request": {
        // Check if the validation exists in the store.
        const existing = currentState.validations.findIndex(([id]) =>
            id.item.type === action.item.type
            && id.item.id === action.item.id
            && id.user === action.user
        );
        if (existing >= 0) {
            // Update the validation.
            currentState.validations[existing] = [{ item: action.item, user: action.user }, action.payload];
        } else {
            // Create a new validation.
            currentState.validations.push([{ item: action.item, user: action.user }, action.payload]);
        }
        return { ...currentState, validations: [...currentState.validations] };
    }

    case "validation/save":
        // Clear the list of validations.
        if (action.payload.isLoading) {
            currentState.validations = [];
        } else if (action.payload.isSuccess || action.payload.isError) {
            return { ...currentState, saving: undefined };
        }
        return { ...currentState, saving: action.payload, modified: false };

    default:
        return currentState;
    }
}
