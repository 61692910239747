/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";
// Import the image asset types.
import { ImageAssetMimeType, ImageAssetMetadata } from "@andromeda/asset-conversion";

// Import the job interface.
import { Job } from "./job";

// Import the css.
import css from "./index.module.scss";


/** Component used to parse a given image file. */
export function ImageParser(props: ImageParserProps): React.ReactElement {
    // Store the file url.
    const [ url, setURL ] = React.useState<string>();
    React.useEffect(function createImageURL(): () => void {
        const url = URL.createObjectURL(props.job.file);
        setURL(url); return () => { URL.revokeObjectURL(url); setURL(undefined); };
    }, [props.job.file]);

    // Resolves the job with the file's metadata.
    const resolve = React.useCallback(
        function resolveImageMetadata(event: React.SyntheticEvent<HTMLImageElement>): void {
            const image = event.currentTarget;

            // Compute the attributes of the video.
            const attributes: ImageAssetMetadata = {
                contentType: props.job.file.type as ImageAssetMimeType,
                width: image.naturalWidth, height: image.naturalHeight
            };
            props.job.resolve(attributes);
        },
        [ props.job ]
    );

    // Rejects the job.
    const reject = React.useCallback(function rejectVideoFile(event: React.SyntheticEvent<HTMLImageElement>): void {
        props.job.reject(event);
    }, [props.job]);

    // Render the component.
    return <img
        className={css["parser"]} alt="file-parser" src={url}
        onLoad={resolve} onLoadedMetadata={resolve}
        onLoadedData={resolve} onError={reject}
    />;
}

/** Props passed down to the {@link VideoParser} component. */
interface ImageParserProps {
    /** The job to run on this parser. */
    job: Job;
}
