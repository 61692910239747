/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import lodash.
import { isEqual } from "lodash";
// Import the JSON:API module.
import type { Relationship, Resource } from "@andromeda/json-api";


/** Helper used to compare two resource lists. */
export default function compareLists(previous: Resource[] | undefined, next: Resource[] | undefined): boolean {
    if (typeof previous === "undefined" || typeof next === "undefined") {
        return previous === next;
    }
    if (previous.length !== next.length) {
        return false;
    }

    // Compare all the resources.
    return previous.every(function areResourceIdentical(resource: Resource, index: number): boolean {
        const other = next[index];

        // Do a simple object comparison.
        if (resource === other) {
            return true;
        }

        // Compare the type and id.
        if (resource.type !== other.type || resource.id !== other.id) {
            return false;
        }

        // Compare the attributes.
        if (!isEqual(resource.attributes, other.attributes)) {
            return false;
        }

        // Compare the relationships.
        if (resource.relationships && other.relationships) {
            // Check if both relationships have the same key set.
            if (!isEqual(Object.keys(resource.relationships), Object.keys(other.relationships))) {
                return false;
            }

            // Compare the key sets.
            for (const key in resource.relationships) {
                const rel = (resource.relationships as Record<string, Relationship>)[key];
                const otherRel = (other.relationships as Record<string, Relationship | undefined>)[key];
                if (!otherRel || !compareRelationship(rel, otherRel)) {
                    return false;
                }
            }
        }
        return true;
    });
}

/** Compares two relationships together. */
function compareRelationship(a: Relationship, b: Relationship): boolean {
    if (a.data === b.data) {
        return true;
    } else if (Array.isArray(a.data) && Array.isArray(b.data)) {
        for (let i = 0; i < a.data.length; i++){
            if (a.data[i].type !== b.data[i]?.type || a.data[i].id !== b.data[i]?.id) {
                return false;
            }
        }
        return true;
    } else if (!Array.isArray(a.data) && !Array.isArray(b.data)) {
        if (a.data === null && b.data === null) {
            return true;
        }
        return a.data?.type === b.data?.type && a.data?.id === b.data?.id;
    }
    return false;
}
