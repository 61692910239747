/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useState } from "react";

// Import the local hooks.
import type { SearchFilter } from "../../../../hooks";
// Import the selector.
import Selector, { Option } from "../selector";
import FavouriteList from "./list";

// Import the css.
import css from "./index.module.scss";


/** Page used to render the favourites of the current user. */
export default function FavouritesPage(): ReactElement {
    // Store the currently selected favourite item filter.
    const [filter, setFilter] = useState<SearchFilter>("all");

    // Render the component.
    return <div className={css["favourites"]}>
        <Selector<SearchFilter> options={FAVOURITE_FILTERS} current={filter} onChange={setFilter} />
        <FavouriteList filter={filter} />
    </div>;
}

/** List of all the favourite types available. */
const FAVOURITE_FILTERS: readonly Option<SearchFilter>[] = [
    { value: "all", name: "Tout" },
    { value: "zaq-tuto", name: "Tuto" },
    { value: "zaq-wiki", name: "Wiki" },
    { value: "course", name: "Training" },
] as const;
