/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the context creator.
import { createContext } from "react";
// Import the context noop tool.
import { makeContextNoop } from "@andromeda/tools";


/** Interface that will be exposed by the card container. */
export interface CardContainerContext {
    /** Index of the card that should be rendered currently. */
    current: number;
    /** Number of cards in this context. */
    count: number;

    /**
     * Callback used to register a new card to the list.
     *
     * @param {(index: number) => void} callback A callback to invoke once the index is available.
     */
    register(callback: (index: number) => void): void;

    /**
     * Un-registers the card from the callback list.
     */
    unregister(): void;

    /**
     * Selects the card at the given index.
     *
     * @param {number} index The new index to select.
     */
    select(index: number): void;
}

// Default state of the context.
const DEFAULT_STATE: CardContainerContext = {
    current: Number.NaN, count: 0,
    register: makeContextNoop("CardContainerContext", "register"),
    unregister: makeContextNoop("CardContainerContext", "unregister"),
    select: makeContextNoop("CardContainerContext", "select"),
};

/** Context that should be used to access and interact with the card container. */
export const CardContainerContext = createContext<CardContainerContext>(DEFAULT_STATE);
CardContainerContext.displayName = "CardContainerContext";
