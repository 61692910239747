/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the legacy resource interfaces.
import {
    LegacyRelationship,
    LegacyResource,
    LegacyResourceIdentifier,
    WithLegacyRelationships
} from "../interfaces";


/**
 * Checks if the given object is a valid {@link LegacyResource} object.
 *
 * @template {string} T
 * @template {string} I
 * @param object The object to check.
 * @param {T} [type=undefined] If provided, the expected type of the object.
 * @param {I} [id=undefined] If provided, the expected id of the object.
 * @return {object is LegacyResource<T, I>} True if the object is a valid {@link LegacyResource}.
 */
export function isValidLegacyResource<T extends string = string, I extends string = string>(
    object: unknown,
    type?: T,
    id?: I
): object is LegacyResource<T, I> {
    // Check if the object is, indeed, an object.
    if (typeof object !== "object" || object === null) {
        return false;
    }

    // Check if the object has a valid type property.
    if (!("type" in object) || typeof object.type !== "string") {
        return false;
    }
    if (type && object.type !== type) {
        return false;
    }

    // Check if the object has a valid id property.
    if (!("id" in object) || typeof object.id !== "string") {
        return false;
    }
    if (id && object.id !== id) {
        return false;
    }

    // Check if the object has a valid attributes property.
    if ("attributes" in object && (typeof object.attributes !== "object" || object.attributes === null)) {
        return false;
    }

    // Check if the object has a valid "relationships" property.
    return hasValidRelationshipsProperty(object);
}

/**
 * Checks if the given object has a valid "relationships" property.
 *
 * @template {object} T
 * @param {T} object The object to check.
 * @return {object is WithLegacyRelationships<T>} True if the object has a valid "relationships" property.
 */
export function hasValidRelationshipsProperty<T extends object>(object: T): object is WithLegacyRelationships<T> {
    // Check if the object has a "relationships" property.
    if (!("relationships" in object)) {
        return true;
    }

    // Check the type of the property.
    if (typeof object.relationships !== "object" || object.relationships === null) {
        return false;
    }

    // Check all the properties one by one.
    for (const property in object.relationships) {
        if (!hasValidRelationship(object, property)) {
            return false;
        }
    }
    return true;
}

/**
 * Helper method used to validate that a given relationship is valid.
 *
 * @template {LegacyRelationships} T
 * @template {string} K
 * @param {T} object The object to check for.
 * @param {K} relationship The relationship to validate.
 * @param {true} asArray If true, the data of the relationship should be an array.
 * @return {object is WithLegacyRelationships<T, K>} True if the relationship exists and is valid.
 */
export function hasValidRelationship<T extends object, K extends string>(
    object: T,
    relationship: K,
    asArray?: true
): object is T & { relationships: { [P in K]: { data: LegacyResourceIdentifier[] } } };

/**
 * Helper method used to validate that a given relationship is valid.
 *
 * @template {LegacyRelationships} T
 * @template {string} K
 * @param {T} object The object to check for.
 * @param {K} relationship The relationship to validate.
 * @param {true} asArray If true, the data of the relationship should be an array.
 * @return {object is WithLegacyRelationships<T, K>} True if the relationship exists and is valid.
 */
export function hasValidRelationship<T extends object, K extends string>(
    object: T,
    relationship: K,
    asArray?: false
): object is T & { relationships: { [P in K]: { data: LegacyResourceIdentifier | null } } };

/** Implementation */
export function hasValidRelationship(object: object, relationship: string, asArray?: boolean): boolean {
    // Check if the relationship exists in the object.
    if (!("relationships" in object) || typeof object.relationships !== "object" || object.relationships === null) {
        return false;
    }
    if (!(relationship in object.relationships)) {
        return false;
    }

    // Check if the property is valid.
    if (!isLegacyRelationship((object.relationships as Record<string, unknown>)[relationship])) {
        return false;
    }
    const value = (object.relationships as { [K: typeof relationship]: LegacyRelationship })[relationship];
    if (asArray === true) {
        if (!Array.isArray(value?.data)) {
            return false;
        }
    } else if (asArray === false) {
        if (Array.isArray(value?.data)) {
            return false;
        }
    }

    return true;
}

/**
 * Checks if the given item is a legacy relationship object.
 *
 * @param item The item to check.
 * @return {item is Relationship | null} True if the item is a valid relationship object.
 */
function isLegacyRelationship(item: unknown): item is LegacyRelationship {
    // Check if the item has a data property.
    if (typeof item !== "object") {
        return false;
    }
    if (item === null) {
        return true;
    }

    // Check if the item has a data property.
    if (!("data" in item)) {
        return false;
    }
    if (typeof item.data !== "object") {
        return false;
    }
    if (item.data === null) {
        return true;
    }

    // Check the identifiers of the data.
    return Array.isArray(item.data) ? item.data.every(checkRelationshipObject) : checkRelationshipObject(item.data);

    /** Checks if the given relationship object is valid. */
    function checkRelationshipObject(object: unknown): object is LegacyResourceIdentifier {
        if (typeof object !== "object" || object === null) {
            return false;
        }

        // Check if the object has a type and id.
        if (!("type" in object) || typeof object.type !== "string") {
            return false;
        }
        if (!("id" in object) || (typeof object.id !== "string" && object.id !== null)) {
            return false;
        }
        // Check if the object has a valid meta property.
        return !("meta" in object) || (typeof object.meta === "object" && object.meta !== null);
    }
}
