/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the xhr response interface.
import { XhrResponse } from "@andromeda/xhr";

/**
 * Error thrown if a delete operation failed.
 * Thrown by the Message constructor.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class DeletionFailedError extends Error {
    /** The status code of the failed response. */
    public status: number;
    /** The body of the failed response. */
    public body: string | undefined;

    /**
     * Class constructor.
     * Decodes the invalid source and generates a new error message.
     *
     * @param {XhrResponse} source The bad source.
     */
    public constructor(source: XhrResponse) {
        // Call the super constructor.
        super(`Failed to delete a resource. Got status code ${source.status}`);

        // Decode the body of the message.
        this.body = undefined;
        if (typeof source.body === "string") this.body = source.body;
        if (typeof source.body === "object")
            this.body = JSON.stringify(source.body);
        this.status = source.status;
    }
}
