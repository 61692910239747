/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback } from "react";
// Import Redux.
import { useDispatch } from "react-redux";
import type { ThunkDispatch } from "redux-thunk";
// Import the legacy ZaqTuto tools.
import { deleteZaq, LegacyZaq, LegacyZaqReducerStore, RequestZaqAction, useLegacyZaq } from "@andromeda/legacy-zaq";
// Import the custom components.
import { useDeleteConfirm, useNotify } from "@andromeda/components";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";

// Import the hooks.
import { useCanDeleteZaqTuto, useCanEditZaqTuto } from "../../hooks";
// Import the item renderer.
import ItemRenderer from "./item";
// Import the icon renderer.
import ZaqTutoIcon from "./icons/tuto";


/** Component used to render a ZaqTuto item. */
export default function ZaqTutoItem(props: ZaqTutoItemProps): ReactElement | null {
    // Check if the user can edit/delete the tuto.
    const canEdit = useCanEditZaqTuto(props.resource.id) && !!props.withButtons;
    const canDelete = useCanDeleteZaqTuto(props.resource.id) && !!props.withButtons;

    // Get the notification tool.
    const { fatal } = useNotify();
    // Get the legacy ZaqTuto dispatch.
    const dispatch = useDispatch<ThunkDispatch<LegacyZaqReducerStore, never, RequestZaqAction>>();
    // Get the deletion confirmation tool.
    const confirmDeletion = useDeleteConfirm("Êtes vous sûr de vouloir supprimer ce ZaqTuto ?");

    // Callback used to delete the ZaqTuto.
    const deleteTuto = useCallback(function deleteCurrentZaqTuto(): void {
        confirmDeletion().then(function deleteZaqWiki(canDelete: boolean): void | Promise<void> {
            if (canDelete) {
                return dispatch(deleteZaq(props.resource.id));
            }
        }).catch(fatal);
    }, [confirmDeletion, dispatch, fatal, props.resource.id]);

    // Render the item.
    return <ItemRenderer
        resource={props.resource}
        icon={ZaqTutoIcon}
        iconProps={{ id: props.resource.id, icon: props.resource.icon?.id }}
        title={props.resource.name}
        description=""
        tags={props.resource.tags}
        play={`/zaq-tuto/player/${props.resource.id}?lite`}
        edit={canEdit ? `/editor/${props.resource.id}` : undefined}
        delete={canDelete ? deleteTuto : undefined}
    />;
}

/** Props passed down to the {@link ZaqTutoItem} component. */
export interface ZaqTutoItemProps {
    /** ZaqTuto resource being rendered. */
    resource: LegacyZaq;
    /** Flag set if the edit and delete button should be shown. */
    withButtons?: boolean;
}

/**
 * Wrapper component used to download the ZaqTuto item from the store,
 * before rendering the {@link ZaqTutoItem}.
 */
export function ZaqTutoItemWrapper(props: ZaqTutoItemWrapperProps): ReactElement | null {
    // Load the tuto from the store.
    const tuto = useLegacyZaq(props.id);

    // Render nothing if the loading failed.
    if (tuto.isError) {
        return null;
    }

    // Render a loader while the course is loading.
    if (!tuto.isSuccess) {
        return <Loader containerClassName={props.loaderClassName} text="Chargement du ZaqTuto ..." />;
    }

    // Render the item.
    return <ZaqTutoItem {...props} resource={tuto.data} />;
}

/** Props passed down to the {@link ZaqTutoItemWrapper} component. */
export interface ZaqTutoItemWrapperProps extends Omit<ZaqTutoItemProps, "resource"> {
    /** Identifier of the ZaqTuto to render. */
    id: string;
    /** CSS class name added to the loader container. */
    loaderClassName?: string;
}
