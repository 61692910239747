/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the file interface.
import { ZaqWikiFile, ZaqWiki, Asset } from "@andromeda/resources";
import { makeContextNoop } from "@andromeda/tools";

/** State of the {@link FileContext}. */
export interface FileContext {
    /** File being rendered. */
    file: ZaqWikiFile;

    /** If true, render the full file. */
    expanded: boolean;
    /** True if the file was opened. */
    opened: boolean;
    /** True if the file was seen. */
    seen: boolean;

    /** Toggles the value of the {@link expanded} flag. */
    toggleExpanded(): void;
    /** Marks the given file as opened. */
    markAsOpened(): void;
    /** Marks the given file as seen. */
    markAsSeen(): void;
}

// Default state of the file context.
const DEFAULT_STATE: FileContext = {
    file: {
        type: ZaqWikiFile.Type,
        id: "__invalid",
        attributes: { name: "__invalid" },
        relationships: {
            wiki: { data: { type: ZaqWiki.Type, id: "__invalid" } },
            asset: { data: { type: Asset.Type, id: "__invalid" } },
        },
        links: { href: "https://example.com/" },
    },
    expanded: false, opened: false, seen: false,
    toggleExpanded: makeContextNoop("FileContext", "toggleExpanded"),
    markAsOpened: makeContextNoop("FileContext", "markAsOpened"),
    markAsSeen: makeContextNoop("FileContext", "markAsSeen"),
};

/** Helper context used to provide easy access to a file. */
export const FileContext = React.createContext<FileContext>(DEFAULT_STATE);
