/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useContext, ReactElement } from "react";

// Import the contexts.
import { EditorSwitchContext } from "../../../../context";
// Import the subcomponents.
import AddItemMenu from "./add-item";

// Import the css.
import css from "./index.module.scss";
import AssignRequirement from "./assign-requirement";


export default function EditMenu(props: EditMenuProps): ReactElement | null {
    const { state: editing } = useContext(EditorSwitchContext);

    if (!editing) {
        return null;
    }
    return <div className={css["container"]}>
        <div className={css["menu"]}>
            <AddItemMenu
                show={props.mode === "item"}
                step={props.step}
                cancel={() => props.changeMode("requirement")}
            />
            <AssignRequirement
                course={props.course}
                step={props.step}
                show={props.mode === "requirement"}
            />
        </div>
    </div>;
}

export interface EditMenuProps {
    course: string;
    step: string;
    mode: "item" | "requirement";

    changeMode(mode: "item" | "requirement"): void;
}
