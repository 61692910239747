/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the link schema.
import LinkSchema from "./link";

/** Schema used to define a list of links. */
const Schema = {
    title: "Links object",
    description: "List of links relating to the resource.",
    type: "object",
    additionalProperties: LinkSchema
} as const;

// Export the schema.
export default Schema;
