/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useMemo, useState } from "react";
// Import the fontawesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the Button component.
import { Button } from "../../../../index";
// Import all the subcomponents.
import PageSelector from "./page-selector";
import Rotate from "./rotate";
import Scale from "./scale";
import ModeSelector from "./mode-selector";
import Fullscreen from "./fullscreen";

// Import the icons.
import { faXmark, faGears } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./index.module.scss";


/** Component used to render the pdf controller. */
export default function PDFToolbar(props: PdfControllerProps): ReactElement | null {
    // Check if the component is fully displayed or not.
    const [show, setShow] = useState(false);
    const elements = useMemo(function renderControllerElements(): ReactElement[] {
        if (show) {
            return [
                <PageSelector key="page-selector" />,
                <Rotate key="rotate" />,
                <Scale key="scale" />,
                <ModeSelector key="mode-selector" />,
                <Fullscreen key="fullscreen" />,
            ];
        } else {
            return [];
        }
    }, [show]);

    // Compute the class name.
    let className = css["toolbar"];
    if (show) {
        className += ` ${css["toolbar--open"]}`;
    } else {
        className += ` ${css["toolbar--closed"]}`;
    }

    if (props.className) {
        className += ` ${props.className}`;
    }

    // Render the component.
    return <div className={className}>
        <Button className={css["button"]} onClick={() => setShow(!show)}>
            <FontAwesomeIcon icon={show ? faXmark : faGears} className={css["button__icon"]} />
        </Button>
        {elements}
    </div>;
}

/** Props passed down to the controller. */
export interface PdfControllerProps {
    /** The class name to add to the controller. */
    className?: string;
}
