/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the resource interface.
import {
    Resource,
    MessageHelper,
    SingleMessageHelper,
} from "@andromeda/json-api";
// Import the Xhr package.
import { Xhr } from "@andromeda/xhr";
// Import the validation method.
import { validate } from "@andromeda/validation";

// Import the read options interface.
import { ReadOneOptions } from "./interfaces";
// Import the helper methods.
import {
    buildParams,
    extractUrl,
    parseValidatorAndOptions,
    PromisableValidateFunction,
    buildHeaders,
} from "./common";
// Import the error class.
import { SourceIsEmptyError } from "../../errors";

/**
 * Extension of the {@link ReadOneOptions} with an enforced url parameter.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export interface WithUrl extends ReadOneOptions {
    /** @inheritDoc */
    url: string;
}

/**
 * Reads a single resource from the server.
 * Seeks the target from the configuration.
 *
 * @template {string} T
 * The type of the requested resource.
 * @template {Resource<T>} R
 * The returned resource type.
 * @param {T} type The type of the requested resource.
 * @param {string} id The id of the requested resource.
 * @param {ReadOneOptions | undefined} [options=undefined] Additional read options.
 * @returns {Promise<SingleMessageHelper<R>>}
 * A promise that resolves with a message object containing the requested resource.
 */
export function readOne<T extends string>(
    type: T,
    id: string,
    options?: ReadOneOptions
): Promise<SingleMessageHelper<Resource<T>>>;

/**
 * Reads a single resource from the server, via the given link.
 *
 * @template {string} T
 * The type of the requested resource.
 * @template {Resource<T>} R
 * The returned resource type.
 * @param {T} type The type of the requested resource.
 * @param {} [options=undefined] Additional read options.
 * @returns {Promise<SingleMessageHelper<R>>}
 * A promise that resolves with a message object containing the requested resource.
 */
export function readOne<T extends string>(
    type: T,
    options: WithUrl
): Promise<SingleMessageHelper<Resource<T>>>;

/**
 * Reads a single resource from the server.
 * Validates the received object.
 * Seeks the target from the configuration.
 *
 * @template {string} T
 * The type of the requested resource.
 * @template {Resource<T>} R
 * The returned resource type.
 * @param {T} type The type of the requested resource.
 * @param {string} id The id of the requested resource.
 * @param {PromisableValidateFunction<R>} validate
 * The validation function used to check the received resource.
 * @param {ReadOneOptions | undefined} [options=undefined] Additional read options.
 * @returns {Promise<SingleMessageHelper<R>>}
 * A promise that resolves with a message object containing the requested resource.
 */
export function readOne<T extends string, R extends Resource<T>>(
    type: T,
    id: string,
    validate: PromisableValidateFunction<R>,
    options?: ReadOneOptions
): Promise<SingleMessageHelper<R>>;

/**
 * Reads a single resource from the server, via the given link.
 * Validates the received object.
 *
 * @template {string} T
 * The type of the requested resource.
 * @template {Resource<T>} R
 * The returned resource type.
 * @param {T} type The type of the requested resource.
 * @param {PromisableValidateFunction<R>} validate
 * The validation function used to check the received resource.
 * @param {WithUrl} options Additional read options.
 * @returns {Promise<SingleMessageHelper<R>>}
 *  A promise that resolves with a message object containing the requested resource.
 */
export function readOne<T extends string, R extends Resource<T>>(
    type: T,
    validate: PromisableValidateFunction<R>,
    options: WithUrl
): Promise<SingleMessageHelper<R>>;

/** Implementation ! */
export async function readOne<T extends string, R extends Resource<T>>(
    type: T,
    idOrValidatorOrOptions:
        | string
        | PromisableValidateFunction<R>
        | ReadOneOptions,
    validatorOrOptions?:
        | PromisableValidateFunction<R>
        | ReadOneOptions
        | WithUrl,
    readOptions?: ReadOneOptions | WithUrl
): Promise<SingleMessageHelper<R>> {
    let validator: PromisableValidateFunction<R> | undefined;
    let options: ReadOneOptions | undefined;
    let id: string | undefined = undefined;

    // Parse the parameters.
    if (typeof idOrValidatorOrOptions === "string") {
        id = idOrValidatorOrOptions;
        const parsed = parseValidatorAndOptions(
            validatorOrOptions,
            readOptions
        );
        validator = parsed.validator;
        options = parsed.options;
    } else {
        const parsed = parseValidatorAndOptions(
            idOrValidatorOrOptions,
            validatorOrOptions as ReadOneOptions | undefined
        );
        validator = parsed.validator;
        options = parsed.options;
    }

    // Prepare the options.
    const url: string = extractUrl(type, options);
    const searchParameters = buildParams(options);
    const headers = buildHeaders(options);

    // Run the request.
    const response = await Xhr.get(url, {
        searchParameters,
        path: options?.path ?? `/${type}/${id}`,
        headers,
    });
    if (
        response.body instanceof MessageHelper &&
        !Array.isArray(response.body.data)
    ) {
        // Validate the response.
        if (response.body.data !== null && typeof validator !== "undefined") {
            validate(response.body.data, await validator, true);
        }

        return response.body as SingleMessageHelper<R>;
    } else {
        throw new SourceIsEmptyError();
    }
}
