/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, AriaAttributes, useMemo, createElement, SyntheticEvent, FocusEventHandler } from "react";


/** Simple component used to render the title of the heading. */
export default function BoxHeaderTitle(props: InternalBoxHeaderTitleProps): ReactElement {
    // Render the title component.
    const readOnly = useMemo(function renderReadOnlyTitle(): ReactElement {
        return createElement(
            `h${props.headingLevel ?? 2}`,
            { className: props.className, children: props.title }
        );
    }, [props.className, props.headingLevel, props.title]);

    // Render the editable title component.
    const editable = useMemo(function renderEditableTitle(): ReactElement {
        return <input
            className={props.className}
            onClickCapture={(ev: SyntheticEvent) => {
                ev.stopPropagation();
            }}
            role="heading"
            placeholder="Donnez moi un titre ..."
            aria-level={props.headingLevel ?? 2}
            defaultValue={props.title ?? ""}
            onBlur={props.onBlur}
            maxLength={props.maxLength}
        />;
    }, [props.className, props.headingLevel, props.maxLength, props.onBlur, props.title]);

    // Select the appropriate element to render.
    return props.editable ? editable : readOnly;
}

/** Props passed down to the {@link BoxHeaderTitle} component. */
export interface BoxHeaderTitleProps {
    /** The title of the header. */
    title?: string;
    /**
     * The heading level used for the header text.
     * @default 2
     */
    headingLevel?: AriaAttributes["aria-level"];
    /** Max length of the title. */
    maxLength?: number;
}

/** Internally expanded version of the {@link BoxHeaderTitleProps}. */
interface InternalBoxHeaderTitleProps extends BoxHeaderTitleProps {
    /** Class name applied to the title component. */
    className: string;
    /** Flag that should be set if the title can be edited. */
    editable: boolean;
    /** Callback attached to the {@code "onBlur"} event of the title element. */
    onBlur?: FocusEventHandler<HTMLInputElement>;
}
