/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the custom interfaces.
import {
    Action,
    Extra,
    FullLegacyZaq,
    LegacyResource,
    LegacyZaqAsset,
    LegacyZaqAssetType,
    LegacyZaqTranslationType,
    LegacyZaqType,
    MajorStep,
    MinorStep,
    Timeline
} from "../interfaces";
// Import the resource tools.
import { hasValidRelationship } from "./legacy-resource";
// Import the query tools.
import { getData, getIncluded } from "./message-parser";


/**
 * Parses a legacy Zaq from a request.
 *
 * @param {string} id The identifier of the requested Zaq.
 * @param {object} body The body of the response.
 * @returns {FullLegacyZaq} The parsed legacy zaq object.
 */
export function parseLegacyZaq(id: string, body: object): FullLegacyZaq;

/**
 * Parses all the legacy Zaq from a response.
 *
 * @param {object} body The body of the response.
 * @returns {FullLegacyZaq[]} The parsed legacy zaq objects.
 */
export function parseLegacyZaq(body: object): FullLegacyZaq[];

/** Implementation. */
export function parseLegacyZaq(id: string | object | null, body?: object): FullLegacyZaq | FullLegacyZaq[] {
    if (typeof id === "object" && id !== null) {
        body = id;
        id = null;
    }
    if (!body) {
        throw new TypeError("The body must be set !");
    }

    // Parse the response.
    if (typeof id === "string") {
        const data = getData(body);
        if (!data || data.type !== LegacyZaqType || data.id !== id) {
            throw new TypeError("Failed to load the Zaq");
        }
        const zaq = parseZaqFromRequest(data, body);
        if (zaq === null) {
            throw new Error(`Could not find a ZaqTuto ${id}`);
        }
        return zaq;
    } else {
        const data = getData(body, true);
        return data
            .map(zaq => parseZaqFromRequest(zaq, body as object))
            .filter((zaq: FullLegacyZaq | null): zaq is FullLegacyZaq => zaq !== null);
    }
}

/** Helper used to parse a given zaq from the provided body. */
function parseZaqFromRequest(zaq: LegacyResource, body: object): FullLegacyZaq | null {
    // Find the translation of the zaq.
    if (!hasValidRelationship(zaq, "translations", true)) {
        console.warn("ZaqTuto %s has no translations", zaq.id);
        return null;
    }
    const translationId = zaq.relationships.translations.data[0]?.id;
    if (!translationId) {
        console.warn("ZaqTuto %s translation has no id", zaq.id);
        return null;
    }
    const translation = getIncluded(body, LegacyZaqTranslationType, translationId);
    if (!translation) {
        console.warn("Translation was not included in the response");
        return null;
    }

    // Get the name of the zaq.
    let name: string;
    if (!translation.attributes || typeof translation.attributes["name"] !== "string") {
        console.warn("ZaqTuto has no name !");
        name = "";
    } else {
        name = translation.attributes["name"];
    }
    // Get the tags of the zaq.
    let tags: string[] = [];
    if (zaq.attributes && Array.isArray(zaq.attributes["tags"])) {
        tags = zaq.attributes["tags"].filter(tag => typeof tag === "string");
    }

    // Find all the assets in the provided body.
    const assets = getIncluded(body, LegacyZaqAssetType)
        .map(function parseLegacyAsset(asset: LegacyResource): LegacyZaqAsset {
            // Get the asset's MIME type.
            if (!asset.attributes) {
                throw new TypeError("An asset has no attributes", { cause: asset });
            }
            if (!("content_type" in asset.attributes) || typeof asset.attributes["content_type"] !== "string") {
                throw new TypeError("An asset has no MIME type", { cause: asset });
            }
            if (!asset.attributes["content_type"].startsWith("image/")) {
                throw new TypeError("An asset is not an image", { cause: asset });
            }
            const mimeType = asset.attributes["content_type"] as `image/${string}`;

            // Get the asset's link.
            if (!asset.links || !("self" in asset.links)) {
                throw new TypeError("An asset has no link", { cause: asset });
            }
            let url: string;
            if (typeof asset.links["self"] === "string") {
                url = asset.links["self"];
            } else if (typeof asset.links["self"] === "object" && asset.links["self"] !== null) {
                if (!("href" in asset.links["self"]) || typeof asset.links["self"].href !== "string") {
                    throw new TypeError("An asset link is invalid", { cause: asset.links["self"] });
                }
                url = asset.links["self"].href;
            } else {
                throw new TypeError("An asset link is invalid", { cause: asset.links["self"] });
            }

            // Return the asset.
            return { id: asset.id, url, mimeType };
        });

    // Get the icon from the zaq.
    let icon: LegacyZaqAsset | undefined = undefined;
    if (hasValidRelationship(zaq, "icon", false)) {
        const assetId = zaq.relationships["icon"]?.data?.id;
        icon = assets.find(asset => asset.id === assetId);
    }

    // Get the owner of this zaq.
    let owner: string | null = null;
    if (hasValidRelationship(zaq, "owner", false)) {
        owner = zaq.relationships.owner.data?.id ?? null;
    }

    // Get all the editors of this zaq.
    const editors: string[] = [];
    if (hasValidRelationship(zaq, "editors", true)) {
        editors.push(...zaq.relationships.editors.data
            .map(editor => editor.id)
            .filter((editor: string | null): editor is string => editor !== null)
        );
    }

    // Get all the organisations of this zaq.
    const organisations: string[] = [];
    if (hasValidRelationship(zaq, "organisations", true)) {
        organisations.push(...zaq.relationships.organisations.data
            .map(org => org.id)
            .filter((org: string | null): org is string => org !== null)
        );
    }

    // Return the zaq.
    return {
        type: "zaq-tuto",
        id: zaq.id,
        name,
        majorSteps: parseDescriptorIfPresent(zaq, translation),
        tags,
        assets,
        icon,
        editors,
        owner,
        organisations
    };
}

/** Helper used to parse the data and translation if they have a descriptor. */
function parseDescriptorIfPresent(data: LegacyResource, translation: LegacyResource): MajorStep[] {
    // Check if the data has a descriptor.
    if (!data.attributes || !("descriptor" in data.attributes)) {
        return [];
    }
    if (!translation.attributes || !("descriptor" in translation.attributes)) {
        return [];
    }
    const descriptor = data.attributes["descriptor"], localised = translation.attributes["descriptor"];
    if (typeof descriptor !== "object" || descriptor === null || typeof localised !== "object" || localised === null) {
        return [];
    }

    return parseLegacyMajorSteps(descriptor, localised);
}

/** Parse the major steps from the given descriptor. */
function parseLegacyMajorSteps(descriptor: object, localised: object): MajorStep[] {
    if (!("Majors" in descriptor) || !("Majors" in localised)) {
        return [];
    }
    if (!Array.isArray(descriptor.Majors) || !Array.isArray(localised.Majors)) {
        throw new TypeError("Major step is not an array !");
    }

    return descriptor.Majors.map(function convertLegacyMajorStep(step: unknown, index: number): MajorStep {
        // Check if the step is an object.
        if (typeof step !== "object" || step === null) {
            throw new TypeError("A major step is invalid");
        }

        // Get the localised descriptor of the step.
        const localisedStep = (localised.Majors as unknown[])[index];
        if (typeof localisedStep !== "object" || localisedStep === null) {
            throw new TypeError("A major step localised data is invalid");
        }

        // Find the name of the step.
        if (!("Name" in localisedStep) || typeof localisedStep.Name !== "string") {
            throw new TypeError("A major step has no name");
        }

        return { name: localisedStep.Name, minorSteps: parseLegacyMinorSteps(step, localisedStep) };
    });
}

/** Parse the minor steps from the given descriptor. */
function parseLegacyMinorSteps(descriptor: object, localised: object): MinorStep[] {
    if (!("Minors" in descriptor) || !("Minors" in localised)) {
        return [];
    }
    if (!Array.isArray(descriptor.Minors) || !Array.isArray(localised.Minors)) {
        throw new TypeError("Minor step is not an array !");
    }

    return descriptor.Minors.map(function convertLegacyMinorStep(step: unknown, index: number): MinorStep {
        // Check if the step is an object.
        if (typeof step !== "object" || step === null) {
            throw new TypeError("A minor step is invalid");
        }

        // Get the localised descriptor of the step.
        const localisedStep = (localised.Minors as unknown[])[index];
        if (typeof localisedStep !== "object" || localisedStep === null) {
            throw new TypeError("A minor step localised data is invalid");
        }

        // Find the name of the step.
        if (!("Description" in localisedStep) || typeof localisedStep.Description !== "string") {
            throw new TypeError("A minor step has no description");
        }
        let explanation: string | undefined = undefined;
        if ("Explanation" in localisedStep && typeof localisedStep.Explanation === "string") {
            explanation = localisedStep.Explanation;
        }

        return { name: localisedStep.Description, explanation, actions: parseLegacyActions(step, localisedStep) };
    });
}

/** Parse the actions from the given descriptor. */
function parseLegacyActions(descriptor: object, localised: object): Action[] {
    if (!("Actions" in descriptor) || !("Actions" in localised)) {
        return [];
    }
    if (!Array.isArray(descriptor.Actions) || !Array.isArray(localised.Actions)) {
        throw new TypeError("Actions is not an array !");
    }

    return descriptor.Actions.map(function convertLegacyAction(action: unknown, index: number): Action {
        // Check if the action is an object.
        if (typeof action !== "object" || action === null) {
            throw new TypeError("An action is invalid");
        }

        // Get the localised descriptor of the action.
        const localisedAction = (localised.Actions as unknown[])[index];
        if (typeof localisedAction !== "object" || localisedAction === null) {
            throw new TypeError("An action's localised data is invalid");
        }

        // Find the type of the action.
        if (!("Type" in action) || typeof action.Type !== "string") {
            throw new TypeError("An action has no type");
        }
        if (!("Parameters" in action) || typeof action.Parameters !== "object" || action.Parameters === null) {
            throw new TypeError("An action has no parameters");
        }

        return {
            type: action.Type,
            parameters: action.Parameters,
            timeline: parseLegacyTimelines(action, localisedAction)
        };
    });
}

/** Parse the timelines from the given descriptor. */
function parseLegacyTimelines(descriptor: object, localised: object): Timeline[] {
    if (!("Timeline" in descriptor) || !("Timeline" in localised)) {
        return [];
    }
    if (!Array.isArray(descriptor.Timeline) || !Array.isArray(localised.Timeline)) {
        throw new TypeError("Timeline is not an array !");
    }

    return descriptor.Timeline.map(function convertLegacyTimeline(timeline: unknown, index: number): Timeline {
        // Check if the timeline is an object.
        if (typeof timeline !== "object" || timeline === null) {
            throw new TypeError("A timeline is invalid");
        }

        // Get the localised descriptor of the timeline.
        const localisedTimeline = (localised.Timeline as unknown[])[index];
        if (typeof localisedTimeline !== "object" || localisedTimeline === null) {
            throw new TypeError("A timeline's localised data is invalid");
        }

        // Find the asset of the timeline.
        let asset: string | undefined = undefined;
        if ("ImageKey" in timeline && typeof timeline.ImageKey === "string") {
            asset = timeline.ImageKey;
        }

        return { asset, extras: parseLegacyExtras(timeline, localisedTimeline) };
    });
}

/** Parse the extras from the given descriptor. */
function parseLegacyExtras(descriptor: object, localised: object): Extra[] {
    if (!("Extras" in descriptor) || !("Extras" in localised)) {
        return [];
    }
    if (!Array.isArray(descriptor.Extras) || !Array.isArray(localised.Extras)) {
        throw new TypeError("Extras is not an array !");
    }

    return descriptor.Extras.map(function convertLegacyExtra(extra: unknown, index: number): Extra {
        // Check if the extra is an object.
        if (typeof extra !== "object" || extra === null) {
            throw new TypeError("An extra is invalid");
        }

        // Get the localised descriptor of the extra.
        const localisedExtra = (localised.Extras as unknown[])[index];
        if (typeof localisedExtra !== "object" || localisedExtra === null) {
            throw new TypeError("A extra's localised data is invalid");
        }

        // Find the type and parameters of the extra.
        if (!("Type" in extra) || typeof extra.Type !== "string") {
            throw new TypeError("An extra has no type !");
        }
        let parameters: object | undefined = undefined;
        if ("Parameters" in extra && typeof extra.Parameters === "object" && extra.Parameters !== null) {
            parameters = extra.Parameters;
        }
        if (
            "Parameters" in localisedExtra &&
            typeof localisedExtra.Parameters === "object" &&
            localisedExtra.Parameters !== null
        ) {
            parameters = { ...parameters, ...localisedExtra.Parameters };
        }
        if (!parameters) {
            throw new TypeError("An extra has no parameters !");
        }

        return { type: extra.Type, parameters };
    });
}
