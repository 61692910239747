/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
// Import the router module.
import { useSearchParams } from "react-router-dom";
// Import the stores.
import {
    ZaqWikiStore,
    ZaqWikiFileStore,
    TagStore,
    AssetStore,
    EncryptionSourceStore,
    OrganisationStore,
    useResourceDispatch
} from "@andromeda/store";
// Import the toggleable button provider.
import { ToggleableButtonContextProvider } from "@andromeda/storybook";
// Import the resources.
import { Asset, Organisation, ZaqWikiFile } from "@andromeda/resources";
// Import the asset manager module.
import {
    ConversionStatusContext,
    loadWebAsset,
    MediaParserProvider,
    useIsConverting,
    withConversionStatusProvider
} from "@andromeda/asset-manager";
// Import the custom components.
import { Loader, SaveIndicator, useNotify } from "@andromeda/components";
// Import the web asset interface.
import { WebAsset } from "@andromeda/converter-lib";

// Import the file updater hook.
import { useFileUpdater } from "./editor/generator/hooks";
// Import the edit context.
import { ZaqWikiEditContext } from "./toggleable-context";
// Import the subcomponents.
import Editor from "./editor";
import Reader from "./reader";

// Import the css.
import "./index.module.scss";


/** Wraps the {@link ZaqWiki} component in all the required reducers. */
export const ZaqWiki = withConversionStatusProvider(
    OrganisationStore.withReducer(
        EncryptionSourceStore.withReducer(
            AssetStore.withReducer(
                TagStore.withReducer(
                    ZaqWikiStore.withReducer(
                        ZaqWikiFileStore.withReducer(
                            ZaqWikiComponent
                        )
                    )
                )
            )
        )
    )
);
export default ZaqWiki;

/** Renders the ZaqWiki. */
function ZaqWikiComponent(props: ZaqWikiProps): ReactElement {
    // Check if the wiki is loading.
    const isLoaded = useWikiLoader(props.id, props.organisation);

    // Get the state of the creating query parameter.
    const [parameters] = useSearchParams();
    const wasJustCreated = useMemo(function getCreatedSearchParam(): boolean {
        return parameters.get("created") !== null;
    }, [parameters]);

    // Pre-render the edit component.
    const edit = useMemo(function preRenderEditComponent(): ReactElement {
        return <Editor id={props.id} />;
    }, [props.id]);

    // Pre-render the read component.
    const read = useMemo(function preRenderReadComponent(): ReactElement {
        return <Reader id={props.id} organisation={props.organisation} />;
    }, [props.id, props.organisation]);

    if (!isLoaded) {
        return <Loader text="Chargement du ZaqWiki ..." />;
    }
    return <MediaParserProvider>
        <ToggleableButtonContextProvider
            defaultState={wasJustCreated}
            context={ZaqWikiEditContext}
            fallback={<Loader />}
        >
            <SaveIndicatorWrapper />
            <ZaqWikiEditContext.Consumer children={context => context.state ? edit : read} />
        </ToggleableButtonContextProvider>
    </MediaParserProvider>;
}

/** Props passed down to the {@link ZaqWiki} component. */
interface ZaqWikiProps {
    /** The identifier of the edited {@link ZaqWiki}. */
    id: string;
    /** The identifier of the current organisation, if applicable. */
    organisation?: string;
}

/** Helper hook used to load the wiki. */
function useWikiLoader(wiki: string, organisation?: string): boolean {
    // Store the loading state.
    const [loaded, setLoaded] = useState(false);

    // Get the notification tool.
    const { fatal } = useNotify();
    // Get the converter tool.
    const convert = useLocalAssetConverter();

    // Get the resource dispatch.
    const dispatch = useResourceDispatch();

    // Check if the store is reading wiki or files.
    const readingWiki = ZaqWikiStore.useSelector(state => !!state.reading);
    const readingFiles = ZaqWikiFileStore.useSelector(state => !!state.reading);

    // Store a few refs to avoid double-loading.
    const filesAreLoading = useRef(false);
    const filesAreLoaded = useRef(false);
    const filesAreConverting = useRef(false);

    // Load the wiki on mount.
    useEffect(function loadWikiElements(): void {
        // If the files are being loaded, ignore.
        if (filesAreLoading.current || filesAreLoaded.current) {
            return;
        }

        // If the wiki is undefined, ignore.
        if (typeof wiki === "undefined") {
            return;
        }

        // If the wiki is being read, ignore.
        if (readingWiki || readingFiles) {
            return;
        }

        // Load the wiki and its files.
        setLoaded(false);
        filesAreLoading.current = true;
        dispatch(ZaqWikiStore.generator.readOne(
            wiki,
            { include: [ZaqWikiFile.Type, Asset.Type, Organisation.Type], immediate: true }
        ))
            .then(() => {
                filesAreLoaded.current = true;
                filesAreLoading.current = false;
                setLoaded(true);
            })
            .catch(fatal);
    }, [fatal, dispatch, readingFiles, readingWiki, wiki]);

    // Load the list of all the wiki files.
    const files = ZaqWikiFileStore.useSelector(
        state => state.resources.filter(file => file.relationships.wiki.data.id === wiki)
    );

    // Load all the assets.
    const assets = AssetStore.useSelector(state => state.resources);
    // Re-convert all assets if needed.
    useEffect(function convertLocalAssets(): void {
        if (filesAreLoaded.current && !filesAreConverting.current) {
            filesAreConverting.current = true;
            for (const file of files) {
                if (!("asset" in file.relationships)) {
                    continue;
                }
                const ref = file.relationships.asset.data;
                if (assets.find(asset => asset.id === ref.id)) {
                    continue;
                }
                loadWebAsset(ref.id).then(asset => {
                    if (asset) {
                        convert(asset, file);
                    }
                });
            }
        }
    }, [assets, convert, files]);

    // Load all the tags.
    const tagsAreLoaded = useRef(false);
    useEffect(function loadTags(): void {
        if (tagsAreLoaded.current) {
            return;
        }
        if (!organisation) {
            return;
        }
        tagsAreLoaded.current = true;

        dispatch(TagStore.generator.readMany({ filter: { tag: { owner: { $eq: organisation } } }, immediate: true }))
            .catch(fatal);
    }, [fatal, dispatch, organisation]);

    return loaded;
}

/** Helper tool used to convert a local asset. */
function useLocalAssetConverter() {
    // Load all the conversions.
    const { conversions } = useContext(ConversionStatusContext);

    // Load the list of stored assets.
    const storedAssets = AssetStore.useSelector(state => state.resources);

    // Load the file converter.
    const convert = useFileUpdater();

    // Return the callback.
    return useCallback(function convertLocalAsset(asset: WebAsset, file: ZaqWikiFile): void {
        // Check if the asset exists on the remote.
        if (storedAssets.some(stored => stored.id === asset.resource.id)) {
            return;
        }

        // Check if the asset is converting.
        if (conversions.has(asset.resource.id)) {
            return;
        }

        // Convert the asset.
        if (
            asset.resource.attributes.contentType.startsWith("image") ||
            asset.resource.attributes.contentType.startsWith("video")
        ) {
            convert(asset, file);
        }
    }, [conversions, convert, storedAssets]);
}

/** Helper hook used to check if the zaq is saving right now. */
function SaveIndicatorWrapper(): ReactElement {
    const wiki = ZaqWikiStore.useSelector();
    const tags = TagStore.useSelector();
    const files = ZaqWikiFileStore.useSelector();
    const isConverting = useIsConverting();

    const isSaving = useMemo(
        () =>
            !!wiki.creating || !!wiki.reading || !!wiki.updating || !!wiki.deleting ||
            !!tags.creating || !!tags.reading || !!tags.updating || !!tags.deleting ||
            !!files.creating || !!files.reading || !!files.updating || !!files.deleting ||
            isConverting,
        [
            files.creating, files.deleting, files.reading, files.updating,
            tags.creating, tags.deleting, tags.reading, tags.updating,
            wiki.creating, wiki.deleting, wiki.reading, wiki.updating,
            isConverting
        ]
    );

    return <SaveIndicator show={isSaving} position="top-left" />;
}
