/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


/**
 * Helper function used to open a new connection to the cache database.
 *
 * @returns {Promise<IDBDatabase>} A promise that resolves with the cache database.
 */
export function getDatabase(): Promise<IDBDatabase> {
    // Open a connection to the cache database.
    const database = window.indexedDB.open("andromeda/search", 5);

    // Create the cache store if needed.
    database.addEventListener("upgradeneeded", function onDatabaseUpgrade(): void {
        // Clear the object stores.
        if (database.result.objectStoreNames.contains("cache")) {
            database.result.deleteObjectStore("cache");
        }
        database.result.createObjectStore("cache", { keyPath: "key" });

        if (database.result.objectStoreNames.contains("favourite")) {
            database.result.deleteObjectStore("favourite");
        }
        database.result.createObjectStore("favourite", { keyPath: "key" });
    });

    // Return a promise that resolves with the database.
    return new Promise<IDBDatabase>(function(resolve, reject): void {
        database.addEventListener("success", function onDatabaseReady(): void {
            resolve(database.result);
        });
        database.addEventListener("error", function onDatabaseOpenFailed(event: Event): void {
            reject(event);
        });
    });
}
