/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useContext, useMemo } from "react";
// Import the route module.
import { Routes, Route, useParams } from "react-router-dom";
// Import the course component.
import Course from "@andromeda/course";
// Import the wiki component.
import ZaqWiki from "@andromeda/zaq-wiki";
// Import the login context.
import { LoginContext } from "@andromeda/login";


/** Router used to render a given course. */
export default function CourseRouter(): ReactElement | null {
    // Load the current organisation id from the login context.
    const loginContext = useContext(LoginContext);
    const organisation = useMemo(function getOrganisationId(): string | undefined {
        if (loginContext.isLoggedIn) return loginContext.organisations.current.id;
        return undefined;
    }, [loginContext.isLoggedIn, loginContext.organisations]);

    // Render the component.
    return <Routes>
        <Route path="/:id/wiki/:wiki" element={<CourseWikiRenderer organisation={organisation} />} />
        <Route path="*" element={<Course />} />
    </Routes>;
}

function CourseWikiRenderer(props: CourseWikiRendererProps) {
    const { wiki } = useParams<"wiki">();
    if (typeof wiki === "undefined") {
        throw new TypeError("Bad parameters !");
    }

    return <ZaqWiki id={wiki} organisation={props.organisation} />;
}
interface CourseWikiRendererProps {
    organisation?: string;
}
