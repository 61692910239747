/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import type { ReactElement } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the icons.
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons/faFloppyDisk";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
// Import the css.
import css from "./validate.module.scss";


/** Renders the cancel and validate button. */
export function Validate(props: ValidateProps): ReactElement {
    // Render the component.
    return <div className={css["validate"]}>
        <button className={classNames(css["button"], css["button--cancel"])} onClick={props.cancel}>
            <FontAwesomeIcon icon={faXmark} className={css["button__icon"]} />
            Annuler
        </button>
        <button
            className={classNames(css["button"], css["button--validate"])}
            disabled={typeof props.validate === "undefined"}
            onClick={props.validate}
        >
            <FontAwesomeIcon icon={faFloppyDisk} className={css["button__icon"]} />
            Sauvegarder
        </button>
    </div>;
}

/** Props passed down to the {@link Validate} component. */
interface ValidateProps {
    /** Callback invoked when the user cancels the operation. */
    cancel(): void;

    /** Callback invoked when the user finishes the capture. */
    validate?(): void;
}
