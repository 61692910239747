/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the MIME types.
import { AudioAssetMimeType, ImageAssetMimeType, VideoAssetMimeType } from "../../asset";
// Import the dimension interfaces.
import { Dimensions, SourceRect } from "./dimensions";


/** Interface used to request an audio to audio conversion. */
export interface AudioToAudioConversion {
    /** MIME type of the output file. */
    contentType: AudioAssetMimeType;
    /** Bitrate of the output file. If undefined, keeps the source value. */
    bitrate?: number;
    /** Codec that should be used to transcode the audio stream. */
    codec?: string;
    /** Starting point of the audio conversion. In the [0;1[ interval, where 1 is the length of the original file. */
    start?: number;
    /** Ending point of the audio conversion. In the [0;1[ interval, where 1 is the length of the original file. */
    end?: number;
}

/** Interface used to request an image to image conversion. */
export interface ImageToImageConversion {
    /** MIME type of the output file. */
    contentType: ImageAssetMimeType;
    /** Dimensions of the output file. */
    dimensions: Dimensions;
    /** Section of the source image to be converted. */
    sourceRect?: SourceRect;
}

/** Interface used to request a video to image conversion. */
export interface VideoToImageConversion {
    /** MIME type of the output file. */
    contentType: ImageAssetMimeType;
    /** Dimensions of the output file. */
    dimensions: Dimensions;
    /** Framerate at which the images should be extracted. */
    framerate: number;
    /** Section of the source video to be converted. */
    sourceRect?: SourceRect;
}

/** Interface used to request a video to video conversion. */
export interface VideoToVideoConversion {
    /** MIME type of the output file. */
    contentType: VideoAssetMimeType;
    /** Dimensions of the output file. */
    dimensions?: Dimensions;
    /** Section of the source video to be converted. */
    sourceRect?: SourceRect;
    /** Framerate of the output file. If undefined, keeps the source value. */
    framerate?: number;
    /** Bitrate of the output file. If undefined, keeps the source value. */
    bitrate?: number;
    /** Codec that should be used to transcode the audio stream. */
    audioCodec?: string;
    /** Bitrate of the audio stream. If undefined, keeps the source value. */
    audioBitrate?: number;
    /** Codec that should be used to transcode the video stream. */
    videoCodec?: string;
    /** Bitrate of the video stream. If undefined, keeps the source value. */
    videoBitrate?: number;
}

/** Union of all the conversion interfaces. */
export type Conversion =
    AudioToAudioConversion |
    ImageToImageConversion |
    VideoToImageConversion |
    VideoToVideoConversion
;
