/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import {
    ReactElement,
    useContext,
    useMemo,
    useEffect,
    useState,
    Context,
    ReactNode,
    FormEvent,
} from "react";
// Import the bootstrap components.
import { OverlayTrigger, Popover } from "react-bootstrap";

// Import the switch context.
import { SwitchContext, SwitchContextProvider, createNamedSwitchContext } from "./context";

// Import the icons.
import lock from "@andromeda/assets/images/lock-white.svg";
import help from "@andromeda/assets/images/question-mark.svg";
// Import the css.
import css from "./switch.module.scss";


/**
 * Component used to render a toggle-able switch on the page.
 * Should be mounted with access to a {@link SwitchContext}.
 */
export function Switch(props: Props): ReactElement {
    // Load the switch context.
    const context = useContext(props.context ?? SwitchContext);
    const { toggle } = context;
    const [ownState, setOwnState] = useState(props.initialState ?? false);
    useEffect(() => {
        if (typeof props.initialState !== "undefined") {
            setOwnState(props.initialState);
        }
    }, [props.initialState]);

    // Compute the value of the switch's state.
    const { onInternalStateChange, onChange } = props;
    const [state, toggleState] = useMemo(
        function stateWrapper(): [boolean, (event: FormEvent<HTMLInputElement>) => void] {
            if (onChange) {
                return [ownState, updateHandler];
            } else if (onInternalStateChange) {
                return [ownState, updateHandler];
            } else {
                return [context.state, updateHandler];
            }

            function updateHandler(event: FormEvent<HTMLInputElement>): void {
                onChange?.(event);
                if (onInternalStateChange) {
                    setOwnState(old => {
                        onInternalStateChange(!old);
                        return !old;
                    });
                } else {
                    toggle();
                }
            }
        },
        [onChange, ownState, context.state, onInternalStateChange, toggle]
    );

    // Check if a colour override was provided.
    const colourOverride = useMemo(function computeColourOverride(): { modifier?: string, style?: string } {
        if (!context.state || !props.checkedColour) {
            return {};
        }

        if ((PredefinedColours as readonly string[]).includes(props.checkedColour)) {
            return { modifier: props.checkedColour };
        }
        return { style: props.checkedColour };
    }, [context.state, props.checkedColour]);

    // Compute the class name of the switch.
    const className = useMemo(function computeClassName(): string {
        let className: string = css["switch"];
        if (state) {
            className += ` ${css["switch--checked"]}`;
        }
        if (props.disabled) {
            className += ` ${css["switch--disabled"]}`;
        }
        if (props.small) {
            className += ` ${css["switch--small"]}`;
        }
        if (props.className) {
            className += ` ${props.className}`;
        }
        return className;
    }, [props.className, props.disabled, props.small, state]);

    // Compute the class name of the slider.
    const sliderClassName = useMemo(function computeSliderClassName(): string {
        let className: string = css["switch__slider"];
        if (colourOverride.modifier) {
            className += ` ${css[`switch__slider--${colourOverride.modifier}`]}`;
        }
        return className;
    }, [colourOverride.modifier]);

    // Render the name of the switch.
    const name = useMemo(function renderSwitchName(): ReactElement | null {
        if (!props.name) {
            return null;
        }

        let className = css["switch__text"];
        if (colourOverride.modifier) {
            className += ` ${css[`switch__text--${colourOverride.modifier}`]}`;
        }

        return <p className={className} style={{ color: colourOverride.style }} children={props.name} />;
    }, [colourOverride.modifier, colourOverride.style, props.name]);

    // Render the padlock if the switch is disabled.
    const padlock = useMemo(function renderPadlockIfDisabled(): ReactElement | null {
        if (!props.disabled) {
            return null;
        }

        return <img src={lock} className={css["switch__padlock"]} alt="padlock" />;
    }, [props.disabled]);

    // Render the explanation.
    const explanation = useMemo(function renderExplanation(): ReactElement | null {
        if (!props.explanation) {
            return null;
        }

        // NOTE: WebStorm thinks that all attributes of the OverlayTrigger components are required for some reason.
        // noinspection RequiredAttributes
        return <OverlayTrigger
            overlay={
                <Popover
                    className={css["switch__explanation__popover"]}
                    children={props.explanation}
                />
            }
            key="help-popover"
            placement="top"
        >
            <img className={css["switch__explanation"]} src={help} alt="help" />
        </OverlayTrigger>;
    }, [props.explanation]);

    // Render the switch.
    return <label className={className}>
        <input
            name={props.inputName}
            className="d-none" type="checkbox" disabled={props.disabled}
            checked={state} onChange={toggleState}
        />
        {explanation}
        <span className={sliderClassName} style={{ backgroundColor: colourOverride.style }} children={padlock} />
        {name}
    </label>;
}

/** List of all the pre-defined colours. */
const PredefinedColours = ["primary", "secondary", "tertiary", "low", "med", "high", "highest"] as const;

/** Props passed down to the {@link Switch} component. */
interface Props {
    /** Optional name of the switch. Will be rendered next to the switch. */
    name?: string;
    /**
     * Overridable colour for the checked switch.
     * Can either be a prefefined colour, or a raw hex value.
     */
    checkedColour?: string | (typeof PredefinedColours)[number];

    /** Flag set if the button is disabled. */
    disabled?: boolean;
    /** Explanation text to render next to the button. */
    explanation?: ReactNode;

    /** Class name added to the switch. */
    className?: string;

    /** Display a small version of the switch. */
    small?: boolean;

    /** Named context to use for this switch. */
    context?: Context<SwitchContext>;

    /**
     * Method used to access the underlying form event directly.
     *
     * @param {FormEvent<HTMLInputElement>} event The event fired by the input element.
     */
    onChange?(event: FormEvent<HTMLInputElement>): void;

    /**
     * Method invoked when the internal state of the switch is toggled.
     * If set, the switch will use its own state instead of a given context.
     *
     * @param {boolean} state The updated state of the switch.
     */
    onInternalStateChange?(state: boolean): void;

    /**
     * Initial value of the internal state of the switch.
     * Only used if {@link onInternalStateChange} is set.
     *
     * @default false
     */
    initialState?: boolean;
    /** Name of the internal input element. */
    inputName?: string;
}

// Re-export the context.
export { SwitchContext, SwitchContextProvider, createNamedSwitchContext };
