/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the search bar tool.
import { createSearchBar } from "@andromeda/components";
// Import the user resource.
import { User } from "@andromeda/resources";

// Create a new search bar type.
export const UserListSearchBar = createSearchBar<User>();
