/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the human readable request generator.
import { humanReadableRequest } from "../config/url";
// Import the interfaces.
import { PreparedXhrRequest, XhrPartialRequest } from "../..";
// Import the error classes.
import { RequestFailedError } from "../../errors";

// Import the response parser.
import { prepareResponse } from "../response/browser";

// Import the logging tool.
import debug from "debug";
import { exponentialBackoff } from "./index";
const log = debug("xhr:services:request");


/**
 * Browser implementation of the request executor.
 * Wraps the request into a {@link XhrPartialRequest} object.
 *
 * @param {PreparedXhrRequest} request The request to run.
 * @returns {Promise<XhrPartialRequest>} A promise that resolves with the generated request.
 */
export async function executeRequest(request: PreparedXhrRequest): Promise<XhrPartialRequest> {
    log(
        "Preparing to execute request %s in a browser environment.",
        humanReadableRequest(request.options.method, request.url)
    );
    // Prepare the client request.
    const abort = new AbortController();
    const response = prepareResponse(request, await runRequest());
    return { ...request, response, abort: abort.abort.bind(abort) };

    /** Recursively runs the request, retrying if it failed with a manageable error. */
    function runRequest(attempt = 0): Promise<Response> {
        return prepareFetchRequest(request, abort.signal).then(onResponse, onError);

        /** Helper function used to handle errors. */
        function onError(error: Error): never {
            throw new RequestFailedError(request.options.method, request.url, error);
        }
        /** Helper function used to handle successful responses. */
        async function onResponse(message: Response): Promise<Response> {
            if ([ 429, 502, 504 ].includes(message.status) && !request.options.configuration?.ignoreRateLimits) {
                log("Received a TooManyRequest response.");
                return exponentialBackoff(attempt, runRequest);
            } else {
                return message;
            }
        }
    }
}

/** Prepares the request for the given request. */
async function prepareFetchRequest(request: PreparedXhrRequest, signal: AbortSignal): Promise<Response> {
    // Convert the headers.
    const headers: Record<string, string> = {};
    for (const [ header, value ] of Object.entries(request.headers)) {
        if (Array.isArray(value)) headers[header] = value.join(",");
        else headers[header] = value;
    }

    // Run the fetch request.
    return fetch(
        request.url.toString(),
        {
            method: request.options.method,
            redirect: request.options.configuration?.ignoreRedirects === true ? "manual" : "follow",
            mode: "cors", credentials: "omit",
            body: request.body,
            headers, signal
        }
    );
}
