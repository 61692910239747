/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import React-Router.
import { Link } from "react-router-dom";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the store.
import { useCourse, useUser } from "@andromeda/store";

// Import the css.
import css from "./title.module.scss";


/** Component used to render the title of a given {@link ZaqTraining}. */
export default function TrainingTitle(props: TrainingTitleProps): ReactElement {
    // Download the training and user from the store.
    const training = useCourse(props.training, true);
    const user = useUser(props.user, true);

    // Render the name of the training.
    const className = classNames(css["title"], props.className);
    return <Link className={className} to={`/zaq-training/${props.training}`}>
        {props.showUserName ? user.data?.attributes.givenName : training.data?.attributes.name}
    </Link>;
}

/** Props passed down to the {@link TrainingTitle} component. */
export interface TrainingTitleProps {
    /** Unique identifier of the training being rendered. */
    training: string;
    /** Unique identifier of the user being rendered. */
    user: string;
    /** If set, shows the username rather than the training name. */
    showUserName: boolean;
    /** Optional class name added to the {@link Link} element. */
    className?: string;
}
