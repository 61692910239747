/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useContext, useMemo } from "react";
// Import React-Bootstrap.
import { Navbar } from "react-bootstrap";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the login context.
import { LoginContext } from "@andromeda/login";

// Import the icons.
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
// Import the css.
import css from "./home.module.scss";


/** Component used to render the home button on the left side of the navbar. */
export default function Home(): ReactElement {
    // Get the current organisation from the login context.
    const organisation = useContext(LoginContext).organisations.current;

    // Get the organisation icon, or its name if it has none.
    const logo = useMemo(function getOrganisationIconOrName(): ReactNode {
        // Check if the organisation has a custom icon.
        if (organisation.attributes.icon) {
            return <img
                src={organisation.attributes.icon}
                alt={organisation.attributes.name}
                className={css["logo"]}
            />;
        }

        // Render the name of the organisation.
        return <p className={css["logo"]} children={organisation.attributes.name} />;

    }, [organisation.attributes.icon, organisation.attributes.name]);

    // Render the component.
    return <Navbar.Brand href="/" className={css["home"]}>
        <FontAwesomeIcon className={css["icon"]} icon={faHome} />
        {logo}
    </Navbar.Brand>;
}
