/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import {
    useContext,
    useEffect,
    useMemo,
    useState,
    ReactElement,
    useCallback,
    KeyboardEvent
} from "react";
// Import React Bootstrap.
import { OverlayTrigger, Popover } from "react-bootstrap";
// Import the miscellaneous components.
import {
    Pdf,
    AutoresizeTextarea,
    useNotify,
    SpeechRecognitionButton
} from "@andromeda/components";
// Import the file resource.
import { ZaqWikiFile, ZaqWikiAssetFile, ZaqWikiTextFile } from "@andromeda/resources";
// Import the asset manager.
import { ConversionStatusIndicator } from "@andromeda/asset-manager";
// Import the store.
import { useResourceDispatch, ZaqWikiFileStore } from "@andromeda/store";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";

// Import the fullscreen modal.
import { FullscreenModal } from "../../fullscreen-modal";
// Import the asset tool.
import { useAsset, useAssetConversionSubject, useAssetResource } from "../../hooks";
// Import the file context.
import { FileContext } from "./context";

// Import the icons.
import help from "@andromeda/assets/images/question-mark-blue.svg";
// Import the css.
import css from "./body.module.scss";


/** Component used to render the body of a file. */
export function Body(): ReactElement | null {
    // Get the file from the context.
    const fileContext = useContext(FileContext);
    // Get the asset from the file.
    const asset = useAssetResource(fileContext.file);
    // Get the conversion status of the asset.
    const conversion = useAssetConversionSubject(fileContext.file);

    // Compute the contents of the file.
    const contents = useMemo(
        function detectFileType(): ReactElement | null {
            if (ZaqWikiFile.isAssetFile(fileContext.file) && asset) {
                if (asset.attributes.contentType.startsWith("image/")) {
                    return <ImageRenderer file={fileContext.file} />;
                }
                if (asset.attributes.contentType.startsWith("video/")) {
                    return <VideoRenderer file={fileContext.file} />;
                }
                if (asset.attributes.contentType.startsWith("audio/")) {
                    return <AudioRenderer file={fileContext.file} />;
                }
                if (asset.attributes.contentType === "application/pdf") {
                    return <PdfRenderer file={fileContext.file} />;
                }
            }
            if (ZaqWikiFile.isTextFile(fileContext.file)) {
                return <TextRenderer file={fileContext.file} />;
            }

            return null;
        },
        [asset, fileContext.file]
    );

    // Render the component.
    if (contents && fileContext.expanded) {
        return <div className={`${css["body"]} ${fileContext.expanded ? "" : css["body--hidden"]}`}>
            {contents}
            {conversion ?
                <ConversionStatusIndicator conversion={conversion} className={css["body__conversion"]} />
                : null}
        </div>;
    } else {
        return null;
    }
}

/** Simple component used to render an image. */
function ImageRenderer(props: AssetProps): ReactElement {
    // Store the state of the modal.
    const [show, setShow] = useState(false);

    const asset = useAsset(props.file);
    if (!asset.isSuccess) {
        return <Loader text="Chargement de l'image ..." />;
    }
    return <>
        <div className={css["body__selectable"]} onClick={() => setShow(true)}>
            <img src={asset.data.url} alt={props.file.attributes.name ?? ""} />
        </div>
        <FullscreenModal show={show} hide={() => setShow(false)}>
            <img src={asset.data.url} alt={props.file.attributes.name ?? ""} />
        </FullscreenModal>
    </>;
}

/** Simple component used to render a video. */
function VideoRenderer(props: AssetProps): ReactElement {
    const asset = useAsset(props.file);
    const autoPlayAndPause = useAutoPlayAndPauseCallback();
    if (!asset.isSuccess) {
        return <Loader text="Chargement de la vidéo ..." />;
    }
    return <video src={asset.data.url} playsInline controls ref={autoPlayAndPause} />;
}

/** Simple component used to render a sound file. */
function AudioRenderer(props: AssetProps): ReactElement {
    const asset = useAsset(props.file);
    const autoPlayAndPause = useAutoPlayAndPauseCallback();
    if (!asset.isSuccess) {
        return <Loader text="Chargement du fichier audio ..." />;
    }
    return <audio src={asset.data.url} playsInline controls ref={autoPlayAndPause} />;
}

/** Simple component used to render a text file. */
function TextRenderer(props: TextProps): ReactElement {
    const dispatch = useResourceDispatch();
    const notify = useNotify();
    const [text, setText] = useState(props.file.attributes.contents);

    // Callback used to update the text on the server.
    const update = useCallback(function updateText(): void {
        const update: ZaqWikiFile.Update = {
            type: ZaqWikiFile.Type,
            id: props.file.id,
            attributes: { contents: text }
        };
        dispatch(ZaqWikiFileStore.generator.update(update)).catch(notify.fatal);
    }, [props.file.id, text, dispatch, notify.fatal]);

    // NOTE: WebStorm thinks that all attributes of the OverlayTrigger components are required for some reason.
    // noinspection RequiredAttributes
    return <div className={css["body__container"]}>
        <AutoresizeTextarea
            rows={1}
            className={css["body__text"]}
            placeholder={"Donner un corps à ce fichier texte"}
            value={text}
            onChange={e => setText(e.currentTarget.value)}
            onBlur={update}
            onKeyDownCapture={absorbTab}
        />
        <SpeechRecognitionButton
            className={css["speech-button"]}
            onText={text => setText(props.file.attributes.contents + "\n" + text)}
            onEnd={update}
            stopWithUser
            interimResults
        />

        <OverlayTrigger
            overlay={
                <Popover className={css["markdown-helper__container"]}>
                    <span className={css["markdown-helper"]}>
                        <span className={css["markdown-helper--symbol"]}>_</span>
                        <span className={css["markdown-helper--italic"]}>italique</span>
                        <span className={css["markdown-helper--symbol"]}>_</span>
                    </span>
                    <span className={css["markdown-helper"]}>
                        <span className={css["markdown-helper--symbol"]}>**</span>
                        <span className={css["markdown-helper--bold"]}>gras</span>
                        <span className={css["markdown-helper--symbol"]}>**</span>
                    </span>
                    <span className={css["markdown-helper"]}>
                        <span className={css["markdown-helper--symbol"]}>__</span>
                        <span className={css["markdown-helper--underline"]}>souligné</span>
                        <span className={css["markdown-helper--symbol"]}>__</span>
                    </span>
                    <span className={css["markdown-helper"]}>
                        <span className={css["markdown-helper--symbol"]}>~~</span>
                        <span className={css["markdown-helper--strikethrough"]}>barré</span>
                        <span className={css["markdown-helper--symbol"]}>~~</span>
                    </span>
                </Popover>
            }
            key="help-popover"
            placement="top"
        >
            <img className={css["markdown-helper__icon"]} src={help} alt="help" />
        </OverlayTrigger>
    </div>;
}

/** Simple component used to render a pdf file. */
function PdfRenderer(props: AssetProps): ReactElement {
    // Store the state of the modal.
    const [show, setShow] = useState(false);

    const asset = useAsset(props.file);
    if (!asset.isSuccess) {
        return <Loader text="Chargement du fichier PDF ..." />;
    }
    return <>
        <Pdf
            className={css["body__selectable"]}
            source={asset.data.url}
            onClick={() => setShow(true)}
            maxHeight={window.innerHeight * 0.75}
        />
        <FullscreenModal show={show} hide={() => setShow(false)}>
            <Pdf source={asset.data.url} fixedSelector />
        </FullscreenModal>
    </>;
}

interface AssetProps {
    file: ZaqWikiAssetFile;
}

interface TextProps {
    file: ZaqWikiTextFile;
}

/** Helper used to absorb the tab event. */
function absorbTab(event: KeyboardEvent<HTMLTextAreaElement>): void {
    // If the key is a tab, add 4-space indentation.
    if (event.key.toLowerCase() === "tab") {
        const target = event.currentTarget;
        const start = target.selectionStart;
        const end = target.selectionEnd;

        // Set the new text value.
        target.value = target.value.slice(0, start) + "    " + target.value.slice(start);
        target.selectionStart = start + 4;
        target.selectionEnd = end + 4;

        // Stop the focus loss.
        event.preventDefault();
        event.stopPropagation();
    }
}

/** Attaches a listener to the "play" event that stops all other sources on the page. */
function useAutoPlayAndPauseCallback(): (element: HTMLMediaElement | null) => void {
    // Store the altered element in a state.
    const [element, setElement] = useState<HTMLMediaElement | null>(null);

    // Fit the video to its parent.
    useEffect(
        function fitToParent(): void {
            if (element && element instanceof HTMLVideoElement) {
                element.onloadedmetadata = () => {
                    if (element.parentElement) {
                        element.height = element.parentElement.clientHeight;
                    }
                };
            }
        },
        [element]
    );

    // Function that pauses any other media source if this one starts playing.
    const pauseOtherSources = useCallback(
        function pauseOtherSources(): void {
            if (!element) {
                return;
            }

            const videoSources = document.getElementsByTagName("video");
            for (let i = 0; i < videoSources.length; i++) {
                if (videoSources[i] !== element) {
                    videoSources[i].pause();
                }
            }
            const audioSources = document.getElementsByTagName("audio");
            for (let i = 0; i < audioSources.length; i++) {
                if (audioSources[i] !== element) {
                    audioSources[i].pause();
                }
            }
        },
        [element]
    );
    useEffect(
        function attachPlayScrollListeners(): (() => void) | void {
            if (!element) {
                return;
            }

            element.addEventListener("play", pauseOtherSources, {
                passive: true
            });
            return function detachPlayScrollListeners(): void {
                element.removeEventListener("play", pauseOtherSources);
            };
        },
        [element, pauseOtherSources]
    );

    // Return the setter.
    return setElement;
}
