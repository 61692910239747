/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { createContext, ReactElement, ReactNode, useCallback, useState } from "react";
// Import the common tools.
import { makeContextNoop } from "@andromeda/tools";

// Import the subcomponents.
import DueDateModal from "./modal";


/** Component used to provide the {@link DueDateModalContext} in the Virtual DOM. */
export default function DueDateModalProvider(props: DueDateModalProviderProps): ReactElement {
    // Store the state of the modal.
    const [course, setCourse] = useState<string>();
    const [user, setUser] = useState<string>();

    // Callback used to show the modal.
    const show = useCallback(function showDateModal(course: string, user: string): void {
        setCourse(course); setUser(user);
    }, []);


    // Render the component.
    return <DueDateModalContext.Provider value={{ show }}>
        {props.children}
        <DueDateModal
            course={course}
            user={user}
            close={() => { setCourse(undefined); setUser(undefined); }}
        />
    </DueDateModalContext.Provider>;
}

/** Props passed down to the {@link DueDateModalProvider} component. */
export interface DueDateModalProviderProps {
    /** Children that will have access to the {@link DueDateModalContext}. */
    children?: ReactNode;
}

/** Context used to show the due-date modal from anywhere. */
export interface DueDateModalContext {
    /**
     * Opens the modal for the provided user.
     *
     * @param {string} course The course to edit.
     * @param {string} user The user to edit.
     */
    show(course: string, user: string): void;
}

/** Context used to show the due-date modal from anywhere. */
export const DueDateModalContext = createContext<DueDateModalContext>({
    show: makeContextNoop("DueDateModalContext", "show")
});
DueDateModalContext.displayName = "DueDateModalContext";
