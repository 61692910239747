/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { useCallback, MouseEventHandler, MouseEvent, ChangeEventHandler, ChangeEvent, useContext } from "react";
// Import the mime type helper.
import mime from "mime";
// Import the file and wiki resources.
import { Asset, ZaqWiki, ZaqWikiFile } from "@andromeda/resources";
// Import the error context.
import { useNotify } from "@andromeda/components";
// Import the stores.
import { ZaqWikiFileStore, useResourceDispatch } from "@andromeda/store";

// Import the reader context.
import { ReaderContext } from "../context";
// Import the asset manager.
import { storeWebAsset, uploadWebAsset, useAssetConverter, useAssetPreparator } from "@andromeda/asset-manager";
// Import the WebAsset interface.
import { WebAsset } from "@andromeda/converter-lib";
import { sleep } from "@andromeda/tools";


/** Helper callback used to forward a click event to a child input. */
export function useClickForwarder(...callbacks: (() => void)[]): MouseEventHandler<HTMLElement> {
    // Return the handler.
    return useCallback(
        function onClick(event: MouseEvent<HTMLElement>): void {
            const children = event.currentTarget.getElementsByTagName("input");
            for (let i = 0; i < children.length; i++) {
                children[i].click();
            }

            // Invoke the callbacks.
            callbacks.forEach(callback => callback());
        },
        [callbacks]
    );
}

/** Wrapper for {@link useGenerator} that can accept a {@link ChangeEvent}. */
export function useInputGenerator(): ChangeEventHandler<HTMLInputElement> {
    // Use the generator dispatch.
    const generator = useGenerator();

    // Return the handler.
    return useCallback(
        function onInputChange(event: ChangeEvent<HTMLInputElement>): void {
            if (event.currentTarget.files === null) return;
            for (let i = 0; i < event.currentTarget.files.length; i++) {
                generator(event.currentTarget.files[i]);
            }
        },
        [generator]
    );
}

/** Wrapper for {@link useGenerator} that can accept a {@link ChangeEvent}. */
export function useURLGenerator(): (url: string, options?: URLOptions) => void {
    // Load the file dispatch.
    const dispatch = useResourceDispatch();
    // Load the current zaq id.
    const { wiki } = useContext(ReaderContext);
    // Load the error context.
    const notify = useNotify();

    // Generate the file.
    return useCallback(
        function urlGenerator(url: string, options?: URLOptions): void {
            // Create the file.
            const file: ZaqWikiFile.Create = {
                type: ZaqWikiFile.Type,
                attributes: { name: options?.name ?? url },
                relationships: {
                    wiki: { data: { type: ZaqWiki.Type, id: wiki.id } },
                },
                links: { url },
            };
            dispatch(ZaqWikiFileStore.generator.create(file, { include: [ZaqWiki.Type] })).catch(notify.fatal);
        },
        [notify.fatal, dispatch, wiki.id]
    );
}

/** Helper hook used to return a dispatch for the file generator action. */
export function useGenerator(): (file: File) => void {
    // Load the file dispatch.
    const dispatch = useResourceDispatch();
    // Load the current zaq id.
    const readerContext = useContext(ReaderContext);
    // Load the error context.
    const notify = useNotify();
    // Load the conversion builder.
    const assetBuilder = useAssetPreparator();
    const converter = useFileUpdater();

    // Return the generator.
    return useCallback(
        async function createFile(file: File): Promise<void> {
            // If the content type is empty, try to parse it from the file extension.
            let type = file.type.trim();
            if (type.length < 1) {
                // Parse the type.
                type = mime.getType(file.name) ?? "application/octet-stream";

                // Re-build the file from source.
                file = new File([file], file.name, { type, lastModified: file.lastModified });
            }

            try {
                // Prepare the asset.
                const asset = await assetBuilder(file);
                await storeWebAsset(asset);

                // Create the file.
                const fileCreate: ZaqWikiFile.Create = {
                    type: ZaqWikiFile.Type,
                    attributes: { name: "" },
                    relationships: {
                        wiki: { data: { type: ZaqWiki.Type, id: readerContext.wiki.id } },
                        asset: { data: { type: Asset.Type, id: asset.resource.id } }
                    },
                };
                const wikiFile = await dispatch(
                    ZaqWikiFileStore.generator.create(fileCreate, { include: [ZaqWiki.Type] })
                ).catch(notify.fatal);

                // Convert the asset if it is an image of a video.
                if (wikiFile && (
                    asset.resource.attributes.contentType.startsWith("image/") ||
                    asset.resource.attributes.contentType.startsWith("video/")
                )) {
                    converter(asset, wikiFile);
                } else {
                    await uploadWebAsset(asset);
                }
            } catch (e: unknown) {
                console.error("Failed to generate a file: ", e);
                notify.warning(
                    "Impossible de générer un module",
                    "Une erreur est survenue pendant la création d'un module."
                );
            }
        },
        [assetBuilder, converter, dispatch, notify, readerContext.wiki.id]
    );
}

/** Helper hook used to generate a file updater. */
export function useFileUpdater(): (asset: WebAsset, file: ZaqWikiFile) => void {
    const notify = useNotify();

    // Load the callbacks.
    const converter = useAssetConverter({ width: 1920, height: 1080 });
    const dispatch = ZaqWikiFileStore.useDispatch();

    // Return the callback.
    return useCallback(async function convertAndUpdateFile(asset: WebAsset, file: ZaqWikiFile): Promise<void> {
        try {
            const converted = await converter(asset);

            // Update the file.
            const update: ZaqWikiFile.Update = {
                type: ZaqWikiFile.Type, id: file.id,
                relationships: {
                    asset: { data: { type: Asset.Type, id: converted.resource.id }}
                }
            };
            dispatch((dispatch, getState) => {
                if (getState()["zaq-wiki-file"]?.resources.find(f => f.id === file.id)) {
                    return dispatch(ZaqWikiFileStore.generator.update(update));
                }
                return Promise.resolve();
            }).catch(notify.fatal);
        } catch (e: unknown) {
            console.warn("Failed a conversion.", e);
            await sleep(5000);
            return convertAndUpdateFile(asset, file);
        }
    }, [converter, dispatch, notify.fatal]);
}

/** Options passed to the {@link useURLGenerator} output. */
interface URLOptions {
    /** Name of the generated file. */
    name?: string;
    /**
     * Type of the url.
     * @default text/uri
     */
    contentType?: string;
}
