/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useState, useMemo, useCallback, useEffect, useContext } from "react";
// Import the PopperJS hook.
import { usePopper } from "react-popper";
// Import the QR-code generator.
import { QRCodeToDataURLOptions, toDataURL } from "qrcode";
// Import the fontawesome component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the button component.
import ToggleableButton, {
    makeToggleableButtonContext,
    withToggleableButtonContextProvider
} from "../toggleable-button";

// Import the icons.
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLink, faShare, faQrcode } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./index.module.scss";


/** Context used for the share button. */
const shareContext = makeToggleableButtonContext();
/** Wrap the share button in a ToggleableButtonContextProvider. */
export default withToggleableButtonContextProvider(ShareButton, shareContext);

/**
 * Button used to share something.
 * Items can be shared with a direct link, a QR code or an email.
 */
function ShareButton(props: ShareButtonProps): ReactElement {
    // Load the context state.
    const { state: isMenuOpen, toggle } = useContext(shareContext);

    // Load the popperjs toolbar.
    const [button, setButton] = useState<HTMLButtonElement | null>(null);
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const [arrow, setArrow] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(button, container, {
        placement: window.innerWidth < 768 ? "bottom" : "left",
        modifiers: [
            { name: "arrow", options: { element: arrow } },
            { name: "offset", options: { offset: [0, 8] } }
        ]
    });

    // Close the context when it is opened and clicked outside of.
    useEffect(function attachCloseListener(): void | (() => void) {
        // If the container reference is not visible, do nothing.
        if (!isMenuOpen) {
            return;
        }

        // Attach the listener.
        setTimeout(() => window.addEventListener("pointerdown", checkIfClickFellInBounds), 0);
        return () => window.removeEventListener("pointerdown", checkIfClickFellInBounds);

        // Listener that will check if the click happened inside the container.
        function checkIfClickFellInBounds(event: PointerEvent): void {
            // If the container or button are not set, do nothing.
            if (!container || !button) {
                return;
            }

            // Get the position of the click.
            const position = { x: event.clientX, y: event.clientY };
            const containerBounds = container.getBoundingClientRect();
            const buttonBounds = button.getBoundingClientRect();

            // Check if the click was outside the bounds of the menu and its button.
            if ((
                   position.x < containerBounds.left
                || position.x > containerBounds.right
                || position.y < containerBounds.top
                || position.y > containerBounds.bottom
            ) && (
                   position.x < buttonBounds.left
                || position.x > buttonBounds.right
                || position.y < buttonBounds.top
                || position.y > buttonBounds.bottom
            )) {
                // Close the menu.
                toggle(false);
            }
        }
    }, [button, container, isMenuOpen, toggle]);

    // Data URI for the QR-code.
    const [qrCode, setQrCode] = useState<string>();
    const [qrCodeElement, setQrCodeElement] = useState<ReactElement>(
        <FontAwesomeIcon icon={faQrcode} className={css["share-menu__share-button__icon"]} />
    );
    useEffect(function generateQRCode(): void {
        toDataURL(props.url, options).then(setQrCode);
    }, [props.url]);
    useEffect(function generateQRCodeIcon(): void {
        if (qrCode) {
            setQrCodeElement(<img alt="qr-code" src={qrCode} className={css["share-menu__share-button__icon"]} />);
        }
    }, [qrCode]);

    // Build the mailto uri.
    const mailto = useMemo(function sendMail(): string {
        // Build the mailto uri.
        const parameters = new URLSearchParams();
        if (props.mailSubject) {
            parameters.set("subject", props.mailSubject.replace(/ /g, "%20"));
        }
        let mailBody = props.mailBody;
        if (mailBody) {
            if (mailBody.includes(URI_REPLACE)) {
                mailBody = mailBody.replace(new RegExp(URI_REPLACE, "g"), props.url);
            } else {
                mailBody += " " + props.url;
            }
        } else {
            mailBody = props.url;
        }
        parameters.set("body", mailBody.replace(/ /g, "%20"));

        return `mailto:?${parameters.toString()}`;
    }, [props.mailBody, props.mailSubject, props.url]);


    // Build the QR-code sahring callback.
    const shareQR = useCallback(function shareQR(): void {
        // Ensure that the QR-code was loaded.
        if (qrCode) {
            // Decode the qr-code png from the data URI.
            const buffer = Uint8Array.from(window.atob(qrCode.split(",")[1]), c => c.charCodeAt(0));
            shareQRCodeImage(
                new File(
                    [buffer],
                    `${props.qrCodeName || encodeURI(props.url)}.png`,
                    { type: "image/png" }
                )
            ).then(success => console.log("QR-code was copied successfully ? %s", success));
        }
    }, [qrCode, props.url, props.qrCodeName]);


    // Render the component.
    return <div className={css["share-menu__container"]}>
        <ToggleableButton context={shareContext} ref={setButton} className={css["share-menu__button"]}>
            <FontAwesomeIcon icon={faShare} className={css["share-menu__button__icon"]} />
        </ToggleableButton>

        <div
            className={`${css["share-menu"]} ${isMenuOpen ? css["share-menu--open"] : ""}`}
            ref={setContainer}
            style={styles["popper"]}
            {...attributes["popper"]}
        >
            <button className={css["share-menu__share-button"]} onClick={() => shareLink(props.url, props.sharedText)}>
                <div className={css["share-menu__share-button__icon__container"]}>
                    <FontAwesomeIcon icon={faLink} className={css["share-menu__share-button__icon"]} />
                </div>
                <p className={css["share-menu__share-button__text"]}>Lien</p>
            </button>
            <button className={css["share-menu__share-button"]} onClick={shareQR}>
                <div className={css["share-menu__share-button__icon__container"]} children={qrCodeElement} />
                <p className={css["share-menu__share-button__text"]}>QR Code</p>
            </button>
            <a className={css["share-menu__share-button"]} href={mailto}>
                <div className={css["share-menu__share-button__icon__container"]}>
                    <FontAwesomeIcon icon={faEnvelope} className={css["share-menu__share-button__icon"]} />
                </div>
                <p className={css["share-menu__share-button__text"]}>Mail</p>
            </a>
            <div className={css["share-menu__arrow"]} ref={setArrow} style={styles["arrow"]} />
        </div>
    </div>;
}

/**
 * Props passed down to the {@link ShareButton} component.
 */
export interface ShareButtonProps {
    /** URL that will be shared. */
    url: string;

    /**
     * Position of the menu.
     *
     * @default "auto"
     */
    position?: "top" | "right" | "bottom" | "left" | "auto";

    /** Name of the generated QR-code. */
    qrCodeName?: string;

    /** Subject of the mail to send while sharing. */
    mailSubject?: string;

    /**
     * Body of the mail to send while sharing.
     * The URL can be injected with the {@link URI_REPLACE} variable.
     */
    mailBody?: string;
    /** Text that will be added when the link is shared. */
    sharedText?: string;
}

/** Value to replace in the {@link ShareButtonProps.mailBody} string. */
const URI_REPLACE = "__URI__" as const;

/**
 * Tries to share a link object.
 *
 * @param {string} url The link to share.
 * @param {string | undefined} [text=undefined] The text to show next to the link.
 */
async function shareLink(url: string, text?: string): Promise<boolean> {
    // Try to share with the Web Share API.
    if (await share({ url, text })) {
        return true;
    }

    // Copy the url directly into the clipboard.
    if (typeof navigator.clipboard === "object" && typeof navigator.clipboard.writeText === "function") {
        return navigator.clipboard.writeText(`${text ? (text + "\n") : ""}${url}`)
            .then(() => window.alert("Le lien a été copié dans le presse-papier !"))
            .then(() => true, () => false);
    }

    return false;
}

/**
 * Tries to share a file.
 *
 * @param {File} qr The qr code to share.
 */
async function shareQRCodeImage(qr: File): Promise<boolean> {
    // Copy the url directly into the clipboard.
    if (typeof navigator.clipboard === "object" && typeof navigator.clipboard.write === "function") {
        return navigator.clipboard.write([new ClipboardItem({ [qr.type]: qr })])
            .then(() => window.alert("Le QR-Code a été copié dans le presse-papier !"))
            .then(() => true, () => false);
    }

    window.alert("Impossible de copier le QR-Code dans votre presse-papier.");
    return false;
}

/**
 * Method used to share an item via the {@link navigator.share} method.
 *
 * @param {ShareData} item The item to share.
 * @return {Promise<boolean>} A promise that resolves with {@code true} if the share was successful.
 */
function share(item: ShareData): Promise<boolean> {
    // Check if the share function is defined and the item can be shared.
    if (typeof navigator.share === "function" && navigator.canShare(item)) {
        // Invoke the share parameter.
        return navigator.share(item).then(() => true, () => false);
    }

    // Return a failure.
    return Promise.resolve(false);
}

/** Options passed to the {@link toDataURL} method. */
const options: Readonly<QRCodeToDataURLOptions> = {
    type: "image/png",
    color: { light: "#00000000", dark: "#359dd1" },
    margin: 0,
    width: (6 - 0.25) * 256,
    errorCorrectionLevel: "high"
} as const;

