/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


// Import React.
import type { ReactElement } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the icons.
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./common.module.scss";


/** Component used to show the user that there is no document here. */
export default function PDFNoDataMessage(): ReactElement {
    return <div className={css["message"]}>
        <FontAwesomeIcon icon={faQuestion} className={css["message__icon"]} />
        <span className={css["message__text"]}>Aucun document trouvé</span>
    </div>;
}
