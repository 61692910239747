/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { useEffect, useState } from "react";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the resources.
import type { Asset } from "@andromeda/resources";
// Import the store.
import { AssetStore, useResourceDispatch } from "@andromeda/store";

// Import the local asset loader.
import { loadWebAsset } from "../lib";


/** Helper hook used to load a {@link Asset} resource. */
export function useAssetResource(id?: string): RequestStatus<Asset> {
    // Get the store dispatch.
    const dispatch = useResourceDispatch();

    // Store the loaded web asset.
    const [resource, setResource] = useState<RequestStatus<Asset>>(RequestStatus.uninitialised());

    // Load the web-asset when the hook is mounted.
    useEffect(function(): void {
        // If the id is invalid, do nothing.
        if (!id) {
            return setResource(RequestStatus.uninitialised());
        }
        setResource(RequestStatus.loading());

        (async function downloadWebAsset(id: string): Promise<Asset> {
            // Try to load the asset from the local database.
            const local = await loadWebAsset(id);
            if (typeof local !== "undefined") {
                return local.resource;
            }

            // Download the resource from the server.
            return await dispatch(AssetStore.generator.readOne(id));
        })(id).then(RequestStatus.success<Asset>).catch(RequestStatus.error).then(setResource);
    }, [dispatch, id]);

    return resource;
}
