/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";

// Import the context.
import { SearchBarContext } from "./context";
// Import the list.
import { SearchBarListProps, SearchBarList } from "./list";

// Import the magnifying glass icon.
import glass from "@andromeda/assets/images/glass.svg";
// Import the css.
import css from "./input.module.scss";


/** Function component used to render a search bar input. */
export function SearchBarInput<T>(props: SearchBarInputProps<T>): React.ReactElement {
    // Load the context from the props.
    const context = React.useContext(props.context);

    // Store the state of focus of the element.
    const [focused, setFocused] = React.useState(false);

    // Registers a callback that clears the focused state if a user clicks outside the container.
    const [element, setElement] = React.useState<HTMLElement | null>(null);
    React.useEffect(function attachClickListener(): void | (() => void) {
        if (!focused) {
            if (element) {
                element.blur();
            }
            return;
        }

        window.addEventListener("click", checkIfClickIsInElement, { passive: true });
        return () => window.removeEventListener("click", checkIfClickIsInElement);

        function checkIfClickIsInElement(event: MouseEvent): void {
            if (!element) {
                return;
            }

            // Check if the element is inside any child.
            if (!isInBounds(element)) {
                setFocused(false);
            }


            // Recursively checks if the event is inside the given item.
            function isInBounds(of: Element): boolean {
                const bounds = of.getBoundingClientRect?.call(of);
                if (typeof bounds === "undefined") {
                    console.warn(of);
                    return true;
                }

                // Recurse.
                for (let i = 0; i < of.children.length; i++) {
                    if (isInBounds(of.children[i])) {
                        return true;
                    }
                }

                return event.clientX > bounds.left && event.clientX < bounds.right &&
                    event.clientY > bounds.top && event.clientY < bounds.bottom;
            }
        }
    }, [element, focused]);

    // Render the component.
    return <div
        className={`${css["input"]} ${props.className}`}
        onFocus={() => setFocused(true)}
        ref={setElement}
    >
        {props.withoutList ?
            null :
            <SearchBarList
                context={props.context}
                direction={props.direction}
                Renderer={props.Renderer}
                focused={focused}
                NewItemRenderer={props.NewItemRenderer}
                clearFocus={() => setFocused(false)}
            />
        }
        <div className={css["input__container"]}>
            <img src={glass} alt={"glass-icon"} className={css["input__glass"]} />
            <input
                type="text"
                className={css["search-bar__input"]}
                defaultValue={context.text}
                placeholder={props.placeholder}
                onChange={ev => context.update(ev.target.value)}
                autoFocus={props.autoFocus}
            />
        </div>
    </div>;
}

/**
 * Props passed down to the {@link SearchBarInput} component.
 *
 * @template T
 */
export interface SearchBarInputProps<T> {
    /** Context object to provide. */
    context: React.Context<SearchBarContext<T>>;
    /** Class name added to the input element. */
    className?: string;
    /** If set, auto-focuses the input element. */
    autoFocus?: boolean;
    /** Optional placeholder for the input. */
    placeholder?: string;

    /** @borrows {SearchBarListProps.direction} */
    direction?: SearchBarListProps<T>["direction"];
    /** @borrows {SearchBarListProps.Renderer} */
    Renderer?: SearchBarListProps<T>["Renderer"];
    /** @borrows {SearchBarListProps.NewItemRenderer} */
    NewItemRenderer?: SearchBarListProps<T>["NewItemRenderer"];

    /** If set, does not render the search bar list. */
    withoutList?: boolean;
}
