/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the configuration tool.
import { getConfig } from "@andromeda/config";


/** Configuration used to describe the endpoint to query for the zaq conversions. */
export interface LegacyZaqConfig {
    /** The host name of the target api. */
    hostname: string;
    /** The root path of the target api. */
    root?: string;
}

/** Interface used to describe the key of the zaq config object. */
interface WithLegacyZaqConfig { "legacy-zaq": LegacyZaqConfig; }

/** Schema used to validate the {@link WithLegacyZaqConfig} interface. */
const LegacyZaqConfigSchema = {
    type: "object",
    properties: {
        "legacy-zaq": {
            type: "object",
            properties: {
                hostname: {
                    type: "string"
                },
                root: {
                    type: "string"
                }
            },
            additionalProperties: false,
            required: ["hostname"]
        }
    },
    required: ["legacy-zaq"]
};

/**
 * Helper hook used to load the legacy zaq configuration data.
 *
 * @return {LegacyZaqConfig | undefined} The loaded configuration data.
 */
export function getLegacyZaqConfig(): Promise<LegacyZaqConfig> {
    return getConfig<WithLegacyZaqConfig>(LegacyZaqConfigSchema).then(config => config["legacy-zaq"]);
}
