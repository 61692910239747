/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json:api interfaces.
import { Resource, Relationship } from "@andromeda/json-api";
// Import the json schema compiler.
import { compileSync } from "@andromeda/validation";

// Import the asset conversion request resource.
import { AssetConversionRequest } from "../request";
// Import the schema.
import * as Schema from "./schema.json";


/** Resource used to report the status of an asset conversion. */
export interface AssetConversionStatus extends Resource<AssetConversionStatus.Type> {
    /** @inheritDoc */
    attributes: AssetConversionStatus.Attributes;
    /** @inheritDoc */
    relationships: AssetConversionStatus.Relationships;
}

/** Augment the {@link AssetConversionStatus} interface. */
export namespace AssetConversionStatus {
    export const Type = "asset-conversion_conversion-status" as const;
    export type Type = typeof Type;

    /** Enumeration of all the stages of a conversion. */
    export enum ConversionStage {
        /** The converter is ready and will soon ask for the converted asset. */
        ready = "ready",
        /** The converter has started to convert the asset. */
        starting = "starting",
        /** The converter has finished converting the asset. */
        converted = "converted",
        /** The client has retrieved all the converted items. */
        done = "done"
    }

    export interface Attributes {
        /** The current stage of the conversion. */
        stage: ConversionStage;
    }

    export interface Relationships {
        /** A reference to the conversion being handled. */
        request: Relationship<AssetConversionRequest>;
    }

    export const validate = compileSync<AssetConversionStatus>(Schema);
}
