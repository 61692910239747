/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";

// Import the contexts.
import { PDFActivePageContext, PDFRenderModeContext } from "../context";
// Import the subcomponents.
import PDFPage from "./page";


/** Component used to render the pages of the PDF according to the {@link PDFRenderModeContext} mode. */
export default function PDFOneByOneContainer(): ReactElement | null {
    // Load the pdf context.
    const { activePageIndex } = useContext(PDFActivePageContext);
    // Load the rendering mode of the pages.
    const { mode } = useContext(PDFRenderModeContext);

    // Render the current page.
    if (mode === "continuous") {
        return null;
    }
    return <PDFPage index={activePageIndex} style={{}} data={null} />;
}
