/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */


/**
 * Helper used to execute a promise for exactly {@link duration} milliseconds.
 *
 * @param {number} duration The duration of the sleep period.
 * @returns {Promise<void>} A promise that resolves after {@link duration} milliseconds.
 */
export function sleep(duration: number): Promise<void> {
    return new Promise<void>(r => setTimeout(r, duration));
}
