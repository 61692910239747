/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


import { ReactElement, useCallback, useMemo } from "react";
import { MajorStep, MinorStep } from "@andromeda/legacy-zaq";
import { Validation } from "@andromeda/resources";
import Step from "./step";
import css from "./chapter.module.scss";


export default function Chapter(props: ChapterProps): ReactElement {
    // Download the validation status from the store.
    const buildStepUpdater = useCallback(function buildStepUpdater(index: number) {
        return function stepUpdate(detail: Partial<Validation.Detail>): void {
            const details = [...props.details];
            details[index] = { ...details[index], ...detail };
            props.update(details);
        };
    }, [props]);

    // Render all the steps.
    const steps = useMemo(function renderChapters(): ReactElement[] {
        return props.chapter.minorSteps.map(function renderStep(step: MinorStep, index: number): ReactElement {
            return <Step
                key={index}
                detail={props.details[index]}
                step={step}
                update={buildStepUpdater(index)}
                is-last={index >= props.chapter.minorSteps.length - 1}
            />;
        });
    }, [buildStepUpdater, props.chapter.minorSteps, props.details]);

    // Render the component.
    return <>
        <h4 className={css["title"]}>{props.chapter.name}</h4>
        {steps}
    </>;
}

export interface ChapterProps {
    details: Validation.Detail[];
    chapter: MajorStep;

    update(detail: Validation.Detail[]): void;
}
