/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useContext, useCallback, useState } from "react";
// Import the custom components.
import { AutoresizeTextarea, useNotify, SpeechRecognitionButton } from "@andromeda/components";
// Import the wiki resource.
import { ZaqWiki } from "@andromeda/resources";
// Import the stores.
import { useResourceDispatch, ZaqWikiStore } from "@andromeda/store";

// Import the reader context.
import { ReaderContext } from "../context";

// Import the css.
import css from "./body.module.scss";


/** Renders the body of the current wiki object. */
export function Body(): ReactElement {
    // Load the body of the wiki object.
    const body = useContext(ReaderContext).wiki.attributes.body;
    // Load the notify.fatal error handler.
    const notify = useNotify();

    // Store the updated text in state.
    const [text, setText] = useState(body ?? "");

    // Callback used to change the body of the wiki.
    const id = useContext(ReaderContext).wiki.id;
    const dispatch = useResourceDispatch();
    const updateBody = useCallback(function updateBody(): void {
        const update: ZaqWiki.Update = { type: ZaqWiki.Type, id, attributes: { body: text } };
        dispatch(ZaqWikiStore.generator.update(update)).catch(notify.fatal);
    }, [id, text, dispatch, notify.fatal]);

    // Render the component.
    return <div className={css["container"]}>
        <AutoresizeTextarea
            className={css["body"]}
            placeholder={"Donner une description à ce ZaqWiki"}
            rows={1}
            value={text}
            onChange={e => setText(e.currentTarget.value)}
            onBlur={updateBody}
        />
        <SpeechRecognitionButton
            onText={setText}
            onEnd={updateBody}
            interimResults
            stopWithUser
        />
    </div>;
}
