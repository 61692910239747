/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useCallback, useContext } from "react";
import { CardContainerContext } from "../container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Button from "../../button";
import css from "./navigator.module.scss";


/** Component used to render a card navigator button. */
export default function CardNavigator(props: CardNavigatorProps): ReactElement {
    // Load the card container context.
    const { current, select } = useContext(CardContainerContext);

    // Prepare the callback to invoke when clicked.
    const onClick = useCallback(function onClick(): void {
        select(current + (props.direction === "prev" ? -1 : 1));
    }, [current, props.direction, select]);

    // Render the button.
    return <Button className={`${css["button"]} ${css[`button--${props.direction}`]}`} onClick={onClick}>
        <FontAwesomeIcon icon={faChevronLeft} className={css["button__icon"]} />
    </Button>
}

/** Props passed down to the {@link CardNavigator} component. */
export interface CardNavigatorProps {
    /** The direction of the navigation. */
    direction: "prev" | "next";
}
