/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


import { ReactElement, useCallback, useContext, useMemo } from "react";
import { RequirementStore, useRequirements, useResourceDispatch, useUsers } from "@andromeda/store";
import { LoginContext } from "@andromeda/login";
import { Requirement, User } from "@andromeda/resources";
import { Loader } from "@andromeda/storybook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import css from "./user-list.module.scss";
import { useNotify } from "@andromeda/components";


export default function UserList(props: UserListProps): ReactElement {
    // Load all the user requirements.
    const users = useUsers(useContext(LoginContext).organisations.current.id);
    const requirements = useRequirements(props.course, users.data?.map(({ id }) => id));

    // Callback used to remove the requirement from the list.
    const { error } = useNotify();
    const dispatch = useResourceDispatch();
    const deleteRequirement = useCallback(function deleteRequirement(user: User): void {
        dispatch(RequirementStore.generator.delete(`${props.step}-${user.id}`)).catch(error);
    }, [dispatch, error, props.step]);

    // Render the users currently in the list.
    const items = useMemo(function renderLevelUsers(): ReactElement[] {
        if (!requirements.isSuccess) {
            return [];
        }

        // Render the user list.
        return requirements.data.reduce(
            function renderRequirements(elements: ReactElement[], requirement: Requirement): ReactElement[] {
                // Check if the requirement should be rendered.
                if (requirement.relationships.step.data.id !== props.step) {
                    return elements;
                }
                if (requirement.attributes.requiredLevel !== props.level) {
                    return elements;
                }

                // Load the requirement's user.
                const user = users.data?.find(user => user.id === requirement.relationships.user.data.id);
                if (!user) {
                    return elements;
                }

                // Render the item.
                elements.push(<li className={css["user"]} key={user.id}>
                    <button className={css["delete-button"]} onClick={() => deleteRequirement(user)}>
                        <FontAwesomeIcon className={css["delete-button__icon"]} icon={faTrash} />
                    </button>
                    <span className={css["user__name"]}>{user.attributes.givenName}</span>
                </li>);
                return elements;
            },
            []
        );
    }, [deleteRequirement, props.level, props.step, requirements.data, requirements.isSuccess, users.data]);

    // Render the component.
    if (!users.isSuccess || !requirements.isSuccess) {
        return <Loader text="Chargement des utilisateurs assignés à ce ZaqTraining ..." />;
    }
    return <ul className={css["user-list"]} children={items} />;
}

export interface UserListProps {
    course: string;
    step: string;
    level: Requirement.Level;
}
