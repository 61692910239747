/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useMemo } from "react";
// Import the CSS class name helper.
import classNames from "classnames";

// Import the css.
import css from "./date.module.scss";


/** Component used to render the due date of a given {@link ZaqTraining}. */
export default function TrainingDueDate(props: TrainingDueDateProps): ReactElement {
    // Convert the date to a string.
    const date = useMemo(function dateToString(): ReactNode {
        // If the date is not set, return an empty string.
        if (props.due === null) {
            return null;
        }

        // Get the components of the date.
        const day = props.due.getDate().toString(10).padStart(2, "0");
        const month = (props.due.getMonth() + 1).toString(10).padStart(2, "0");
        const year = props.due.getFullYear().toString(10).slice(2);

        // Return the date.
        return `${day}/${month}/${year}`;
    }, [props.due]);

    // Render the component.
    return <div className={classNames(css["due-date"], props.className)} children={date} />;
}

/** Props passed down to the {@link TrainingDueDate} component. */
export interface TrainingDueDateProps {
    /** The date at which the training is due. */
    due: Date | null;
    /** Optional class name added to the root <div> element. */
    className?: string;
}
