/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./speech-recognition.d.ts" />

// Import React.
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the notification tool.
import { useNotify } from "../notification";

// Import the icons.
import { faMicrophone } from "@fortawesome/free-solid-svg-icons/faMicrophone";
// Import the css.
import css from "./index.module.scss";


export function SpeechRecognitionButton(props: SpeechRecognitionButtonProps): ReactElement | null {
    // Get the notification tool.
    const { error } = useNotify();

    // Flag set if the microphone is currently listening.
    const [listening, setIsListening] = useState(false);

    // Build the recognition tool.
    const [recognition, setRecognition] = useState<SpeechRecognition>();
    useEffect(function initialiseRecognitionTool(): void | VoidFunction {
        // Check if voice recognition is available in this browser.
        if (typeof webkitSpeechRecognition === "undefined") {
            return;
        }

        // Initialise the recognition tool.
        const recognition = new webkitSpeechRecognition();
        recognition.lang = props.lang ?? "fr-FR";
        recognition.continuous = props.continuous ?? false;
        recognition.interimResults = props.interimResults ?? false;
        recognition.maxAlternatives = props.maxAlternatives ?? 1;
        setRecognition(recognition);

        // Add listeners for the states of the recognition.
        recognition.addEventListener("audiostart", onRecognitionEvent);
        recognition.addEventListener("audioend", onRecognitionEvent);
        recognition.addEventListener("soundstart", onRecognitionEvent);
        recognition.addEventListener("soundend", onRecognitionEvent);
        recognition.addEventListener("speechstart", onRecognitionEvent);
        recognition.addEventListener("speechend", onRecognitionEvent);
        recognition.addEventListener("start", onRecognitionEvent);
        recognition.addEventListener("end", onRecognitionEvent);
        recognition.addEventListener("nomatch", onRecognitionEvent);
        recognition.addEventListener("result", onRecognitionEvent);
        recognition.addEventListener("error", onRecognitionEvent);

        // Delete the tool when the hook is unmounted.
        return function deleteRecognitionTool(): void {
            // Remove the listeners for the states of the recognition.
            recognition.removeEventListener("audiostart", onRecognitionEvent);
            recognition.removeEventListener("audioend", onRecognitionEvent);
            recognition.removeEventListener("soundstart", onRecognitionEvent);
            recognition.removeEventListener("soundend", onRecognitionEvent);
            recognition.removeEventListener("speechstart", onRecognitionEvent);
            recognition.removeEventListener("speechend", onRecognitionEvent);
            recognition.removeEventListener("start", onRecognitionEvent);
            recognition.removeEventListener("end", onRecognitionEvent);
            recognition.removeEventListener("nomatch", onRecognitionEvent);
            recognition.removeEventListener("result", onRecognitionEvent);
            recognition.removeEventListener("error", onRecognitionEvent);

            recognition.abort();
            setRecognition(undefined);
        };

        /** Helper used to handle recognition events. */
        function onRecognitionEvent(event: Event): void {
            // Check if an error occurred.
            if (event.type === "error") {
                const { error: err, message } = event as SpeechRecognitionErrorEvent;
                switch (err) {
                case "no-speech":
                case "aborted":
                    return;
                case "audio-capture":
                    return error(event, "Pas de microphone", "Impossible de capturer votre voix");
                case "network":
                    return error(
                        event,
                        "Pas de connection",
                        "Une connection internet est requise pour la reconnaissance vocale."
                    );
                case "not-allowed":
                case "service-not-allowed":
                    return error(
                        event,
                        "Pas d'autorisation",
                        "Vous n'avez pas donné l'autorisation d'utiliser la reconnaissance vocale"
                    );
                case "language-not-supported":
                    return error(
                        event,
                        "Langage pas disponible",
                        "La reconnaissance vocale n'est pas disponible en français sur votre appareil"
                    );
                default:
                    return error(event, err, message);
                }
            }

            // Check if the audio is being captured.
            if (event.type === "audiostart" || event.type === "speechstart") {
                return setIsListening(true);
            }
            if (event.type === "audioend" || event.type === "speechend" || event.type === "end") {
                return setIsListening(false);
            }
        }
    }, [error, props.continuous, props.interimResults, props.lang, props.maxAlternatives]);

    // Bind all the events to the recognition tool.
    useEffect(
        function attachListenersToRecognition(): void | VoidFunction {
            // Wait for the recognition tool to be loaded.
            if (!recognition) {
                return;
            }

            // Add all the event listeners.
            props.onAudioStart && recognition.addEventListener("audiostart", props.onAudioStart);
            props.onAudioEnd && recognition.addEventListener("audioend", props.onAudioEnd);
            props.onSoundStart && recognition.addEventListener("soundstart", props.onSoundStart);
            props.onSoundEnd && recognition.addEventListener("soundend", props.onSoundEnd);
            props.onSpeechStart && recognition.addEventListener("speechstart", props.onSpeechStart);
            props.onSpeechEnd && recognition.addEventListener("speechend", props.onSpeechEnd);
            props.onResult && recognition.addEventListener("result", props.onResult);
            props.onNoMatch && recognition.addEventListener("nomatch", props.onNoMatch);
            props.onError && recognition.addEventListener("error", props.onError);
            props.onStart && recognition.addEventListener("start", props.onStart);
            props.onEnd && recognition.addEventListener("end", props.onEnd);

            return function removeListenersFromRecognition(): void {
                // Remove all the event listeners.
                props.onAudioStart && recognition.removeEventListener("audiostart", props.onAudioStart);
                props.onAudioEnd && recognition.removeEventListener("audioend", props.onAudioEnd);
                props.onSoundStart && recognition.removeEventListener("soundstart", props.onSoundStart);
                props.onSoundEnd && recognition.removeEventListener("soundend", props.onSoundEnd);
                props.onSpeechStart && recognition.removeEventListener("speechstart", props.onSpeechStart);
                props.onSpeechEnd && recognition.removeEventListener("speechend", props.onSpeechEnd);
                props.onResult && recognition.removeEventListener("result", props.onResult);
                props.onNoMatch && recognition.removeEventListener("nomatch", props.onNoMatch);
                props.onError && recognition.removeEventListener("error", props.onError);
                props.onStart && recognition.removeEventListener("start", props.onStart);
                props.onEnd && recognition.removeEventListener("end", props.onEnd);
            };
        },
        [
            props.onAudioEnd,
            props.onAudioStart,
            props.onEnd,
            props.onError,
            props.onNoMatch,
            props.onResult,
            props.onSoundEnd,
            props.onSoundStart,
            props.onSpeechEnd,
            props.onSpeechStart,
            props.onStart,
            recognition
        ]
    );

    // Callback used to toggle between listening and not listening.
    const running = useRef(false);
    const toggle = useCallback(function toggleListeningState(): void {
        if (!running.current) {
            recognition?.start();
            running.current = true;
        } else {
            recognition?.stop();
            running.current = false;
        }
    }, [recognition]);

    // Stop listening after some time without text.
    useEffect(function stopAfterNoTextForAWhile(): void | VoidFunction {
        let timeout: ReturnType<typeof setTimeout> | null = null;

        // Wait for the system to be listening.
        if (!recognition || !listening || !props.stopWithUser) {
            return;
        }

        // Add a listener to the result event to trigger a new timeout.
        recognition.addEventListener("result", onResult);

        // Remove the callback when the hook is unmounted.
        return function removeEventListener(): void {
            recognition.removeEventListener("result", onResult);
        }

        /** Helper used to trigger a timeout 1s after the last result. */
        function onResult(): void {
            // Delete the existing timeout.
            if (timeout !== null) {
                clearTimeout(timeout);
            }

            // Start a new timeout.
            timeout = setTimeout(function stopListeningToUser(): void {
                // Stop the recognition.
                if (running.current) {
                    running.current = false;
                    recognition?.stop();
                }

                // Clear the listening flag.
                setIsListening(false);
            }, 1000);
        }
    }, [listening, props.stopWithUser, recognition]);

    // Propagate the text to the parent.
    const { onText } = props;
    useEffect(function extractTextFromRecognition(): void | VoidFunction {
        // If the callback is not set, do nothing.
        if (!onText || !recognition) {
            return;
        }

        // Listen for events from the recognition tool.
        recognition.addEventListener("result", onResults);

        // Remove the event listeners from the recognition.
        return function removeEventListener(): void {
            recognition.removeEventListener("result", onResults);
        }

        // Callback invoked when text is returned from the recognition tool.
        function onResults(event: SpeechRecognitionEvent): void {
            // Check if there are results in the event.
            if (event.results.length <= 0) {
                return;
            }
            const results = event.results.item(0);
            if (results.length <= 0) {
                return;
            }

            // Invoke the text callback.
            let { transcript } = results.item(0);
            transcript = transcript.slice(0, 1).toUpperCase() + transcript.slice(1);
            onText?.(transcript);
        }
    }, [onText, recognition]);

    if (typeof recognition === "undefined") {
        return null;
    }
    return <button
        type="button"
        onClick={toggle}
        className={classNames(css["microphone"], { [css["microphone--active"]]: listening }, props.className)}
    >
        <FontAwesomeIcon icon={faMicrophone} className={classNames(css["microphone__icon"], props.iconClassName)} />
    </button>;
}

/** Props passed down to the {@link SpeechRecognitionButton} component. */
export interface SpeechRecognitionButtonProps {
    /** @see SpeechRecognition.lang */
    lang?: string;
    /** @see SpeechRecognition.continuous */
    continuous?: boolean;
    /** @see SpeechRecognition.interimResults */
    interimResults?: boolean;
    /** @see SpeechRecognition.maxAlternatives */
    maxAlternatives?: number;
    /** Stops the recognition as soon as the user stops speaking. */
    stopWithUser?: boolean;
    /** Class name added to the button. */
    className?: string;
    /** Class name added to the icon. */
    iconClassName?: string;

    /** @see SpeechRecognitionEventMap.audiostart */
    onAudioStart?(event: Event): void;

    /** @see SpeechRecognitionEventMap.audioend */
    onAudioEnd?(event: Event): void;

    /** @see SpeechRecognitionEventMap.soundstart */
    onSoundStart?(event: Event): void;

    /** @see SpeechRecognitionEventMap.soundend */
    onSoundEnd?(event: Event): void;

    /** @see SpeechRecognitionEventMap.speechstart */
    onSpeechStart?(event: Event): void;

    /** @see SpeechRecognitionEventMap.speechend */
    onSpeechEnd?(event: Event): void;

    /** @see SpeechRecognitionEventMap.result */
    onResult?(event: SpeechRecognitionEvent): void;

    /** @see SpeechRecognitionEventMap.nomatch */
    onNoMatch?(event: SpeechRecognitionEvent): void;

    /** @see SpeechRecognitionEventMap.error */
    onError?(event: SpeechRecognitionErrorEvent): void;

    /** @see SpeechRecognitionEventMap.start */
    onStart?(event: Event): void;

    /** @see SpeechRecognitionEventMap.end */
    onEnd?(event: Event): void;

    /**
     * Callback invoked every time a new text string is generated by the recognition service.
     *
     * @param {string} text The text returned by the service.
     */
    onText?(text: string): void;
}
