/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


import { ReactElement } from "react";

import css from "./tab-selector.module.scss";


export default function TabSelector(props: TabSelectorProps): ReactElement {
    // Render the tabs.
    return <div className={css["container"]}>
        <button
            className={css["tab"]}
            disabled={props.current === "zaq-tuto"}
            onClick={() => props.onChange("zaq-tuto")}
            children="ZaqTuto"
        />
        <button
            className={css["tab"]}
            disabled={props.current === "zaq-wiki"}
            onClick={() => props.onChange("zaq-wiki")}
            children="ZaqWiki"
        />
        <button
            className={css["tab"]}
            disabled={props.current === "external"}
            onClick={() => props.onChange("external")}
            children="Externe"
        />
    </div>;
}

    export type Tab
=
    "zaq-tuto" | "zaq-wiki" | "external";
    export interface TabSelectorProps
{
    current: Tab;
    onChange
    (
    tab: Tab): void;
}
