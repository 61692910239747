/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the debug tool.
import debug from "debug";

// Import the xhr headers interface.
import { XhrHeaders, XhrFetchOptions } from "../../interfaces";


/** Variable used to store the authentication token. */
let token: string | null = null;

/**
 * Simple method used to set the authentication token globally.
 * The token will be added to every request made with the Xhr package.
 *
 * @param {string} value The new value of the token.
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export function setAuthToken(value: string): void {
    log("Storing a new auth token");
    token = value;
}

/**
 * Clears the globally set authentication token.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export function clearAuthToken(): void {
    log("Clearing the auth token");
    token = null;
}

/**
 * Internal method used to inject the "Authorization" header into the requests.
 *
 * @param {XhrHeaders} into The object to inject the header into.
 * @param {XhrFetchOptions} [options={ method: "GET" }] The options of the request.
 */
export function injectAuthHeader(into: XhrHeaders, options: XhrFetchOptions = { method: "GET" }): void {
    if (options.configuration?.noAuthorization) {
        log("Clearing the authorization token for this request");
        delete into["Authorization"];
    } else if (typeof token === "string" && !("authorization" in into)) {
        log("Injecting the authorization header from the stored token");
        into["Authorization"] = `Bearer ${token}`;
    }
}

const log = debug("xhr:services:auth");
