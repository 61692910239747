/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the debug module.
import debug from "debug";
import { useState } from "react";


/** Helper hook used to detect clicks that happened outside an element. */
export function useOutsideClick<T extends HTMLElement = HTMLElement>(
    callback: () => void,
    checkChildren = false
): React.Ref<T> {
    // Stores the reference to the element to watch.
    const [ref, setRef] = useState<T | null>(null);

    // Attach the callback.
    React.useEffect(function attachClickListener(): void | (() => void) {
        if (ref === null) {
            log("Ref is null, clearing all listeners.");
            return;
        }

        // Mutex-like object used to ensure that "removeEventListener" was not called
        // BEFORE "addEventListener".
        let wasDestroyed = false;
        // Attach the listener to the window.
        // Wait for the current click event to be flushed.
        setTimeout(() => {
            if (wasDestroyed) return;
            log("Attaching the listeners to the provided ref %o", ref);
            window.addEventListener("click", onWindowClick);
            window.addEventListener("touchend", onWindowClick);
        }, 500);
        return function destroy(): void {
            wasDestroyed = true;
            log("Removing all click listeners for %o", ref);
            window.removeEventListener("click", onWindowClick);
            window.removeEventListener("touchend", onWindowClick);
        }

        /** Function used to handle click events. */
        function onWindowClick(event: MouseEvent | TouchEvent): void {
            if (!ref) return;
            log("Received a click event, checking if it falls in the bounds of the ref");

            // Get the reference rect and clicked area.
            let pos: { x: number, y: number };
            if ("clientX" in event) {
                pos = { x: event.clientX, y: event.clientY };
            } else {
                const touch = event.touches.item(0);
                if (!touch) return;
                pos = { x: touch.clientX, y: touch.clientY };
            }

            // Check all the bound items.
            if (!checkBounds(ref, pos)) {
                callback();
            }
        }

        /** Checks the bounds of a given click. */
        function checkBounds(element: Element, pos: { x: number, y: number }): boolean {
            const refBounds = element.getBoundingClientRect();

            // Check if the reference was clicked.
            if (pos.x > refBounds.x &&
                pos.y > refBounds.y &&
                pos.x < refBounds.x + refBounds.width &&
                pos.y < refBounds.y + refBounds.height
            ) {
                log("Click was within the bounds of %o", element);
                return true;
            }

            return checkChildren && Array.from(element.children).some(element => checkBounds(element, pos));
        }
    }, [callback, checkChildren, ref]);

    return setRef;
}

const log = debug("components:hooks:outside-click");
