/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useContext, useMemo } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the stores.
import { ZaqWikiFileStore, ZaqWikiStore } from "@andromeda/store";
// Import the resources.
import { ZaqWikiFile } from "@andromeda/resources";
// Import the storybook components.
import { CardContainerContext } from "@andromeda/storybook";

// Import the icon helper.
import { useIcon } from "../helpers";
// Import the summary context.
import { SummaryContext } from "./toggleable-context";

// Import the icons.
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
// Import the css.
import css from "./summary.module.scss";


/** Component used to render the summary. */
export default function Summary(props: SummaryProps): ReactElement {
    // Load the toggleable context.
    const { state: isOpen, toggle } = useContext(SummaryContext);

    // Load the identifier of the requested wiki.
    const wiki = ZaqWikiStore.useSelector(({ resources }) => resources.find(r => r.id === props.id));

    // Load all the wiki's modules.
    const modules = ZaqWikiFileStore.useSelector(function findAllModules(state): ZaqWikiFile[] {
        // If the wiki was not loaded, return an empty list.
        if (!wiki) {
            return [];
        }

        // Find all the modules and return them.
        return wiki.relationships.files.data
            .map((module): ZaqWikiFile | undefined => state.resources.find(r => r.id === module.id))
            .filter((module): module is ZaqWikiFile => typeof module !== "undefined");
    });

    // Render all the items.
    const items = useMemo(function renderSummaryItems(): ReactElement[] {
        let grouped = false;
        const items: ReactElement[] = [];

        // Render all the modules.
        for (let i = 0; i < modules.length; i++) {
            items.push(<SummaryItem index={i} key={modules[i].id} module={modules[i]} grouped={grouped} />);

            // If this is a section, add a separator and indent all the children.
            if (ZaqWikiFile.isSectionFile(modules[i])) {
                grouped = true;
            }

        }

        return items;
    }, [modules]);

    // Render the component.
    return <>
        <div
            className={classNames(css["backdrop"], { [css["backdrop--visible"]]: isOpen })}
            onClick={() => toggle(false)}
        />
        <div className={classNames(css["summary"], { [css["summary--open"]]: isOpen })}>
            <div className={css["summary__header"]}>
                <span className={css["summary__title"]} children={wiki?.attributes.name} />
                <button className={css["summary__close"]} onClick={() => toggle(false)}>
                    <FontAwesomeIcon className={css["summary__close__icon"]} icon={faXmark} />
                </button>
            </div>
            <ul className={css["summary__container"]} children={items} />
        </div>
    </>;
}

export interface SummaryProps {
    id: string;
}


/** Component used to render an item for the {@link Summary} component. */
function SummaryItem(props: SummaryItemProps): ReactElement {
    // Get the currently active card.
    const { current } = useContext(CardContainerContext);
    // Load the summary context.
    const { toggle } = useContext(SummaryContext);

    // Load the icon of the module.
    const icon = useIcon(props.module, css["summary-item__icon"]);

    // Load the title of the module.
    const title = useMemo(function getTitleFromModule(): string {
        return ZaqWikiFile.isSectionFile(props.module) ? props.module.attributes.title : props.module.attributes.name;
    }, [props.module]);

    // Prepare the callback to select the card.
    const { select } = useContext(CardContainerContext);
    const onClick = useCallback(function onSummaryItemClick(): void {
        // Add one to the index to account for the title card.
        select(props.index + 1);

        // Close the summary.
        toggle(false);
    }, [props.index, select, toggle]);

    // Compute the class name of the element.
    const className = useMemo(function buildClassName(): string {
        let className = css["summary-item"];
        if (props.grouped && !ZaqWikiFile.isSectionFile(props.module)) {
            className += " " + css["summary-item--indented"];
        }
        if (current - 1 === props.index) {
            className += " " + css["summary-item--selected"];
        }
        if (ZaqWikiFile.isSectionFile(props.module)) {
            className += " " + css["summary-item--title"];
        }
        return className;
    }, [current, props.grouped, props.index, props.module]);

    // Render the component.
    return <li className={className}>
        <button className={css["summary-item__button"]} onClick={onClick}>
            <span className={css["summary-item__icon__container"]} children={icon} />
            <p className={css["summary-item__text"]} children={title} />
        </button>
    </li>;
}

/** Props passed down to the {@link SummaryItem} component. */
interface SummaryItemProps {
    /** The index of the rendered module. */
    index: number;
    /** The module to render. */
    module: ZaqWikiFile;

    /** If true, this module should be grouped under a title element. */
    grouped?: boolean;
}
