/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the bootstrap components.
import { Container } from "react-bootstrap";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the loader component.
import { Loader, SaveIndicator, DiscordWebhookProvider } from "@andromeda/components";
// Import the user store.
import { UserStore, useResourceDispatch, useIsSaving } from "@andromeda/store";
// Import the user resource.
import { User, Organisation } from "@andromeda/resources";
// Import the confirmation modal provider.
import { ConfirmProvider } from "@andromeda/components";

// Import the admin context.
import { AdminContext } from "./context";
// Import the subcomponents.
import { UserEditor } from "./user-editor";
import { UserCreator } from "./user-creator";

// Import the css.
import css from "./admin.module.scss";


/** Component used to render the administration interface. */
export function Admin(): React.ReactElement {
    // Get the login context.
    const loginCtx = React.useContext(LoginContext);
    // Get the users that belong to the current organisation.
    const users = UserStore.useSelector(state =>
        state.resources.filter(user =>
            user.relationships.organisations.data.some(org => org.id === loginCtx.organisations.current.id)
        )
    );

    // Memo used to avoid unnecessary loadOrganisationsUsers calls.
    const currentOrgId = React.useMemo(() => loginCtx.organisations.current.id, [loginCtx.organisations]);
    // Load the current organisation's users.
    const dispatch = useResourceDispatch();
    React.useEffect(function loadOrganisationUsers(): void {
        dispatch(UserStore.generator.readMany({
            filter: { user: { organisations: { $eq: currentOrgId }}}
        })).catch(console.error);
    }, [currentOrgId, dispatch]);

    // Check if the stores are saving.
    const isSavingUser = useIsSaving(User.Type);
    const isSavingOrganisation = useIsSaving(Organisation.Type);

    if (typeof users === "undefined") return <Loader text="Chargement des utilisateurs ..." />;
    return <DiscordWebhookProvider
        id="971000571832197170"
        token="Eti0I3vhRdIL_7CZAxsZ-3VIRtLJKNni2zyEtUZOiKckMDQXvX-2k3LtARa8N-FoiFhZ"
    >
        <ConfirmProvider>
            <AdminContext.Provider value={{ users }}>
                <SaveIndicator show={isSavingUser || isSavingOrganisation} />
                <Container>
                    <h1 className={css["title"]}>
                        {loginCtx.organisations.current.attributes.name} - Gestion des comptes
                    </h1>
                    <UserEditor />
                    <UserCreator />
                </Container>
            </AdminContext.Provider>
        </ConfirmProvider>
    </DiscordWebhookProvider>;
}
