/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useCallback, useContext, useEffect, useState } from "react";
// Import the xhr tools.
import { resolveURL } from "@andromeda/xhr";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the resource helper.
import { createOne } from "@andromeda/resource-helper";


/** Type alias used for the return type of {@link useCreateElement}. */
export type UseCreateElementResult = [
    createCallback: (element: "zaq" | "zaq-tuto" | "zaq-training") => VoidFunction,
    promise: Promise<string> | null
];

/** Hook used to build the elements needed to create new ZaqTuto, {@link ZaqWiki} and {@link ZaqTraining}. */
export function useCreateElement(): UseCreateElementResult {
    const auth = useContext(LoginContext);
    const [promise, setPromise] = useState<Promise<string> | null>(null);

    // Callback used to create the requested element.
    const create = useCallback(
        function buildCreator(element: "zaq" | "zaq-tuto" | "zaq-training"): VoidFunction {
            return function create(): void {
                switch (element) {
                case "zaq":
                    setPromise(createNewZaq());
                    break;
                case "zaq-tuto":
                    setPromise(createNewZaqTuto(auth.organisations.current.id, auth.self.id));
                    break;
                case "zaq-training":
                    setPromise(createNewZaqTraining(auth.organisations.current.id));
                    break;
                default:
                    setPromise(Promise.reject(new TypeError(`Cannot create a ${element} element`)));
                }
            };
        },
        [auth.organisations, auth.self.id]
    );

    // Clear the promise once it is over.
    useEffect(function clearPromiseOnFinished(): void {
        promise?.finally(() => setPromise(null));
    }, [promise]);

    return [create, promise];
}

/**
 * Helper function used to create a new ZaqTuto object.
 *
 * @param {string} organisation The organisation that will own the tuto.
 * @param {string} owner The identifier of the user that will own the tuto.
 * @returns {Promise<string>} A promise that resolves with the URL that the user should be redirected to.
 */
async function createNewZaqTuto(organisation: string, owner: string): Promise<string> {
    // Prepare the object provided in the request.
    const tuto = {
        type: "com.zimproov.api.zaqtiv",
        attributes: { descriptor: {}, tags: [] },
        relationships: {
            organisations: { data: [{ type: "com.zimproov.api.organisation", id: organisation }] },
            owner: { data: { type: "com.zimproov.api.user", id: owner } },
            editors: { data: [] },
            icon: { data: null }
        }
    };

    // Run the request.
    let response = await fetch(
        await resolveURL("legacy-api", `/organisation/${organisation}/zaqtiv`),
        {
            method: "POST",
            body: JSON.stringify({ data: tuto }),
            headers: { "Content-Type": "application/vnd.api+json" }
        }
    );

    // Grab the id from the response.
    const id = (await response.json()).data?.[0]?.id;
    if (!response.ok || typeof id !== "string") {
        throw new TypeError("Failed to generate a new ZaqTuto");
    }

    // Prepare the translation object.
    const translation = {
        type: "com.zimproov.api.zaqtiv.translation",
        attributes: { name: "", descriptor: { DisplayName: "" }, lang: "fr-FR" },
        relationships: { zaqtiv: { data: { type: tuto.type, id } } }
    };
    response = await fetch(
        await resolveURL("legacy-api", `/zaqtiv/${id}/translation`),
        {
            method: "POST",
            body: JSON.stringify({ data: translation }),
            headers: { "Content-Type": "application/vnd.api+json" }
        }
    );
    if (!response.ok) {
        throw new TypeError("Failed to generate a new ZaqTuto");
    }

    return `/editor/${id}`;
}

/**
 * Helper function used to create a new {@link ZaqTraining} object.
 *
 * @param {string} organisation The organisation that will own the training.
 * @returns {Promise<string>} A promise that resolves with the URL that the user should be redirected to.
 */
async function createNewZaqTraining(organisation: string): Promise<string> {
    // Create the training in the store.
    const training = await createOne({
        type: "course",
        attributes: { name: "" },
        relationships: { ownerOrganisation: { data: { type: "organisation", id: organisation } } }
    });

    // If the server returned a null, throw an error.
    if (training.data === null) {
        throw new Error("Failed to create the ZaqWiki");
    }

    // Redirect to the training.
    return `/zaq-training/${training.data.id}?edit`;
}


/**
 * Helper function used to create a new {@link Zaq} object.
 *
 * @returns {Promise<string>} A promise that resolves with the URL that the user should be redirected to.
 */
async function createNewZaq(): Promise<string> {
    // Create the wiki in the store.
    const { data: zaq } = await createOne({ type: "zaq" }, { headers: { "Content-Language": "fr-FR" } });

    // If the server returned a null, throw an error.
    if (zaq === null) {
        throw new Error("Failed to create the ZaqWiki");
    }

    // Redirect to the editor.
    return `/preview/editor/${zaq.id}`;
}
