/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";
// Import React–Router.
import { Link } from "react-router-dom";

// Import the contexts.
import { ValidatorSwitchContext } from "../../../context";

// Import the css.
import css from "./validate.module.scss";


/** Component used to render the validate button. */
export default function Validate(): ReactElement | null {
    const { state: validating } = useContext(ValidatorSwitchContext);

    // Render the component.
    if (!validating) {
        return null;
    }
    return <Link className={css["validate"]} to="validation">
        Valider mes apprenants sur le terrain
    </Link>;
}
