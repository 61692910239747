/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


// Import React.
import { ReactElement, useEffect, useState } from "react";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the asset tools.
import { downloadWebAsset } from "@andromeda/asset-manager";

// Import the wrapped icon component.
import IconRenderer from "./icon";


/** Component used to render the icon of a ZaqTuto. */
export default function ZaqWikiIcon(props: ZaqWikiIconProps): ReactElement | null {
    // Download the asset when the wiki is ready.
    const [asset, setAsset] = useState<RequestStatus<Blob | null>>(RequestStatus.uninitialised());
    useEffect(function downloadAssetWhenWikiIsLoaded(): void {
        // Check if the icon is set.
        if (!props.icon) {
            return setAsset(RequestStatus.success(null));
        }

        // Download the icon.
        downloadWebAsset(props.icon)
            .then(asset => new Blob([asset.data], { type: asset.resource.attributes.contentType }))
            .then(RequestStatus.success<Blob>)
            .catch(RequestStatus.error)
            .then(setAsset);
    }, [props.icon]);

    return <IconRenderer asset={asset} alt="Icône de ce ZaqWiki" />;
}

/** Props provided to the {@link ZaqWikiIcon} component. */
export interface ZaqWikiIconProps {
    /** Identifier of the loaded icon. */
    icon?: string;
    /** Class name added to the <img> element. */
    className?: string;
}
