/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the search item type tools.
import { getNameFromSearchFilter, SearchItemType } from "../../../hooks";

// Import the icons.
import { ReactComponent as Checkmark } from "@andromeda/assets/images/checkmark-colored.svg";
// Import the CSS.
import css from "./type.module.scss";


/** Component used to render the type of any given item. */
export default function ItemType(props: ItemTypeProps): ReactElement {
    return <div className={classNames(css["type"], props.className)}>
        <p className={css["text"]}>{getNameFromSearchFilter(props.type)}</p>
        <Checkmark className={css["icon"]} />
    </div>;
}

/** Props passed down to the {@link ItemType} component. */
export interface ItemTypeProps {
    /** The type of the item. */
    type: SearchItemType;
    /** Optional class name added to the <div> element. */
    className?: string;
}
