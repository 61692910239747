/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the resource identifier interface.
import { ResourceIdentifier, InferResourceIdentifier } from "./resource-identifier";
// Import the own link interface.
import { AnyLinks } from "./links";
// Import the metadata interface.
import { Metadata } from "./meta";
// Import the list of forbidden keys.
import { ForbiddenKeys } from "./common";


/**
 * Interface used to describe a relationship with only resource identifier(s).
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
interface RelationshipWithResourceIdentifier<
    Resource extends ResourceIdentifier[] | ResourceIdentifier | null = ResourceIdentifier[] | ResourceIdentifier | null
> {
    /** Point to the data in the relationship. */
    data: InferResourceIdentifier<Resource>;
}

/**
 * Interface used to describe a relationship with only links.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
interface RelationshipWithLinks {
    /** Links to the relationship object. */
    links?: AnyLinks;
}

/**
 * Interface used to describe a relationship with only metadata.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
interface RelationshipWithMetadata<T extends Metadata = Metadata> {
    /** Metadata of the relationship. */
    meta?: T;
}

/**
 * Type used to describe the union of all the possible relationship objects.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export type Relationship<
    T extends ResourceIdentifier[] | ResourceIdentifier | null = ResourceIdentifier[] | ResourceIdentifier | null,
    M extends Metadata = Metadata,
> = RelationshipWithResourceIdentifier<T> & RelationshipWithLinks & RelationshipWithMetadata<M>;

/**
 * Type used to describe a relationship with an enforced "data" member.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type RelationshipWithData<
    T extends ResourceIdentifier[] | ResourceIdentifier | null = ResourceIdentifier[] | ResourceIdentifier | null,
> = Required<RelationshipWithResourceIdentifier<T>>;

/**
 * Type used to enforce the relationships interface.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type EnforceRelationships<T extends object = object> = {
    [K in keyof T]: K extends ForbiddenKeys ? never : (T[K] extends Relationship ? T[K] : never)
};
