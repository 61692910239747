/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the uuid generator.
import { v4 } from "uuid";

// Import the error interface.
import { Error as JsonApiErrorInterface } from "../interfaces";


/**
 * Base error used for all JSON:API-serializable errors.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class JsonApiError extends Error {
    /** Instance of the JSON:API error that is assigned to this error object. */
    public readonly jsonapi: JsonApiErrorInterface;
    /** Optional inner exception that was caught. */
    public readonly inner?: unknown;

    /**
     * Class constructor.
     * Creates a new JSON:API error object.
     *
     * @param {string} message The message of the error, also used as detail if none were provided.
     * @param {JsonApiErrorInterface | undefined} [options=undefined] The inner JSON:API error options.
     */
    public constructor(message: string, options?: JsonApiErrorInterface);

    /**
     * Class constructor.
     * Transforms an {@link Error} into a JSON:API error.
     *
     * @param {unknown} source the error to convert.
     */
    public constructor(source: unknown);

    /** Class constructor implementation ! */
    public constructor(source: unknown | string, options: JsonApiErrorInterface = {}) {
        if (typeof source === "string") {
            super(source);
            this.jsonapi = this.generateError({ detail: source, ...options });
        } else {
            super("An unhandled error occurred.");
            this.inner = source;
            this.jsonapi = this.generateError(options);
            if (typeof source === "object" && source !== null && "name" in source && "message" in source) {
                this.jsonapi.title = (source as Error).name;
                this.jsonapi.detail = (source as Error).message;
            }
        }
    }

    /** Optional property used to get the code of the error. */
    protected get code(): string | undefined { return undefined; }
    /** Optional property used to get the status of the error. */
    protected get status(): string | null { return null; }

    /** Helper method used to generate a more explicit json api error. */
    private generateError(source: JsonApiErrorInterface): JsonApiErrorInterface {
        return {
            id: v4(),
            status: this.status !== null ? this.status : undefined,
            title: Object.getPrototypeOf(this).constructor.name,
            links: typeof source.code === "string" ? { about: "https://developer.zimproov.com/errors/" + source.code } : undefined,
            code: this.code,
            ...source
        };
    }
}
