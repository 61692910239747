/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { FocusEvent, ReactElement, useCallback, useContext, useMemo } from "react";
// Import the resources.
import { Step, Course } from "@andromeda/resources";
// Import the store.
import { CourseStore, useCourse, useDueDate, useRequirements, useResourceDispatch, useSteps } from "@andromeda/store";
// Import the login module.
import { LoginContext } from "@andromeda/login";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the custom components.
import { useNotify } from "@andromeda/components";

// Import the contexts.
import { EditorSwitchContext } from "../../../context";
// Import the helper tools.
import { useIsEditor } from "../../../helpers";
// Import the subcomponents.
import StepRenderer from "./step";
import Title from "../../title";

// Import the css.
import css from "./index.module.scss";
import AddStepIcon from "./add-step-icon";


/** Container component used to render all the steps of the provided course. */
export default function StepContainer(props: StepContainerProps): ReactElement {
    // Load all the steps.
    const selfId = useContext(LoginContext).self.id;
    const course = useCourse(props.course);
    const steps = useSteps(props.course);
    const requirements = useRequirements(props.course, selfId);
    const dueDate = useDueDate(props.course, selfId);
    const isEditor = useIsEditor(props.course)(selfId);

    // Render all the steps.
    const renderedSteps = useMemo(function renderSteps(): ReactElement[] {
        // Wait for the steps to be loaded.
        if (!steps.isSuccess || !course.isSuccess) {
            return [];
        }

        // Render all the steps.
        return course.data.relationships.steps.data
            .map(({ id }) => steps.data.find(step => step.id === id))
            .filter((step: Step | undefined): step is Step => typeof step !== "undefined")
            .map((step: Step, index: number) => <StepRenderer
                startsOpen={index === 0}
                course={props.course}
                step={step.id}
                key={step.id}
            />);
    }, [course.data?.relationships.steps.data, course.isSuccess, props.course, steps.data, steps.isSuccess]);

    // Callback invoked to update the name of the training.
    const dispatch = useResourceDispatch();
    const { error } = useNotify();
    const updateName = useCallback(function updateTrainingName(event: FocusEvent<HTMLTextAreaElement>): void {
        dispatch(
            CourseStore.generator.update({
                type: Course.Type,
                id: props.course,
                attributes: { name: event.currentTarget.value }
            })
        ).catch(error);
    }, [dispatch, error, props.course]);

    // Render the component.
    if (!course.isSuccess || !steps.isSuccess || !requirements.isSuccess || !dueDate.isSuccess) {
        return <Loader className={css["loader"]} transparent text="Chargement des étapes ..." />;
    }
    return <section className={css["container"]}>
        <Title
            title={course.data.attributes.name}
            showToggle={isEditor}
            toggleName="éditer"
            toggleContext={EditorSwitchContext}
            editable={isEditor}
            onChange={updateName}
        />
        {renderedSteps}
        <AddStepIcon course={props.course} />
    </section>;
}

/** Props passed down to the {@link StepContainer} component. */
export interface StepContainerProps {
    /** Identifier of the renderer course. */
    course: string;
}
