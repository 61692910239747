/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { Dispatch, useContext, useEffect, useMemo, useState } from "react";
// Import lodash.
import { noop } from "lodash";
// Import the common tools.
import { serialiseError } from "@andromeda/tools";
// Import the resources.
import { Course } from "@andromeda/resources";
// Import the store.
import { useCourses } from "@andromeda/store";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the custom components.
import { useNotify } from "@andromeda/components";


/**
 * Helper hook used to select a {@link ZaqTraining} and store its info in local storage.
 *
 * @param {boolean} enabled Flag set if the hook should return a value.
 * @returns {[(string | undefined), React.Dispatch<string>]}
 * A value-dispatch tuple with the selected {@link ZaqTraining}'s identifier.
 */
export function useTrainingSelector(enabled: boolean): [string | undefined, Dispatch<string>] {
    // Store the selected item.
    const [selected, setSelected] = useState<string>();

    // Download all the training from the store.
    const organisation = useContext(LoginContext).organisations.current.id;
    const training = useCourses(organisation);
    const localStorageKey = `andromeda/zaq-training/monitoring/selected-training/${organisation}`;

    // Select a training by default.
    useEffect(function selectDefaultTraining(): void {
        // Try to load the default training from local storage.
        const saved = window.localStorage.getItem(localStorageKey);
        if (saved !== null) {
            return setSelected(saved);
        }

        // Wait for the training list to download.
        if (!training.isSuccess || training.data.length <= 0) {
            return;
        }

        // Select the first training alphabetically.
        const first = training.data.reduce(function isBeforeAlphabetically(from: Course, current: Course): Course {
            if (current.attributes.name.localeCompare(from.attributes.name) < 0) {
                return current;
            }
            return from;
        });
        setSelected(first.id);
    }, [localStorageKey, training.data, training.isSuccess]);

    // Save the selected item to local storage.
    const { warning } = useNotify();
    useEffect(function saveSelectedToLocalStorage(): void {
        try {
            // If there is a selected item, store it in local storage.
            if (typeof selected !== "undefined") {
                window.localStorage.setItem(localStorageKey, selected);
            }
        } catch (e: unknown) {
            // Warn the user about the error.
            warning(
                "Impossible de sauvegarder une préférence",
                "Impossible de sauvegarder le ZaqTraining sélectionné dans vos préférences:\n"
                + serialiseError(e).toString()
            );
        }
    }, [localStorageKey, selected, warning]);

    // Memoize the returned tuple.
    return useMemo(function memoizeOutputTuple(): [string | undefined, Dispatch<string>] {
        // If the selector is disabled, return a noop.
        if (!enabled) {
            return [undefined, noop];
        }
        return [selected, setSelected];
    }, [enabled, selected]);
}
