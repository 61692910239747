/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { createContext } from "react";
// Import the common tools.
import { makeContextNoop } from "@andromeda/tools";


/** Object used to open the external modal from anywhere. */
export interface ExternalModalContext {
    /**
     * Callback used to open the modal.
     *
     * @param {string} external The external to edit.
     */
    show(external: string): void;
}

/** Context used to show the external modal. */
export const ExternalModalContext = createContext<ExternalModalContext>({
    show: makeContextNoop("ExternalModalContext", "show")
});
ExternalModalContext.displayName = "ExternalModalContext";
