/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";

// Import the keys interface.
import { Keys } from "./interfaces";
// Import the subcomponents.
import { Input } from "./input";
import { Table } from "./table";
import { Header } from "./header";

// Import the css.
import css from "./file.module.scss";


/** Component used to generate new users from a file. */
export function File(): React.ReactElement {
    // Store the keys used for the current data.
    const [ keys, setKeys ] = React.useState<Keys | null>(null);
    const [ availableKeys, setAvailableKeys ] = React.useState<string[]>([]);

    // Updates the source keys, while looking for a potential email key.
    const updateAvailableKeys = React.useCallback(function updateAvailableKeys(
        keys: string[], predictedEmail: string | null
    ): void {
        if (keys.length <= 0) {
            setAvailableKeys([]); setKeys(null);
        } else {
            setAvailableKeys(keys);
            if (predictedEmail) { setKeys({ email: predictedEmail }); }
            else { setKeys({ email: keys[0] }); }
        }
    }, []);

    // Updates the keys used in the header.
    const updateKeys = React.useCallback(function updateKeys(update: Partial<Keys>): void {
        if (!keys) return;

        for (const [ type, key ] of Object.entries(update)) {
            if (typeof key === "undefined") continue;

            // Clear the key if it is used elsewhere.
            for (const [ type, currentKey ] of Object.entries(keys)) {
                if (key === currentKey) keys[type as keyof Keys] = undefined;
            }

            keys[type as keyof Keys] = key;
        }
        setKeys({ ...keys });
    }, [keys]);

    // Render the component.
    return <section className={css["file"]}>
        <h4>Entrez un fichier csv avec les informations des utilisateurs à créer</h4>
        <p>Vous pourrez ensuite lier les colonnes de votre document aux propriétés de vos utilisateurs.</p>
        <Input keys={keys} updateAvailableKeys={updateAvailableKeys} />
        {keys ?
            <>
                <Header keys={keys} availableKeys={availableKeys} updateKeys={updateKeys} />
                <Table />
            </>
        : null}
    </section>;
}
