/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useContext } from "react";
// Import the monitoring component.
import { Monitoring } from "@andromeda/course";
// Import the login context.
import { LoginContext } from "@andromeda/login";


/** Wrapper component used to check if the user can see the {@link Admin} component. */
export default function MonitoringWrapper(): React.ReactElement {
    // Check if the user is an admin.
    const { isAdmin } = useContext(LoginContext);

    if (!isAdmin) {
        return <h1>vous n'avez pas les droits de voir cet écran !</h1>;
    }
    return <Monitoring />;
}
