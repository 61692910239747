/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the router components.
import { Routes, Route } from "react-router-dom";
// Import the password reset tool.
import { PasswordResetHandler, LoginProvider } from "@andromeda/login";
// Import the reducer wrapper.
import {
    UserStore,
    OrganisationStore,
    TagStore,
    ZaqWikiStore,
    ZaqWikiFileStore,
    AssetStore,
    CourseStore,
    ExternalStore,
    SessionStore,
    ValidationStore,
    DueDateStore,
    RequirementStore,
    StepStore,
    EncryptionSourceStore,
    ShareLinkStore,
    ZaqStore,
    ZaqTagStore,
} from "@andromeda/store";
// Import the legacy reducer.
import { withLegacyZaqReducer } from "@andromeda/legacy-zaq";

// Import the navbar component.
import Navbar, { NavbarSearchContextProvider } from "../components/navbar";
// Import the player.
import PlayerLinkParser from "./player/link-parser";
import Player from "./player/player";
// Import the share link parser.
import ShareLinkParser from "./share-link-parser";
// Import the sub-routers.
import AdminRouter from "./admin";
import MonitoringRouter from "./monitoring";
import WikiRouter from "./wiki";
import CourseRouter from "./course";
import EditorRouter from "./editor";
// Import the menu components.
import MainMenu from "../components/main-menu";


/** Wrap the {@link MainRouter} with the {@link rootReducer}. */
export default UserStore.withReducer(
    OrganisationStore.withReducer(
        TagStore.withReducer(
            ZaqWikiStore.withReducer(
                ZaqWikiFileStore.withReducer(
                    AssetStore.withReducer(
                        CourseStore.withReducer(
                            ExternalStore.withReducer(
                                SessionStore.withReducer(
                                    ValidationStore.withReducer(
                                        DueDateStore.withReducer(
                                            RequirementStore.withReducer(
                                                StepStore.withReducer(
                                                    EncryptionSourceStore.withReducer(
                                                        ShareLinkStore.withReducer(
                                                            ZaqStore.withReducer(
                                                                ZaqTagStore.withReducer(
                                                                    withLegacyZaqReducer(MainRouter)
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);

/**
 * Main router of the application.
 * Render all the children elements.
 */
function MainRouter(): React.ReactElement {
    return <ShareLinkParser>
        <Routes>
            {/* Render the standalone player route. */}
            <Route path="/p/:key/*" element={<PlayerLinkParser />} />
            {/* Render the standalone wiki route. */}
            <Route path="/w/:wiki/player/:gameId/*" element={<Player />} />
            <Route path="/w/:wiki" element={<WikiRouter />} />
            <Route path="/password-reset" element={<PasswordResetHandler />} />

            <Route
                path="*"
                element={<LoginProvider>
                    <NavbarSearchContextProvider>
                        <Routes>
                            {/* Render the player route. */}
                            <Route path="wiki/:wiki/player/:gameId/*" element={<Player />} />

                            {/* Render the navbar. */}
                            <Route
                                path="*"
                                element={<>
                                    <Navbar />
                                    <Routes>
                                        <Route path="zaq-training/:courseId/player/:gameId/*" element={<Player />} />
                                        <Route path="zaq-tuto/player/:gameId/*" element={<Player />} />
                                        <Route path="editor/:tuto" element={<EditorRouter />} />
                                        <Route path="admin/*" element={<AdminRouter />} />
                                        <Route path="monitoring/*" element={<MonitoringRouter />} />
                                        <Route path="wiki/:wiki" element={<WikiRouter />} />
                                        <Route path="zaq-training/*" element={<CourseRouter />} />
                                        <Route path="*" element={<MainMenu />} />
                                    </Routes>
                                </>}
                            >
                            </Route>
                        </Routes>
                    </NavbarSearchContextProvider>
                </LoginProvider>}
            />
            <Route path="*" element={<h1>404 Not Found</h1>} />
        </Routes>
    </ShareLinkParser>;
}
