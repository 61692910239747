/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the redux interfaces.
import * as Redux from "redux";

// Import the registry interface.
import { Registry } from "./interfaces";
// Import the event target builder.
import { RegistryEventHandler } from "./listeners";
// Import the reducer combiner.
import { combineRegistryReducer } from "./reducers";
import { applyMiddleware } from "redux";
import { buildMiddleware } from "./middleware";


// Re-export the reducer manipulators.
export * from "./reducers";
// Re-export the middleware manipulators.
export * from "./middleware";

/**
 * Interface used to describe the store after it was enhanced by the registry.
 * Every store has its own registry.
 */
export interface StoreWithRegistry<
    S = unknown,
    A extends Redux.Action = Redux.AnyAction
> extends Redux.Store<S, A> {
    /** Reference to the registry of this store. */
    registry: Registry;
}

/** Enhancer used to apply a new registry instance to the store. */
export function applyRegistry(): Redux.StoreEnhancer<StoreWithRegistry> {
    return function registryEnhancer(createStore: Redux.StoreEnhancerStoreCreator): Redux.StoreCreator {
        return function createStoreWithRegistry<S = unknown, A extends Redux.Action = Redux.AnyAction>(
            reducer: Redux.Reducer,
            defaultState?: Redux.PreloadedState<S>
        ): StoreWithRegistry<S, A> {
            // Create the registry.
            const eventTarget = new RegistryEventHandler();
            const registry: Registry = {
                middlewareMap: new Map(),
                reducerMap: new Map(),
                eventTarget
            };

            // Create the store with the middleware.
            const store = applyMiddleware(buildMiddleware(registry))(createStore)(
                reducer,
                defaultState as Redux.PreloadedState<never>
            );

            // Listen to the updates.
            eventTarget.addEventListener("mount:reducer", () =>
                store.replaceReducer(combineRegistryReducer(registry))
            );
            eventTarget.addEventListener("unmount:reducer", () =>
                store.replaceReducer(combineRegistryReducer(registry))
            );

            // Return the generated store.
            return { ...store, registry } as StoreWithRegistry<S, A>;
        };
    };
}
