/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the bootstrap components.
import { Form } from "react-bootstrap";
// Import the user resource.
import { User } from "@andromeda/resources";

// Import the creator context.
import { UserCreatorContext } from "../context";

// Import the icons.
import bin from "@andromeda/assets/images/bin.svg";
// Import the css.
import css from "./table.module.scss";
import { useValidateEmail } from "../hooks";

/** Component used to render the table of all the staged users. */
export function Table(): React.ReactElement {
    // Render the staged rows.
    const { staged, removeStaged } = React.useContext(UserCreatorContext);
    const rows = React.useMemo(
        function renderStageRows(): React.ReactElement[] {
            return staged.map((staged, index) => (
                <li key={index} className={css["table__row"]}>
                    <div className={css["table__cell"]}>
                        <button
                            className={css["table__delete-button"]}
                            onClick={() => removeStaged(index)}
                        >
                            <img src={bin} alt="delete" />
                        </button>
                        <Field
                            property="email"
                            value={staged.email}
                            index={index}
                            type="email"
                        />
                    </div>
                    <div className={css["table__cell"]}>
                        <Field
                            property="username"
                            value={staged.username}
                            index={index}
                        />
                    </div>
                    <div className={css["table__cell"]}>
                        <Field
                            property="givenName"
                            value={staged.givenName}
                            index={index}
                        />
                    </div>
                </li>
            ));
        },
        [removeStaged, staged]
    );

    // Render the component.
    return (
        <Form onSubmit={ev => ev.preventDefault()} validated>
            <ul className={css["table"]} children={rows} />
        </Form>
    );
}

/** Helper component used to render a field. */
function Field(props: FieldProps): React.ReactElement {
    const validate = useValidateEmail(props.index);
    const { updateStaged } = React.useContext(UserCreatorContext);

    // Refresh the validation when the component is rendered.
    const [control, setControl] = React.useState<HTMLInputElement | null>(null);
    React.useEffect(
        function refreshValidation(): void {
            if (control && control.type === "email") validate(control);
        },
        [control, validate]
    );

    if (typeof props.value === "undefined") {
        return <p>N/A</p>;
    } else {
        return (
            <span>
                <Form.Control
                    ref={setControl}
                    type={props.type ?? "text"}
                    value={props.value}
                    required
                    autoComplete={"off"}
                    onChange={(
                        ev: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                        updateStaged(props.index, {
                            [props.property]: ev.currentTarget.value,
                        });
                        if (props.type === "email") validate(ev.currentTarget);
                    }}
                />
                <Form.Control.Feedback type="invalid">
                    Vous devez spécifier une addresse mail !
                </Form.Control.Feedback>
            </span>
        );
    }
}

/** Props passed down to the {@link Field} component. */
interface FieldProps {
    /** Name of the rendered key. */
    property: keyof User.Create.Attributes;
    /** Index of the rendered user. */
    index: number;
    /** Value of the rendered key. */
    value: string | undefined;
    /** Optional type of the underlying input object. */
    type?: React.HTMLProps<HTMLInputElement>["type"];
}
