/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the bootstrap components.
import { Form, Modal } from "react-bootstrap";
// Import the confirmation hook.
import { ConfirmBodyProps, useConfirm, Loader } from "@andromeda/components";
// Import the link file resource.
import { ZaqWikiFile, ZaqWiki, ZaqWikiLinkFile } from "@andromeda/resources";
// Import the file store.
import { useResourceDispatch, ZaqWikiFileStore } from "@andromeda/store";

// Import the reader context.
import { ReaderContext } from "../../context";

// Import the icons.
import close from "@andromeda/assets/images/close.svg";
import check from "@andromeda/assets/images/check.svg";
// Import the css.
import css from "./link-popup.module.scss";


/**
 * Simple wrapper for the {@link useConfirm} hook.
 * Provides a promise-based way to generate a ZaqWikiLinkFile.
 *
 * @returns {() => Promise<LinkResult>} A promise that resolves with the generated ZaqWikiFile.
 * Or "undefined" if the popup was canceled.
 */
export function useLinkConfirm(): () => Promise<LinkResult> {
    return useConfirm({
        body: LinkModalBody,
        title: "Créer un lien",
        centered: true,
        size: "lg"
    });
}

/** Body of the {@link useLinkConfirm} modal.  */
function LinkModalBody(
    props: ConfirmBodyProps<LinkResult>
): React.ReactElement {
    // Store the link from the form.
    const [link, setLink] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    // Validates the link.
    const valid = React.useMemo(() => {
        return link.match(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/
        );
    }, [link]);

    // Generates a new link.
    const id = React.useContext(ReaderContext).wiki.id;
    const dispatch = useResourceDispatch();
    const generate = React.useCallback(
        function generateLinkFile(url: string): void {
            setLoading(true);
            dispatch(
                ZaqWikiFileStore.generator.create(
                    {
                        type: ZaqWikiFile.Type,
                        attributes: { name: "" },
                        relationships: { wiki: { data: { type: ZaqWiki.Type, id } } },
                        links: { url }
                    },
                    { include: [ZaqWiki.Type] }
                )
            )
                .then(file => props.resolve(file as ZaqWikiLinkFile))
                .then(() => setLoading(false));
        },
        [dispatch, id, props]
    );

    // Render the component.
    if (loading) {
        return <Loader asBlock text="Création du module ..." />;
    }
    return <Modal.Body className={css["popup"]}>
        <Form validated className={css["popup__input"]}>
            <Form.Control
                /* eslint-disable-next-line max-len */
                pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)"
                onChange={e => setLink(e.target.value)}
                value={link}
                required
                type="url"
                className={css["popup__input__field"]}
                placeholder="Lien"
            />
            <Form.Control.Feedback className={css["popup__input__feedback"]} type="invalid">
                Votre lien est invalide
            </Form.Control.Feedback>
        </Form>
        <div className={css["popup__button-container"]}>
            <button
                onClick={() => props.resolve()}
                className={`${css["popup__button"]} ${css["popup__button--cancel"]}`}
            >
                <img src={close} alt="close" />
                <p>annuler</p>
            </button>
            <button
                disabled={!valid}
                onClick={() => generate(link)}
                className={`${css["popup__button"]} ${css["popup__button--confirm"]}`}
            >
                <img src={check} alt="close" />
                <p>valider</p>
            </button>
        </div>
    </Modal.Body>;
}

/** Union of all the possible results for the link modal. */
type LinkResult = ZaqWikiFile | undefined;
