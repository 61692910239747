/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";
// Import React-Router.
import { Link } from "react-router-dom";
// Import React-Bootstrap.
import { Dropdown } from "react-bootstrap";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the login context.
import { LoginContext, useFeatureFlag, useIsAdmin } from "@andromeda/login";

// Import the organiastion selector.
import OrganisationSelector from "./organisation-selector";

// Import the icons.
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons/faPowerOff";
// Import the css.
import css from "./index.module.scss";


/** Component used to render the user menu at the right of the navbar. */
export default function UserMenu(): ReactElement {
    // Get the logout callback from the login context.
    const { logout } = useContext(LoginContext);

    // Check if the user is an admin.
    const isAdmin = useIsAdmin();

    // Check if the user has access to the zaq wiki preview.
    const canAccessZaqWikiPreview = useFeatureFlag("zaq-wiki-preview");

    // Render the component.
    return <Dropdown.Menu className={css["menu"]} align="end">
        <Dropdown.Item as={Link} className={css["button"]} to="/admin" data-disabled={!isAdmin}>
            <FontAwesomeIcon className={css["icon"]} icon={faUsers} />
            Administration
        </Dropdown.Item>
        <Dropdown.Divider className={css["divider"]} data-disabled={!isAdmin} />
        <OrganisationSelector />
        {canAccessZaqWikiPreview && <Dropdown.Item  className={css["button"]} as={Link} to="/preview/" reloadDocument>
            <FontAwesomeIcon className={css["icon"]} icon={faGlobe} />
            ZaqWiki Offline
        </Dropdown.Item>}
        <Dropdown.Item as="button" className={css["button"]} onClick={logout}>
            <FontAwesomeIcon className={css["icon"]} icon={faPowerOff} />
            Se déconnecter
        </Dropdown.Item>
    </Dropdown.Menu>;
}
