/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, ReactNode, useMemo, forwardRef, Ref, ComponentPropsWithoutRef } from "react";

// Import the button stylings.
import css from "./index.module.scss";


/** Forward the reference to the inner button element. */
export default forwardRef(Button);

/**
 * Simple component used to render a button.
 * {@link Button} elements are a simple wrapper around the {@link HTMLButtonElement} with some added styling.
 */
function Button(props: ButtonProps, ref: Ref<HTMLButtonElement>): ReactElement {
    // Load the class name from the provided props.
    const className = useMemo(function getClassName(): string {
        let className = css["button"];
        if (props.variant) {
            className += " " + css[`button--${props.variant}`];
        }
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }, [props.className, props.variant]);

    // Render the button.
    return <button {...props} ref={ref} className={className} children={props.children} />;
}

/**
 * Props passed down to the {@link Button} component.
 */
export interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
    /** Children elements to render inside the button. */
    children?: ReactNode;

    /** Class name applied to the {@link HTMLButtonElement}. */
    className?: string;

    /** Variant of the button. */
    variant?: "primary" | "secondary" | "info" | "danger" | "success";
}
