/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the store.
import { ZaqTagStore } from "../../stores";
// Import the resource hooks.
import useResources from "./util/use-resources";


export function useManyZaqTags(organization: string): RequestStatus<any[]> {
    // Convert the organization to base64url.
    const buffer = Uint8Array.from(
        organization.match(/[0-9a-fA-F]{2}/g)?.map(byte => parseInt(byte, 16)) ?? []
    );
    const base64 = window.btoa(
        Array.prototype.map.call(buffer, byte => String.fromCharCode(byte)).join("")
    );

    // Return the resources.
    return useResources(
        ZaqTagStore,
        "zaq-tag",
        {
            searchParams: {
                "filter[zaq-tag][organization]": base64.replaceAll("+", "-").replaceAll("/", "_")
            }
        },
        state => state.resources
    );
}
