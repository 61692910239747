/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ForwardedRef, forwardRef, ReactElement, ReactNode, useEffect, useState } from "react";
// Import React-PDF.
import type { LoadingProcessData } from "react-pdf";
import { Document } from "react-pdf";
import type PDFJS from "react-pdf/dist/pdfjs-dist";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the subcomponents.
import PDFDocumentLoaderMessage from "./message/document-loader";
import PDFErrorMessage from "./message/error";
import PDFNoDataMessage from "./message/no-data";


/** Type alias for the {@link RequestStatus} of the {@link Document} component. */
export type PDFDocumentStatus = RequestStatus<PDFJS.PDFDocumentProxy, number>;

/**
 * Component used to render a {@link Document} component.
 * Does not render any page of the PDF.
 */
const PDFDocument = forwardRef<HTMLDivElement | null, PDFDocumentProps>(
    function PDFDocument(props: PDFDocumentProps, ref: ForwardedRef<HTMLDivElement | null>): ReactElement {
        // Store the status and progress of the request.
        const [status, setStatus] = useState<PDFDocumentStatus>(RequestStatus.uninitialised);

        // Reset the status to uninitialised when the source changes.
        useEffect(function resetStatusOnSourceChange(): void {
            setStatus(RequestStatus.uninitialised);
        }, [props.source]);

        // Render the document component.
        return <Document
            inputRef={ref}
            file={props.source}
            onLoadProgress={updateStatus}
            onLoadError={updateStatus}
            onLoadSuccess={updateStatus}
            onSourceError={updateStatus}
            onItemClick={page => props.onItemClick?.(+page.pageNumber - 1)}
            loading={<PDFDocumentLoaderMessage progress={status.isLoading ? status.loadingMeta : undefined} />}
            error={<PDFErrorMessage />}
            noData={<PDFNoDataMessage />}
            children={props.children}
            className={props.className}
            renderMode="canvas"
            externalLinkTarget="_blank"
            externalLinkRel="nofollow noopener noreferrer"
            rotate={props.rotate}
        />;

        /**
         * Helper function used to report the progress of a request.
         *
         * @param {LoadingProcessData} progress The progress status of the request.
         */
        function updateStatus(progress: LoadingProcessData): void;

        /**
         * Helper function used to report a failed request.
         *
         * @param {Error} error The error that was thrown when loading the document.
         */
        function updateStatus(error: Error): void;

        /**
         * Helper function used to report a successful request.
         *
         * @param {PDFJS.PDFDocumentProxy} document The loaded document proxy object.
         */
        function updateStatus(document: PDFJS.PDFDocumentProxy): void;

        /** Implementation ! */
        function updateStatus(source: LoadingProcessData | Error | PDFJS.PDFDocumentProxy): void {
            if ("loaded" in source && "total" in source) {
                setStatus(RequestStatus.loading(source.loaded / source.total));
                props.onLoadUpdate?.(RequestStatus.loading(source.loaded / source.total));
            } else if (source instanceof Error) {
                setStatus(RequestStatus.error(source));
                props.onLoadUpdate?.(RequestStatus.error(source));
            } else {
                setStatus(RequestStatus.success(source));
                props.onLoadUpdate?.(RequestStatus.success(source));
            }
        }
    }
);
export default PDFDocument;

/** Props passed down to the {@link PDFDocument} component. */
export interface PDFDocumentProps {
    /** PDF document that should be rendered. */
    source: string | Blob;

    /** Children of the rendered {@link Document} component. */
    children?: ReactNode;
    /** Class name applied to the {@link Document} component. */
    className?: string;
    /** Global rotation applied to all the pages of this document. */
    rotate?: 0 | 90 | 180 | 270;

    /**
     * Callback invoked when the document's loading status changes.
     *
     * @param {PDFDocumentStatus} status The new loading status of the document.
     */
    onLoadUpdate?(status: PDFDocumentStatus): void;

    /**
     * Callback invoked when an item of the pdf is clicked.
     *
     * @param {number} index The index of the requested page.
     */
    onItemClick?(index: number): void;
}
