/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the interfaces.
import {
    Filter,
    PropertyDelimiter,
    OperatorDelimiter,
    Operators,
    AnyOperator,
    AnyValue,
    ArrayValue,
    PropertyFilter,
    CombinedFilter,
    CombinationOperators
} from "./interface";

// Import the error classes.
import { JsonApiError, BadFilterError, InvalidOperatorError, InvalidValueError } from "../../errors";

// Import the logging tool.
import debug from "debug";
const log = debug("json-api:services:filtering:parser");


/**
 * Decodes the provided filter from a url parameter.
 *
 * @param {string} encoded The encoded filter to parse.
 * @returns {Filter} The decoded filter.
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export function parseFilter(encoded: string): Filter {
    log("Parsing filter %s", encoded);
    // Decode the string in a list of filters.
    const filters = decodeURIComponent(encoded).split(";");
    const output: Filter = {};

    // Loop through the filters.
    log("Found %d filters", filters.length);
    for (const filter of filters) {
        // Find the delimiting elements.
        const propertyDelimiter = filter.indexOf(PropertyDelimiter);
        const operatorDelimiter = filter.indexOf(OperatorDelimiter);
        if (propertyDelimiter === -1 || operatorDelimiter === -1) {
            throw new BadFilterError(filter);
        }

        try {
            // Split the property, operator and value.
            const property = filter.slice(0, propertyDelimiter);
            const operator = filter.slice(propertyDelimiter + PropertyDelimiter.length, operatorDelimiter);
            checkOperator(operator);
            const value = decodeValue(filter.slice(operatorDelimiter + OperatorDelimiter.length));
            log("Adding an %s operator for property %s", operator, property);

            // Build the operator.
            let target: PropertyFilter & CombinedFilter = output;
            const path = property.split(".");
            let i = 0;


            // Loop through the properties.
            for (; i < path.length - 1; i++) {
                // Check if the item is a combination operator.
                if (Object.values(CombinationOperators).includes(path[i] as CombinationOperators)) {
                    const combinationOperator = path[i] as CombinationOperators;
                    if (!(combinationOperator in target)) {
                        target[combinationOperator] = [];
                    }
                    const list = target[combinationOperator] as PropertyFilter[];
                    target = list[list.push({}) - 1];
                } else {
                    // Generate a new object if it does not exist yet.
                    if (typeof target[path[i]] === "undefined") {
                        target[path[i]] = {};
                    }
                    target = target[path[i]] as Filter;
                }
            }
            // Add the operator to the target.
            target[path[i]] = { ...target[path[i]], ...buildOperator(operator, value) };
        } catch (e: unknown) {
            // If the error is a JSON:API error, rethrow it through a BadFilterError
            if (e instanceof JsonApiError) {
                throw new BadFilterError(filter, e);
            } else {
                throw e;
            }
        }
    }

    return output;
}

/** Ensures that the given operator is valid. */
function checkOperator(operator: string): asserts operator is Operators {
    if (!Object.values(Operators).includes(operator as Operators)) {
        throw new InvalidOperatorError(operator);
    }
}

/** Decode the encoded value into a comparable value. */
function decodeValue(value: string): AnyValue {
    // Prepare the output array.
    const values = value.split(",");
    const output: ArrayValue = new Array(values.length);

    // Loop through all the values.
    for (let i = 0; i < values.length; i++) {
        // Check if the value is a string.
        if (values[i].startsWith("\"") && values[i].endsWith("\"")) {
            output[i] = decodeURIComponent(values[i].slice(1, values[i].length - 1));
        } else {
            if (value === "true") output[i] = true;
            else if (values[i] === "false") output[i] = false;
            else if (values[i] === "null") output[i] = null;
            else {
                // Try to parse the value as a number.
                const asFloat = parseFloat(values[i]);
                if (isNaN(asFloat)) throw new InvalidValueError(values[i]);
                output[i] = asFloat;
            }
        }
    }

    // Return the value.
    log("Decoded value %s into [ %s ]", value, output.join(", "));
    return output.length === 1 ? output[0] : output;
}

/** Builds the operator object. */
function buildOperator(operator: Operators, value: AnyValue): AnyOperator {
    // Check the type of the operator.
    switch (operator) {
    case Operators.gt:
    case Operators.gte:
    case Operators.lte:
    case Operators.lt:
        if (typeof value !== "number") throw new InvalidValueError(value, operator);
        break;
    case Operators.in:
    case Operators.nin:
        if (!Array.isArray(value)) value = [ value ];
        break;
    case Operators.match:
        if (Array.isArray(value)) throw new InvalidValueError(value, operator);
        return { $elemMatch: { $eq: value }};
    }

    return { [operator]: value } as AnyOperator;
}
