/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useCallback } from "react";
// Import React-Router.
import { useNavigate } from "react-router-dom";
// Import the auth0 module.
import { AppState, Auth0Provider as WrappedAuth0Provider } from "@auth0/auth0-react";
// Import the loader component.
import { Loader } from "@andromeda/components";

// Import the config loader.
import { useLoginConfig } from "../config/config";


/** Provider used to render the {@link WrappedAuth0Provider} with the configuration loaded from disk. */
export function Auth0Provider(props: Props): ReactElement {
    // Load the login configuration.
    const config = useLoginConfig();

    // Callback invoked when a login redirection occurred.
    const navigate = useNavigate();
    const onRedirectCallback = useCallback(function onRedirected(state?: AppState): void {
        // Check if a returnTo path was provided.
        if (typeof state?.returnTo !== "string") {
            return;
        }

        // Navigate to the requested location.
        navigate(state.returnTo);
    }, [navigate]);

    // Render the component.
    if (config === null) return <Loader text={"Chargement de la configuration ..."}/>;
    return <WrappedAuth0Provider
        domain={config.domain}
        clientId={config.clientId}
        redirectUri={window.location.origin}
        scope="read:any create:any delete:any update:any"
        useRefreshTokens
        cacheLocation="localstorage"
        audience={config.audience}
        children={props.children}
        onRedirectCallback={onRedirectCallback}
    />;
}

/** Props passed to the {@link Auth0Provider} component. */
interface Props {
    /** @borrows PropsWithChildren.children */
    children: ReactNode;
}
