
/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useCallback } from "react";
// Import React-Router.
import { useNavigate } from "react-router-dom";
// Import the legacy-zaq helper tool.
import { useLegacyZaq } from "@andromeda/legacy-zaq";
// Import the resources.
import type { Step } from "@andromeda/resources";

// Import the item container.
import ItemContainer from "./item";


/** Component used to render a ZaqTutoPlus item on the screen. */
export default function TutoPlusItem(props: TutoPlusItemProps): ReactElement {
    // Load the zaq from the store.
    const zaq = useLegacyZaq(props.item.id);

    // Prepare the redirection callback.
    const navigate = useNavigate();
    const redirect = useCallback(function redirect(): void {
        navigate(`player/${props.item.id}`);
    }, [navigate, props.item.id]);

    // Render the step.
    return <ItemContainer
        item={props.item}
        step={props.step}
        title={zaq.data?.name}
        icon={zaq.data?.icon?.url}
        loading={!zaq.isSuccess}
        kind="Tuto+"
        onClick={redirect}
        children={props.children}
    />;
}

/** Props passed down to the {@link TutoPlusItem} component. */
export interface TutoPlusItemProps {
    /** Identifier of the tuto to render. */
    item: Step.Item;
    /** Identifier of the current step. */
    step: string;

    /** Children that should be rendered below the title of the item. */
    children?: ReactNode;
}
