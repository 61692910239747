/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the resources.
import { Requirement, Validation } from "@andromeda/resources";

// Import the custom interfaces.
import type {
    ExternalProgress,
    ItemProgress,
    ZaqTutoPlusProgress,
    ZaqTutoProgress,
    ZaqWikiProgress
} from "./interfaces";


/**
 * Parses the provided requirement detail and extracts its progress status.
 *
 * @param {Requirement.ZaqDetail} detail The detail to parse.
 * @param {boolean} locked Flag that should be set if the parent step is locked.
 * @param {Validation[]} validations All the validations of the user for the current course.
 * @return {ZaqTutoPlusProgress} The progress of the item.
 */
export default function parseItemProgress(
    detail: Requirement.ZaqDetail,
    locked: boolean,
    validations: Validation[]
): ZaqTutoPlusProgress;

/**
 * Parses the provided requirement detail and extracts its progress status.
 *
 * @param {Requirement.TutoDetail} detail The detail to parse.
 * @param {boolean} locked Flag that should be set if the parent step is locked.
 * @param {Validation[]} validations All the validations of the user for the current course.
 * @return {ZaqTutoProgress} The progress of the item.
 */
export default function parseItemProgress(
    detail: Requirement.TutoDetail,
    locked: boolean,
    validations: Validation[]
): ZaqTutoProgress;

/**
 * Parses the provided requirement detail and extracts its progress status.
 *
 * @param {Requirement.WikiDetail} detail The detail to parse.
 * @param {boolean} locked Flag that should be set if the parent step is locked.
 * @param {Validation[]} validations All the validations of the user for the current course.
 * @return {ZaqWikiProgress} The progress of the item.
 */
export default function parseItemProgress(
    detail: Requirement.WikiDetail,
    locked: boolean,
    validations: Validation[]
): ZaqWikiProgress;

/**
 * Parses the provided requirement detail and extracts its progress status.
 *
 * @param {Requirement.ExternalDetail} detail The detail to parse.
 * @param {boolean} locked Flag that should be set if the parent step is locked.
 * @param {Validation[]} validations All the validations of the user for the current course.
 * @return {ExternalProgress} The progress of the item.
 */
export default function parseItemProgress(
    detail: Requirement.ExternalDetail,
    locked: boolean,
    validations: Validation[]
): ExternalProgress;

/**
 * Parses the provided requirement detail and extracts its progress status.
 *
 * @param {Requirement.Detail} detail The detail to parse.
 * @param {boolean} locked Flag that should be set if the parent step is locked.
 * @param {Validation[]} validations All the validations of the user for the current course.
 * @return {ItemProgress} The progress of the item.
 */
export default function parseItemProgress(
    detail: Requirement.Detail,
    locked: boolean,
    validations: Validation[]
): ItemProgress;

/** Implementation ! */
export default function parseItemProgress(
    detail: Requirement.Detail,
    locked: boolean,
    validations: Validation[]
): ItemProgress {
    // Check the locked flag and the date.
    if (locked) {
        if (Requirement.isZaqDetail(detail)) {
            return { type: detail.item.type, id: detail.item.id, level: null, validated: false };
        } else if (Requirement.isExternalDetail(detail)) {
            return { type: detail.item.type, id: detail.item.id, level: null };
        } else if (Requirement.isWikiDetail(detail)) {
            return { type: detail.item.type, id: detail.item.id, viewed: false };
        } else {
            return { type: detail.item.type, id: detail.item.id, viewed: false };
        }
    }

    // Build the progress object.
    if (Requirement.isZaqDetail(detail)) {
        const validation = validations.find(validation =>
            "zaq" in validation.relationships
            && validation.relationships.zaq.data.id === detail.item.id
        );
        return {
            type: detail.item.type,
            id: detail.item.id,
            level: detail.level,
            validated: detail.validated,
            lastValidation: validation ? new Date(validation.attributes.date) : undefined
        };
    } else if (Requirement.isExternalDetail(detail)) {
        const validation = validations.find(validation =>
            "external" in validation.relationships
            && validation.relationships.external.data.id === detail.item.id
        );
        return {
            type: detail.item.type,
            id: detail.item.id,
            level: detail.level,
            lastValidation: validation ? new Date(validation.attributes.date) : undefined
        };
    } else if (Requirement.isWikiDetail(detail)) {
        return {
            type: detail.item.type,
            id: detail.item.id,
            viewed: detail.viewed
        };
    } else {
        return {
            type: detail.item.type,
            id: detail.item.id,
            viewed: detail.viewed
        };
    }
}
