/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { MouseEvent, ReactElement, useState, useCallback, useRef, useEffect } from "react";
// Import the font awesome component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the icons.
import { faFileAudio, faPause, faPlay, faRotateLeft, faRotateRight } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./audio.module.scss";


/** Component used to render an audio module. */
export default function AudioModule(props: AudioModuleProps): ReactElement {
    // Store the duration of the audio playback.
    const [duration, setDuration] = useState<number>(0);
    // Store the current icon.
    const [icon, setIcon] = useState(faPlay);

    // Reference to the <audio> component.
    const audio = useRef<HTMLAudioElement | null>(null);

    // References to the progress bar value and text.
    const progressValue = useRef<HTMLDivElement | null>(null);
    const progressText = useRef<HTMLSpanElement | null>(null);

    // Callback used to toggle the audio component.
    const toggle = useCallback(function playingAudio() : void {
        audio.current && audio.current[audio.current.paused ? "play" : "pause"]();
    },[])

    // Infers the duration of the audio file from the current element.
    const inferAudioDuration = useCallback(function inferAudioDuration(): void {
        setDuration(audio.current?.duration ?? 0);
    },[]);

    // Handler used to update the timing refs.
    const updateTimeIndicator = useCallback(function updateIndicator(audio: HTMLAudioElement): void {
        // Update the current time indicator.
        if (progressText.current) {
            progressText.current.innerText = toHoursMinutesAndSeconds(audio.currentTime);
        }

        // Update the progress bar.
        if (progressValue.current) {
            const progress = audio.currentTime / duration;
            progressValue.current.style.width = `${Math.floor(progress * 100000) / 1000}%`;
        }
    }, [duration]);
    useEffect(function mountTimeUpdateInterval(): void | (() => void) {
        // If the reference to the audio is unset, do nothing.
        if (!audio.current) {
            return;
        }

        // Attach the interval to the video when it starts playing.
        let interval: number = Number.NaN;
        audio.current.addEventListener("play", () => (interval = setInterval(updateTimeIndicator, 33, audio.current)));
        audio.current.addEventListener("pause", clearVideoListener);
        return clearVideoListener;

        // Method used to clear the interval.
        function clearVideoListener(): void {
            clearInterval(interval);
        }
    }, [updateTimeIndicator]);

    // Handler used to update the time of the audio playback when the progressbar is clicked.
    const seek = useCallback(function seek(event: MouseEvent<HTMLElement>): void {
        // If the audio ref is not set, do nothing.
        if(!audio.current) {
            return;
        }

        // Compute the position of the click on the progress bar.
        const rect = event.currentTarget.getBoundingClientRect();
        const progress = (event.clientX - rect.left) / rect.width;

        // Update the audio element.
        audio.current.currentTime =  progress * duration;
    }, [duration]);

    // Render the component.
    return <div className={css["audio"]}>
        {/* Render the audio icon. */}
        <FontAwesomeIcon icon={faFileAudio} className={css["audio__icon"]} />

        {/* Render the player. */}
        <div className={css["audio__container"]}>
            <h3 className={css["audio__title"]} children={props.title} />

            {/* Render the progress bar. */}
            <div className={css["audio__progressbar"]} onClick={seek}>
                <div className={css["audio__progressbar__value"]} ref={progressValue} />

                <div className={css["audio__progressbar__text"]}>
                    <span ref={progressText} children="0:00" />
                    <span children={toHoursMinutesAndSeconds(duration)} />
                </div>
            </div>

            {/* Render the controls. */}
            <div className={css["audio__controls"]}>
                <button
                    className={`${css["audio__controls__button"]} ${css["audio__controls__button--back"]}`}
                    onClick={() => audio.current && (audio.current.currentTime -= 10) }
                >
                    <FontAwesomeIcon icon={faRotateLeft} className={css["audio__controls__button__icon"]} />
                    {/*<span className={css["audio__controls__button__time"]}>10</span>*/}
                </button>

                <button
                    className={`${css["audio__controls__button"]} ${css["audio__controls__button--play"]}`}
                    onClick={toggle}
                >
                    <FontAwesomeIcon icon={icon} className={css["audio__controls__button__icon"]} />
                </button>

                <button
                    className={`${css["audio__controls__button"]} ${css["audio__controls__button--forward"]}`}
                    onClick={() => audio.current && (audio.current.currentTime += 10) }
                >
                    <FontAwesomeIcon icon={faRotateRight} className={css["audio__controls__button__icon"]} />
                    {/*<span className={css["audio__controls__button__time"]}>10</span>*/}
                </button>
            </div>

            {/* Render the audio element. */}
            <audio
                onLoadedMetadata={inferAudioDuration}
                onLoadedData={inferAudioDuration}
                onPlaying={() => setIcon(faPause)}
                onPause={() => setIcon(faPlay)}
                onSeeked={e => updateTimeIndicator(e.currentTarget)}
                ref={audio}
                src={props.src}
                className={css["audio__audio"]}
            />
        </div>
    </div>;

}

/** Props passed down to the {@link AudioModule} component. */
export interface AudioModuleProps {
    /** Title of the module. */
    title: string;
    /** URL of the audio file to play. */
    src: string;
}

/** @returns {string} The given duration in the hh:mm:ss format. */
export function toHoursMinutesAndSeconds(seconds: number): string {
    // If the given time is not finite, return a predefined string.
    if (!isFinite(seconds)) {
        return "";
    }

    // Prepare the output string.
    let time = "";

    // If there is an hour component to the time.
    if (seconds > 3600) {
        const hours = Math.floor(seconds / 3600);
        time += `${hours.toString(10)}:`;
        seconds -= hours * 3600;
    }

    // Render the minutes component of the time.
    const minutes = Math.floor(seconds / 60);
    time += `${minutes.toString(10)}:`;
    seconds -= minutes * 60;

    // Render the seconds component of the time.
    time += Math.round(seconds).toString(10).padStart(2, "0");

    return time;
}
