/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { Context, FocusEvent, ReactElement, useContext } from "react";
// Import the custom components.
import { Switch, AutoresizeTextarea, SwitchContext } from "@andromeda/components";

// Import the custom contexts.
import { EditorSwitchContext } from "../../context";

// Import the css.
import css from "./index.module.scss";


/** Renders the title of the currently open ZaqTraining. */
export default function Title(props: TitleProps): ReactElement | null {
    const { state: editing } = useContext(EditorSwitchContext);

    // Render the toggle.
    let toggle: ReactElement | null = null;
    if (props.showToggle) {
        toggle = <Switch context={props.toggleContext} name={props.toggleName} className={css["switch"]} />;
    }

    // Render the title.
    let title: ReactElement;
    if (props.editable && editing) {
        title = <AutoresizeTextarea
            placeholder="Donner un nom à ce ZaqTraining ..."
            defaultValue={props.title}
            onBlur={props.onChange}
        />;
    } else {
        title = <h2 children={props.title} />;
    }

    // Render the component.
    return <header className={css["title"]}>
        {title}{toggle}
    </header>;
}

/** Props passed down to the {@link Title} component. */
export interface TitleProps {
    /** The title of the section. */
    title: string;

    /** Flag that should be set if the user can interact with the toggle. */
    showToggle?: boolean;
    /** Context that should be used for the toggle. */
    toggleContext?: Context<SwitchContext>;
    /** Name of the rendered toggle. */
    toggleName?: string;

    /** Flag that should be set if the title of the item is editable. */
    editable?: boolean;
    /** Event that is fired when the title is changed. */
    onChange?(event: FocusEvent<HTMLTextAreaElement>): void;
}
