/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the metadata interface.
import { Metadata } from "./meta";


/**
 * Basic interface for a simple link object.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
interface LinkObject {
    /** URL of the described link. */
    href: string;
}

/**
 * Interface used to describe a link with additional metadata.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export interface LinkObjectWithMetadata<T extends Metadata = Metadata> extends LinkObject {
    /** Additional metadata passed alongside the object. */
    meta: T;
}

/** Type for a link object. Can either be a simple string, or a {@link LinkObject} with some optional metadata. */
export type Link = string | LinkObject | LinkObjectWithMetadata;

/** Interface used to describe a link to the object itself. */
interface SelfLink      { self      ?: Link; }
/** Interface used to describe a related link to the object. */
interface RelatedLink   { related   ?: Link; }
/** Interface used to describe a pagination link to the first page of elements in the collection. */
interface FirstLink     { first     ?: Link; }
/** Interface used to describe a pagination link to the previous page of elements in the collection. */
interface PreviousLink  { previous  ?: Link; }
/** Interface used to describe a pagination link to the next page of elements in the collection. */
interface NextLink      { next      ?: Link; }
/** Interface used to describe a pagination link to the last page of elements in the collection. */
interface LastLink      { last      ?: Link; }

/**
 * Union of all the links to the own object.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
type OwnLinks = SelfLink & RelatedLink;

/**
 * Union of all the pagination links for the object.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
type PaginationLinks = FirstLink & PreviousLink & NextLink & LastLink;

/**
 * Type used to describe the links object interface.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export type AnyLinks = OwnLinks & PaginationLinks & Record<string, Link | undefined>

/** Helper function used to extract the url from a link. */
export function extractURL(from: Link): URL {
    return new URL(typeof from === "string" ? from : from.href);
}
