/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import react dom.
import * as ReactDOM from "react-dom";

// Import the loader icon.
import { ReactComponent as Loading } from "@andromeda/assets/images/loading-blue.svg";
// Import the css.
import css from "./save-indicator.module.scss";


/** Simple save indicator that will be displayed on the screen. */
export function SaveIndicator(props: Props): React.ReactElement | null {
    // Attach a hook to prevent the user from closing the page.
    React.useEffect(function preventPageClose(): (() => void) | void {
        if (!props.show || props.preventNavigation === false) {
            return;
        }

        window.addEventListener("beforeunload", preventPageClose);
        return () => window.removeEventListener("beforeunload", preventPageClose);

        function preventPageClose(event: BeforeUnloadEvent): string {
            event.preventDefault();
            event.returnValue = "";
            return "";
        }
    }, [props.preventNavigation, props.show]);

    // Create the portal.
    if (props.show) {
        return ReactDOM.createPortal(
            <div className={`${css["save-indicator"]} ${css[`save-indicator--${props.position ?? "top-left"}`]}`}>
                <Loading className={css["save-indicator__loader"]} title="loader-icon" />
                <p>{props.title ?? "Sauvegarde en cours ..."}</p>
            </div>,
            document.body
        );
    } else {
        return null;
    }
}

/** Props passed down to the {@link SaveIndicator} */
interface Props {
    /** If true, show the save indicator. */
    show: boolean;

    /**
     * Location of the indicator.
     * @default "top-left"
     */
    position?: "top-left" | "top-right" | "bottom-left" | "bottom-right";

    /** Override for the title of the item. */
    title?: string;

    /**
     * If true, prevents navigation to another page.
     *
     * @default true
     */
    preventNavigation?: boolean;
}
