/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


// Import React.
import { ReactElement, useContext, useState } from "react";
// Import the font-awesome icons.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the editor switch.
import { EditorSwitchContext } from "../../../../context";
// Import the search-bar.
import UserSearchBar from "./search-bar";
// Import the modal.
import AdministrationModal from "./modal";

// Import the icons.
import { faUserGear } from "@fortawesome/free-solid-svg-icons/faUserGear";
// Import the css.
import css from "./index.module.scss";


/** Icon used to open the administration modal popup button. */
export default function AdministrationButton(props: AdministrationButtonProps): ReactElement | null {
    // Get the state of the edit switch.
    const { state: editable } = useContext(EditorSwitchContext);
    // Store the state of the modal.
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Render the button.
    if (!editable) {
        return null;
    }
    return <UserSearchBar.Provider source={[]}>
        <button onClick={() => setIsModalOpen(true)} className={css["admin-button"]}>
            <FontAwesomeIcon icon={faUserGear} className={css["admin-button__icon"]} />
            <span className={css["admin-button__text"]}>Attribuer des droits</span>
        </button>
        <AdministrationModal
            course={props.course}
            show={isModalOpen}
            onHide={() => setIsModalOpen(false)}
        />
    </UserSearchBar.Provider>;
}

export interface AdministrationButtonProps {
    course: string;
}
