/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the Xhr class.
import { Xhr } from "@andromeda/xhr";
// Import the validation method.
import { validate } from "@andromeda/validation";
// Import the JSON:API interfaces.
import {
    Resource,
    Link,
    MessageHelper,
    MultipleMessageHelper,
    SingleMessageHelper,
} from "@andromeda/json-api";

// Import the promisable validate function.
import {
    PromisableValidateFunction,
    parseValidatorAndOptions,
    extractUrl,
    buildParams,
    ReadOptions,
    buildHeaders,
} from "../crud";
// Import the invalid source error class.
import { InvalidSourceError } from "../../errors";

// Interface used to describe an object with a "self" link.
export interface WithSelfLink {
    links: { self: Link };
}

/**
 * Method used to read a resource from a given link.
 *
 * @param {WithSelfLink} source The source with the url to read.
 * @param {ReadOptions | undefined} [readOptions=undefined] The options of the read operation.
 * @returns {Promise<MessageHelper>}
 * A promise that resolves with the parsed message.
 */
export function readBySelfLink(
    source: WithSelfLink,
    readOptions?: ReadOptions
): Promise<MessageHelper>;

/**
 * Method used to read a resource from a given link.
 *
 * @template {string} T
 * The type of the resource to read.
 * @param {WithSelfLink} source The source with the url to read.
 * @param {T} type The type of the read resource.
 * @param {ReadOptions | undefined} [readOptions=undefined] The options of the read operation.
 * @returns {Promise<MessageHelper<Resource<T>>>}
 * A promise that resolves with the parsed message.
 */
export function readBySelfLink<T extends string>(
    source: WithSelfLink,
    type: T,
    readOptions?: ReadOptions
): Promise<
    SingleMessageHelper<Resource<T>> | MultipleMessageHelper<Resource<T>>
>;

/**
 * Method used to read a resource from a given link.
 * Validates the received resource with the given validator.
 *
 * @template {Resource} R
 * The resource to read.
 * @param {WithSelfLink} source The source with the url to read.
 * @param {R["type"]} type The type of the read resource.
 * @param {PromisableValidateFunction<R>} validator The validator for the resource.
 * @param {ReadOptions | undefined} [readOptions=undefined] The options of the read operation.
 * @returns {Promise<MessageHelper<R>>} A promise that resolves with the parsed message.
 */
export function readBySelfLink<R extends Resource>(
    source: WithSelfLink,
    type: R["type"],
    validator: PromisableValidateFunction<R>,
    readOptions?: ReadOptions
): Promise<SingleMessageHelper<R> | MultipleMessageHelper<R>>;

/** Implementation ! */
export async function readBySelfLink<R extends Resource>(
    source: WithSelfLink,
    typeOrValidatorOrOptions?:
        | R["type"]
        | PromisableValidateFunction<R>
        | ReadOptions,
    validatorOrOptions?: PromisableValidateFunction<R> | ReadOptions,
    readOptions?: ReadOptions
): Promise<SingleMessageHelper<R> | MultipleMessageHelper<R>> {
    // Parse the parameters.
    let type: string | undefined;
    let validator: PromisableValidateFunction<R> | undefined;
    let options: ReadOptions | undefined;
    if (typeof typeOrValidatorOrOptions === "string") {
        const parsed = parseValidatorAndOptions(
            validatorOrOptions,
            readOptions
        );
        validator = parsed.validator;
        options = parsed.options;
        type = typeOrValidatorOrOptions;
    } else {
        const parsed = parseValidatorAndOptions(
            typeOrValidatorOrOptions,
            validatorOrOptions as ReadOptions | undefined
        );
        validator = parsed.validator;
        options = parsed.options;
        type = undefined;
    }

    // Prepare the options.
    const url: string = extractUrl(
        typeof source.links.self === "string"
            ? source.links.self
            : source.links.self.href,
        options
    );
    const searchParameters = buildParams(options);
    const headers = buildHeaders(options);

    // Run the request.
    const request = await Xhr.get(url, { searchParameters, headers });

    // Check if the message has some data.
    if (request.body instanceof MessageHelper) {
        // Validate the data.
        if (typeof validator !== "undefined") {
            if (Array.isArray(request.body.data)) {
                for (const resource of request.body.data) {
                    validate(resource, await validator, true);
                }
            } else if (request.body.data !== null) {
                validate(request.body.data, await validator, true);
            }
        } else {
            if (Array.isArray(request.body.data)) {
                if (
                    !request.body.data.every(resource => resource.type === type)
                ) {
                    throw new InvalidSourceError(request);
                }
            } else if (
                request.body.data !== null &&
                request.body.data.type !== type
            ) {
                throw new InvalidSourceError(request);
            }
        }

        return request.body as MessageHelper<R>;
    } else {
        throw new InvalidSourceError(request);
    }
}
