/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the json:api resource interface.
import { Resource } from "@andromeda/json-api";

// Import the base interface.
import { AssetBase } from "./base";

// Import the validation schema.
import Schema from "../schema/model";

/** Interface used to represent a model/* asset. */
export interface ModelAsset extends AssetBase {
    /** @inheritDoc */
    attributes: ModelAsset.Attributes;
}

/** Namespace used to augment the {@link ModelAsset} interface. */
export namespace ModelAsset {
    export const Type = AssetBase.Type;
    export type Type = typeof Type;

    export interface Attributes extends AssetBase.Attributes {
        /** @inheritDoc */
        contentType: `model/${string}`;
        /** Width of the image, in pixels. */
        width: number;
        /** Height of the image, in pixels. */
        height: number;
    }

    export const validate = compileSync<ModelAsset>(Schema.ResourceSchema);

    export interface Update extends Resource<ModelAsset.Type> {
        /** @inheritDoc */
        attributes?: Partial<Attributes>;
        /** @inheritDoc */
        relationships?: Update.Relationships;
    }

    export namespace Update {
        export interface Relationships {
            /** @borrows {AssetBase.Relationships.alternatives} */
            alternatives?: AssetBase.Relationships["alternatives"];
        }

        export const validate = compileSync<Update>(Schema.UpdateSchema);
    }

    export type Create = ModelAsset;

    export namespace Create {
        export const validate = compileSync<Create>(Schema.CreateSchema);
    }
}
