/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
import { useAssetConverter, useAssetPreparator } from "@andromeda/asset-manager";
import { Asset, ZaqWiki } from "@andromeda/resources";
import { useContext } from "react";
import { ReaderContext } from "../context";
import { useResourceDispatch, ZaqWikiStore } from "@andromeda/store";


/** Helper hook used to generate an icon from a file.  */
export function useIconGenerator(): (file: File) => void {
    const dispatch = useResourceDispatch();
    const id = useContext(ReaderContext).wiki.id;
    const prepare = useAssetPreparator();
    const convert = useAssetConverter({ width: 512, height: 512 });
    return React.useCallback(async function generatorWrapper(file: File): Promise<void> {
        // Ensure that the file is an image.
        if (!file.type.startsWith("image")) {
            return;
        }

        // Prepare the image.
        const asset = await prepare(file);
        let update: ZaqWiki.Update = {
            type: ZaqWiki.Type, id,
            relationships: { icon: { data: { type: Asset.Type, id: asset.resource.id }}}
        };
        await dispatch(ZaqWikiStore.generator.update(update));

        // Convert the image.
        const converted = await convert(asset);
        update = {
            type: ZaqWiki.Type, id,
            relationships: { icon: { data: { type: Asset.Type, id: converted.resource.id }}}
        };
        await dispatch(ZaqWikiStore.generator.update(update));
    }, [convert, dispatch, id, prepare]);
}
