/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";
// Import the asset metadata interface.
import { AnyAssetMetadata } from "@andromeda/asset-conversion";
import { v4 } from "uuid";


/** Helper hook used for the {@link MediaParserProvider}'s job queue. */
export function useJobQueue(): JobQueue {
    // Queue used to store the list of all the jobs that should be run.
    const [ queue, setQueue ] = React.useState<JobWithId[]>([]);

    // Helper callback used to build a job resolver.
    const buildResolver = React.useCallback(function buildJobResolver(job: JobWithId) {
        return function jobResolver(value: AnyAssetMetadata): void {
            setQueue(queue => {
                if (!queue.some(queuedJob => queuedJob.id === job.id)) {
                    return queue;
                }
                job.resolve(value);
                return queue.slice(1);
            });
        }
    }, []);

    // Helper callback used to build a job rejecter.
    const buildRejecter = React.useCallback(function buildJobResolver(job: JobWithId) {
        return function jobRejecter(error: unknown): void {
            setQueue(queue => {
                if (!queue.some(queuedJob => queuedJob.id === job.id)) {
                    return queue;
                }
                job.reject(error);
                return queue.slice(1);
            });
        }
    }, []);

    // Callback used to push a new job to the queue.
    const push = React.useCallback(function pushJob(job: Job): void {
        const jobWithId = { id: v4(), ...job };
        setQueue(queue => [
            ...queue,
            { ...jobWithId, resolve: buildResolver(jobWithId), reject: buildRejecter(jobWithId) }
        ]);
    }, [ buildRejecter, buildResolver ]);

    // Return the queue.
    return React.useMemo(() => ({ current: queue[0], push }), [push, queue]);
}

/** Interface used to describe a single parser job. */
export interface Job {
    /** The file that should be parsed. */
    file: File;

    /** A callback to invoke once the parsing is complete. */
    resolve(value: AnyAssetMetadata): void;
    /** A callback to invoke if an error arose while parsing the file. */
    reject(error: unknown): void;
}

interface JobWithId extends Job {
    /** unique identifier of the job */
    id: string;
}

/** Interface of the {@link useJobQueue} hook's return value. */
interface JobQueue {
    /** The job that is currently being run. */
    current: Job | undefined;

    /** Method used to push a new job to the queue. */
    push(job: Job): void;
}
