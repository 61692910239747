/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ChangeEvent, useMemo, ReactEventHandler } from "react";


/** Union of the editable props. */
export type EditableHeaderProps = WithEditableHeaderProps | WithoutEditableHeaderProps;

/**
 * Helper hook used to generate a {@link ChangeEvent} callback handler.
 *
 * @param {EditableHeaderProps} props The editable props to handle.
 * @return {ReactEventHandler<HTMLInputElement> | undefined} A callback to attach to a {@link HTMLInputElement}.
 */
export default function useEditCallbackHandler(
    props: EditableHeaderProps
): ReactEventHandler<HTMLInputElement> | undefined {
    return useMemo(function generateEditCallbackHandler(): ReactEventHandler<HTMLInputElement> | undefined {
        // If the event should not be handled, return an empty object.
        if (!props.editable) {
            return undefined;
        }

        // Build the handler.
        return function editCallbackHandler(event: ChangeEvent<HTMLInputElement>): void {
            props.onEdited?.(event.currentTarget.value);
        };
    }, [props]);
}


/** Interface used to describe the props used to edit the header title. */
interface WithEditableHeaderProps {
    /** Flag that should be set if the header is editable. */
    editable: true;
    /**
     * Value of the interval used by RxJS for throttling, in ms.
     * @default 100
     */
    interval?: number;

    /** Callback that will be invoked when the header is edited. */
    onEdited(value: string): void;
}

// Helper type used to map all the properties of WithEditableHeaderProps to "undefined".
// type PartialWithEditableHeaderProps = { [K in Exclude<keyof WithEditableHeaderProps, "editable">]?:  };

/** Version of the props used when the header title is not editable. */
interface WithoutEditableHeaderProps extends Partial<Omit<WithEditableHeaderProps, "editable">> {
    /** @borrows EditableHeaderProps.editable */
    editable?: boolean;
}
