/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */


// Re-export the target helper.
export { resolveTarget } from "./target";
// Re-export the URL builder.
export { resolveURL } from "./url";
