/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the schema object.
import { SchemaObject } from "ajv";

// Import all the sub-schemas.
import Link from "./link";
import Links from "./links";
import Meta from "./meta";
import Relationship from "./relationship";
import ResourceIdentifier from "./resource-identifier";
import Resource from "./resource";


/**
 * Helper method used to generate a resource schema.
 *
 * @param {string} type The type of the generated resource.
 * @param {SchemaObject | undefined} attributes The schema of the resource's attributes.
 * @param {SchemaObject | undefined} relationships The schema of the resource's relationships.
 * @param {SchemaObject | undefined} links The schema of the resource's links.
 * @returns {SchemaObject} The generated resource schema.
 */
export function makeResource(type: string, attributes?: SchemaObject, relationships?: SchemaObject, links?: SchemaObject): SchemaObject {
    // Prepare the resource.
    const resource = {
        ...Resource,
        properties: {
            type: { const: type },
            id: Resource.properties.id,
            links: { ...Links, ...links }
        } as Record<string, unknown>
    };

    // Check if the user provided any attributes.
    if (typeof attributes !== "undefined") {
        resource.properties["attributes"] = {
            title: Resource.properties.attributes.title,
            description: Resource.properties.attributes.description,
            type: Resource.properties.attributes.type,
            not: Resource.properties.attributes.not,
            ...attributes
        };
    }

    // Check if the user provided any relationships.
    if (typeof relationships !== "undefined") {
        resource.properties["relationships"] = {
            title: Resource.properties.relationships.title,
            description: Resource.properties.relationships.description,
            type: Resource.properties.relationships.type,
            not: Resource.properties.relationships.not,
            ...relationships
        };
    }

    return resource;
}


/**
 * Helper method used to remove the id from a schema.
 *
 * @param {SchemaObject} schema The schema to remove the id from.
 * @returns {SchemaObject} The generated resource schema.
 */
export function withoutId(schema: SchemaObject): SchemaObject {
    const copy: SchemaObject = { ...schema };
    if ("properties" in copy && typeof copy["attributes"] === "object") {
        copy["attributes"] = { ...copy["attributes"] };
        if ("id" in copy["attributes"]) {
            delete copy["attributes"]["id"];
        }
    }

    if (Array.isArray(copy["required"])) {
        copy["required"] = copy["required"].filter(item => item !== "id");
    }

    return copy;
}

/**
 * Helper method used to generate a relationship schema.
 *
 * @param {SchemaObject} schema The "data" element of the new relationship.
 * @param {boolean} [toMany=false] If set, defines the relationship as a to-many relationship.
 * @param {boolean} [nullable=false] If set, defines the relationship as nullable.
 * Ignored if {@link toMany} is true.
 * @returns {SchemaObject} The generated relationship.
 */
export function makeRelationship(schema: SchemaObject, toMany = false, nullable = false): SchemaObject {
    // Create the data element of the relationship.
    let data: SchemaObject;
    if (toMany) {
        data = {
            type: "array",
            items: schema,
            uniqueItems: true
        };
    } else if (nullable) {
        data = { oneOf: [ schema, { type: "null" }]};
    } else {
        data = schema;
    }

    return { ...Relationship, properties: { ...Relationship.properties, data }};
}

// Re-export all the schemas.
export { Link, Links, Meta, Relationship, ResourceIdentifier, Resource };
