/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";

// Import the user resource.
import { User } from "@andromeda/resources";

/** Interface used to describe the {@link UserCreatorContext}. */
export interface UserCreatorContext {
    /** List of all the staged creation requests. */
    staged: ReadonlyArray<User.Create.Attributes & UserCreationRejection>;

    /** Method used to update a staged creation request. */
    updateStaged(
        index: number,
        attributes: Partial<User.Create.Attributes>
    ): void;
    /** Method used to push a new creatin request. */
    pushStaged(attributes: User.Create.Attributes): void;
    /** Removes a staged request from the list. */
    removeStaged(index: number): void;
    /** Overrides the entire staged list. */
    setStaged(
        attributes: Array<User.Create.Attributes & UserCreationRejection>
    ): void;
}

/** Context used to manipulate a list of creatable users. */
export const UserCreatorContext = React.createContext<UserCreatorContext>({
    staged: [],
    updateStaged() {
        window.alert(`Called a stub !`);
    },
    pushStaged() {
        window.alert(`Called a stub !`);
    },
    removeStaged() {
        window.alert(`Called a stub !`);
    },
    setStaged() {
        window.alert(`Called a stub !`);
    },
});

/** Interface used to explain why a user creation was rejected. */
export interface UserCreationRejection {
    /** Reason for which the user creation was rejected. */
    rejectionReason?: UserCreationRejection.Reason;
}

/** Namespace used to augment the {@link UserCreationRejection} interface. */
export namespace UserCreationRejection {
    /** Enumeration of all the possible reasons for which a creation could be rejected. */
    export enum Reason {
        unknown,
        duplicateEmail,
        duplicateUsername,
    }
}
