/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API interfaces.
import { Resource, Relationship, ResourceIdentifier } from "@andromeda/json-api";
// Import the json:schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the resource helpers.
import { PartialResource, CreatableResource } from "@andromeda/resource-helper";

// Import the course resource.
import { Course } from "../course";
// Import the requirement resource.
import { Requirement } from "../requirement";

// Import the schemas.
import { StepSchema } from "./schema";


/**
 * Interface used to represent a course's step.
 * Steps are composed of an ordered list of Zaq, ZaqWiki or ZaqTuto.
 */
export interface Step extends Resource<Step.Type> {
    /** @inheritDoc */
    attributes: Step.Attributes;
    /** @inheritDoc */
    relationships: Step.Relationships;
}

/** Augment the {@link Step} interface. */
export namespace Step {
    export const Type = "step" as const;
    export type Type = typeof Type;

    /** Attributes of a {@link Step} */
    export interface Attributes {
        /** Localised name of the step. */
        name: string;
    }

    /** Relationships of a {@link Step} */
    export interface Relationships {
        /** Reference to the course that has the requirement. */
        course: Relationship<Course>;
        /** List of items found in this step, in order. */
        items: Relationship<Item[]>;
        /** List of requirements attached to this step. */
        requirements: Relationship<Requirement[]>;
    }

    // Alias for the items in the relatinship.
    export type Item = ResourceIdentifier<"zaq" | "zaq-wiki" | "zaq-tuto" | "external">;
    export const validate = compileSync<Step>(StepSchema.ResourceSchema);


    /** Interface used to update a {@link Step}. */
    export interface Update extends PartialResource<Step> {
        relationships?: Update.Relationships;
    }

    /** Augments the {@link Update} interface. */
    export namespace Update {
        export type Relationships = Partial<Omit<Step.Relationships, "course">>;
        export const validate = compileSync<Step.Update>(StepSchema.UpdateSchema);
    }


    /** Interface used to create new {@link Step}. */
    export interface Create extends CreatableResource<Step.Type> {
        attributes: Step.Attributes;
        relationships: Create.Relationships;
    }

    /** Augments the {@link Create} interface. */
    export namespace Create {
        export type Relationships = Pick<Step.Relationships, "course"> & Partial<Pick<Step.Relationships, "items">>;
        export const validate = compileSync<Step.Create>(StepSchema.CreateSchema);
    }
}
