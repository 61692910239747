/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useMemo } from "react";
// Import the common tools.
import { normalise, RequestStatus } from "@andromeda/tools";

// Import the item hooks.
import type { SearchFilter, SearchableItem } from "./item";


/** Object used to describe the result of a search. */
export interface ResultingItem extends SearchableItem {
    /** The score attributed to this item. */
    score: number;
}

/**
 * Hook used to map a list of {@link SearchableItem} to a sorted list of {@link ResultingItem}.
 *
 * @param {RequestStatus<SearchableItem[]>} source The items to sort.
 * @param {string} query The current query string.
 * @param {SearchFilter} filter The current search filter.
 * @returns {RequestStatus<ResultingItem[]>} The status of the request used to sort all the items.
 */
export function useSearchResults(
    source: RequestStatus<SearchableItem[]>,
    query: string,
    filter: SearchFilter
): RequestStatus<ResultingItem[]> {
    // Normalise the query.
    const normalised = useMemo(function normaliseQuery(): [string, ...string[]] {
        // Normalise the query string.
        const normalisedQuery = normalise(query);

        // Check if there is more than one word in the query.
        const words = normalisedQuery.split(" ");
        if (words.length < 2) {
            return [normalisedQuery];
        }

        // Return the normalised query.
        return [normalisedQuery, ...words];
    }, [query]);

    // Map all the results to their weighted scores.
    return useMemo(function findResultsInSource(): RequestStatus<ResultingItem[]> {
        // Wait for the source data to be ready.
        if (source.isError) {
            return source;
        }

        if (!source.isSuccess) {
            return RequestStatus.loading();
        }

        // Map all the data to their results.
        const results = source.data
            .map((item: SearchableItem): ResultingItem => compareItemToQuery(item, normalised, filter))
            .filter((a: ResultingItem): boolean => a.score > 1e-6)
            .sort((a: ResultingItem, b: ResultingItem): number => {
                // Get the difference between the numbers.
                const difference = b.score - a.score;
                if (difference > 1e-6) {
                    return difference;
                }

                // Compare by the date tag.
                const dateA = a.tags.find(tag => tag.match(/[0-9]{4}-[01][0-9]-[0-3][0-9]/));
                const dateB = b.tags.find(tag => tag.match(/[0-9]{4}-[01][0-9]-[0-3][0-9]/));
                if (typeof dateA !== "undefined" || typeof dateB !== "undefined") {
                    // Compare the dates.
                    return new Date(dateB ?? 0).valueOf() - new Date(dateA ?? 0).valueOf();
                }

                // As a last resort, compare by title.
                return a.title.localeCompare(b.title);
            });

        return RequestStatus.success(results);
    }, [filter, normalised, source]);
}


/**
 * Helper function used to compare a {@link SearchableItem} to the current query.
 *
 * @param {SearchableItem} item
 * @param {[string, ...string[]]} query
 * @param {SearchFilter} filter
 * @returns {ResultingItem}
 */
function compareItemToQuery(item: SearchableItem, query: [string, ...string[]], filter: SearchFilter): ResultingItem {
    // Check if the item passes the filter.
    if (filter !== "all" && item.resource.type !== filter) {
        return { ...item, score: 0 };
    }

    // If the query is empty, return a score of one.
    if (query[0].length <= 0) {
        return { ...item, score: 1 };
    }

    // Compute the score of the item.
    let score = 0;
    // Compare the title, description, and tags of the element.
    for (const type of ["title", "description", "tags"] as const) {
        // Multiply the previous score by 10.
        score *= 10;

        // Ignore the item has no tags.
        if (type === "tags" && item.tags.length <= 0) {
            continue;
        }

        // Compare the type to all the items of the query.
        for (let i = 0; i < query.length; i++) {
            // Skip empty query elements.
            let queryItem = query[i];
            if (queryItem.length <= 0) {
                continue;
            }

            if (queryItem.startsWith("#")) {
                // Ignore non-tags if the query starts with a "#" sign.
                if (type !== "tags") {
                    continue;
                }

                // Remove the "#" sign from the query.
                queryItem = queryItem.slice(1);
            }

            // If the compared elements are tags.
            if (type === "tags") {
                // Get the score of all the tags.
                const tagScores = item.tags.reduce((score, tag) => score + getMatchScore(tag, queryItem), 0);

                // Add the average to the list.
                score += tagScores;
            } else {
                let value = item[type];
                if (type === "title" && value.length === 0) {
                    value = "sans nom";
                }
                score += getMatchScore(value, queryItem);
            }
        }
    }

    // Return the item.
    return { ...item, score };
}

/** Gets the score of any given text. */
function getMatchScore(text: string, match: string): number {
    // Check if the text matches.
    const index = text.indexOf(match);

    // If the text did not match, return a 0.
    if (index < 0) {
        return 0;
    }

    // Get the score of this match.
    // const indexScore = (1 - index / text.length) / 2;
    // const lengthScore = (match.length / text.length) / 2;
    return 1;//indexScore + lengthScore;
}
