/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useState } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the React date picker component.
import DatePicker from "react-date-picker";

// Import the popper wrapper.
import LinkExpirationPopper from "./popper";

// Import the icons.
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
// Import the React date picker CSS.
import "react-date-picker/dist/DatePicker.css";
// Import the css.
import css from "./index.module.scss";


/** Component used to render and manipulate the expiration of the link. */
export default function LinkExpiration(props: LinkExpirationProps): ReactElement {
    // Stores a reference to the container element for PopperJS.
    const [container, setContainer] = useState<HTMLLabelElement | null>(null);
    // Stores a reference to the PopperJS element.
    const [popper, setPopper] = useState<HTMLDivElement | null>(null);

    // Stores the opened state of the calendar.
    const [open, setOpen] = useState(false);

    // Callback used when the date value has changed.
    const { onChange: onChangeCallback } = props;
    const onChange = useCallback(function onDateChanged(date: [Date | null, Date | null] | Date | null): void {
        // Ignore date ranges.
        if (Array.isArray(date)) {
            return;
        }

        // Invoke the props callback.
        onChangeCallback(date);
    }, [onChangeCallback]);

    // Render the component.
    return <label className={classNames(css["expiration"], props.className)} ref={setContainer}>
        <span className={css["label"]}>Expire</span>
        <DatePicker
            className={css["date"]}
            calendarClassName={css["calendar"]}
            isOpen={open}
            portalContainer={popper}
            calendarIcon={null}
            clearIcon={<FontAwesomeIcon className={css["icon"]} icon={faXmark} />}
            openCalendarOnFocus
            value={props.expires}
            onChange={onChange}
            onCalendarOpen={() => setOpen(true)}
            onCalendarClose={() => setOpen(false)}
            maxDetail="month"
            minDetail="year"
            showLeadingZeros
        />
        <LinkExpirationPopper ref={setPopper} container={container} id={props.id} open={open} />
    </label>;
}

/** Props passed down to the {@link LinkExpiration} component. */
export interface LinkExpirationProps {
    /** The identifier of the link being edited. */
    id: string;
    /** The current expiration date of the link. */
    expires: string | null;
    /** An optional class name added to the <label> element. */
    className?: string;

    /** Callback invoked when the expiration date changes. */
    onChange(date: Date | null): void;
}
