/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the metadata interface.
import { Metadata } from "./meta";


/** Interface used to describe the JSON:API object. */
interface JsonApiWithoutMetadata {
    /** The version of the used JSON:API standard. */
    version: "1.0";
}

/** Interface used to describe the JSON:API object with some additional metadata. */
interface JsonApiWithMetadata<Meta extends Metadata = Metadata> extends JsonApiWithoutMetadata {
    /** The metadata attached to the message. */
    meta: Meta;
}

/**
 * Interface used to describe the JSON:API object.
 * Provides info about the implementation of the standard.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export type JsonApi = JsonApiWithoutMetadata | JsonApiWithMetadata;
