/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the request interfaces.
import { XhrPartialRequest, PreparedXhrRequest } from "./interfaces";

// Import the Node request runner.
import { executeRequest } from "./services/request/browser";

// Import the base class.
import { XhrHandler } from "./index";


/**
 * Implementation of the global Xhr class.
 * Provides access to all the supported requests.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
class BrowserXhr extends XhrHandler {
    /** @inheritDoc */
    protected executeRequest(request: PreparedXhrRequest): Promise<XhrPartialRequest> {
        return executeRequest(request);
    }
}

/** Export the xhr instance. */
export const Xhr = new BrowserXhr();
export * from "./services/config";
export * from "./services/auth";
export * from "./errors";
