/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the event handler class.
import { EventHandler } from "@andromeda/events";

// Import the asset interface.
import { ConversionAsset } from "./asset";


/** Interface used for the optional {@link AssetManager} cache. */
export abstract class AssetManagerCache extends EventHandler<AssetManagerCache.EventMap> {
    /**
     * Seeks the asset from the cache.
     *
     * @param {string} uuid The uuid of the requested asset.
     * @returns {Promise<ConversionAsset | null>} The asset that was stored in the cache.
     */
    abstract get(uuid: string): Promise<ConversionAsset | null>;

    /**
     * Stores a new asset into the cache.
     *
     * @param {ConversionAsset} asset The asset to store in the cache.
     */
    abstract set(asset: ConversionAsset): void;
}

/** Augment the {@link AssetManagerCache} interface. */
export namespace AssetManagerCache {
    /** Event map of the {@link AssetManagerCache}. */
    export interface EventMap {
        /** Event fired when a new asset is added to the cache. */
        "asset": ConversionAsset;
    }
}
