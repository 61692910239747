/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useCallback, useContext, useMemo, useState } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the custom components.
import { AutoresizeTextarea, SpeechRecognitionButton, useDeleteConfirm, useNotify } from "@andromeda/components";
// Import the file resource.
import { ZaqWikiFile } from "@andromeda/resources";
// Import the stores.
import { useResourceDispatch, ZaqWikiFileStore, ZaqWikiStore } from "@andromeda/store";

// Import the icon helper.
import { useIcon } from "../../helpers";
// Import the reader context.
import { ReaderContext } from "../context";
// Import the file context.
import { FileContext } from "./context";

// Import the icons.
import bin from "@andromeda/assets/images/bin-red.svg";
import grip from "@andromeda/assets/images/grip-tertiary.svg";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
// Import the css.
import css from "./header.module.scss";


/** Function-component used to render the header of a file. */
export function Header(): ReactElement {
    // Get the file context.
    const fileContext = useContext(FileContext);
    const readerContext = useContext(ReaderContext);

    // Load the title from the file.
    const [title, setTitle] = useState(function loadTitleFromFile(): string {
        if ("title" in fileContext.file.attributes) {
            return fileContext.file.attributes.title;
        }
        return fileContext.file.attributes.name;
    });

    // Get the icon and link flag.
    const icon = useIcon(fileContext.file, css["header__icon"], fileContext.expanded);
    // Deletes the file.
    const deleteConfirm = useDeleteConfirm("Vous êtes sur le point de supprimer ce fichier.");
    const dispatch = useResourceDispatch();
    const notify = useNotify();
    const deleteFile = useCallback(
        function deleteFile(): void {
            deleteConfirm().then(shouldDelete => {
                if (!shouldDelete) {
                    return;
                }

                // Delete the file.
                return dispatch(ZaqWikiFileStore.generator.delete(fileContext.file.id))
                    // Reload the wiki.
                    .then(() => dispatch(ZaqWikiStore.generator.readOne(readerContext.wiki.id)));
            }).catch(notify.fatal);
        },
        [notify.fatal, deleteConfirm, dispatch, fileContext.file.id, readerContext.wiki.id]
    );

    // Updates the file name or title if this is a section title.
    const update = useCallback(
        function updateFileName(): void {
            // Prepare the update.
            const update: ZaqWikiFile.Update = {
                type: ZaqWikiFile.Type,
                id: fileContext.file.id,
                attributes: { ["title" in fileContext.file.attributes ? "title" : "name"]: title }
            };

            dispatch(ZaqWikiFileStore.generator.update(update)).catch(notify.fatal);
        },
        [fileContext.file.id, fileContext.file.attributes, title, dispatch, notify.fatal]
    );

    // Pre-render the contents of the element.
    const contents = useMemo(
        function renderContents(): ReactElement {
            // Render the chevron.
            const visible = ZaqWikiFile.isAssetFile(fileContext.file) || ZaqWikiFile.isTextFile(fileContext.file);
            const chevronClassName = classNames(css["header__chevron"], { [css["header__chevron--visible"]]: visible });

            return <>
                <img className={css["header__grab-handle"]} src={grip} alt="drag-handle" />
                <FontAwesomeIcon className={chevronClassName} icon={faChevronRight} />
                {icon}
                <AutoresizeTextarea
                    className={css["header__text"]}
                    value={title}
                    placeholder={"Cliquez ici pour donner un nom à ce module."}
                    onBlur={update}
                    onChange={e => setTitle(e.currentTarget.value)}
                />
                <SpeechRecognitionButton
                    interimResults
                    stopWithUser
                    onText={setTitle}
                    onEnd={update}
                />
                <button className={css["header__bin"]} onClick={deleteFile}>
                    <img src={bin} alt="delete" />
                </button>
            </>;
        },
        [deleteFile, fileContext.file, icon, title, update]
    );

    // Compute the css modifiers.
    const className = useMemo(function computeClassName(): string {
        return classNames({
            [css["header"]]: true,
            [css["header--retracted"]]: fileContext.expanded,
            [css["header--editor"]]: true
        });
    }, [fileContext.expanded]);

    // Render the component.
    return <div className={className} children={contents} onClick={fileContext.toggleExpanded} />;
}
