/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import {
    createContext,
    PropsWithChildren,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
// Import Lodash.
import { noop } from "lodash";


/** Context used to show/hide the navbar's search button. */
interface NavbarSearchContext {
    /** Current visible state of the search button. */
    readonly visible: boolean;

    /**
     * Callback used to hide the search button in the navbar.
     *
     * @returns {VoidFunction} A callback used to show the button again.
     */
    hide(): VoidFunction;
}

/** @see NavbarSearchContext */
const NavbarSearchContext = createContext<NavbarSearchContext>({
    visible: false,
    hide(): VoidFunction {
        // Warn about this being a no-op.
        console.warn("You called a no-op NavbarSearchContext.hide method. Did you mount it inside of a Provider ?");

        // Return a noop.
        return noop;
    }
});

/** Provider component for the {@link NavbarSearchContext}. */
export function NavbarSearchContextProvider(props: PropsWithChildren): ReactElement {
    // Store the number of times "hide" was called.
    const [handle, setHandle] = useState(0);

    // Callback used to increment the "handle" counter.
    const incrementHandleCounter = useCallback(function incrementSearchContextHandle(): VoidFunction {
        // Increment the counter.
        setHandle(value => value + 1);

        // Return a callback used to decrement the counter.
        return function decrementSearchContextHandle(): void {
            setHandle(value => Math.max(0, value - 1));
        }
    }, []);

    // Build the value of the context.
    const context = useMemo(function getSearchContextValue(): NavbarSearchContext {
        return { hide: incrementHandleCounter, visible: handle <= 0 };
    }, [incrementHandleCounter, handle]);

    // Provide the context.
    return <NavbarSearchContext.Provider value={context} children={props.children} />;
}

/** Hides the navbar search button when the caller component is mounted. */
export function useHideNavbarSearchButton(): void {
    // Get the search context.
    const { hide } = useContext(NavbarSearchContext);

    // Call the hide method when mounted and unmounted.
    useEffect(hide, [hide]);
}

/** Component used to invoke the {@link useHideNavbarSearchButton} hook when mounted. */
export function HideNavbarSearchButton(): null {
    // Invoke the hide hook.
    useHideNavbarSearchButton();

    // Return null always.
    return null;
}

/** @returns {boolean} True if the search button should be visible. */
export function useIsNavbarSearchButtonVisible(): boolean {
    return useContext(NavbarSearchContext).visible;
}
