/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the bootstrap components.
import { Form } from "react-bootstrap";
// Import the csv parser.
import { parse, CsvError, Info } from "csv-parse/browser/esm";

// Import the keys interface.
import { Keys } from "./interfaces";
import { UserCreatorContext } from "../context";
import { User } from "@andromeda/resources";
import { EMAIL_REGEX } from "../hooks";

/** Component used to render the input used to load the data from a given csv file. */
export function Input(props: InputProps): React.ReactElement {
    // Stores the data loaded from the csv file.
    const [data, setData] = React.useState<Record<string, string[]>>({});

    // Reload the data when the file changes.
    const reloadData = React.useCallback(function loadData(
        ev: React.ChangeEvent<HTMLInputElement>
    ): void {
        const file = ev.currentTarget.files?.[0];
        if (!file) return;

        // Parse the text from the file.
        file.text()
            .then(text =>
                parse(
                    text,
                    { columns: true, delimiter: ";" },
                    function handleParseResults(
                        error: CsvError | undefined,
                        records: Record<string, string>[],
                        info: Info
                    ): void {
                        if (error) throw error;

                        const data: Record<string, string[]> = {};
                        const columns = (
                            info as unknown as { columns: { name: string }[] }
                        ).columns;
                        for (const column of columns) {
                            data[column.name] = [];
                            for (const record of records) {
                                data[column.name].push(record[column.name]);
                            }
                        }
                        setData(data);
                    }
                )
            )
            .catch(window.alert);
    },
    []);

    // Update the available keys when the data source changes.
    const { updateAvailableKeys } = props;
    React.useEffect(
        function updateKeysInParent(): void {
            let bestEmailGuess: string | null = null;
            for (const key of Object.keys(data)) {
                if (data[key][0].match(EMAIL_REGEX)) {
                    bestEmailGuess = key;
                    break;
                }
            }
            updateAvailableKeys(Object.keys(data), bestEmailGuess);
        },
        [data, updateAvailableKeys]
    );

    // Reload the staged users when the keys change.
    const { setStaged } = React.useContext(UserCreatorContext);
    React.useEffect(
        function reloadStagedUsers(): void {
            if (!props.keys) return;

            const size = Object.values(data)[0].length;
            const staged: User.Create.Attributes[] = [];
            for (let i = 0; i < size; i++) {
                staged.push({
                    email: props.keys.email ? data[props.keys.email][i] : "N/A",
                    username: props.keys.username
                        ? data[props.keys.username][i]
                        : undefined,
                    givenName: props.keys.givenName
                        ? data[props.keys.givenName][i]
                        : undefined,
                });
            }
            setStaged(staged);
        },
        [setStaged, data, props.keys]
    );

    // Render the component.
    return (
        <div>
            <Form.Control
                type="file"
                accept="text/csv"
                multiple={false}
                onChange={reloadData}
            />
        </div>
    );
}

/** Props passed down to the {@link Input} component. */
interface InputProps {
    /** List of all the currently used keys. */
    keys: Keys | null;
    /** Updates the list of available keys. */
    updateAvailableKeys(value: string[], predictedEmail: string | null): void;
}
