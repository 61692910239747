/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement } from "react";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the requirement resource.
import { Requirement } from "@andromeda/resources";

// Import the level icon.
import LevelIcon from "../../level-icon";

// Import the checkmark icon.
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
// Import the css.
import css from "./legend.module.scss";
import classNames from "classnames";


/** Function used to render the progress legend. */
export default function Legend(): ReactElement {
    return <div className={css["overflow-container"]}>
        <div className={css["legend"]}>
            <p className={css["title"]}>Légende</p>
            <div className={css["legend__row"]}>
                <div className={css["legend__row__cell"]}>
                    <LevelIcon
                        backgroundClassName={css["level-background"]}
                        level={Requirement.Level.novice}
                        className={css["icon"]}
                    />
                    <p className={css["text"]}>novice</p>
                </div>
                <div className={css["legend__row__cell"]}>
                    <LevelIcon
                        backgroundClassName={css["level-background"]}
                        level={Requirement.Level.apprentice}
                        className={css["icon"]}
                    />
                    <p className={css["text"]}>apprenti</p>
                </div>
                <div className={css["legend__row__cell"]}>
                    <LevelIcon
                        backgroundClassName={css["level-background"]}
                        level={Requirement.Level.master}
                        className={css["icon"]}
                    />
                    <p className={css["text"]}>maîtrise</p>
                </div>
                <div className={css["legend__row__cell"]}>
                    <LevelIcon
                        backgroundClassName={css["level-background"]}
                        level={Requirement.Level.expert}
                        className={css["icon"]}
                    />
                    <p className={css["text"]}>expert</p>
                </div>
                <div className={css["legend__row__cell"]}>
                    <p className={`${css["text"]} ${css["text--gray"]}`}>non&nbsp;applicable</p>
                </div>
            </div>
            <div className={css["legend__row"]}>
                <div className={css["legend__row__cell"]}>
                    <FontAwesomeIcon icon={faCheck} className={classNames(css["check"], css["check--objective-met"])} />
                    <p className={css["text"]}>objectif atteint</p>
                </div>
                <div className={css["legend__row__cell"]}>
                    <FontAwesomeIcon icon={faCheck} className={classNames(css["check"], css["check--validated"])} />
                    <p className={css["text"]}>validé par formateur</p>
                </div>
            </div>
        </div>
    </div>;
}
