/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the option interfaces.
import {
    XhrFetchOptionsWithPromisedBody,
    XhrFetchOptionsWithPromisedResponse,
    XhrFetchOptions,
    XhrOptionsWithPromisedResponse,
    XhrOptionsWithPromisedBody,
    XhrOptions,
    XhrPartialResponse,
    XhrResponse,
    XhrPartialRequest,
    PreparedXhrRequest
} from "./interfaces";

// Import the request builder.
import { prepareRequest } from "./services/request";
// Import the authentication token methods.
import { setAuthToken, clearAuthToken } from "./services/auth";


/**
 * Implementation of the global Xhr class.
 * Provides access to all the supported requests.
 */
export abstract class XhrHandler {
    /**
     * Helper method used to set the global auth token.
     * This token will be added to every request made with the package.
     *
     * @param {string} token The new value of the global authentication token.
     */
    public setAuthToken = setAuthToken;

    /** Helper method used to clear the global auth token. */
    public clearAuthToken = clearAuthToken;

    /**
     * Wrapper of {@link fetch} for GET requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the request.
     */
    public get(target: string | URL): Promise<XhrResponse>;

    /**
     * Wrapper of {@link fetch} for GET requests.
     * Returns a request object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedBody} options The options of the get request.
     * @returns {Promise<XhrPartialRequest>} A promise that resolves with the generated request.
     */
    public get(target: string | URL, options: XhrOptionsWithPromisedResponse): Promise<XhrPartialRequest>;

    /**
     * Wrapper of {@link fetch} for GET requests.
     * Returns a partially-loaded response object, with its headers and status code
     * but only a promise to its header.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedResponse} options The options of the get request.
     * @returns {Promise<XhrPartialResponse>} A promise that resolves with a partial response object.
     */
    public get(target: string | URL, options: XhrOptionsWithPromisedBody): Promise<XhrPartialResponse>;

    /**
     * Wrapper of {@link fetch} for GET requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptions} options The options of the get request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the full response object.
     */
    public get(target: string | URL, options: XhrOptions): Promise<XhrResponse>;

    /** Implementation ! */
    public get(
        target: string | URL,
        options?: XhrOptions | XhrOptionsWithPromisedBody | XhrOptionsWithPromisedResponse
    ): Promise<XhrResponse | XhrPartialResponse | XhrPartialRequest> {
        return this.fetch(target, { ...options, method: "GET" });
    }


    /**
     * Wrapper of {@link fetch} for HEAD requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the request.
     */
    public head(target: string | URL): Promise<XhrResponse>;

    /**
     * Wrapper of {@link fetch} for HEAD requests.
     * Returns a request object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedBody} options The options of the get request.
     * @returns {Promise<XhrPartialRequest>} A promise that resolves with the generated request.
     */
    public head(target: string | URL, options: XhrOptionsWithPromisedResponse): Promise<XhrPartialRequest>;

    /**
     * Wrapper of {@link fetch} for HEAD requests.
     * Returns a partially-loaded response object, with its headers and status code
     * but only a promise to its header.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedResponse} options The options of the get request.
     * @returns {Promise<XhrPartialResponse>} A promise that resolves with a partial response object.
     */
    public head(target: string | URL, options: XhrOptionsWithPromisedBody): Promise<XhrPartialResponse>;

    /**
     * Wrapper of {@link fetch} for HEAD requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptions} options The options of the get request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the full response object.
     */
    public head(target: string | URL, options: XhrOptions): Promise<XhrResponse>;

    /** Implementation ! */
    public head(
        target: string | URL,
        options?: XhrOptions | XhrOptionsWithPromisedBody | XhrOptionsWithPromisedResponse
    ): Promise<XhrResponse | XhrPartialResponse | XhrPartialRequest> {
        return this.fetch(target, { ...options, method: "HEAD" });
    }


    /**
     * Wrapper of {@link fetch} for POST requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the request.
     */
    public post(target: string | URL): Promise<XhrResponse>;

    /**
     * Wrapper of {@link fetch} for POST requests.
     * Returns a request object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedBody} options The options of the get request.
     * @returns {Promise<XhrPartialRequest>} A promise that resolves with the generated request.
     */
    public post(target: string | URL, options: XhrOptionsWithPromisedResponse): Promise<XhrPartialRequest>;

    /**
     * Wrapper of {@link fetch} for POST requests.
     * Returns a partially-loaded response object, with its headers and status code
     * but only a promise to its header.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedResponse} options The options of the get request.
     * @returns {Promise<XhrPartialResponse>} A promise that resolves with a partial response object.
     */
    public post(target: string | URL, options: XhrOptionsWithPromisedBody): Promise<XhrPartialResponse>;

    /**
     * Wrapper of {@link fetch} for POST requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptions} options The options of the get request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the full response object.
     */
    public post(target: string | URL, options: XhrOptions): Promise<XhrResponse>;

    /** Implementation ! */
    public post(
        target: string | URL,
        options?: XhrOptions | XhrOptionsWithPromisedBody | XhrOptionsWithPromisedResponse
    ): Promise<XhrResponse | XhrPartialResponse | XhrPartialRequest> {
        return this.fetch(target, { ...options, method: "POST" });
    }


    /**
     * Wrapper of {@link fetch} for PUT requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the request.
     */
    public put(target: string | URL): Promise<XhrResponse>;

    /**
     * Wrapper of {@link fetch} for PUT requests.
     * Returns a request object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedBody} options The options of the get request.
     * @returns {Promise<XhrPartialRequest>} A promise that resolves with the generated request.
     */
    public put(target: string | URL, options: XhrOptionsWithPromisedResponse): Promise<XhrPartialRequest>;

    /**
     * Wrapper of {@link fetch} for PUT requests.
     * Returns a partially-loaded response object, with its headers and status code
     * but only a promise to its header.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedResponse} options The options of the get request.
     * @returns {Promise<XhrPartialResponse>} A promise that resolves with a partial response object.
     */
    public put(target: string | URL, options: XhrOptionsWithPromisedBody): Promise<XhrPartialResponse>;

    /**
     * Wrapper of {@link fetch} for PUT requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptions} options The options of the get request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the full response object.
     */
    public put(target: string | URL, options: XhrOptions): Promise<XhrResponse>;

    /** Implementation ! */
    public put(
        target: string | URL,
        options?: XhrOptions | XhrOptionsWithPromisedBody | XhrOptionsWithPromisedResponse
    ): Promise<XhrResponse | XhrPartialResponse | XhrPartialRequest> {
        return this.fetch(target, { ...options, method: "PUT" });
    }


    /**
     * Wrapper of {@link fetch} for PATCH requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the request.
     */
    public patch(target: string | URL): Promise<XhrResponse>;

    /**
     * Wrapper of {@link fetch} for PATCH requests.
     * Returns a request object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedBody} options The options of the get request.
     * @returns {Promise<XhrPartialRequest>} A promise that resolves with the generated request.
     */
    public patch(target: string | URL, options: XhrOptionsWithPromisedResponse): Promise<XhrPartialRequest>;

    /**
     * Wrapper of {@link fetch} for PATCH requests.
     * Returns a partially-loaded response object, with its headers and status code
     * but only a promise to its header.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedResponse} options The options of the get request.
     * @returns {Promise<XhrPartialResponse>} A promise that resolves with a partial response object.
     */
    public patch(target: string | URL, options: XhrOptionsWithPromisedBody): Promise<XhrPartialResponse>;

    /**
     * Wrapper of {@link fetch} for PATCH requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptions} options The options of the get request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the full response object.
     */
    public patch(target: string | URL, options: XhrOptions): Promise<XhrResponse>;

    /** Implementation ! */
    public patch(
        target: string | URL,
        options?: XhrOptions | XhrOptionsWithPromisedBody | XhrOptionsWithPromisedResponse
    ): Promise<XhrResponse | XhrPartialResponse | XhrPartialRequest> {
        return this.fetch(target, { ...options, method: "PATCH" });
    }


    /**
     * Wrapper of {@link fetch} for DELETE requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the request.
     */
    public delete(target: string | URL): Promise<XhrResponse>;

    /**
     * Wrapper of {@link fetch} for DELETE requests.
     * Returns a request object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedBody} options The options of the get request.
     * @returns {Promise<XhrPartialRequest>} A promise that resolves with the generated request.
     */
    public delete(target: string | URL, options: XhrOptionsWithPromisedResponse): Promise<XhrPartialRequest>;

    /**
     * Wrapper of {@link fetch} for DELETE requests.
     * Returns a partially-loaded response object, with its headers and status code
     * but only a promise to its header.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedResponse} options The options of the get request.
     * @returns {Promise<XhrPartialResponse>} A promise that resolves with a partial response object.
     */
    public delete(target: string | URL, options: XhrOptionsWithPromisedBody): Promise<XhrPartialResponse>;

    /**
     * Wrapper of {@link fetch} for DELETE requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptions} options The options of the get request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the full response object.
     */
    public delete(target: string | URL, options: XhrOptions): Promise<XhrResponse>;

    /** Implementation ! */
    public delete(
        target: string | URL,
        options?: XhrOptions | XhrOptionsWithPromisedBody | XhrOptionsWithPromisedResponse
    ): Promise<XhrResponse | XhrPartialResponse | XhrPartialRequest> {
        return this.fetch(target, { ...options, method: "DELETE" });
    }


    /**
     * Wrapper of {@link fetch} for OPTIONS requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the request.
     */
    public options(target: string | URL): Promise<XhrResponse>;

    /**
     * Wrapper of {@link fetch} for OPTIONS requests.
     * Returns a request object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedBody} options The options of the get request.
     * @returns {Promise<XhrPartialRequest>} A promise that resolves with the generated request.
     */
    public options(target: string | URL, options: XhrOptionsWithPromisedResponse): Promise<XhrPartialRequest>;

    /**
     * Wrapper of {@link fetch} for OPTIONS requests.
     * Returns a partially-loaded response object, with its headers and status code
     * but only a promise to its header.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptionsWithPromisedResponse} options The options of the get request.
     * @returns {Promise<XhrPartialResponse>} A promise that resolves with a partial response object.
     */
    public options(target: string | URL, options: XhrOptionsWithPromisedBody): Promise<XhrPartialResponse>;

    /**
     * Wrapper of {@link fetch} for OPTIONS requests.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrOptions} options The options of the get request.
     * @returns {Promise<XhrResponse>} A promise that resolves with the full response object.
     */
    public options(target: string | URL, options: XhrOptions): Promise<XhrResponse>;

    /** Implementation ! */
    public options(
        target: string | URL,
        options?: XhrOptions | XhrOptionsWithPromisedBody | XhrOptionsWithPromisedResponse
    ): Promise<XhrResponse | XhrPartialResponse | XhrPartialRequest> {
        return this.fetch(target, { ...options, method: "OPTIONS" });
    }


    /**
     * Method used to run an HTTP request.
     * Returns a fully-loaded response object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrFetchOptions} options The options of the fetch request.
     * @returns {Promise<XhrResponse | XhrPartialResponse | XhrPartialRequest>}
     * A promise that resolves with the request.
     */
    public async fetch(target: string | URL, options: XhrFetchOptions): Promise<XhrResponse>;

    /**
     * Method used to run an HTTP request.
     * Returns a partially-loaded response object, with its headers and status code
     * but only a promise to its header.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrFetchOptionsWithPromisedBody} options The options of the fetch request.
     * @returns {Promise<XhrPartialResponse>}
     * A promise that resolves with the request.
     */
    public async fetch(target: string | URL, options: XhrFetchOptionsWithPromisedBody): Promise<XhrPartialResponse>;

    /**
     * Method used to run an HTTP request.
     * Returns a request object.
     *
     * @param {string | URL} target The target of the request.
     * @param {XhrFetchOptionsWithPromisedResponse} options The options of the fetch request.
     * @returns {Promise<XhrPartialRequest>}
     * A promise that resolves with the request.
     */
    public async fetch(target: string | URL, options: XhrFetchOptionsWithPromisedResponse): Promise<XhrPartialRequest>;

    /** Implementation ! */
    public async fetch(
        target: string | URL,
        options: XhrFetchOptions | XhrFetchOptionsWithPromisedBody | XhrFetchOptionsWithPromisedResponse
    ): Promise<XhrResponse | XhrPartialResponse | XhrPartialRequest> {
        // Run the request.
        const request = prepareRequest(target, options).then(request => this.executeRequest(request));

        // Check the options.
        if (options.configuration?.waitForResponse === false) {
            return request;
        } else if (options.configuration?.waitForBody === false) {
            return request.then(request => request.response);
        } else {
            // Wait for the body to be fully loaded.
            const response = await request.then(request => request.response);
            const body = await response.body;

            // Build the full response object.
            const fullResponse = { ...await response, body };
            delete (fullResponse as { abort?: unknown }).abort;

            return fullResponse;
        }
    }

    /** Implementation of the request object. */
    protected abstract executeRequest(request: PreparedXhrRequest): Promise<XhrPartialRequest>;

    /** Class constructor. Binds all methods. */
    public constructor() {
        this.get = this.get.bind(this);
        this.head = this.head.bind(this);
        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.patch = this.patch.bind(this);
        this.delete = this.delete.bind(this);
        this.options = this.options.bind(this);
    }
}

/** Declare the xhr handler instance. */
export declare const Xhr: XhrHandler;

/** Re-export the utility interfaces. */
export * from "./interfaces";
export * from "./services/auth";
export * from "./services/config";
export * from "./errors";
