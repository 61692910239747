/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useMemo } from "react";
// Import the store.
import { useCourseValidations, useDueDate, useDueDates, useRequirements, useSteps } from "@andromeda/store";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the course parser.
import parseCourseProgress from "./parse-course-progress";
// Import the custom types.
import type { CourseProgress } from "./interfaces";


// Re-export the interfaces.
export * from "./interfaces";

/** Helper used to load the progress from a given course and user. */
export function useProgress(courseId: string, userId: string | undefined): RequestStatus<CourseProgress> {
    // Load the steps, requirements and due-date.
    const steps = useSteps(courseId);
    const requirements = useRequirements(courseId, userId);
    const dueDate = useDueDate(courseId, userId);
    const validations = useCourseValidations(courseId, userId);

    // Parse the progress.
    return useMemo(
        function parseProgress(): RequestStatus<CourseProgress> {
            // Wait for everything to be loaded.
            if (!userId) {
                return RequestStatus.uninitialised();
            }
            if (!steps.isSuccess || !requirements.isSuccess || !dueDate.isSuccess || !validations.isSuccess) {
                return RequestStatus.loading();
            }

            return RequestStatus.success(
                parseCourseProgress(userId, steps.data, requirements.data, dueDate.data, validations.data)
            );
        },
        [
            dueDate.data,
            dueDate.isSuccess,
            requirements.data,
            requirements.isSuccess,
            steps.data,
            steps.isSuccess,
            userId,
            validations.data,
            validations.isSuccess
        ]
    );
}

/** Helper used to load the progress from a given course and list of users. */
export function useMultipleProgress(courseId: string, userIds: string[] = []): RequestStatus<CourseProgress[]> {
    // Load the steps, requirements and due-date.
    const steps = useSteps(courseId);
    const requirements = useRequirements(courseId, userIds);
    const dueDate = useDueDates(courseId, userIds);
    const validations = useCourseValidations(courseId, userIds);

    // Parse the progress.
    return useMemo(
        function parseProgress(): RequestStatus<CourseProgress[]> {
            // Wait for everything to be loaded.
            if (!steps.isSuccess || !requirements.isSuccess || !dueDate.isSuccess || !validations.isSuccess) {
                return RequestStatus.loading();
            }

            return RequestStatus.success(userIds
                .map(function parseUserProgress(user: string): CourseProgress | null {
                    // Load the requirements for the user.
                    const userRequirements = requirements.data.filter(
                        requirement => requirement.relationships.user.data.id === user
                    );
                    if (userRequirements.length <= 0) {
                        return null;
                    }

                    // Find the user's due-date.
                    const due = dueDate.data.find(date => date.relationships.user.data.id === user);
                    // Find the user's validations.
                    const userValidations = validations.data.filter(
                        validation => validation.relationships.user.data.id === user
                    );

                    return parseCourseProgress(user, steps.data, userRequirements, due, userValidations);
                })
                .filter((progress: CourseProgress | null): progress is CourseProgress => progress !== null)
            );
        },
        [
            dueDate.data,
            dueDate.isSuccess,
            requirements.data,
            requirements.isSuccess,
            steps.data,
            steps.isSuccess,
            userIds,
            validations.data,
            validations.isSuccess
        ]
    );
}
