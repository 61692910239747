/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API module.
import type { Filter } from "@andromeda/json-api";
// Import the requirement resource.
import { Requirement } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the store.
import { RequirementStore, ResourceStore } from "../../stores";
// Import the course hook.
import { useCourse } from "./course";
// Import the resource hooks.
import useResources from "./util/use-resources";
import useResource from "./util/use-resource";


/**
 * Hook used to download a requirement from the store.
 *
 * @param {string} step The identifier of the requirement's step.
 * @param {string} user The identifier of the requirement's user.
 * @param {boolean} [cache=true] Try to read from the cache if possible.
 * @return {RequestStatus<Requirement>} The status of the request.
 */
export function useRequirement(step: string, user: string, cache = true): RequestStatus<Requirement> {
    return useResource(RequirementStore, Requirement.Type, `${step}-${user}`, cache);
}

/**
 * Downloads all the requirement of the specified step.
 *
 * @param {string} course The course to load the requirements for.
 * @param {string} user The user to load the requirements for.
 * @return {RequestStatus<Requirement[]>} The status of the request.
 */
export function useRequirements(course: string, user: string | string[] = []): RequestStatus<Requirement[]> {
    // Prepare the filter used for the request.
    const steps = useCourse(course).data?.relationships.steps.data?.map(({ id }) => id);
    let filter: Filter | undefined = undefined;
    if (steps) {
        filter = {
            step: { id: { $in: steps }},
            // requirement: { user: { $in: Array.isArray(user) ? user: [user] }}
        };
    }

    // Prepare the selector used to load the requirements.
    const selector = function selector(state: ResourceStore<Requirement>): Requirement[] {
        return state.resources.filter(function requirementBelongsToOrg(requirement: Requirement): boolean {
            if (!user.includes(requirement.relationships.user.data.id)) {
                return false;
            }
            return Boolean(steps?.includes(requirement.relationships.step.data.id));
        });
    };

    // Return the resources.
    return useResources(RequirementStore, Requirement.Type, { filter }, selector);
}
