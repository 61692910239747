/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useEffect, useState } from "react";

// Import the css.
import css from "./fill.module.scss";


/** Component used to render the fill of the level icon. */
export default function LevelIconFill(props: LevelIconFillProps): ReactElement {
    // Store the current state of the fill.
    const [fillAmount, setFillAmount] = useState(0);

    // Animate the filling to go up to the provided amount.
    useEffect(function animateFillAmount(): VoidFunction {
        // Register the animation frame callback.
        const start: number = performance.now();
        let handle = window.requestAnimationFrame(animate);
        // Clear the callback if the component is unmounted.
        return function stopAnimationOnUnmount(): void {
            window.cancelAnimationFrame(handle);
        };

        /** Helper used to animate the  */
        function animate(time: number): void {
            // Get the time elapsed since the start.
            const elapsed = props.time > 1e-3 ? Math.max(time - start - props.delay, 0) / props.time : 1;

            // Interpolate the fill amount of the circle.
            setFillAmount(function interpolateFillAmount(): number {
                return Math.min(elapsed * props.amount, props.amount);
            });

            // Request the next frame.
            if (elapsed < 1) {
                handle = requestAnimationFrame(animate);
            }
        }
    }, [props.amount, props.delay, props.time]);

    // Check if the target is on the left side of the circle.
    const isOnLeftSide = fillAmount > Math.PI;
    // Get the target position of the arc.
    const offsetAngle = fillAmount - Math.PI / 2;

    // Build the commands for the path.
    const commands: string[] = [];

    // Render the path.
    commands.push(`M 16, 0`);
    const outer = {
        x: 16 + Math.cos(offsetAngle) * 16,
        y: 16 + Math.sin(offsetAngle) * 16,
    }
    commands.push(`A16, 16 0 ${+isOnLeftSide} 1 ${outer.x.toFixed(2)}, ${outer.y.toFixed(2)}`);
    commands.push(`L 16, 16`);
    commands.push(`L 16, 0`);

    // Render a circle if the value is very close to, or above 2π.
    if (2 * Math.PI - fillAmount < 1e-3) {
        return <circle className={css["fill"]} cx={16} cy={16} r={16} mask={props.mask} />;
    }
    // Render the path.
    return <path className={css["fill"]} d={commands.join(" ")} mask={props.mask} />;
}

/** Props passed down to the {@link LevelIconFill} component. */
export interface LevelIconFillProps {
    /** The amount that the icon should fill up to, in radians. */
    amount: number;

    /** The amount of time that the animation should take, in milliseconds. */
    time: number;
    /** The delay applied before the animation is started, in milliseconds. */
    delay: number;

    /** Mask applied to the path. */
    mask?: string;
}
