/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext, useEffect, useMemo, useState } from "react";
// Import RxJS.
import { forkJoin, Subject, Subscription } from "rxjs";
// Import the store.
import { AssetStore, CourseStore, DueDateStore, ExternalStore, RequirementStore, StepStore } from "@andromeda/store";
// Import the custom components.
import { SaveIndicator } from "@andromeda/components";
// Import the asset manager.
import { ConversionStatusContext } from "@andromeda/asset-manager";


/** Helper component used to render the save state of the course. */
export default function Saving(): ReactElement {
    // Load the save state of the store.
    const isSavingCourse = CourseStore.useSelector(
        state => !!state.updating || !!state.deleting
    );
    const isSavingStep = StepStore.useSelector(
        state => !!state.creating || !!state.updating || !!state.deleting
    );
    const isSavingRequirement = RequirementStore.useSelector(
        state => !!state.creating || !!state.updating || !!state.deleting
    );
    const isSavingExternal = ExternalStore.useSelector(
        state => !!state.creating || !!state.updating || !!state.deleting
    );
    const isSavingAsset = AssetStore.useSelector(
        state => !!state.creating || !!state.updating || !!state.deleting
    );
    const isSavingDueDate = DueDateStore.useSelector(
        state => !!state.creating || !!state.updating || !!state.deleting
    );

    // Find the state of all the conversions.
    const conversionStatusContext = useContext(ConversionStatusContext);
    const [isConverting, setIsConverting] = useState(false);
    useEffect(function attachToAllConversions(): () => void {
        setIsConverting(true);
        const subscriptions: Subscription[] = [];

        // Map all the observables to their state.
        const subjects: Subject<void>[] = [];
        for (const conversion of conversionStatusContext.conversions.values()) {
            const subject = new Subject<void>();
            subscriptions.push(conversion.subscribe({
                complete: () => subject.complete()
            }));
            subjects.push(subject);
        }

        // Merge all the subjects.
        subscriptions.push(forkJoin(subjects).subscribe({
            complete: () => setIsConverting(false),
            error: () => setIsConverting(false)
        }));

        return () => {
            subscriptions.forEach(s => s.unsubscribe());
        };
    }, [conversionStatusContext.conversions]);

    const isSaving = useMemo(
        () =>
            isSavingCourse || isSavingStep || isSavingRequirement
            || isSavingExternal || isSavingAsset || isSavingDueDate
            || isConverting,
        [
            isSavingCourse,
            isSavingStep,
            isSavingRequirement,
            isSavingExternal,
            isSavingAsset,
            isSavingDueDate,
            isConverting
        ]
    );

    return <SaveIndicator show={isSaving} position="top-left" />;
}
