/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import React-Router.
import { useNavigate } from "react-router-dom";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the modal.
import SearchModal from "./modal";
// Import the context.
import { useIsNavbarSearchButtonVisible } from "./context";

// Import the icons.
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
// Import the css.
import css from "./index.module.scss";


// Re-export the context tools.
export { NavbarSearchContextProvider, useHideNavbarSearchButton, HideNavbarSearchButton } from "./context";

/** Component used to render the search button of the navbar. */
export default function Search(): ReactElement | null {
    // Get the navigation callback.
    const navigate = useNavigate();

    // Store the state of the search modal.
    const [open, setOpen] = useState(false);

    // Ref used to navigate to the search when the modal is exited.
    const queryText = useRef<string>();
    useEffect(function clearQueryTextOnOpen(): void {
        // Clear the query text when the modal gets opened.
        if (open) {
            delete queryText.current;
        }
    }, [open]);

    // Callback used to close the modal.
    const submit = useCallback(function submitModal(text: string): void {
        // Store the text in the exit ref.
        queryText.current = text;

        // Close the modal.
        setOpen(false);
    }, []);

    // Callback used when the modal was exited.
    const exited = useCallback(function onModalExited(): void {
        // Check if the query text was set.
        if (typeof queryText.current !== "undefined") {
            // Navigate to the search screen.
            navigate(`/search?query=${queryText.current}`);
        }
    }, [navigate]);

    // Check if the button should be shown.
    if (!useIsNavbarSearchButtonVisible()) {
        return null;
    }

    // Render the component.
    return <>
        <button
            className={classNames(css["button"], { [css["button--toggled"]]: open })}
            onClick={() => setOpen(value => !value)}
        >
            <FontAwesomeIcon className={css["button__icon"]} icon={faMagnifyingGlass} />
        </button>
        <SearchModal show={open} onSubmit={submit} onExited={exited} onClose={() => setOpen(false)} />
    </>;
}
