/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the configuration loader.
import { getConfig } from "@andromeda/config";
// Import the notification context.
import { useNotify } from "@andromeda/components";

// Import the schema.
import Schema from "./schema.json";


/** Interface used to describe the configuration of the login package. */
export interface Config {
    /** Domain used to log the user in. */
    domain: string;
    /** Identifier of the client application. */
    clientId: string;
    /** Audience of the generated token. */
    audience: string;

    /** Domain that should be used for authentication. */
    authDomain: string;

    /** Configuration used for the unleash client. */
    unleash: {
        /** URL for the unleash server. */
        url: string;
        /** Client key for this instance. */
        clientKey: string;
        /** Name of the current app. */
        appName: string;
    }
}

/** Wrapper for {@link getConfig} used to simplify the use of the {@link Config} interface. */
export function useLoginConfig(): Config | null {
    const notify = useNotify();
    const [ config, setConfig ] = React.useState<Config | null>(null);

    React.useEffect((): void => {
        getConfig<{ login: Config }>(Schema).then(config => setConfig(config.login), notify.fatal);
    }, [notify.fatal]);

    return config;
}
