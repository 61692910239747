/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";

// Import the creator context.
import { UserCreatorContext } from "./context";
// Import the subcomponents.
import { Manual } from "./manual";
import { File } from "./file";

// Import the icons.
import pointer from "@andromeda/assets/images/pointer.svg";
import download from "@andromeda/assets/images/download.svg";
// Import the css.
import css from "./header.module.scss";

/** Component used to render the header of the creation module. */
export function Header(props: HeaderProps): React.ReactElement {
    const [ mode, setMode ] = React.useState(1);
    const { updateBody } = props;
    const { setStaged } = React.useContext(UserCreatorContext);
    React.useEffect(function selectRenderedBody(): void {
        setStaged([]);

        switch (mode) {
        case 1: updateBody(<Manual />); break;
        case 2: updateBody(<File />); break;
        default: updateBody(null); break;
        }
    }, [mode, setStaged, updateBody]);

    // Render the component.
    return <header className={css["header"]}>
        <h2>Inviter de nouveaux membres</h2>
        <div className={`${css["header__tab-container"]} d-none d-sm-flex`}>
            <Tab name="manuellement"    icon={pointer}  active={mode === 1} activate={() => setMode(1)}/>
            <Tab name="avec un fichier" icon={download} active={mode === 2} activate={() => setMode(2)}/>
        </div>
    </header>;
}

/** Props passed down to the {@link Header} component.*/
interface HeaderProps {
    /** Method used to update the rendered body of the container. */
    updateBody(element: React.ReactElement | null): void;
}

/** Function component used to render a tab. */
function Tab(props: TabProps): React.ReactElement {
    return <button
        className={`${css["header__tab"]} ${props.active ? css["header__tab--active"] : ""}`}
        onClick={props.activate}
    >
        {props.icon ? <img src={props.icon} alt={props.name} /> : null}
        <h3 children={props.name} />
    </button>;
}

/** Props passed down to the {@link Tab} component. */
interface TabProps {
    /** Rendered name of the tab. */
    name: string;
    /** Rendered icon for the tab. */
    icon?: string;

    /** Flag set to true if the tab is currently active. */
    active: boolean;
    /** Activates the tab. */
    activate(): void;
}
