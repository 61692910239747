/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the resource interface.
import {
    Resource,
    MessageHelper,
    MultipleMessageHelper,
} from "@andromeda/json-api";
// Import the Xhr package.
import { Xhr } from "@andromeda/xhr";
// Import the validation method.
import { validate } from "@andromeda/validation";

// Import the read options interface.
import { ReadManyOptions } from "./interfaces";
// Import the helper methods.
import {
    buildParams,
    extractUrl,
    parseValidatorAndOptions,
    PromisableValidateFunction,
    buildHeaders,
} from "./common";
// Import the message class.
import { SourceIsEmptyError } from "../../errors";

/**
 * Reads multiple resources from the server.
 * Seeks the target from the configuration.
 *
 * @template {string} T
 * The type of the requested resource.
 * @template {Resource<T>} R
 * The returned resource type.
 * @param {T} type The type of the requested resource.
 * @returns {Promise<MultipleMessageHelper<R>>}
 * A promise that resolves with a message object containing the requested resources.
 */
export function readMany<T extends string, R extends Resource<T>>(
    type: T
): Promise<MultipleMessageHelper<R>>;

/**
 * Reads multiple resources from the server.
 * Validates the received objects.
 * Seeks the target from the configuration.
 *
 * @template {string} T
 * The type of the requested resource.
 * @template {Resource<T>} R
 * The returned resource type.
 * @param {T} type The type of the requested resource.
 * @param {PromisableValidateFunction<R>} validate
 * The validation function used to check the received resource.
 * @returns {Promise<MultipleMessageHelper<R>>}
 * A promise that resolves with a message object containing the requested resource.
 */
export function readMany<T extends string, R extends Resource<T>>(
    type: T,
    validate: PromisableValidateFunction<R>
): Promise<MultipleMessageHelper<R>>;

/**
 * Reads multiple resources from the server.
 * Seeks the target from the configuration.
 *
 * @template {string} T
 * The type of the requested resource.
 * @template {Resource<T>} R
 * The returned resource type.
 * @param {T} type The type of the requested resource.
 * @param {ReadManyOptions} options Additional read options.
 * @returns {Promise<MultipleMessageHelper<R>>}
 * A promise that resolves with a message object containing the requested resources.
 */
export function readMany<T extends string, R extends Resource<T>>(
    type: T,
    options?: ReadManyOptions
): Promise<MultipleMessageHelper<R>>;

/**
 * Reads multiple resources from the server.
 * Validates the received objects.
 * Seeks the target from the configuration.
 *
 * @template {string} T
 * The type of the requested resource.
 * @template {Resource<T>} R
 * The returned resource type.
 * @param {T} type The type of the requested resource.
 * @param {PromisableValidateFunction<R>} validate
 * The validation function used to check the received resource.
 * @param {ReadOneOptions} options Additional read options.
 * @returns {ReadManyOptions<MultipleMessageHelper<R>>}
 * A promise that resolves with a message object containing the requested resources.
 */
export function readMany<T extends string, R extends Resource<T>>(
    type: T,
    validate: PromisableValidateFunction<R>,
    options?: ReadManyOptions
): Promise<MultipleMessageHelper<R>>;

/** Implementation ! */
export async function readMany<T extends string, R extends Resource<T>>(
    type: T,
    validatorOrOptions?: PromisableValidateFunction<R> | ReadManyOptions,
    readOptions?: ReadManyOptions
): Promise<MultipleMessageHelper<R>> {
    // Get the options object.
    const { validator, options } = parseValidatorAndOptions(
        validatorOrOptions,
        readOptions
    );

    // Prepare the options.
    const url: string = extractUrl(type, options);
    const searchParameters = buildParams(options);
    const headers = buildHeaders(options);

    // Run the request.
    const response = await Xhr.get(url, {
        searchParameters,
        path: options?.path ?? `/${type}`,
        headers,
    });
    if (
        response.body instanceof MessageHelper &&
        Array.isArray(response.body.data)
    ) {
        // Validate the response.
        if (typeof validator !== "undefined") {
            for (const resource of response.body.data) {
                validate(resource, await validator, true);
            }
        }

        return response.body as MultipleMessageHelper<R>;
    } else {
        throw new SourceIsEmptyError();
    }
}
