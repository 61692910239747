/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import {
    ComponentType,
    Context,
    createElement, Fragment,
    ReactElement,
    ReactNode,
    useMemo,
    useState,
    useTransition
} from "react";
// Import the context.
import { ToggleableButtonContext, DefaultToggleableButtonContext } from "./context";


/** Provides the given {@link ToggleableButtonContext} to its children. */
export default function ToggleableButtonContextProvider(props: ToggleableButtonContextProviderProps): ReactElement {
    // Store the state of the context.
    const [state, setState] = useState(!!props.defaultState);

    // Load the provider component.
    const { Provider } = useMemo(function computeProviderComponent(): Context<ToggleableButtonContext> {
        return props.context ?? DefaultToggleableButtonContext;
    }, [props.context]);

    // Compute the value of the context.
    const [isReloadingState, startStateTransition] = useTransition();
    const value = useMemo(function computeContextValue(): ToggleableButtonContext {
        return { state: props.state ?? state, toggle: props.onToggle ?? toggle };

        /** Callback used to switch between the edit and read modes. */
        function toggle(state?: boolean): void {
            startStateTransition(function transitionState(): void {
                setState(function updateStateValue(prevState: boolean): boolean {
                    // If a state was provided, set it to the explicit value.
                    if (typeof state === "boolean") {
                        return state;
                    }

                    // Toggle the state.
                    return !prevState;
                })
            })
        }
    }, [props.onToggle, props.state, state]);

    // Render the provider.
    if (isReloadingState && props.fallback) {
        return createElement(Fragment, { children: props.fallback });
    }
    return <Provider value={value} children={props.children} />;
}

/** Props passed down to the {@link ToggleableButtonContextProvider} component. */
export interface ToggleableButtonContextProviderProps {
    /** Children that will have access to this context. */
    children: ReactNode;

    /**
     * The context that should be provided by this provider.
     *
     * @default DefaultToggleableButtonContext
     */
    context?: Context<ToggleableButtonContext>;

    /**
     * Current state of the context.
     * If left undefined, the provider will use an internal state.
     */
    state?: boolean;

    /**
     * Default state of the context.
     *
     * @default false
     */
    defaultState?: boolean;

    /** A fallback component rendered while the state is changing. */
    fallback?: ReactNode;

    /** Callback invoked when the state of the button should change. */
    onToggle?(state?: boolean): void;
}

/**
 * Helper method used to wrap a given component inside of a {@link ToggleableButtonContextProvider}.
 *
 * @template {object} P
 * @param {React.ComponentType<P>} component The component to wrap.
 * @param {React.Context<ToggleableButtonContext>} [context=DefaultToggleableButtonContext] The context to provide.
 * @return {React.ComponentType<P>} The wrapped component.
 */
export function withToggleableButtonContextProvider<P extends object>(
    component: ComponentType<P>,
    context: Context<ToggleableButtonContext> = DefaultToggleableButtonContext
): ComponentType<P> {
    return function withToggleableButtonContext(props: P): ReactElement {
        return <ToggleableButtonContextProvider context={context} children={createElement(component, props)} />;
    }
}
