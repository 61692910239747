/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { useMemo, ReactNode, ReactElement } from "react";


// Import the css.
import css from "./row.module.scss";


/** Component used to render a table row. */
export function TableRow(props: TableRowProps): ReactElement {
    // Render the row class name.
    const rowClassName = useMemo(function renderRowClassName(): string {
        let className = css["table-row"];
        if (props.className) {
            className += ` ${props.className}`;
        }
        return className;
    }, [props.className]);

    // Render the cell list.
    const cells = useMemo(function renderCells(): ReactNode[] {
        if (typeof props.cells === "undefined") {
            return [];
        }

        return props.cells.map(function renderCell(cell: ReactNode, index: number): ReactElement {
            // Check if the cell is already a "td" element.
            if (typeof cell === "object" && cell !== null && ("type" in cell)) {
                if (cell.type === "td") {
                    // Check if the cell has a valid key.
                    if (cell.key === null) {
                        cell.key = index;
                    }
                    return cell;
                }
            }
            return <td className={props.tdClassName} key={index} children={cell} />;
        });
    }, [props.cells, props.tdClassName]);

    return <tr className={rowClassName}>
        <th className={props.thClassName} key="head" children={props.header} />
        {cells}
    </tr>;
}

/** Props passed down to the {@link TableRow} component. */
interface TableRowProps {
    /** The contents of the first node. */
    header: ReactNode;
    /** The contents of the rest of the row. */
    cells: ReactNode[] | undefined;

    /** Class name to add to the <tr> element. */
    className?: string;
    /** Class name to add to the <th> element. */
    thClassName?: string;
    /** Class name to add to the <td> element. */
    tdClassName?: string;
}
