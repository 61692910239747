/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the bootstrap components.
import { Row, Col } from "react-bootstrap";

// Import the subcomponents.
import { Header } from "./header";
import { Body } from "./body";
import { Categories } from "./categories";
import { Tags } from "./tags";


/**
 * Function-component used to render the content of a Wiki object.
 * NOTE: This DOES NOT render the files of the object ! Only its title/body.
 */
export function Content(): React.ReactElement {
    // Render the component.
    return <>
        <Row className="mt-2">
            <Col className="d-flex flex-column align-items-stretch">
                <Header />
                <Body />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="d-flex flex-row justify-content-start align-items-center">
                <Categories />
            </Col>
        </Row>
        <Row className="mt-3">
            <Tags />
        </Row>
    </>;
}
