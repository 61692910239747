/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { CSSProperties, ReactElement, ReactNode, useMemo } from "react";

// Import the css.
import css from "./table.module.scss";


/** Component used to render a CSS-grid based table on the screen. */
export default function Table(props: TableProps): ReactElement {
    const rowCount = useMemo(() => props.source.length, [props.source.length]);
    const colCount = useMemo(
        () => props.columnCount ?? props.source[0]?.length ?? 0,
        [props.columnCount, props.source]
    );

    // Flatten the list of cells.
    const cells = useMemo(function renderTableCells(): ReactNode[] {
        // Apply the styling to all the cells.
        const styledCells: ReactNode[][] = props.source.map(
            function styliseRow(row: readonly (Cell | ReactNode)[], rowIndex: number): ReactNode[] {
                const colCount = row.length;
                return row.map(function styliseCell(cell: Cell | ReactNode, columnIndex: number): ReactNode {
                    // Build the class name for the cell.
                    let className = css["cell"];
                    if (rowIndex === 0 && columnIndex === 0) {
                        className += ` ${css["cell--hidden"]}`;
                    }
                    if (rowIndex === 0 && columnIndex === 1) {
                        className += ` ${css["cell--rounded-top-left"]}`;
                    }
                    if (rowIndex === 0 && columnIndex >= colCount - 1) {
                        className += ` ${css["cell--rounded-top-right"]}`;
                    }
                    if (rowIndex === 1 && columnIndex === 0) {
                        className += ` ${css["cell--rounded-top-left"]}`;
                    }
                    if (rowIndex === rowCount - 1 && columnIndex === 0) {
                        className += ` ${css["cell--rounded-bottom-left"]}`;
                    }
                    if (rowIndex === rowCount - 1 && columnIndex === colCount - 1) {
                        className += ` ${css["cell--rounded-bottom-right"]}`;
                    }
                    if (rowIndex === rowCount - 1) {
                        className += ` ${css["cell--no-bottom-border"]}`;
                    }
                    if (columnIndex === colCount - 1) {
                        className += ` ${css["cell--no-right-border"]}`;
                    }
                    if (typeof cell === "object" && cell !== null && "className" in cell) {
                        className += ` ${cell.className}`;
                    }

                    // Extract the children from the cell.
                    let children: ReactNode;
                    if (typeof cell === "object" && cell !== null && "contents" in cell) {
                        children = cell.contents;
                    } else {
                        children = cell;
                    }

                    // Wrap the cell in a container.
                    return <div key={`${rowIndex}.${columnIndex}`} className={className} children={children} />;
                });
            }
        );

        return styledCells.flat();
    }, [props.source, rowCount]);

    // Build the style of the table div.
    const style = useMemo(function buildTableType(): CSSProperties {
        return {
            "--column-count": colCount,
            "--column-size": props.columnSize,
            "--row-size": props.rowSize,
            "--header-column-size": props.headerColumnSize
        } as CSSProperties;
    }, [colCount, props.columnSize, props.headerColumnSize, props.rowSize]);

    // Render the component.
    return <div className={css["overflow-container"]}>
        <div className={css["table"]} style={style}>
            {cells}
            {props.children}
        </div>
    </div>;
}

/** Props passed down to the {@link Table} component. */
export interface TableProps {
    /** Data to render in the table. */
    source: TableSource;

    /** Number of column in this table. */
    columnCount?: number;
    /** Size applied to the columns. */
    columnSize?: string;
    /** Size applied to the rows. */
    rowSize?: string;
    /** Size applied to the header column. */
    headerColumnSize?: string;

    /** Optional children added below the table. */
    children?: ReactNode;
}

/** Helper type used to provide info about how to render a given cell. */
export interface Cell {
    /** The contents of the cell. */
    contents: ReactNode;

    /** A class name to apply to the cell. */
    className?: string;
}

/** Interface used to describe the type of the data to render in the table. */
export type TableSource = (Cell | ReactNode)[][];
