/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement } from "react";

// Import the custom store.
import { useSave, useWasModified } from "../../store";

// Import the icons.
import { ReactComponent as Checkmark } from "@andromeda/assets/svg/checkmark.svg";
// Import the css.
import css from "./save.module.scss";


/** Component used to render the save button. */
export default function Save(): ReactElement {
    return <button onClick={useSave()} disabled={!useWasModified()} className={css["button"]}>
        <Checkmark className={css["button__icon"]} />
        Sauvegarder mes changements
    </button>;
}
