/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { createContext } from "react";

// Import the page state interface.
import type PDFPage from "./page";


/**
 * Object used to describe the context of a rendered PDF document.
 * The document can be manipulated via the {@link PDFPage} wrapper objects.
 */
export interface PDFContext {
    /** Number of pages on this document. */
    readonly pageCount: number;

    /** List of all the pages that can be rendered. */
    readonly pages: ReadonlyArray<PDFPage>;
}

/** Context used to manipulate and render a given PDF document. */
export const PDFContext = createContext<PDFContext>({
    pageCount: Number.NaN,
    pages: [],
});
PDFContext.displayName = "PDFContext";
