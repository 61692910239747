/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */


/** Return type fo the {@link makeContextNoop} function. */
export type NoopFunction = () => never;

/** Exception that is thrown by the {@link NoopFunction} object. */
export class NoopError extends Error {
    public constructor(context: string, name: string) {
        super(
            `A no-op function (${name}) was called.\n` +
            `Did you mount this component under a provider for the ${context} context ?`
        );
    }
}

/** Exception that is thrown by the {@link NeverProxy} object. */
export class NeverProxyError extends Error {
    public constructor(context: string, name: string) {
        super(
            `A never proxy for an object (${name}) was called.\n` +
            `Did you mount this component under a provider for the ${context} context ?`
        );
    }
}

/**
 * Helper tool used to make a noop function for a react context's default state.
 *
 * @param {string} context The name of the context to noop for.
 * @param {string} name The name of the no-oped function.
 * @returns {NoopFunction} A no-op function.
 */
export function makeContextNoop(context: string, name: string): NoopFunction {
    return function noop(): never { throw new NoopError(context, name); }
}

/**
 * Helper tool used to make a context invalid object.
 *
 * @param {string} context The name of the context to noop for.
 * @param {string} name The name of the no-oped function.
 * @returns {NoopFunction} A no-op function.
 */
export function makeContextInvalidObject<T>(context: string, name: string): T {
    return new Proxy({}, new NeverProxy(context, name)) as unknown as T;
}

/** Proxy that throws any time the underlying object is accessed. */
class NeverProxy implements ProxyHandler<never> {
    public constructor(private context: string, private name: string) {}
    get(): never { throw new NeverProxyError(this.context, this.name); }
    set(): never { throw new NeverProxyError(this.context, this.name); }
    apply(): never { throw new NeverProxyError(this.context, this.name); }
    construct(): never { throw new NeverProxyError(this.context, this.name); }
    defineProperty(): never { throw new NeverProxyError(this.context, this.name); }
    deleteProperty(): never { throw new NeverProxyError(this.context, this.name); }
    getOwnPropertyDescriptor(): never { throw new NeverProxyError(this.context, this.name); }
    getPrototypeOf(): never { throw new NeverProxyError(this.context, this.name); }
    has(): never { throw new NeverProxyError(this.context, this.name); }
    isExtensible(): never { throw new NeverProxyError(this.context, this.name); }
    ownKeys(): never { throw new NeverProxyError(this.context, this.name); }
    preventExtensions(): never { throw new NeverProxyError(this.context, this.name); }
    setPrototypeOf(): never { throw new NeverProxyError(this.context, this.name); }
}
