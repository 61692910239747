/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the AJV Interfaces.
import { AnySchema, AnySchemaObject, ValidateFunction } from "ajv";
// Import the YAML parser.
import * as YAML from "yaml";

// Import the cache tool.
import { checkForCache, getAjv } from "./lib/cache";


// Re-export the main methods.
export * from "./lib/index";

/**
 * Function used to compile the provided schema(s).
 *
 * @template T
 * @param {AnySchema} schema The schema to compile.
 */
export function compileSync<T>(schema: AnySchema): ValidateFunction<T> {
    const ajv = getAjv({ loadSchema });
    if (typeof schema === "object" && "$id" in schema && typeof schema.$id !== "undefined") {
        const existing = ajv.getSchema<T>(schema.$id);
        if (existing) return existing;
    }
    return ajv.compile<T>(schema);
}

/**
 * Function used to compile the provided schema(s).
 *
 * @template T
 * @param {AnySchema | string} schema The schema to compile.
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 1
 */
export function compile<T>(schema: AnySchema | string): Promise<ValidateFunction<T>> {
    return checkForCache<T>(schema, { loadSchema });
}

/** Special validation function that never validates. */
export const neverValidates = compileSync<never>(false);

/**
 * Polyfill used to load a schema from the web.
 *
 * @param {string} uri The uri of the schema to load.
 * @returns {Promise<AnySchemaObject>} A promise that resolves with the loaded schema.
 */
async function loadSchema(uri: string): Promise<AnySchemaObject> {
    // Load the schema.
    const schemaResponse = await window.fetch(uri);
    if (!schemaResponse.ok) throw new Error("Failed to load the schema " + uri);

    const type = schemaResponse.headers.get("Content-Type");
    if (type?.includes("application/json")) {
        return schemaResponse.text().then(JSON.parse);
    } else if (type?.includes("text/yaml")) {
        return schemaResponse.text().then(YAML.parse);
    } else {
        throw new Error("Unsupported schema MIME type: " + type);
    }
}
