/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API error class.
import { JsonApiError } from "@andromeda/json-api";

/**
 * Error thrown if a relationship given to readByRelationship is invalid.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class InvalidRelationshipError extends JsonApiError {
    /** @inheritDoc */
    public override get code(): string {
        return "0x10703";
    }

    /** @inheritDoc */
    public constructor() {
        super("A given relationship has no data or self link !");
    }
}
