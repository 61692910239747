/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the method, header and search parameter interfaces.
import { XhrMethod, XhrHeaders, XhrSearchParameters, XhrRequestBodyType } from "./common";


/** Interface used to describe additional configuration of a fetch request. */
interface XhrFetchConfig {
    /** Strips the "authorization" header if it was set. */
    noAuthorization?: boolean;

    /** If true, ignore redirections and return a 3XX response. */
    ignoreRedirects?: boolean;
    /** If true, does not try again after encountering a 429, 502 or 504 response. */
    ignoreRateLimits?: boolean;

    /**
     * If true, returns the response object directly from the fetch request.
     * @default true
     */
    waitForResponse?: boolean;

    /**
     * If true, wait for the body of the response before resolving the fetch promise.
     * @default true
     */
    waitForBody?: boolean;
}

/** Interface used to specify a fetch configuration with the {@link waitForResponse} property set to {@code true}. */
interface XhrFetchConfigWithPromisedResponse extends XhrFetchConfig {
    /** @inheritDoc */
    waitForResponse: false;
}

/** Interface used to specify a fetch configuration with the {@link waitForBody} property set to {@code true}. */
interface XhrFetchConfigWithPromisedBody extends XhrFetchConfig {
    /** @inheritDoc */
    waitForBody: false;
}

/**
 * Interface used to describe the options passed to a fetch request.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export interface XhrFetchOptions {
    /** The HTTP verb used for the request. */
    method: XhrMethod;
    /** The sub-path of the request. */
    path?: string;

    /** Headers to pass along with the request. */
    headers?: XhrHeaders;
    /** Search parameters applied to the request. */
    searchParameters?: XhrSearchParameters;
    /** Body of the request. */
    body?: XhrRequestBodyType;

    /** Additional configuration of the request. */
    configuration?: XhrFetchConfig;
    /** Signal used to interrupt the request. */
    signal?: AbortSignal;
}

/**
 * Interface used to describe the fetch options with a {@link XhrFetchConfigWithPromisedResponse} as configuration.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export interface XhrFetchOptionsWithPromisedResponse extends XhrFetchOptions {
    /** @inheritDoc */
    configuration: XhrFetchConfigWithPromisedResponse;
}

/**
 * Interface used to describe the fetch options with a {@link XhrFetchConfigWithPromisedBody} as configuration.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export interface XhrFetchOptionsWithPromisedBody extends XhrFetchOptions {
    /** @inheritDoc */
    configuration: XhrFetchConfigWithPromisedBody;
}

/**
 * Interface used to describe fetch options without a "method" parameter.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type XhrOptions = Omit<XhrFetchOptions, "method">;

/**
 * Interface used to describe fetch options without a "method" parameter
 * and with a {@link XhrFetchConfigWithPromisedResponse} as configuration.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type XhrOptionsWithPromisedResponse = Omit<XhrFetchOptionsWithPromisedResponse, "method">;

/**
 * Interface used to describe fetch options without a "method" parameter
 * and with a {@link XhrFetchConfigWithPromisedBody} as configuration.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type XhrOptionsWithPromisedBody = Omit<XhrFetchOptionsWithPromisedBody, "method">;
