/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode } from "react";
// Import React Router.
import { useSearchParams } from "react-router-dom";
// Import the custom components.
import { createNamedSwitchContext, SwitchContextProvider } from "@andromeda/components";


/** Context used for the editor switch. */
export const EditorSwitchContext = createNamedSwitchContext("course-editor");

/** Component used to provide the {@link EditorSwitchContext} in the tree. */
export default function EditSwitchProvider(props: EditSwitchProviderProps): ReactElement {
    // Get the edit parameter.
    const defaultState = useSearchParams()[0].get("edit") !== null;

    // Return the provider.
    return <SwitchContextProvider
        context={EditorSwitchContext}
        defaultState={defaultState}
        children={props.children}
    />;
}

/** Props passed down to the {@link EditSwitchProvider} component. */
export interface EditSwitchProviderProps {
    /** Children that will have access to the context. */
    children?: ReactNode;
}
