/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import rect.
import { useCallback, useContext } from "react";
// Import the WebAsset interface.
import { WebAsset } from "@andromeda/converter-lib";
// Import the RxJS module.
import { BehaviorSubject, filter, lastValueFrom, map } from "rxjs";
// Import the asset conversion interfaces.
import {
    HeightAndRatioDimensions,
    ImageAssetMetadata,
    SpecificDimensions,
    VideoAssetMetadata,
    WidthAndRatioDimensions
} from "@andromeda/asset-conversion";
// Import the sleep tool.
import { sleep } from "@andromeda/tools";

// Import the conversion status context.
import { ConversionStatusContext } from "../components";
// Import the info interfaces.
import { ConversionInfo, ConversionInfoDone, ConversionStatus, convert } from "../lib";


/**
 * Builds a new asset converter callback.
 *
 * @param {SpecificDimensions} target The target dimensions of the converter asset(s).
 * @return {(asset: WebAsset) => Promise<WebAsset>} A callback that can be used to convert an asset.
 */
export function useAssetConverter(target: SpecificDimensions): (asset: WebAsset) => Promise<WebAsset> {
    // Load the conversion context.
    const conversionContext = useContext(ConversionStatusContext);

    // Return the method.
    return useCallback(async function convertAsset(asset: WebAsset): Promise<WebAsset> {
        // Check if a conversion is already running.
        const running = conversionContext.conversions.get(asset.resource.id);
        if (running) {
            // If the running conversion is complete, just return the asset.
            if (running.value.status === ConversionStatus.done) {
                return running.value.assets[0];
            }

            // Wait for the conversion to finish.
            return lastValueFrom(running.pipe(
                filter((item: ConversionInfo): item is ConversionInfoDone => item.status === ConversionStatus.done),
                map(item => item.assets[0])
            ));
        }

        // Convert the element.
        let conversion: BehaviorSubject<ConversionInfo>;
        if (asset.resource.attributes.contentType.startsWith("image/")) {
            conversion = convert(asset, {
                contentType:
                    asset.resource.attributes.contentType === "image/png" ? "image/png" : "image/jpeg",
                dimensions: extractOptimalRatio(
                    (asset.resource.attributes as ImageAssetMetadata).width,
                    (asset.resource.attributes as ImageAssetMetadata).height,
                    target
                )
            });
        } else if (asset.resource.attributes.contentType.startsWith("video/")) {
            conversion = convert(asset, {
                contentType: "video/mp4",
                dimensions: extractOptimalRatio(
                    (asset.resource.attributes as VideoAssetMetadata).width,
                    (asset.resource.attributes as VideoAssetMetadata).height,
                    target
                ),
                framerate: Math.min(24, Math.max(1, (asset.resource.attributes as VideoAssetMetadata).framerate))
            });
        } else {
            throw new Error(
                "Tried to convert an unsupported file ! (File type was " +
                asset.resource.attributes.contentType +
                " )"
            );
        }

        // Push the conversion to the map of conversions.
        conversionContext.push(asset.resource.id, conversion);

        // Wait for the conversion to finish.
        const doneObservable = conversion.pipe(
            map(conversion => { console.log(conversion); return conversion; }),
            filter(function filterDoneConversion(conversion: ConversionInfo): conversion is ConversionInfoDone {
                return conversion.status === ConversionStatus.done;
            }),
            map((conversion) => conversion.assets[0]),
            filter((asset: WebAsset | undefined): asset is WebAsset => typeof asset !== "undefined")
        );

        let convertedAsset: WebAsset;
        try {
            convertedAsset = await lastValueFrom(doneObservable);
        } catch (e: unknown) {
            console.warn("Failed to convert an asset, trying again in 5 seconds.");
            await sleep(5000);
            return convertAsset(asset);
        }

        return convertedAsset;
    }, [conversionContext, target]);
}

/** Helps to find the optional ratio dimensions for a file. */
function extractOptimalRatio(
    width: number, height: number, fitInside: SpecificDimensions
): WidthAndRatioDimensions | HeightAndRatioDimensions {
    const ratio = width / height, targetRatio = fitInside.width / fitInside.height;
    if (ratio < targetRatio) {
        height = Math.max(Math.min(fitInside.height, height), 1);
        return { height, ratio };
    } else {
        width = Math.max(Math.min(fitInside.width, width), 1);
        return { width, ratio };
    }
}
