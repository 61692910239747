/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useMemo, useState } from "react";
// Import the JSON:API module.
import { ResourceIdentifier } from "@andromeda/json-api";

// Import the storage tools.
import { useFavouriteList } from "../../../storage";
// Import the local hooks.
import type { SearchFilter } from "../../../hooks";
// Import the selector.
import Select, { Option } from "../../select";
// Import the item wrapper.
import { ItemWrapper } from "../../item";
// Import the empty body component.
import Empty from "./empty";
// Import the widget.
import Widget from "../widget";

// Import the css.
import css from "./index.module.scss";


/** Component used to render the favourite widget. */
export default function FavouriteWidget(): ReactElement {
    // Get the list of favourites from the store.
    const favourites = useFavouriteList();

    // Get the currently rendered item type.
    const [type, setType] = useState<SearchFilter>("all");

    // Render the list of favourites.
    const items = useMemo(function renderFavouriteItemList(): ReactNode[] {
        return favourites
            .filter(function filterUnnecessaryItems(item: ResourceIdentifier): boolean {
                return type === "all" || item.type === type;
            })
            .map(function renderFavouriteItem(item: ResourceIdentifier): ReactNode {
                return <li key={`${item.type}/${item.id}`} className={css["item"]}>
                    <ItemWrapper resource={item} />
                </li>;
            });
    }, [favourites, type]);

    // Render the body of the widget.
    const body = useMemo(function renderWidgetBody(): ReactNode {
        // If there are no favourites, return the broken heart placeholder.
        if (items.length <= 0) {
            return <Empty />;
        }
        return <ul className={css["list"]} children={items} />;
    }, [items]);

    // Render the widget.
    return <Widget
        className={css["widget"]}
        title="Mes favoris"
        children={body}
        actions={<Select<SearchFilter> options={SelectOptions} value={type} onChange={setType} noSearch />}
    />;
}

/** Options passed down to the {@link Select} component. */
const SelectOptions: ReadonlyArray<Option<SearchFilter>> = [
    { name: "Tout", value: "all" },
    { name: "Tuto", value: "zaq-tuto" },
    { name: "Wiki", value: "zaq-wiki" },
    { name: "Training", value: "course" },
]
