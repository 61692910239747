/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useMemo, useState } from "react";
// Import the environment class.
import { Environment } from "@andromeda/config";

// Import the notification interface.
import { Notification, ErrorNotification } from "./notification";

// Import the icons.
import bug from "@andromeda/assets/images/bug.svg";
import warning from "@andromeda/assets/images/warning.svg";
import close from "@andromeda/assets/images/close.svg";
// Import the css.
import css from "./notification.module.scss";
import { useDiscordMessageHook } from "./discord";
import { jsonSanitiser } from "@andromeda/json-api";


/**
 * Renderer method used to show the list of provided notifications.
 * Also, provides a way for notifications to be cleared.
 */
export function NotificationRenderer(props: NotificationRendererProps): ReactElement {
    // Render all the notifications.
    const { clearNotification } = props;
    const notifications = useMemo(function renderNotifications(): (ReactElement | null)[] {
        return props.notifications
            .filter(notification => notification.type !== Notification.Type.fatal)
            .map(notification =>
                <NotificationItemRenderer
                    notification={notification}
                    clear={() => clearNotification(notification.uuid)}
                    key={notification.uuid}
                />
            );
    }, [ props.notifications, clearNotification ]);

    // Render the list.
    return <ol className={css["notification-list"]} children={notifications} />;
}

/** Props passed down to the {@link NotificationRenderer} component. */
interface NotificationRendererProps {
    /** The list of notifications to render. */
    notifications: Notification[];

    /**
     * Method used to clear a given notification.
     *
     * @param {string} uuid The uuid of the notification to clear.
     */
    clearNotification(uuid: string): void;
}

/** Function used to render a notification. */
function NotificationItemRenderer(props: NotificationItemRendererProps): ReactElement {
    // Compute the icon.
    const icon = useMemo(function renderNotifIcon(): ReactElement | null {
        switch (props.notification.type) {
        case Notification.Type.debug:
            return <img className={css["notification__icon"]} src={bug} alt="bug-icon" />;
        case Notification.Type.warning:
        case Notification.Type.error:
        case Notification.Type.fatal:
            return <img className={css["notification__icon"]} src={warning} alt="warning-icon" />;
        default: return null;
        }
    }, [ props.notification.type ]);

    // Store the dissipating state of the element.
    const [ dissipate, setDissipate ] = useState(false);
    useMemo(function queueDissipation(): (() => void) | void {
        // Errors and warnings should not auto-dissipate.
        if (props.notification.type === Notification.Type.fatal) return;
        if (props.notification.type === Notification.Type.error) return;
        if (props.notification.type === Notification.Type.warning) return;

        const timeout = setTimeout(() => setDissipate(true), 15 * 1000);
        return () => clearTimeout(timeout);
    }, [props.notification.type]);

    // Compute the class name.
    const className = useMemo(function computeClassName(): string {
        let className = css["notification"];
        // Hide the debug notifications in production.
        if (props.notification.type === Notification.Type.debug) {
            if (Environment.current !== Environment.development) {
                className += " " + css["notification--hidden"];
            }
        }

        // Add the notification type.
        className += " " + css[`notification--${props.notification.type}`];

        // Add the dissipation animation.
        if (dissipate) className += " " + css["notification--dissipating"];
        return className;
    }, [ dissipate, props.notification.type ]);

    // Render the error details.
    const report = useDiscordMessageHook(props.notification.discordWebhookContext);
    const detail = useMemo(function renderErrorDetail(): ReactElement | null {
        if (![ Notification.Type.error, Notification.Type.fatal ].includes(props.notification.type))
            return null;
        const notification = props.notification as ErrorNotification;

        // Stringify the error.
        let message: string;
        if (typeof notification.error === "object") {
            message = JSON.stringify(jsonSanitiser(notification.error), null, 4);
        } else {
            message = String(notification.error);
        }

        // Render the details of the error.
        return <div className={css["notification__detail"]}>
            <p>Détails de l'erreur: </p>
            <pre children={message} />
            <button onClick={() => {
                report(notification);
                setDissipate(true);
            }}>Envoyer l'erreur aux développeurs</button>
        </div>
    }, [ props.notification, report ]);

    // Render the notification.
    return <li
        className={className}
        onAnimationEnd={ev => {
            if (ev.animationName.includes("dissipate")) { props.clear(); }
        }}
    >
        <div className={css["notification__header"]}>
            <p className={css["notification__title"]} children={props.notification.title} />
            <button onClick={() => setDissipate(true)} className={css["notification__close-button"]}>
                <img src={close} alt="discard-notification" />
            </button>
        </div>
        <div className={css["notification__body"]}>
            <div className={css["notification__body__row"]}>
                {icon}
                <p className={css["notification__text"]} children={props.notification.text} />
            </div>
            <div className={css["notification__body__row"]} children={detail} />
        </div>
    </li>
}
/** Props passed down to the {@link NotificationItemRenderer} component. */
interface NotificationItemRendererProps {
    /** The notification to render. */
    notification: Notification;
    /** The method used to clear the notification. */
    clear(): void;
}
