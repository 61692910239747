/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the json:api resource interface.
import { Resource, Relationship } from "@andromeda/json-api";

// Import the asset metadata resource.
import { AssetMetadata } from "../../asset";
// Import the asset conversion request resource.
import { AssetConversionRequest } from "../request";
// Import the schema.
import * as Schema from "./schema.json";


/** Interface used to update the status of the asset converter. */
export interface AssetConversionResult extends Omit<Resource<AssetConversionResult.Type>, "attributes"> {
    /** @inheritDoc */
    relationships: AssetConversionResult.Relationships;
}

/** Augment the {@link AssetConversionResult} interface. */
export namespace AssetConversionResult {
    /** Type of {@link AssetConversionResult} resources. */
    export const Type = "asset-conversion_conversion-result" as const;
    export type Type = typeof Type;

    export interface Relationships {
        /** Reference to the {@link AssetConversionRequest} that was completed. */
        request: Relationship<AssetConversionRequest>;
        /** List of assets generated by this conversion. */
        assets: Relationship<AssetMetadata[]>;
    }

    /** ValidateFunction used to validate {@link AssetConversionResult} objects. */
    export const validate = compileSync<AssetConversionResult>(Schema);
}
