/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the store.
import { useCourse, useUser } from "@andromeda/store";

// Import the css.
import css from "./info.module.scss";


/** Component used to render the current ZaqTraining and User's names. */
export default function Info(props: InfoProps): ReactElement | null {
    // Load the course and user.
    const course = useCourse(props.course);
    const user = useUser(props.user);

    // Wait for the course and user to be loaded.
    if (!course.isSuccess || !user.isSuccess) {
        return null;
    }
    // Render the component.
    return <div className={css["info"]}>
        <span className={css["label"]}>ZaqTraining: </span>
        <span className={css["value"]}>{course.data.attributes.name}</span>
        <span className={css["label"]}>Utilisateur: </span>
        <span className={css["value"]}>{user.data.attributes.givenName}</span>
    </div>;
}

/** Props passed down to the {@link Info} component. */
export interface InfoProps {
    /** The identifier of the currently rendered course. */
    course: string | undefined;
    /** The identifier of the currently rendered user. */
    user: string | undefined;
}
