/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ChangeEvent, ReactElement, SyntheticEvent, useId, useMemo } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the store.
import { useSteps } from "@andromeda/store";

// Import the icons.
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./step-selector.module.scss";


/** Component used to render the step selector. */
export default function StepSelector(props: StepSelectorProps): ReactElement {
    // Load all the steps from the current course.
    const steps = useSteps(props.course);

    // Render the select options.
    const options = useMemo(function renderOptions(): [index: number | "undefined", name: string][] {
        const options: [id: number | "undefined", name: string][] = new Array((steps.data?.length ?? 0) + 1);

        // Add the "global" option.
        options[0] = ["undefined", "Global"];

        // Add all the step options.
        if (!steps.isSuccess) {
            return options;
        }
        for (let i = 0; i < steps.data.length; i++) {
            options[i + 1] = [i, steps.data[i].attributes.name];
        }
        return options;
    }, [steps.data, steps.isSuccess]);

    // Callback used to handle change events.
    function onChange(event: ChangeEvent<HTMLSelectElement>): void {
        if (event.currentTarget.value === "undefined") {
            props.onChange(undefined);
        } else {
            props.onChange(+event.currentTarget.value);
        }
    }

    // Helper used to propagate the click event to the child on safari.
    function propagateClickToSelect(event: SyntheticEvent<HTMLElement>): void {
        event.currentTarget.querySelector("select")?.[0]?.click();
    }

    // Render the component.
    const id = useId();
    return <label htmlFor={`selector-${id}`} className={css["selector__container"]} onClick={propagateClickToSelect}>
        <select
            id={`selector-${id}`}
            className={css["selector"]}
            children={options.map(([id, name]) => <option key={id} value={id} children={name} />)}
            value={typeof props.current === "undefined" ? "undefined" : props.current.toString(10)}
            onChange={onChange}
        />
        <FontAwesomeIcon className={css["selector__chevron"]} icon={faChevronDown} />
    </label>;
}

/** Props passed down to the {@link StepSelector} component. */
export interface StepSelectorProps {
    /** Identifier of the currently rendered course. */
    course: string;
    /** Index of the currently selected step. */
    current: number | undefined;

    /**
     * Callback invoked when the value of the current step has changed.
     *
     * @param {number | undefined} index The index of the newly selected step.
     */

    onChange(index: number | undefined): void;
}
