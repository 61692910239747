/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React-Redux.
import { useStore } from "react-redux";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";
// Import the ZaqWiki resource.
import { ZaqWiki } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the resource helper types.
import type { ReadManyOptions } from "@andromeda/resource-helper";

// Import the store.
import { ResourceStore, ZaqStore } from "../../stores";
// Import the resource hooks.
import useResources from "./util/use-resources";
import useResource from "./util/use-resource";
// Import the tag loader.
import { useManyZaqTags } from "./zaq-tag";

/**
 * Hook used to download a Zaq from the store.
 *
 * @param {string} id The identifier of the Zaq.
 * @param {string} organization The current organization context.
 * @param {boolean} [cache=true] Try to read from the cache if possible.
 * @return {RequestStatus<ZaqWiki>} The status of the request.
 */
export function useZaq(id: string, organization: string, cache = true): RequestStatus<ZaqWiki> {
    useManyZaqTags(organization);
    const status = useResource(ZaqStore, "zaq", id, cache);
    const store = useStore<any>();

    // Map the result to a zaq wiki object.
    if (!status.isSuccess) {
        return status;
    }

    // Return the data.
    const { data: zaq } = status as unknown as { data: any };
    return RequestStatus.success(mapZaqToWiki(zaq, store, organization));
}

/** Converts a date to a date string. */
function dateToString(date: any): string {
    date = new Date(date);
    return [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getDate().toString().padStart(2, "0")
    ].join("-");
}

/**
 * Downloads all the Zaq that match the provided options.
 *
 * @param {ReadManyOptions} [options] The options of the request.
 * @return {RequestStatus<ZaqWiki[]>} The status of the request.
 */
export function useManyZaq(organization: string, options?: ReadManyOptions): RequestStatus<ZaqWiki[]> {
    // Build the request options.
    if (!options) {
        // Convert the organization id to base64url.
        const buffer = Uint8Array.from(
            organization.match(/[0-9a-f]{2}/gi)?.map(byte => parseInt(byte, 16)) ?? []
        );
        const base64 = window.btoa(
            Array.prototype.map.call(buffer, byte => String.fromCharCode(byte)).join("")
        );

        options = {
            searchParams: {
                "filter[zaq][organization]": base64.replaceAll("+", "-").replaceAll("/", "_"),
                "page[size]": "1000"
            }
        };
    }

    // Prepare the selector used to load the ZaqWikis.
    const selector = function selector(state: ResourceStore<any>, store: any): ZaqWiki[] {
        // Return all the wiki.
        return state.resources.map(zaq => mapZaqToWiki(zaq, store, organization));
    };

    // Return the resources.
    return useResources(ZaqStore, "zaq", options, selector);
}

function mapZaqToWiki(zaq: any, store: any, organization: string): ZaqWiki {
    // Search for the tags and users in the store.
    const tags: any[] = store["zaq-tag"]?.resources ?? [];
    const users: any[] = store.user?.resources ?? [];

    // Map the wiki's tags.
    const mappedTags: string[] = zaq.relationships.tags.data
        .reverse()
        .map((tag: ResourceIdentifier) => tags.find(({ id }) => id === tag.id)?.attributes.text)
        .concat(dateToString(zaq.meta["created-at"]))
        .concat(users.find(({ id }) => id === zaq.meta["created-by"])?.attributes.givenName)
        .filter((item?: string): item is string => !!item);

    return {
        type: "zaq-wiki",
        id: zaq.id,
        attributes: {
            name: zaq.attributes.title,
            body: zaq.attributes.subtitle,
            tags: mappedTags.reverse()
        },
        relationships: {
            organisations: { data: [{ type: "organisation", id: organization }] },
            icon: { data: zaq.relationships.icon.data },
            categories: { data: [] },
            files: { data: [] }
        },
        meta: {
            "is-new-zaq": true,
            "legacy-id": zaq.meta["legacy-id"]
        }
    };
}
