/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useCallback, useMemo, useState } from "react";
// Import the Bootstrap components.
import { Container, Row } from "react-bootstrap";
// Import the roman numeral tool.
import { toRomanNumeral } from "@andromeda/tools";

// Import the notification interface.
import { ErrorNotification } from "./notification";
// Import the discord hook helper.
import { useDiscordMessageHook } from "./discord";

// Import the icons.
import criticalError from "@andromeda/assets/images/critical.svg";
import loader from "@andromeda/assets/images/loading.svg";
import check from "@andromeda/assets/images/checkmark.svg";
// Import the css.
import css from "./fatal.module.scss";


/** Component used to render a fatal error. */
export function FatalErrorRenderer(props: FatalErrorRendererProps): ReactElement {
    // Render the error list.
    const errors = useMemo(function renderErrorList(): ReactElement[] {
        return props.notifications.map(
            function renderErrorNotification(notification: ErrorNotification, index: number): ReactElement {
                return <FatalErrorItem
                    key={notification.uuid}
                    notification={notification}
                    index={props.notifications.length > 1 ? index : undefined}
                />;
            }
        );
    }, [props.notifications]);

    // Render the page.
    return <Container className={css["fatal-error"]}>
        <Row>
            <img src={criticalError} alt="critical-error" className={css["fatal-error__icon"]} />
        </Row>
        <Row>
            <div
                className={css["fatal-error__description"]}
                children={props.notifications.length > 1 ? MULTIPLE_ERRORS : SINGLE_ERROR}
            />
        </Row>
        <ol  className={css["fatal-error__error-list"]} children={errors} />
        <button
            className={css["fatal-error__button"]}
            onClick={() => window.location.assign("/")}
            children="Menu Principal"
        />
    </Container>;
}

/** Props passed down to the {@link FatalErrorRenderer} component. */
interface FatalErrorRendererProps {
    /** The list of error notifications to render. */
    notifications: ErrorNotification[];
}

/** Helper component used to render a single error item. */
function FatalErrorItem(props: FatalErrorItemProps): ReactElement {
    // Get the error reporter.
    const [ status, setStatus ] = useState<"unreported" | "sending" | "ok">("unreported");
    const reporter = useDiscordMessageHook(props.notification.discordWebhookContext);
    const report = useCallback(function report(): void {
        if (status !== "unreported") {
            return;
        }

        setStatus("unreported");
        reporter(props.notification).then(() => setStatus("ok"));
        setStatus("sending");
    }, [props.notification, reporter, status]);

    // Render the error's title.
    const title = useMemo(function renderErrorTitle(): ReactNode {
        let title: ReactNode = null;
        if (props.notification.title) {
            title = props.notification.title;
            if (typeof props.index !== "undefined") {
                title = <><em>{toRomanNumeral(props.index + 1)}.</em>{title}</>;
            }
        }
        return title;
    }, [props.index, props.notification.title])

    // Render the error's text.
    const text = useMemo(function renderErrorText(): ReactElement | null {
        if (!props.notification.text) {
            return null;
        }

        return <p children={props.notification.text} className={css["error__body__description"]} />;
    }, [props.notification.text]);

    // Build the button's contents.
    const buttonContents = useMemo(function renderButtonContents(): ReactNode {
        switch (status) {
        case "unreported":
            return <>
                envoyer cette erreur aux développeurs
            </>;
        case "sending":
            return <>
                <img src={loader} alt="loading" />
                erreur en cours d'envoi ...
            </>;
        case "ok":
            return <>
                <img src={check} alt="ok" />
                erreur rapportée
            </>;
        }
    }, [status]);

    // Render the error.
    return <li className={css["error"]}>
        <div className={css["error__header"]} children={title} />
        <div className={css["error__body"]}>
            {text}
            <p children="Détails :" className={css["error__body__details"]} />
            <pre className={css["error__body__code"]} children={String(props.notification.error)} />
        </div>
        <button
            className={`${css["error__button"]} ${css[`error__button--${status}`]}`}
            children={buttonContents}
            disabled={status !== "unreported"}
            onClick={report}
        />
    </li>
}

/** Props passed down to the {@link FatalErrorItem} component. */
interface FatalErrorItemProps {
    /** The error object to render. */
    notification: ErrorNotification;
    /** The index of the error. */
    index?: number;
}

// Text rendered when there is a single error.
const SINGLE_ERROR = <p>
    <em>Erreur fatale</em><br />
    Une erreur irrécupérable s'est produite sur cette page.<br />
    N'hésitez pas à nous contacter en cas de problème persistant.<br />
    <small>Détails de l'erreur:</small>
</p>;

// Text rendered when there are multiple errors.
const MULTIPLE_ERRORS = <p>
    <em>Erreur fatale</em><br />
    Plusieurs erreurs irrécupérables se sont produites sur cette page.<br />
    N'hésitez pas à nous contacter en cas de problème persistant.<br />
    <small>Détails des erreurs:</small>
</p>;
