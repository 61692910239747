/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useContext, useMemo } from "react";
// Import the common tools.
import { normalise, RequestStatus } from "@andromeda/tools";
// Import the JSON:API module.
import { ResourceIdentifier } from "@andromeda/json-api";
// Import the resources.
import { Course, Tag, ZaqWiki } from "@andromeda/resources";
// Import the store.
import { useCourses, useManyZaq, useManyZaqTags, useUsers } from "@andromeda/store";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the legacy ZaqTuto tools.
import { LegacyZaq, useLegacyZaqFromOrganisation } from "@andromeda/legacy-zaq";

// Import the storage tools.
import { useCachedData } from "../../storage";


/** Helper interface used to combine all searchable items into a single object. */
export interface SearchableItem {
    /** The resource being wrapped by this item. */
    resource: LegacyZaq | ZaqWiki | Course;

    /** The title of the resource. */
    title: string;
    /** The description of the resource. */
    description: string;
    /** A list of all the tags applied to the resource. */
    tags: string[];
}

/** Object used as the return type of {@link useSearchableItems}. */
export interface SearchableItemList extends Array<SearchableItem> {
    /** Flag set if the item list was loaded from cache. */
    cached: boolean;
}

/**
 * Hook used to download all the searchable items from the store.
 *
 * @returns {RequestStatus<SearchableItemList>} A list of all the items downloaded from the store.
 */
export function useSearchableItems(): RequestStatus<SearchableItemList, number | null> {
    // Get the identifier of the current organisation.
    const organisation = useContext(LoginContext).organisations.current.id;

    // Download all the items from the store.
    const zaq = useManyZaq(organisation);
    const tuto = useLegacyZaqFromOrganisation(organisation);
    const courses = useCourses(organisation);
    const zaqTags = useManyZaqTags(organisation);
    useUsers(organisation);

    // Concatenate all the results into one big array.
    const items = useMemo(
        function buildSearchableItemList(): RequestStatus<SearchableItem[], number> {
            // Propagate error states.
            switch (true) {
            case tuto.isError:
                return RequestStatus.error(tuto.error);
            case zaq.isError:
                return RequestStatus.error(zaq.error);
            case courses.isError:
                return RequestStatus.error(courses.error);
            case zaqTags.isError:
                return RequestStatus.error(zaqTags.error);
            }

            if (!tuto.isSuccess || !zaq.isSuccess || !courses.isSuccess || !zaqTags.isSuccess) {
                return RequestStatus.loading(
                    (+tuto.isSuccess + +zaq.isSuccess + +courses.isSuccess + +zaqTags.isSuccess) / 4
                );
            }

            // Map all the items.
            const source = [...tuto.data, ...zaq.data, ...courses.data];
            return RequestStatus.success(source.map(item => convertItem(item, [])));
        },
        [
            tuto.isSuccess,
            tuto.data,
            tuto.isError,
            tuto.error,
            courses.isSuccess,
            courses.data,
            courses.isError,
            courses.error,
            zaq.isSuccess,
            zaq.data,
            zaq.isError,
            zaq.error,
            zaqTags.isSuccess,
            zaqTags.isError,
            zaqTags.error
        ]
    );

    // Load the data from the cache if available.
    const cached = useCachedData<SearchableItem[]>(organisation, items);

    // Return the data.
    return useMemo(function getRequestStatus(): RequestStatus<SearchableItemList, number | null> {
        // If the items were downloaded from the store, return them.
        if (items.isSuccess) {
            return RequestStatus.success(Object.assign(items.data, { cached: false }));
        }

        // If the items failed to load, return the error.
        if (items.isError) {
            console.error(items.error);
            return items;
        }

        // If the cache is being loaded, return a loading status.
        if (typeof cached === "undefined" || cached === null) {
            return RequestStatus.loading(items.isLoading ? items.loadingMeta : null);
        }

        // Remove non-matching items from the cache.
        const filteredCache = cached.filter(
            item => item.resource.type !== "zaq-wiki" || !!item.resource.meta?.["is-new-zaq"]
        );

        // Return the cache.
        return RequestStatus.success(Object.assign(filteredCache, { cached: true }));
    }, [cached, items]);
}

/**
 * Converts a given item into a {@link SearchableItem}.
 *
 * @param {LegacyZaq | ZaqWiki | Course} item The item to convert
 * @param {Tag[]} tags The list of all the available tags.
 * @returns {SearchableItem} The converted item.
 */
function convertItem(item: LegacyZaq | ZaqWiki | Course, tags: Tag[]): SearchableItem {
    switch (item.type) {
    case "zaq-tuto":
        return {
            resource: item,
            tags: item.tags.map(tag => normalise(tag)),
            title: normalise(item.name),
            description: ""
        };
    case "zaq-wiki":
        return {
            resource: item,
            title: normalise(item.attributes.name) ?? "Sans nom",
            description: normalise(item.attributes.body) ?? "",
            tags: item.relationships.categories.data.reduce(
                function getWikiTag(tagList: string[], { id }: ResourceIdentifier<"tag">): string[] {
                    // Search for the tag in the input list.
                    const tag = tags.find(tag => tag.id === id);
                    if (typeof tag !== "undefined") {
                        tagList.push(normalise(tag.attributes.name));
                    }

                    // Return the list of all the tags.
                    return tagList;
                },
                item.attributes.tags.map(tag => normalise(tag))
            )
        };
    case "course":
        return {
            resource: item,
            title: normalise(item.attributes.name),
            description: "",
            tags: []
        };
    }
}
