/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API interfaces.
import { Resource, Relationship, ResourceIdentifier } from "@andromeda/json-api";
// Import the json:schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the resource helpers.
import { CreatableResource } from "@andromeda/resource-helper";

// Import the user resource.
import { User, Requirement } from "../..";

// Import the schemas.
import { ValidationSchema } from "./schema";


/** Interface used to represent a validation. */
export type Validation = Validation.ZaqValidation | Validation.ExternalValidation;

/** Augment the {@link Validation} interface. */
export namespace Validation {
    export const Type = "validation" as const;
    export type Type = typeof Type;

    /** Details of a minor step validation. */
    export interface Detail {
        /** Flag set if the minor step was validated. */
        validated?: boolean;
        /** Optional remarks added by the validator. */
        remarks?: string;
    }

    /** Interface used as a base for all the attributes interfaces. */
    interface AttributesBase {
        /**
         * Date at which the validation took place.
         * Expressed as an ISO 8601.
         */
        date: string;
    }
    /** Interface used for the attributes of a zaq validation. */
    interface ZaqAttributes extends AttributesBase {
        /** List of validation details. */
        detail: Detail[][];
    }
    /** Interface used for the attributes of an external validation. */
    interface ExternalAttributes extends AttributesBase {
        /** Level that the user has reached. */
        level: Requirement.Level | null;
    }

    /** Interface used as a base for all the relationship interfaces. */
    interface RelationshipsBase {
        /** User that was being validated. */
        user: Relationship<ResourceIdentifier<User.Type>>;
        /** User that made the validation. */
        validator: Relationship<ResourceIdentifier<User.Type>>;
    }
    /** Relationships of a validation made on a zaq. */
    interface ZaqRelationships extends RelationshipsBase {
        /** Reference to the validated zaq. */
        zaq: Relationship<Resource<"zaq">>;
    }
    /** Relationships of a validation made on an external item. */
    interface ExternalRelationships extends RelationshipsBase {
        /** Reference to the validated external. */
        external: Relationship<Resource<"external">>;
    }

    /** Validation for Zaq elements. */
    export interface ZaqValidation extends Resource<Validation.Type> {
        attributes: ZaqAttributes; relationships: ZaqRelationships;
    }
    /** Validation for external elements. */
    export interface ExternalValidation extends Resource<Validation.Type> {
        attributes: ExternalAttributes; relationships: ExternalRelationships;
    }

    /** Relationships of a {@link Validation} */
    // export type Relationships = ZaqRelationships | ExternalRelationships;

    export const validate = compileSync<Validation>(ValidationSchema.ResourceSchema);


    /** Interface used to update a {@link Validation}. */
    export type Update = never;

    /** Augments the {@link Update} interface. */
    export namespace Update {
        export const validate = compileSync<Validation.Update>(ValidationSchema.UpdateSchema);
    }


    /** Interface used to create new {@link Validation}. */
    export type Create = Create.CreateZaq | Create.CreateExternal;

    /** Augments the {@link Create} interface. */
    export namespace Create {
        type CreateZaqAttributes = Omit<ZaqAttributes, "date">;
        type CreateZaqRelationships = Omit<ZaqRelationships, "validator">;
        export interface CreateZaq extends Omit<CreatableResource<Validation.Type>, "attributes" | "relationships"> {
            attributes: CreateZaqAttributes; relationships: CreateZaqRelationships;
        }
        type CreateExternalAttributes = Omit<ExternalAttributes, "date">;
        type CreateExternalRelationships = Omit<ExternalRelationships, "validator">;
        export interface CreateExternal extends Omit<CreatableResource<Validation.Type>, "attributes" | "relationships"> {
            attributes: CreateExternalAttributes; relationships: CreateExternalRelationships;
        }
        export const validate = compileSync<Validation.Create>(ValidationSchema.CreateSchema);
    }
}
