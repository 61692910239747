/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";

// Import the confirmation hook.
import { useConfirm, ConfirmBodyProps } from "@andromeda/components";

// Import the subcomponents.
import { VideoCapture } from "./video";
import { AudioCapture } from "./audio";

// Import the css.
import css from "./capture.module.scss";


/** Wrapper of {@link useConfirm} that captures the user's screen. */
export function useCaptureModal(): (props: Omit<CaptureModalBodyProps, keyof ConfirmBodyProps<void>>) => Promise<void> {
    return useConfirm({
        fullscreen: true,
        transparent: true,
        cancel: undefined,
        header: () => null, // Do not render a header.
        body: CaptureModalBody
    });
}

/** Body rendered by the {@link useCaptureModal}. */
function CaptureModalBody(props: CaptureModalBodyProps): React.ReactElement {
    // Render the component.
    if (props.mode === "audio") {
        return <div
            className={css["capture"]}
            children={<AudioCapture resolve={props.resolve} reject={props.reject} />}
        />;
    }
    return <div className={css["capture"]}>
        <VideoCapture source={props.mode} resolve={props.resolve} reject={props.reject} />
    </div>;
}

/** Props passed down to the {@link CaptureModalBody} component. */
interface CaptureModalBodyProps extends ConfirmBodyProps<void> {
    /** Current capture mode. */
    mode: "screen" | "video" | "audio";
}
