/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json:api resource interface.
import { Resource, Relationship } from "@andromeda/json-api";
// Import the schema compiler.
import { compileSync } from "@andromeda/validation";

// Import the organisation resource.
import { Organisation } from "../..";
// Import the schemas.
import { EncryptionSourceSchema } from "./schema";

/**
 * Interface used to represent an encryption source.
 * Encryption sources are used to query encryption keys.
 */
export interface EncryptionSource extends Resource<EncryptionSource.Type> {
    /** @inheritDoc */
    attributes: EncryptionSource.Attributes;
    /** @inheritDoc */
    relationships: EncryptionSource.Relationships;
}

/** Namespace used to augment the {@link EncryptionSource} interface. */
export namespace EncryptionSource {
    /** Value of the {@link EncryptionSource}'s type. */
    export const Type = "encryption-source" as const;
    /** Type of the {@link EncryptionSource}'s type. */
    export type Type = typeof Type;

    /** Interface used to describe the attributes of a local encryption source. */
    interface LocalSourceAttributes {
        /** Encryption key, encoded in base64. */
        key: string;
        /** MD5 hash of the encryption key. */
        md5: string;
    }

    /** Union of all the source attributes. */
    export type Attributes = LocalSourceAttributes;

    /** Relationships of a {@link EncryptionSource} resource. */
    export interface Relationships {
        /** List of all the organisations using this encryption source. */
        organisations: Relationship<Organisation[]>;
    }

    /** Validation function used to check {@link EncryptionSource} objects. */
    export const validate = compileSync<EncryptionSource>(
        EncryptionSourceSchema.ResourceSchema
    );

    // Re-export the schema.
    export const Schema = EncryptionSourceSchema;

    /** Interface used to represent creation requests for {@link EncryptionSource} objects. */
    export interface Create
        extends Omit<Resource<EncryptionSource.Type>, "attributes"> {
        /** @inheritDoc */
        relationships: Create.Relationships;
    }

    /** Augment the {@link Create} interface. */
    export namespace Create {
        /** Relationships of the {@link Create} resource. */
        export interface Relationships {
            /** Reference to the organisation that will own this {@link EncryptionSource}. */
            organisation: Relationship<Organisation>;
        }

        /** Validation function used to check {@link Create} objects. */
        export const validate = compileSync<Create>(
            EncryptionSourceSchema.CreateSchema
        );
    }
}
