/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement, ReactNode } from "react";
// Import the JSON:API module.
import { extractURL } from "@andromeda/json-api";
// Import the store.
import { useAsset, useExternal } from "@andromeda/store";
// Import the resources.
import { Step } from "@andromeda/resources";

// Import the item container.
import ItemContainer from "./item";


/** Component used to render an External item on the screen. */
export default function ExternalItem(props: ExternalItemProps): ReactElement {
    // Load the external from the store.
    const external = useExternal(props.item.id);
    const icon = useAsset(external.data?.relationships.asset.data?.id, false);

    // Render the step.
    return <ItemContainer
        item={props.item}
        step={props.step}
        title={external.data?.attributes.name ?? undefined}
        icon={icon.data?.links?.get ? extractURL(icon.data.links.get).toString() : undefined}
        loading={!external.isSuccess}
        kind="Externe"
        children={props.children}
    />;
}

/** Props passed down to the {@link ExternalItem} component. */
export interface ExternalItemProps {
    /** Identifier of the external to render. */
    item: Step.Item;
    /** Identifier of the current step. */
    step: string;

    /** Children that should be rendered below the title of the item. */
    children?: ReactNode;
}
