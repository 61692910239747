/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


import { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import css from "./button.module.scss";

export default function ItemButton(props: ItemButtonProps): ReactElement {
    return <button
        className={classNames(css["button"], css[`button--${props.variant}`], props.className)}
        onClick={props.action}
        disabled={typeof props.action === "undefined"}
    >
        <FontAwesomeIcon className={css["icon"]} icon={props.icon} />
    </button>;
}

export interface ItemButtonProps {
    /** The icon used for this button. */
    icon: IconDefinition;
    /** The variant of the button. */
    variant: "primary" | "danger";
    /** Optional css class name added to the <button> element. */
    className?: string;

    /**
     * The action triggered by this button.
     * If left undefined, the button will not be rendered.
     */
    action?(): void;
}
