/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the json:api error interface.
import { Error as JsonApiErrorInterface } from "@andromeda/json-api";

// Import the list of supported methods.
import { XhrMethod } from "../interfaces";


/**
 * Error thrown when a response message has no status code.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class NoStatusCodeError extends Error {
    /** @inheritDoc */
    public constructor() {
        super("A response with no status code was received !");
    }
}

/**
 * Error thrown when a response failed to parse.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class ResponseParseFailedError extends Error {
    /**
     * @param {XhrMethod} method The method of the request.
     * @param {URL} url The requested url.
     * @param {unknown} inner The inner error.
     */
    public constructor(method: XhrMethod, url: URL, inner: unknown) {
        super(
          `The body from the "${method}" request to "${url}" failed to parse.\n` +
          `Inner error: ${String(inner)}`
        );
    }
}

/**
 * Error thrown when a response parsing was aborted.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class ResponseParseAbortedError extends Error {
    /**
     * @param {XhrMethod} method The method of the request.
     * @param {URL} url The requested url.
     */
    public constructor(method: XhrMethod, url: URL) {
        super(`The body from the "${method}" request to "${url}" was aborted.`);
    }
}

/** Error thrown when a JSON:API error message is received. */
export class JsonApiErrorMessage extends Error {
    /** List of errors returned by the server. */
    public readonly errors: JsonApiErrorInterface[];

    /** Class constructor. Builds a new {@link JsonApiErrorMessage} object. */
    public constructor(errors: JsonApiErrorInterface[]) {
        let message = "The server returned one or more json:api errors !\nDetails:\n";
        for (const error of errors) {
            message += `↳ ${error.title ?? ""}\n`;
            if (error.id)       message += `   ↳ id: ${error.id}\n`;
            if (error.detail)   message += `   ↳ detail: ${error.detail}\n`;
            if (error.status)   message += `   ↳ status: ${error.detail}\n`;
        }
        super(message);
        this.errors = errors;
    }
}
