/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext, useMemo } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the login module.
import { LoginContext } from "@andromeda/login";
// Import the store.
import { useRequirements, useSteps } from "@andromeda/store";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the resources.
import type { Step } from "@andromeda/resources";

// Import the custom hooks.
import { useProgress } from "../../../../hooks";
// Import the table component.
import Table, { TableSource } from "../../../table/table";
// Import the subcomponents.
import ItemCell from "../../../table/helpers/item-cell";

// Import the icons.
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Checkmark } from "@andromeda/assets/svg/checkmark.svg";
// Import the css.
import css from "./index.module.scss";


/** Component used to render the user's own progression on the screen. */
export default function OwnProgression(props: OwnProgressionProps): ReactElement {
    // Load the user's own progression.
    const user = useContext(LoginContext).self.id;
    const progress = useProgress(props.course, user);
    const steps = useSteps(props.course);
    const requirement = useRequirements(props.course, user);

    // Filter out all unnecessary steps.
    const requiredSteps = steps.data?.filter(function shouldIncludeStep(step: Step): boolean | undefined {
        return requirement.data?.some(requirement => requirement.relationships.step.data.id === step.id);
    });

    // Render all the cells of the progression table.
    const cells = useMemo(function renderProgressCells(): TableSource | null {
        // Wait for everything to load properly.
        if (!progress.isSuccess || !requirement.isSuccess || !requiredSteps) {
            return null;
        }
        if (requiredSteps.length <= 0) {
            return null;
        }

        // Find out the longest step.
        const rowCount = Math.max(...requiredSteps.map(step => step.relationships.items.data.length)) + 1;
        const columnCount = requiredSteps.length + 1;
        // Prepare the cells.
        const cells = new Array(rowCount).fill([]).map(() => new Array(columnCount).fill(null));

        // Render the header row and column.
        for (let column = 1; column < columnCount; column++) {
            // Get the progress of the step.
            const step = progress.data.step(requiredSteps[column - 1].id);

            // Compute the class names.
            let className = css["step-cell"];
            if (step?.complete) {
                className += ` ${css["step-cell--complete"]}`;
            }
            if (step?.locked) {
                className += ` ${css["step-cell--locked"]}`;
            }

            // Render the cell.
            cells[0][column] = <div className={className}>
                {step?.complete ? <Checkmark className={`${css["checkmark"]} ${css["checkmark--white"]}`} /> : null}
                {step?.locked ? <FontAwesomeIcon className={css["lock"]} icon={faLock} /> : null}
                {requiredSteps[column - 1].attributes.name}
            </div>;
        }
        for (let row = 1; row < rowCount; row++) {
            cells[row][0] = <div className={css["row-header"]}>Élément #{row}</div>;
        }

        // Render all the cells.
        for (let column = 1; column < columnCount; column++) {
            for (let row = 1; row < rowCount; row++) {
                // Find the item's progress.
                const step = progress.data.step(requiredSteps[column - 1].id);
                const item = step?.item(requiredSteps[column - 1].relationships.items.data[row - 1]);

                cells[row][column] = <ItemCell key={`${row}.${column}`} item={item} step={step} />;
            }
        }

        return cells;
    }, [progress.data, progress.isSuccess, requiredSteps, requirement.isSuccess]);

    // Render the table.
    if (typeof requiredSteps !== "undefined" && requirement.isSuccess && requiredSteps.length <= 0) {
        return <span className={css["not-applicable-message"]}>Vous n'êtes pas assigné à ce ZaqTraining</span>;
    }
    if (!cells) {
        return <Loader className={css["step-cell"]} text="Chargement de votre progression ..." transparent />;
    }
    return <Table source={cells} />;
}

/** Props passed down to the {@link OwnProgression} component. */
export interface OwnProgressionProps {
    /** The identifier of the course that is being rendered. */
    course: string;
}
