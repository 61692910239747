/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the response interfaces.
import { XhrResponse, XhrPartialResponse } from "./response";
// Import the fetch options interface.
import { XhrFetchOptions } from "./options";
// Import the common interfaces..
import { XhrHeaders, XhrEncodedBodyType } from "./common";


/**
 * Interface representing a single Xhr request.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export interface PreparedXhrRequest {
    /** Full url of the generated request. */
    url: Readonly<URL>;
    /** Resolved target name of the request, if applicable. */
    target: string | null;

    /** Full list of lowercase headers sent along with the request. */
    headers: XhrHeaders;
    /** Encoded body to send along with the request. */
    body: Readonly<XhrEncodedBodyType> | undefined;

    /** List of all the options used to generate this request. */
    options: XhrFetchOptions;
}

/**
 * Interface used to represent a Xhr request that was started.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export interface XhrRequest extends PreparedXhrRequest {
    /** Promise that resolves with the response sent by the server. */
    response: Promise<XhrResponse>;
    /** Method that can be used to abort the request. */
    abort(): void;

}

/**
 * Extension of the {@link XhrRequest} interface that returns a partial response object.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export interface XhrPartialRequest extends Omit<XhrRequest, "response"> {
    /** Promise that resolves with the response sent by the server. */
    response: Promise<XhrPartialResponse>;
}
