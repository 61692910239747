/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useMemo } from "react";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";

// Import the storage tools.
import { useFavouriteList } from "../../../../storage";
// Import the local hooks.
import type { SearchFilter } from "../../../../hooks";
// Import the item wrapper component.
import { ItemWrapper } from "../../../item";

// Import the css.
import css from "./list.module.scss";
import Empty from "./empty";


/** Component used to render the list of all the favourites of the current user. */
export default function FavouriteList(props: FavouriteListProps): ReactElement {
    // Get the list of all the favourites.
    const favourites = useFavouriteList();

    // Render all the favourites.
    const children = useMemo(function renderFavourites(): ReactNode {
        const items = favourites.filter(function shouldItemBeRenderer(item: ResourceIdentifier): boolean {
            return props.filter === "all" || props.filter === item.type;
        });

        // If there are no items, render the empty object.
        if (items.length < 1) {
            return <li className={css["item"]}><Empty /></li>;
        }

        // Render the item list.
        return items.map(function renderFavouriteItem(item: ResourceIdentifier): ReactElement {
            return <li key={item.id} className={css["item"]}>
                <ItemWrapper resource={item} />
            </li>;
        });
    }, [favourites, props.filter]);

    // Render the list.
    return <ul className={css["list"]} children={children} />;
}

/** Props passed down to the {@link FavouriteList} component. */
export interface FavouriteListProps {
    /** Current filtering applied to the favourites list. */
    filter: SearchFilter;
}
