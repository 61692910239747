/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json object interface.
import { JsonObject, JsonValue } from "./common";


/**
 * Base interface for all the metadata of the JSON:API standard.
 * Enforces an object structure with string keys and JSON-serializable data.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export type Metadata<T extends JsonObject = Record<string, JsonValue | undefined>> = T;
