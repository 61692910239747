/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useMemo } from "react";

// Import the css.
import css from "./selector.module.scss";


/** Object used to describe an option of the {@link Selector} component. */
export interface Option<T extends string> {
    /** The value of the option. */
    value: T;
    /** The human-readable name of the option. */
    name: string;
}

/** Helper component used to render a top-level selector element. */
export default function Selector<T extends string>(props: SelectorProps<T>): ReactElement {
    // Render all the options.
    const options = useMemo(function renderChoices(): ReactElement[] {
        return props.options.map(function renderChoice(option: Option<T>, index: number): ReactElement {
            return <li
                key={index}
                className={css["item"]}
                aria-pressed={props.current === option.value}
                role="button"
                onClick={() => props.onChange(option.value)}
                children={option.name}
            />;
        });
    }, [props]);

    // Render the component.
    return <ul className={css["selector"]} children={options} />;
}

/**
 * Props passed down to the {@link Selector} component.
 *
 * @template {string} T
 */
export interface SelectorProps<T extends string> {
    /** The options rendered by the current element. */
    options: readonly Option<T>[];
    /** The value of the currently active option. */
    current: T;

    /**
     * Callback invoked when an option was selected.
     *
     * @param {T} value The value of the selected option.
     */
    onChange(value: T): void;
}
