/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useEffect } from "react";
// Import React-Redux.
import { useDispatch, useSelector } from "react-redux";
// Import Redux-Thunk.
import { ThunkDispatch } from "redux-thunk";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the custom interfaces.
import type { FullLegacyZaq } from "./interfaces";
// Import the action generator.
import { downloadZaq } from "./store";
// Import the store.
import { downloadMultipleZaq, RequestZaqAction, LegacyZaqReducerKey, LegacyZaqReducerStore } from "./store";
// Import the legacy interface.
import type { LegacyZaq } from "./interfaces";


// Re-export the store.
export * from "./store";
// Re-export the interfaces.
export * from "./interfaces";

/**
 * Helper hook used to download a legacy zaq from the API.
 *
 * @param {string | undefined} id The identifier of the zaq that should be loaded.
 * @param {false} [withDescriptor=false] If true, loads the descriptor of the Zaq too.
 * @return {RequestStatus<FullLegacyZaq>} The status of the request.
 */
export function useLegacyZaq(id: string | undefined, withDescriptor?: false): RequestStatus<LegacyZaq>;

/**
 * Helper hook used to download a legacy zaq from the API.
 *
 * @param {string | undefined} id The identifier of the zaq that should be loaded.
 * @param {true} withDescriptor If true, loads the descriptor of the Zaq too.
 * @return {RequestStatus<FullLegacyZaq>} The status of the request.
 */
export function useLegacyZaq(id: string | undefined, withDescriptor: true): RequestStatus<FullLegacyZaq>;

/** Implementation. */
export function useLegacyZaq(id: string | undefined, withDescriptor = false): RequestStatus<FullLegacyZaq | LegacyZaq> {
    // Get the status of the request.
    const status = useSelector(
        function findRequestStatus(state: LegacyZaqReducerStore): RequestStatus<FullLegacyZaq | LegacyZaq> | undefined {
            // Check if an id was provided.
            if (typeof id === "undefined") {
                return undefined;
            }

            // Return the state of the request.
            return state[LegacyZaqReducerKey].resources[id];
        }
    );

    // Start the download if the request is not initialised.
    const dispatch = useDispatch<ThunkDispatch<LegacyZaqReducerStore, never, RequestZaqAction>>();
    useEffect(function startZaqDownload(): void {
        if (typeof id !== "undefined") {
            dispatch(downloadZaq(id, withDescriptor)).catch(console.error);
        }
    }, [dispatch, id, withDescriptor]);

    // If the request is not started, return an "uninitialised" status.
    if (typeof status === "undefined") {
        return RequestStatus.uninitialised();
    }
    return status;
}

/**
 * Helper hook used to download multiple legacy Zaq from the store.
 *
 * @param {string | undefined} organisation The identifier of the organisation that owns the requested Zaq.
 * @return {RequestStatus<FullLegacyZaq[]>} The status of the request.
 */
export function useLegacyZaqFromOrganisation(organisation: string | undefined): RequestStatus<FullLegacyZaq[]> {
    // Get the status of the request.
    const status = useSelector(
        function findRequestStatus(state: LegacyZaqReducerStore): RequestStatus<FullLegacyZaq[]> | undefined {
            // Check if an id was provided.
            if (typeof organisation === "undefined") {
                return undefined;
            }

            // Return the state of the request.
            const map = state[LegacyZaqReducerKey].organisations[organisation];
            if (!map?.isSuccess) {
                return undefined;
            }
            const statuses = Object.values(map.data).map(
                id => state[LegacyZaqReducerKey].resources[id] ?? RequestStatus.uninitialised()
            );
            if (!statuses.every(status => status.isSuccess)) {
                return RequestStatus.loading();
            }
            return RequestStatus.success(statuses.map(status => status.data as FullLegacyZaq));
        }
    );

    // Start the download if the request is not initialised.
    const dispatch = useDispatch<ThunkDispatch<LegacyZaqReducerStore, never, RequestZaqAction>>();
    useEffect(function startZaqDownload(): void {
        if (typeof organisation !== "undefined") {
            dispatch(downloadMultipleZaq(organisation)).catch(console.error);
        }
    }, [dispatch, organisation]);

    // If the request is not started, return an "uninitialised" status.
    if (typeof status === "undefined") {
        return RequestStatus.uninitialised();
    }
    return status;
}
