/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, ReactNode, useContext, useEffect, useMemo, useRef } from "react";
// Import ReactRouter.
import { Link } from "react-router-dom";
// Import the JSON:API filter.
import { Filter } from "@andromeda/json-api";
// Import the resources.
import { Course, DueDate, Requirement } from "@andromeda/resources";
// Import the stores.
import {
    useResourceDispatch,
    CourseStore,
    RequirementStore,
    StepStore,
    UserStore,
    DueDateStore
} from "@andromeda/store";
// Import the custom components.
import { Table, TablePage, Loader, useNotify } from "@andromeda/components";
// Import the login context.
import { LoginContext } from "@andromeda/login";

// Import the level icon.
import LevelIcon from "../components/level-icon";
// Import the due-date modal.
import withDueDateModal, { DueDateModalContext } from "./due-date-modal";

// Import the icons.
import person from "@andromeda/assets/images/person-blue.svg";
import { ReactComponent as Check } from "@andromeda/assets/svg/checkmark.svg";
import { ReactComponent as Warning } from "@andromeda/assets/svg/warning.svg";
import { ReactComponent as LoaderIcon } from "@andromeda/assets/images/loading-blue.svg";
// Import the css.
import css from "./user-info-monitoring.module.scss";


/** {@borrows UnwrappedUserInfoMonitoring} */
export const UserInfoMonitoring = RequirementStore.withReducer(
    CourseStore.withReducer(
        StepStore.withReducer(
            DueDateStore.withReducer(
                withDueDateModal<typeof UnwrappedUserInfoMonitoring, UserInfoMonitoringProps>(
                    UnwrappedUserInfoMonitoring
                )
            )
        )
    )
);

/** Component used to render the info table of a given user. */
function UnwrappedUserInfoMonitoring(props: UserInfoMonitoringProps): ReactElement {
    const dispatch = useResourceDispatch();
    const notify = useNotify();
    const organisation = useContext(LoginContext).organisations.current.id;

    const username = UserStore.useSelector(
        state => state.resources.find(resource => resource.id === props.user)?.attributes.givenName
    );

    // Download the user's requirements, steps and course from the API.
    const isLoaded = useRef(false);
    useEffect(() => {
        isLoaded.current = false;
    }, [props.user]);
    useEffect(() => {
        // Prevent a reloading of the user.
        if (isLoaded.current) {
            return;
        }
        isLoaded.current = true;
        if (!props.user) {
            return;
        }

        // Load all the courses from the current organisation.
        const filter: Filter = {
            [Course.Type]: {
                $or: [
                    {
                        ownerOrganisation: { $eq: organisation }
                    }, {
                        editorOrganisations: { $eq: organisation }
                    }, {
                        readerOrganisations: { $eq: organisation }
                    }
                ]
            }
        };
        dispatch(CourseStore.generator.readMany({ filter }))
            .then(courses => {
                for (const course of courses) {
                    // Load the steps from the course.
                    dispatch(
                        RequirementStore.generator.readMany({
                            filter: {
                                [Course.Type]: { id: { $eq: course.id } },
                                [Requirement.Type]: { user: { $eq: props.user } }
                            },
                            immediate: true
                        })
                    );
                    dispatch(
                        DueDateStore.generator.readMany({
                            filter: {
                                [Course.Type]: { id: { $eq: course.id } },
                                [DueDate.Type]: { user: { $eq: props.user } }
                            },
                            immediate: true
                        })
                    );
                }
            })
            .catch(notify.fatal);

    }, [dispatch, notify.fatal, organisation, props.user]);

    // Load all the table's pages.
    const pages = useTablePages(props.user);

    // Check if the course are loading.
    const isLoadingCourse = CourseStore.useSelector(state => state.reading);
    if (isLoadingCourse) {
        return <Loader
            text="Chargement des ZaqTraining ..."
            asBlock className={css["loader"]}
        />;
    }
    // Render the component.
    return <div>
        <div className={css["details"]}>
            <img className={css["details__icon"]} alt="person-icon" src={person} />
            <p className={css["details__legend"]}>nom:</p>
            <p className={css["details__text"]}>{username}</p>
        </div>
        <div className={css["table__container"]}>
            <Table
                className={css["table"]}
                theadClassName={css["table__header"]}
                tbodyRowClassName={css["table__row"]}
                tbodyHeadClassName={css["table__legend"]}
                tbodyDataClassName={css["table__cell"]}
                pages={pages}
            />
        </div>
    </div>;
}

/** Props passed down to the {@link UserInfoMonitoring} component. */
interface UserInfoMonitoringProps {
    /** The id of the rendered user. */
    user: string;
}

/** Custom hook used to load the table pages. */
function useTablePages(userId?: string): TablePage[] {
    // Load all the course statuses.
    const status = useCourseStatuses(userId);
    // Get the due-date modal.
    const { show } = useContext(DueDateModalContext);

    // Load the cell and header contents.
    const cells = useMemo(function renderTableCells(): ReactNode[][] {
        // Filter out all the ignored statuses.
        return status.map(function renderCourseStatus(status: CourseStatus): ReactElement[] {
            switch (status.status) {
            case "reported": {
                let dueDateClassName = css["table__due-date"];
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                let icon: ReactElement | null = null;
                if (status.date && status.validated) {
                    dueDateClassName += " " + css["table__due-date--done"];
                    icon = <Check className={css["table__due-date__icon"]} />;
                } else if (status.date && status.date.getTime() <= yesterday.getTime()) {
                    dueDateClassName += " " + css["table__due-date--past-due"];
                    icon = <Warning className={css["table__due-date__icon"]} />;
                }

                let levelClassName = css["table__level"];
                if (status.objective) {
                    levelClassName += " " + css["table__level--success"];
                }
                let objectiveClassName = css["table__icon__container"];
                if (status.objective) {
                    objectiveClassName += " " + css["table__icon__container--success"];
                }
                let validatedClassName = css["table__icon__container"];
                if (status.validated) {
                    validatedClassName += " " + css["table__icon__container--success"];
                }
                let validatedDateClassName = css["table__due-date"];
                if (status.validationDate && status.validated) {
                    validatedDateClassName += " " + css["table__due-date--done"];
                }

                return [
                    <button onClick={() => show(status.course.id)} className={dueDateClassName}>
                        {icon}
                        {status.date?.toLocaleDateString("fr") ?? "N/A"}
                    </button>,
                    <div className={levelClassName}>
                        <LevelIcon level={status.level} className={css["table__level__icon"]} />
                        <p
                            className={css["table__level__text"]}
                            children={status.level !== null ? Requirement.Level.toString(status.level) : ""}
                        />
                    </div>,
                    <div className={objectiveClassName}>
                        <Check className={css["table__icon"]} />
                    </div>,
                    <div className={validatedClassName}>
                        <Check className={css["table__icon"]} />
                    </div>,
                    <div className={validatedDateClassName}>
                        {status.validationDate?.toLocaleDateString("fr") ?? "N/A"}
                    </div>
                ];
            }
            case "loading":
            default:
                return [
                    <td key={status.course.id} colSpan={5}>
                        <span className={css["table__wide"]}>
                            <LoaderIcon className={css["table__wide__icon"]} />
                            <p className={css["table__wide__text"]}>Chargement ...</p>
                        </span>
                    </td>
                ];
            }
        });
    }, [show, status]);

    // Render the header column.
    const headerColumn = useMemo(function renderTableHeaderColumn(): ReactNode[] {
        // Filter out all the ignored courses.
        return status.map(function renderHeaderColumn(status: CourseStatus): ReactNode {
            let className = css["table__name"];
            if (status.status === "reported" && status.validated) {
                className += " " + css["table__name--validated"];
            }
            return <Link
                to={`/zaq-training/${status.course.id}`}
                className={className}
                children={status.course.attributes.name}
            />;
        });
    }, [status]);

    // Return the table page.
    return [{ name: "", cells: cells, headerRow: HEADER_ROW, headerColumn }];
}

// Constant header row values.
const HEADER_ROW = [
    "prochaine échéance",
    "niveau moyen obtenu",
    "objectif atteint ?",
    "validé ?",
    "dernière validation"
];

/**
 * Loads all the course statuses for the given user.
 *
 * @param {string} userId The id of the user to load the info of.
 * @returns {CourseStatus[]} The list of statuses of all the user's courses.
 */
function useCourseStatuses(userId?: string): CourseStatus[] {
    // Load all the requirements for the user.
    const requirements = RequirementStore.useSelector(function findUserRequirements(state) {
        return state.resources.filter(function requirementMatchesUser(requirement) {
            return requirement.relationships.user.data.id === userId;
        });
    });

    // Load all the courses from the steps.
    const courses = CourseStore.useSelector(function findCoursesFromSteps(state) {
        return state.resources;
    });
    const dueDates = DueDateStore.useSelector(
        state => state.resources.filter(date => date.relationships.user.data.id === userId)
    );
    const isLoadingSteps = RequirementStore.useSelector(state => state.reading);

    // Compute the statuses.
    return useMemo(function computeCourseStatuses(): CourseStatus[] {
        return courses.map(function getStatusFromCourse(course: Course): CourseStatus | null {
                // Find the due-date for this course.
                const dueDate = dueDates.find(date => date.relationships.course.data.id === course.id);

                // Load the requirements for the current course.
                const courseRequirements = requirements.filter(
                    function findCurrentCourseRequirements(requirement: Requirement): boolean {
                        return course.relationships.steps.data.some(
                            step => step.id === requirement.relationships.step.data.id
                        );
                    }
                );
                // If no requirement was found return a loading or missing status.
                if (courseRequirements.length <= 0) {
                    if (!isLoadingSteps) {
                        return null;
                    }
                    return { course, status: "loading" };
                }

                let validated = true, objective = true, level = 0, validationDate: Date | null = null;
                for (const requirement of courseRequirements) {
                    // Loop through the items of the requirement.
                    for (const detail of requirement.attributes.detail) {
                        // If no session was run, invalidate the object.
                        if (detail.date === null) {
                            validated = false;
                            objective = false;
                            continue;
                        }

                        // Update the validation date if needed.
                        const date = new Date(detail.date);
                        if (validationDate === null || validationDate.getTime() < date.getTime()) {
                            validationDate = date;
                        }

                        if (Requirement.isZaqDetail(detail) || Requirement.isExternalDetail(detail)) {
                            if (detail.level !== null) {
                                level += detail.level + 1;
                            }
                            objective =
                                objective &&
                                detail.level !== null &&
                                detail.level >= requirement.attributes.requiredLevel;

                            if (Requirement.isZaqDetail(detail)) {
                                validated = validated && detail.validated;
                            } else {
                                validated =
                                    validated &&
                                    detail.level !== null &&
                                    detail.level >= requirement.attributes.requiredLevel;
                            }
                        } else if (Requirement.isTutoDetail(detail) || Requirement.isWikiDetail(detail)) {
                            objective = objective && detail.viewed;
                            level += detail.viewed ? 4 : 0;
                        }
                    }
                }

                // Compute the average level of the course.
                let averageLevel: number | null = null;
                if (courseRequirements.length > 0) {
                    const sum = courseRequirements.reduce((acc, req) => acc + req.attributes.detail.length, 0);
                    averageLevel = Math.round(level / sum);
                    if (averageLevel < 1) {
                        averageLevel = null;
                    } else {
                        averageLevel -= 1;
                    }
                }

                // Return the status object.
                return {
                    course,
                    status: "reported",
                    level: averageLevel,
                    objective,
                    validated,
                    date: dueDate ? new Date(dueDate.attributes.date) : undefined,
                    recurrence: dueDate?.attributes.recurrence ?? null,
                    validationDate
                };
            })
            // Filter out any ignored courses.
            .filter(function removeIgnoredCourses(course: CourseStatus | null): course is CourseStatus {
                return course !== null;
            })
            // Sort the courses by name and loading status.
            .sort(function compareStatuses(a: CourseStatus, b: CourseStatus): number {
                if (a.status === b.status) {
                    return a.course.attributes.name.localeCompare(b.course.attributes.name);
                }
                if (a.status === "reported") {
                    return -1;
                }
                if (b.status === "reported") {
                    return 1;
                }
                if (a.status === "loading") {
                    return -1;
                }
                if (b.status === "loading") {
                    return 1;
                }
                return 0;
            });
    }, [courses, dueDates, isLoadingSteps, requirements]);
}

/** Base interface for all the course statuses. */
interface CourseStatusBase<T extends string> {
    /** The course being described. */
    course: Course;
    /** The status of the described course. */
    status: T;
}

/** Type used for the loading courses. */
type CourseLoadingStatus = CourseStatusBase<"loading">;

/** Interface used to report the progress of a single course. */
interface CourseReportedStatus extends CourseStatusBase<"reported"> {
    /** The average level reached in the course. */
    level: Requirement.Level | null;
    /** If true, the objective was reached for every item of every step of the course. */
    objective: boolean;
    /** If true, every Zaq of this course was validated. */
    validated: boolean;
    /** Date at which the course should be completed. */
    date?: Date;
    /** Is set to {@link true} if the course should have a recurrence. */
    recurrence: `${number}${"d" | "w" | "m" | "y"}` | null;
    /** The date of the session/validation that */
    validationDate: Date | null;
}

type CourseStatus = CourseLoadingStatus | CourseReportedStatus;
