/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API module.
import type { Filter } from "@andromeda/json-api";
// Import the user resource.
import { User } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the store.
import { UserStore, ResourceStore } from "../../stores";
// Import the resource hooks.
import useResources from "./util/use-resources";
import useResource from "./util/use-resource";


/**
 * Hook used to download a user from the store.
 *
 * @param {string | undefined} id The identifier of the requested user.
 * @param {boolean} [cache=true] Try to read from the cache if possible.
 * @return {RequestStatus<User>} The status of the request.
 */
export function useUser(id: string | undefined, cache = true): RequestStatus<User> {
    return useResource(UserStore, User.Type, id, cache);
}

/**
 * Downloads all the users of the specified organisation.
 *
 * @param {string} organisation The organisation to load the users for.
 * @return {RequestStatus<User[]>} The status of the request.
 */
export function useUsers(organisation: string): RequestStatus<User[]> {
    // Prepare the filter used for the request.
    const filter: Filter = { organisation: { id: { $eq: organisation }}};

    // Prepare the selector used to load the users.
    const selector = function selector(state: ResourceStore<User>): User[] {
        return state.resources.filter(function userBelongsToOrg(user: User): boolean {
            return user.relationships.organisations.data.some(({ id }) => id === organisation);
        })
    }

    // Return the resources.
    return useResources(UserStore, User.Type, { filter }, selector);
}
