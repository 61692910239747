/*
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the button.
import { Button } from "../../../../index";
// Import the context.
import { PDFRenderModeContext, PDFActivePageContext } from "../../context";

// Import the icons.
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./page-selector.module.scss";


/** Component used to render the page selector element of the controller bar.  */
export default function PageSelector(): ReactElement {
    // Load the controller context.
    const { activePageIndex, pageCount, setActivePage } = useContext(PDFActivePageContext);
    const { mode } = useContext(PDFRenderModeContext);

    // Render the component.
    if (mode === "continuous") {
        return <CurrentPageIndicator />;
    }
    return <div className={css["section"]}>
        <Button
            className={css["button"]}
            disabled={activePageIndex <= 0}
            onClick={() => setActivePage(activePageIndex - 1)}
        >
            <FontAwesomeIcon icon={faChevronLeft} className={css["button__icon"]} />
        </Button>

        <CurrentPageIndicator />

        <Button
            className={css["button"]}
            disabled={activePageIndex >= pageCount - 1}
            onClick={() => setActivePage(activePageIndex + 1)}
        >
            <FontAwesomeIcon icon={faChevronRight} className={css["button__icon"]} />
        </Button>
    </div>;
}

/** Helper component used to render the current page indicator. */
function CurrentPageIndicator(): ReactElement {
    // Load the controller context.
    const { activePageIndex, pageCount } = useContext(PDFActivePageContext);

    // Render the component.
    return <div className={css["text"]}>
        <span className={css["text__optional"]}>Page&nbsp;</span>
        <span>{activePageIndex + 1}/{pageCount}</span>
    </div>;
}
