/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


// Import React.
import { ReactElement } from "react";

// Import the wrapped icons.
import ZaqTutoIcon from "./tuto";
import ZaqWikiIcon from "./wiki";


/** Component used to render the icon of a ZaqTuto. */
export default function ZaqTrainingIcon(props: ZaqTrainingIconProps): ReactElement | null {
    if (typeof props.icon === "undefined") {
        return null;
    }
    if (props.icon.match(/^[0-9a-f]{24}$/i)) {
        return <ZaqTutoIcon className={props.className} id={props.id} icon={props.icon} />;
    }
    return <ZaqWikiIcon className={props.className} icon={props.icon} />;
}

/** Props provided to the {@link ZaqTrainingIcon} component. */
export interface ZaqTrainingIconProps {
    /** Identifier of the rendered training. */
    id: string;
    /** Identifier of the loaded icon. */
    icon?: string;
    /** Class name added to the <img> element. */
    className?: string;
}
