/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode } from "react";
// Import the login module.
import { useAdminCheck } from "@andromeda/login";
// Import the custom components.
import { Switch } from "@andromeda/components";
// Import the resource hooks.
import { useUser } from "@andromeda/store";

// Import the editor and validator helper hooks.
import { useIsEditor, useIsValidator } from "../../../../helpers";
// Import the permissions updater hook.
import usePermissionUpdateBuilder from "./use-permission-update-builder";

// Import the css.
import css from "./user-permissions.module.scss"


/** Component used to render the permissions of a single user. */
export default function UserPermissions(props: UserPermissionsProps): ReactElement | null {
    const permissionsUpdater = usePermissionUpdateBuilder(props.course, props.user);
    const user = useUser(props.user);

    // Check the permissions level of the user.
    const admin = useAdminCheck()(props.user);
    const editor = useIsEditor(props.course)(props.user);
    const validator = useIsValidator(props.course)(props.user);

    // Build the explanations.
    let editorExplanation: ReactNode = undefined;
    let validatorExplanation: ReactNode = undefined;
    if (admin) {
        editorExplanation = "Vous avez déterminé cet utilisateur comme administrateur"
            + " dans la gestion des comptes, ce qui lui donne tous les droits sur vos ZaqTraining.";
    } else if (editor) {
        validatorExplanation = "Un utilisateur qui est éditeur peut également valider un ZaqTraining.";
    }

    // Render the component.
    if (!user.isSuccess) {
        return null;
    }
    return <>
        <p children={user.data.attributes.givenName} className={css["text"]} />
        <Switch
            className={css["editor"]}
            onInternalStateChange={permissionsUpdater("editor")}
            initialState={admin || editor}
            explanation={editorExplanation}
            disabled={admin}
            name="éditeur"
            small
        />
        <Switch
            className={css["validator"]}
            onInternalStateChange={permissionsUpdater("validator")}
            initialState={admin || editor || validator}
            explanation={validatorExplanation}
            disabled={admin || editor}
            name="formateur"
            small
        />
        <div className={css["separator"]} />
    </>;
}

/** Props passed down to the {@link UserPermissions} component. */
export interface UserPermissionsProps {
    /** The identifier of the user to render. */
    user: string;
    /** The identifier of the course to render. */
    course: string;
}
