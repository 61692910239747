/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the common tools.
import type { RequestStatus } from "@andromeda/tools";

// Import the action types.
import type { RequestActions } from "./action";


/** State of the {@link requestReducer}. */
export interface RequestReducerState {
    /** Map of all the requests found in the store. */
    requests: Record<string, RequestStatus | undefined>;
}

// Default state of the reducer.
const DEFAULT_STATE: RequestReducerState = { requests: {} };

/**
 * Reducer used to update the state of the request store.
 *
 * @param {RequestReducerState} [currentState=DEFAULT_STATE] The current state of the store.
 * @param {RequestActions} action The action that was dispatched to the store.
 * @return {RequestReducerState} The new state of the store.
 */
export function requestReducer(
    currentState: RequestReducerState = DEFAULT_STATE,
    action: RequestActions
): RequestReducerState {
    switch (action.type) {
    case "requests/clear":
        // Clear the request from the store.
        delete currentState.requests[action.id];
        return { requests: { ...currentState.requests } };
    case "requests/update":
        // Update the request inf the store.
        return {
            requests: {
                ...currentState.requests,
                [action.id]: action.request
            }
        };
    default:
        return currentState;
    }
}
