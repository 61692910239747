/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the icons.
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
// Import the css.
import css from "./status.module.scss";


/** Component used to render the icons of the training status. */
export default function TrainingStatusIcon(props: TrainingStatusIconProps): ReactElement {
    return <>
        <FontAwesomeIcon
            className={classNames(css["icon"], css["icon--completed"], props.className)}
            icon={faCheck}
        />
        <FontAwesomeIcon
            className={classNames(css["icon"], css["icon--past-due"], props.className)}
            icon={faExclamationCircle}
        />
        <FontAwesomeIcon
            className={classNames(css["icon"], css["icon--validated"], props.className)}
            icon={faCheck}
        />
    </>;
}

/** Props passed down to the {@link TrainingStatusIcon} component. */
export interface TrainingStatusIconProps {
    /** Optional class name added to the icon component. */
    className?: string;
}
