/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the validate function.
import { ValidateFunction } from "ajv";
// Import the json:api interfaces.
import { Resource, Relationship, AnyLinks, Link } from "@andromeda/json-api";
// Import the schema compiler.
import { compileSync } from "@andromeda/validation";

// Import the asset resource.
import { Asset } from "../..";
// Import the wiki resource.
import { ZaqWiki } from "../zaq-wiki";
// Import the schema.
import { ZaqWikiFileSchema } from "./schema";

export namespace ZaqWikiFile {
    export const Type = "zaq-wiki-file" as const;
    export type Type = typeof Type;

    export interface Attributes {
        /** Localised name of the wiki file. */
        name: string;
    }

    export interface Relationships {
        /** Wiki that owns this file. */
        wiki: Relationship<ZaqWiki>;
    }

    export const validate: ValidateFunction<ZaqWikiFile> = compileSync(ZaqWikiFileSchema.ResourceSchema);

    export interface Update extends Resource<ZaqWikiFile.Type> {
        attributes?: Update.Attributes;
        relationships?: Update.Relationships;
    }
    export namespace Update {
        export type Attributes =
            | Partial<ZaqWikiTextFile.Attributes>
            | Partial<ZaqWikiSectionFile.Attributes>;
        export type Relationships = Partial<Pick<ZaqWikiAssetFile.Relationships, "asset">>;
        export const validate: ValidateFunction<ZaqWikiFile.Update> = compileSync(ZaqWikiFileSchema.UpdateSchema);
    }

    export type Create = Omit<ZaqWikiFile, "id">;
    export namespace Create {
        export const validate: ValidateFunction<ZaqWikiFile.Create> = compileSync(ZaqWikiFileSchema.CreateSchema);

        /** Checks if the given file in a {@link ZaqWikiAssetFile}. */
        export function isAssetFile(file: ZaqWikiFile.Create): file is ZaqWikiAssetFile.Create {
            return "asset" in file.relationships;
        }
        /** Checks if the given file in a {@link ZaqWikiTextFile}. */
        export function isTextFile(file: ZaqWikiFile.Create): file is ZaqWikiTextFile.Create {
            return "contents" in file.attributes;
        }
        /** Checks if the given file in a {@link ZaqWikiLinkFile}. */
        export function isLinkFile(file: ZaqWikiFile.Create): file is ZaqWikiLinkFile.Create {
            return typeof file.links === "object" && "url" in file.links;
        }
        /** Checks if the given file in a {@link ZaqWikiTutoFile}. */
        export function isTutoFile(file: ZaqWikiFile.Create): file is ZaqWikiTutoFile.Create {
            return "zaqTutoId" in file.attributes;
        }
        /** Checks if the given file in a {@link ZaqWikiSectionFile}. */
        export function isSectionFile(file: ZaqWikiFile.Create): file is ZaqWikiSectionFile.Create {
            return "title" in file.attributes;
        }
    }

    /** Checks if the given file in a {@link ZaqWikiAssetFile}. */
    export function isAssetFile(file: ZaqWikiFile): file is ZaqWikiAssetFile {
        return "asset" in file.relationships;
    }
    /** Checks if the given file in a {@link ZaqWikiTextFile}. */
    export function isTextFile(file: ZaqWikiFile): file is ZaqWikiTextFile {
        return "contents" in file.attributes;
    }
    /** Checks if the given file in a {@link ZaqWikiLinkFile}. */
    export function isLinkFile(file: ZaqWikiFile): file is ZaqWikiLinkFile {
        return typeof file.links === "object" && "url" in file.links;
    }
    /** Checks if the given file in a {@link ZaqWikiTutoFile}. */
    export function isTutoFile(file: ZaqWikiFile): file is ZaqWikiTutoFile {
        return "zaqTutoId" in file.attributes;
    }
    /** Checks if the given file in a {@link ZaqWikiSectionFile}. */
    export function isSectionFile(file: ZaqWikiFile): file is ZaqWikiSectionFile {
        return "title" in file.attributes;
    }
}

/** Resource used for a ZaqWiki file linked to an asset. */
export interface ZaqWikiAssetFile extends Resource<ZaqWikiFile.Type> {
    /** @inheritDoc */
    attributes: ZaqWikiFile.Attributes;
    /** @inheritDoc */
    relationships: ZaqWikiAssetFile.Relationships;
}

export namespace ZaqWikiAssetFile {
    export interface Relationships extends ZaqWikiFile.Relationships {
        /** Relationship to the asset of this file. */
        asset: Relationship<Asset>;
    }
    export type Create = Omit<ZaqWikiAssetFile, "id">;
}

/** Resource used for a ZaqWiki file with text contents. */
export interface ZaqWikiTextFile extends Resource<ZaqWikiFile.Type> {
    /** @inheritDoc */
    attributes: ZaqWikiTextFile.Attributes;
    /** @inheritDoc */
    relationships: ZaqWikiFile.Relationships;
}

export namespace ZaqWikiTextFile {
    export interface Attributes extends ZaqWikiFile.Attributes {
        /** Localised text contents of this file. */
        contents: string;
    }
    export type Create = Omit<ZaqWikiTextFile, "id">;
}

/** Resource used for a ZaqWiki file with a link. */
export interface ZaqWikiLinkFile extends Resource<ZaqWikiFile.Type> {
    /** @inheritDoc */
    attributes: ZaqWikiFile.Attributes;
    /** @inheritDoc */
    relationships: ZaqWikiFile.Relationships;
    /** @inheritDoc */
    links: ZaqWikiLinkFile.Links;
}

export namespace ZaqWikiLinkFile {
    export interface Links extends AnyLinks {
        /** URL being described by this file. */
        url: Link;
    }
    export type Create = Omit<ZaqWikiLinkFile, "id">;
}

/** Resource used for a ZaqWiki file referencing a ZaqTuto. */
export interface ZaqWikiTutoFile extends Resource<ZaqWikiFile.Type> {
    /** @inheritDoc */
    attributes: ZaqWikiTutoFile.Attributes;
    /** @inheritDoc */
    relationships: ZaqWikiFile.Relationships;
}

export namespace ZaqWikiTutoFile {
    export interface Attributes extends ZaqWikiFile.Attributes {
        /** Identifier of the tuto. */
        zaqTutoId: string;
    }
    export type Create = Omit<ZaqWikiTutoFile, "id">;
}

/** Resource used for a ZaqWiki file used for section titles. */
export interface ZaqWikiSectionFile extends Resource<ZaqWikiFile.Type> {
    /** @inheritDoc */
    attributes: ZaqWikiSectionFile.Attributes;
    /** @inheritDoc */
    relationships: ZaqWikiFile.Relationships;
}

export namespace ZaqWikiSectionFile {
    export interface Attributes {
        /** Title of this subsection. */
        title: string;
    }
    export type Create = Omit<ZaqWikiSectionFile, "id">;
}

/** Union of all the possible zaq wiki file types. */
export type ZaqWikiFile =
    | ZaqWikiAssetFile
    | ZaqWikiTextFile
    | ZaqWikiLinkFile
    | ZaqWikiTutoFile
    | ZaqWikiSectionFile;
