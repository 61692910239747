/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ComponentType, ReactElement, useMemo } from "react";
// Import React-Router.
import { useNavigate } from "react-router-dom";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";

// Import the hooks.
import type { SearchItemType } from "../../../hooks";

// Import the subcomponents.
import ItemType from "./type";
import ItemFavourite from "./favourite";
import ItemText from "./text";
import ItemButton from "./button";

// Import the icons.
import { faPen } from "@fortawesome/free-solid-svg-icons/faPen";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
// Import the css.
import css from "./index.module.scss";
import ItemIcon from "./icon";


/** Component used to render an item on the screen. */
export default function ItemRenderer<P extends object>(props: ItemRendererProps<P>): ReactElement {
    const edit = useMemo(function getEditionAction(): VoidFunction | undefined {
        const edit = props.edit;
        if (!edit) {
            return undefined;
        }

        return function redirectToEditor(): void {
            window.location.href = edit;
        };
    }, [props.edit]);

    return <article className={css["item"]}>
        <ItemType className={css["type"]} type={props.resource.type} />
        <ItemFavourite className={css["favourite"]} resource={props.resource} />
        <ItemIcon className={css["icon"]} component={props.icon} props={props.iconProps} />
        <ItemText
            title={props.title}
            description={props.description}
            tags={props.tags}
            to={props.play}
            className={css["text"]}
        />
        <ItemButton
            className={classNames(css["button"], css["button--edit"])}
            action={edit}
            icon={faPen}
            variant="primary"
        />
        <ItemButton
            className={classNames(css["button"], css["button--delete"])}
            action={props.delete}
            icon={faTrash}
            variant="danger"
        />
    </article>;
}

/** Props passed down to the {@link ItemRenderer} component. */
export interface ItemRendererProps<P extends object> {
    /** The resource being rendered by this item. */
    resource: ResourceIdentifier<SearchItemType>;
    /** The icon of the item. */
    icon: ComponentType<P>;
    /** The props of the item's icon component. */
    iconProps: P;
    /** The title of the item. */
    title: string;
    /** The description of the item. */
    description: string;
    /** The tags of the item. */
    tags: string[];
    /** The link to redirect to, in order to play the item. */
    play: string;
    /** The link to redirect to, in order to edit the item. */
    edit?: string | false;

    /** A callback used to delete the item. */
    delete?(): void;
}
