/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the valiate function interface.
import { ValidateFunction } from "ajv";
// Import the json schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the json:api resource interface.
import { Relationship, Resource } from "@andromeda/json-api";
// Import the asset interface.
import { Asset } from "@andromeda/resources";

// Import the list of conversions.
import { Conversion } from "./conversion";
// Import the schema.
import * as Schema from "./schema.json";


/** Resource used to request a conversion from the server. */
export interface AssetConversionRequest extends Resource<AssetConversionRequest.Type> {
    /** @inheritDoc */
    attributes: Conversion;
    /** @inheritDoc */
    relationships: AssetConversionRequest.Relationships;
}

/** Augment the {@link AssetConversionRequest} interface. */
export namespace AssetConversionRequest {
    /** Type of {@link AssetConversionRequest} resources. */
    export const Type = "asset-conversion_conversion-request" as const;
    export type Type = typeof Type;

    export interface Relationships {
        /** Information regarding the asset to convert. */
        asset: Relationship<Asset>;
    }

    /** ValidateFunction used to validate {@link AssetConversionRequest} objects. */
    export const validate: ValidateFunction<AssetConversionRequest> = compileSync(Schema);
}
// Re-export the conversions.
export * from "./conversion";
// Re-export the dimensions.
export * from "./dimensions";
