/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode } from "react";
// Import React Router.
import { useSearchParams } from "react-router-dom";
// Import the custom components.
import { createNamedSwitchContext, SwitchContextProvider } from "@andromeda/components";


/** Context used for the validator switch. */
export const ValidatorSwitchContext = createNamedSwitchContext("course-validator");

/** Component used to provide the {@link ValidatorSwitchContext} in the tree. */
export default function ValidateSwitchProvider(props: ValidateSwitchProviderProps): ReactElement {
    // Get the edit parameter.
    const defaultState = useSearchParams()[0].get("validate") !== null;

    // Return the provider.
    return <SwitchContextProvider
        context={ValidatorSwitchContext}
        defaultState={defaultState}
        children={props.children}
    />;
}

/** Props passed down to the {@link EditSwitchProvider} component. */
export interface ValidateSwitchProviderProps {
    /** Children that will have access to the context. */
    children?: ReactNode;
}
