/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useMemo } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the icons.
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./due-date-picker.module.scss";


/** Component used to render the due-date picker. */
export default function DueDatePicker(props: DueDatePickerProps): ReactElement {
    // Convert the date to the input format.
    let inputValue: string | undefined = undefined;
    if (props.date) {
        inputValue = `${props.date.getFullYear()}-`
            + `${(props.date.getMonth() + 1).toString(10).padStart(2, "0")}-`
            + `${props.date.getDate().toString(10).padStart(2, "0")}`;
    }

    // Render the input.
    const input = useMemo(function renderInput(): ReactNode {
        if (!props.date) {
            return <button className={css["add"]} onClick={() => props.setDate(new Date())}>
                <FontAwesomeIcon className={css["add__icon"]} icon={faPlus} />
            </button>;
        }

        return <>
            <input
                className={css["input"]}
                type="date"
                value={inputValue}
                onChange={e => props.setDate(e.currentTarget.valueAsDate)}
                required
            />
            <button className={css["delete"]} onClick={() => props.setDate(null)}>
                <FontAwesomeIcon className={css["delete__icon"]} icon={faXmark} />
            </button>
        </>;
    }, [inputValue, props]);

    // Render the component.
    return <div className={css["container"]}>
        <span className={css["label"]}>Échéance: </span>
        {input}
    </div>;
}

/** Props passed down to the {@link DueDatePicker} component. */
export interface DueDatePickerProps {
    /** Current value of the date. */
    date: Date | null;

    /** Callback used to update the date. */
    setDate(date: Date | null): void;
}
