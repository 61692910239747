/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


/** Union of all the possible search item types. */
export type SearchItemType = "zaq-tuto" | "zaq-wiki" | "course";
/** Union of the possible search filters. */
export type SearchFilter = "all" | SearchItemType;

/**
 * Helper function used to extract the human-readable name of a given search item filter.
 *
 * @param {SearchFilter} filter The filter to convert.
 * @returns {string} The human-readable name of the filter.
 */
export function getNameFromSearchFilter(filter: SearchFilter): string {
    switch (filter) {
    case "all":
        return "Tout";
    case "zaq-tuto":
        return "Tuto";
    case "zaq-wiki":
        return "Wiki";
    case "course":
        return "Training";
    }
}
