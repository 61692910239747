/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the ajv interfaces.
import { ValidateFunction as AJVValidateFunction } from "ajv";

// Import the validation error interface.
import { ValidationError } from "./errors";

/** Alias for the validation functions used by {@link validate}. */
export type ValidateFunction<T = unknown> = AJVValidateFunction<T> | (() => AJVValidateFunction<T>);

/**
 * Function used to validate a given object.
 *
 * @template T
 * @param {unknown} object The object to validate.
 * @param {ValidateFunction<T>} validator The validator function to use.
 * @returns {object is T} True if the object is valid.
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 1
 */
export function validate<T>(object: unknown, validator: ValidateFunction<T>): object is T;

/**
 * Function used to validate a given object.
 * Throws an error if the object does NOT follow the schema.
 *
 * @template T
 * @param {unknown} object The object to validate.
 * @param {ValidateFunction<T>} validator The validator function to use.
 * @param {true} assert Enables forced assertion.
 * @throws {ValidationError} The object is not following the given schema.
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 1
 */
export function validate<T>(object: unknown, validator: ValidateFunction<T>, assert: true): asserts object is T;

/** Implementation ! */
export function validate<T>(object: unknown, validator: ValidateFunction<T>, assert?: true): boolean {
    if (validator.length === 0) validator = (validator as () => AJVValidateFunction<T>)();

    // Check if the object is of the given type.
    if (validator(object)) {
        return true;
    } else {
        // If the method is an assertion, throw an error.
        if (assert === true) {
            throw new ValidationError(validator as AJVValidateFunction<T>);
        } else {
            return false;
        }
    }
}

/**
 * Alias for {@link validate}
 *
 * @template T
 * @param {unknown} object The object to validate.
 * @param {ValidateFunction<T>} validator The validator function to use.
 * @returns {object is T} True if the object is valid.
 */
export function check<T>(object: unknown, validator: ValidateFunction<T>): object is T;

/**
 * Alias for {@link validate}
 *
 * @template T
 * @param {unknown} object The object to validate.
 * @param {ValidateFunction<T>} validator The validator function to use.
 * @param {true} assert Enables forced assertion.
 * @throws {ValidationError} The object is not following the given schema.
 */
export function check<T>(object: unknown, validator: ValidateFunction<T>, assert: true): asserts object is T;

/** Implementation ! */
export function check<T>(object: unknown, validator: ValidateFunction<T>, assert?: true): boolean {
    if (assert) {
        validate(object, validator, true);
        return true;
    } else {
        return validate(object, validator);
    }
}

// Re-export the error classes.
export * from "./errors";
