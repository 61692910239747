/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import React-Router.
import { Link } from "react-router-dom";
// Import the store.
import { useUser } from "@andromeda/store";
// Import the storybook components.d
import { Loader } from "@andromeda/storybook";

// Import the css.
import css from "./common.module.scss";


/** Helper component used to render the name of the current user. */
export default function Username(props: UsernameProps): ReactElement {
    // Load the user info.
    const user = useUser(props.id);

    // Render the component.
    if (!user.isSuccess) {
        return <Loader />;
    }
    return <Link
        to={`/monitoring/${props.id}`}
        className={css["row-header"]}
        children={user.data.attributes.givenName}
    />;
}

/** Props passed down to the {@link Username} component. */
export interface UsernameProps {
    /** The identifier of the user to render. */
    id: string;
}
