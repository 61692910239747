/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the ajv interfaces.
import AJV, { ValidateFunction, AnySchema, AnySchemaObject } from "ajv";
// Import the draft-06 meta schema.
import * as draft06 from "ajv/dist/refs/json-schema-draft-06.json";
// Import the ajv formats.
import addFormats from "ajv-formats";

// Import the logging tool.
import debug from "debug";
const log = debug("validation:cache");


/** Interface used to describe the compiler options. */
interface AjvOptions {
    /** Method passed to the {@link AJV} instance to load remote schemas. */
    loadSchema: (uri: string) => Promise<AnySchemaObject>;
}

/**
 * Checks if the requested function exists in the cache, and generate it if not.
 *
 * @template T
 * @param {AnySchema | string} source The schema to compile.
 * @param {AjvOptions} options The options of the generated instance.
 * @returns {Promise<ValidateFunction<T>>} A promise that resolves with the generated instance.
 */
export function checkForCache<T>(source: AnySchema | string, options: AjvOptions): Promise<ValidateFunction<T>> {
    // Check if the schema has an id.
    let id: string;
    if (typeof source === "string") {
        id = source;
    } else if (typeof source === "object" && "$id" in source && typeof (source as { $id: unknown }).$id === "string") {
        id = (source as { $id: string }).$id;
    } else {
        id = hash(source);
    }

    // Enqueue a new runner.
    return new Promise<ValidateFunction<T>>((resolve) => {
        async function checkForCacheRunner(): Promise<void> {
            // Helper used to run the compilation.
            async function compilationRunner(): Promise<ValidateFunction<T>> {
                // Check if the schema exists in the local cache.
                if (compiledCache.has(id)) {
                    const validator = compiledCache.get(id) as ValidateFunction<T> | Promise<ValidateFunction<T>>;
                    if ("then" in validator) {
                        log("Schema \"%s\" is being compiled, using the cached version.", id);
                    } else {
                        log("Schema \"%s\" was already cached, using the cached version.", id);
                    }
                    return Promise.resolve(validator);
                }

                // Check if the schema was already compiled.
                const compiled = getAjv(options).getSchema(id);
                if (typeof compiled !== "undefined") return compiled as ValidateFunction<T>;
                log("Loading a new schema \"%s\".", id);

                // If the schema is a string, load if from the server.
                let schema: AnySchema;
                if (typeof source === "string") {
                    schema = await options.loadSchema(id);
                } else {
                    schema = source;
                }

                // Assing the id to the schema.
                if (typeof schema !== "boolean") {
                    // Ensure that the id is writable.
                    // Sometimes, a schema is marked as const in TS and
                    // a TypeError arises if we try to override its "$id" property.
                    const idDescriptor = Object.getOwnPropertyDescriptor(schema, "$id");
                    if (idDescriptor?.writable) { schema.$id = id; }
                    return await getAjv(options).compileAsync(schema);
                } else {
                    return getAjv(options).compile(schema);
                }

            }

            // Load the schema.
            const compilation = compilationRunner();
            // Update the cache.
            compilation.then(schema => { compiledCache.set(id, schema); });
            compiledCache.set(id, compilation);
            await compilation.then(resolve);
        }
        checkForCacheRunner();
    });
}

/** Helper method used to load the ajv instance. */
export function getAjv(options: AjvOptions): AJV {
    if (ajv === null) {
        log("Generating a new AJV instance.");
        ajv = new AJV({ loadSchema: options.loadSchema, allowUnionTypes: true });
        addFormats(ajv);
        ajv.addMetaSchema(draft06);
    }
    return ajv;
}

/**
 * Simple hashing method based on the Java implementation.
 *
 * @param source The object to hash.
 * @returns {string} The resulting hash.
 */
export function hash(source: unknown): string {
    const hashSource = JSON.stringify(source);
    let hash = 0;
    if (hashSource.length == 0) return hash.toString(16);

    for (let i = 0; i < hashSource.length; i++) {
        const char = hashSource.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash;
    }

    return hash.toString(16);
}

// AJV instance.
let ajv: AJV | null = null;
// Local cache of compiled functions.
const compiledCache = new Map<string, ValidateFunction | Promise<ValidateFunction>>();
