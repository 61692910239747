/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API interfaces.
import {
    Resource,
    MultipleMessageHelper,
    MessageHelper,
    WritableMessageHelper,
} from "@andromeda/json-api";
// Import the Xhr package.
import { Xhr } from "@andromeda/xhr";
// Import the validation method.
import { validate } from "@andromeda/validation";

// Import the read options interface.
import { ReadManyOptions } from "./interfaces";
// Import the helper methods.
import {
    buildParams,
    extractUrl,
    CreatableResource,
    parseValidatorAndOptions,
    PromisableValidateFunction,
    buildHeaders,
} from "./common";
// Import the empty source error.
import { SourceIsEmptyError } from "../../errors";

/**
 * Creates multiple new resources on the server.
 * Seeks the target from the configuration.
 *
 * @template {Resource<T>} R
 * The type of the created resource.
 * @param {CreatableResource<R>[]} resources The resources to create on the server.
 * @param {ReadManyOptions | undefined} [options=undefined] Additional read options.
 * @returns {Promise<MultipleMessageHelper<R>>} A promise that resolves with a message object
 * containing the generated resources.
 */
export function createMany<R extends Resource>(
    resources: CreatableResource<R>[],
    options?: ReadManyOptions
): Promise<MultipleMessageHelper<R>>;

/**
 * Creates multiple new resources on the server.
 * Validates the received object.
 * Seeks the target from the configuration.
 *
 * @template {Resource} R
 * The returned resource type.
 * @template {CreatableResource<R>} C=CreatableResource<R>
 * The generated resource type.
 * @param {C[]} resources The resources to generate.
 * @param {PromisableValidateFunction<R>} validate
 * The validation function used to check the received resources.
 * @param {ReadManyOptions | undefined} [options=undefined] Additional read options.
 * @returns {Promise<MultipleMessageHelper<R>>} A promise that resolves with a message object containing the generated
 *   resources.
 */
export function createMany<
    R extends Resource,
    C extends CreatableResource<R> = CreatableResource<R>
>(
    resources: C[],
    validate: PromisableValidateFunction<R>,
    options?: ReadManyOptions
): Promise<MultipleMessageHelper<R>>;

/**
 * Creates multiple new resources on the server.
 * MUST use client-generated uuids for creation.
 * Seeks the target from the configuration.
 *
 * @template {Resource} C
 * The generated resource type.
 * @param {C[]} resources The resources to generate.
 * @param {ReadManyOptions | undefined} [options=undefined] Additional read options.
 * @returns {Promise<MultipleMessageHelper<C>>} A promise that resolves with a message object containing the generated
 *   resources.
 */
export function createMany<C extends Resource>(
    resources: C[],
    options?: ReadManyOptions
): Promise<MultipleMessageHelper<C>>;

/**
 * Creates multiple new resources on the server.
 * MUST use client-generated uuids for creation.
 * Validates the received object.
 * Seeks the target from the configuration.
 *
 * @template {Resource} C
 * The generated resource type.
 * @template {Resource<C["type"]>} R=C
 * The returned resource type.
 * @param {C[]} resources The resources to generate.
 * @param {PromisableValidateFunction<R>} validate
 * The validation function used to check the received resource.
 * @param {ReadManyOptions | undefined} [options=undefined] Additional read options.
 * @returns {Promise<MultipleMessageHelper<R>>} A promise that resolves with a message object containing the generated
 *   resources.
 */
export function createMany<
    C extends Resource,
    R extends Resource<C["type"]> = C
>(
    resources: C[],
    validate: PromisableValidateFunction<R>,
    options?: ReadManyOptions
): Promise<MultipleMessageHelper<R>>;

/** Implementation ! */
export async function createMany<
    C extends Resource<T>,
    T extends string = C["type"],
    R extends Resource<T> = C
>(
    resources: C[] | Exclude<C, "id">[],
    validatorOrOptions?: PromisableValidateFunction<R> | ReadManyOptions,
    readOptions?: ReadManyOptions
): Promise<MultipleMessageHelper<R>> {
    // Get the options object.
    const { validator, options } = parseValidatorAndOptions(
        validatorOrOptions,
        readOptions
    );

    // Prepare the options.
    const url: string = extractUrl(resources[0].type, options);
    const searchParameters = buildParams(options);
    const body = new WritableMessageHelper(resources);
    const headers = buildHeaders(options);

    // Run the request.
    const response = await Xhr.post(url, {
        searchParameters,
        path: `/${resources[0].type}`,
        body,
        headers,
    });
    if (
        response.body instanceof MessageHelper &&
        Array.isArray(response.body.data)
    ) {
        return response.body as MultipleMessageHelper<R>;
    } else {
        // Validate the created objects.
        if (typeof validator === "undefined") throw new SourceIsEmptyError();
        const validated: R[] = [];
        for (const resource of resources) {
            validate(resource, await validator, true);
            validated.push(resource);
        }
        return new MultipleMessageHelper<R>(validated);
    }
}
