/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the json:api resource interface.
import { Resource } from "@andromeda/json-api";

// Import the base interface.
import { AssetBase } from "./base";

// Import the validation schema.
import Schema from "../schema/font";

/** Interface used to represent a font/* asset. */
export interface FontAsset extends AssetBase {
    /** @inheritDoc */
    attributes: FontAsset.Attributes;
}

/** Namespace used to augment the {@link FontAsset} interface. */
export namespace FontAsset {
    export const Type = AssetBase.Type;
    export type Type = typeof Type;

    export interface Attributes extends AssetBase.Attributes {
        /** @inheritDoc */
        contentType: `font/${string}`;
        /** Name of the font. */
        fontName: string;
        /** Name of the font's family. */
        familyName: string;
        /** Weight of the font. */
        weight: string;
    }

    export const validate = compileSync<FontAsset>(Schema.ResourceSchema);

    export interface Update extends Resource<FontAsset.Type> {
        /** @inheritDoc */
        attributes?: Partial<Attributes>;
        /** @inheritDoc */
        relationships?: Update.Relationships;
    }

    export namespace Update {
        export interface Relationships {
            /** @borrows {AssetBase.Relationships.alternatives} */
            alternatives?: AssetBase.Relationships["alternatives"];
        }

        export const validate = compileSync<Update>(Schema.UpdateSchema);
    }

    export type Create = FontAsset;

    export namespace Create {
        export const validate = compileSync<Create>(Schema.CreateSchema);
    }
}
