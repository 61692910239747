/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useState } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the custom components.
import { useDeleteConfirm, useNotify } from "@andromeda/components";
// Import the store.
import { ShareLinkStore, useResourceDispatch } from "@andromeda/store";

// Import the icons.
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
// Import the css.
import css from "./delete.module.scss";


/** Component used to render the button used to delete the icon. */
export default function LinkDeleteButton(props: LinkDeleteButtonProps): ReactElement {
    // Get the notification tool.
    const { error } = useNotify();

    // Get a callback to get assurance from the user that the deletion is indeed requested.
    const confirm = useDeleteConfirm("Êtes vous sûr de supprimer ce lien ?");

    // Flag set when the link is being deleted.
    const [deleting, setDeleting] = useState(false);

    // Callback used to delete the link.
    const dispatch = useResourceDispatch();
    const deleteLink = useCallback(async function deleteShareLink(): Promise<void> {
        try {
            // Ask the user for assurance that this is indeed what they want to do.
            if (await confirm()) {
                // Delete the link.
                setDeleting(true);
                await dispatch(ShareLinkStore.generator.delete(props.id));
                setDeleting(false);
            }
        } catch (err: unknown) {
            error(
                err,
                "Impossible de supprimer un lien",
                "Une erreur est survenue pendant la suppression de votre lien"
            );
        }
    }, [confirm, dispatch, error, props.id]);

    // Build the class name of the button.
    const className = classNames(css["button"], css["button--danger"], props.className);

    // Render the component.
    return <button className={className} onClick={deleteLink} disabled={deleting}>
        <FontAwesomeIcon className={css["button__icon"]} icon={deleting ? faSpinner : faTrash} />
    </button>;
}

/** Props passed down to the {@link LinkDeleteButton} component. */
export interface LinkDeleteButtonProps {
    /** The identifier of the link to delete. */
    id: string;
    /** An optional class name added to the <button> element. */
    className?: string;
}
