/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the metadata schema.
import MetadataSchema from "./meta";

/** Schema used to describe a single link. */
const Schema = {
    title: "Link",
    oneOf: [{
        description: "A string containing the link's URL.",
        type: "string"
    }, {
        description: "An object with a \"href\" property with the link's URL.",
        type: "object",
        properties: {
            "href": {
                description: "A string containing the link's URL.",
                type: "string"
            },
            "meta": MetadataSchema
        },
        additionalProperties: false,
        required: [ "href" ]
    }]
} as const;

export default Schema;
