/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API interfaces.
import { Resource, Relationship, ResourceIdentifier } from "@andromeda/json-api";
// Import the json:schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the resource helpers.
import { CreatableResource } from "@andromeda/resource-helper";

// Import the user and requirement resources.
import { User, Requirement } from "../..";

// Import the schemas.
import { SessionSchema } from "./schema";


/**
 * Interface used to represent a user's session.
 * Sessions can be made on wikis, tutos and zaq.
 */
export type Session = Session.ZaqWikiSession | Session.ZaqTutoSession | Session.ZaqLearningSession | Session.ZaqChallengeSession;

/** Augment the {@link Session} interface. */
export namespace Session {
    export const Type = "session" as const;
    export type Type = typeof Type;

    /** Attributes of the {@link BaseSession} resource. */
    interface BaseAttributes {
        /** Start date of the session, expressed as an ISO 8601 string. */
        start: string;
        /** End date of the session, expressed as an ISO 8601 string. */
        end: string;
    }
    /** Relationships of the {@link BaseSession} resource. */
    interface BaseRelationships {
        /** Reference to the user that made this session. */
        user: Relationship<ResourceIdentifier<User.Type>>;
    }
    /** Base interface common for all session types. */
    interface BaseSession extends Resource<Session.Type> {
        attributes: BaseAttributes; relationships: BaseRelationships;
    }

    /** Interface used to specify the details of a viewed wiki file. */
    export interface WikiFileDetail {
        /** The unique identifier of the file. */
        id: string;
        /** A flag set if the file was opened. */
        opened: boolean;
        /** A flag set if the file entered the viewport. */
        seen: boolean;
    }

    /** Attributes of a tuto session. */
    interface WikiAttributes extends BaseAttributes {
        /** A list of details that were operated in this session. */
        files: WikiFileDetail[];
    }
    /** Relationships of a wiki session. */
    interface WikiRelationships extends BaseRelationships {
        /** Reference to the wiki that the session was made on. */
        wiki: Relationship<Resource<"zaq-wiki">>;
    }
    /** Interface used to describe a session made on a zaq wiki. */
    export interface ZaqWikiSession extends BaseSession {
        attributes: WikiAttributes; relationships: WikiRelationships;
    }
    /** Helper used to check if the given {@link Session} is a {@link ZaqWikiSession}. */
    export function isZaqWikiSession(session: Session): session is ZaqWikiSession {
        return "wiki" in session.relationships;
    }

    /** Attributes of a tuto session. */
    interface TutoAttributes extends BaseAttributes {
        /** Total completion of the session. Expressed as a normalized [0,1] value. */
        completion: number;
    }
    /** Relationships of a tuto session. */
    interface TutoRelationships extends BaseRelationships {
        /** Reference to the tuto that the session was made on. */
        tuto: Relationship<Resource<"zaq-tuto">>;
    }
    /** Interface used to describe a session made on a zaq tuto. */
    export interface ZaqTutoSession extends BaseSession {
        attributes: TutoAttributes; relationships: TutoRelationships;
    }
    /** Helper used to check if the given {@link Session} is a {@link ZaqTutoSession}. */
    export function isZaqTutoSession(session: Session): session is ZaqTutoSession {
        return "tuto" in session.relationships;
    }

    /** Attributes of a zaq session. */
    interface ZaqAttributes extends BaseAttributes {
        /** Total completion of the session. Expressed as a normalized [0,1] value. */
        completion: number;
    }
    /** Attributes of a zaq learning session. */
    interface ZaqLearningAttributes extends ZaqAttributes {
        /** Mode that the session was running. */
        mode: "learning";
    }
    /** Attributes of a zaq challenge session. */
    interface ZaqChallengeAttributes extends ZaqAttributes {
        /** Mode that the session was running. */
        mode: "challenge";
        /** Score that the user reached in the challenge session. */
        score: number;
        /** Level that was reached by the user. */
        level: Requirement.Level | null;
    }
    /** Relationships of a tuto session. */
    interface ZaqRelationships extends BaseRelationships {
        /** Reference to the zaq that the session was made on. */
        zaq: Relationship<Resource<"zaq">>;
    }
    /** Interface used to describe a session made on a zaq tuto. */
    export interface ZaqLearningSession extends BaseSession {
        attributes: ZaqLearningAttributes;
        relationships: ZaqRelationships;
    }
    /** Interface used to describe a session made on a zaq tuto. */
    export interface ZaqChallengeSession extends BaseSession {
        attributes: ZaqChallengeAttributes;
        relationships: ZaqRelationships;
    }
    /** Helper used to check if the given {@link Session} is a {@link ZaqTutoSession}. */
    export function isZaqSession(session: Session): session is ZaqLearningSession | ZaqChallengeSession {
        return "zaq" in session.relationships;
    }

    export const validate = compileSync<Session>(SessionSchema.ResourceSchema);


    /** Interface used to update a {@link Session}. */
    export interface Update extends Omit<Resource<Session.Type>, "relationships"> {
        attributes?: Update.Attributes;
    }

    /** Augments the {@link Update} interface. */
    export namespace Update {
        type UpdateWikiAttributes = Partial<Pick<WikiAttributes, "files">>;
        type UpdateTutoAttributes = Partial<Pick<TutoAttributes, "completion">>;
        type UpdateZaqAttributes = Partial<Pick<ZaqChallengeAttributes, "completion" | "score" | "level">>;
        export type Attributes = UpdateWikiAttributes | UpdateTutoAttributes | UpdateZaqAttributes;
        export const validate = compileSync<Session.Update>(SessionSchema.UpdateSchema);
    }


    /** Interface used to create new {@link Session}. */
    export type Create = Create.WikiSession | Create.TutoSession | Create.ZaqSession;

    /** Augments the {@link Create} interface. */
    export namespace Create {
        /** Attributes of a wiki session. */
        interface CreateWikiAttributes {
            /** @borrows {Session.WikiAttributes["files"]} */
            files: WikiAttributes["files"];
        }
        /** Relationships of a wiki session. */
        interface CreateWikiRelationships {
            /** @borrows {Session.WikiRelationships["zaq"]} */
            wiki: WikiRelationships["wiki"];
        }
        /** Interface used to describe a session made on a zaq tuto. */
        export interface WikiSession extends CreatableResource<Session.Type> {
            attributes: CreateWikiAttributes; relationships: CreateWikiRelationships;
        }

        /** Relationships of a tuto session. */
        interface CreateTutoRelationships {
            /** @borrows {Session.WikiRelationships["zaq"]} */
            tuto: TutoRelationships["tuto"];
        }
        /** Interface used to describe a session made on a zaq tuto. */
        export interface TutoSession extends Omit<CreatableResource<Session.Type>, "attributes"> {
            relationships: CreateTutoRelationships;
        }

        /** Attributes of a zaq session. */
        interface CreateZaqAttributes {
            /** Mode that the session was running. */
            mode: "challenge" | "learning";
        }
        /** Relationships of a zaq session. */
        interface CreateZaqRelationships {
            /** @borrows {Session.ZaqRelationships["zaq"]} */
            zaq: ZaqRelationships["zaq"];
        }
        /** Interface used to describe a session made on a zaq tuto. */
        export interface ZaqSession extends CreatableResource<Session.Type> {
            attributes: CreateZaqAttributes; relationships: CreateZaqRelationships;
        }

        /** Helper used to check if the given {@link Session} is a {@link ZaqWikiSession}. */
        export function isZaqWikiSession(session: Session.Create): session is WikiSession {
            return "wiki" in session.relationships;
        }
        /** Helper used to check if the given {@link Session} is a {@link ZaqTutoSession}. */
        export function isZaqTutoSession(session: Session.Create): session is TutoSession {
            return "tuto" in session.relationships;
        }
        /** Helper used to check if the given {@link Session} is a {@link ZaqTutoSession}. */
        export function isZaqSession(session: Session.Create): session is ZaqSession {
            return "zaq" in session.relationships;
        }
        export const validate = compileSync<Session.Create>(SessionSchema.CreateSchema);
    }
}
