/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the custom components.
import { useNotify } from "@andromeda/components";

// Import the icons.
import { faCopy } from "@fortawesome/free-regular-svg-icons/faCopy";
// Import the css.
import css from "./copy.module.scss";
import { useZaqWiki } from "@andromeda/store";


/** Component used to copy a link. */
export default function LinkCopyButton(props: LinkCopyButtonProps): ReactElement {
    // Get the notification tools.
    const { error } = useNotify();

    // Get the name of the wiki.
    const name = useZaqWiki(props.id).data?.attributes.name;

    // Callback used to copy the link into the clipboard.
    const copy = useCallback(function copyToClipboard(): void {
        // Check if the API is available.
        if (typeof navigator.clipboard?.writeText !== "function") {
            return error(
                new Error("Missing clipboard API"),
                "Impossible de copier",
                "Impossible de copier le lien dans votre presse-papier."
            );
        }

        // Write the link to the clipboard.
        navigator.clipboard.writeText(`ZaqWiki "${name}":\n${props.link}`)
            .then(function warnUserThatLinkWasCopied(): void {
                window.alert("Le lien a été copié dans votre presse-papiers.");
            })
            .catch(function propagateCopyError(e: unknown): void {
                return error(
                    e,
                    "Impossible de copier",
                    "Une error est survenue durant la copie de votre lien."
                );
            });
    }, [error, name, props.link]);

    // Render the component.
    return <button
        className={classNames(css["button"], props.className)}
        onClick={copy}
    >
        <FontAwesomeIcon className={css["button__icon"]} icon={faCopy} />
    </button>;
}

/** Props passed down to the {@link LinkCopyButton} component. */
export interface LinkCopyButtonProps {
    /** The id of the current ZaqWiki. */
    id: string;
    /** The link that should be copied. */
    link: string;
    /** An optional class name added to the <button> element. */
    className?: string;
}
