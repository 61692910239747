/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the event handler class.
import { EventHandler, Event } from "@andromeda/events";
// Import the websocket wrapper.
import { WebSocket } from "@andromeda/ws";
// Import the message helper class.
import { MessageHelper, Relationship, JsonApiError } from "@andromeda/json-api";
// Import the debug library.
import debug from "debug";

// Import the asset resources.
import { AssetRequest, AssetMetadata } from "../resources";
// Import the asset class.
import { ConversionAsset } from "./asset";
// Import the cache class.
import { AssetManagerCache } from "./cache";
// Import the message encoder.
import { encodeAssetMessage } from "./encode";
// Import the message decoder.
import { decodeAssetMessage } from "./decode";


/** Interface used by the {@link AssetManager} to query assets to send through the socket. */
export interface AssetSource {
    /**
     * Find the asset with the provided id.
     * Used by {@link AssetManager} when the other side of the socket requested one or more assets.
     *
     * @param {string} id The identifier of the requested asset.
     * @returns {Promise<ConversionAsset | null>} A promise that resolves with the asset to return through the socket.
     * Or null if the asset does not exist.
     */
    get(id: string): Promise<ConversionAsset | null>;
}

/**
 * Manager class used to send requests for assets through a {@link WebSocket}.
 * Also, handles incoming asset requests and sends them back.
 */
export class AssetManager {
    /** Socket used internally by this instance. */
    public readonly socket: WebSocket;
    /** Asset source used internally by this instance. */
    public readonly source: AssetSource;
    /** Cache used internally by this instance. */
    public readonly cache: AssetManagerCache;

    /**
     * Class constructor.
     * Attaches all the listeners to the underlying {@link WebSocket}.
     *
     * @param {WebSocket} socket The socket to wrap with this manager.
     * @param {AssetSource} source The source of the assets to send to the client.
     * @param {AssetManagerCache} [cache=DefaultAssetManagerCache] The optional cache to use with this manager.
     */
    public constructor(
        socket: WebSocket,
        source: AssetSource,
        cache: AssetManagerCache = new DefaultAssetManagerCache()
    ) {
        log("Generating a new asset manager");
        this.socket = socket; this.source = source; this.cache = cache;
        this._handleRequests = this._handleRequests.bind(this);
        this._handleMessage = this._handleMessage.bind(this);

        this.socket.addEventListener("message", this._handleRequests);
        this.socket.addEventListener("arraybuffer", this._handleMessage);

        // Disable socket buffering.
        this.socket.string.buffering = false;
        this.socket.arraybuffer.buffering = false;
        this.socket.message.buffering = false;
    }

    /** Detaches from the underlying socket. */
    public detach(): void {
        this.socket.removeEventListener("message", this._handleRequests);
        this.socket.removeEventListener("arraybuffer", this._handleMessage);
    }

    /**
     * Requests an asset from the party at the other end of the socket.
     * Checks the cache to see if the asset is still in cache.
     * Sends a {@link AssetRequest} through the socket and waits for a response back.
     *
     * @param {string} uuid The uuid of the requested asset.
     * @returns {Promise<ConversionAsset>} A promise that resolves with the asset object.
     */
    public request(uuid: string): Promise<ConversionAsset>;

    /**
     * Requests multiple assets from the party at the other end of the socket.
     * Checks the cache to see if the assets are still in cache.
     * Sends {@link AssetRequest} objects through the socket and waits for a response back.
     *
     * @param {string[]} uuids The uuids of the requested assets.
     * @returns {Promise<ConversionAsset[]>} A promise that resolves with the asset objects.
     */
    public request(uuids: string[]): Promise<ConversionAsset[]>;

    /**
     * Requests multiple assets from the party at the other end of the socket.
     * Checks the cache to see if the assets are still in cache.
     * Sends {@link AssetRequest} objects through the socket and waits for a response back.
     *
     * @param {Relationship<AssetMetadata[]>} relationship The relationship to grab the asset from.
     * @returns {Promise<ConversionAsset[]>} A promise that resolves with the asset objects.
     */
    public request(relationship: Relationship<AssetMetadata[]>): Promise<ConversionAsset[]>;

    /** Implementation ! */
    public async request(uuids: string | string[] | Relationship<AssetMetadata[]>): Promise<ConversionAsset | ConversionAsset[]> {
        let UUIDs: string[];
        if (typeof uuids === "string") UUIDs = [ uuids ];
        else if (Array.isArray(uuids)) UUIDs = uuids;
        else UUIDs = uuids.data.map(asset => asset.id);
        log("Requesting assets: [ %s ]", UUIDs.join(", "));

        // Search all the assets in the cache first.
        const assets: ConversionAsset[] = [], missing: string[] = [];
        for (const UUID of UUIDs) {
            const cached = await this.cache.get(UUID).catch(() => null);
            if (cached) assets.push(cached);
            else missing.push(UUID);
        }
        log("Found %d already in cache", assets.length);

        // Send a request for all the missing assets.
        const requests = missing.map((uuid: string): AssetRequest => ({ type: AssetRequest.Type, id: uuid }));
        this.socket.write(new MessageHelper(requests));

        // Wait for the assets to arrive.
        await Promise.all(missing.map(uuid => {
            return new Promise<void>((resolve, reject) => {
                const abort = new AbortController();

                // Stop waiting after 10 minutes.
                const timeout = setTimeout(function abortAssetWait(): void {
                    abort.abort();
                    reject(new Error("Stopped waiting for an asset after waiting for 10 minutes."));
                }, 10 * 60 * 1000);

                // Wait for the asset.
                this.cache.addEventListener("asset", event => {
                    log("Asset event %s while waiting for %s", event.value.id, uuid);
                    if (event.value.id !== uuid) return;

                    abort.abort();
                    clearTimeout(timeout);
                    assets.push(event.value); resolve();
                }, { signal: abort.signal });
            });
        }));

        return typeof uuids === "string" ? assets[0] : assets;
    }

    /** Handles incoming asset requests. */
    private async _handleRequests(event: Event<MessageHelper>): Promise<void> {
        try {
            // Check if the event has any asset message.
            const assets = event.value.findMany(AssetRequest.Type, await AssetRequest.validate);
            if (assets.length <= 0) return;

            const data: ConversionAsset[] = [];
            for (const request of assets) {
                log("Got a request for asset \"%s\"", request.id);
                // Request the asset from the store.
                const asset = await this.source.get(request.id);
                if (asset) data.push(asset);
            }

            // Send the assets to the client.
            if (data.length <= 0) return;
            this.socket.write(encodeAssetMessage(data));
        } catch (e: unknown) {
            if (e instanceof JsonApiError) {
                this.socket.write(e);
            } else {
                this.socket.write(
                    new JsonApiError(
                        "Failed to query the requested asset",
                        {
                            title: "AssetRequestFailure",
                            status: "500",
                            source: { pointer: "/id" },
                            meta: {
                                inner: JSON.stringify(e)
                            }
                        }
                    )
                );
            }
        }
    }

    /** Handles incoming asset messages. */
    private async _handleMessage(event: Event<ArrayBuffer>): Promise<void> {
        try {
            for (const asset of decodeAssetMessage(event.value)) {
                this.cache.set(asset);
                this.cache.dispatchEvent("asset", asset);
            }
        } catch (e: unknown) {
            console.error(e);
            this.socket.write(new JsonApiError("Non asset-data array buffer received"));
        }
    }
}

/** Default cache used by the {@link AssetManager} class. Does not cache anything. */
class DefaultAssetManagerCache extends EventHandler<AssetManagerCache.EventMap> implements AssetManagerCache {
    /** @inheritDoc */
    public get(): Promise<null> { return Promise.resolve(null); }
    /** @inheritDoc */
    public set(): void { return; }
}

const log = debug("asset-conversion:message:manager");
