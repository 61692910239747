/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

/** Interface used to describe the localised values of a property. */
export interface Localised<T extends string = string> extends Record<string, T | undefined> {
    /** French localisation of this field. */
    "fr-FR"?: T;
    /** British-english localisation of this field. */
    "en-GB"?: T;
    /** American-english localisation of this field. */
    "en-US"?: T;
    /** German localisation of this field. */
    "de-DE"?: T;
    /** Japanese localisation of this field. */
    "jp-JP"?: T;
    /** Italian localisation of this field. */
    "it-IT"?: T;
    /** Spanish localisation of this field. */
    "es-ES"?: T;
    /** Portuguese localisation of this field. */
    "pt-PT"?: T;
    /** Brazilian-portuguese localisation of this field. */
    "pt-BR"?: T;
}
