/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";
// Import the metadata interfaces.
import {
    VideoAssetMetadata,
    ImageAssetMetadata,
    AudioAssetMetadata,
    VideoAssetMimeType,
    ImageAssetMimeType,
    AudioAssetMimeType, AnyAssetMetadata
} from "@andromeda/asset-conversion";
// Import the noop helper.
import { makeContextNoop } from "@andromeda/tools";


/** Default value of the {@link MediaParserContext}. */
const DEFAULT_VALUE: MediaParserContext = {
    parse: makeContextNoop("MediaParserContext", "parse"),
};

/** Context used to parse a given media object and return its properties. */
export const MediaParserContext = React.createContext<MediaParserContext>(DEFAULT_VALUE);

/** Context provided by ExtrapolationProvider and needed for useConversionAssetBuilder. */
export interface MediaParserContext {
    /**
     * Parses the provided video file and returns its metadata.
     *
     * @param {File} file The file to parse.
     * @returns {Promise<VideoAssetMetadata>} A promise that resolves with the parsed metadata.
     */
    parse(file: File & { type: VideoAssetMimeType }): Promise<VideoAssetMetadata>;

    /**
     * Parses the provided image file and returns its metadata.
     *
     * @param {File} file The file to parse.
     * @returns {Promise<ImageAssetMetadata>} A promise that resolves with the parsed metadata.
     */
    parse(file: File & { type: ImageAssetMimeType }): Promise<ImageAssetMetadata>;

    /**
     * Parses the provided audio file and returns its metadata.
     *
     * @param {File} file The file to parse.
     * @returns {Promise<AudioAssetMetadata>} A promise that resolves with the parsed metadata.
     */
    parse(file: File & { type: AudioAssetMimeType }): Promise<AudioAssetMetadata>;

    /**
     * Parses the provided file and returns its metadata.
     *
     * @param {File} file The file to parse.
     * @returns {Promise<AnyAssetMetadata>} A promise that resolves with the parsed metadata.
     */
    parse(file: File): Promise<AnyAssetMetadata>;
}
