/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */


/** Enumeration of all the supported compression values. */
export enum CompressionKind {
    none = 0x0,
    gzip = 0x1,
    compress = 0x2,
    deflate = 0x3,
    br = 0x4
}

/** Error thrown by {@link parseCompression} if a compression value is invalid. */
export class InvalidCompressionError extends Error {
    constructor(byte: number) {
        super(
            `Found an invalid value for a compression byte: ${byte}.\n` +
            `Expected one of: [ ${Object.values(CompressionKind).join(", ")} ]`
        );
    }
}

/**
 * Converts a value to a {@link CompressionKind} value.
 * @throws {@link InvalidCompressionError} If the value is not valid.
 */
export function parseCompression(value: number): CompressionKind {
    switch (value) {
    case CompressionKind.none: return value;
    case CompressionKind.gzip: return value;
    case CompressionKind.compress: return value;
    case CompressionKind.deflate: return value;
    case CompressionKind.br: return value;
    default: throw new InvalidCompressionError(value);
    }
}
