/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import React-Router.
import { Route, Routes } from "react-router-dom";
// Import the custom components.
import { ConfirmProvider } from "@andromeda/components";
// Import the store.
import {
    AssetStore,
    CourseStore,
    EncryptionSourceStore,
    TagStore,
    withRequestStore,
    ZaqWikiStore
} from "@andromeda/store";
// Import the legacy zaq tools.
import { withLegacyZaqReducer } from "@andromeda/legacy-zaq";

// Import the subcomponents.
import FavouriteWarningModal from "./component/favourite-warning";
import Home from "./component/home";
import Search from "./component/search";
import Create from "./component/create";

// Import the css.
import css from "./index.module.scss";


/** List of all possible modes for the main menu. */
export type MenuMode = "home" | "search-results";

/** Draws the main menu of the ZaqTiv platform. */
const MainMenu = ZaqWikiStore.withReducer(
    TagStore.withReducer(
        CourseStore.withReducer(
            AssetStore.withReducer(
                EncryptionSourceStore.withReducer(
                    withLegacyZaqReducer(
                        withRequestStore(
                            MainMenuComponent
                        )
                    )
                )
            )
        )
    )
);
export default MainMenu;

/** @see MainMenu */
function MainMenuComponent(): ReactElement {
    // Render the component.
    return <div className={css["main-menu"]}>
        <ConfirmProvider>
            <Routes>
                <Route path="search" element={<Search />} />
                <Route path="*" element={<Home />} />
            </Routes>
            <FavouriteWarningModal />
            <Create />
        </ConfirmProvider>
    </div>;
}
