/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */


/** Type alias for a task that can be run by the {@link PromiseQueue}. */
export type PromiseTask<T = unknown> = Promise<T> | (() => Promise<T>);

/**
 * Interface used to describe a promise queue.
 * This object runs every task passed via {@link enqueue}, in order.
 */
export class PromiseQueue {
    /** Queue used to store all the jobs that will need to be run. */
    protected readonly jobs: PromiseJob[] = [];

    /** Flag set if the worker has reached the end of the queue and paused itself. */
    private _paused = true;

    /**
     * Enqueues a new task to the runner.
     *
     * @template T
     * @param {PromiseTask<T>} task The task to enqueue.
     * @returns {Promise<T>} A promise that resolves with the results from the task.
     */
    public enqueue<T = unknown>(task: PromiseTask<T>): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.jobs.push({
                task,
                resolve(error: Error | null, result?: T): void {
                    if (error) reject(error);
                    else resolve(result as T);
                },
            });
            this.wakeUp();
        });
    }

    /** Picks up the first item in the queue, if it is not already running. */
    protected wakeUp(): void {
        if (!this._paused) return;
        this._paused = false;
        this.readNext();
    }

    /** Reads an executes the next job in the queue. */
    protected async readNext(): Promise<void> {
        const runner = this.jobs.shift();
        if (!runner) { this._paused = true; return; }

        // Run the job.
        try {
            if (typeof runner.task === "function") {
                resolve.call(this, await runner.task());
            } else {
                resolve.call(this, await runner.task);
            }
        } catch (e: unknown) {
            reject.call(this, e);
        }

        function resolve(this: PromiseQueue, value: unknown): void {
            if (runner) runner.resolve(undefined, value);
            this.readNext();
        }
        function reject(this: PromiseQueue, error: unknown): void {
            if (runner) runner.resolve(error);
            this.readNext();
        }
    }
}

/**
 * Interface used to represent a job. Used internally by {@link PromiseQueue}.
 *
 * @template T
 */
export interface PromiseJob<T = unknown> {
    /** The task to run in this job. */
    task: PromiseTask<T>;

    /**
     * Callback invoked when the job is complete.
     *
     * @param {unknown | undefined} error The error that arose when running the task.
     * @param {T} result The result of the task.
     */
    resolve(error: unknown | undefined, result?: T): void;
}
