/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React
import {
    createContext,
    Fragment,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the resources.
import { Session, ZaqWiki } from "@andromeda/resources";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the store.
import { SessionStore, useResourceDispatch, ZaqWikiFileStore } from "@andromeda/store";


/** Context used to describe a {@link ZaqWiki} session. */
export interface ZaqWikiSessionContext {
    /** The identifier of the currently running session. */
    readonly id: string;

    /**
     * Method used to mark a file as being opened.
     *
     * @param {string} file The identifier of the file that was opened.
     */
    openFile(file: string): void;
}

/** @see ZaqWikiSessionContext */
export const ZaqWikiSessionContext = createContext<ZaqWikiSessionContext>({
    id: "00000000-0000-0000-0000-000000000000",
    openFile() {
        console.warn("Noop");
    }
});

/** Component used to provide the {@link ZaqWikiSessionContext}. */
export function ZaqWikiSessionContextProvider(props: ZaqWikiSessionContextProviderProps) {
    // Get the store dispatch.
    const dispatch = useResourceDispatch();

    // Load all the wiki's files.
    const files = ZaqWikiFileStore.useSelector(
        store => store.resources.filter(
            file => file.relationships.wiki.data.id === props.wiki
        ),
        (a, b) => a.length !== b.length || a.some((file, index) => file.id !== b[index].id)
    );

    // Get the current user info.
    const loginInfo = useContext(LoginContext);

    // Create a new session in the store.
    const [session, setSession] = useState<RequestStatus<Session.ZaqWikiSession | null>>(RequestStatus.uninitialised);
    const running = useRef(false);
    useEffect(function() {
        // Stop if the user is not authenticated.
        if (!loginInfo.isLoggedIn) {
            return setSession(RequestStatus.success(null));
        }

        // If a session is being created, stop here.
        if (!session.isUninitialised || running.current) {
            return;
        }

        // Create a new session.
        running.current = true;
        createNewSession()
            .then(session => RequestStatus.success(session))
            .catch(error => RequestStatus.error(error))
            .then(status => setSession(status))
            .finally(() => running.current = false);

        /** Wrapper used to create a new session. */
        async function createNewSession(): Promise<Session.ZaqWikiSession> {
            // Start a new session.
            return dispatch(
                SessionStore.generator.create({
                    type: Session.Type,
                    attributes: {
                        files: files.map(file => ({ id: file.id, opened: true, seen: true }))
                    },
                    relationships: {
                        wiki: {
                            data: {
                                type: ZaqWiki.Type,
                                id: props.wiki
                            }
                        }
                    }
                })
            ) as Promise<Session.ZaqWikiSession>;
        }
    }, [dispatch, loginInfo, props.wiki, session.isUninitialised]);

    // Method used to update the status of a given file.
    const openFile = useCallback(async function() {
        return;
    }, []);

    // Build the session context.
    const context = useMemo(function(): ZaqWikiSessionContext | null {
        // Wait for the session to be started.
        if (!session.isSuccess || session.data === null) {
            return null;
        }

        return {
            id: session.data.id,
            openFile
        };
    }, [openFile, session.data, session.isSuccess]);

    // Provide the component.
    if (!context) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <Fragment children={props.children} />;
    }
    return <ZaqWikiSessionContext.Provider value={context} children={props.children} />;
}

export interface ZaqWikiSessionContextProviderProps extends PropsWithChildren {
    wiki: string;
}
