/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useContext, useEffect, useMemo, useRef } from "react";
// Import the storybook components.
import { CardContainerContext } from "@andromeda/storybook";

// Import the css.
import css from "./tuto.module.scss";


/** Component used to render a tuto module. */
export default function TutoModule(props: TutoModuleProps): ReactElement {
    // Import the card context.
    const { current, select, count } = useContext(CardContainerContext);

    // Compute the url from the provided id.
    const url = useMemo(function computeURLFromId(): string {
        return new URL(`${window.location.pathname}/player/${props.id}`, window.location.origin).toString();
    }, [props.id]);

    // Resize the iframe to fit the container.
    const observer = useRef<ResizeObserver>();
    const fitFrameToParent = useCallback(function observeFrameParent(frame: HTMLIFrameElement | null): void {
        // Delete the existing observer.
        if (observer.current) {
            observer.current.disconnect();
            delete observer.current;
        }

        // If the frame is not set, do nothing.
        if (frame === null || frame.parentElement === null) {
            return;
        }

        // Create a new observer.
        observer.current = new ResizeObserver(onParentResize);
        // Observe the parent element.
        observer.current.observe(frame.parentElement);

        // Callback invoked when the frame's parent is resized.
        function onParentResize(entries: ResizeObserverEntry[]): void {
            // If the frame is not set, do nothing.
            if (frame === null) {
                return;
            }

            // Apply the resize entries to the frame.
            for (const entry of entries) {
                frame.style.width = `${Math.floor(entry.contentRect.width)}px`;
                frame.style.height = `${Math.floor(entry.contentRect.height) - 48}px`;
            }
        }
    }, []);

    // // Send the fullscreen request to the child frame.
    // useEffect(function dispatchFullscreenEventsToFrame(): () => void {
    //     // Attach the event listeners.
    //     window.addEventListener("request-fullscreen", transmitEventToFrame);
    //     return function removeEventListener() {
    //         window.removeEventListener("request-fullscreen", transmitEventToFrame);
    //     }
    //
    //     function transmitEventToFrame(event: Event): void {
    //         if (event.type === "request-fullscreen") {
    //             event.preventDefault();
    //
    //             // Send a message to the child frame.
    //             frame.current?.contentWindow?.postMessage(JSON.stringify({
    //                 data: { type: "fullscreen", attributes: { kind: "enter" }}
    //             }));
    //         }
    //     }
    // }, []);

    // Move on to the next card if the user exits the player.
    useEffect(function moveToNextOnExit(): () => void {
        // Attach the listener.
        window.addEventListener("message", onMessage);
        return function removeMessageListener(): void {
            window.removeEventListener("message", onMessage);
        }

        // Callback invoked when a message is dispatched to the frame.
        function onMessage(event: MessageEvent): void {
            // Check if the event's source is valid.
            if (event.origin !== window.location.origin) {
                return;
            }

            // Check if the message is an object.
            if (typeof event.data !== "object" || event.data === null) {
                return;
            }

            // If the message is an exit event, move to the next card.
            if (event.data.type === "exit" && event.data.scope === "remote-player") {
                if (current + 1 >= count) {
                    select(0);
                } else {
                    select(current + 1);
                }
            }
        }
    }, [count, current, select]);

    // Render the component.
    return <div className={css["tuto"]}>
        <iframe
            className={css["tuto__frame"]}
            title={props.title}
            src={url}
            ref={fitFrameToParent}
            allow="fullscreen"
            allowFullScreen
        />
    </div>;
}

/** Props passed down to the {@link TutoModule}. */
export interface TutoModuleProps {
    /** The title of this module. */
    title: string;
    /** The id of the ZaqTuto to run. */
    id: string;
}
