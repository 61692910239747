/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the Xhr package.
import { Xhr } from "@andromeda/xhr";

// Import the read options interface.
import { DeleteOptions } from "./interfaces";
// Import the helper methods.
import { buildParams, extractUrl, buildHeaders } from "./common";
// Import the error class.
import { DeletionFailedError } from "../../errors";

/**
 * Deletes a single resource from the server.
 * Seeks the target from the configuration.
 *
 * @param {string} type The type of the resource to delete.
 * @param {string} id The id of the resource to delete.
 * @param {DeleteOptions | undefined} [options=undefined] The options for the deletion operation.
 * @returns {Promise<void>} A promise that resolves when the resource is deleted.
 */
export async function deleteOne(
    type: string,
    id: string,
    options?: DeleteOptions
): Promise<void> {
    // Prepare the options.
    const url: string = extractUrl(type, options);
    const searchParameters = buildParams(options);
    const headers = buildHeaders(options);

    // Run the request.
    const response = await Xhr.delete(url, {
        searchParameters,
        path: options?.path ?? `/${type}/${id}`,
        headers,
    });
    // Check the response.
    if (response.status < 200 || response.status >= 400) {
        const error = new DeletionFailedError(response);
        console.warn(
            `Failed to delete a resource. Body of the response:\n${error.body}.`
        );
        throw error;
    }
}
