/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useMemo, useState } from "react";
// Import React-Bootstrap.
import { Alert } from "react-bootstrap";
// Import the resources.
import { Requirement, Step } from "@andromeda/resources";
// Import the store.
import { useRequirements, useSteps } from "@andromeda/store";

// Import the level icon.
import LevelIcon from "../level-icon";

// Import the css.
import css from "./requirement-renderer.module.scss";


/** Component used to reder the requirement for the current item. */
export default function RequirementRenderer(props: RequirementProps): ReactElement {
    // Store the state of the alert.
    const [showAlert, setShowAlert] = useState(true);

    // Load the required level for the current item.
    const requirements = useRequirements(props.course, props.user);
    const steps = useSteps(props.course);
    const requiredLevel = useMemo(
        function useRequiredLevel(): Requirement.Level | null {
            if (!requirements.isSuccess || !steps.isSuccess) {
                return null;
            }

            // Find the steps with the current item and at least one requirement.
            let requiredLevel = Requirement.Level.novice;
            for (const step of steps.data) {
                // Check if the step holds the current item.
                if (!step.relationships.items.data.some(
                    ({ id, type }) => id === props.item?.id && type === props.item?.type)
                ) {
                    continue;
                }

                // Find the requirement for the step.
                const requirement = requirements.data.find(
                    requirement => requirement.relationships.step.data.id === step.id
                        && requirement.relationships.user.data.id === props.user
                );
                if (requirement && requirement.attributes.requiredLevel > requiredLevel) {
                    requiredLevel = requirement.attributes.requiredLevel;
                }
            }
            return requiredLevel;
        },
        [
            props.item?.id,
            props.item?.type,
            props.user,
            requirements.data,
            requirements.isSuccess,
            steps.data,
            steps.isSuccess
        ]
    );

    return <>
        <div className={css["required__container"]}>
            <span className={css["required__title"]}>Niveau attendu :</span>
            <div className={css["required__pill"]}>
                <LevelIcon
                    className={css["required"]}
                    backgroundClassName={css["required__background"]}
                    level={requiredLevel}
                />
                {Requirement.Level.toString(requiredLevel ?? 0)}
            </div>
        </div>
        <Alert
            className={css["alert"]}
            variant="info"
            dismissible
            transition
            show={showAlert && props.item?.type === "zaq"}
            onClose={() => setShowAlert(false)}
        >
            L'apprenant doit réciter à voix haute les actions qu'il va effectuer
        </Alert>
    </>;
}

export interface RequirementProps {
    user: string | undefined;
    course: string;
    item: Step.Item | undefined;
}
