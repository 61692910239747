/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useId } from "react";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the resources.
import { Requirement } from "@andromeda/resources";

// Import the validation store.
import { useValidationEditor } from "../../store";
// Import the level icon.
import LevelIcon from "../level-icon";

// Import the css.
import css from "./external.module.scss";


/** Component used to validate an external element. */
export default function External(props: ExternalProps): ReactElement | null {
    const id = useId();

    // Load the external from the store.
    const [validation, updateValidation] = useValidationEditor(props.user, { type: "external", id: props.external });

    // Callback used to update the selected level.
    const buildLevelUpdater = useCallback(function buildLevelUpdater(level: Requirement.Level | null) {
        return function levelUpdater(): void {
            updateValidation({ level });
        };
    }, [updateValidation]);

    if (!validation.isSuccess) {
        return <Loader
            transparent
            containerClassName={css["loader"]}
            text="Chargement de la validation précédente ..."
        />;
    }
    return <div className={css["container"]}>
        <h3 className={css["title"]}>Sélectionnez le niveau atteint par cet apprenant :</h3>
        <input
            id={`${id}-none`}
            className={css["button__input"]}
            type="radio"
            checked={validation.data.attributes.level === null}
            onChange={buildLevelUpdater(null)}
        />
        <label htmlFor={`${id}-none`} className={css["button"]}>
            <LevelIcon className={css["button__icon"]} level={-1} />
            aucun
        </label>
        <input
            id={`${id}-novice`}
            className={css["button__input"]}
            type="radio"
            checked={validation.data.attributes.level === Requirement.Level.novice}
            onChange={buildLevelUpdater(Requirement.Level.novice)}
        />
        <label htmlFor={`${id}-novice`} className={css["button"]}>
            <LevelIcon className={css["button__icon"]} level={Requirement.Level.novice} />
            novice
        </label>
        <input
            id={`${id}-apprentice`}
            className={css["button__input"]}
            type="radio"
            checked={validation.data.attributes.level === Requirement.Level.apprentice}
            onChange={buildLevelUpdater(Requirement.Level.apprentice)}
        />
        <label htmlFor={`${id}-apprentice`} className={css["button"]}>
            <LevelIcon className={css["button__icon"]} level={Requirement.Level.apprentice} />
            apprenti
        </label>
        <input
            id={`${id}-master`}
            className={css["button__input"]}
            type="radio"
            checked={validation.data.attributes.level === Requirement.Level.master}
            onChange={buildLevelUpdater(Requirement.Level.master)}
        />
        <label htmlFor={`${id}-master`} className={css["button"]}>
            <LevelIcon className={css["button__icon"]} level={Requirement.Level.master} />
            maîtrise
        </label>
        <input
            id={`${id}-expert`}
            className={css["button__input"]}
            type="radio"
            checked={validation.data.attributes.level === Requirement.Level.expert}
            onChange={buildLevelUpdater(Requirement.Level.expert)}
        />
        <label htmlFor={`${id}-expert`} className={css["button"]}>
            <LevelIcon className={css["button__icon"]} level={Requirement.Level.expert} />
            expert
        </label>
    </div>;
}

/** Props passed down to the {@link External} component. */
export interface ExternalProps {
    /** The identifier of the user that is being validated. */
    user: string;
    /** The identifier of the external that is being validated. */
    external: string;
}
