/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */


import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "@andromeda/components";
import { Xhr, XhrResponse } from "@andromeda/xhr";
import { MessageHelper, Relationship } from "@andromeda/json-api";
import Player from "./player";


/** Simple component used to parse a player link. */
export default function PlayerLinkParser(): ReactElement {
    // Get the link key from the parameters.
    const { key } = useParams<{ key?: string }>();

    // Store the loading and id states.
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState<string>();

    // Check the key when the component is mounted.
    useEffect(function checkLink(): void {
        // If the key is undefined, do nothing.
        if (!key) {
            return setLoading(false);
        }

        // Load the key from the api.
        Xhr.get("legacy-api", { path: "/link", searchParameters: { key } }).then(
            function onKeyResponse({ body }: XhrResponse): void {
                // If the response is not a JSON:API message, ignore it.
                if (!(body instanceof MessageHelper)) {
                    return setLoading(false);
                }

                // Search the link from the message.
                const link = body.findMany("com.zimproov.api.zaqtiv.link")[0];
                if (!link) {
                    return setLoading(false);
                }

                // Check if the link requires a redirection.
                const skipRedirection = new URLSearchParams(window.location.search).get("no-redirect") !== null;
                const hostname = link.meta && (link.meta as { hostname?: unknown }).hostname;
                if (!skipRedirection && typeof hostname === "string" && hostname !== window.location.hostname) {
                    const url = new URL(window.location.href);
                    url.host = hostname;
                    url.port = "";
                    return window.location.assign(url.toString());
                }

                // Load the id from the zaq.
                const relationships = link.relationships;
                const rel = relationships && "zaqtiv" in relationships && (relationships as { zaqtiv: Relationship });
                const id = rel && (Array.isArray(rel.zaqtiv.data) ? rel.zaqtiv.data[0].id : rel.zaqtiv.data?.id);
                if (id) {
                    setId(id);
                }
            }
        ).then(() => setLoading(false), () => setLoading(false));
    }, [key]);

    // Check if the game is loading.
    if (loading) {
        return <Loader text="Vérification du lien ..." />;
    } else if (id === undefined) {
        return <h1>Ce lien est invalide</h1>;
    } else {
        return <Player gameId={id} />;
    }
}
