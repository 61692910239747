/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { CSSProperties, ReactElement, ReactNode, useMemo } from "react";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the flag item.
import ProgressFlag from "./flag";

// Import the css.
import css from "./index.module.scss";
import ProgressFinishLine from "./finish-line";


/** Component used to render the progress of the user in the current training. */
export default function TrainingProgress(props: TrainingProgressProps): ReactElement | null {
    // Render all the flags.
    const flags = useMemo(function renderProgressFlags(): ReactNode[] {
        return props.flagOffsets.map(function renderProgressFlag(offset: number, index: number): ReactNode {
            return <ProgressFlag key={index} offset={offset} index={index} />;
        });
    }, [props.flagOffsets]);

    // If the progress is not defined, render nothing.
    if (typeof props.progress === "undefined") {
        return null;
    }

    // Build the style passed to the bar.
    const style = { "--progress": (props.progress * 100).toFixed(0) + "%" } as CSSProperties;

    // Render the component.
    return <div className={classNames(css["progress"], props.className)} style={style}>
        {flags}
        <ProgressFinishLine />
    </div>;
}

/** Props passed down to the {@link TrainingProgress} component. */
export interface TrainingProgressProps {
    /** The value of the progress to render. */
    progress: number | undefined;
    /** The flags to render on top of the bar. */
    flagOffsets: number[];
    /** Optional class name added to the root <div> element. */
    className?: string;
}
