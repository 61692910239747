/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the react-bootstrap components.
import { OverlayTrigger, Tooltip as BootstrapTooltip, TooltipProps as BootstrapTooltipProps } from "react-bootstrap";
// Import the mobile check.
import { isMobile } from "react-device-detect";


/** Props passed down to the {@link Tooltip} component. */
interface TooltipProps {
    /** Text to render on the tooltip. */
    text?: string;
    /** Children to render under the overlay. */
    children: React.ReactElement;

    /** Boolean used to hide the tooltip. */
    hidden?: boolean;

    /** Position of the tooltip relative to its container. */
    placement?: BootstrapTooltipProps["placement"];
}

/**
 * Simple function component used to render a tooltip.
 *
 * @author Quentin Miranda
 * @version 1
 */
export function Tooltip(props: TooltipProps): React.ReactElement {
    if (props.hidden || typeof props.text === "undefined" || props.text === "" || isMobile) return props.children;

    // Render the trigger.
    return <OverlayTrigger
        delay={{ show: 100, hide: 100 }}
        overlay={ <BootstrapTooltip id={props.text} placement={props.placement ?? "top"}>{props.text} </BootstrapTooltip> }
        placement={props.placement ?? "top"}
        children={props.children}
    />;
}
