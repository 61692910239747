/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the user resource.
import { User } from "@andromeda/resources";

// Import the context.
import { UserCreatorContext, UserCreationRejection } from "./context";
// Import the subcomponents.
import { Header } from "./header";
import { Commit } from "./commit";

// Import the css.
import css from "./user-creator.module.scss";

/** Component used to render the element that can be used to create one or more new user(s). */
export function UserCreator(): React.ReactElement {
    // Store all the staged requests.
    const [staged, setStaged] = React.useState<
        Array<User.Create.Attributes & UserCreationRejection>
    >([]);

    // Prepare the context value.
    const updateStaged = React.useCallback(
        function updateStagedUserRequest(
            index: number,
            attributes: Partial<User.Create.Attributes>
        ): void {
            if (typeof attributes.email === "string")
                attributes.email = attributes.email.trim();
            if (typeof attributes.username === "string")
                attributes.username = attributes.username.trim();
            if (typeof attributes.givenName === "string")
                attributes.givenName = attributes.givenName.trim();

            if (index >= staged.length) {
                if (typeof attributes.email !== "string") {
                    throw new Error(
                        "Cannot create a user request without an email address !"
                    );
                }
                staged.push(
                    attributes as Partial<User.Create.Attributes> &
                        Required<Pick<User.Create.Attributes, "email">>
                );
            } else {
                staged.splice(index, 1, {
                    ...staged[index],
                    ...attributes,
                    rejectionReason: undefined,
                });
            }
            setStaged([...staged]);
        },
        [staged]
    );
    const pushStaged = React.useCallback(
        function pushStagedUserRequest(
            attributes: User.Create.Attributes
        ): void {
            updateStaged(staged.length, attributes);
        },
        [staged.length, updateStaged]
    );
    const removeStaged = React.useCallback(
        function removeStagedUserRequest(index: number): void {
            if (index >= staged.length) return;
            staged.splice(index, 1);
            setStaged([...staged]);
        },
        [staged]
    );

    // Store the body of the element.
    const [body, setBody] = React.useState<React.ReactElement | null>(null);

    // Render the component.
    return (
        <UserCreatorContext.Provider
            value={{
                staged,
                updateStaged,
                pushStaged,
                removeStaged,
                setStaged,
            }}
        >
            <section className={`${css["user-creator"]} mt-3`}>
                <Header updateBody={setBody} />
                <div className={css["user-creator__body"]} children={body} />
                <Commit />
            </section>
        </UserCreatorContext.Provider>
    );
}
