/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the css.
import css from "./header.module.scss";


/** Component used to render the header of the training status. */
export default function TrainingStatusHeader(props: TrainingStatusHeaderProps): ReactElement {
    return <div className={classNames(css["header"], props.className)}>
        <span className={css["title"]}>{props.showUsername ? "Utilisateur" : "Formation"}</span>
        <span className={css["due-date"]}>Échéance</span>
        <span className={css["expected"]}>Niveau Attendu</span>
        <span className={css["achieved"]}>Niveau Atteint</span>
        <span className={css["level"]}>Niveau Attendu&nbsp;&nbsp;Atteint</span>
        <span className={css["state"]}>État</span>
    </div>;
}

/** Props passed down to the {@link TrainingStatusHeader} component. */
export interface TrainingStatusHeaderProps {
    /** Flag used to specify that the items are showing usernames. */
    showUsername?: boolean;
    /** Optional class name added to the root <div> element. */
    className?: string;
}
