/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the interfaces.
import {
    Filter,
    PropertyFilter,
    AnyOperator,
    Operators,
    PropertyDelimiter,
    OperatorDelimiter,
    CombinationOperators
} from "./interface";

// Import the logging tool.
import debug from "debug";
const log = debug("json-api:services:filtering:encoder");


/**
 * Encodes the provided filter into a url-safe parameter.
 *
 * @param {Filter} filter The filter to encode.
 * @returns {string} The encoded filter.
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export function encodeFilter(filter: Filter): string {
    log("Encoding a new filter");
    // List of all the computed filters.
    const filters: string[] = encodeProperty(filter);

    // Encode the list of filters.
    const encoded = encodeURIComponent(filters.join(";"));
    if (encoded.length > 1024) {
        throw new Error("Filter is too long and will break");
    }
    return encoded;
}

/** Helper method used to recursively encode a property. */
function encodeProperty(property: PropertyFilter | AnyOperator, path: string[] = []): string[] {
    log("Encoding a new property for the filter at path \"%s\"", path.join("."));
    // Prepare the list of generated filters.
    const filters: string[] = [];

    // Helper method used to encode a value.
    function encode(value: unknown): string {
        if (typeof value === "string") {
            return `"${encodeURIComponent(value)}"`;
        } else if (typeof value === "number") {
            return value.toString(10);
        } else {
            return `"${encodeURIComponent(String(value))}"`;
        }
    }

    // Loop through the entries of the property.
    for (const [ key, value ] of Object.entries(property)) {
        // If the key is an operator.
        if (Object.values(Operators).includes(key as Operators)) {
            log("Pushing an operator \"%s\"", key);
            // Encode the operator.
            let encodedValue: string;
            if (Array.isArray(value)) {
                if (value.length <= 0) continue;
                encodedValue = value.map(encode).join(",");
            } else {
                encodedValue = encode(value);
            }

            filters.push(`${path.join(".")}${PropertyDelimiter}${key}${OperatorDelimiter}${encodedValue}`);
        // Handle the combination operators.
        } else if (Object.values(CombinationOperators).includes(key as CombinationOperators)) {
            // Ensure that the value is an array.
            if (!Array.isArray(value)) throw new Error("Combination operators MUST be arrays !");

            // Encode all the items separately.
            for (const item of value as PropertyFilter[]) {
                filters.push(...encodeProperty(item, [ ...path, key ]));
            }
        } else {
            // Recurse.
            filters.push(...encodeProperty(value, [ ...path, key ]));
        }
    }

    return filters;
}
