/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement } from "react";
// Import React-Router.
import { useParams } from "react-router-dom";

// Import the iframe css.
import css from "./player/player.module.scss";


/** Simple component used to render an editor iframe. */
export default function EditorRouter(): ReactElement {
    const { tuto } = useParams<"tuto">();

    return <iframe
        title="Éditeur de ZaqTuto"
        className={css["iframe-container"]}
        allowFullScreen
        src={`/editor/${tuto}`}
        allow={"fullscreen"}
    />;
}
