/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useCallback, useMemo, useState } from "react";

// Import the context.
import { CardContainerContext } from "./context";
import { CardProgression } from "../progression";

// Import the css.
import css from "./index.module.scss";


export default function CardContainer(props: CardContainerProps): ReactElement {
    // Store the counts.
    const [count, setCount] = useState(0);
    const [current, setCurrent] = useState(Number.NaN);

    // Generate the registering callback.
    const register = useCallback(function register(callback: (index: number) => void): void {
        setCount((previousCount: number): number => {
            // Invoke the callback.
            callback(previousCount);

            // Increment the previous count value.
            previousCount++;
            setCurrent((current: number): number => {
                // If the value is NaN, return a 0.
                return isNaN(current) ? 0 : current;
            });

            return previousCount;
        });
    }, []);

    // Generate the unregistering callback.
    const unregister = useCallback(function unregister(): void {
        setCount((previousCount: number): number => {
            // Decrement the previous count value.
            previousCount--;

            // Clamp the value to 0.
            if (previousCount < 0) {
                previousCount = 0;
                setCurrent(Number.NaN);
            }
            return previousCount;
        });
    }, []);

    // Generate the select callback.
    const select = useCallback(function select(value: number): void {
        setCurrent((): number => {
            // If there are no cards, return a NaN.
            if (count <= 0) {
                return Number.NaN;
            }

            // Wrap the value.
            if (value < 0) {
                return count + value;
            } else if (value >= count) {
                return value - count;
            } else {
                return value;
            }
        });
    }, [count]);

    // Build the container class name.
    const className = useMemo(function buildContainerClassName(): string {
        let className = css["container"];
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }, [props.className]);

    // Render the component.
    return <CardContainerContext.Provider value={{ current, count, register, unregister, select }}>
        <div className={className}>
            {props.children}
            <CardProgression />
        </div>
    </CardContainerContext.Provider>;
}

/** Props passed down to the {@link CardContainer} component. */
export interface CardContainerProps {
    /** Elements that will have access to the {@link CardContainerContext}. */
    children?: ReactNode;

    /** CSS class name that will be applied to the container div. */
    className?: string;
}

export { CardContainerContext };
