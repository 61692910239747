/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the bootstrap components.
import { Modal } from "react-bootstrap";
// Import the common components.
import { useConfirm, ConfirmBodyProps, Loader } from "@andromeda/components";
// Import the file resource.
import { ZaqWikiFile, ZaqWiki, ZaqWikiTutoFile } from "@andromeda/resources";
// Import the stores.
import { useResourceDispatch, ZaqWikiFileStore, ZaqWikiStore } from "@andromeda/store";

// Import the reader context.
import { ReaderContext } from "../../context";
// Import the search bar.
import { ZaqTutoSearchBarSource, ZaqTutoSearchBar } from "./search-bar";
// Import the category container.
import { Category } from "./category";

// Import the icons.
import close from "@andromeda/assets/images/close.svg";
import check from "@andromeda/assets/images/check.svg";
// Import the css.
import css from "./notice-popup.module.scss";
import { FullLegacyZaq, useLegacyZaqFromOrganisation, withLegacyZaqReducer } from "@andromeda/legacy-zaq";
import { ReactElement, useCallback, useContext, useMemo, useState } from "react";
import { LoginContext } from "@andromeda/login";


/** Wrapper for the {@link useConfirm} hook that generates a new notice. */
export function useTutoConfirm(): () => Promise<ZaqWikiFile | undefined> {
    const callback = useConfirm({ body: TutoModalBody, title: "Ajouter un ZaqTuto", centered: true, size: "lg" });
    return callback as () => Promise<ZaqWikiFile | undefined>;
}

/** Body of the {@link useTutoConfirm} helper. */
const TutoModalBody = withLegacyZaqReducer(TutoModalBodyComponent);

/** @see TutoModalBody */
function TutoModalBodyComponent(props: ConfirmBodyProps<ZaqWikiFile | undefined>): ReactElement {
    // Stores the selected tuto id.
    const [selected, setSelected] = useState<FullLegacyZaq>();

    // Load all the available tuto.
    const organisation = useContext(LoginContext).organisations.current.id;
    const tuto = useLegacyZaqFromOrganisation(organisation);
    // Extract all the tags from the tuto.
    const tags = useMemo(function loadTags(): Set<string> {
        const tags = new Set<string>();

        // Wait for the tuto to be loaded.
        if (tuto.isSuccess) {
            // Add the special "empty" tag.
            tags.add("__empty");

            // Add all the tags to the set.
            for (const item of tuto.data) {
                item.tags.forEach(tag => tags.add(tag));
            }
        }

        return tags;
    }, [tuto.data, tuto.isSuccess]);

    // Convert all the notices to searchable items.
    const source = useMemo(function generateSources(): ZaqTutoSearchBarSource[] {
        // Wait for the tuto to be loaded.
        if (!tuto.isSuccess) {
            return [];
        }

        // Render all the tuto.
        return tuto.data.map(function extractSourceFromTuto(tuto: FullLegacyZaq): ZaqTutoSearchBarSource {
            return {
                text: [tuto.name, ...tuto.tags],
                key: tuto.id,
                data: tuto
            };
        });
    }, [tuto.data, tuto.isSuccess]);

    // Render all the categories.
    const categories = useMemo(function renderCategories(): ReactElement[] {
        const categories: ReactElement[] = [];
        tags.forEach(function renderTagCategory(tag: string): void {
            categories.push(<Category key={tag} selected={selected} category={tag} onSelected={setSelected} />);
        });
        return categories;
    }, [selected, tags]);

    // Render the body of the modal.
    const body = useMemo(function renderBody(): ReactElement {
        // Render a loader while the data is loading.
        if (!tuto.isSuccess) {
            return <Loader text="Chargement des ZaqTuto ..." />;
        }

        // Return the list and search bar input.
        return <>
            <div className={css["notice__list-container"]} children={categories} />
            <ZaqTutoSearchBar.Input />
        </>;
    }, [categories, tuto.isSuccess]);

    // Creates a new tuto module.
    const [loading, setLoading] = useState(false);
    const dispatch = useResourceDispatch();
    const id = useContext(ReaderContext).wiki.id;
    const generate = useCallback(
        function generateZaqTutoModule(tuto?: FullLegacyZaq): void {
            // If no tuto is loaded, show an error.
            if (!tuto) {
                return window.alert("Nothing selected !");
            }
            setLoading(true);

            // Prepare the generated file.
            const file: ZaqWikiFile.Create = {
                type: ZaqWikiFile.Type,
                attributes: { name: tuto.name, zaqTutoId: tuto.id },
                relationships: { wiki: { data: { type: ZaqWiki.Type, id } } }
            };

            // Create the file in the store.
            dispatch(ZaqWikiFileStore.generator.create(file))
                .then(file => props.resolve(file as ZaqWikiTutoFile))
                .then(() => dispatch(ZaqWikiStore.generator.readOne(id)));
        },
        [dispatch, id, props]
    );

    // Render the component.
    if (loading) {
        return <Loader asBlock text="Création de votre module ..." />;
    }
    return (
        <Modal.Body className={css["notice__body"]}>
            <ZaqTutoSearchBar.Provider source={source} onSelected={source => setSelected(source.data)}>
                <div className={css["notice__container"]} children={body} />
            </ZaqTutoSearchBar.Provider>
            <div className={css["notice__button-container"]}>
                <button
                    onClick={() => props.resolve()}
                    className={`${css["notice__button"]} ${css["notice__button--cancel"]}`}
                >
                    <img src={close} alt="close" />
                    <p>annuler</p>
                </button>
                <button
                    disabled={typeof selected === "undefined"}
                    onClick={() => generate(selected)}
                    className={`${css["notice__button"]} ${css["notice__button--confirm"]}`}
                >
                    <img src={check} alt="select" />
                    <p>valider</p>
                </button>
            </div>
        </Modal.Body>
    );
}
