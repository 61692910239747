/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ComponentType, useCallback, useMemo } from "react";
// Import React-Redux.
import { useStore } from "react-redux";
// Import Redux.
import type { AnyAction, Store } from "redux";
// Import Redux-Thunk.
import type { ThunkDispatch } from "redux-thunk";
// Import common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the reducer helper.
import { withReducer } from "../reducer";
// Import the selector hook.
import { useSelector } from "../../hooks";
// Import the reducer.
import { requestReducer, RequestReducerState } from "./reducer";


// Re-export the reducer.
export { requestReducer } from "./reducer";
// Re-export the state.
export type { RequestReducerState } from "./reducer";
// Re-export the actions.
export type { RequestActions, ClearRequestAction, UpdateRequestAction } from "./action";

/** Name of the request store. */
export const RequestReducerKey = "request-store";

/**
 * Wraps a component with the request store.
 *
 * @template {object} P
 * @param {React.ComponentType<P>} component The component to wrap.
 * @return {React.ComponentType<P>} The wrapped component.
 */
export function withRequestStore<P extends object>(component: ComponentType<P>): ComponentType<P> {
    return withReducer(component, RequestReducerKey, requestReducer);
}

/**
 * Wrapper hook used to select a request from the store.
 *
 * @param {string} id The id of the request.
 * @return {RequestStatus} The status of the request.
 */
export function useRequestSelector(id: string): RequestStatus {
    // Wrap the selector to return a "RequestStatus.uninitialised" instead of an "undefined".
    const wrappedSelector = useCallback(
        function returnUninitialisedIfUndefined(state: RequestReducerState): RequestStatus {
            const result = state.requests[id];
            if (typeof result === "undefined") {
                return RequestStatus.uninitialised();
            }
            return result;
        },
        [id]
    );

    return useSelector(RequestReducerKey, wrappedSelector);
}

/** Dispatch returned by {@link useRequestDispatch}. */
type RequestDispatch = (
    dispatch: (request?: RequestStatus) => void,
    getState: () => RequestStatus
) => void | Promise<void>;

/**
 * Wrapper for {@link useDispatch}.
 *
 * @param {string} id The id of the request.
 * @return { ((request: RequestStatus) => void) | (() => void)} A helper to clear/update requests.
 */
export function useRequestDispatch(id: string): (dispatch: RequestDispatch) => void {
    const store = useStore();
    return useMemo(() => createRequestDispatch(store, id), [store, id]);
}

/**
 * Helper function used to create a {@link RequestDispatch} wrapper.
 *
 * @param {Store} store The store to bind to.
 * @param {string} id The identifier of the request.
 * @returns {(dispatch: RequestDispatch) => void} The wrapped {@link RequestDispatch} tool.
 */
export function createRequestDispatch(store: Store, id: string): (dispatch: RequestDispatch) => void {
    // Get the dispatch from the store.
    const dispatch = store.dispatch as ThunkDispatch<unknown, unknown, AnyAction>;
    const getState = store.getState;

    // Return the wrapper.
    return function dispatchWrapper(wrapped: RequestDispatch): void {
        dispatch(function dispatchRequest(dispatch: ThunkDispatch<unknown, unknown, AnyAction>): void {
            const promise = wrapped(
                function dispatchWrapper(request?: RequestStatus): void {
                    if (typeof request === "undefined") {
                        dispatch({ type: "requests/clear", id });
                    } else {
                        dispatch({ type: "requests/update", id, request });
                    }
                },
                function getStateWrapper(): RequestStatus {
                    return getState()[RequestReducerKey].requests[id] ?? RequestStatus.uninitialised();
                }
            );

            // If the returned value is a promise.
            if (typeof promise === "object" && "then" in promise) {
                // Catch any error.
                promise.catch(error => dispatch({
                    type: "requests/update",
                    id,
                    request: RequestStatus.error(error)
                }));
            }
        });
    }
}
