/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the logger.
import debug from "debug";
const log = debug("config:services:parser:hierarchy");


/** Enumeration of all the available environment types. */
export enum Environment { development, test, production}

/** Namespace used to group all the environment methods. */
export namespace Environment {
    /** Stores the currently active environment. */
    export const current = getEnvironment();
}

/**
 * @param {Environment} [environment=current] The environment to translate into a string.
 * @returns {string} The name of the specified environment.
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export function stringifyEnvironment(environment: Environment = Environment.current): string {
    switch (environment) {
    case Environment.development: return "development";
    case Environment.test       : return "test";
    case Environment.production : return "production";
    }
}

/**
 * Computes the currently active environment.
 *
 * @returns {Environment} The current environment of the application.
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 1
 */
function getEnvironment(): Environment {
    let environment: undefined | string | Environment;
    // Check the defined variable.
    try { environment = ENVIRONMENT; } catch { /* Ignore errors. */ }
    // Check the environment variable.
    if (!environment) try { environment = process.env["ENVIRONMENT"]; } catch { /* Ignore errors. */ }

    if (!environment) {
        try {
            // Check if a cli argument was provided.
            for (let i = 0; i < process.argv.length; i++) {
                if (process.argv[i] === "-e" || process.argv[i] === "--environment") {
                    // Get the next argument.
                    environment = process.argv[i + 1];
                } else if (process.argv[i].startsWith("--environment=")) {
                    // Get the environment value.
                    environment = process.argv[i].slice("--environment=".length);
                }
            }
        } catch { /* Ignore errors. */ }
    }
    if (typeof environment === "string") environment = environment.toLowerCase();

    // Validate the value of the environment variable.
    switch (environment) {
      // If the environment is a development environment.
    case "dev":
    case "development": environment = Environment.development; break;
      // If the environment is a testing environment.
    case "test":
    case "testing": environment = Environment.test; break;
      // If the environment is a production environment.
    case "prod":
    case "production": environment = Environment.production; break;

    // If the environment is null or undefined, log a warning.
    case null:
    case undefined:
        if (!isSilent()) {
            console.warn(`No environment found, defaulting to "${stringifyEnvironment(Environment.production)}".`);
        }
        environment = Environment.production; break;
    // If the environment is invalid, log a warning.
    default:
        if (!isSilent()) {
            console.warn(`Invalid environment type "${environment}". Defaulting to "${stringifyEnvironment(Environment.production)}".`);
        }
        environment = Environment.production; break;
    }
    // Print the selected environment.
    if (!isSilent()) {
        log(`Current environment: "${stringifyEnvironment(environment)}"`);
    }

    return environment;
}

/** Simple helper used to check if the config should be silent. */
function isSilent(): boolean {
    // Check if the SILENT global is set.
    try { return SILENT ?? false; } catch { /* Ignore errors. */ }
    // Check if the silent argument is set.
    try { return process.argv.includes("--silent"); } catch { /* Ignore errors. */ }

    // Return a false by default.
    return false;
}

// Current environment webpack definition.
declare const ENVIRONMENT: string | undefined;
// Silenced global definition.
declare const SILENT: boolean;
