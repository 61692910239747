/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { createContext } from "react";
// Import the context noop tool.
import { makeContextNoop } from "@andromeda/tools";

// Import the search bar item.
import type { SearchBarCategory, SearchResultItem } from "./interface";


// Re-export the provider.
export { SearchBarContextProvider } from "./provider";

// Re-export the interfaces.
export type {
    SearchBarCategory,
    SearchResultItem,
    SearchBarItemRenderer,
    SearchBarItemRendererProps,
    SearchBarItemWithoutData
} from "./interface";

/** Interface used to define how the search bar context should behave. */
export interface SearchBarContext<T = void> {
    /** List of all the items rendered in this search bar. */
    readonly items: ReadonlyArray<SearchResultItem<T>>;

    /** Current value stored in the search bar. */
    readonly text: string;
    /** Normalised version of the {@link text} value. */
    readonly textNorm: string;

    /** List of all the categories with at least one match. */
    readonly matchedCategories: SearchBarCategory<T>[];
    /** List of all the items that matched the search. */
    readonly matches: ReadonlyArray<SearchResultItem<T>>;

    /**
     * Updates the text field of the context.
     *
     * @param {string} value The new value of the text.
     */
    setText(value: string): void;
}

/** Augment the {@link SearchBarContext}. */
export namespace SearchBarContext {
    /** Default value used for the search bar context. */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    export const DEFAULT_VALUE: SearchBarContext<any> = {
            text: "", textNorm: "", matches: [], matchedCategories: [], items: [],
            setText: makeContextNoop("SearchBarContext", "setText")
        };

    /** Default search bar context used when not overriden by a custom context. */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    export const DefaultSearchBarContext = createContext<SearchBarContext<any>>(DEFAULT_VALUE);
}
