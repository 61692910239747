/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API module.
import type { Filter } from "@andromeda/json-api";
// Import the course resource.
import { Course } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the store.
import { CourseStore, ResourceStore } from "../../stores";
// Import the resource hooks.
import useResources from "./util/use-resources";
import useResource from "./util/use-resource";


/**
 * Hook used to download a course from the store.
 *
 * @param {string | undefined} id The identifier of the requested course.
 * @param {boolean} [cache=true] Try to read from the cache if possible.
 * @param {boolean} [includeAsset=false] If true, loads the icon of the training.
 * @return {RequestStatus<Course>} The status of the request.
 */
export function useCourse(id: string | undefined, cache = true, includeAsset = false): RequestStatus<Course> {
    // Add the include-asset parameter if needed.
    const searchParams: Record<string, string> = {};
    if (includeAsset) {
        searchParams["include-asset"] = "true";
    }

    // Wrap the useResource hook.
    return useResource(CourseStore, Course.Type, id, cache, true, { searchParams });
}

/**
 * Downloads all the course of the specified organisation.
 *
 * @param {string} organisation The organisation to load the courses for.
 * @return {RequestStatus<Course[]>} The status of the request.
 */
export function useCourses(organisation: string): RequestStatus<ReadonlyArray<Course>> {
    // Prepare the filter used for the request.
    const filter: Filter = {
        [Course.Type]: {
            $or: [
                { readerOrganisations: { $eq: organisation } },
                { editorOrganisations: { $eq: organisation } },
                { ownerOrganisation: { $eq: organisation } }
            ]
        }
    };

    // Prepare the selector used to load the courses.
    const selector = function selector(state: ResourceStore<Course>): Course[] {
        return state.resources.filter(function courseBelongsToOrg(course: Course): boolean {
            if (course.relationships.readerOrganisations.data.some(({ id }) => id === organisation)) {
                return true;
            }
            if (course.relationships.editorOrganisations.data.some(({ id }) => id === organisation)) {
                return true;
            }
            return course.relationships.ownerOrganisation.data.id === organisation;
        });
    };

    // Return the resources.
    return useResources(CourseStore, Course.Type, { filter, searchParams: { "include-asset": "true" } }, selector);
}
