/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { useContext, useEffect, useMemo, useState } from "react";
// Import RxJS.
import { BehaviorSubject } from "rxjs";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the resources.
import { Asset, ZaqWikiFile } from "@andromeda/resources";
// Import the web asset tools.
import {
    ConversionInfo,
    ConversionStatusContext,
    downloadWebAsset,
    useAssetResource as useWebAssetResource
} from "@andromeda/asset-manager";
// Import the custom components.
import { useNotify } from "@andromeda/components";


/** Return type of the {@link useAsset} hook. */
export interface LoadedAsset {
    /** File object that was downloaded from the server. */
    file: File;
    /** URL used to load the object from memory. */
    url: string;
}

/**
 * Hook used to download the asset from a given {@link ZaqWikiFile} and build a URL for it.
 *
 * @param {ZaqWikiFile} file The file that was downloaded from the server.
 * @returns {RequestStatus<LoadedAsset>} The status of the request.
 */
export function useAsset(file: ZaqWikiFile): RequestStatus<LoadedAsset> {
    // Load the notification tool.
    const { error } = useNotify();

    // Store the status of the request.
    const [status, setStatus] = useState<RequestStatus<LoadedAsset>>(RequestStatus.uninitialised());
    const [asset, setAsset] = useState<Promise<File>>();

    // Extract the id from the file.
    const assetId = useAssetId(file);

    // Extract the file name from the file.
    const filename = useMemo(function getFilenameFromFile(): string {
        // If the file is not an asset, return an empty string.
        if (!ZaqWikiFile.isAssetFile(file)) {
            return "";
        }

        return file.attributes.name.replace(/[/.?<>\\:*|]/g, "");
    }, [file]);

    // Download the asset from the server when its identifier is set.
    useEffect(function downloadAsset(): void | VoidFunction {
        // If the id is undefined, do nothing.
        if (typeof assetId === "undefined") {
            return setAsset(undefined);
        }

        // Start downloading the asset.
        setAsset((async function downloadFile(id: string): Promise<File> {
            // Download the asset from the server.
            const asset = await downloadWebAsset(id);

            // Create a new file from the asset.
            return new File([asset.data], filename, { type: asset.resource.attributes.contentType });
        })(assetId));
    }, [assetId, error, filename]);

    // Generate the object URL for the file.
    useEffect(function buildURLFromFile(): void | VoidFunction {
        // Check if the file promise is set.
        if (typeof asset === "undefined") {
            return setStatus(RequestStatus.uninitialised());
        }
        setStatus(RequestStatus.loading());

        // Wait for the file promise to finish.
        let wasInterrupted = false;
        const urlPromise = asset.then(
            function onAssetReady(file: File): RequestStatus<LoadedAsset> {
                return RequestStatus.success({ file, url: URL.createObjectURL(file) });
            },
            function onLoadingError(error: unknown): RequestStatus<LoadedAsset> {
                return RequestStatus.error(error);
            }
        ).then(function updateRequestStatus(status: RequestStatus<LoadedAsset>): string | undefined {
            // If the request was not interrupted, update the status of the request.
            if (!wasInterrupted) {
                setStatus(status);
            }

            // Return the url for cleanup.
            return status.data?.url
        });

        // Return a cleanup callback.
        return function cleanupFileURL(): void {
            // Stop any running request.
            wasInterrupted = true;

            // Clear the status.
            setStatus(RequestStatus.uninitialised());

            // Clear the url once the promise completes.
            urlPromise.then(url => url && URL.revokeObjectURL(url));
        }
    }, [asset]);

    // Return the state of the request.
    return status;
}

/**
 * Helper hook used to load the asset resource from the redux store.
 *
 * @param {ZaqWikiFile} file The file to get the asset from.
 * @return {Asset | undefined} The asset for the file, or undefined if no such asset exists.
 */
export function useAssetResource(file: ZaqWikiFile): Asset | undefined {
    return useWebAssetResource(useAssetId(file)).data;
}

/**
 * Helper hook used to extract the asset conversion from a given file.
 *
 * @param {ZaqWikiFile} file The file to get the conversion status from.
 * @return {BehaviorSubject<ConversionInfo> | undefined}
 * A {@link BehaviorSubject} that allows following the conversion status.
 */
export function useAssetConversionSubject(file: ZaqWikiFile): BehaviorSubject<ConversionInfo> | undefined {
    const conversionStatusContext = useContext(ConversionStatusContext);
    return useMemo(function findAssetConversionFromFile(): BehaviorSubject<ConversionInfo> | undefined {
        if (!ZaqWikiFile.isAssetFile(file)) {
            return undefined;
        }
        return conversionStatusContext.conversions.get(file.relationships.asset.data.id);
    }, [conversionStatusContext.conversions, file]);
}

/** Helper hook used to extract the asset if from a {@link ZaqWikiFile}. */
function useAssetId(file: ZaqWikiFile): string | undefined {
    return useMemo(function extractAssetIdFromFile(): string | undefined {
        if (!ZaqWikiFile.isAssetFile(file)) {
            return undefined;
        }
        return file.relationships.asset.data.id;
    }, [file]);
}
