/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */


/** Error thrown if a target was not found within the configuration file. */
export class NoSuchTargetError extends Error {
    public constructor(target: string) {
        super(`The requested target "${target}" was not found in the configuration file !`);
    }
}
