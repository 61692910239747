/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { useState, ReactElement, MouseEvent, useCallback } from "react";
// Import RxJS
import { Subject } from "rxjs";
// Import the custom components.
import { ConfirmProvider, useOutsideClick } from "@andromeda/components";

// Import the subcomponents.
import {
    NoticeIcon,
    LinkIcon,
    ImageIcon,
    MobileVideoIcon,
    SoundIcon,
    TextIcon,
    PdfIcon,
    CaptureIcon, ClipboardIcon, FileIcon, SectionIcon, DesktopVideoIcon
} from "./icons";
import { ActionMenuContext } from "./context";

// Import the add icon.
import add from "@andromeda/assets/images/add.svg";
// Import the css.
import css from "./generator.module.scss";


/**
 * Function-component used to render the generator row.
 * This row is used to add new files to the current wiki object.
 */
export function Generator(): ReactElement | null {
    // Store the opened state of the menu.
    const [ opened, setOpened ] = useState(false);
    const outsideRef = useOutsideClick<HTMLDivElement>(() => setOpened(false));
    const [ observable ] = useState(new Subject<MouseEvent>());

    // Attach the click handler.
    const onClick = useCallback(function handleOnClick(event: MouseEvent): void {
        // Invoke the observable if the menu was just opened.
        setOpened(!opened);
        if (!opened) { observable.next(event); }
    }, [observable, opened]);

    // Render the component.
    return <ActionMenuContext.Provider value={{ close: () => setOpened(false), onOpenClick: observable }}>
        <div
            ref={outsideRef}
            className={`${css["create-menu"]} ${opened ? css["create-menu--open"] : css["create-menu--closed"]}`}
        >
            <button className={css["create-menu__anchor"]} onClick={onClick}>
                <img src={add} alt="open-menu" />
            </button>
            <div className={css["create-menu__list"]}>
                <ConfirmProvider>
                    <ClipboardIcon />
                    <FileIcon />
                    <ImageIcon />
                    <DesktopVideoIcon />
                    <MobileVideoIcon />
                    <SoundIcon />
                    <CaptureIcon />
                    <TextIcon />
                    <SectionIcon />
                    <PdfIcon />
                    <NoticeIcon />
                    <LinkIcon />
                </ConfirmProvider>
            </div>
        </div>
    </ActionMenuContext.Provider>;
}
