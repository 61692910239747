/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";

// Import the status info interface.
import { ConversionStatus, ConversionInfo } from "../../lib";

// Import the icons.
import questionMark from "@andromeda/assets/images/question-mark-blue.svg";
import waiting from "@andromeda/assets/images/hourglass.svg";
import arrow from "@andromeda/assets/images/down-arrow.svg";
import loader from "@andromeda/assets/images/loading.svg";
import check from "@andromeda/assets/images/checkmark.svg";
// Import the css.
import css from "./icon.module.scss";


/** Component used to render the loading icon of a conversion. */
export function LoadingIcon(props: LoadingIconProps): React.ReactElement {
    // Get the corresponding icon.
    const icon = React.useMemo(function loadCorrectIcon(): string {
        switch (props.status.status) {
        case ConversionStatus.preparing:
        case ConversionStatus.queued: return waiting;
        case ConversionStatus.converting:
        case ConversionStatus.storing: return loader;
        case ConversionStatus.downloading:
        case ConversionStatus.uploading: return arrow;
        case ConversionStatus.done: return check;
        default: return questionMark;
        }
    }, [ props.status.status ]);

    // Load the class name of the element.
    const className = React.useMemo(function computeClassName(): string {
        let className = css["loading-icon"];
        switch (props.status.status) {
        case ConversionStatus.preparing:     className += ` ${css["loading-icon--preparing"]}`; break;
        case ConversionStatus.queued:        className += ` ${css["loading-icon--queued"]}`; break;
        case ConversionStatus.converting:    className += ` ${css["loading-icon--converting"]}`; break;
        case ConversionStatus.uploading:     className += ` ${css["loading-icon--uploading"]}`; break;
        case ConversionStatus.downloading:   className += ` ${css["loading-icon--downloading"]}`; break;
        case ConversionStatus.storing:       className += ` ${css["loading-icon--storing"]}`; break;
        case ConversionStatus.done:          className += ` ${css["loading-icon--done"]}`; break;
        }
        if (props.className) className += ` ${props.className}`;
        return className;
    }, [props.className, props.status.status]);

    // Render the component.
    return <span className={className}>
        <img src={icon} alt="current-status-icon" />
    </span>;
}

/** Props passed down to the {@link LoadingBar} component. */
interface LoadingIconProps {
    /** Status of the current conversion. */
    status: ConversionInfo;

    /** Additional clas name applied to the element. */
    className?: string;
}
