/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Re-export the schemas.
import * as ResourceSchema from "./resource.json";
import * as UpdateSchema from "./update.json";
import * as CreateSchema from "./create.json";
export const SessionSchema = {
    ResourceSchema,
    UpdateSchema,
    CreateSchema,
};
