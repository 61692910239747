/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useMemo } from "react";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the custom components.
import { SaveIndicator } from "@andromeda/components";

// Import the hooks.
import { ResultingItem, SearchableItemList } from "../../hooks";
// Import the item component.
import Item from "../item";

// Import the css.
import css from "./results.module.scss";
import { RequestStatus } from "@andromeda/tools";


/** Component used to render the results of the current search query. */
export default function SearchResults(props: SearchResultsProps): ReactElement {
    // Throw error statuses.
    if (props.results.isError) {
        throw props.results.error;
    }

    // Render the results items.
    const resultItems = useMemo(function renderResultItems(): ReactNode[] {
        // Wait for the search results to be available.
        if (!props.results.isSuccess) {
            return [];
        }

        // Render the items.
        return props.results.data.map(function renderSearchResult(result: ResultingItem): ReactNode {
            return <li className={css["item-container"]} key={`${result.resource.type}/${result.resource.id}`}>
                <Item resource={result.resource} withButtons />
            </li>;
        });
    }, [props.results.data, props.results.isSuccess]);

    // Get the progress value if applicable.
    const progressValue = useMemo(function getProgressValue(): number | undefined {
        // Check if a progress value was provided.
        if (!props.items.isLoading || typeof props.items.loadingMeta !== "number") {
            return undefined;
        }
        return props.items.loadingMeta;
    }, [props.items]);

    // If the items are still loading, show a loader.
    if (!props.results.isSuccess) {
        return <Loader containerClassName={css["loader"]} value={progressValue} text="Chargement des éléments ..." />;
    }
    // Render the results area.
    return <ul className={css["list"]}>
        {resultItems}
        <SaveIndicator
            show={!!props.items.data?.cached}
            title="Mise à jour de la liste ..."
            position="bottom-left"
            preventNavigation={false}
        />
    </ul>;
}

export interface SearchResultsProps {
    items: RequestStatus<SearchableItemList>;
    results: RequestStatus<ResultingItem[]>;
}
