/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API interfaces.
import { Resource, Relationship } from "@andromeda/json-api";
// Import the schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the resource helpers.
import { PartialResource, CreatableResource } from "@andromeda/resource-helper";

// Import the organisation resource.
import { Organisation } from "../organisation";
// Import the schema.
import { TagSchema } from "./schema";

/**
 * Interface used to represent a tag resource.
 * Tags can be used to group elements together and belong to one, and only one, organisation.
 */
export interface Tag extends Resource<Tag.Type> {
    /** @inheritDoc */
    attributes: Tag.Attributes;
    /** @inheritDoc */
    relationships: Tag.Relationships;
}

/** Namespace used to augment the {@link Tag} interface. */
export namespace Tag {
    /** Type of the tag object. */
    export const Type = "tag" as const;
    export type Type = typeof Type;

    /** Attributes of a tag. */
    export interface Attributes {
        /** Name of the tag. */
        name: string;
    }

    /** Relationships of a tag. */
    export interface Relationships {
        /** Organisation that owns this tag. */
        owner: Relationship<Organisation>;
    }

    /** Validator used to ensure that a tag is valid. */
    export const validate = compileSync<Tag>(TagSchema.ResourceSchema);

    /** Partial interface used to describe a tag update. */
    export type Update = Omit<PartialResource<Tag>, "relationships">;
    /** Namespace used to augment the {@link Update} interface. */
    export namespace Update {
        /** Validator used to ensure that a tag update is valid. */
        export const validate = compileSync<Tag>(TagSchema.UpdateSchema);
    }

    /** Interface used to describe a tag creation. */
    export type Create = CreatableResource<Tag>;

    export namespace Create {
        /** Validator used to ensure that a tag creation is valid. */
        export const validate = compileSync<Tag>(TagSchema.CreateSchema);
    }
}
