/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { MouseEvent, ReactElement, useCallback, useEffect, useState } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the custom components.
import { useNotify } from "@andromeda/components";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";

// Import the permission hooks.
import { useCanCreateZaqTraining, useCanCreateZaqTuto, useCanCreateZaqWiki } from "../../hooks";
// Import the element creation hook.
import { useCreateElement } from "./create";

// Import the icons.
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
// Import the css.
import css from "./index.module.scss";


/** Component used to render the creation menu on the screen. */
export default function Create(): ReactElement | null {
    // Check what the user can create.
    const canCreateZaqTuto = useCanCreateZaqTuto();
    const canCreateZaqWiki = useCanCreateZaqWiki();
    const canCreateZaqTraining = useCanCreateZaqTraining();

    // Store the opened state of the menu.
    const [isOpen, setIsOpen] = useState(false);

    // Callback invoked to close the menu when the backdrop is clicked.
    const closeOnBackdropClick = useCallback(function handleBackdropClick(event: MouseEvent<HTMLDivElement>): void {
        // Check if the backdrop was clicked directly.
        if (event.target !== event.currentTarget) {
            return;
        }

        // Close the menu.
        setIsOpen(false);
    }, []);

    // Get the hook used to create elements.
    const [create, promise] = useCreateElement();

    // Redirect/notify when the promise completes.
    const { error } = useNotify();
    useEffect(function logPromiseErrors(): void {
        promise?.then(url => window.location.assign(url)).catch(error);
    }, [error, promise]);

    // If the user cannot create anything, render nothing.
    if (!canCreateZaqTuto && !canCreateZaqWiki && !canCreateZaqTraining) {
        return null;
    }

    // Render a loader if the item is being created.
    if (promise !== null) {
        return <Loader text="Création en cours ..." containerClassName={css["loader"]} />;
    }

    // Get the classname of the container.
    const className = classNames(css["container"], { [css["container--open"]]: isOpen });
    return <div className={className} onClick={closeOnBackdropClick}>
        <button className={css["button"]} onClick={() => setIsOpen(prev => !prev)}>
            <FontAwesomeIcon className={css["button__icon"]} icon={faPlus} />
            {/*<span className={css["button__text"]}>Nouveau</span>*/}
        </button>
        <ul className={css["list"]}>
            <button
                role="listitem"
                className={css["item"]}
                disabled={!canCreateZaqTuto}
                onClick={create("zaq-tuto")}
            >
                <FontAwesomeIcon icon={faPlus} />
                ZaqTuto
            </button>
            <button
                role="listitem"
                className={css["item"]}
                disabled={!canCreateZaqWiki}
                onClick={create("zaq")}
            >
                <FontAwesomeIcon icon={faPlus} />
                ZaqWiki
            </button>
            <button
                role="listitem"
                className={css["item"]}
                disabled={!canCreateZaqTraining}
                onClick={create("zaq-training")}
            >
                <FontAwesomeIcon icon={faPlus} />
                ZaqTraining
            </button>
        </ul>
    </div>;
}
