/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";


/** Props passed down to the {@link ViewportDiv} function-component. */
interface Props {
    /** Children provided to the element. */
    children?: React.ReactNode;

    /** The identifier of the generated div. */
    id?: string;
    /** The name(s) of the class(es) to apply to the generated div. */
    className?: string;

    /** The style to apply to the generated div. */
    style?: Omit<React.CSSProperties, "width" | "height" | "maxWidth" | "maxHeight" | "minWidth" | "minHeight">;

    /** If true, apply the size to the "min-" properties. */
    min?: boolean;
    /** If true, apply the size to the "max-" properties. (Overrides the value of {@link min}). */
    max?: boolean;
}

// Alias for the size object.
type Size = { width: number, height: number };

/** Helper function used to extract the size of the {@link window}. */
function extractSize(): Size {
    return { width: window.innerWidth, height: window.innerHeight };
}

/** Helper function used to compute the size CSS property. */
function computeSizeProperties(size: Size, min: boolean | undefined, max: boolean | undefined): React.CSSProperties {
    if (max) { return { maxWidth: size.width + "px", maxHeight: size.height + "px" }; }
    if (min) { return { minWidth: size.width + "px", minHeight: size.height + "px" }; }
    return { width: size.width + "px", height: size.height + "px" };
}

/**
 * Simple component that is ALWAYS the size of the {@link window.innerHeight} and {@link window.innerWidth} in size.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.2
 * @version 1
 */
export function ViewportDiv(props: Props): React.ReactElement {
    // Store the size of the window.
    const [ size, setSize ] = React.useState<Size>(extractSize);
    // Memo-ify the callaback used by the effect.
    const resizeListener = React.useCallback(() => setSize(extractSize), [setSize]);
    // Re-compute the size when the window changes.
    React.useEffect(() => {
        // Attach the listener.
        window.addEventListener("resize", resizeListener, { passive: true });
        return () => window.removeEventListener("resize", resizeListener);
    }, [ resizeListener ]);

    // Store the properties on which the size is applied.
    const [ sizeStyle, setSizeStyle ] = React.useState<React.CSSProperties>();
    React.useEffect(
        () => setSizeStyle(computeSizeProperties(size, props.min, props.max)),
        [ size, props.min, props.max ]
    );

    // Render the div.
    return <div
        id={props.id}
        className={props.className}
        style={{ ...props.style, ...sizeStyle }}
        children={props.children}
    />;
}
