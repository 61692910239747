/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useCallback, useMemo } from "react";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";
// Import the resources
import type { Step } from "@andromeda/resources";

// Import the css.
import css from "./item-list.module.scss";


/** Helper type used to describe an item of the list. */
export interface Item {
    /** The type of the item. */
    type: Step.Item["type"];
    /** The id of the item. */
    id: string;
    /** The name of the item. */
    name: string;
    /** Additional controls rendered on the line. */
    controls?: ReactNode;
}

/** Component used to render a list of items. */
export default function ItemList(props: ItemListProps): ReactElement {
    // Generator callback used to scroll the selected item into view.
    const scrollWhenSelected = useCallback(function buildScrollRefCallback(item: ResourceIdentifier) {
        if (item.type !== props.selected?.type || item.id !== props.selected?.id) {
            return undefined;
        }

        return function scrollIfItemMatchesCurrent(ref: HTMLLIElement | null): void {
            ref?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [props.selected?.id, props.selected?.type]);

    // Memoize the list of items.
    const items = useMemo(function renderItems(): ReactElement[] {
        return props.items
            .sort(function compareItems({ name: a }: Item, { name: b }: Item): number {
                return a.localeCompare(b);
            })
            .map(function renderItem(item: Item): ReactElement {
                let className = css["item"];
                if (props.selected?.id === item.id && props.selected?.type === item.type) {
                    className += ` ${css["item--selected"]}`;
                }

                return <li
                    role="button"
                    onClick={() => props.select(item)}
                    className={className}
                    key={`${item.type}.${item.id}`}
                    ref={scrollWhenSelected(item)}
                >
                    <span className={css["item__title"]}>{item.name}</span>
                    {item.controls}
                </li>;
            });
    }, [props, scrollWhenSelected]);

    // Build the class name.
    let className = css["list"];
    if (props.className) {
        className += ` ${props.className}`;
    }

    // Render the component.
    return <ul children={items} className={className} />;
}

/** Props passed down to the {@link ItemList} component. */
export interface ItemListProps {
    /** Currently selected item. */
    selected: ResourceIdentifier | undefined;

    /** Callback invoked to select a new component. */
    select(item: ResourceIdentifier): void;

    /** List of items to render in the list. */
    items: Item[];

    /** Class name of the <ul> element. */
    className?: string;
}
