/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the metadata schema.
import MetadataSchema from "./meta";

/** Schema used to represent a single resource identifier. */
const Schema = {
    title: "Resource identifier",
    description: "Schema used to describe a single resource identifier.",
    properties: {
        type: { type: "string" },
        id: { type: "string" },
        meta: MetadataSchema
    },
    additionalProperties: false,
    required: [ "type", "id" ]
} as const;

// Export the schema.
export default Schema;
