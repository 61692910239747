/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the validation method.
import { validate } from "@andromeda/validation";
// Import the JSON:API message validator interface.
import { messageValidator, MessageHelper } from "@andromeda/json-api";

// Import the body type enumeration.
import { XhrBodyType } from "../..";
// Import the error message tool.
import { JsonApiErrorMessage } from "../../errors";

// Import the logging tool.
import debug from "debug";
const log = debug("xhr:services:response");


/**
 * Helper method used to decode a body object.
 *
 * @param {Buffer | ArrayBuffer} from The buffer to decode.
 * @param {string | null | undefined} type The mime type of the buffer data.
 * @returns {Promise<XhrBodyType | undefined>} A promise that resolves with the decoded data.
 */
export async function decodeBody(from: Buffer | ArrayBuffer, type: string | null | undefined): Promise<XhrBodyType | undefined> {
    // If the mime type is not text or application, return the raw buffer.
    if (typeof type === "undefined" || type === null || (!type.startsWith("application") && !type.startsWith("text"))) {
        log("Body is not a text-based object (%s), returning it as-is.", type);
        return from;
    }

    // Helper function used to convert a string to a JSON:API message helper.
    async function toMessageHelper(value: string): Promise<MessageHelper> {
        const message = JSON.parse(value);
        validate(message, await messageValidator, true);
        if (typeof message.errors !== "undefined") {
            throw new JsonApiErrorMessage(message.errors);
        }
        else if (typeof message.data === "undefined") {
            throw new Error("Meta-only JSON:API responses are not supported yet.");
        }

        log("Decoded a JSON:API message !");
        return new MessageHelper(message.data, message.included);
    }

    // Check if the data is a buffer or an array buffer.
    if (from instanceof ArrayBuffer) {
        log("Decoding from ArrayBuffer ...");
        // Check the size of the buffer.
        if (from.byteLength === 0) return undefined;

        // Conver the buffer to a string.
        const string = new TextDecoder().decode(new Uint8Array(from));
        return type === "application/vnd.api+json" ? toMessageHelper(string) : string;
    } else {
        log("Decoding from Buffer ...");
        // Check the size of the buffer.
        if (from.length === 0) return undefined;

        // Conver the buffer to a string.
        const string = from.toString("utf-8");
        return type === "application/vnd.api+json" ? toMessageHelper(string) : string;
    }
}
