/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


/** Union of all the target-able progress levels. */
export type TargetLevel = 1 | 2 | 3 | 4;

/** Union of all the achievable progress levels. */
export type Level = 0 | TargetLevel;

/** Interface used to describe a required step. */
export interface RequiredStep {
    /** Identifier of the required step. */
    id: string;
    /** Number of items found in this step. */
    length: number;
}

/** Object used to describe the progress obtained by a user for a given {@link ZaqTraining}. */
export interface TrainingProgress {
    /** Identifier of the {@link User} being parsed. */
    user: string;
    /** Identifier of the {@link ZaqTraining} being parsed. */
    training: string;
    /** The expected level for this user. */
    expected: TargetLevel;
    /** The achieved level for this user. */
    achieved: Level;
    /** The date at which the course is due. */
    due: Date | null;
    /** List of all the steps required for this training. */
    steps: RequiredStep[];
}

/** Object used to describe a training progress that is still running. */
export interface CurrentTrainingProgress extends TrainingProgress {
    /** Progress reached by the user in the course, in the interval [0, 1]. */
    progress: number;
    /** @see ValidatedTrainingProgress.validated */
    validated: false;
}

/** Object used to describe a training progress that was validated. */
export interface ValidatedTrainingProgress extends TrainingProgress {
    /** @see CurrentTrainingProgress.progress */
    progress?: undefined;
    /** Flag set if the progress was validated. */
    validated: true;
}

/** Union of the training progress types. */
export type AnyTrainingProgress = CurrentTrainingProgress | ValidatedTrainingProgress;
