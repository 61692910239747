/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useState, useCallback } from "react";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the custom components.
import { useNotify } from "@andromeda/components";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";

// Import the icons.
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons/faMicrophone";
import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons/faMicrophoneSlash";
// Import the css.
import css from "./preparation.module.scss";
import classNames from "classnames";


// Augment the global media constraints.
declare global {
    interface MediaTrackConstraints {
        /** The resizing mode of the video input. */
        resizeMode?: ConstrainDOMString;
    }
}

/** Component used to prepare the capture. */
export function Preparation(props: PreparationProps): ReactElement {
    // Get the notification tool.
    const { fatal } = useNotify();

    // State of the recorder preparation.
    const [isPreparingRecorder, setIsPreparingRecorder] = useState(false);
    const [withMicrophone, setWithMicrophone] = useState(false);

    // Memo used to generate the media recorder.
    const generateRecorder = useCallback(function generateMediaRecorder(): void {
        // Set the preparation flag.
        setIsPreparingRecorder(true);

        // Load the video feed.
        const promises: Promise<MediaStream>[] = [];
        if (props.source === "video") {
            // Check if the microphone was requested.
            let audioConstraints: MediaTrackConstraints | false = false;
            if (withMicrophone) {
                audioConstraints = {
                    suppressLocalAudioPlayback: { exact: true },
                    noiseSuppression: { ideal: true },
                    echoCancellation: { ideal: true },
                    autoGainControl: { ideal: true }
                };
            }

            // Build the stream constraints.
            const constraints: MediaStreamConstraints = {
                audio: audioConstraints,
                video: {
                    aspectRatio: { exact: 16 / 9 },
                    height: { ideal: 720, max: 1080 },
                    width: { ideal: 1280, max: 1920 },
                    frameRate: { ideal: 25, min: 20, max: 30 },
                    facingMode: { ideal: "environment" },
                    resizeMode: { ideal: "crop-and-scale" }
                }
            };
            promises.push(navigator.mediaDevices.getUserMedia(constraints));
        } else {
            // Build the display stream constraints.
            const constraints: DisplayMediaStreamOptions = {
                audio: false,
                video: {
                    height: { max: 1080 },
                    width: { max: 1920 },
                    frameRate: { ideal: 24 }
                }
            };
            promises.push(navigator.mediaDevices.getDisplayMedia(constraints));

            // If a microphone capture was requested.
            if (withMicrophone) {
                // Build the stream constraints.
                const constraints: MediaStreamConstraints = {
                    audio: {
                        suppressLocalAudioPlayback: true,
                        noiseSuppression: true,
                        echoCancellation: true,
                        autoGainControl: true
                    },
                    video: false
                };
                promises.push(navigator.mediaDevices.getUserMedia(constraints));
            }
        }


        // Merge the streams and start the recording.
        startRecorder().then(props.start).catch(fatal).finally(() => setIsPreparingRecorder(false));

        /**
         * Helper asynchronous function used to generate a {@link MediaRecorder} from the loaded stream source.
         *
         * @returns {Promise<MediaRecorder>} A promise that resolves with the generated recorder.
         */
        async function startRecorder(): Promise<MediaRecorder> {
            // Flatten the map of all the streams.
            const tracks: MediaStreamTrack[] = [];
            for (const stream of await Promise.all(promises)) {
                tracks.push(...stream.getTracks());
            }

            // Build the recorder.
            return new MediaRecorder(
                new MediaStream(tracks),
                { audioBitsPerSecond: 131_072, videoBitsPerSecond: 10_485_760 }
            );
        }
    }, [fatal, props.source, props.start, withMicrophone]);


    // Render the loader.
    if (isPreparingRecorder) {
        return <Loader
            containerClassName={css["loader"]}
            text="Préparation de votre enregistrement ..."
            transparent
        />;
    }

    // Render the component.
    return <div className={css["preparation"]}>
        <button className={css["button"]} onClick={generateRecorder}>
            <FontAwesomeIcon className={css["button__icon"]} icon={faPlay} />
            Débuter la capture
        </button>
        <button
            onClick={() => setWithMicrophone(prevState => !prevState)}
            className={css["option"]}
            aria-pressed={withMicrophone}
        >
            <FontAwesomeIcon
                className={classNames(css["option__icon"], css["option__icon--active"])}
                icon={faMicrophone}
            />
            <FontAwesomeIcon
                className={classNames(css["option__icon"], css["option__icon--inactive"])}
                icon={faMicrophoneSlash}
            />
        </button>
    </div>;
}

/** Props passed down to the {@link Preparation} component. */
interface PreparationProps {
    /** Starts a new recording session. */
    start(recorder: MediaRecorder): void;

    /** The feed that should be captured by the modal. */
    source: "screen" | "video";
}
