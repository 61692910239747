/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
// Import the browser router generator.
import { BrowserRouter } from "react-router-dom";
// Import the redux store provider.
import { Provider } from "react-redux";
// Import the theme context provider.
import { ThemeContextProvider } from "@andromeda/storybook";
// Import the notification provider.
import { NotificationProvider } from "@andromeda/components";

// Import the main router.
import MainRouter from "./router";
// Import the worker tools.
import "./worker";

// Import the store and its provider.
import { store } from "@andromeda/store";

// Render the app.
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <StrictMode>
        <Provider store={store}>
            <ThemeContextProvider>
                <NotificationProvider>
                    <BrowserRouter children={<MainRouter />} />
                </NotificationProvider>
            </ThemeContextProvider>
        </Provider>
    </StrictMode>
);
