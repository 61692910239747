/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the resources.
import { Requirement } from "@andromeda/resources";

// Import the level icon.
import LevelIcon from "../../../../level-icon";

// Import the icons.
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./level-select.module.scss";


/** Component used to render the level selector. */
export default function LevelSelect(props: LevelSelectProps): ReactElement {
    return <div className={css["level-select__container"]}>
        <select
            className={css["level-select"]}
            value={props.level}
            onChange={e => props.setLevel(+e.currentTarget.value)}
        >
            <option
                value={Requirement.Level.novice}
                children={Requirement.Level.toString(Requirement.Level.novice)}
            />
            <option
                value={Requirement.Level.apprentice}
                children={Requirement.Level.toString(Requirement.Level.apprentice)}
            />
            <option
                value={Requirement.Level.master}
                children={Requirement.Level.toString(Requirement.Level.master)}
            />
            <option
                value={Requirement.Level.expert}
                children={Requirement.Level.toString(Requirement.Level.expert)}
            />
        </select>
        <LevelIcon className={css["level"]} level={props.level} />
        <FontAwesomeIcon className={css["chevron"]} icon={faChevronDown} />
    </div>;
}

/** Props passed down to the {@link LevelSelect} component. */
export interface LevelSelectProps {
    /** The current level that was selected. */
    level: Requirement.Level;
    /** Callback used to select a new level. */
    setLevel(level: Requirement.Level): void;
}
