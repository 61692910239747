/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

import { pdfjs } from "react-pdf";


// Set the pdfjs global version.
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export * from "./lib";
export * from "./context";
