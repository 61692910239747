/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";

// Import the context.
import { SearchBarContext } from "./context";
// Import the provider.
import { SearchBarProvider, SearchBarProviderProps } from "./provider";
// Import the input.
import { SearchBarInputProps, SearchBarInput } from "./input";

// Re-export the context.
export type { SearchBarSource, SearchBarMatch } from "./context";
// Re-export the list props.
export type { SearchBarRendererProps, SearchBarNewItemRendererProps } from "./list";
// Re-export the normaliser.
export { normalise } from "./provider";


/** Factory method used to build a new search bar component. */
export function createSearchBar<T = undefined>(): SearchBar<T> {
    // Create a new search bar context.
    const context = React.createContext<SearchBarContext<T>>(DEFAULT_VALUE as SearchBarContext<T>);

    // Create the search bar provider.
    const Provider = function WrappedSearchBarProvider(
        props: Omit<SearchBarProviderProps<T>, "context">
    ): React.ReactElement {
        return <SearchBarProvider context={context} {...props} />;
    };

    // Create the input.
    const Input = function WrappedSearchBarInput(props: Omit<SearchBarInputProps<T>, "context">): React.ReactElement {
        return <SearchBarInput context={context} {...props} />;
    };

    return { context, Provider, Input };
}

/** Interface used to describe the object returned by {@link createSearchBar}. */
interface SearchBar<T> {
    /** The generated {@link React.Context}. */
    context: React.Context<SearchBarContext<T>>;
    /** The generated {@link React.Provider}. */
    Provider: WrappedSearchBarProvider<T>;
    /** The input component of the search bar. */
    Input: WrappedSearchBarInput<T>;
}

/** Interface used to describe the type of the {@link SearchBar.Provider}. */
interface WrappedSearchBarProvider<T> {
    (props: Omit<SearchBarProviderProps<T>, "context">): React.ReactElement;
}

/** Interface used to describe the type of the {@link SearchBar.Input}. */
interface WrappedSearchBarInput<T> {
    (props: Omit<SearchBarInputProps<T>, "context">): React.ReactElement;
}

// Default state of the newly generated contexts.
const DEFAULT_VALUE: SearchBarContext<unknown> = {
    matches: [], text: "", source: [], creatable: false,
    update() {
        return;
    }, select() {
        return;
    }, create() {
        return;
    }
};
