/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";

// Import the context.
import { SearchBarContext, SearchBarSource, SearchBarMatch } from "./context";
import { normalise } from "./index";

// Import the css.
import css from "./list.module.scss";


/** Component used to render the list of all valid elements. */
export function SearchBarList<T>(props: SearchBarListProps<T>): React.ReactElement | null {
    // Get the matches from the context.
    const context = React.useContext(props.context);
    const select = React.useCallback((index: number) => {
        context.select(index);
        props.clearFocus();
    }, [context, props]);

    const withCreateOption = React.useMemo(function shouldRenderCreateOption(): boolean {
        if (!context.creatable) return false;
        if (context.text.length <= 0) return false;
        return !context.matches.some(item => item.matches.some(text => normalise(text) === normalise(context.text)));
    }, [context.creatable, context.matches, context.text]);

    // Store the rendered list of elements.
    const [ list, setList ] = React.useState<HTMLUListElement | null>(null);

    // Index of the currently selected element.
    const [ selected, setSelected ] = React.useState(0);

    // Render the matches.
    const matches = React.useMemo(function renderMatches(): React.ReactElement[] {
        return [ ...context.matches ]
            .map(function renderMatch(match: SearchBarMatch<T>, index: number): React.ReactElement {
                let rendered: React.ReactElement;
                if (typeof props.Renderer === "function") {
                    rendered = <props.Renderer
                        matches={match.matches} source={match.source} focused={index === selected}
                    />;
                } else {
                    rendered = <p
                        className={css["list__item__text"]}
                        children={Array.isArray(match.source.text) ? match.source.text[0] : match.source.text}
                    />;
                }
                return <li
                    className={`${css["list__item"]} ${selected === index ? css["list__item--selected"] : ""}`}
                    key={index}
                    children={rendered}
                    onClick={() => select(index)}
                    onMouseOver={() => setSelected(index)}
                />;
            });
    }, [context.matches, props, select, selected]);

    // Append the creation element if relevant.
    const matchesWithCreatable = React.useMemo(function appendCreatable(): React.ReactElement[] {
        if (!withCreateOption) return matches;

        // Render the item.
        let rendered: React.ReactElement | null;
        if (typeof props.NewItemRenderer === "function") {
            rendered = props.NewItemRenderer({ text: context.text });
        } else {
            rendered = <p className={css["list__item__text"]}>+ Créer {context.text}</p>;
        }

        // If the rendered element is empty, return the original list.
        if (rendered === null) return matches;

        return [
            ...matches,
            <li
                className={
                    `${css["list__item"]} ` +
                    `${selected === context.matches.length ? css["list__item--selected"] : ""}`
                }
                key="create"
                children={rendered}
                onClick={() => context.create()}
                onMouseOver={() => setSelected(context.matches.length)}
            />
        ];
    }, [context, matches, props, selected, withCreateOption]);

    // Listen to keyboard inputs.
    React.useEffect(function attachKeyboardListener(): void | (() => void) {
        if (props.focused) {
            // Attach the listener.
            window.addEventListener("keydown", onKeyDown);
            return () => window.removeEventListener("keydown", onKeyDown);
        }

        // Function used to handle the key event.
        function onKeyDown(event: KeyboardEvent) {
            const items = matchesWithCreatable.length;
            switch (event.key) {
            case "ArrowDown": {
                const index = (selected + 1) % items;
                setSelected(index);
                scroll(list?.children[index]);
                break;
            }
            case "ArrowUp": {
                let index = selected - 1;
                if (index < 0) index = items - 1;
                setSelected(index);
                scroll(list?.children[index]);
                break;
            }
            case "Enter": {
                if (selected === matchesWithCreatable.length - 1) {
                    context.create();
                    setSelected(matchesWithCreatable.length - 2);
                } else {
                    select(selected);
                }
                break;
            }
            }
        }

        // Scrolls the element into view.
        function scroll(element?: Element): void {
            if (typeof element === "undefined") return;
            if (element.parentElement == null) return;

            // Get the position of the element and its parent.
            const parentPos = element.parentElement.getBoundingClientRect().top;
            const selfPos = element.getBoundingClientRect().top;
            const difference = (selfPos - parentPos) - 8;

            // Scroll the parent.
            element.parentElement.scrollBy({ top: difference, behavior: "smooth" });
        }
    }, [context, context.matches.length, list?.children, matchesWithCreatable.length, props.focused, select, selected]);

    // Render the component.
    if (!props.focused) return null;
    return <div className={`${css["list"]} ${props.direction === "up" ? css["list--up"] : css["list--down"]}`}>
        <ul className={css["list__container"]} children={matchesWithCreatable} ref={setList} />
    </div>;
}

/**
 * Props passed down to the {@link SearchBarList} component.
 *
 * @template T
 */
export interface SearchBarListProps<T> {
    /** Reference to the context used by this search list. */
    context: React.Context<SearchBarContext<T>>;
    /** Direction in which the list should extend. */
    direction?: "up" | "down";

    /** Should be set if the element is in focus. */
    focused: boolean;

    clearFocus(): void;

    /**
     * Method used to render a source item.
     * If not provided, renders the first text in a <p> element.
     *
     * @param {SearchBarRendererProps<T>} props The props passed down to the element.
     * @returns {React.ReactElement} The element to render in the search bar list.
     */
    Renderer?(props: SearchBarRendererProps<T>): React.ReactElement;

    /**
     * Method used to render a creatable item.
     * If not provided, renders the text in a <p> element.
     *
     * @param {SearchBarNewItemRendererProps} props The props passed down to the element.
     * @returns {React.ReactElement} The element to render in the search bar list.
     */
    NewItemRenderer?(props: SearchBarNewItemRendererProps): React.ReactElement | null;
}

/**
 * Props passed to the {@link SearchBarListProps["Renderer"]} method.
 *
 * @template T
 */
export interface SearchBarRendererProps<T> {
    /** The source of the item being rendered. */
    source: SearchBarSource<T>;
    /** The strings that matched this item. */
    matches: readonly string[];

    /** A flag set if the element is focused. */
    focused: boolean;
}

/**
 * Props passed to the {@link SearchBarListProps["NewItemRenderer"]} method.
 *
 * @template T
 */
export interface SearchBarNewItemRendererProps {
    /** The text currently found in the search bar input. */
    text: string;
}
