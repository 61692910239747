/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the json:api resource interface.
import { Resource } from "@andromeda/json-api";

// Import the base interface.
import { AssetBase } from "./base";

// Import the validation schema.
import Schema from "../schema/application";

/**
 * Interface used to represent an application/* asset.
 */
export interface ApplicationAsset extends AssetBase {
    /** @inheritDoc */
    attributes: ApplicationAsset.Attributes;
}

/** Namespace used to augment the {@link ApplicationAsset} interface. */
export namespace ApplicationAsset {
    export const Type = AssetBase.Type;
    export type Type = typeof Type;

    export interface Attributes extends AssetBase.Attributes {
        /** @inheritDoc */
        contentType: `application/${string}`;
    }

    export const validate = compileSync<ApplicationAsset>(
        Schema.ResourceSchema
    );

    export interface Update extends Resource<ApplicationAsset.Type> {
        /** @inheritDoc */
        attributes?: Partial<Attributes>;
        /** @inheritDoc */
        relationships?: Update.Relationships;
    }

    export namespace Update {
        export interface Relationships {
            /** @borrows {AssetBase.Relationships.alternatives} */
            alternatives?: AssetBase.Relationships["alternatives"];
        }

        export const validate = compileSync<Update>(Schema.UpdateSchema);
    }

    export type Create = ApplicationAsset;

    export namespace Create {
        export const validate = compileSync<Create>(Schema.CreateSchema);
    }
}
