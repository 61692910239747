/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the react-redux dispatch hook.
import { useDispatch } from "react-redux";
// Import the resource store builder.
import { buildResourceStore } from "./components";
// Import the validation tool.
import { compile } from "@andromeda/validation";
// Import the resources.
import {
    User,
    Organisation,
    Tag,
    ZaqWiki,
    ZaqWikiFile,
    Course,
    Step,
    Requirement,
    Validation,
    Session,
    External,
    Asset,
    EncryptionSource,
    DueDate,
    CreateShareLink,
    ShareLink,
    UpdateShareLink,
    ShareLinkValidator,
    ShareLinkType,
} from "@andromeda/resources";


// Re-export the components.
export * from "./components";

/** Redux store used to store and manipulate user resources. */
export const UserStore = buildResourceStore<[User, User.Update, User.Create]>(User.Type, User.validate);
/** Redux store used to store and manipulate organisation resources. */
export const OrganisationStore = buildResourceStore<[Organisation, Organisation.Update, Organisation.Create]>(
    Organisation.Type,
    Organisation.validate
);
/** Store used to handle Tag resources. */
export const TagStore = buildResourceStore<[Tag, Tag.Update, Tag.Create]>(
    Tag.Type,
    Tag.validate
);
/** Store used to handle Zaq resources. */
export const ZaqWikiStore = buildResourceStore<[ZaqWiki, ZaqWiki.Update, ZaqWiki.Create]>(
    ZaqWiki.Type,
    ZaqWiki.validate
);
/** Store used to handle ZaqFile resources. */
export const ZaqWikiFileStore = buildResourceStore<[ZaqWikiFile, ZaqWikiFile.Update, ZaqWikiFile.Create]>(
    ZaqWikiFile.Type,
    ZaqWikiFile.validate
);

/** Store used to handle Course resources. */
export const CourseStore = buildResourceStore<[Course, Course.Update, Course.Create]>(
    Course.Type,
    Course.validate
);
/** Store used to handle Step resources. */
export const StepStore = buildResourceStore<[Step, Step.Update, Step.Create]>(
    Step.Type,
    Step.validate
);
/** Store used to handle Requirement resources. */
export const RequirementStore = buildResourceStore<[Requirement, Requirement.Update, Requirement.Create]>(
    Requirement.Type,
    Requirement.validate
);
/** Store used to handle DueDate resources. */
export const DueDateStore = buildResourceStore<[DueDate, DueDate.Update, DueDate.Create]>(
    DueDate.Type,
    DueDate.validate
);
/** Store used to handle Validation resources. */
export const ValidationStore = buildResourceStore<[Validation, Validation.Update, Validation.Create]>(
    Validation.Type,
    Validation.validate
);
/** Store used to handle External resources. */
export const ExternalStore = buildResourceStore<[External, External.Update, External.Create]>(
    External.Type,
    External.validate
);
/** Store used to handle Session resources. */
export const SessionStore = buildResourceStore<[Session, Session.Update, Session.Create]>(
    Session.Type,
    Session.validate
);
/** Store used to handle {@link Asset} resources. */
export const AssetStore = buildResourceStore<[Asset, Asset.Update, Asset.Create]>(
    Asset.Type,
    Asset.validate
);
/** Store used to handle {@link EncryptionSource} resources. */
export const EncryptionSourceStore = buildResourceStore<[EncryptionSource, never, EncryptionSource.Create]>(
    EncryptionSource.Type,
    EncryptionSource.validate
);
/** Store used to handle {@link ShareLink} resources. */
export const ShareLinkStore = buildResourceStore<[ShareLink, UpdateShareLink, CreateShareLink]>(
    ShareLinkType,
    ShareLinkValidator
);
export const ZaqStore = buildResourceStore<[any, never, any]>(
    "zaq",
    compile(true)
);
export const ZaqTagStore = buildResourceStore<[any, never, never]>(
    "zaq-tag",
    compile(true)
);


/** Helper used to merge the types of all the store's dispatches. */
export function useResourceDispatch():
    ReturnType<typeof UserStore["useDispatch"]> &
    ReturnType<typeof OrganisationStore["useDispatch"]> &
    ReturnType<typeof TagStore["useDispatch"]> &
    ReturnType<typeof ZaqWikiStore["useDispatch"]> &
    ReturnType<typeof ZaqWikiFileStore["useDispatch"]> &
    ReturnType<typeof CourseStore["useDispatch"]> &
    ReturnType<typeof StepStore["useDispatch"]> &
    ReturnType<typeof RequirementStore["useDispatch"]> &
    ReturnType<typeof DueDateStore["useDispatch"]> &
    ReturnType<typeof ValidationStore["useDispatch"]> &
    ReturnType<typeof ExternalStore["useDispatch"]> &
    ReturnType<typeof AssetStore["useDispatch"]> &
    ReturnType<typeof EncryptionSourceStore["useDispatch"]> &
    ReturnType<typeof ShareLinkStore["useDispatch"]> &
    ReturnType<typeof SessionStore["useDispatch"]> {
    return useDispatch();
}
