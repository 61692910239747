/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the bootstrap components.
import { Row, Col } from "react-bootstrap";
// Import the switch context.
import { useNotify } from "@andromeda/components";
// Import the tag resource.
import { Tag, ZaqWiki } from "@andromeda/resources";
// Import the store.
import { useResourceDispatch, ZaqWikiStore } from "@andromeda/store";

// Import the reader context.
import { ReaderContext } from "../context";
// Import the search bar.
import { TagSearchBar } from "../search";

// Import the icons.
import close from "@andromeda/assets/images/close.svg";
import add from "@andromeda/assets/images/add.svg";
// Import the css.
import css from "./category.module.scss";
import classNames from "classnames";


/** Component used to render and edit the current wiki's categories. */
export function Categories(): React.ReactElement {
    const notify = useNotify();

    // Read the wiki's tags.
    const { categories, wiki } = React.useContext(ReaderContext);

    // Removes the given tag from the wiki.
    const dispatch = useResourceDispatch();
    const removeTag = React.useCallback(
        function removeTag(tag: Tag): void {
            // Remove the file from the zaq.
            const update: ZaqWiki.Update = {
                type: ZaqWiki.Type,
                id: wiki.id,
                relationships: {
                    categories: {
                        data: categories.filter(ref => ref.id !== tag.id).map(tag => ({ type: Tag.Type, id: tag.id }))
                    }
                }
            };

            // Remove the tag from the zaq.
            dispatch(ZaqWikiStore.generator.update(update)).catch(notify.fatal);
        },
        [notify.fatal, dispatch, categories, wiki.id]
    );

    // Pre-render all the tags.
    const renderedTags = React.useMemo(
        function renderTags(): React.ReactElement[] {
            // Render all the tag elements.
            return categories.map(function renderTag(tag: Tag): React.ReactElement {
                const className = classNames(css["tag"], css["tag--editable"], css["tag__remove"]);
                return <div key={tag.id} className={className} onClick={() => removeTag(tag)}>
                    <img src={close} alt={"remove-tag"} />
                    <p>{tag.attributes.name}</p>
                </div>;
            });
        },
        [removeTag, categories]
    );

    // Render the component.
    return <Row className="d-flex flex-column flex-md-row flex-nowrap align-items-center">
        <Col className="d-flex flex-row justify-content-start align-items-start">
            <h3 className={css["tag-container__title"]}>catégories: </h3>
            <div className={css["tag-container"]}>
                {renderedTags}
                <AddTag key="add" />
            </div>
        </Col>
    </Row>;
}

/** Simple component used to render the tag addition bar. */
function AddTag(): React.ReactElement {
    const [isInput, setIsInput] = React.useState(false);

    if (isInput) {
        return <TagSearchBar.Input autoFocus className={css["tag-add__input"]} />;
    } else {
        return <div
            className={`${css["tag"]} ${css["tag--editable"]} ${css["tag__add"]}`}
            onClick={() => setIsInput(true)}
        >
            <img src={add} alt="add-new-category" />
            <p>catégorie</p>
        </div>;
    }
}
