/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the debug tool.
import debug from "debug";

/**
 * Custom event class used by the {@link EventHandler} class.
 * Allows any listener to abort the event via the {@link abort} method.
 *
 * @template T
 */
export class Event<T> {
    /** The type of the event. */
    public readonly type: PropertyKey;
    /** The current value of the event. */
    public readonly value: T;
    /** The date at which the event was created. */
    public readonly timeStamp: Date;

    /** The abort controller used to stop the event from reaching subsequent listeners. */
    private readonly _abort: AbortController;

    /** Class constructor. */
    public constructor(type: PropertyKey, ...args: [T]) {
        this.type = type; this.value = args[0] as T;
        this.timeStamp = new Date(); this._abort = new AbortController();
    }

    /** Checks if the event was aborted. */
    public get aborted(): boolean { return this._abort.signal.aborted; }
    /** Aborts the event. */
    public abort() { this._abort.abort(); }
}

/** Type used to enforce an event map with only string keys. */
export type EventMap<T = Record<string, unknown>> = { [K in keyof T]: K extends string ? T[K]: never };

/**
 * Custom event handler class.
 * Loosely based off the JS {@link EventTarget} interface.
 *
 * @template {Record<string, unknown>} T
 */
export class EventHandler<T extends EventMap<T> = Record<string, unknown>> {
    /**
     * Adds a new listener for an event of this handler class.
     *
     * @template T
     * @template {keyof T} K
     * @param {K} key The event type to listen to.
     * @param {(event: T[K]) => void} listener The listener to attach to the event.
     * @param {EventHandler.Options} [options={}] The options for this listener instance.
     * @returns {this} The {@link EventHandler} instance for chain calls.
     */
    public addEventListener<K extends keyof T>(
        key: K,
        listener: (event: Event<T[K]>) => void | Promise<void>,
        options: EventHandler.Options = {}
    ): this {
        log("Adding a new listener to the \"%s\" event", key);
        if (!this._listeners.has(key)) { this._listeners.set(key, []); }
        const list = this._listeners.get(key) as Listener<T[keyof T]>[];

        if (options?.at === "start") {
            list.unshift({ listener, options });
        } else {
            list.push({ listener, options });
        }

        if (options?.signal) {
            options.signal.addEventListener("abort", () => this.removeEventListener(key, listener));
        }
        return this;
    }

    /**
     * Removes a listener from this handler class.
     *
     * @template T
     * @template {keyof T} K
     * @param {K} key The event type to stop listening to.
     * @param {(event: T[K]) => void} listener The listener to remove.
     * @returns {this} The {@link EventHandler} instance for chain calls.
     */
    public removeEventListener<K extends keyof T>(key: K, listener: (event: Event<T[K]>) => void): this {
        log("Removing a listener from the \"%s\" event", key);
        const list = this._listeners.get(key);
        const index = list?.findIndex(object => object.listener === listener) ?? -1;
        if (list && index >= 0) {
            log("Removing the listener at index %d", index);
            list.splice(index, 1);
        }
        return this;
    }

    /**
     * Dispatches the provided event with the specified value.
     *
     * @template T
     * @template {keyof T} K
     * @param type The type of the event to dispatch.
     * @param {T[K] extends void ? [] : [T[K]]} args The value of the event to dispatch.
     */
    public async dispatchEvent<K extends keyof T>(
        type: K,
        ...args: T[K] extends void ? [] : [T[K]]
    ): Promise<Event<T[K]>> {
        const event = new Event<T[K]>(type, args[0] as T[K]);
        log("Dispatching a new \"%s\" event", event.type);
        const listeners = this._listeners.get(event.type as keyof T);

        if (listeners) {
            log("Invoking %d listeners ...", listeners.length);
            for (const value of listeners) {
                await value.listener(event);
                if (value.options.once) {
                    log("Removing a listener that was listening only once.");
                    this.removeEventListener(event.type as keyof T, value.listener);
                }
                if (event.aborted) {
                    log("Event was aborted, stopping here.");
                    break;
                }
            }
        }

        return event;
    }

    /** Clears all the listeners. */
    protected clear(): void { this._listeners.clear(); }

    /** Map of all the attached listeners. */
    private readonly _listeners: Map<keyof T, Listener[]> = new Map<keyof T, Listener[]>();
}

/** Augment the {@link EventHandler} class. */
export namespace EventHandler {
    /** Options passed to the {@link EventHandler.addEventListener} method. */
    export interface Options {
        /**
         * Where to place the listener in the queue.
         * @default {"end"}
         */
        at?: "start" | "end";

        /** If set, clears the listener immediately after being called once. */
        once?: boolean;

        /** Abort signal that can be used to remove the listener. */
        signal?: AbortSignal;
    }
}

/**
 * Interface used to describe a listener instance, internally used by {@link EventHandler}.
 *
 * @template T
 */
interface Listener<T = unknown> {
    /**
     * Invokes the listener.
     *
     * @param {T} value The value of the event.
     */
    listener(value: Event<T>): void | Promise<void>;

    /** Options used for this listener instance. */
    options: EventHandler.Options;
}

const log = debug("events");
