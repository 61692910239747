/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import RxJS.
import { filter, Observable, Subject } from "rxjs";
// Import the web asset interface.
import { WebAsset } from "@andromeda/converter-lib";


/**
 * Stores the given web asset into the store.
 *
 * @param {WebAsset} asset The asset to store.
 * @return {Promise<void>} A promise that resolves once the asset was stored.
 */
export async function storeWebAsset(asset: WebAsset): Promise<void> {
    // Clear the asset's links.
    delete asset.resource.links?.get;
    delete asset.resource.links?.put;

    // Prepare the request.
    const request = (await db).transaction(STORE_INFO.name, "readwrite");

    // Store the asset.
    return new Promise<unknown>(function storeWebAssetInDb(resolve, reject): void {
        // Store the asset.
        request.objectStore(STORE_INFO.name).put(asset);

        // Wait for the transaction to complete.
        request.addEventListener("complete", resolve);
        request.addEventListener("error", reject);
        request.addEventListener("abort", reject);
    })
    // Dispatch an update through the subject.
        .then(() => { dbSubject.next(asset); });
}

/**
 * Tries to load the given web asset from the store.
 *
 * @param {string} id The identifier of the requested asset.
 * @return {Promise<WebAsset | undefined>} A promise that resolves with:
 *   - {@link WebAsset}: The asset found in the database.
 *   - {@link undefined}: No asset with the given id was found in the database.
 */
export async function loadWebAsset(id: string): Promise<WebAsset | undefined> {
    // Prepare the request.
    const request = (await db).transaction(STORE_INFO.name, "readonly");

    // Store the asset.
    return new Promise<WebAsset | undefined>(function storeWebAssetInDb(resolve, reject): void {
        // Store the asset.
        const asset = request.objectStore(STORE_INFO.name).get(id);

        // Wait for the transaction to complete.
        request.addEventListener("complete", () => resolve(asset.result));
        request.addEventListener("error", reject);
        request.addEventListener("abort", reject);
    });
}

/**
 * Subscribes to change in the provided web asset.
 *
 * @param {string} id The id of the asset to watch.
 * @return {Observable<WebAsset>} An Observable that will dispatch updates to the asset.
 */
export function subscribeToWebAsset(id: string): Observable<WebAsset> {
    // Try to load the asset from the store.
    loadWebAsset(id).then(asset => asset && dbSubject.next(asset));

    // Load the asset from the subject.
    return dbSubject.pipe(filter(asset => asset.resource.id === id));
}


/** Information used for the used indexed database. */
const DB_INFO = { name: "zaqtiv-assets", version: 1 } as const;
/** Information used for the used indexed database object store. */
const STORE_INFO = { name: "asset", keyPath: "resource.id" } as const;

/** Memoized version of the database. */
const db: Promise<IDBDatabase> = (function getDatabase(): Promise<IDBDatabase> {
    // Open a new database.
    return new Promise<IDBDatabase>(function openDatabase(resolve, reject): void {
        const request = window.indexedDB.open(DB_INFO.name, DB_INFO.version);
        request.addEventListener("error", reject);
        request.addEventListener("upgradeneeded", upgradeNeededHandler);
        request.addEventListener("success", () => resolve(request.result));

        /** Helper used to upgrade the database. */
        function upgradeNeededHandler(): void {
            // Generate the object store.
            request.result.createObjectStore(STORE_INFO.name, { keyPath: STORE_INFO.keyPath });
        }
    });
})();
/** Subject used for the {@link subscribeToWebAsset} method. */
const dbSubject = new Subject<WebAsset>();
