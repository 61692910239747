/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useContext, useEffect, useRef } from "react";
// Import the React-Window tool.
import { ListOnItemsRenderedProps, VariableSizeList } from "react-window";

// Import the size helper hook.
import { usePageSizeHelper } from "../hooks/use-size-of-page";
// Import the contexts.
import { PDFActivePageContext, PDFContainerContext, PDFContext, PDFRenderModeContext } from "../context";
// Import the subcomponents.
import PDFPage from "./page";


/** Component used to render the pages of the PDF according to the {@link PDFRenderModeContext} mode. */
export default function PDFContinuousContainer(): ReactElement | null {
    // Load the pdf context.
    const { pages } = useContext(PDFContext);
    const { setActivePage, pageCount, $activePageObservable } = useContext(PDFActivePageContext);
    const { width, height } = useContext(PDFContainerContext).container;
    // Load the rendering mode of the pages.
    const { mode } = useContext(PDFRenderModeContext);
    // Load the page size tool.
    const getPageSize = usePageSizeHelper();

    // Reset the list size whenever a page rotates or scales.
    const list = useRef<VariableSizeList | null>(null);
    useEffect(function recomputeOnSizeOrRotationChange(): void {
        list.current?.resetAfterIndex(0);
    },[pages]);
    // Callback invoked to scroll to the currently visible page.
    useEffect(function scrollToPageOnUpdate(): () => void {
        const subscription = $activePageObservable.subscribe(function scrollTo(index: number): void {
            list.current?.scrollToItem(index, "start");
        });
        return subscription.unsubscribe.bind(subscription);
    }, [$activePageObservable, setActivePage]);

    // Callback invoked when the active page index is changed.
    const updateActivePageIndex = useCallback(function updateItemsRendered(items: ListOnItemsRenderedProps): void {
        setActivePage(items.visibleStopIndex, false);
    }, [setActivePage]);



    // Render the list of pages.
    if (mode === "one-by-one") {
        return null;
    }
    return <VariableSizeList
        itemSize={item => getPageSize(item, true)[1]}
        itemCount={pageCount}
        width={width}
        height={height}
        children={PDFPage}
        overscanCount={1}
        onItemsRendered={updateActivePageIndex}
        ref={list}
    />;
}
