/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

/** Primitive types for JSON objects. */
export type JsonPrimitive = string | number | boolean | null;

/** Base type for all json objects. */
export type JsonObject<T extends object = object> = {
    [K in keyof T]: K extends string ? (T[K] extends JsonValue ? T[K] : never) : never
};

/** Base type for all json arrays. */
export type JsonArray = Array<JsonValue>;

/** Base type for all json values. */
export type JsonValue = JsonPrimitive | JsonObject | JsonArray;

// List of all the keys forbidden in the attributes and relationships.
export type ForbiddenKeys = "id" | "type" | "attributes" | "relationships" | "links";
