/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import React-Router.
import { useNavigate } from "react-router-dom";
// Import the React device detector.
import { MobileView, BrowserView } from "react-device-detect";
// Import React-Bootstrap.
import { Container, Row } from "react-bootstrap";

// Import the search button hider component.
import { HideNavbarSearchButton } from "../../navbar";
// Import the subcomponents.
import SearchBar from "./searchbar";
import WidgetArea from "./widget-area";
import HomeMobile from "./mobile-selector";

// Import the css.
import css from "./home.module.scss";
import { ZaqTiv } from "@andromeda/components";


/** Component used to render the home page of the main menu. */
export default function Home(): ReactElement {
    // Get the navigation callback.
    const navigate = useNavigate();

    // Render the component.
    return <>
        <MobileView>
            <HomeMobile />
        </MobileView>
        <BrowserView className={css["background"]}>
            <Container className={css["container"]}>
                <HideNavbarSearchButton />
                <Row className={css["search-area"]}>
                    <ZaqTiv className={css["zaqtiv"]} variant="white" />
                    <SearchBar
                        className={css["searchbar"]}
                        placeholder="Rechercher ..."
                        onSubmit={query => navigate(`/search?query=${query}`)}
                        size="lg"
                    />
                </Row>
                <WidgetArea />
            </Container>
        </BrowserView>
    </>;
}
