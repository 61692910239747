/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

import { useContext, useEffect, useState } from "react";
import { ConversionStatusContext } from "../components";
import { forkJoin, Subject, Subscription } from "rxjs";
import { ConversionStatus } from "../lib";


/** Helper hook used to check if a conversion is currently running. */
export function useIsConverting(): boolean {
    const [isConverting, setIsConverting] = useState(false);
    // Use the conversion context.
    const conversionStatusContext = useContext(ConversionStatusContext);

    // Attach to the conversions.
    useEffect(function attachToAllConversions(): () => void {
        setIsConverting(true);
        const subscriptions: Subscription[] = [];

        // Map all the observables to their state.
        const subjects: Subject<true>[] = [];
        for (const conversion of conversionStatusContext.conversions.values()) {
            const subject = new Subject<true>();
            const subscription = conversion.subscribe({
                next(info): void {
                    if (info.status === ConversionStatus.done) {
                        subject.next(true);
                        subject.complete();
                        subscription.unsubscribe();
                    }
                },
                complete(): void {
                    subscription.unsubscribe();
                }
            });
            subscriptions.push(subscription);
            subjects.push(subject);
        }

        // Merge all the subjects.
        subscriptions.push(forkJoin(subjects).subscribe({
            complete: () => setIsConverting(false),
            error: () => setIsConverting(false)
        }));

        return () => {
            subscriptions.forEach(s => s.unsubscribe());
        };
    }, [conversionStatusContext.conversions]);

    return isConverting;
}
