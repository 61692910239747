/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { useEffect, useContext, useState } from "react";

// Import the login context.
import { LoginContext } from "../context";


/** Checks if a given feature flag is accessible. */
export function useFeatureFlag(flag: string): boolean {
    // Get the login context.
    const context = useContext(LoginContext);

    // Store the state of the feature flag.
    const [enabled, setEnabled] = useState(false);

    // Attach a listener for the flag update.
    useEffect(function attachFlagUpdateListener(): void | VoidFunction {
        // Check if the user is logged in.
        if (!context.isLoggedIn) {
            return;
        }

        // Get the state of the flag.
        setEnabled(context.unleash.isEnabled(flag));

        // Listen to the update events from the client.
        context.unleash.on("update", updateEnabledFlag);
        return function stopListeningForUnleashUpdates (): void {
            context.unleash.off("update", updateEnabledFlag);
        }

        // Callback used to update the flag.
        function updateEnabledFlag(): void {
            setEnabled(context.unleash.isEnabled(flag));
        }
    }, [context, flag]);

    return enabled;
}
