/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the icons.
import { faFrown } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./common.module.scss";


/** Component used to render an error message to the user. */
export default function PDFErrorMessage(): ReactElement {
    return <div className={css["message"]}>
        <FontAwesomeIcon icon={faFrown} className={css["message__icon"]} />
        <span className={css["message__text"]}>Impossible de charger le document</span>
    </div>;
}
