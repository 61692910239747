/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useCallback, useEffect, useContext, useState, useMemo, FocusEvent } from "react";
// Import the bootstrap components.
import { Form } from "react-bootstrap";
// Import the user resource.
import { User } from "@andromeda/resources";
// Import the user store.
import { UserStore } from "@andromeda/store";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the resource reader.
import { readOne } from "@andromeda/resource-helper";
// Import the loader component.
import { Loader, Switch } from "@andromeda/components";

// Import the deletion hook.
import { useDeleteUserConfirm } from "./delete-user";
// Import the role component.
import { Role } from "./role";

// Import the icons.
import person from "@andromeda/assets/images/person.svg";
import bin from "@andromeda/assets/images/bin.svg";
// Import the css.
import css from "./user-info.module.scss";


/** Component used to render and edit the info of a particular user. */
export function UserInfo(props: Props): ReactElement | null {
    // Load the user from the store.
    const loginContext = useContext(LoginContext);
    const isOwner = useMemo(function checkIfUserIsOwner(): boolean {
        return loginContext.organisations.current.relationships.owner.data?.id === props.user;
    }, [loginContext.organisations, props.user]);
    const self = useMemo(() => loginContext.self, [loginContext.self]);

    // Load the full user info.
    const [user, setUser] = useState<User.Full | null>(null);
    useEffect(function loadUserDetails(): void {
        readOne(User.Type, props.user, User.Full.validate, { searchParams: { full: "" } })
            .then(message => message.data)
            .then(setUser);
    }, [props.user]);

    // Method invoked to update the user info.
    const dispatch = UserStore.useDispatch();
    const update = useCallback(function updateUserInfo(event: FocusEvent<HTMLInputElement>): void {
        if (!user) {
            return;
        }
        const target: HTMLInputElement = event.currentTarget;
        target.setCustomValidity("");
        if (!target.checkValidity()) {
            return;
        }

        // Find the changed elements.
        const userUpdate: User.Update = { type: User.Type, id: props.user };
        if (target.name === "email") {
            if (user.attributes[target.name] === target.value) {
                return;
            }
            userUpdate.attributes = { [target.name]: target.value };
        } else if (target.name === "username") {
            if (user.attributes[target.name] === target.value) {
                return;
            }
            userUpdate.attributes = { [target.name]: target.value };
        } else if (target.name === "givenName") {
            if (user.attributes[target.name] === target.value) {
                return;
            }
            userUpdate.attributes = { [target.name]: target.value };
        } else if (target.name === "disabled") {
            if (user.meta[target.name] === !target.checked) {
                return;
            }
            userUpdate.meta = { [target.name]: !target.checked };
            setUser({ ...user, meta: { disabled: !target.checked } });
        }

        // Build the full update.
        dispatch(UserStore.generator.update(userUpdate)).catch(() => {
            target.setCustomValidity(
                "Ce nom d'utilisateur est déjà attribué !"
            );
        });
    }, [dispatch, props.user, user]);

    // Get the user deletion modal.
    const confirm = useDeleteUserConfirm();
    const deleteUser = useCallback(function deleteUser(): void {
        if (isOwner) {
            return;
        }

        confirm(props.user).then(result => {
            if (result === "delete") {
                props.hide();
            }
        });
    }, [confirm, isOwner, props]);

    // Render the role and disable form.
    const disabledForm = useMemo(function renderDisabledForm(): ReactElement | null {
        if (!user || isOwner) return null;

        // Compute the class name.
        let className = css["user-info__toggle"];
        if (user.meta.disabled) {
            className += ` ${css["user-info__toggle--disabled"]}`;
        } else {
            className += ` ${css["user-info__toggle--enabled"]}`;
        }

        return <span className={className}>
            <p className={css["user-info__toggle__label"]} children="compte:" />
            <label className={css["user-info__toggle__container"]}>
                <p className={css["user-info__toggle__disabled-text"]} children="en veille" />
                <Switch
                    initialState={!user.meta.disabled}
                    onInternalStateChange={() => { return; }}
                    onChange={update}
                    inputName="disabled"
                />
                <p className={css["user-info__toggle__enabled-text"]} children="actif" />
            </label>
        </span>
    }, [isOwner, update, user]);

    // Render the element.
    if (!user) {
        return <Loader asBlock text="Chargement des informations de cet utilisateur ..." />;
    }
    return <Form onSubmit={e => e.preventDefault()} validated className={css["user-info"]}>
        <div className={css["user-info__info"]}>
            <img className={css["user-info__info__person"]} src={person} alt="person" />
            <div className={css["user-info__info__form"]}>
                <Form.Label className={css["user-info__info__form__label"]}>
                    <p>identifiant:</p>
                    <div className={css["user-info__info__form__label__content"]}>
                        <Form.Control
                            type="text"
                            autoComplete="username"
                            defaultValue={user.attributes.username}
                            name="username"
                            disabled={isOwner && user.id !== self.id}
                            onBlur={update}
                        />
                        <Form.Control.Feedback type="invalid">
                            Identifiant indisponible
                        </Form.Control.Feedback>
                    </div>
                </Form.Label>
                <Form.Label className={css["user-info__info__form__label"]}>
                    <p>mail:</p>
                    <div className={css["user-info__info__form__label__content"]}>
                        <Form.Control
                            type="email"
                            autoComplete="email"
                            defaultValue={user.attributes.email}
                            name="email"
                            disabled={isOwner && user.id !== self.id}
                            onBlur={update}
                        />
                        <Form.Control.Feedback type="invalid">
                            Adresse invalide ou déjà utilisée
                        </Form.Control.Feedback>
                    </div>
                </Form.Label>
                <Form.Label className={css["user-info__info__form__label"]}>
                    <p>nom:</p>
                    <div className={css["user-info__info__form__label__content"]}>
                        <Form.Control
                            type="text"
                            autoComplete="name"
                            defaultValue={user.attributes.givenName}
                            name="givenName"
                            disabled={isOwner && user.id !== self.id}
                            onBlur={update}
                        />
                    </div>
                </Form.Label>
            </div>
            <button className={css["user-info__info__bin"]} onClick={deleteUser} disabled={isOwner}>
                <img src={bin} alt="bin" />
            </button>
        </div>
        <div className={css["user-info__role"]}>
            <p className={css["user-info__role__label"]}>droits: </p>
            <Role user={props.user} />
        </div>
        {disabledForm}
    </Form>;
}

/** Props passed down to the {@link UserInfo} component. */
interface Props {
    /** User being manipulated. */
    user: string;

    /** Method used to return to the list of users. */
    hide(): void;
}
