/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the UUID generator.
import { v4 } from "uuid";

// Import the asset metadata interface.
import {
    AssetMetadata,
    AudioAssetMimeType,
    VideoAssetMimeType,
    ImageAssetMimeType,
    ExtractMetadata
} from "../resources";
import {
    Asset,
    AudioAsset,
    VideoAsset,
    ImageAsset,
} from "@andromeda/resources";
import { UnsupportedAssetTypeError } from "../errors";
import { validate } from "@andromeda/validation";



/** Interface used to describe a single asset. */
export class ConversionAsset<T extends `${string}/${string}` = `${string}/${string}`> implements Omit<AssetMetadata, "type"> {
    /** Type of the {@link ConversionAsset} resource. */
    public static readonly Type = "asset-conversion_asset" as const;

    /** Type of the {@link ConversionAsset} resource. */
    public readonly type = ConversionAsset.Type;
    /** UUID of this {@link ConversionAsset} resource. */
    public readonly id;
    /** @inheritDoc */
    public attributes: ExtractMetadata<T> & ConversionAsset.WithData;

    /**
     * Builds a new {@link ConversionAsset} object from the provided data.
     *
     * @param {ArrayBuffer} data The data contents of the asset.
     * @param {AnyAssetMetadata} attributes The attributes of the asset.
     * @param {string | undefined} [id=undefined] The id of the asset.
     * Will generate a random UUID if not specified.
     */
    public constructor(
        data: ArrayBuffer,
        attributes: ExtractMetadata<T>,
        id?: string
    ) {
        this.id = id ?? v4();
        this.attributes = { ...attributes, data };
    }

    /** Serializes the asset for encoding into a message. */
    public serialize(): ConversionAsset.EncodedAsset {
        return {
            data: this.attributes.data,
            metadata: new TextEncoder().encode(JSON.stringify(this.metadata)),
        };
    }

    /** Deserializes an asset from an encoded frame. */
    public static deserialize(encoded: ConversionAsset.EncodedAsset): ConversionAsset {
        const metadata = JSON.parse(new TextDecoder().decode(encoded.metadata));
        validate(metadata, AssetMetadata.validate, true);
        return new ConversionAsset(encoded.data, metadata.attributes, metadata.id);
    }

    /** Generates the {@link AssetMetadata} corresponding to this asset. */
    public get metadata(): AssetMetadata {
        const metadata = {
            id: this.id,
            type: AssetMetadata.Type,
            attributes: { ...this.attributes },
        };
        delete (metadata.attributes as { data: unknown }).data;
        return metadata;
    }

    /**
     * Converts an asset resource into a {@link ConversionAsset} object.
     *
     * @param {Asset} asset The asset to convert.
     * @param {ArrayBuffer} data The data of the asset.
     * @returns {ConversionAsset} The converted asset object.
     */
    public static fromAsset(asset: Asset, data: ArrayBuffer): ConversionAsset {
        if (asset.attributes.contentType.startsWith("image")) {
            return new ConversionAsset(
                data,
                {
                    contentType: asset.attributes
                        .contentType as ImageAssetMimeType,
                    width: (asset as ImageAsset).attributes.width,
                    height: (asset as ImageAsset).attributes.height,
                },
                asset.id
            );
        } else if (asset.attributes.contentType.startsWith("video")) {
            return new ConversionAsset(
                data,
                {
                    contentType: asset.attributes
                        .contentType as VideoAssetMimeType,
                    bitrate: (asset as VideoAsset).attributes.bitrate,
                    length: (asset as VideoAsset).attributes.length,
                    width: (asset as VideoAsset).attributes.width,
                    height: (asset as VideoAsset).attributes.height,
                    framerate: (asset as VideoAsset).attributes.framerate,
                },
                asset.id
            );
        } else if (asset.attributes.contentType.startsWith("audio")) {
            return new ConversionAsset(
                data,
                {
                    contentType: asset.attributes
                        .contentType as AudioAssetMimeType,
                    bitrate: (asset as AudioAsset).attributes.bitrate,
                    length: (asset as AudioAsset).attributes.length,
                },
                asset.id
            );
        } else {
            throw new UnsupportedAssetTypeError(asset.attributes.contentType);
        }
    }
}

/** Augment the {@link ConversionAsset} interface. */
export namespace ConversionAsset {
    export type Type = typeof ConversionAsset.Type;

    export interface WithData {
        /** Contents of the asset. */
        data: ArrayBuffer;
    }

    export interface EncodedAsset {
        /** JSON-stringified, then UTF-8 encoded string of the asset. */
        metadata: ArrayBuffer;
        /** Asset data. */
        data: ArrayBuffer;
    }
}
