/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the user resource.
import { User } from "@andromeda/resources";
// Import the login context.
import { LoginContext } from "@andromeda/login";

// Import the admin context.
import { AdminContext } from "../context";
// Import the delete confirm hook.
import { useDeleteUserConfirm } from "./delete-user";

// Import the icons.
import moon from "@andromeda/assets/images/moon.svg";
import bin from "@andromeda/assets/images/bin.svg";
import pencil from "@andromeda/assets/images/pencil.svg";
// Import the css.
import css from "./user-list.module.scss";
import { Role } from "./role";
import { normalise } from "@andromeda/components";
import { UserListSearchBar } from "./context";


/** Union of all the supported user sorting modes. */
export type UserSortingMode = "member-asc" | "member-desc" | "access-asc" | "access-desc";

/** Component used to render a table with all the users in a list. */
export function UserList(props: Props): React.ReactElement {
    // Load all the users.
    const organisation = React.useContext(LoginContext).organisations.current;
    const { users } = React.useContext(AdminContext);
    // Load the deletion modal.
    const deleteUser = useDeleteUserConfirm();

    // Render all the user list items.
    const { matches, text } = React.useContext(UserListSearchBar.context);
    const findAccessLevel = React.useCallback(function findAccessLevel(user: User): 1 | 2 | 3 | 4 {
        if (organisation.relationships.owner.data?.id === user.id) {
            return 4;
        } else if (organisation.relationships.administrators.data.find(admin => admin.id === user.id)) {
            return 3;
        } else if (organisation.relationships.editors.data.find(admin => admin.id === user.id)) {
            return 2;
        } else {
            return 1;
        }
    }, [organisation.relationships.administrators.data, organisation.relationships.editors.data, organisation.relationships.owner.data?.id]);
    const renderedUsers = React.useMemo(function renderUsers(): React.ReactElement[] {
        return users
            .filter(function removeFilteredUsers(user: User): boolean {
                if (text.length <= 0) return true;
                return matches.find(match => match.source.data.id === user.id) !== undefined;
            })
            .sort(function sortUsers(a: User, b: User): number {
                // Check the current sorting mode.
                switch (props.sortingMode) {
                case "member-asc": {
                    const aName = normalise(a.attributes.givenName), bName = normalise(b.attributes.givenName);
                    return aName > bName ? 1 : (aName < bName ? -1 : 0);
                }
                case "member-desc": {
                    const aName = normalise(a.attributes.givenName), bName = normalise(b.attributes.givenName);
                    return aName < bName ? 1 : (aName > bName ? -1 : 0);
                }
                case "access-asc": {
                    const aAccess = findAccessLevel(a), bAccess = findAccessLevel(b);
                    return aAccess < bAccess ? -1 : (aAccess > bAccess ? 1 : 0);
                }
                case "access-desc": {
                    const aAccess = findAccessLevel(a), bAccess = findAccessLevel(b);
                    return aAccess > bAccess ? -1 : (aAccess < bAccess ? 1 : 0);
                }
                default: return 0;
                }
            })
            .map(function renderUser(user: User): React.ReactElement {
                // Check if the user is the owner.
                const isOwner = organisation.relationships.owner.data?.id === user.id;

                return <li
                    className={`${css["user-list__item"]} ${user.meta.disabled ? css["user-list__item--disabled"] : ""}`}
                    key={user.id}
                >
                    <span className={css["user-list__item__name"]}>
                        <button
                            onClick={() => props.select(user)}
                        >
                            <img src={moon} alt="disabled" />
                            <p>{user.attributes.givenName}</p>
                        </button>
                    </span>
                    <span className={css["user-list__item__role"]}>
                        <Role user={user.id} />
                    </span>
                    <span className={css["user-list__item__actions"]}>
                        <button
                            onClick={() => props.select(user)}
                            className={css["user-list__item__actions__edit"]}
                            children={<img src={pencil} alt="update" />}
                        />
                        <button
                            className={css["user-list__item__actions__delete"]}
                            children={<img src={bin} alt="delete" />}
                            disabled={isOwner}
                            onClick={() => { if (!isOwner) deleteUser(user.id); }}
                        />
                    </span>
                </li>;
            });
    }, [deleteUser, findAccessLevel, matches, organisation.relationships.owner.data?.id, props, text.length, users]);

    // Render the component.
    return <div className={css["user-list__container"]}>
        <UserListSearchBar.Input direction="down" className={css["user-list__search-bar"]} />
        <ul className={css["user-list"]} children={renderedUsers} />
    </div>;
}

/** Props passed down to the {@link UserList} component. */
interface Props {
    /** Method invoked to select a user from the list. */
    select(user: User): void;

    /** Sorting mode applied to the list. */
    sortingMode: UserSortingMode;
}
