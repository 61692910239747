/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, SyntheticEvent, useCallback, useContext } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the resources.
import { Step } from "@andromeda/resources";
// Import the store.
import { StepStore, useResourceDispatch, useStep } from "@andromeda/store";

// Import the custom contexts.
import { EditorSwitchContext } from "../../../context";

// Import the icons.
import { faTrash } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./delete-button.module.scss";


/** Component used to render the delete button. */
export default function DeleteButton(props: DeleteButtonProps): ReactElement | null {
    const { state: editing } = useContext(EditorSwitchContext);

    // Callback used to remove the selected item from the current step.
    const step = useStep(props.step);
    const dispatch = useResourceDispatch();
    const remove = useCallback(function remove(event: SyntheticEvent): void {
        event.stopPropagation(); event.preventDefault();
        if (!step.isSuccess) {
            return;
        }

        // Dispatch the update to the step.
        const update: Step.Update = {
            type: Step.Type, id: props.step,
            relationships: {
                items: {
                    data: step.data.relationships.items.data.filter(
                        item => item.type !== props.item.type || item.id !== props.item.id
                    )
                }
            }
        };
        dispatch(StepStore.generator.update(update, { include: ["requirement"] })).catch(console.error);
    }, [dispatch, props.item.id, props.item.type, props.step, step.data?.relationships.items.data, step.isSuccess]);

    if (!editing) {
        return null;
    }
    return <button className={css["button"]} onClickCapture={remove}>
        <FontAwesomeIcon className={css["button__icon"]} icon={faTrash} />
    </button>;
}

/** Props passed down to the {@link DeleteButton} component. */
export interface DeleteButtonProps {
    /** Identifier of the current step. */
    step: string;
    /** Identifier of the current item. */
    item: Step.Item;
}
