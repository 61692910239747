/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useEffect } from "react";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the JSON:API module.
import type { Resource } from "@andromeda/json-api";


// Import the dispatch hook.
import { useResourceDispatch } from '../../../stores';
// Import the components.
import { ResourceStore, ResourceStoreHelper, useRequestDispatch, useRequestSelector } from "../../../components";
// Import the list comparator tool.
import compareLists from "./compare-lists";


/**
 * Hook used to download a list of resources from the store.
 *
 * @template {Resource} R
 * @param {ResourceStoreHelper<[R, never, never]>} store The store used to query the resource.
 * @param {R["type"]} type The store used to query the resource.
 * @param {(ReadManyOptions & ActionGeneratorOptions<[R, never, never]>) | undefined} options
 * The options passed to the generator.
 * @param {(state: ResourceStore<R>) => R[]} selector
 * The selector used to retrieve the resources from the store.
 * @return {RequestStatus<R[]>} The status of the request.
 */
export default function useResources<R extends Resource>(
    store: ResourceStoreHelper<[R, never, never]>,
    type: R["type"],
    options: Parameters<ResourceStoreHelper<[R, never, never]>["generator"]["readMany"]>[0] | undefined,
    selector: (state: ResourceStore<R>, store: unknown) => R[]
): RequestStatus<R[]>;

/** Implementation ! */
export default function useResources(
    store: ResourceStoreHelper<[Resource, never, never]>,
    type: string,
    options: Parameters<ResourceStoreHelper<[Resource, never, never]>["generator"]["readMany"]>[0] | undefined,
    selector: (state: ResourceStore<Resource>, store: unknown) => Resource[]
): RequestStatus<Resource[]> {
    // Get the status of the request.
    const requestId = `${type}/${window.btoa(JSON.stringify(options))}`;
    const request = useRequestSelector(requestId);
    const dispatchRequest = useRequestDispatch(requestId);

    // Load the resource from the store.
    const resources = store.useSelector(selector, compareLists);
    const dispatchResource = useResourceDispatch();

    // Download the request from the store.
    useEffect(function downloadResource(): void {
        // Wait until some options are provided.
        if (typeof options === "undefined") {
            return;
        }

        // Run the request.
        dispatchRequest((dispatch, getState) => {
            if (!getState().isUninitialised) {
                return;
            }

            dispatch(RequestStatus.loading());
            dispatchResource(store.generator.readMany(options))
                .then(() => RequestStatus.success())
                .catch(RequestStatus.error)
                .then(dispatch);
        });
    }, [dispatchRequest, dispatchResource, options, request.isUninitialised, store.generator]);

    // Return the state of the request.
    if (request.isSuccess) {
        return RequestStatus.success(resources);
    }
    return request;
}
