/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useEffect, useState } from "react";
// Import React-Bootstrap.
import { Modal } from "react-bootstrap";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the store.
import { DueDateStore, useDueDate, useResourceDispatch } from "@andromeda/store";
// Import the store.
import { Course, DueDate, User } from "@andromeda/resources";
// Import the custom components.
import { useNotify } from "@andromeda/components";

// Import the subcomponents.
import Info from "./info";
import DueDatePicker from "./due-date-picker";

// Import the icons.
import { faXmark } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./modal.module.scss"


/** Component used to render the modal used to edit a user's due date. */
export default function DueDateModal(props: DueDateModalProps): ReactElement {
    // Load the due-date for the user and course.
    const dueDate = useDueDate(props.course, props.user);

    // Store the edited version of the date.
    const [date, setDate] = useState<Date | null>(null);
    useEffect(function reloadDateOnChange(): void {
        if (dueDate.isSuccess) {
            setDate(DueDate.getDate(dueDate.data));
        }
    }, [dueDate.data, dueDate.isSuccess]);
    const [recurrence, setRecurrence] = useState<DueDate.Recurrence | null>(null);
    useEffect(function reloadRecurrenceOnChange(): void {
        if (dueDate.isSuccess && dueDate.data !== null) {
            setRecurrence(dueDate.data.attributes.recurrence);
        }
    }, [dueDate.data, dueDate.isSuccess]);

    // Wrapper for "setDate" that ensures that the date is set to midnight.
    function setDateAtMidnight(date: Date | null): void {
        // Set the time to midnight.
        if (date) {
            date.setHours(0, 0, 0, 0);
        }

        setDate(date);
    }

    // Callback used to save the changes and close the modal.
    const { error } = useNotify();
    const dispatch = useResourceDispatch();
    const saveAndClose = useCallback(function saveAndClose(): void {
        // Assert that the course and user are set.
        if (typeof props.course === "undefined" || typeof props.user === "undefined") {
            return;
        }

        // Save the update.
        if (date === null) {
            if (dueDate.data !== null) {
                dispatch(DueDateStore.generator.delete(`${props.course}-${props.user}`)).catch(error);
            }
        } else {
            if (dueDate.data === null) {
                const create: DueDate.Create = {
                    type: DueDate.Type,
                    attributes: {
                        date: date.toISOString(),
                        recurrence
                    },
                    relationships: {
                        course: { data: { type: Course.Type, id: props.course }},
                        user: { data: { type: User.Type, id: props.user }}
                    }
                };
                dispatch(DueDateStore.generator.create(create)).catch(error);
            } else {
                const update: DueDate.Update = {
                    type: DueDate.Type, id: `${props.course}-${props.user}`,
                    attributes: {
                        date: date.toISOString(),
                        recurrence
                    }
                };
                dispatch(DueDateStore.generator.update(update)).catch(error);
            }
        }

        // Close the modal.
        props.close();
    }, [date, dispatch, dueDate.data, error, props, recurrence]);

    // Render the component.
    return <Modal
        animation keyboard centered enforceFocus
        show={typeof props.course !== "undefined" && typeof props.user !== "undefined"}
        onHide={saveAndClose}
        className={css["modal"]} backdropClassName={css["modal__backdrop"]}
        contentClassName={css["modal__content"]} dialogClassName={css["modal__dialog"]}
    >
        <Modal.Header className={css["header"]}>
            <span className={css["title"]}>Gestion de l'échéance</span>
            <button onClick={saveAndClose} className={css["close"]}>
                <FontAwesomeIcon className={css["close__icon"]} icon={faXmark} />
            </button>
        </Modal.Header>
        <Modal.Body className={css["body"]}>
            <Info course={props.course} user={props.user} />
            <DueDatePicker date={date} setDate={setDateAtMidnight} />
        </Modal.Body>
    </Modal>;
}

/** Props passed down to the {@link DueDateModal} component. */
export interface DueDateModalProps {
    /** Identifier of the course that should be edited. */
    course: string | undefined;
    /** Identifier of the user that should be edited. */
    user: string | undefined;
    /** Callback used to close the modal. */
    close(): void;
}
