/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the redux thunk action interface.
import { ThunkAction } from "redux-thunk";
// Import the JSON:API filter interface.
import {
    SingleMessageHelper,
    MultipleMessageHelper,
    Relationship,
    WritableMessageHelper,
    messageValidator,
} from "@andromeda/json-api";
// Import the CRUD tuple helper.
import {
    CRUDTuple,
    PromisableValidateFunction,
    ReadOneOptions,
    ReadManyOptions,
} from "@andromeda/resource-helper";
// Import the crud operations.
import * as CRUD from "@andromeda/resource-helper";
// Import the xhr class.
import { Xhr } from "@andromeda/xhr";
// Import the schema validator.
import { validate } from "@andromeda/validation";

// Import the resource store type.
import { WithResourceStore } from "./reducer";
// Import the CRUDAction union.
import { CRUDAction } from "./actions";
// Import the error classes.
import {
    ReadFailedError,
    DeleteFailedError,
    CreateFailedError,
    UpdateFailedError,
} from "./error";
import { PromiseQueue } from "@andromeda/queue";

/** Interface used to provide options for the action generator. */
interface ActionGeneratorOptions<R extends CRUDTuple, T = void> {
    /** If true, this task can be executed without waiting in the queue. */
    immediate?: boolean;
    /** Callback that will be invoked before the "/request" dispatch is made. */
    beforeRequestDispatch?: T extends void ? () => void | Promise<void> : (resource: T) => T | Promise<T>;
    /** Callback that will be invoked after the "/request" dispatch is made. */
    afterRequestDispatch?: T extends void ? () => void | Promise<void> : (resource: T) => T | Promise<T>;
    /** Callback that will be invoked before the "/success" dispatch is made. */
    beforeSuccessDispatch?: (resource: R[0]) => void | Promise<void>;
    /** Callback that will be invoked after the "/success" dispatch is made. */
    afterSuccessDispatch?: (resource: R[0]) => void | Promise<void>;
}

/** Interface used to provide options for the delete action generator. */
interface DeleteActionGeneratorOptions {
    /** If true, this task can be executed without waiting in the queue. */
    immediate?: boolean;
    /** Callback that will be invoked before the "/success" dispatch is made. */
    beforeSuccessDispatch?: () => void | Promise<void>;
    /** Callback that will be invoked after the "/success" dispatch is made. */
    afterSuccessDispatch?: () => void | Promise<void>;
}

/** Alias for the local {@link ThunkAction} */
export type CRUDThunkAction<Return, R extends CRUDTuple> = ThunkAction<
    Return,
    WithResourceStore<R[0]>,
    never,
    CRUDAction<R>
>;

/**
 * Interface used to describe the object returned from {@link buildActionGenerator}.
 * Provides a simple interface to manage the resource store.
 *
 * @template {CRUDTuple} R
 */
export interface ActionGenerator<R extends CRUDTuple> {
    /**
     * Creates a new resource in the database.
     *
     * @param {R[2]} resource The resource to create.
     * @param {ReadOneOptions & ActionGeneratorOptions<R>} options Options for the action.
     * @returns {CRUDThunkAction<Promise<R[0]>, R>} The action to dispatch to the store.
     */
    create(
        resource: R[2],
        options?: ReadOneOptions & ActionGeneratorOptions<R, R[2]>
    ): CRUDThunkAction<Promise<R[0]>, R>;

    /**
     * Reads a single resource from the database.
     *
     * @param {string} id The id of the resource to read.
     * @param {ReadOneOptions & ActionGeneratorOptions<R>} options Options for the action.
     * @returns {CRUDThunkAction<Promise<R[0]>, R>} The action to dispatch to the store.
     */
    readOne(
        id: string,
        options?: ReadOneOptions & ActionGeneratorOptions<R>
    ): CRUDThunkAction<Promise<R[0]>, R>;

    /**
     * Reads multiple resources from the database.
     *
     * @param {ReadManyOptions & ActionGeneratorOptions<R>} options Options for the action.
     * @returns {CRUDThunkAction<Promise<R[0][]>, R>} The action to dispatch to the store.
     */
    readMany(
        options?: ReadManyOptions & ActionGeneratorOptions<R>
    ): CRUDThunkAction<Promise<R[0][]>, R>;

    /**
     * Updates a resource in the database.
     *
     * @param {R[1]} resource The update to apply.
     * @param {ReadOneOptions & ActionGeneratorOptions<R>} options Options for the action.
     * @returns {CRUDThunkAction<Promise<R[0]>, R>} The action to dispatch to the store.
     */
    update(
        resource: R[1],
        options?: ReadOneOptions & ActionGeneratorOptions<R, R[1]>
    ): CRUDThunkAction<Promise<R[0]>, R>;

    /**
     * Deletes a resource from the database.
     *
     * @param {string} id The id of the resource to delete.
     * @param {boolean} immediate (Optional) If set, immediately deletes the object from the local store.
     * @param {DeleteActionGeneratorOptions} options Options for the action.
     * @returns {CRUDThunkAction<Promise<void>, R>} The action to dispatch to the store.
     */
    delete(id: string, immediate?: boolean, options?: DeleteActionGeneratorOptions): CRUDThunkAction<Promise<void>, R>;

    /**
     * Pushes a new item into a relationship array.
     *
     * @param {string} id The id of the altered resource.
     * @param {keyof R[0]["relationships"]} relationship The relationship to alter.
     * @param {R[0]["relationships"][typeof relationship] extends Relationship ? R[0]["relationships"][typeof
     *     relationship]["data"] : never} element The element to push into the array.
     * @returns {CRUDThunkAction<Promise<void>, R>} The action to dispatch to the store.
     */
    pushRelationship(
        id: string,
        relationship: keyof R[0]["relationships"],
        element: R[0]["relationships"][typeof relationship] extends Relationship
            ? R[0]["relationships"][typeof relationship]["data"]
            : never
    ): CRUDThunkAction<Promise<void>, R>;

    /**
     * Removes an item from a relationship array.
     *
     * @param {string} id The id of the altered resource.
     * @param {keyof R[0]["relationships"]} relationship The relationship to alter.
     * @param {R[0]["relationships"][typeof relationship] extends Relationship ? R[0]["relationships"][typeof
     *     relationship]["data"] : never} element The element to remove from the array.
     * @returns {CRUDThunkAction<Promise<void>, R>} The action to dispatch to the store.
     */
    popRelationship(
        id: string,
        relationship: keyof R[0]["relationships"],
        element: R[0]["relationships"][typeof relationship] extends Relationship
            ? R[0]["relationships"][typeof relationship]["data"]
            : never
    ): CRUDThunkAction<Promise<void>, R>;
}

/** Helper function used to build an {@link ActionGenerator}. */
export function buildActionGenerator<R extends CRUDTuple>(
    name: R[0]["type"],
    validator: PromisableValidateFunction<R[0]>
): ActionGenerator<R> {
    // Queue that will be used for all the operations of this generator.
    const queue = new PromiseQueue();

    // Return the methods.
    return {
        create,
        readOne,
        readMany,
        update,
        delete: deleteOne,
        pushRelationship,
        popRelationship,
    };

    /** Method used to create a new resource. */
    function create(
        resource: R[2],
        options?: ReadOneOptions & ActionGeneratorOptions<R, R[2]>
    ): CRUDThunkAction<Promise<R[0]>, R> {
        return function (dispatch): Promise<R[0]> {
            const task = async () => {
                let message: SingleMessageHelper<R[0]>;
                try {
                    resource = await options?.beforeRequestDispatch?.(resource) ?? resource;
                    dispatch({ type: `${name}/create/request`, payload: resource });
                    resource = await options?.afterRequestDispatch?.(resource) ?? resource;

                    message = await CRUD.createOne(
                        resource,
                        validator,
                        options
                    );
                } catch (e: unknown) {
                    dispatch({
                        type: `${name}/create/failure`,
                        payload: new CreateFailedError(e),
                    });
                    throw e;
                }

                if (message.data === null) {
                    const payload = new CreateFailedError(
                        "Response data was null"
                    );
                    dispatch({ type: `${name}/create/failure`, payload });
                    throw payload;
                } else {
                    await options?.beforeSuccessDispatch?.(message.data);

                    // Check if there are included elements.
                    if (message.included.length > 0) {
                        for (const item of message.included) {
                            dispatch({ type: `${item.type}/read-one/request` });
                            dispatch({
                                type: `${item.type}/read-one/success`,
                                payload: item,
                            });
                        }
                    }

                    dispatch({
                        type: `${name}/create/success`,
                        payload: message.data,
                    });
                    await options?.afterSuccessDispatch?.(message.data);
                    return message.data;
                }
            };

            if (options?.immediate) {
                return task();
            } else {
                return queue.enqueue(task);
            }
        };
    }

    /** Method used to read a single resource from the store. */
    function readOne(
        id: string,
        options?: ReadOneOptions & ActionGeneratorOptions<R>
    ): CRUDThunkAction<Promise<R[0]>, R> {
        return function (dispatch): Promise<R[0]> {
            const task = async () => {
                await options?.beforeRequestDispatch?.call(undefined);
                dispatch({ type: `${name}/read-one/request` });
                await options?.afterRequestDispatch?.call(undefined);

                let message: SingleMessageHelper<R[0]>;
                try {
                    message = await CRUD.readOne(name, id, validator, options);
                } catch (e: unknown) {
                    dispatch({
                        type: `${name}/read-one/failure`,
                        payload: new ReadFailedError(e),
                    });
                    throw e;
                }

                if (message.data === null) {
                    const payload = new ReadFailedError(
                        "Response data was null"
                    );
                    dispatch({ type: `${name}/read-one/failure`, payload });
                    throw payload;
                } else {
                    // Check if there are included elements.
                    if (message.included.length > 0) {
                        for (const item of message.included) {
                            dispatch({ type: `${item.type}/read-one/request` });
                            dispatch({
                                type: `${item.type}/read-one/success`,
                                payload: item,
                            });
                        }
                    }

                    await options?.beforeSuccessDispatch?.call(
                        undefined,
                        message.data
                    );
                    dispatch({
                        type: `${name}/read-one/success`,
                        payload: message.data,
                    });
                    await options?.afterSuccessDispatch?.call(
                        undefined,
                        message.data
                    );
                    return message.data;
                }
            }

            if (options?.immediate) {
                return task();
            } else {
                return queue.enqueue(task);
            }
        };
    }

    /** Method used to read multiple resources from the database. */
    function readMany(
        options?: ReadManyOptions & ActionGeneratorOptions<R>
    ): CRUDThunkAction<Promise<R[0][]>, R> {
        return function (dispatch): Promise<R[0][]> {
            const task = async () => {
                await options?.beforeRequestDispatch?.call(undefined);
                dispatch({ type: `${name}/read-many/request` });
                await options?.afterRequestDispatch?.call(undefined);

                let message: MultipleMessageHelper<R[0]>;
                try {
                    message = await CRUD.readMany(name, validator, options);
                } catch (e: unknown) {
                    dispatch({
                        type: `${name}/read-many/failure`,
                        payload: new ReadFailedError(e),
                    });
                    throw e;
                }

                // Check if there are included elements.
                if (message.included.length > 0) {
                    for (const item of message.included) {
                        dispatch({ type: `${item.type}/read-one/request` });
                        dispatch({
                            type: `${item.type}/read-one/success`,
                            payload: item,
                        });
                    }
                }

                await Promise.all(
                    message.data.map(r =>
                        options?.beforeSuccessDispatch?.call(undefined, r)
                    )
                );
                dispatch({
                    type: `${name}/read-many/success`,
                    payload: message.data,
                });
                await Promise.all(
                    message.data.map(r =>
                        options?.afterSuccessDispatch?.call(undefined, r)
                    )
                );
                return message.data;
            }

            if (options?.immediate) {
                return task();
            } else {
                return queue.enqueue(task);
            }
        };
    }

    /** Message used to update a resource in the database. */
    function update(
        resource: R[1],
        options?: ReadOneOptions & ActionGeneratorOptions<R, R[1]>
    ): CRUDThunkAction<Promise<R[0]>, R> {
        return function (dispatch, getState): Promise<R[0]> {
            const task = async () => {
                resource = await options?.beforeRequestDispatch?.(resource) ?? resource;
                dispatch({ type: `${name}/update/request`, payload: resource });
                resource = await options?.afterRequestDispatch?.(resource) ?? resource;

                let message: SingleMessageHelper<R[0]>;
                try {
                    message = await CRUD.updateOne(
                        resource,
                        validator,
                        options
                    );
                } catch (e: unknown) {
                    dispatch({
                        type: `${name}/update/failure`,
                        payload: new ReadFailedError(e),
                    });
                    throw e;
                }

                // If the message is empty, read the resource from the store.
                let updated: R[0];
                if (message.data === null) {
                    const stored = getState()[name].resources.find(
                        r => r.id === resource.id
                    );
                    if (typeof stored === "undefined") {
                        const payload = new UpdateFailedError(
                            "Resource was not updated, but not found in the store."
                        );
                        dispatch({ type: `${name}/update/failure`, payload });
                        throw payload;
                    }
                    updated = stored;
                } else {
                    updated = message.data;
                }

                // Check if there are included elements.
                if (message.included.length > 0) {
                    for (const item of message.included) {
                        dispatch({ type: `${item.type}/read-one/request` });
                        dispatch({
                            type: `${item.type}/read-one/success`,
                            payload: item,
                        });
                    }
                }

                await options?.beforeSuccessDispatch?.(updated);
                dispatch({ type: `${name}/update/success`, payload: updated });
                await options?.afterSuccessDispatch?.(updated);
                return updated;
            }

            if (options?.immediate) {
                return task();
            } else {
                return queue.enqueue(task);
            }
        };
    }

    /** Method used to delete a resource from the database. */
    function deleteOne(
        id: string,
        immediate?: boolean,
        options?: DeleteActionGeneratorOptions
    ): CRUDThunkAction<Promise<void>, R> {
        return function (dispatch): Promise<void> {
            const task = async () => {
                if (immediate)
                    dispatch({ type: `${name}/delete/request`, payload: id });
                else dispatch({ type: `${name}/delete/request` });

                try {
                    await CRUD.deleteOne(name, id);
                } catch (e: unknown) {
                    dispatch({
                        type: `${name}/delete/failure`,
                        payload: new DeleteFailedError(e),
                    });
                    throw e;
                }
                await options?.beforeSuccessDispatch?.();
                dispatch({ type: `${name}/delete/success`, payload: id });
                await options?.afterSuccessDispatch?.();
            }

            if (options?.immediate) {
                return task();
            } else {
                return queue.enqueue(task);
            }
        };
    }

    /** Adds an element to a relationship array. */
    function pushRelationship(
        id: string,
        relationship: keyof R[0]["relationships"],
        element: R[0]["relationships"][typeof relationship] extends Relationship
            ? R[0]["relationships"][typeof relationship]["data"]
            : never
    ): CRUDThunkAction<Promise<void>, R> {
        return function (dispatch, getState): Promise<void> {
            return queue.enqueue(async () => {
                dispatch({
                    type: `${name}/update/request`,
                    payload: {
                        type: name,
                        id,
                        relationships: { [relationship]: { data: [element] } },
                    },
                });

                const request = await Xhr.post(name, {
                    path: `/${name}/${id}/relationships/${String(relationship)}`,
                    body: new WritableMessageHelper(element),
                });
                validate(request.body, await messageValidator, true);
                if (
                    typeof request.body.data === "undefined" ||
                    Array.isArray(request.body.data)
                ) {
                    dispatch({
                        type: `${name}/update/failure`,
                        payload: new ReadFailedError(
                            "Failed to read the response."
                        ),
                    });
                    throw new Error("Failed to update the object !");
                }

                // If the message is empty, read the resource from the store.
                let updated: R[0];
                if (request.body.data === null) {
                    const stored = getState()[name].resources.find(
                        r => r.id === id
                    );
                    if (typeof stored === "undefined") {
                        const payload = new UpdateFailedError(
                            "Resource was not updated, but not found in the store."
                        );
                        dispatch({ type: `${name}/update/failure`, payload });
                        throw payload;
                    }
                    updated = stored;
                } else {
                    updated = request.body.data;
                }

                dispatch({ type: `${name}/update/success`, payload: updated });
            });
        };
    }

    /** Removes an element from a relationship array. */
    function popRelationship(
        id: string,
        relationship: keyof R[0]["relationships"],
        element: R[0]["relationships"][typeof relationship] extends Relationship
            ? R[0]["relationships"][typeof relationship]["data"]
            : never
    ): CRUDThunkAction<Promise<void>, R> {
        return function (dispatch, getState): Promise<void> {
            return queue.enqueue(async () => {
                dispatch({
                    type: `${name}/update/request`,
                    payload: {
                        type: name,
                        id,
                        relationships: { [relationship]: { data: [element] } },
                    },
                });

                const request = await Xhr.delete(name, {
                    path: `/${name}/${id}/relationships/${String(relationship)}`,
                    body: new WritableMessageHelper(element),
                });
                validate(request.body, await messageValidator, true);
                if (
                    typeof request.body.data === "undefined" ||
                    Array.isArray(request.body.data)
                ) {
                    dispatch({
                        type: `${name}/update/failure`,
                        payload: new ReadFailedError(
                            "Failed to read the response."
                        ),
                    });
                    throw new Error("Failed to update the object !");
                }

                // If the message is empty, read the resource from the store.
                let updated: R[0];
                if (request.body.data === null) {
                    const stored = getState()[name].resources.find(
                        r => r.id === id
                    );
                    if (typeof stored === "undefined") {
                        const payload = new UpdateFailedError(
                            "Resource was not updated, but not found in the store."
                        );
                        dispatch({ type: `${name}/update/failure`, payload });
                        throw payload;
                    }
                    updated = stored;
                } else {
                    updated = request.body.data;
                }

                dispatch({ type: `${name}/update/success`, payload: updated });
            });
        };
    }
}
