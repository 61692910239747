/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


// Import React.
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the resource tools.
import { readOne } from "@andromeda/resource-helper";
// Import the web-asset interface.
import type { WebAsset } from "@andromeda/converter-lib";
// Import the asset conversion tools.
import type { ImageAssetMimeType } from "@andromeda/asset-conversion";

// Import the storage helper.
import { useCachedData } from "../../../storage";
// Import the wrapped icon component.
import IconRenderer from "./icon";


/** Component used to render the icon of a ZaqTuto. */
export default function ZaqTutoIcon(props: ZaqTutoIconProps): ReactElement | null {
    // Load the asset from the store.
    const [asset, setAsset] = useState<RequestStatus<WebAsset | null>>(RequestStatus.uninitialised());

    // Store the asset in cache.
    const cachedAsset = useCachedData(props.icon ? `zaq-tuto/icon/${props.icon}` : undefined, asset);

    // Callback used to download a blob from the server.
    const downloadAssetFromServer = useCallback(
        async function downloadZaqTutoAsset(tutoId: string, assetId: string): Promise<WebAsset | null> {
            // Download the asset from the server.
            const path = `/zaqtiv/${tutoId}/asset/${assetId}`;
            const message = await readOne("com.zimproov.api.zaqtiv.asset", assetId, { path });
            const resource = message.findOne("com.zimproov.api.zaqtiv.asset", assetId);

            // Extract the link from the resource.
            const link = resource?.links?.self;
            if (typeof link === "undefined") {
                return null;
            }

            // Download the asset's data.
            const response = await fetch(typeof link === "string" ? link : link.href);
            // Create a web asset from the response.
            const blob = await response.blob();
            return {
                resource: {
                    type: "asset",
                    id: assetId,
                    attributes: {
                        contentType: blob.type as ImageAssetMimeType,
                        height: -1,
                        width: -1,
                        size: blob.size
                    },
                    relationships: {
                        alternatives: { data: [] },
                        source: { data: null },
                        encryptionSource: { data: null }
                    }
                },
                data: await blob.arrayBuffer()
            };
        },
        []
    );

    // Download the asset from the legacy api when the icon is loaded.
    useEffect(function downloadAssetWhenTutoIsLoaded(): VoidFunction | void {
        // If the asset was cached, use it directly.
        if (typeof cachedAsset === "undefined") {
            return setAsset(RequestStatus.loading());
        }
        if (cachedAsset !== null) {
            return setAsset(RequestStatus.success(cachedAsset));
        }

        // If the icon is not set, stop here.
        if (typeof props.icon === "undefined") {
            return setAsset(RequestStatus.success(null));
        }

        // Prepare the request to the legacy api.
        downloadAssetFromServer(props.id, props.icon)
            .then(RequestStatus.success<WebAsset | null>)
            .catch(RequestStatus.error)
            .then(setAsset);
    }, [cachedAsset, downloadAssetFromServer, props.icon, props.id]);

    // Generate a Blob from the asset.
    const blob = useMemo(function buildBlobFromWebAsset(): RequestStatus<Blob | null> {
        // Wait for the asset to be loaded.
        if (!asset.isSuccess) {
            return asset;
        }

        // Create a blob from the asset.
        if (asset.data === null) {
            return RequestStatus.success(null);
        }
        return RequestStatus.success(new Blob([asset.data.data], { type: asset.data.resource.attributes.contentType }));
    }, [asset]);

    // Return the renderer.
    return <IconRenderer className={props.className} asset={blob} alt="Icône de ce ZaqTuto" />;
}

/** Props provided to the {@link ZaqTutoIcon} component. */
export interface ZaqTutoIconProps {
    /** The unique identifier of the owner ZaqTuto. */
    id: string;
    /** Identifier of the loaded icon. */
    icon?: string;
    /** Class name added to the <img> element. */
    className?: string;
}
