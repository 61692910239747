/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the resource interface.
import {
    Resource,
    MessageHelper,
    SingleMessageHelper,
    WritableMessageHelper,
} from "@andromeda/json-api";
// Import the Xhr package.
import { Xhr } from "@andromeda/xhr";
// Import the validation method.
import { validate } from "@andromeda/validation";

// Import the read options interface.
import { ReadOneOptions } from "./interfaces";
// Import the helper methods.
import {
    buildParams,
    extractUrl,
    PartialResource,
    parseValidatorAndOptions,
    PromisableValidateFunction,
    buildHeaders,
} from "./common";

/**
 * Updates a single resource on the server.
 * Seeks the target from the configuration.
 *
 * @template {Resource<T>} R
 * The updated resource type.
 * @param {PartialResource<R>} update The resource to update.
 * @param {ReadOneOptions | undefined} [options=undefined] Additional read options.
 * @returns {Promise<SingleMessageHelper<R> | null>} A promise that resolves with a message object
 * containing the requested resource, or a null if the update was accepted as-is.
 */
export function updateOne<R extends Resource>(
    update: PartialResource<R>,
    options?: ReadOneOptions
): Promise<SingleMessageHelper<R>>;

/**
 * Updates a single resource on the server.
 * Validates the received object.
 * Seeks the target from the configuration.
 *
 * @template {Resource<T>} R
 * The updated resource type.
 * @param {PartialResource<R>} update The resource to update.
 * @param {PromisableValidateFunction<R>>} validate
 * The validation function used to check the received resource.
 * @param {ReadOneOptions | undefined} [options=undefined] Additional read options.
 * @returns {Promise<SingleMessageHelper<R> | null>} A promise that resolves with a message object
 * containing the requested resource, or a null if the update was accepted as-is.
 */
export function updateOne<R extends Resource>(
    update: PartialResource<R>,
    validate: PromisableValidateFunction<R>,
    options?: ReadOneOptions
): Promise<SingleMessageHelper<R>>;

/** Implementation ! */
export async function updateOne<R extends Resource>(
    update: PartialResource<R>,
    validatorOrOptions?: PromisableValidateFunction<R> | ReadOneOptions,
    readOptions?: ReadOneOptions
): Promise<SingleMessageHelper<R>> {
    // Get the options object.
    const { validator, options } = parseValidatorAndOptions(
        validatorOrOptions,
        readOptions
    );

    // Prepare the options.
    const url: string = extractUrl(update.type, options);
    const body = new WritableMessageHelper(update);
    const searchParameters = buildParams(options);
    const headers = buildHeaders(options);

    // Run the request.
    const response = await Xhr.patch(url, {
        searchParameters,
        path: `/${update.type}/${update.id}`,
        body,
        headers,
    });
    if (
        response.body instanceof MessageHelper &&
        !Array.isArray(response.body.data)
    ) {
        // Validate the response.
        if (response.body.data !== null && typeof validator !== "undefined") {
            validate(response.body.data, await validator, true);
        }

        return response.body as SingleMessageHelper<R>;
    } else {
        return new SingleMessageHelper<R>(null);
    }
}
