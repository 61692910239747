/*
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the button.
import { Button } from "../../../../index";
// Import the context.
import { FullscreenContext } from "../../context";

// Import the icons.
import { faExpand, faCompress } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./fullscreen.module.scss";


/** Component used to render the fullscreen buttons of the controller bar. */
export default function Fullscreen(): ReactElement | null {
    // Load the controller context.
    const { isFullscreen, allowed, request, exit } = useContext(FullscreenContext);

    /** Helper used to call {@link request} or {@link exit} depending on {@link isFullscreen}. */
    function toggle(): void {
        if (isFullscreen) {
            exit();
        } else {
            request();
        }
    }

    // If the user cannot request for fullscreen, render nothing.
    if (!allowed) {
        return null;
    }

    // Render the component.
    return <div className={css["section"]}>
        <Button className={css["button"]} onClick={toggle}>
            <FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand} className={css["button__icon"]} />
        </Button>
    </div>;
}
