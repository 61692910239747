/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";

// Import the keys interface.
import { Keys } from "./interfaces";

// Import the css.
import css from "./header.module.scss";


/** Component used to render the header of the csv list. */
export function Header(props: HeaderProps): React.ReactElement {
    // Render the component.
    return <div className={css["header"]}>
        <div className={css["header__cell"]}>
            <p>email</p>
            <SelectHelper
                value={props.keys.email}
                from={props.availableKeys}
                onChange={value => props.updateKeys({ email: value as string })}
            />
        </div>
        <div className={css["header__cell"]}>
            <p>identifiant</p>
            <SelectHelper
                value={props.keys.username}
                from={props.availableKeys}
                onChange={value => props.updateKeys({ username: value })}
            />
        </div>
        <div className={css["header__cell"]}>
            <p>nom</p>
            <SelectHelper
                value={props.keys.givenName}
                from={props.availableKeys}
                onChange={value => props.updateKeys({ givenName: value })}
            />
        </div>
    </div>;
}

/** Props passed down to the {@link Header} component. */
interface HeaderProps {
    /** List of keys used for the fields. */
    keys: Keys;
    /** List of all available keys. */
    availableKeys: string[];
    /** Updates the provided list of keys. */
    updateKeys(keys: Partial<Keys>): void;
}

/** Helper component used to render a select. */
function SelectHelper(props: SelectHelperProps): React.ReactElement {
    // Render the options.
    const options = React.useMemo(function renderOptions(): React.ReactElement[] {
        const options: React.ReactElement[] = [];
        options.push(<option key={"__undefined"} value={"__undefined"} children={"N/A"} />);
        for (const item of props.from) {
            options.push(<option key={item} value={item} children={item} />);
        }
        return options;
    }, [props.from]);
    // Render the component.
    return <select
        value={props.value ?? "__undefined"}
        children={options}
        onChange={ev => {
            if (ev.currentTarget.value === "__undefined") {
                props.onChange(undefined);
            } else {
                props.onChange(ev.currentTarget.value);
            }
        }}
    />;
}

/** Props passed down to the {@link SelectHelper} component. */
interface SelectHelperProps {
    /** The list from which the selection can be made. */
    from: string[];
    /** The value of the selected element. */
    value: string | undefined;
    /** Handle invoked when the value changes. */
    onChange(value: string | undefined): void;
}
