/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API module.
import type { Filter } from "@andromeda/json-api";
// Import the External resource.
import { External } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the store.
import { ExternalStore, ResourceStore } from "../../stores";
// Import the resource hooks.
import useResources from "./util/use-resources";
import useResource from "./util/use-resource";


/**
 * Hook used to download an external from the store.
 *
 * @param {string | undefined} id The identifier of the External.
 * @param {boolean} [cache=true] Try to read from the cache if possible.
 * @return {RequestStatus<External>} The status of the request.
 */
export function useExternal(id: string | undefined, cache = true): RequestStatus<External> {
    return useResource(ExternalStore, External.Type, id, cache);
}

/**
 * Downloads all the External of the specified organisation.
 *
 * @param {string} organisation The organisation to load the External for.
 * @return {RequestStatus<External[]>} The status of the request.
 */
export function useExternals(organisation: string): RequestStatus<External[]> {
    // Prepare the filter used for the request.
    const filter: Filter = { organisation: { id: { $eq: organisation }}, };

    // Prepare the selector used to load the Externals.
    const selector = function selector(state: ResourceStore<External>): External[] {
        return state.resources.filter(function ExternalBelongsToOrg(external: External): boolean {
            return external.relationships.organisation.data.id === organisation;
        });
    };

    // Return the resources.
    return useResources(ExternalStore, External.Type, { filter }, selector);
}
