/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import AJV.
import type { ValidateFunction } from "ajv";
// Import Zod.
import z from "zod";
// Import the JSON schema converter.
import { zodToJsonSchema } from "zod-to-json-schema";
// Import the JSON schema tools.
import { compile } from "@andromeda/validation";


/** Type of the {@link ShareLink} resource. */
export type ShareLinkType = typeof ShareLinkType;
/** @see ShareLinkType */
export const ShareLinkType = "share-link";

/** Resource used to describe a shared link object. */
export type ShareLink = z.infer<typeof ShareLink>;
/** @see ShareLink */
export const ShareLink = z.object({
    type: z.literal(ShareLinkType),
    id: z.string().regex(/^[a-zA-Z0-9_-]{22}(?:==)?$/),
    attributes: z.object({
        /** Human-defined name of this link. */
        name: z.string(),
        description: z.string(),
        /** Flag set if the link is active. */
        active: z.boolean(),
        /** Time at which the link will become inactive, if applicable. */
        expires: z.string().datetime().nullable()
    }),
    relationships: z.object({
        /** Item that is being shared by this link. */
        zaq: z.object({
            data: z.object({
                type: z.literal("zaq"),
                id: z.string()
            })
        })
    }),
    meta: z.any(),
    links: z.any()
});
/** JSON-Schema validator version of the {@link ShareLink}. */
export const ShareLinkValidator: Promise<ValidateFunction<ShareLink>> = compile(zodToJsonSchema(ShareLink));

/** Object used to create a new share link in the database. */
export type CreateShareLink = z.infer<typeof CreateShareLink>;
/** @see CreateShareLink */
export const CreateShareLink = ShareLink.pick({ type: true, relationships: true }).extend({
    /** Attributes used to create a new share link. */
    attributes: z.object({
        /** Time at which the link will become inactive, if applicable. */
        expires: z.string().datetime().optional()
    }).optional(),
});

/** Object used to update a share link in the database. */
export type UpdateShareLink = z.infer<typeof UpdateShareLink>;
/** @see UpdateShareLink */
export const UpdateShareLink = ShareLink.pick({ type: true, id: true }).extend({
    /** Attributes used to create a new share link. */
    attributes: z.object({
        /** Human-defined name of this link. */
        name: z.string().optional(),
        /** Flag set if the link is active. */
        active: z.boolean().optional(),
        /** Time at which the link will become inactive, if applicable. */
        expires: z.string().datetime().nullable().optional()
    }).optional(),
});
