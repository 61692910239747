/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the web asset interface.
import { WebAsset } from "@andromeda/converter-lib";


/** Exhaustive list of all the conversion statuses. */
export enum ConversionStatus {
    /** Special value used to represent an uninitialized value. */
    uninitialized = -1,
    /** The conversion request was received, and is being prepared. */
    preparing,
    /** The conversion request was pushed to the converter's queue. */
    queued,
    /** The asset is being uploaded to the server. */
    uploading,
    /** The asset is being converted on the server. */
    converting,
    /** The asset is being downloaded back from the server. */
    downloading,
    /** The asset is being stored. */
    storing,
    /** The asset conversion is complete. */
    done
}

/** Base interface for all the conversion info types. */
interface ConversionInfoBase<T extends ConversionStatus> {
    /** Current stage of the conversion. */
    status: T;
}
/** Base interface the conversion info types with progress. */
interface ConversionInfoWithProgress<T extends ConversionStatus> extends ConversionInfoBase<T> {
    /** Progress of the current stage of conversion. */
    progress: number;
}

export type ConversionInfoUninitialized = ConversionInfoBase<ConversionStatus.uninitialized>;
export type ConversionInfoPreparing = ConversionInfoBase<ConversionStatus.preparing>;
export type ConversionInfoQueued = ConversionInfoBase<ConversionStatus.queued>;
export type ConversionInfoUploading = ConversionInfoWithProgress<ConversionStatus.uploading>;
export type ConversionInfoConverting = ConversionInfoWithProgress<ConversionStatus.converting>;
export type ConversionInfoDownloading = ConversionInfoWithProgress<ConversionStatus.downloading>;
export type ConversionInfoStoring = ConversionInfoWithProgress<ConversionStatus.storing>;
export interface ConversionInfoDone extends ConversionInfoBase<ConversionStatus.done> {
    /** List of assets generated by this conversion process. */
    assets: WebAsset[];
}

/** Union of all the conversion info types. */
export type ConversionInfo =
    ConversionInfoUninitialized | ConversionInfoPreparing | ConversionInfoQueued | ConversionInfoUploading |
    ConversionInfoConverting | ConversionInfoDownloading | ConversionInfoStoring | ConversionInfoDone;
