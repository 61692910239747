/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";
// Import React-Bootstrap.
import { NavItem, Dropdown, NavLink } from "react-bootstrap";
// Import the login context.
import { LoginContext } from "@andromeda/login";

// Import the subcomponents.
import PersonIcon from "./icon";
import UserMenu from "./menu";

// Import the css.
import css from "./index.module.scss";


/** Component used to render the current user and a dropdown menu inside it. */
export default function User(): ReactElement {
    // Get the name of the current user.
    const name = useContext(LoginContext).self.attributes.givenName;

    // Render the component.
    return <Dropdown as={NavItem} className={css["container"]}>
        <Dropdown.Toggle as={NavLink} className={css["button"]}>
            <PersonIcon className={css["icon"]} />
            <p className={css["username"]} children={name} />
        </Dropdown.Toggle>
        <UserMenu />
    </Dropdown>
}
