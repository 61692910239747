/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { useEffect, useState } from "react";
// Import the conversion asset class.
import { WebAsset } from "@andromeda/converter-lib";

// Import downloader.
import { downloadWebAsset } from "../lib";


/** Helper hook used to load a {@link WebAsset}. */
export function useAsset(id?: string): WebAsset | null | undefined {
    // Store the loaded web asset.
    const [asset, setAsset] = useState<WebAsset | null>();

    // Load the web-asset when the hook is mounted.
    useEffect(function(): void {
        // If the id is invalid, do nothing.
        if (!id) {
            return;
        }

        // Download the asset and store it.
        downloadWebAsset(id).then(setAsset, e => { console.warn(e); setAsset(null); });
    }, [id]);

    return asset;
}
