/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import {
    Context,
    createContext,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";


/** Context used to provide a toggleable switch in the react dom. */
export interface SwitchContext {
    /** Toggles the switch on or off. */
    toggle(): void;

    /** Forces the state of the switch. */
    setState(state: boolean): void;

    /** The current state of the switch. */
    state: boolean;
}

// Default state of the switch context.
const DEFAULT_STATE = {
    toggle: () => {
        window.alert(
            "The SwitchContext noop toggle was invoked !\n" +
            "Are you sure you added a provider above this Switch component ?"
        );
    },
    setState: () => {
        window.alert(
            "The SwitchContext noop setState was invoked !\n" +
            "Are you sure you added a provider above this Switch component ?"
        );
    },
    state: false
};

/** Context used for the switches of the application. */
export const SwitchContext = createContext<SwitchContext>(DEFAULT_STATE);

/** Provider for the {@link SwitchContext}. */
export function SwitchContextProvider(props: Props): ReactElement {
    // Store the state of the switch.
    const [state, setState] = useState(props.defaultState ?? false);

    // Wrapper used to invoke the onSwitch prop.
    const { onSwitch } = props;
    useEffect(() => {
        onSwitch && onSwitch(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSwitch]);
    const setStateWrapper = useCallback(function setStateWrapper(state: boolean): void {
        setState(state);
        if (onSwitch) {
            onSwitch(state);
        }
    }, [onSwitch]);

    // Prepare the value of the switch.
    const value = useMemo(function generateSwitchState(): SwitchContext {
        return {
            state,
            toggle: () => { setStateWrapper(!state); },
            setState: setStateWrapper
        };
    }, [setStateWrapper, state]);

    // Load the context to provide.
    const { Provider } = useMemo(function findSwitchContext(): Context<SwitchContext> {
        return props.context ?? SwitchContext;
    }, [props.context]);

    return <Provider value={value} children={props.children} />;
}

/** Props passed to the {@link SwitchContextProvider} component. */
interface Props {
    /** Children that will have access to this switch context. */
    children?: ReactNode;
    /** Default state of the switch. Defaults to "false". */
    defaultState?: boolean;

    /** Optional callback that can be invoked when the toggle is switched. */
    onSwitch?(state: boolean): void;

    /** Optional named context to provide. */
    context?: Context<SwitchContext>;
}

/** Creates a custom, named context. */
export function createNamedSwitchContext(name: string): Context<SwitchContext> {
    const context = createContext<SwitchContext>(DEFAULT_STATE);
    context.displayName = name;
    return context;
}
