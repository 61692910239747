/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";

// Import the context and its interfaces.
import { SearchBarContext, SearchBarSource, SearchBarMatch } from "./context";


/** Provider component for the {@link SearchBarContext}. */
export function SearchBarProvider<T>(props: SearchBarProviderProps<T>): React.ReactElement {
    // Store the text of the search bar.
    const [ text, setText ] = React.useState<string>(props.defaultValue ?? "");

    // Memoise the matches.
    const matches = React.useMemo(function memoiseMatches(): SearchBarMatch<T>[] {
        return findMatches(props.source, text).sort(compare);
    }, [ text, props.source ]);

    const notCreatableTexts = React.useMemo(function normaliseNonCreatables(): string[] {
        if (!props.notCreatableTexts) return [];
        return props.notCreatableTexts.map(normalise);
    }, [props.notCreatableTexts]);

    // Render the component.
    return <props.context.Provider
        value={{
            text,
            creatable: props.creatable === true && !notCreatableTexts.includes(normalise(text)),
            update: setText,
            matches,
            source: props.source,
            select(index: number) { props.onSelected?.(matches[index].source); },
            create() { props.onCreated?.call(undefined, text); }
        }}
        children={props.children}
    />;
}

/** Props passed down to the {@link SearchBarProvider} component. */
export interface SearchBarProviderProps<T> {
    /** Context object to provide. */
    context: React.Context<SearchBarContext<T>>;
    /** Source data for the search bar. */
    source: SearchBarSource<T>[];
    /** Flag to set if the search bar allows for new values. */
    creatable?: boolean;
    /** List of texts that cannot be created. */
    notCreatableTexts?: string[];

    /** Callback invoked when an item is selected. */
    onSelected?(item: SearchBarSource<T>): void;
    /** Callback invoked when an item is created. */
    onCreated?(text: string): void;

    /** Children with access to the context. */
    children?: React.ReactNode;
    /** Optional default value for the search bar text. */
    defaultValue?: string;
}

/** Function used to find all the matches for a given source and text. */
function findMatches<T>(sources: SearchBarSource<T>[], text: string): SearchBarMatch<T>[] {
    // If the text is empty, return a list of empty matches.
    if (text.length <= 0) return sources.map(source => ({ source, matches: [], score: Number.POSITIVE_INFINITY }));

    // Find all the matches in the source.
    const matches: SearchBarMatch<T>[] = [];
    const normalisedText = normalise(text);
    for (const source of sources) {
        // Get the matching texts.
        const textMatches: string[] = []; let score = Number.POSITIVE_INFINITY;
        for (const text of Array.isArray(source.text) ? source.text : [ source.text ]) {
            const index = normalise(text).indexOf(normalisedText);
            if (index >= 0) {
                score = index < score ? index : score;
                textMatches.push(text);
            }
        }

        if (textMatches.length > 0) {
            matches.push({ source, matches: textMatches, score });
        }
    }
    return matches;
}

/** Function used to sort a list of matches. */
function compare<T>(a: SearchBarMatch<T>, b: SearchBarMatch<T>): number {
    if (a.score === b.score) {
        const aTxt = typeof a.source.text === "string" ? a.source.text : a.source.text[0];
        const bTxt = typeof b.source.text === "string" ? b.source.text : b.source.text[0];
        return aTxt.localeCompare(bTxt);
    }
    return a.score > b.score ? 1 : -1;
}

// Helper function used to normalise a string.
export function normalise(value: string): string {
    return value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();
}
