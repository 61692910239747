/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the ajv interfaces.
import { AnySchema, ErrorObject, ValidateFunction as AJVValidateFunction } from "ajv";
import { SourceCode, Evaluated } from "ajv/dist/types";
import { ValidateFunction } from "../lib";


/**
 * Error thrown when the compilation of a schema fails.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 1
 */
export class CompilationError extends Error {
    /**
     * Creates a new {@link CompilationError} instance.
     *
     * @param {unknown} inner The inner error that occurred during the schema compilation.
     */
    public constructor(inner: unknown) {
        super(`Failed to compile a schema !\nInner error details: ${String(inner)}`);
    }
}

/**
 * Error thrown when the loading of a schema fails.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 1
 */
export class LoadingError extends Error {
    /**
     * Creates a new {@link LoadingError} instance.
     *
     * @param {string} uri The uri of the schema that failed to load.
     */
    public constructor(uri: string) {
        super(`Failed to load the schema at "${uri}"`);
    }
}

/**
 * Error thrown when a validation fails in non-failing mode.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 1
 */
export class ValidationError extends Error {
    /** Reference to the validation schema. */
    public readonly schema: AnySchema;
    /** Reference to the evaluated element. */
    public readonly evaluated: Evaluated | undefined;
    /** Source code of the schema. */
    public readonly source: SourceCode | undefined;
    /** Detailed error info. */
    public readonly errors: ErrorObject[] | null | undefined;

    /** @inheritDoc */
    public constructor(validator: ValidateFunction) {
        const source = (validator.length === 0) ? (validator as () => AJVValidateFunction)() : validator as AJVValidateFunction;

        // Build the error message.
        const message: string[] = [ "Failed to validate an object." ];
        for (const error of source.errors ?? []) {
            message.push(` ↳ ${error.message ?? ""}`);
            if (error.schemaPath) { message.push(`   ↳ Schema path: ${error.schemaPath} `); }
            if (error.instancePath) { message.push(`   ↳ Instance path: ${error.instancePath} `); }
            for (const [ key, value ] of Object.entries(error.params)) {
                message.push(`   ↳ ${key}: ${value}`);
            }
            message.push("");
        }

        super(message.join("\n"));
        this.schema = source.schema;
        this.evaluated = source.evaluated;
        this.source = source.source;
        this.errors = source.errors;
    }
}
