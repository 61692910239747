/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the user resource.
import { User } from "@andromeda/resources";

// Import the admin context.
import { AdminContext } from "../context";
// Import the subcomponents.
import { UserInfo } from "./user-info";
import { UserList, UserSortingMode } from "./user-list";
// Import the user list search bar.
import { UserListSearchBar } from "./context";

// Import the back arrow.
import arrow from "@andromeda/assets/images/arrow.svg";
import chevron from "@andromeda/assets/images/chevron.svg";
// Import the css.
import css from "./user-editor.module.scss";
import { SearchBarSource } from "@andromeda/components";


/** Component used to render the user editor. */
export function UserEditor(): React.ReactElement {
    // Store the currently selected user.
    const [ user, setUser ] = React.useState<User | null>(null);
    // Store the current sorting mode.
    const [ sortingMode, setSortingMode ] = React.useState<UserSortingMode>("member-asc");

    // Prepare the sources for the search bar.
    const { users } = React.useContext(AdminContext);
    const userSearchBarSource = React.useMemo(function prepareUserSources(): SearchBarSource<User>[] {
        return users.map(user => ({ data: user, key: user.id, text: user.attributes.givenName }));
    }, [users]);

    // Render the body of the editor.
    const children = React.useMemo(function renderEditorBody(): React.ReactElement {
        if (user) {
            return <>
                <InfoHeader clear={() => setUser(null)} />
                <UserInfo user={user.id} hide={() => setUser(null)} />
            </>;
        } else {
            return <>
                <ListHeader sortingMode={sortingMode} setSortingMode={setSortingMode} />
                <UserList select={setUser} sortingMode={sortingMode} />
            </>;
        }
    }, [sortingMode, user]);

    // Render the component.
    return <UserListSearchBar.Provider onSelected={user => setUser(user.data)} source={userSearchBarSource}>
        <div className={`${css["user-editor"]} mt-4`} children={children} />
    </UserListSearchBar.Provider>;
}

/** Header component of the user list. */
function ListHeader(props: ListHeaderProps): React.ReactElement {
    // Render the selection arrows.
    const renderArrows = React.useCallback(function renderArrows(className: string): React.ReactElement {
        return <div className={className}>
            <img src={chevron} alt="up" className={`${css["user-editor__arrow"]} ${css["user-editor__arrow--up"]}`}/>
            <img src={chevron} alt="down" className={`${css["user-editor__arrow"]} ${css["user-editor__arrow--down"]}`}/>
        </div>;
    }, []);
    const memberArrows = React.useMemo(function renderMemberArrows(): React.ReactElement {
        let className = css["user-editor__select-arrows"];
        if (props.sortingMode === "member-asc") className += " " + css["user-editor__select-arrows--up"];
        if (props.sortingMode === "member-desc") className += " " + css["user-editor__select-arrows--down"];

        return renderArrows(className);
    }, [props.sortingMode, renderArrows]);
    const accessArrows = React.useMemo(function renderAccessArrows(): React.ReactElement {
        let className = css["user-editor__select-arrows"];
        if (props.sortingMode === "access-asc") className += " " + css["user-editor__select-arrows--up"];
        if (props.sortingMode === "access-desc") className += " " + css["user-editor__select-arrows--down"];

        return renderArrows(className);
    }, [props.sortingMode, renderArrows]);

    // Compute the next member and access sorting mode.
    const nextMemberSortingMode = React.useMemo(function computeNextMemberSortingMode(): UserSortingMode {
        switch (props.sortingMode) {
        case "member-asc": return "member-desc";
        default: return "member-asc";
        }
    }, [props.sortingMode]);
    const nextAccessSortingMode = React.useMemo(function computeNextAccessSortingMode(): UserSortingMode {
        switch (props.sortingMode) {
        case "access-asc": return "access-desc";
        default: return "access-asc";
        }
    }, [props.sortingMode]);

    // Render the component.
    return <div className={css["user-editor__header"]}>
        <button
            className={`${css["user-editor__header__item"]} ${css["user-editor__header__item--name"]}`}
            onClick={() => props.setSortingMode(nextMemberSortingMode)}
        >
            <p>membre</p>
            {memberArrows}
        </button>
        <button
            className={`${css["user-editor__header__item"]} ${css["user-editor__header__item--role"]}`}
            onClick={() => props.setSortingMode(nextAccessSortingMode)}
        >
            <p>droits d'accès</p>
            {accessArrows}
        </button>
        <button
            className={`${css["user-editor__header__item"]} ${css["user-editor__header__item--actions"]}`}
        >
            <p>gestion</p>
        </button>
    </div>;
}
/** Props passed down to the {@link ListHeader} component. */
interface ListHeaderProps {
    /** Changes the current list order. */
    setSortingMode(order: UserSortingMode): void;
    /** Currently selected sorting order. */
    sortingMode: UserSortingMode;
}

/** Header component of the user info list. */
function InfoHeader(props: InfoHeaderProps): React.ReactElement {
    return <div className={css["user-editor__header"]}>
        <span className={`${css["user-editor__header__item"]} ${css["user-editor__header__item--back"]}`}>
            <button onClick={props.clear}><img src={arrow} alt="back" />retour</button>
        </span>
    </div>;
}

/** Props passed down to the {@link InfoHeader} component. */
interface InfoHeaderProps {
    /** Clears the currently selected user. */
    clear(): void;
}
