/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";

// Import the loader component.
import { Loader } from "@andromeda/components";

// Import the icons.
import download from "@andromeda/assets/images/download.svg";
import microphone from "@andromeda/assets/images/microphone.svg";
// Import the css.
import css from "./preparation.module.scss";
import { useClickForwarder } from "../../hooks";


/** Component used to prepare the capture. */
export function Preparation(props: PreparationProps): React.ReactElement {
    // State of the recorder preparation.
    const [ isPreparingRecorder, setIsPreparingRecorder ] = React.useState(false);

    // Memo used to generate the media recorder.
    const generateRecorder = React.useCallback(function generateMediaRecorder(): void {
        setIsPreparingRecorder(true);

        // Merge the streams and start the recording.
        navigator.mediaDevices.getUserMedia({
            video: false,
            audio: {
                suppressLocalAudioPlayback: true,
                noiseSuppression: true,
                autoGainControl: true,
                echoCancellation: true
            }
        })
            .then(stream => stream.getTracks())
            .then(tracks => new MediaStream(tracks))
            .then(stream => new MediaRecorder(stream, { audioBitsPerSecond: 128 * 1000 }))
            .then(props.start)
            .catch(e => {
                console.error(e);
                window.alert("Impossible de lancer la capture !");
                setIsPreparingRecorder(false);
            });
    }, [ props.start ]);

    // Extracts an audio file from the given target.
    const forwardClick = useClickForwarder();
    const extractFile = React.useCallback(function extractAudioFile(event: React.ChangeEvent<HTMLInputElement>): void {
        const file = event.currentTarget.files?.[0];
        if (!file) return;
        props.useFile(file);
    }, [ props ]);


    // Render the loader.
    if (isPreparingRecorder) {
        return <Loader
            className={css["preparation__loader"]}
            text="Préparation de votre enregistrement ..."
            transparent
        />;
    }

    // Render the component.
    return <div className={css["preparation"]}>
        <button className={css["preparation__button"]} onClick={generateRecorder}>
            <img src={microphone} alt="microphone-icon" />
            <p>capturer votre microphone</p>
        </button>
        <button className={css["preparation__button"]} onClick={forwardClick}>
            <img src={download} alt="microphone-icon" />
            <p>choisir un fichier</p>
            <input type="file" accept="audio/*" multiple={false} onChange={extractFile} className="d-none" />
        </button>
    </div>;
}

/** Props passed down to the {@link Preparation} component. */
interface PreparationProps {
    /** Starts a new recording session. */
    start(recorder: MediaRecorder): void;
    /** Provides a full file to render. */
    useFile(file: File): void;
}
