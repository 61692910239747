/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json:schema compiler
import { compileSync } from "@andromeda/validation";

// Import the schema.
import * as Schema from "./schema.json";


/** Interface used to request a converter instance. */
export interface AssetConverterRequest { type: AssetConverterRequest.Type; }

/** Augment the {@link AssetConverterRequest} interface. */
export namespace AssetConverterRequest {
    /** Type of {@link AssetConverterRequest} resources. */
    export const Type = "asset-conversion_converter-request" as const;
    export type Type = typeof Type;

    export const validate = compileSync<AssetConverterRequest>(Schema);
}
