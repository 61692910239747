/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the resources.
import { DueDate, Requirement } from "@andromeda/resources";


/** Object used to describe the metadata extracted from a requirement. */
export interface RequirementMetadata {
    /** The requested level for the */
    requestedLevel: Requirement.Level;
    objectiveMet: boolean;
    validated: boolean;
    date?: Date;
}

export function parseRequirement(requirement: Requirement, dueDate: DueDate | null = null): RequirementMetadata {
    // If the requirement has no details, return the default value.
    if (requirement.attributes.detail.length === 0) {
        return invalid();
    }

    // Check if the requirement should be validated.
    const validatable = requirement.attributes.detail.some(detail => ["zaq", "external"].includes(detail.item.type));

    // Get the last possible validation date.
    let notValidUntil: Date | undefined = undefined;
    if (dueDate !== null && dueDate.attributes.recurrence !== null) {
        notValidUntil = DueDate.getLastRecurrenceDate(DueDate.getDate(dueDate), dueDate.attributes.recurrence);
    }

    // Build the metadata.
    const metadata: RequirementMetadata = {
        requestedLevel: requirement.attributes.requiredLevel,
        objectiveMet: true,
        validated: validatable
    };
    for (const detail of requirement.attributes.detail) {
        // Ignore if the details are out-of-date.
        if (typeof notValidUntil !== "undefined") {
            if (!detail.date || new Date(detail.date) < notValidUntil) {
                return invalid();
            }
        }

        // Check the type of detail.
        if (Requirement.isZaqDetail(detail)) {
            metadata.objectiveMet &&= detail.level !== null && detail.level >= metadata.requestedLevel;
            metadata.validated &&= detail.validated;
        } else if (Requirement.isTutoDetail(detail) || Requirement.isWikiDetail(detail)) {
            metadata.objectiveMet &&= detail.viewed;
        } else if (Requirement.isExternalDetail(detail)) {
            metadata.validated &&= detail.level !== null && detail.level >= metadata.requestedLevel;
            metadata.objectiveMet &&= metadata.validated;
        } else {
            return invalid();
        }
    }
    return metadata;

    /** Helper used to build an invalid metadata object. */
    function invalid(): RequirementMetadata {
        return { requestedLevel: requirement.attributes.requiredLevel, objectiveMet: false, validated: false };
    }
}
