/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the Asset resource.
import { Asset } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the store.
import { AssetStore } from "../../stores";
// Import the resource hooks.
import useResource from "./util/use-resource";


/**
 * Hook used to download an Asset from the store.
 *
 * @param {string | undefined} id The identifier of the Asset.
 * @param {boolean} [cache=false] Try to read from the cache if possible.
 * @return {RequestStatus<Asset>} The status of the request.
 */
export function useAsset(id: string | undefined, cache = false): RequestStatus<Asset> {
    return useResource(AssetStore, Asset.Type, id, cache);
}
