/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, MouseEvent, useCallback } from "react";
// Import React-Router.
import { useNavigate } from "react-router-dom";
import { useCourse } from "@andromeda/store";
import { Loader } from "@andromeda/storybook";
// Import the warning confirm.
import { useWarningConfirm } from "@andromeda/components";

// Import the custom store.
import { useWasModified } from "../../store";
// Import the subcomponents.
import Back from "../back";
import Title from "../title";


/** Renders the title of the validation screen. */
export default function ValidationTitle(props: ValidationTitleProps): ReactElement {
    const course = useCourse(props.course);
    const warning = useWarningConfirm(
        "Êtes vous sûr de vouloir quitter la validation?\nTout changement non sauvegardé sera perdu."
    );
    const modified = useWasModified();

    // Callback used to prevent an unwanted return.
    const navigate = useNavigate();
    const preventUnwantedReturn = useCallback(function (event: MouseEvent<HTMLLinkElement>): void {
        if (modified) {
            event.preventDefault();
            const { href } = event.currentTarget;
            warning().then(shouldReturn => {
                if (shouldReturn) {
                    navigate(new URL(href).pathname);
                }
            });
        }
    }, [modified, navigate, warning]);

    // Render the component.
    if (!course.isSuccess) {
        return <Loader text="Chargement du parcours de formation ..." />;
    }
    return <>
        <Back course={course.data.id} onClick={preventUnwantedReturn} />
        <Title title={course.data.attributes.name} />
    </>;
}

/** Props of the validation title. */
interface ValidationTitleProps {
    /** Identifier of the current course. */
    course: string | undefined;
}
