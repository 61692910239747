/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

import { useCallback, useEffect, useState } from "react";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
import { createOne } from "@andromeda/resource-helper";
import { extractURL, Resource } from "@andromeda/json-api";
import { loadWebAsset, storeWebAsset } from "@andromeda/asset-manager";


/**
 * Hook used to query a download link from the store.
 *
 * @param {string} id The identifier of the asset that should be downloaded.
 * @return {RequestStatus<any>} The status of the request.
 */
export function useV2Asset(id?: string): RequestStatus<Blob | null> {
    // Download the data from the link.
    const [asset, setAsset] = useState<RequestStatus<Blob | null>>(RequestStatus.uninitialised);

    // Callback used to download the asset.
    const downloadAsset = useCallback(async function(id: string, signal?: AbortSignal): Promise<Blob> {
        // Set the loading flag.
        setAsset(RequestStatus.loading());

        // Check if the asset is stored locally.
        const stored = await loadWebAsset(id);
        if (stored) {
            return new Blob([stored.data], { type: stored.resource.attributes.contentType });
        }

        // Create a new download link.
        const { data: link } = await createOne<Resource>({
            type: "download-link",
            id: window.crypto.randomUUID(),
            relationships: { asset: { data: { type: "asset", id }}}
        }, { signal });

        // Check if the link is valid.
        if (!link || !link.links?.["target"]) {
            throw new Error("Failed to create a download link");
        }

        // Download the target.
        const target = extractURL(link.links["target"]);
        const data = await fetch(target, { mode: "cors", signal }).then(r => r.blob());

        // Store the asset locally.
        await storeWebAsset({
            data: await data.arrayBuffer(),
            resource: {
                type: "asset",
                id,
                attributes: {
                    contentType: data.type as `image/${string}`,
                    width: -1,
                    height: -1,
                    size: -1
                },
                relationships: {
                    source: { data: null },
                    alternatives: { data: [] },
                    encryptionSource: { data: null }
                }
            }
        });

        return data;
    }, []);

    // Create the link and download the asset after the first render.
    useEffect(function() {
        const abort = new AbortController();

        // Run the code on the next frame.
        const timeout = setTimeout(function() {
            // Wait for an identifier to be provided.
            if (!id) {
                return setAsset(RequestStatus.success(null));
            }

            downloadAsset(id, abort.signal)
                .then(asset => RequestStatus.success(asset))
                .catch(error => RequestStatus.error(error))
                .then(setAsset);
        });

        // Clear the timeout.
        return function() {
            abort.abort();
            clearTimeout(timeout);
        }
    }, [downloadAsset]);

    return asset;
}
