/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
import { useMemo } from "react";
// Import the file resource.
import { Session, ZaqWikiFile } from "@andromeda/resources";

// Import the file context.
import { FileContext } from "./context";
// Import the reder context.
import { ReaderContext } from "../context";
// Import the subcomponents.
import { Header } from "./header";
import { Body } from "./body";

// Import the css.
import css from "./file.module.scss";


/** Component used to render a given file. */
export function File(props: Props): React.ReactElement {
    const context = React.useContext(ReaderContext);
    // Store the internal expansion flag.
    const [isExpanded, setExpanded] = React.useState(props.defaultExpanded === true);

    const { seen, opened } = React.useMemo(function loadSeenAndOpened(): Session.WikiFileDetail {
        return context.session.attributes.files.find(progress => progress.id === props.file.id) ??
            { id: props.file.id, seen: false, opened: false };
    }, [context.session.attributes.files, props.file.id]);

    // Build the file context.
    const fileContext = useMemo(function buildFileContext(): FileContext {
        return {
            file: props.file,
            expanded: isExpanded,
            toggleExpanded: () => setExpanded(!isExpanded),
            seen, opened,
            markAsOpened() {
                if (!opened) {
                    context.updateFile(props.file.id, true, seen);
                }
            },
            markAsSeen() {
                if (!seen) {
                    context.updateFile(props.file.id, true, true);
                }
            }
        };
    }, [context, isExpanded, opened, props.file, seen]);

    // Render the component.
    return <FileContext.Provider value={fileContext}>
        <div className={`mt-3 ${css["file"]}`}>
            <Header />
            <Body />
        </div>
    </FileContext.Provider>;
}

/** Props passed down to the {@link File} component. */
interface Props {
    /** Interface used to represent the file to render. */
    file: ZaqWikiFile;
    /** If true, the file is expanded by default */
    defaultExpanded?: boolean;
}
