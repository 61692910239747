/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json:api interfaces.
import {
    Resource,
    RelationshipWithData, ResourceIdentifier
} from "@andromeda/json-api";
// Import the schema compiler.
import { compileSync } from "@andromeda/validation";

// Import the schema.
import { UserV2Schema } from "./schema";

/** Interface used to represent a V2 user. */
export interface UserV2 extends Resource<UserV2.Type> {
    /** @inheritDoc */
    attributes?: never;
    /** @inheritDoc */
    relationships: UserV2.Relationships;
    /** @inheritDoc */
    meta?: never;
}

export namespace UserV2 {
    export const Type = "user" as const;
    export type Type = typeof Type;

    export type Attributes = never;

    export interface Relationships {
        "favourite-items": RelationshipWithData<ResourceIdentifier[]>
    }

    export const validate = compileSync<UserV2>(UserV2Schema.ResourceSchema);
    export const Schema = UserV2Schema;

    /** Interface used to describe a user update resource. */
    export interface Update extends Resource<UserV2.Type> {
        /** @inheritDoc */
        attributes?: never;
        /** @inheritDoc */
        relationships?: Partial<UserV2.Relationships>;
        /** @inheritDoc */
        meta?: never;
    }

    export namespace Update {
        export const validate = compileSync<UserV2.Update>(
            UserV2Schema.UpdateSchema
        );
    }
}
