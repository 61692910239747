/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the resources.
import { Requirement, Step, Validation } from "@andromeda/resources";
// Import the JSON:API module..
import { ResourceIdentifier } from "@andromeda/json-api";

// Import the custom interfaces.
import {
    ExternalProgress,
    ItemProgress,
    StepProgress,
    ZaqTutoPlusProgress,
    ZaqTutoProgress,
    ZaqWikiProgress
} from "./interfaces";
// Import the item parser.
import parseItemProgress from "./parse-item-progress";


/**
 * Parses the provided step's progress.
 *
 * @param {Step} step The step to parse.
 * @param {Requirement} requirement The requirement of the parsed step.
 * @param {boolean} locked Flag that should be set if this step is "locked".
 * @param {Validation[]} validations All the validations of the user for the current course.
 * @return {StepProgress} The progress of the step.
 */
export default function parseStepProgress(
    step: Step,
    requirement: Requirement,
    locked: boolean,
    validations: Validation[]
): StepProgress {
    // Prepare the progress object.
    const items = new Map<`${string}.${string}`, ItemProgress>();
    let complete = !locked, objectiveMet: boolean | undefined = undefined, validated: boolean | undefined = undefined;
    let levelSum = 0, levelCount = 0, lastValidation: Date | undefined = undefined;
    for (const detail of requirement.attributes.detail) {
        // Get the step's progress.
        const progress = parseItemProgress(detail, locked, validations);

        // Check the type of the detail.
        if (progress.type === "zaq") {
            // Check the progress of the item.
            objectiveMet = (objectiveMet ?? true)
                && progress.level !== null
                && progress.level >= requirement.attributes.requiredLevel;
            validated = (validated ?? true) && progress.validated;
            complete = (complete ?? true) && objectiveMet;
            levelSum += (progress.level ?? -1) + 1;
            levelCount++;
        } else if (progress.type === "external") {
            // Check the progress of the item.
            validated = (validated ?? true)
                && progress.level !== null
                && progress.level >= requirement.attributes.requiredLevel;
            levelSum += (progress.level ?? -1) + 1;
            levelCount++;
        } else {
            // Check the progress of the item.
            objectiveMet = (objectiveMet ?? true) && progress.viewed;
            complete = (complete ?? true) && objectiveMet;
        }

        // Check if the item was validated.
        if ("lastValidation" in progress && progress.lastValidation) {
            if (lastValidation && progress.lastValidation > lastValidation) {
                lastValidation = progress.lastValidation;
            } else {
                lastValidation = progress.lastValidation;
            }
        }

        // Add the progress to the map.
        items.set(`${detail.item.type}.${detail.item.id}`, progress);
    }

    // Compute the average level.
    let averageLevel: Requirement.Level | null = null;
    if (!locked && levelCount >= 1) {
        averageLevel = Math.min(Math.floor((levelSum / levelCount) - 1), Requirement.Level.expert);
    }

    // Return the progress.
    return {
        complete, objectiveMet, validated, locked, lastValidation,
        requiredLevel: requirement.attributes.requiredLevel,
        averageLevel,
        item,
        [Symbol.iterator](): Iterator<ItemProgress> {
            return items.values();
        }
    };

    /** @see StepProgress.item */
    function item(type: "zaq", id: string): ZaqTutoPlusProgress | undefined;
    /** @see StepProgress.item */
    function item(type: "zaq-tuto", id: string): ZaqTutoProgress | undefined;
    /** @see StepProgress.item */
    function item(type: "zaq-wiki", id: string): ZaqWikiProgress | undefined;
    /** @see StepProgress.item */
    function item(type: "external", id: string): ExternalProgress | undefined;
    /** @see StepProgress.item */
    function item(type: "zaq" | "zaq-tuto" | "zaq-wiki" | "external", id: string): ItemProgress | undefined;
    /** @see StepProgress.item */
    function item(id: ResourceIdentifier<"zaq">): ZaqTutoPlusProgress | undefined;
    /** @see StepProgress.item */
    function item(id: ResourceIdentifier<"zaq-tuto">): ZaqTutoProgress | undefined;
    /** @see StepProgress.item */
    function item(id: ResourceIdentifier<"zaq-wiki">): ZaqWikiProgress | undefined;
    /** @see StepProgress.item */
    function item(id: ResourceIdentifier<"external">): ExternalProgress | undefined;
    /** @see StepProgress.item */
    function item(id: ResourceIdentifier<"zaq" | "zaq-tuto" | "zaq-wiki" | "external">): ItemProgress | undefined;
    /** @see StepProgress.item */
    function item(
        type:
            | "zaq"
            | "zaq-tuto"
            | "zaq-wiki"
            | "external"
            | ResourceIdentifier<"zaq" | "zaq-tuto" | "zaq-wiki" | "external">,
        id?: string
    ): ItemProgress | undefined {
        if (typeof type === "object") {
            id = type.id; type = type.type;
        }
        return items.get(`${type}.${id}`);
    }
}
