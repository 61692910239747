/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";
// Import the legacy zaq tools.
import type { LegacyZaq } from "@andromeda/legacy-zaq";
// Import the resources.
import type { Course, ZaqWiki } from "@andromeda/resources";

// Import the subcomponents.
import ZaqTutoItem, { ZaqTutoItemWrapper } from "./zaq-tuto";
import ZaqTrainingItem, { ZaqTrainingItemWrapper } from "./zaq-training";
import ZaqItem, { ZaqItemWrapper } from "./zaq";


/** Component used to render any item on the screen. */
export default function Item(props: ItemProps): ReactElement | null {
    // Extract the resource from the props.
    const { resource } = props;

    // Render the proper item.
    switch (resource.type) {
    case "zaq-tuto":
        return <ZaqTutoItem resource={resource} withButtons={props.withButtons} />;
    case "zaq-wiki":
        return <ZaqItem resource={resource} withButtons={props.withButtons} />;
    case "course":
        return <ZaqTrainingItem resource={resource} withButtons={props.withButtons} />;
    default:
        return null;
    }
}


/** Props passed down to the {@link Item} component. */
export interface ItemProps {
    /** Resource being rendered. */
    resource: LegacyZaq | ZaqWiki | Course;
    /** Flag set if the edit and delete button should be shown. */
    withButtons?: boolean;
}

/** Wrapper component used to download the requested item from the store before rendering its component. */
export function ItemWrapper(props: ZaqTrainingItemWrapperProps): ReactElement | null {
    switch (props.resource.type) {
    case "zaq-tuto":
        return <ZaqTutoItemWrapper {...props} id={props.resource.id} />;
    case "zaq-wiki":
        return <ZaqItemWrapper {...props} id={props.resource.id} />;
    case "course":
        return <ZaqTrainingItemWrapper {...props} id={props.resource.id} />;
    default:
        return null;
    }
}

/** Props passed down to the {@link ZaqTrainingItemWrapper} component. */
export interface ZaqTrainingItemWrapperProps extends Omit<ItemProps, "resource"> {
    /** Type of item to render. */
    resource: ResourceIdentifier;
    /** CSS class name added to the loader container. */
    loaderClassName?: string;
}
