/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement, ReactNode } from "react";
// Import the resources.
import type { Step } from "@andromeda/resources";

// Import the subcomponents.
import TutoItem from "./tuto";
import TutoPlusItem from "./tuto-plus";
import WikiItem from "./wiki";
import ExternalItem from "./external";


/** Component used to render an item. */
export default function Item(props: ItemProps): ReactElement | null {
    switch (props.item.type) {
    case "zaq-tuto": return <TutoItem step={props.step} item={props.item} children={props.children} />;
    case "zaq": return <TutoPlusItem step={props.step} item={props.item} children={props.children} />;
    case "zaq-wiki": return <WikiItem step={props.step} item={props.item} children={props.children} />;
    case "external": return <ExternalItem step={props.step} item={props.item} children={props.children} />;
    default: return null;
    }
}

/** Props passed down to the {@link Item} component. */
export interface ItemProps {
    /** Identifier of the item to render. */
    item: Step.Item;
    /** Identifier of the current step. */
    step: string;

    /** Children component rendered under the item. */
    children?: ReactNode;
}
