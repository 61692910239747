/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";
import { toRomanNumeral } from "@andromeda/tools";

// Import the css.
import css from "./select.module.scss";


/** Component used to select a page on the table. */
export function PageSelect(props: PageSelectProps): React.ReactElement | null {
    // Render the options.
    const options = React.useMemo(function renderOptions(): React.ReactElement[] {
        return props.names.map((name: string, index: number) => <option
            key={index} value={index} children={index === 0 ? name : `${toRomanNumeral(index)}. ${name}`}
        />);
    }, [props.names]);

    // Forwards the click to the "select" child.
    const forwardClick = React.useCallback(function forwardClick(event: React.SyntheticEvent<HTMLButtonElement>): void {
        event.currentTarget.getElementsByTagName("select")[0]?.click();
    }, []);

    // If there is only one page, render nothing.
    if (props.names.length <= 1) return null;
    return <button className={css["page-select"]} onClick={forwardClick}>
        <select
            value={props.current}
            onChange={ev => props.update(parseInt(ev.currentTarget.value))}
            children={options}
        />
    </button>;
}

/** Props passed down to the {@link PageSelect} component. */
interface PageSelectProps {
    /** The names of the pages to show. */
    names: string[];
    /** The currently selected page. */
    current: number;
    /** Method used to update the page number. */
    update(pageNumber: number): void;
}
