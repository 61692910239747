/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useState } from "react";
// Import the FontAwesome component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the resources.
import { Step } from "@andromeda/resources";
// Import the store.
import { StepStore, useResourceDispatch, useStep } from "@andromeda/store";

// Import the local contexts.
import { ExternalModalProvider } from "../../../../../context";
// Import the subcomponents.
import TabSelector, { Tab } from "./tab-selector";
import AddZaqTuto from "./zaq-tuto";
import AddZaqWiki from "./zaq-wiki";
import AddExternal from "./external";
import ItemSearchBar from "./search-bar";

// Import the icons.
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./index.module.scss";


/** Component used to render the "add" menu. */
export default function AddItemMenu(props: AddItemMenuProps): ReactElement | null {
    // Store the currently selected tab.
    const [tab, setTab] = useState<Tab>("zaq-tuto");
    // Store the currently selected item.
    const [selected, setSelected] = useState<Step.Item>();
    const select = useCallback(function select(item: Step.Item): void {
        setTab(item.type === "zaq" ? "zaq-tuto" : item.type);
        setSelected(item);
    }, []);

    // Callback used to add the selected item to the current step.
    const step = useStep(props.step);
    const dispatch = useResourceDispatch();
    const addToStep = useCallback(function addItemToStep(): void {
        if (!selected || !step.isSuccess) {
            return;
        }

        // Dispatch the update to the step.
        const update: Step.Update = {
            type: Step.Type, id: props.step,
            relationships: {
                items: {
                    data: [
                        ...step.data.relationships.items.data,
                        { type: selected.type, id: selected.id }
                    ]
                }
            }
        };
        dispatch(StepStore.generator.update(update, { include: ["requirement"] })).catch(console.error);
        setSelected(undefined);
    }, [dispatch, props.step, selected, step.data?.relationships.items.data, step.isSuccess]);

    // Render the component.
    if (!props.show) {
        return null;
    }
    return <ExternalModalProvider>
        <TabSelector current={tab} onChange={setTab} />
        <div className={css["body"]}>
            {tab === "zaq-tuto" ? <AddZaqTuto step={props.step} select={select} selected={selected} /> : null}
            {tab === "zaq-wiki" ? <AddZaqWiki select={select} selected={selected} /> : null}
            {tab === "external" ? <AddExternal select={select} selected={selected} /> : null}
            <ItemSearchBar select={select} />
            <div className={css["button-container"]}>
                <button className={`${css["button"]} ${css["button--cancel"]}`} onClick={props.cancel}>
                    <FontAwesomeIcon icon={faXmark} />
                    Annuler
                </button>
                <button
                    className={`${css["button"]} ${css["button--add"]}`}
                    disabled={typeof selected === "undefined"}
                    onClick={addToStep}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    Ajouter
                </button>
            </div>
        </div>
    </ExternalModalProvider>;
}

/** Props passed down to the {@link AddItemMenu} component. */
export interface AddItemMenuProps {
    /** Flag that should be set to show the element. */
    show: boolean;
    /** Step that is being modified. */
    step: string;
    /** Callback invoked to cancel the menu. */
    cancel(): void;
}
