/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { createContext, useContext, useCallback, ReactNode, ReactElement, useMemo } from "react";
// Import the xhr class.
import { Xhr } from "@andromeda/xhr";

// Import the notification context.
import { NotificationContext } from "./context";
// Import the notification object.
import { Notification } from "./notification";


/** Context used to specify where the error reports will be sent. */
export interface DiscordWebhookContext {
    /** Identifier of the webhook. */
    id: string;
    /** Token of the webhook. */
    token: string;
}

// Default state of the discord webhook context.
const DEFAULT_STATE: DiscordWebhookContext = {
    id: "970976616689856512", token: "i9AYq0PuDuGHM4cE9ttgQF-NQgYBk40Rl2vxLcENOJpN6SYn2iwROkjJvRAegzCnKZWN"
};

/** Context used to provide a discord error reporting context. */
export const DiscordWebhookContext = createContext<DiscordWebhookContext>(DEFAULT_STATE);

export function DiscordWebhookProvider(props: DiscordWebhookProviderProps): ReactElement {
    return <DiscordWebhookContext.Provider value={{ id: props.id, token: props.token }} children={props.children} />;
}

/** Props provided to the {@link DiscordWebhookContext} component. */
interface DiscordWebhookProviderProps extends DiscordWebhookContext { children: ReactNode; }

/** Interface used to describe the error report that will be sent via discord. */
export interface ErrorReport {
    /** Optional title of the error. */
    title?: string | null;
    /** Text of the reported error. */
    text?: string | null;
    /** Actual body of the reported error. */
    error: unknown;
}

/**
 * Hook used to generate a discord error report button.
 *
 * @returns {(report: ErrorReport) => void} The function to call to report an error on discord.
 */
export function useDiscordMessageHook(context?: DiscordWebhookContext): (report: ErrorReport) => Promise<void> {
    // Get the notification context.
    const notify = useContext(NotificationContext);

    // Callback used to invoke the discord endpoint.
    const defaultContext = useContext(DiscordWebhookContext);
    const { id, token } = useMemo(() => context ?? defaultContext, [context, defaultContext]);
    const invokeWebhook = useCallback(function invokeDiscordWebhook(content: string): Promise<void> {
        // Slice the contents.
        if (content.length > 2000) content = content.slice(0, 1997) + "...";

        // Invoke the callback.
        return Xhr.post(
            `https://discord.com/api/webhooks/${id}/${token}`,
            {
                body: JSON.stringify({ content }),
                headers: { "Content-Type": "application/json" }
            }
        )
            .then(() => { /* Discard the response. */ })
            .catch(error => {
                notify.push({
                    type: Notification.Type.error,
                    text: "Impossible d'envoyer cette erreur aux développeurs !",
                    error
                });
            });
    }, [id, notify, token]);


    // Return the callback.
    return useCallback(function errorReporter(report: ErrorReport): Promise<void> {
        // Build the string.
        let contents = "";
        if (report.title) contents += `***${report.title}***\n`;
        if (report.text) contents += `${report.text}\n`;
        contents += "```";
        contents += String(report.error);
        if (typeof report.error === "object" && report.error !== null && "stack" in report.error) {
            contents += "\n\n" + (report.error as { stack: unknown }).stack;
        }
        contents += "```";

        return invokeWebhook(contents);
    }, [ invokeWebhook ]);
}
