/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useMemo } from "react";

// Import the css.
import css from "./index.module.scss";
import titleCss from "./title.module.scss";


/**
 * Helper hook used to compute all the component class names.
 *
 * @param {ClassNameProps} props The props that were passed to the BoxHeader component.
 * @param {boolean} open Flag that should be set if the box is open.
 * @param {boolean} locked Flag that should be set if the box is locked.
 * @return {ClassNames} The class names that should apply to the component.
 */
export default function useClassNames(props: ClassNameProps, open: boolean, locked: boolean): ClassNames {
    // Compute the header class name.
    const className = useMemo(function getClassName(): string {
        let className = css["header"];
        if (open) {
            className += ` ${css["header--open"]}`;
        }
        if (props.className) {
            className += ` ${props.className}`;
        }
        return className;
    }, [open, props.className]);

    // Compute the icon container class name.
    const iconContainerClassName = useMemo(function getClassName(): string {
        let className = css["header__icon-container"];
        if (props.iconContainerClassName) {
            className += ` ${props.iconContainerClassName}`;
        }
        return className;
    }, [props.iconContainerClassName]);

    // Compute the title class name.
    const titleClassName = useMemo(function getClassName(): string {
        let className = titleCss["title"];
        if (props.titleClassName) {
            className += ` ${props.titleClassName}`;
        }
        return className;
    }, [props.titleClassName]);

    // Compute the control container class name.
    const controlContainerClassName = useMemo(function getClassName(): string {
        let className = css["header__control-container"];
        if (props.controlContainerClassName) {
            className += ` ${props.controlContainerClassName}`;
        }
        return className;
    }, [props.controlContainerClassName]);

    // Compute the chevron class name.
    const chevronClassName = useMemo(function getClassName(): string {
        let className = css["header__chevron"];
        if (locked) {
            className += ` ${css["header__chevron--locked"]}`;
        }
        if (props.chevronClassName) {
            className += ` ${props.chevronClassName}`;
        }
        return className;
    }, [locked, props.chevronClassName]);


    // Merge all the class names together.
    return useMemo(function combineClassNames(): ClassNames {
        return { className, iconContainerClassName, titleClassName, controlContainerClassName, chevronClassName };
    }, [chevronClassName, className, controlContainerClassName, iconContainerClassName, titleClassName]);
}

/** Interface used to describe the class name props for the box header. */
export type ClassNameProps = Partial<ClassNames>;

/** Interface used to describe the return type of {@link useClassNames}. */
interface ClassNames {
    /** Class name for the box header. */
    className: string;
    /** Class name for the chevron. */
    chevronClassName: string;
    /** Class name for the icon container. */
    iconContainerClassName: string;
    /** Class name for the title span. */
    titleClassName: string;
    /** Class name for the control container. */
    controlContainerClassName: string;
}
