/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { createContext, ReactElement, ReactNode, useEffect, useRef, useState } from "react";


/** List of all the fitting modes for the PDF pages. */
export type PDFPageFit = "width" | "height" | "contain";

/** Object used to define the container of a PDF page. */
export interface PDFContainerContext {
    /** The Rect of the element used as a container for the document. */
    container: DOMRectReadOnly;

    /**
     * Defines how the pdf should be fitted to the parent.
     * If set to "width", the page will take the entire width of the parent.
     * If set to "height", the page will take the entire height of the parent.
     * If set to "contain", the page will be contained within the parent.
     *
     * @default "contain"
     */
    fit: PDFPageFit;
}

/** Context used to define the size of  */
export const PDFContainerContext = createContext<PDFContainerContext>({
    container: new DOMRectReadOnly(), fit: "contain"
});
PDFContainerContext.displayName = "PDFContainerContext";

/** Component used to provide the {@link PDFContainerContext} to the tree. */
export default function PDFContainerProvider(props: PDFContainerProviderProps): ReactElement | null {
    // Attach a resize observer to the container element.
    const [container, setContainer] = useState<DOMRectReadOnly | undefined>();
    const observer = useRef(new ResizeObserver(entries => setContainer(entries[entries.length - 1].contentRect)));
    useEffect(function listenForContainerResize(): void | (() => void) {
        // Get a local copy of the prop.
        const container = props.container;

        // Attach the observer.
        if (container) {
            // setContainer(container.getBoundingClientRect());
            observer.current.observe(container);
            return observer.current.unobserve.bind(observer.current, container);
        }
    }, [props.container]);

    if (typeof container === "undefined" || container.width <= 0 || container.height <= 0) {
        return null;
    }
    return <PDFContainerContext.Provider
        value={{ container, fit: props.fit ?? "contain" }}
        children={props.children}
    />;
}

/** Props passed down to the {@link PDFContainerProvider} component. */
export interface PDFContainerProviderProps {
    /** Reference to the container that will hold the document. */
    container: HTMLElement | null | undefined;
    /**
     * Defines how the pdf should be fitted to the parent.
     * If set to "width", the page will take the entire width of the parent.
     * If set to "height", the page will take the entire height of the parent.
     * If set to "contain", the page will be contained within the parent.
     *
     * @default "contain"
     */
    fit?: PDFPageFit;

    /** Children that will have access to the document. */
    children?: ReactNode;
}
