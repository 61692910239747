/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the JSON object and list of forbidden keys.
import { JsonObject, ForbiddenKeys } from "./common";
// Import the relationship interface.
import { Relationship, EnforceRelationships } from "./relationship";
// Import the links interface.
import { AnyLinks } from "./links";
// Import the metadata interface.
import { Metadata } from "./meta";
// Import the resource identifier base class.
import { ResourceIdentifier } from "./resource-identifier";


/** Helper type used to forbid some attribute property names. */
type EnforceAttributes<T> = { [K in keyof T]: K extends ForbiddenKeys ? never : T[K] };

/**
 * Interface used to describe resources with some attributes.
 * The attributes cannot have keys named "type", "id", "relationships" or "links".
 */
interface ResourceWithAttributes<
    Type extends string = string,
    Attributes extends EnforceAttributes<Attributes> = EnforceAttributes<JsonObject>
> extends ResourceIdentifier<Type> {
    /** List of attributes for the resource. */
    attributes?: Attributes;
}

/**
 * Interface used to describe resources some relationships.
 * The relationships cannot have keys named "type", "id", "attributes" or "links".
 */
interface ResourceWithRelationships<
      Type extends string = string,
      Relationships extends EnforceRelationships<Relationships> = EnforceRelationships<Record<string, Relationship>>
> extends ResourceIdentifier<Type> {
    /** List of relationships for the resource. */
    relationships?: Relationships;
}

/** Interface used to describe resources with some links. */
interface ResourceWithLinks<Type extends string = string> extends ResourceIdentifier<Type> {
    /** List of all the links of the resource. */
    links?: AnyLinks;
}

/** Interface used to describe resources with some metadata. */
interface ResourceWithMeta<
    Type extends string = string,
    Meta extends Metadata = Metadata
> extends ResourceIdentifier<Type> {
    /** Metadata of the resource. */
    meta?: Meta;
}


/**
 * Interface used to describe the JSON:API resource interface.
 * A resource MUST have at least an id and a type property.
 * They can have an attributes, relationship, links or meta property as well.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export type Resource<
    Type extends string = string,
    Attributes extends EnforceAttributes<Attributes> = EnforceAttributes<JsonObject>,
    Relationships extends EnforceRelationships<Relationships> = EnforceRelationships
> = ResourceWithAttributes<Type, Attributes> & ResourceWithRelationships<Type, Relationships> & ResourceWithLinks<Type> & ResourceWithMeta<Type>;

/**
 * Interface used to describe the JSON:API resource interface.
 * This interface is to be used ONLY for creation requests.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export type ResourceWithoutId<
    Type extends string = string,
    Attributes extends EnforceAttributes<Attributes> = EnforceAttributes<JsonObject>,
    Relationships extends EnforceRelationships<Relationships> = EnforceRelationships
> = Omit<Resource<Type, Attributes, Relationships>, "id">;
