/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { useCallback, useMemo, useContext, ReactElement, ReactNode } from "react";
// Import the stores.
import { UserStore, useResourceDispatch, OrganisationStore } from "@andromeda/store";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the switch component.
import { Switch } from "@andromeda/components";

// Import the css.
import css from "./role.module.scss";
import { Organisation, User } from "@andromeda/resources";

/** Component used to render and edit the role of a user. */
export function Role(props: Props): ReactElement | null {
    // Load the user from the store.
    const user = UserStore.useSelector(state => state.resources.find(user => user.id === props.user));
    const organisation = useContext(LoginContext).organisations.current;

    // Check if the user is the owner of the organisation.
    const [ isOwner, isAdmin, isEditor ] = useMemo(
        function getUserPermissions(): [isOwner: boolean, isAdmin: boolean, isEditor: boolean] {
            if (!user) return [ false, false, false ];
            return [
                organisation.relationships.owner.data?.id === user.id,
                organisation.relationships.administrators.data.some(admin => admin.id === user.id),
                organisation.relationships.editors.data.some(editor => editor.id === user.id),
            ];
        },
        [
            organisation.relationships.administrators.data,
            organisation.relationships.editors.data,
            organisation.relationships.owner.data?.id,
            user
        ]
    );

    // Check if the account is disabled.
    const isDisabled = useMemo(function findIfUserIsDisabled(): boolean {
        return user?.meta.disabled === true;
    }, [user?.meta.disabled]);

    // Method used to update the level of the user.
    const dispatch = useResourceDispatch();
    const updateLevel = useCallback(async function updateRole(role: "admin" | "editor" | "user"): Promise<void> {
        if (!user) return;

        // Prepare the update.
        const update = {
            type: Organisation.Type, id: organisation.id,
            relationships: {
                administrators: {
                    data: organisation.relationships.administrators.data.filter(admin => admin.id !== user.id)
                },
                editors: {
                    data: organisation.relationships.editors.data.filter(editor => editor.id !== user.id)
                }
            }
        };
        switch (role) {
        case "admin": update.relationships.administrators.data.push({ type: User.Type, id: user.id }); break;
        case "editor": update.relationships.editors.data.push({ type: User.Type, id: user.id }); break;
        }

        // Run the update.
        await dispatch(OrganisationStore.generator.update(update));
    }, [
        dispatch,
        organisation.id,
        organisation.relationships.administrators.data,
        organisation.relationships.editors.data,
        user
    ]);

    // Render the user status switches.
    const adminSwitch = useMemo(function renderAdminSwitch(): ReactElement | null {
        if (isDisabled) return null;
        const explanation: ReactNode[] = [ "Un administrateur possède tous les droits sur une organisation." ];
        if (isOwner) {
            explanation.push(
                <br key={0} />,
                <br key={1} />,
                "Cet utilisateur est le propriétaire de cette organisation. ",
                "Vous ne pouvez pas retirer ses droits administrateurs."
            );
        }

        return <Switch
            className={`${css["role__switch"]} ${css["role__switch--admin"]}`}
            initialState={isOwner || isAdmin}
            disabled={isOwner}
            explanation={explanation}
            onInternalStateChange={state => updateLevel(state ? "admin" : "user")}
            name="admin"
        />;
    }, [isAdmin, isDisabled, isOwner, updateLevel]);
    const editorSwitch = useMemo(function renderEditorSwitch(): ReactElement | null {
        if (isDisabled) return null;
        const explanation: ReactNode[] = [
            "Un éditeur a le droit d'editer les Zaq et ZaqTuto pour lesquels il a les droits d'édition.\n",
            "Il peut également en créer de nouveaux."
        ];
        if (isOwner || isAdmin) {
            explanation.push(
                <br key={0} />,
                <br key={1} />,
                "Cet utilisateur est déjà administrateur de cette organisation. " +
                "Vous ne pouvez pas retirer ses droits éditeurs."
            );
        }

        return <Switch
            className={`${css["role__switch"]} ${css["role__switch--editor"]}`}
            initialState={isOwner || isAdmin || isEditor}
            disabled={isOwner || isAdmin}
            explanation={explanation}
            onInternalStateChange={state => updateLevel(state ? "editor" : "user")}
            name="éditeur"
        />;
    }, [isAdmin, isDisabled, isEditor, isOwner, updateLevel]);


    // Render the component.
    if (!user) return null;
    return <ul className={css["role"]}>
        {adminSwitch}{editorSwitch}
    </ul>;
}

/** Props passed down to the {@link Role} component. */
interface Props {
    /** Identifier of the user whose role is displayed. */
    user: string;
}
