/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the validate function.
import { ValidateFunction } from "ajv";
// Import the json:api interfaces.
import { Resource, Relationship, AnyLinks, Link, ResourceIdentifier } from "@andromeda/json-api";
// Import the schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the creatable resource interface.
import { CreatableResource } from "@andromeda/resource-helper";

// Import the user and encryption source resources.
import { User, EncryptionSource } from "../..";
// Import the schema.
import { OrganisationSchema } from "./schema";

/**
 * Interface used to represent an organisation.
 * Organisations are the top-most resource in the hierarchy of ownership.
 */
export interface Organisation extends Resource<Organisation.Type> {
    /** @inheritDoc */
    attributes: Organisation.Attributes;
    /** @inheritDoc */
    relationships: Organisation.Relationships;
    /** @inheritDoc */
    links: Organisation.Links;
}

export namespace Organisation {
    export const Type = "organisation" as const;
    export type Type = typeof Type;

    export interface Attributes {
        /** Name of the organisation. */
        name: string;
        /** Subdomain used for this organisation. */
        subdomain: string;
        /** Optional icon of the organisation. */
        icon: string | null;

        /** List of permissions for this organisation. */
        permissions: {
            /** If true, this organisation can create and edit ZaqWiki. */
            "zaq-wiki": boolean;
            /** If true, this organisation can create and edit ZaqTuto. */
            "zaq-tuto": boolean;
            /** If true, this organisation can create and edit courses. */
            "courses": boolean
        };
    }

    export interface Relationships {
        /** Reference to the user that owns this organisation. */
        owner: Relationship<ResourceIdentifier<User.Type> | null>;
        /** List of all the administrators for this organisation. */
        administrators: Relationship<ResourceIdentifier<User.Type>[]>;
        /** List of all the zaq editors for this organisation. */
        editors: Relationship<ResourceIdentifier<User.Type>[]>;

        /** List of all the encryption sources for this organisation. */
        encryptionSources: Relationship<EncryptionSource[]>;
    }

    export interface Links extends AnyLinks {
        /** Link to the full domain of the organisation. */
        domain: Link;
    }

    export const validate: ValidateFunction<Organisation> =
        compileSync<Organisation>(OrganisationSchema.ResourceSchema);
    export const Schema = OrganisationSchema;

    /** Interface used to describe an organisation update resource. */
    export interface Update extends Resource<Organisation.Type> {
        /** @inheritDoc */
        attributes?: Partial<Organisation.Attributes>;
        /** @inheritDoc */
        relationships?: Partial<Organisation.Relationships>;
    }

    export namespace Update {
        export const validate: ValidateFunction<Organisation.Update> =
            compileSync<Organisation.Update>(OrganisationSchema.UpdateSchema);
    }

    /** Interface used to describe an organisation create resource. */
    export interface Create extends Omit<CreatableResource<Resource<Organisation.Type>>, "relationships"> {
        /** @inheritDoc */
        attributes: Create.Attributes;
        /** @inheritDoc */
        relationships: Create.Relationships;
    }

    export namespace Create {
        export interface Attributes extends Omit<Organisation.Attributes, "icon"> {
            /** @borrows {Organisation.Attributes["icon"]} */
            icon?: Organisation.Attributes["icon"];
        }

        export type Relationships = Pick<Organisation.Relationships, "owner">;

        export const validate: ValidateFunction<Organisation.Create> =
            compileSync<Organisation.Create>(OrganisationSchema.CreateSchema);
    }
}
