/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useCallback, useContext, useMemo, useState } from "react";
// Import the font-awesome components.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the storybook components.
import { Box } from "@andromeda/storybook";
// Import the store.
import { StepStore, useResourceDispatch, useStep } from "@andromeda/store";
// Import the login module.
import { LoginContext } from "@andromeda/login";
// Import the custom components.
import { useNotify } from "@andromeda/components";
// Import the resources.
import { Step } from "@andromeda/resources";

// Import the custom hooks.
import { useProgress } from "../../../hooks";
// Import the contexts.
import { EditorSwitchContext, ValidatorSwitchContext } from "../../../context";
// Import the item renderer.
import Item from "../item";
// Import the item requirements' renderer.
import ItemRequirements from "../item/item-requirements";
// Import the subcomponents.
import StepStatus from "./step-status";
import EditMenu from "./edit-menu";
import AddItemIcon from "./edit-menu/add-item-icon";

// Import the icons.
import { faLock } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./step.module.scss";


/** Component used to render a step of the course. */
export default function StepRenderer(props: StepRendererProps): ReactElement | null {
    const { state: editing } = useContext(EditorSwitchContext);
    const { state: validating } = useContext(ValidatorSwitchContext);

    // Load the step from the store.
    const step = useStep(props.step);

    // Load the user's progress for the current step.
    const progress = useProgress(props.course, useContext(LoginContext).self.id).data?.step(props.step);

    // Render all the items.
    const items = useMemo(function renderStepItems(): ReactElement[] | null {
        if (!step.isSuccess) {
            return null;
        }

        // Check if the items should be rendered.
        if (!editing && !validating && progress?.locked) {
            return null;
        }

        // Render the items.
        return step.data.relationships.items.data.map(function renderStepItem(item): ReactElement {
            return <Item key={`${item.type}.${item.id}`} step={props.step} item={item}>
                <ItemRequirements item={item} step={props.step} />
            </Item>;
        });
    }, [editing, progress?.locked, props.step, step.data, step.isSuccess, validating]);

    // Store the editing mode.
    const [editMode, setEditMode] = useState<"item" | "requirement">("requirement");

    // Callback used to update the step's name.
    const { error } = useNotify();
    const dispatch = useResourceDispatch();
    const onEdited = useCallback(function editStep(name: string): void {
        dispatch(
            StepStore.generator.update(
                {
                    type: Step.Type,
                    id: props.step,
                    attributes: { name },
                }
            )
        ).catch(error);
    }, [dispatch, error, props.step]);


    // If the step should not be rendered, return a null.
    if (!progress && !editing && !validating) {
        return null;
    }

    // Render the lock icon.
    let icon: ReactNode = null;
    if (progress?.locked && !editing && !validating) {
        icon = <FontAwesomeIcon icon={faLock} className={css["header__lock"]} />;
    }

    // Compute the header's class name.
    let className = css["header"];
    if (progress && progress.locked && !editing && !validating) {
        className += " " + css["header--locked"];
    }

    // Render the component.
    return <Box
        headerProps={{
            controls: <StepStatus course={props.course} step={props.step} />,
            editable: editing,
            icon,
            title: step.data?.attributes.name,
            className,
            interval: 500,
            onEdited: onEdited,
            maxLength: 48
        }}
        startsOpen={props.startsOpen}
        canOpen={!progress || !progress.locked || editing || validating}
        bodyClassName={`${css["body"]} ${editing ? css["body--editing"] : ""}`}
    >
        <div className={css["item-container"]}>
            {items}
            <AddItemIcon show={editMode !== "item"} onClick={() => setEditMode("item")} />
        </div>
        <EditMenu mode={editMode} changeMode={setEditMode} course={props.course} step={props.step} />
    </Box>;
}

/** Props passed down to the {@link StepRenderer} component. */
export interface StepRendererProps {
    /** The identifier of the current course. */
    course: string;
    /** The identifier of the step to render. */
    step: string;
    /** Flag that should be set if the step's box starts open. */
    startsOpen?: boolean;
}
