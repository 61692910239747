/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useContext, useState } from "react";
// Import the React responsive tools.
import { useMediaQuery } from "react-responsive";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import popper.
import type { Placement } from "@popperjs/core";
// Import the storybook components.
import { makeToggleableButtonContext, ToggleableButton, ToggleableButtonContextProvider } from "@andromeda/storybook";

// Import the share component.
import Share from "./share";

// Import the share icon.
import { faShare } from "@fortawesome/free-solid-svg-icons/faShare";
import { useManyZaq } from "@andromeda/store";
import { LoginContext } from "@andromeda/login";


/** Component used to render the share button of the reader mode. */
export default function ShareButton(props: ShareButtonProps) {
    // Store a reference to the button element.
    const [button, setButton] = useState<HTMLButtonElement | null>(null);
    // Flag set when the share menu should be open.
    const [open, setOpen] = useState(false);

    // Compute the placement of the menu.
    const placement: Placement = useMediaQuery({ maxWidth: 576 }) ? "bottom" : "left-start";

    // Resolve the id for the new zaq.
    const { organisations: { current } } = useContext(LoginContext);
    const zaq = useManyZaq(current.id, { searchParams: { "filter[zaq][legacy-id]": props.id } });
    if (!zaq.isSuccess || zaq.data.length < 1) {
        return null;
    }

    // Render the component.
    return <ToggleableButtonContextProvider
        context={ShareButtonStateContext}
        onToggle={() => setOpen(state => !state)}
        state={open}
    >
        <ToggleableButton className={props.className} ref={setButton} context={ShareButtonStateContext}>
            <FontAwesomeIcon icon={faShare} />
        </ToggleableButton>
        <Share
            placement={placement}
            id={zaq.data[0].id}
            container={button}
            show={open}
            onClose={() => setOpen(false)}
        />
    </ToggleableButtonContextProvider>;
}

/** Props passed down to the {@link ShareButton} component. */
export interface ShareButtonProps {
    /** Identifier of the shared {@link ZaqWiki}. */
    id: string;
    /** Optional class name appended to the button component. */
    className?: string;
}

// Context used by the share button.
const ShareButtonStateContext = makeToggleableButtonContext();
