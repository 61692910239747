/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the confirmation props.
import { ConfirmBodyProps } from "@andromeda/components";

// Import the generator hook.
import { useGenerator } from "../../hooks";
// Import the validation component.
import { Validate } from "../validate";
// Import the subcomponents.
import { Preparation } from "./preparation";


/** Component used to capture the screen of the user. */
export function AudioCapture(props: ConfirmBodyProps<void>): React.ReactElement {
    const generate = useGenerator();
    const runGenerator = React.useCallback(function runGenerator(file: File): void {
        generate(file); props.resolve();
    }, [generate, props]);

    // Store the media recorder.
    const [ recorder, setRecorder ] = React.useState<MediaRecorder>();

    // Start recording.
    React.useEffect(function startRecording(): (() => void) | void {
        if (!recorder) return; recorder.start();
    }, [ recorder ]);
    const stop = React.useCallback(function stopRecorder(): void {
        try { recorder?.stop(); } catch (e: unknown) { console.error(e); }
        try { recorder?.stream.getTracks().forEach(track => track.stop()); } catch (e: unknown) { console.error(e); }
    }, [ recorder ]);

    // Generate the callbacks.
    const cancel = React.useCallback(function cancelRecording(): void {
        stop(); props.resolve();
    }, [props, stop]);
    const validate = React.useMemo(function generateValidateCallback(): (() => void) | undefined {
        if (!recorder) return undefined;
        return function validateRecording(): void {
            if (recorder.state !== "recording") {
                throw new Error("Tried to capture a non-recording stream !");
            }

            // Attach a stop listener on the recorder.
            const type = recorder.mimeType;
            const data: Blob[] = [];
            recorder.addEventListener("dataavailable", event => {
                data.push(event.data);
            });

            // Generate the video.
            recorder.addEventListener("stop", () => {
                runGenerator(new File(data, "Capture microphone", { type }));

            }, { once: true });
            stop();
        }
    }, [recorder, runGenerator, stop]);


    // Render the component.
    return <>
        {recorder ? null : <Preparation start={setRecorder} useFile={runGenerator} />}
        <Validate cancel={cancel} validate={validate} />
    </>;
}
