/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement } from "react";
// Import React-router.
import { useParams } from "react-router-dom";
// Import React Bootstrap.
import { Container } from "react-bootstrap";
// Import the custom components.
import { Loader } from "@andromeda/storybook";
// Import the store.
import { useCourse } from "@andromeda/store";

// Import the contexts.
import { DueDateModalProvider, EditSwitchProvider, ValidateSwitchProvider } from "../../context";
// Import the subcomponents.
import Back from "../back";
import StepContainer from "./step";
import Progress from "./progress";

// Import the css.
import css from "./index.module.scss";


/** Component used to render a course on the screen. */
export default function Course(): ReactElement | null {
    // Load the course from the store.
    const id = useParams()["course"];
    const course = useCourse(id, false);

    // Render the loader while the course is loading.
    if (course.isUninitialised || course.isLoading) {
        return <Loader containerClassName={css["loader"]} text="Chargement du ZaqTraining ..." />;
    }
    if (course.isError) {
        return <div>
            <span>Impossible de charger le ZaqTraining ...</span>
            <code><pre children={JSON.stringify(course.error, null, 4)} /></code>
        </div>;
    }
    return <EditSwitchProvider>
        <ValidateSwitchProvider>
            <DueDateModalProvider>
                <Container className={css["container"]}>
                    <Back course={course.data.id} />
                    <StepContainer course={course.data.id} />
                    <Progress course={course.data.id} />
                </Container>
            </DueDateModalProvider>
        </ValidateSwitchProvider>
    </EditSwitchProvider>;
}
