/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


import type { ReactElement } from "react";
// Import the FontAwesome icon.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the custom hooks.
import type { StepProgress } from "../../../hooks";
// Import the level icon component.
import LevelIcon from "../../level-icon";

// Import the icons.
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Checkmark } from "@andromeda/assets/svg/checkmark.svg";
// Import the css.
import css from "./common.module.scss";

/** Helper component used to render the cell of a given step. */
export default function StepCell(props: StepCellProps): ReactElement {
    // If the progress is undefined, return an N/A cell.
    if (typeof props.progress === "undefined") {
        return <span className={`${css["cell"]} ${css["cell--not-applicable"]}`} />;
    }

    // Build the cell's class name
    let className = css["cell"];
    if (props.progress.complete) {
        className += ` ${css["cell--complete"]}`;
    }

    // Render the cell.
    return <span className={className}>
        <LevelIcon className={css["level"]} level={props.progress.averageLevel} />
        {props.progress.objectiveMet ? <Checkmark className={css["checkmark"]} /> : null}
        {props.progress.validated ? <Checkmark className={css["checkmark"]} /> : null}
        {props.progress.locked ? <FontAwesomeIcon icon={faLock} className={css["lock"]} /> : null}
    </span>
}

/** Props passed down to the {@link StepCell} component. */
export interface StepCellProps {
    /** The progress of the current cell. */
    progress?: StepProgress;
}
