/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode } from "react";
// Import the font awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the edition helpers.
import useEditCallbackHandler, { EditableHeaderProps } from "./edition";
// Import the class name helpers.
import useClassNames, { ClassNameProps } from "./class-names";
// Import the title component.
import BoxHeaderTitle, { BoxHeaderTitleProps } from "./title";

// Import the icons.
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";


/**
 * Component used to render the header of the box component.
 */
export default function BoxHeader(props: BoxHeaderProps & InternalBoxHeaderProps): ReactElement {
    // Get the change handler.
    const onBlurHandler = useEditCallbackHandler(props);

    // Get the class names.
    const {
        className,
        iconContainerClassName,
        titleClassName,
        controlContainerClassName,
        chevronClassName
    } = useClassNames(props, props.open, props.locked);

    // Render the header component.
    return <header role="button" aria-expanded={props.open} className={className} onClick={props.toggle}>
        <FontAwesomeIcon icon={faChevronRight} className={chevronClassName} />
        <span className={iconContainerClassName} children={props.icon} />
        <BoxHeaderTitle
            title={props.title}
            editable={props.editable === true}
            headingLevel={2}
            className={titleClassName}
            onBlur={onBlurHandler}
            maxLength={props.maxLength}
        />
        <span className={controlContainerClassName} children={props.controls} />
    </header>;
}

/** Props used directly by the {@link BoxHeader} component.*/
interface BoxHeaderOwnProps {
    /** Icon that will be rendered to the left of the title. */
    icon?: ReactNode;
    /** Controls that will be rendered at the end of the box. */
    controls?: ReactNode;
}

/** Props passed down to the {@link BoxHeader} component. */
export type BoxHeaderProps = BoxHeaderOwnProps & EditableHeaderProps & ClassNameProps & BoxHeaderTitleProps;

/** Internal props passed to the {@link BoxHeader} component. */
export interface InternalBoxHeaderProps {
    /** Current state of the box. */
    open: boolean;
    /** If true, the box cannot change its state. */
    locked: boolean;

    /** Callback invoked when the user requests a toggle of the box. */
    toggle(): void;
}
