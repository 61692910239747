/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement } from "react";
// Import the stores.
import {
    SessionStore,
    ValidationStore,
    RequirementStore,
    StepStore,
    CourseStore,
    ExternalStore,
    AssetStore,
    TagStore,
    ZaqWikiStore,
    EncryptionSourceStore,
    DueDateStore,
    withStores, withRequestStore
} from "@andromeda/store";
// Import the switch context provider.
import { DiscordWebhookProvider, ConfirmProvider } from "@andromeda/components";
// Import the legacy-zaq helper.
import { withLegacyZaqReducer } from "@andromeda/legacy-zaq";

// Import the router
import ZaqTrainingRouter from "./components/router";


/** Wrapper component used to render a given ZaqTraining on the interface. */
const ZaqTraining = withRequestStore(
    withLegacyZaqReducer(
        withStores(
            function ZaqTraining(): ReactElement {
                return <DiscordWebhookProvider
                    id="990918486287069234"
                    token="OXsVSdIn-FhUDM2oV-OYFW-dobgQdj5rz78qLFNeeFhPAehR_Cwc6FiZQe9QGUGAt8WR"
                >
                    <ConfirmProvider>
                        <ZaqTrainingRouter />
                    </ConfirmProvider>
                </DiscordWebhookProvider>;
            },
            TagStore,
            ZaqWikiStore,
            EncryptionSourceStore,
            AssetStore,
            ExternalStore,
            SessionStore,
            ValidationStore,
            DueDateStore,
            RequirementStore,
            StepStore,
            CourseStore
        )
    )
);

export default ZaqTraining;

export { Monitoring } from "./monitoring";

