/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactNode, ReactElement, useMemo } from "react";

// Import the page select component.
import { PageSelect } from "./select";

// Import the css.
import css from "./header.module.scss";


/** Renders the header of a table. */
export function TableHeader(props: TableHeaderProps): ReactElement {
    // Pre-render the thead class name.
    const theadClassName = useMemo(function renderTheadClassName(): string {
        let className = css["table-header"];
        if (props.className) {
            className += ` ${props.className}`;
        }
        return className;
    }, [props.className]);

    // Render all the cells.
    const cells = useMemo(function renderCellsWithSelect(): ReactNode[] {
        const cells: ReactNode[] = [
            <th key="select">
                <PageSelect names={props.pageNames} current={props.currentPage} update={props.updatePageNumber} />
            </th>,
        ];

        // Convert all the cells to a <td> element if needed.
        for (let i = 0; i < props.cells.length; i++) {
            let cell: ReactNode = props.cells[i];

            // Check if the cell itself is already a <td> element.
            if (
                typeof cell === "object" &&
                cell !== null &&
                ("type" in cell) &&
                (cell as { type: unknown }).type === "td"
            ) {
                // Add a key if not provided.
                if (cell.key === null) {
                    cell.key = i;
                }
            } else {
                cell = <td key={i} children={cell} />;
            }
            cells.push(cell);
        }

        return cells;
    }, [props.cells, props.currentPage, props.pageNames, props.updatePageNumber]);

    // Render the component.
    return <thead className={theadClassName}>
        <tr className={props.rowClassName} children={cells} />
    </thead>;
}

/** Props passed down to the {@link TableHeader} component. */
interface TableHeaderProps {
    /** List of cells to render in the header. */
    cells: ReactNode[];

    /** Passed down to {@link PageSelect}. */
    pageNames: string[];
    /** Passed down to {@link PageSelect}. */
    currentPage: number;
    /** Passed down to {@link PageSelect}. */
    updatePageNumber(pageNumber: number): void;

    /** Class name that will be added to the <thead> element. */
    className?: string;
    /** Class name that will be added to the <thead>'s <tr> element. */
    rowClassName?: string;
}
