/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the base class.
import { JsonApiError } from "./index";


/**
 * Error thrown if the jsonSanitise function was called with an "undefined" parameter.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class NotSanitisableError extends JsonApiError {
    /** @inheritDoc */
    public override get code(): string { return "0x10301"; }

    /** @param {string} type The type that cannot be sanitized. */
    public constructor(type: string) { super(`"${type}" cannot be sanitised into a JSON object.`); }
}
