/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import React-Router.
import { useParams } from "react-router-dom";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the custom components.
import { Loader, ConfirmProvider, DiscordWebhookProvider } from "@andromeda/components";
// Import the zaq wiki component.
import ZaqWiki from "@andromeda/zaq-wiki";


/** Wrapper for the {@link ZaqWiki} and {@link ZaqWikiMenu} component. */
export default function WikiRouter(): React.ReactElement {
    // Load the current organisation id from the login context.
    const loginContext = React.useContext(LoginContext);
    const organisation = React.useMemo(function getOrganisationId(): string | undefined {
        if (loginContext.isLoggedIn) return loginContext.organisations.current.id;
        return undefined;
    }, [loginContext.isLoggedIn, loginContext.organisations]);

    // Load the id from the parameters.
    const { wiki } = useParams<"wiki">();
    if (typeof wiki === "undefined") {
        throw new TypeError("Invalid parameters !");
    }

    // Render the component.
    return <React.Suspense fallback={<Loader text="Chargement du module wiki ..." />}>
        <DiscordWebhookProvider
            id="990919120356790313"
            token="2d0vIccYPRrB8bydyv6ZsyzfyB4AWLiDJlBST64_NCERGieYTE_8854fp5T5PfVmNR36"
        >
            <ConfirmProvider>
                <ZaqWiki id={wiki} organisation={organisation} />
            </ConfirmProvider>
        </DiscordWebhookProvider>
    </React.Suspense>;
}
