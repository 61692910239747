/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement } from "react";

// Import the css.
import css from "./title.module.scss";


/** Component used to render a title module. */
export default function TitleModule(props: TitleModuleProps): ReactElement {
    // Render the component.
    return <div className={css["title"]}>
        <h4 className={css["title__text"]} children={props.title} />
    </div>;
}

/** Props passed down to the {@link TitleModule}. */
export interface TitleModuleProps {
    /** The subtitle to show on the module. */
    title: string;
}
