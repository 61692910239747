/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement } from "react";
// Import React router.
import { Route, Routes } from "react-router-dom";

// Import the sub-components.
import Course from "./course";
import Saving from "./saving";
import Validation from "./validation";


/** Router used to draw the correct training item on screen. */
export default function ZaqTrainingRouter(): ReactElement {
    // Render the routes.
    return <>
        <Routes>
            <Route path="/:course/*" element={
                <Routes>
                    <Route path="/validation/*" element={<Validation />} />
                    <Route path="*" element={<Course />} />
                </Routes>
            } />
            <Route path="*" element="How did you end up here ?" />
        </Routes>
        <Saving />
    </>
}
