/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";

// Import the subcomponents.
import FavouritesPage from "./favourites";
import TrainingPage from "./training";


/** Union of all the page types available. */
export type PageType = "favourites" | "training" | "monitoring";

/** Component used to render the currently selected page element. */
export default function Page(props: PageProps): ReactElement | null {
    switch (props.type) {
    case "favourites":
        return <FavouritesPage />;
    case "training":
        return <TrainingPage />;
    case "monitoring":
        return <TrainingPage administrator />;
    default:
        return null;
    }
}

/** Props passed down to the {@link Page} component. */
export interface PageProps {
    /** The type of the current page. */
    type: PageType;
}
