/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the bootstrap components.
import { ColProps, Col, Button } from "react-bootstrap";

// Import the icons.
import chevron from "@andromeda/assets/images/chevron.svg";
// Import the css.
import css from "./carousel.module.scss";


/** Interface used to specify how the carousel children should be rendered. */
export interface CarouselChild {
    /** Element to render inside the item. */
    element: React.ReactNode;
    /** Optional title of the item. */
    title?: string;
}

/** Component used to render a carousel. */
export function Carousel(props: Props): React.ReactElement {
    // Store the index of the currently selected element.
    const [ currentIndex, setCurrentIndex ] = React.useState<number>(0);
    const next = React.useCallback(function next(): void {
        setCurrentIndex((currentIndex + 1) % props.elements.length);
    }, [ currentIndex, props.elements.length ]);
    const previous = React.useCallback(function previous(): void {
        let newIndex = currentIndex - 1;
        if (newIndex < 0) newIndex = props.elements.length - 1;
        setCurrentIndex(newIndex);
    }, [ currentIndex, props.elements.length ]);

    // Assign indices to all the elements.
    const withIndices = React.useMemo(function computeIndices(): CarouselChildWithIndex[] {
        // Compute the largest distance to the center.
        const maxDistance = Math.floor(props.elements.length / 2);

        return props.elements.map((item: CarouselChild, idx: number): CarouselChildWithIndex => {
            let index = idx - currentIndex;
            while(index < -maxDistance) index += props.elements.length;
            while(index >  maxDistance) index -= props.elements.length;
            return { ...item, index };
        });
    }, [ currentIndex, props.elements ]);

    // Memoise the rendered children.
    const elements = React.useMemo(function renderElements(): React.ReactElement[] {
        return withIndices.map(item =>
            <div
                key={
                    typeof item.element === "object" &&
                    item.element !== null &&
                    "key" in item.element
                    ?
                    item.element.key
                    :
                    item.index
                }
                children={item.element}
                className={
                    `${css["carousel__item"]} ` +
                    `${css[`carousel__item--${Math.min(Math.max(item.index, -4), 4)}`]}`
                }
            />
        );
    }, [ withIndices ]);

    // Render the buttons.
    const buttons = React.useMemo(function renderButtons(): React.ReactElement | null {
        if (props.elements.length <= 1) return null;
        return <>
            <Button onClick={previous} className={`${css["carousel__arrow"]} ${css["carousel__arrow--previous"]}`}>
                <img src={chevron} alt={"previous-item"} />
            </Button>
            <Button onClick={next} className={`${css["carousel__arrow"]} ${css["carousel__arrow--next"]}`}>
                <img src={chevron} alt={"next-item"} />
            </Button>
        </>;
    }, [next, previous, props.elements.length]);

    // Render the dots at the bottom of the screen.
    const dots = React.useMemo(function renderDots(): React.ReactElement | null {
        if (!props.showDots || props.elements.length <= 1) return null;

        // Render the list of dots.
        const dotElements: React.ReactElement[] = [];
        for (let i = 0; i < Math.min(props.elements.length, 10); i++) {
            dotElements.push(<div
                onClick={() => setCurrentIndex(i)}
                className={
                    `${css["carousel__dots__element"]} ` +
                    `${(i - currentIndex) % props.elements.length === 0 ? css["carousel__dots__element--current"] : ""}`
                }
                key={i}
            />);
        }

        // Render the dot container.
        return <div className={css["carousel__dots"]} children={dotElements} />;
    }, [currentIndex, props.elements.length, props.showDots, setCurrentIndex]);

    // Render the name of the current element.
    const name = React.useMemo(function renderCurrentName(): React.ReactElement | null {
        if (props.elements.length <= currentIndex) return null;
        return <h4 className={css["carousel__item__title"]}>{props.elements[currentIndex].title}</h4>;
    }, [currentIndex, props.elements]);

    // Render the carousel.
    return <Col
        xs={props.xs} sm={props.sm}
        md={props.md} lg={props.lg} xl={props.xl}
        xxl={props.xxl} className={css["carousel"]}
    >
        {elements}{buttons}{dots}{name}
    </Col>;
}

/** Props passed down to the {@link Carousel} component. */
interface Props extends Pick<ColProps, "xs" | "sm" | "md" | "lg" | "xl" | "xxl"> {
    /** List of elements to render inside the carousel. */
    elements: CarouselChild[];
    /** If set, show a series of dots under the carousel. */
    showDots?: boolean;
}

/** Extension of the {@link CarouselChild} interface with a computed index. */
interface CarouselChildWithIndex extends CarouselChild {
    /**
     * Index of the carousel child.
     * Negative values are displayed to the left,
     * while positive ones to the right.
     * 0 is the one in the center.
     */
    index: number;
}
