/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { FormEvent, ReactElement, useCallback, useState } from "react";
// Import the CSS classname tool.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the custom components.
import { SpeechRecognitionButton } from "@andromeda/components";


// Import the icons.
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
// Import the css.
import css from "./index.module.scss";


/** Component used to render the search bar on the screen. */
export default function SearchBar(props: SearchBarProps): ReactElement {
    // Extract the callbacks from the props.
    const { onSubmit: onSubmitCallback, onChange: onChangeCallback } = props;

    // Store the text found in the search bar.
    const [text, setText] = useState(props.defaultValue || "");

    // Callback invoked to change the text.
    const updateText = useCallback(function updateSearchbarText(text: string): void {
        // Update the text.
        setText(text);

        // Invoke the callback.
        onChangeCallback?.(text);
    }, [onChangeCallback]);

    // Callback invoked when the form is submitted.
    const onSubmit = useCallback(function onFormSubmit(event: FormEvent<HTMLFormElement>): void {
        // Prevent the default behaviour.
        event.preventDefault();

        // Propagate the submit event.
        onSubmitCallback?.(text);
    }, [onSubmitCallback, text]);

    // Callback invoked when the speech recognition recognizes a result.
    const onResults = useCallback(function loadRecognitionResults(event: SpeechRecognitionEvent): void {
        let text = "";
        // Loop through all the recognitions.
        for (let i = 0; i < event.results.length; i++) {
            // Ignore partial or empty recognitions.
            if (event.results[i].length <= 0) {
                continue;
            }
            const result = event.results[i][0];

            // Check the confidence level of the result.
            text += result.transcript;
        }

        // Propagate the update.
        updateText(text);
    }, [updateText]);

    // Build the class name.
    const className = classNames(
        css["searchbar__container"],
        css[`searchbar__container--${props.size ?? "md"}`],
        props.className
    );

    // Render the search bar.
    return <form role="searchbox" className={className} aria-label="sitewide" onSubmit={onSubmit}>
        <label className={css["searchbar"]}>
            <FontAwesomeIcon className={css["search-icon"]} icon={faMagnifyingGlass} />
            <input
                className={css["input"]}
                type="search"
                name="search"
                placeholder={props.placeholder}
                value={text}
                onChange={e => updateText(e.currentTarget.value)}
            />
            <button
                className={classNames(css["button"], css["button--clear"])}
                type="reset"
                onClick={() => updateText("")}
                disabled={text.length <= 0}
            >
                <FontAwesomeIcon className={css["button__icon"]} icon={faXmark} />
            </button>
            <SpeechRecognitionButton
                className={classNames(css["button"], css["button--speech"])}
                iconClassName={css["button__icon"]}
                interimResults
                stopWithUser
                onResult={onResults}
                onEnd={() => props.onSubmit?.(text)}
            />
            <button className={css["button"]} type="submit">
                <FontAwesomeIcon className={css["button__icon"]} icon={faChevronRight} />
            </button>
        </label>
    </form>;
}

/** Props passed down to the {@link SearchBar} component. */
export interface SearchBarProps {
    /** The default text found in the searchbar. */
    defaultValue?: string;
    /** Placeholder text shown in the search bar when nothing is written inside of it. */
    placeholder?: string;
    /** Class name applied to the searchbar container. */
    className?: string;

    /** Size of the search bar. */
    size?: "lg";

    /**
     * Callback invoked when the search bar's contents are submitted.
     *
     * @param {string} text The text that was found in the bar when it was submitted.
     */
    onSubmit?(text: string): void;

    /**
     * Callback invoked when the text of the search bar is being changed.
     *
     * @param {string} text The new text found in the searchbar.
     */
    onChange?(text: string): void;
}
