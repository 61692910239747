/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the user resource.
import { User } from "@andromeda/resources";

/** Interface used to define the state of the administration context. */
export interface AdminContext {
    /** List of all the users belonging to the current organisation. */
    users: User[];
}

// Default state of the context.
const DEFAULT_STATE: AdminContext = { users: [] };

/** Context used to provide easy access to the explorer context for the entire reader object. */
export const AdminContext = React.createContext<AdminContext>(DEFAULT_STATE);
