/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json:api interfaces.
import {
    Resource,
    Relationship,
    RelationshipWithData,
    Metadata,
} from "@andromeda/json-api";
// Import the schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the creatable resource interface.
import { CreatableResource } from "@andromeda/resource-helper";

// Import the organisation resource.
import { Organisation } from "../organisation";
// Import the schema.
import { UserSchema } from "./schema";

/** Interface used to represent a user. */
export interface User extends Resource<User.Type> {
    /** @inheritDoc */
    attributes: User.Attributes;
    /** @inheritDoc */
    relationships: User.Relationships;
    /** @inheritDoc */
    meta: User.Meta;
}

export namespace User {
    export const Type = "user" as const;
    export type Type = typeof Type;

    export interface Attributes {
        /** Name shown on the interface. */
        givenName: string;
    }

    export interface Relationships {
        /** List of organisation that this user belongs to. */
        organisations: Relationship<Organisation[]>;
    }

    export interface Meta extends Metadata {
        /** Flag set if the user account was disabled. */
        disabled: boolean;
    }

    export const validate = compileSync<User>(UserSchema.ResourceSchema);
    export const Schema = UserSchema;

    /** Interface used to describe a user with full data. */
    export interface Full extends Resource<User.Type> {
        /** @inheritDoc */
        attributes: Full.Attributes;
        /** @inheritDoc */
        relationships: User.Relationships;
        /** @inheritDoc */
        meta: User.Meta;
    }

    export namespace Full {
        export interface Attributes extends User.Attributes {
            /** Email address of the user. */
            email: string;
            /** Username of the user. */
            username: string;
        }

        export const validate = compileSync<User.Full>(UserSchema.FullSchema);
    }

    /** Interface used to describe a user update resource. */
    export interface Update extends Resource<User.Type> {
        /** @inheritDoc */
        attributes?: Update.Attributes;
        /** @inheritDoc */
        relationships?: Partial<User.Relationships>;
        /** @inheritDoc */
        meta?: Partial<User.Meta>;
    }

    export namespace Update {
        export interface Attributes extends Partial<User.Attributes> {
            /** New email address of the user. */
            email?: string;
            /** New username of the user. */
            username?: string;
        }

        export const validate = compileSync<User.Update>(
            UserSchema.UpdateSchema
        );
    }

    interface CreateNew extends CreatableResource<Resource<User.Type>> {
        /** @inheritDoc */
        attributes: Create.Attributes;
        /** @inheritDoc */
        relationships: Create.Relationships;
    }

    interface CreateExisting
        extends Omit<CreatableResource<Resource<User.Type>>, "attributes"> {
        /** @inheritDoc */
        id: string;
        /** @inheritDoc */
        relationships: Create.Relationships;
    }

    export type Create = CreateNew | CreateExisting;

    export namespace Create {
        export interface Attributes {
            /** Email address of the user. */
            email: string;
            /** Username of the user. */
            username?: string;
            /** Given name for the user. */
            givenName?: string;
        }

        export interface Relationships {
            /** Organisation that will own the generated user. */
            organisation: RelationshipWithData<Organisation>;
        }

        export const validate = compileSync<User.Create>(
            UserSchema.CreateSchema
        );
    }
}
