/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API module.
import type { Filter } from "@andromeda/json-api";
// Import the ZaqWiki resource.
import { ZaqWiki } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the store.
import { ZaqWikiStore, ResourceStore } from "../../stores";
// Import the resource hooks.
import useResources from "./util/use-resources";
import useResource from "./util/use-resource";


/**
 * Hook used to download a ZaqWiki from the store.
 *
 * @param {string} id The identifier of the ZaqWiki.
 * @param {boolean} [cache=true] Try to read from the cache if possible.
 * @return {RequestStatus<ZaqWiki>} The status of the request.
 */
export function useZaqWiki(id: string, cache = true): RequestStatus<ZaqWiki> {
    return useResource(ZaqWikiStore, ZaqWiki.Type, id, cache);
}

/**
 * Downloads all the ZaqWiki of the specified step.
 *
 * @param {string} organisation The organisation to load the ZaqWiki for.
 * @return {RequestStatus<ZaqWiki[]>} The status of the request.
 */
export function useZaqWikis(organisation: string): RequestStatus<ZaqWiki[]> {
    // Prepare the filter used for the request.
    const filter: Filter = { "zaq-wiki": { organisations: { $eq: organisation }}, };

    // Prepare the selector used to load the ZaqWikis.
    const selector = function selector(state: ResourceStore<ZaqWiki>): ZaqWiki[] {
        return state.resources.filter(function ZaqWikiBelongsToOrg(wiki: ZaqWiki): boolean {
            return wiki.relationships.organisations.data.some(org => org.id === organisation);
        });
    };

    // Return the resources.
    return useResources(ZaqWikiStore, ZaqWiki.Type, { filter }, selector);
}
