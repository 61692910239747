/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the css.
import css from "./finish-line.module.scss";


/** Simple SVG component used to render the finish line at the end of the parent. */
export default function ProgressFinishLine(props: ProgressFinishLineProps): ReactElement {
    // Render the element.
    return <svg className={classNames(css["finish-line"], props.className)} viewBox="0 0 2 4">
        <rect className={classNames(css["square"], css["square--dark"])} x={0} y={0} />
        <rect className={classNames(css["square"], css["square--light"])} x={1} y={0} />
        <rect className={classNames(css["square"], css["square--light"])} x={0} y={1} />
        <rect className={classNames(css["square"], css["square--dark"])} x={1} y={1} />
        <rect className={classNames(css["square"], css["square--dark"])} x={0} y={2} />
        <rect className={classNames(css["square"], css["square--light"])} x={1} y={2} />
        <rect className={classNames(css["square"], css["square--light"])} x={0} y={3} />
        <rect className={classNames(css["square"], css["square--dark"])} x={1} y={3} />
    </svg>
}

/** Props passed down to the {@link ProgressFinishLine} component. */
export interface ProgressFinishLineProps {
    /** Optional class name added to the root <svg> element. */
    className?: string;
}
