/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useCallback } from "react";
// Import React-Router.
import { useNavigate } from "react-router-dom";
// Import the store.
import { useAsset, useZaqWiki } from "@andromeda/store";
// Import the JSON:API module.
import { extractURL } from "@andromeda/json-api";
// Import the resources.
import type { Step } from "@andromeda/resources";

// Import the item container.
import ItemContainer from "./item";


/** Component used to render a ZaqWiki item on the screen. */
export default function WikiItem(props: WikiItemProps): ReactElement {
    // Load the wiki from the store.
    const wiki = useZaqWiki(props.item.id);
    const icon = useAsset(wiki.data?.relationships.icon.data?.id, false);

    // Prepare the redirection callback.
    const navigate = useNavigate();
    const redirect = useCallback(function redirect(): void {
        navigate(`wiki/${props.item.id}`);
    }, [navigate, props.item.id]);

    // Render the step.
    return <ItemContainer
        item={props.item}
        step={props.step}
        title={wiki.data?.attributes.name ?? undefined}
        icon={icon.data?.links?.get ? extractURL(icon.data.links.get).toString() : undefined}
        loading={!icon.isSuccess}
        kind="Wiki"
        onClick={redirect}
        children={props.children}
    />;
}

/** Props passed down to the {@link WikiItem} component. */
export interface WikiItemProps {
    /** Identifier of the wiki to render. */
    item: Step.Item;
    /** Identifier of the current step. */
    step: string;

    /** Children that should be rendered below the title of the item. */
    children?: ReactNode;
}
