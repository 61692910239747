/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useMemo, useState } from "react";
// Import the pdf renderer.
import { Pdf } from "@andromeda/storybook";

// Import the fullscreen modal.
import { FullscreenModal } from "../../fullscreen-modal";

// Import the css.
import css from "./pdf.module.scss";


/** Component used to render a pdf module. */
export default function PdfModule(props: PdfModuleProps): ReactElement {
    // If true, shows the module in full screen.
    const [fullscreen, setFullscreen] = useState(false);
    // Render the pdf component.
    const pdf = useMemo(function renderPDFElement(): ReactElement {
        return <Pdf
            fit="width"
            defaultMode="continuous"
            src={props.src}
            isFullscreen={fullscreen}
            className={css["pdf__document"]}
            toolbarClassName={fullscreen ? undefined : css["pdf__toolbar"]}
            onRequestFullscreen={() => setFullscreen(true)}
            onExitFullscreen={() => setFullscreen(false)}
        />;
    }, [fullscreen, props.src]);

    // Render the component.
    return <div className={css["pdf"]}>
        <h3 className={css["pdf__title"]} children={props.title} />
        {fullscreen ? null : pdf}
        <FullscreenModal show={fullscreen} hide={() => setFullscreen(false)} children={!fullscreen ? null : pdf} />
    </div>;
}

/** Props passed down to the {@link PdfModule}. */
export interface PdfModuleProps {
    /** The title of the current module. */
    title: string;
    /** The source of the PDF to render. */
    src: string;
}
