/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the headers interface.
import { XhrHeaders } from "../..";

// Import the logging tool.
import debug from "debug";
const log = debug("xhr:services:proxy");


/**
 * Implementation of the {@link ProxyHandler} for {@link XhrHeaders} objects.
 * Converts the object into a lowercase version of the headers.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class XhrHeaderProxyHandler implements ProxyHandler<XhrHeaders> {
    /**
     * Helper method used to apply the handler to a given target object.
     *
     * @param {XhrHeaders} target The target to generate a proxy for.
     * @returns {XhrHeaders} A proxy with the handler attached.
     */
    public static apply(target: { [key: string]: string | string[] | undefined } | undefined): XhrHeaders {
        log("Applying a new XhrHeaderProxyHandler.");
        // Convert all the target's properties to lowercase.
        return new Proxy(XhrHeaderProxyHandler.toLowerCase(target ?? {}), new XhrHeaderProxyHandler());
    }

    /**
     * Seeks the value of the header.
     *
     * @param {XhrHeaders} target The object to get the header from.
     * @param {string} header The name of the requested header.
     * @returns {string | string[] | null} The value of the header, or null if not found.
     */
    public get(target: XhrHeaders, header: string): string | string[] | null {
        // Check if the target has the header.
        header = header.toLowerCase();

        if (header in target) {
            return target[header];
        } else {
            return null;
        }
    }

    /**
     * Assigns a new value to a given header.
     * Converts the property to lowercase.
     *
     * @param {XhrHeaders} target The target to update.
     * @param {string} header The name of the header to set.
     * @param {string | string[]} value The new value of the header.
     */
    public set(target: XhrHeaders, header: string, value: string | string[]): boolean {
        target[header.toLowerCase()] = value;
        return true;
    }

    /**
     * Checks if the requested header exists.
     *
     * @param {XhrHeaders} target The headers object to check.
     * @param {string} header The name of the header to check.
     * @returns {boolean} True if the property exists.
     */
    public has(target: XhrHeaders, header: string): boolean {
        return Array.prototype.includes.call(this.ownKeys(target), header.toLowerCase());
    }

    /**
     * Deletes the given header from the proxy target.
     *
     * @param {XhrHeaders} target The target to update.
     * @param {string} header The name of the header to delete.
     */
    public deleteProperty(target: XhrHeaders, header: string): boolean {
        delete target[header.toLowerCase()];
        return true;
    }

    /**
     * Returns a list of all the keys in the header object.
     *
     * @param {XhrHeaders} target
     * @returns {ArrayLike<string | symbol>}
     */
    public ownKeys(target: XhrHeaders): ArrayLike<string> {
        return Object.keys(target).map(key => key.toLowerCase());
    }

    /** Helper method used to convert all the properties of the given object to lowercase. */
    protected static toLowerCase(target: { [key: string]: string | string[] | undefined }): XhrHeaders {
        const proxy: XhrHeaders = {};
        for (const [ key, value ] of Object.entries(target)) {
            if (value !== undefined) {
                proxy[key.toLowerCase()] = value;
            }
        }
        return proxy;
    }

    /** Private constructor forces the use of {@link apply}. */
    protected constructor() { return; }
}

/**
 * Implementation of the {@link ProxyHandler} for {@link XhrHeaders} objects.
 * Converts the object into a read-only, lowercase version of the headers.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class XhrReadonlyHeaderProxyHandler extends XhrHeaderProxyHandler {
    /**
     * Helper method used to apply the handler to a given target object.
     *
     * @param {XhrHeaders} target The target to generate a proxy for.
     * @returns {XhrHeaders} A proxy with the handler attached.
     */
    public static override apply(target: { [key: string]: string | string[] | undefined } | undefined): XhrHeaders {
        log("Applying a new XhrReadonlyHeaderProxyHandler.");
        // Convert all the target's properties to lowercase.
        return new Proxy(XhrHeaderProxyHandler.toLowerCase(target ?? {}), new XhrReadonlyHeaderProxyHandler());
    }

    /** Acts as if the target was sealed and frozen, aka: does nothing. */
    public override set(): boolean { return true; }
    /** Acts as if the target was sealed and frozen, aka: does nothing. */
    public override deleteProperty(): boolean { return true; }
}
