/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useId } from "react";

// Import the icons.
import { ReactComponent as Checkmark } from "@andromeda/assets/svg/checkmark.svg";
// Import the css.
import css from "./form.module.scss";


/** Component used to render the form of the external modal. */
export default function ExternalForm(props: ExternalFormProps): ReactElement {
    const id = useId();

    // Render the component.
    return <>
        <label htmlFor={`${id}-text`} className={css["label"]}>
            Nom:
        </label>
        <input
            id={`${id}-text`}
            type="text"
            className={css["text-field"]}
            placeholder="Donner un nom à cette formation externe ..."
            value={props.name}
            onChange={e => props.setName(e.currentTarget.value)}
        />
        <label htmlFor={`${id}-icon`} className={css["label"]}>
            Icône:
        </label>
        <input
            id={`${id}-icon`}
            type="file"
            className={css["hidden"]}
            multiple={false}
            accept="image/*"
            onChange={e => props.setImage(e.currentTarget.files?.item(0))}
        />
        <label htmlFor={`${id}-icon`} className={css["icon"]} children={props.image} />
        <button className={css["button"]} onClick={props.hide}>
            <Checkmark className={css["button__icon"]} />
            Sauvegarder les changements
        </button>
    </>;
}

/** Props passed down to the {@link ExternalForm} component. */
export interface ExternalFormProps {
    /** Component rendered for the icon of the external. */
    image?: ReactNode;
    /** Callback used to update the external's icon. */
    setImage(image: File | null | undefined): void;

    /** The name of the current external. */
    name: string;
    /** Callback used to update the external's name. */
    setName(name: string): void;

    /** Callback used to close the modal. */
    hide(): void;
}
