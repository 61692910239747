/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { PropsWithChildren, ReactElement } from "react";
// Import the CSS class name helper.
import classNames from "classnames";

// Import the css.
import css from "./header.module.scss";


/** Helper component used to render a widget header. */
export default function WidgetHeader(props: WidgetHeaderProps): ReactElement {
    return <header className={classNames(css["header"], props.className)}>
        <h1 className={css["title"]} children={props.title} />
        {props.children}
    </header>;
}

/** Props passed down to the {@link WidgetHeader} component. */
export interface WidgetHeaderProps extends PropsWithChildren {
    /** The title rendered in the header. */
    title: string;

    /** A class name added to the <header> element. */
    className?: string;
}
