/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// // Re-export the schemas.
// import * as ResourceSchema from "./resource.schema.json";
// import * as UpdateSchema from "./update.schema.json";
// import * as CreateSchema from "./create.schema.json";
// const Schema = { ResourceSchema, UpdateSchema, CreateSchema };
// export default Schema;

// Import all the schemas.
import application from "./application";
import audio from "./audio";
import font from "./font";
import image from "./image";
import model from "./model";
import text from "./text";
import video from "./video";

const schema = {
    ResourceSchema: {
        oneOf: [
            application.ResourceSchema,
            audio.ResourceSchema,
            font.ResourceSchema,
            image.ResourceSchema,
            model.ResourceSchema,
            text.ResourceSchema,
            video.ResourceSchema,
        ],
    },
    CreateSchema: {
        oneOf: [
            application.CreateSchema,
            audio.CreateSchema,
            font.CreateSchema,
            image.CreateSchema,
            model.CreateSchema,
            text.CreateSchema,
            video.CreateSchema,
        ],
    },
    UpdateSchema: {
        oneOf: [
            application.UpdateSchema,
            audio.UpdateSchema,
            font.UpdateSchema,
            image.UpdateSchema,
            model.UpdateSchema,
            text.UpdateSchema,
            video.UpdateSchema,
        ],
    },
};
export default schema;
