/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the resources.
import { Requirement } from "@andromeda/resources";

// Import the level types.
import type { Level, TargetLevel } from "./progress";


/** Object used to compute the progress of a given requirement. */
export interface RequirementProgress {
    /** Expected level for this requirement. */
    expected: TargetLevel;
    /** Level that was actually achieved in this requirement. */
    achieved: Level;
    /** Progress that was reached in this level. */
    progress: number;
    /** Flag set if the requirement was completely validated. */
    validated: boolean;
}

/**
 * Helper function used to parse the progress of a given {@link Requirement}.
 *
 * @param {Requirement} requirement The requirement to parse.
 * @returns {RequirementProgress} The progress of this requirement.
 */
export default function parseRequirement(requirement: Requirement): RequirementProgress {
    // Get the expected level from the requirement.
    const expected = requirement.attributes.requiredLevel + 1 as TargetLevel;

    // Check if the requirement has any items.
    if (requirement.attributes.detail.length <= 0) {
        return { expected, achieved: 4, progress: 1, validated: true };
    }

    // Loop through the items of the step.
    let progress = 0, achieved = 0, validated = true;
    for (const detail of requirement.attributes.detail) {
        if (Requirement.isZaqDetail(detail)) {
            // Check if the expected level was reached.
            if (detail.level !== null && detail.level > expected) {
                progress++; achieved += detail.level + 1;
            }

            // Check if the item was validated.
            validated &&= detail.validated;
        } else if (Requirement.isTutoDetail(detail) || Requirement.isWikiDetail(detail)) {
            // Check if the item was viewed.
            if (detail.viewed) {
                progress++; achieved += 4;
            }
        } else if (Requirement.isExternalDetail(detail)) {
            // Check if the expected level was reached.
            if (detail.level !== null && detail.level > expected) {
                progress++; achieved += detail.level + 1;
            } else {
                validated = false;
            }
        }
    }

    // Average the achieved level and progress.
    progress /= requirement.attributes.detail.length;
    achieved /= requirement.attributes.detail.length;

    // Return the progress.
    return { progress, expected, achieved: Math.round(achieved) as Level, validated: validated && progress >= 1 };
}
