/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement } from "react";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the css.
import css from "./zaqtiv.module.scss";


/** Component used to render the ZaqTiv logo on the screen. */
export function ZaqTiv(props: ZaqTivProps): ReactElement {
    // Build the class name.
    console.log(css);
    const className = classNames(
        css["zaqtiv"],
        { [css[`zaqtiv--${props.variant}`]]: props.variant },
        props.className
    );

    // Render the SVG.
    return <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.9 327.6">
        <g>
            <path
                className={css["path"]}
                d="M9.9,156.7h111.7l-62.6,86h52.7v32.5H0l62.9-86.4h-53V156.7z"
            />
            <path
                className={css["path"]}
                d={
                    "M216.5,275.3V255H216c-5.5,7.9-11.1,13.6-16.7,17.2c-5.6,3.5-12.5,5.3-20.8,5.3c-13.9,0-25.7-6" +
                    "-35.4-17.9s-14.6-26.5-14.6-43.6c0-16.9,5.2-31.4,15.6-43.5c10.4-12.1,22.7-18.1,36.9-18.1c6.8," +
                    "0,12.9,1.5,18.3,4.5c5.4,3,11.2,7.9,17.2,14.5V157h35.3v118.3H216.5z M190.6,189.7c-7,0-12.8,2." +
                    "8-17.5,8.3c-4.7,5.6-7,12.6-7,21c0,7.9,2.2,14.3,6.7,19.1c4.4,4.8,10.3,7.2,17.6,7.2c7,0,13-2.8," +
                    "17.9-8.3c5-5.6,7.4-12.3,7.4-20.2c0-7.4-2.5-13.7-7.4-19.1C203.3,192.4,197.4,189.7,190.6,189.7z"
                }
            />
            <path
                className={css["path"]}
                d={
                    "M357.5,327.6v-73.5H357c-5.6,8.3-11.2,14.2-16.7,17.9s-12.4,5.5-20.5,5.5c-14.2,0-26.1-5.8-35.9-" +
                    "17.4c-9.8-11.6-14.7-25.8-14.7-42.7c0-17.6,5.1-32.5,15.2-44.5s22.5-18.1,37-18.1c8,0,14.7,1.6," +
                    "20.2,4.9c5.5,3.3,10.6,8.5,15.4,15.7h0.6V157h35.3v170.5H357.5z M356.4,218c0-7.3-2.5-13.8-7.4-" +
                    "19.6c-4.9-5.8-10.5-8.7-16.6-8.7c-7.4,0-13.6,2.8-18.5,8.3s-7.3,12.4-7.3,20.8c0,7.7,2.3,14.1,6." +
                    "8,19.2c4.5,5.1,10.1,7.7,16.7,7.7c7.1,0,13.2-2.8,18.5-8.3S356.4,225.4,356.4,218z"
                }
            />
            <path
                className={css["path"]}
                d="M428.4,116.1h35.3v40.7h17v32.4h-17v86.2h-35.3v-86.2h-13.5v-32.4h13.5V116.1z"
            />
            <path
                className={css["path"]}
                d={
                    "M498.8,112.8c0-6,2.2-11.1,6.5-15.5s9.5-6.5,15.6-6.5c6.1,0,11.3,2.2,15.6,6.5c4.3,4.3,6.5,9.5," +
                    "6.5,15.5c0,6.1-2.2,11.3-6.5,15.6c-4.3,4.3-9.5,6.5-15.6,6.5c-6.1,0-11.3-2.2-15.6-6.5C501,124." +
                    "1,498.8,118.9,498.8,112.8z M503.4,156.7h35.3v118.6h-35.3V156.7z"
                }
            />
            <g>
                <path
                    className={css["box"]}
                    d={
                        "M685.2,306.3H629c-37.8,0-68.6-30.8-68.6-68.6v-56.2c0-37.8,30.8-68.6,68.6-68.6h56.2c37.8," +
                        "0,68.6,30.8,68.6,68.6v56.2C753.8,275.5,723,306.3,685.2,306.3z M629,132.3c-27.1,0-49.2,22" +
                        ".1-49.2,49.2v56.2c0,27.1,22.1,49.2,49.2,49.2h56.2c27.1,0,49.2-22.1,49.2-49.2v-56.2c0-27." +
                        "1-22.1-49.2-49.2-49.2H629z"
                    }
                />
            </g>
            <path
                className={css["path"]}
                d={
                    "M841.6,3.3C826,26.6,810.8,49.9,794.9,73l-47.3,69.2c-15.8,23-31.4,46.2-47.6,68.9l-29.8,42.3l-3" +
                    ".3,4.6l-15.2,21.6l-0.1,0.1c-1.1,1.6-3.4,2-5.2,0.9c-0.3-0.2-0.6-0.5-0.8-0.9l-15.6-20.9l-4.7-6." +
                    "3L601.6,221L558,161.9c-0.7-0.9-0.5-2.3,0.5-3c0.8-0.6,1.7-0.5,2.5-0.1l0.6,0.4c5.5,3.6,10.5,7.4," +
                    "15.8,11.1l15,11.6c9.9,7.9,19.2,16.2,28.8,24.3c6.6,5.9,13.1,11.9,19.5,18c21.3-37.4,45.6-72.5,72." +
                    "5-106c17.5-21.8,36.3-42.8,56.8-62.6c10.1-10,21-19.4,32.2-28.7c5.4-4.8,11.3-9.2,17.3-13.5c5.8-4." +
                    "4,12-8.6,18.5-12.7l0.7-0.5c1-0.6,2.3-0.3,2.9,0.7C842,1.8,842,2.6,841.6,3.3z"
                }
            />
        </g>
    </svg>;
}

/** Props passed down to the {@link ZaqTiv} component. */
export interface ZaqTivProps {
    /** Variant of the logo. */
    variant?: "primary" | "secondary" | "white";
    /** Optional class named added to the <svg> element. */
    className?: string;
}
