/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */


/**
 * Interface used to represent a resource filter.
 * Filters can be encoded into and decoded from a query parameter.
 */
export interface Filter {
    [resource: string]: PropertyFilter | CombinedFilter;
}

/** Interface used to describe a combination filter. */
export type CombinedFilter = {
    [K in CombinationOperators]?: PropertyFilter[];
}

/** Helper interface used to describe a filter applied to a property of a resource. */
export interface PropertyFilter {
    [property: string]: PropertyFilter | CombinedFilter | AnyOperator;
}

// Base type for all the operator types.
type Operator<O extends Operators, T> = { [operator in O]: T };
// Base types for the operator values.
export type PrimitiveValue = number | string | boolean | null;
export type ArrayValue = PrimitiveValue[];
export type AnyValue = PrimitiveValue | ArrayValue;

type EqualityOperator = Operator<Operators.eq, AnyValue>;
type NonEqualityOperator = Operator<Operators.ne, AnyValue>;
type GreaterThanOperator = Operator<Operators.gt, number>;
type GreaterThanOrEqualsOperator = Operator<Operators.gte, number>;
type LessThanOperator = Operator<Operators.lt, number>;
type LessThanOrEqualsOperator = Operator<Operators.lte, number>;
type InOperator = Operator<Operators.in, ArrayValue>;
type NotInOperator = Operator<Operators.nin, ArrayValue>;
type MatchOperator = Operator<Operators.match, { $eq: PrimitiveValue }>;

// Union of all the operator types.
export type AnyOperator = EqualityOperator | NonEqualityOperator |
    GreaterThanOperator | GreaterThanOrEqualsOperator | LessThanOperator | LessThanOrEqualsOperator |
    InOperator | NotInOperator | MatchOperator;

/** Exhaustive list of all the supported operators for the filtering. */
export enum Operators {
    eq = "$eq", ne = "$ne", gt = "$gt", gte = "$gte", lte = "$lte",
    lt = "$lt", in = "$in", nin = "$nin", match = "$elemMatch"
}

/** Exhaustive list of all the supported combination operators for the filtering. */
export enum CombinationOperators {
    and = "$and", or = "$or"
}

/**
 * Delimiter used to mark the end of the property and the start of the operator.
 *
 * @type {" "}
 */
export const PropertyDelimiter = " " as const;

/**
 * Delimiter used to mark the end of the operator and the start of the value.
 *
 * @type {":"}
 */
export const OperatorDelimiter = ":" as const;
