/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the validate function interface.
import { ValidateFunction } from "ajv";
// Import the JSON:API interfaces.
import { Resource, Relationship, ResourceIdentifier } from "@andromeda/json-api";
// Import the json:schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the resource helpers.

import { PartialResource, CreatableResource } from "@andromeda/resource-helper";
// Import the organisation, user and asset resources.
import { Organisation, User, Asset } from "../..";
// Import the step resource.
import { Step } from "../step";

// Import the schemas.
import { CourseSchema } from "./schema";


/**
 * Interface used to represent a course.
 * Course are owned by one or more organisations and hold steps.
 * Courses are edited and validated by users.
 */
export interface Course extends Resource<Course.Type> {
    /** @inheritDoc */
    attributes: Course.Attributes;
    /** @inheritDoc */
    relationships: Course.Relationships;
}

/** Augment the {@link Course} interface. */
export namespace Course {
    export const Type = "course" as const;
    export type Type = typeof Type;

    /** Attributes of a {@link Course} */
    export interface Attributes {
        /** Localised name of the course. */
        name: string;
    }

    /** Relationships of a {@link Course} */
    export interface Relationships {
        /** List of organisations that have access to this course. */
        readerOrganisations: Relationship<Organisation[]>;
        /** List of organisations that can edit this course. */
        editorOrganisations: Relationship<Organisation[]>;
        /** Organisation that owns this course. */
        ownerOrganisation: Relationship<Organisation>;
        /** List of users which can edit this course. */
        editors: Relationship<ResourceIdentifier<User.Type>[]>;
        /** List of users which can validate this course. */
        validators: Relationship<ResourceIdentifier<User.Type>[]>;
        /** List of steps in this course. */
        steps: Relationship<Step[]>;
        /** Icon used for this course. */
        icon: Relationship<Asset | Resource<"com.zimproov.api.zaqtiv.asset"> | null>;
    }

    export const validate: ValidateFunction<Course> = compileSync<Course>(CourseSchema.ResourceSchema);


    /** Interface used to update a {@link Course}. */
    export interface Update extends Omit<PartialResource<Course>, "relationships"> {
        relationships?: Update.Relationships;
    }

    /** Augments the {@link Update} interface. */
    export namespace Update {
        export type Relationships = Omit<Partial<Course.Relationships>, "icon">;
        export const validate: ValidateFunction<Course.Update> = compileSync<Course.Update>(CourseSchema.UpdateSchema);
    }


    /** Interface used to create new {@link Course}. */
    export interface Create extends CreatableResource<Course.Type> {
        attributes: Course.Attributes;
        relationships: Course.Create.Relationships;
    }

    /** Augments the {@link Create} interface. */
    export namespace Create {
        export type Relationships =
            Omit<Partial<Course.Relationships>, "ownerOrganisation" | "icon"> &
            Pick<Course.Relationships, "ownerOrganisation">;
        export const validate: ValidateFunction<Course.Create> = compileSync<Course.Create>(CourseSchema.CreateSchema);
    }
}
