/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useContext } from "react";
// Import the store.
import { useResourceDispatch, useZaq, ZaqStore } from "@andromeda/store";
// Import the resources.
import type { ZaqWiki } from "@andromeda/resources";
// Import the custom components.
import { useDeleteConfirm, useNotify } from "@andromeda/components";
// Import the feature flag hook.
import { LoginContext } from "@andromeda/login";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";

// Import the hooks.
import { useCanDeleteZaqWiki, useCanEditZaqWiki } from "../../hooks";
// Import the item renderer.
import ItemRenderer from "./item";
import ZaqIcon from "./icons/zaq";


/** Component used to render a new Zaq item. */
export default function ZaqItem(props: ZaqItemProps): ReactElement | null {
    // Check if the user can edit/delete the tuto.
    const canEdit = useCanEditZaqWiki() && props.withButtons;
    const canDelete = useCanDeleteZaqWiki() && props.withButtons;

    // Get the notification tool.
    const { fatal } = useNotify();
    // Get the legacy ZaqWiki dispatch.
    const dispatch = useResourceDispatch();
    // Get the deletion confirmation tool.
    const confirmDeletion = useDeleteConfirm("Êtes vous sûr de vouloir supprimer ce ZaqWiki ?");

    // Callback used to delete the ZaqWiki.
    const deleteWiki = useCallback(function deleteCurrentZaqWiki(): void {
        confirmDeletion()
            .then(function deleteZaqWiki(canDelete: boolean): void | Promise<void> {
                if (canDelete) {
                    return dispatch(ZaqStore.generator.delete(props.resource.id));
                }
            })
            .catch(fatal);
    }, [confirmDeletion, dispatch, fatal, props.resource.id]);

    // Render the item.
    return <ItemRenderer
        resource={props.resource}
        title={!props.resource.attributes.name ? "Sans nom" : props.resource.attributes.name}
        description={props.resource.attributes.body ?? ""}
        icon={ZaqIcon}
        iconProps={{ icon: props.resource.relationships.icon.data?.id }}
        tags={props.resource.attributes.tags}
        play={`/preview/player/${props.resource.id}`}
        edit={canEdit && `/preview/editor/${props.resource.id}`}
        delete={canDelete ? deleteWiki : undefined}
    />;
}

/** Props passed down to the {@link ZaqItem} component. */
export interface ZaqItemProps {
    /** ZaqWiki resource being rendered. */
    resource: ZaqWiki;
    /** Flag set if the edit and delete button should be shown. */
    withButtons?: boolean;
}

/**
 * Wrapper component used to download the {@link ZaqWiki} item from the store,
 * before rendering the {@link ZaqItem}.
 */
export function ZaqItemWrapper(props: ZaqItemWrapperProps): ReactElement | null {
    // Load the wiki from the store.
    const { organisations: { current: organization }} = useContext(LoginContext);
    const wiki = useZaq(props.id, organization.id);

    // Render nothing if the loading failed.
    if (wiki.isError) {
        return null;
    }

    // Render a loader while the course is loading.
    if (!wiki.isSuccess) {
        return <Loader containerClassName={props.loaderClassName} text="Chargement du ZaqWiki ..." />;
    }

    // Render the item.
    return <ZaqItem {...props} resource={wiki.data} />;
}

/** Props passed down to the {@link ZaqItemWrapper} component. */
export interface ZaqItemWrapperProps extends Omit<ZaqItemProps, "resource"> {
    /** Identifier of the {@link ZaqWiki} to render. */
    id: string;
    /** CSS class name added to the loader container. */
    loaderClassName?: string;
}
