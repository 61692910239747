/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the event target.
import { EventHandler } from "@andromeda/events";

/** Map of all the registry events and their listeners.. */
interface RegistryEventMap {
    "before:mount:middleware": string;
    "mount:middleware": string;
    "after:mount:middleware": string;

    "before:unmount:middleware": string;
    "unmount:middleware": string;
    "after:unmount:middleware": string;

    "before:mount:reducer": string;
    "mount:reducer": string;
    "after:mount:reducer": string;

    "before:unmount:reducer": string;
    "unmount:reducer": string;
    "after:unmount:reducer": string;
}

/** Interface that can be used as a target for registry events. */
export class RegistryEventHandler extends EventHandler<RegistryEventMap> {}
