/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the validation compiler.
import { compileSync } from "@andromeda/validation";

// Import the message interface.A
import { Message, DataMessageWithoutId } from "../../interfaces";
// Import the schemas.
import * as JsonApiSchema from "./json-api.schema.json";
import * as JsonApiRequestSchema from "./json-api-request.schema.json";

/** Promise that resolves with the message validator. */
export const messageValidator = compileSync<Message>(JsonApiSchema);
/** Promise that resolves with the request validator. */
export const requestValidator = compileSync<DataMessageWithoutId>(JsonApiRequestSchema);
