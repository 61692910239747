/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the css.
import css from "./create-element.module.scss"
// Import the icons.
import add from "@andromeda/assets/images/add.svg";

export function CreateElement(props: CreateElementProps): React.ReactElement {
    return <div className={css["create-menu"]}>
        <button className={css["create-menu__anchor"]}>
            <img src={add} alt="open-menu" />
        </button>
    </div>
}

interface CreateElementProps {
    //onCreateElement: () => void;
}
