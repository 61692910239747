/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ComponentType } from "react";
// Import the store.
import { withReducer, withRequestStore } from "@andromeda/store";

// Import the reducer.
import { legacyZaqReducer, LegacyZaqReducerKey } from "./reducer";


// Re-export the reducer and actions.
export { LegacyZaqReducerKey, legacyZaqReducer } from "./reducer";
export type { LegacyZaqReducerStore, LegacyZaqReducerState } from "./reducer";
export type { RequestZaqAction, RegisterZaqAction } from "./actions";
export { downloadZaq, downloadMultipleZaq, deleteZaq } from "./actions";

/** Helper used to mount a component with access to the {@link legacyZaqReducer}. */
export function withLegacyZaqReducer<P extends object>(component: ComponentType<P>): ComponentType<P> {
    return withRequestStore(
        withReducer(
            component,
            LegacyZaqReducerKey,
            legacyZaqReducer
        ),
    );
}
