/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the common interfaces.
import { XhrBodyType, XhrHeaders } from "./common";
// Import the request interface.
import { PreparedXhrRequest } from "./request";


/**
 * Interface used to represent a response sent from the server.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export interface XhrResponse {
    /** Reference to the request object that generated this response. */
    request: Readonly<PreparedXhrRequest>;
    /** Status code of the response. */
    status: number;
    /** List of headers returned by the server. */
    headers: XhrHeaders;
    /**
     * Body of the response returned by the server.
     * Will be a {@link string} if the response's mime type is "application" or "text".
     * Otherwise, will be a {@link Buffer} in Node.JS environments and an {@link ArrayBuffer} anywhere else.
     */
    body?: XhrBodyType;
}

/**
 * Extension of the {@link XhrResponse} with the body not yet fully downloaded.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export interface XhrPartialResponse extends Omit<XhrResponse, "body"> {
    /** Body of the response returned by the server. */
    body: Promise<XhrBodyType | undefined>;
    /** Method that can be used to stop the reading from the server. */
    abort(): void;
}
