/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */


import { Admin as UnwrappedAdmin } from "./components";
import { UserStore } from "@andromeda/store";

export const Admin = UserStore.withReducer(UnwrappedAdmin);
