/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


export type {
    LegacyResource,
    LegacyRelationship,
    LegacyResourceIdentifier,
    LegacyRelationships,
    WithLegacyRelationships
} from "./legacy-resource";
export {
    LegacyZaqAssetType,
    LegacyZaqTranslationType,
    LegacyZaqType,
} from "./legacy-zaq";
export type {
    Extra,
    Action,
    LegacyZaq,
    FullLegacyZaq,
    LegacyZaqAsset,
    MajorStep,
    MinorStep,
    Timeline
} from "./legacy-zaq";
