/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext, useMemo } from "react";
// Import the login module.
import { LoginContext, useIsAdmin } from "@andromeda/login";

// Import the option type.
import type { Option } from "./pages/selector";
// Import the page types.
import type { PageType } from "./pages";

// Import the css.
import css from "./page-selector.module.scss";
import { useCourses } from "@andromeda/store";


/** Helper component used to render the page selector. */
export default function PageSelector(props: PageSelectorProps): ReactElement {
    // Check if the user is an admin.
    const isAdmin = useIsAdmin();

    // Load the list of ZaqTraining.
    const training = useCourses(useContext(LoginContext).organisations.current.id);

    // Build the list of selectable pages.
    const showTrainingPage = training.isSuccess && training.data.length > 0;
    const pages = useMemo(function loadPageOptions(): Option<PageType>[] {
        const pages: Option<PageType>[] = [{ name: "Favoris", value: "favourites" }];

        // Add the user-training option if active.
        if (showTrainingPage) {
            pages.push({ name: "Formations", value: "training" });

            if (isAdmin) {
                pages.push({ name: "Suivi", value: "monitoring" });
            }
        }
        return pages;
    }, [isAdmin, showTrainingPage]);

    // Render all the options.
    const options = useMemo(function renderChoices(): ReactElement[] {
        return pages.map(function renderChoice(option, index: number): ReactElement {
            return <li
                key={index}
                className={css["item"]}
                aria-pressed={props.current === option.value}
                role="button"
                onClick={() => props.onChange(option.value)}
                children={option.name}
            />;
        });
    }, [pages, props]);

    // Render the component.
    return <ul className={css["selector"]} children={options} />;
}

/** Props passed down to the {@link PageSelector} component. */
export interface PageSelectorProps {
    /** The type of the currently active page. */
    current: PageType;

    /**
     * Callback invoked when a new page type was requested.
     *
     * @param {PageType} type The selected page type.
     */
    onChange(type: PageType): void;
}
