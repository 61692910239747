/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json-api object interface.
import { JsonApi } from "./json-api";
// Import the links interfaces.
import { AnyLinks} from "./links";
// Import the metadata interface.
import { Metadata } from "./meta";
// Import the resource interfaces.
import { Resource, ResourceWithoutId } from "./resource";


/**
 * Interface used to describe a data message with resources that have no identifiers.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
interface DataMessageWithDataWithoutId<
  Resources extends ResourceWithoutId | ResourceWithoutId[] | null = ResourceWithoutId | ResourceWithoutId[] | null
> {
    /** Data of the message. */
    data?: Resources;
}

/**
 * Interface used to describe a data message with resources.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
interface DataMessageWithData<
  Resources extends Resource | Resource[] | null = Resource | Resource[] | null
> {
    /** Data of the message. */
    data?: Resources;
}

/**
 * Interface used to describe a data message with included resources.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
interface DataMessageWithDataWithIncludedData<
  Resources extends Resource | Resource[] | null = Resource | Resource[] | null,
  IncludedResources extends Resource[] = Resource[]
> extends DataMessageWithData<Resources> {
    /** Additional resources in the message. */
    included?: IncludedResources;
}

/**
 * Interface used to describe a data message with details about the JSON:API implementation.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
interface DataMessageWithJsonApi {
    /** Details about the JSON:API implementation. */
    jsonapi?: JsonApi;
}

/**
 * Interface used to describe a data message with some links.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
interface DataMessageWithLinks {
    /** Links of the primary data in the message. */
    links?: AnyLinks;
}

/**
 * Interface used to describe a data message with some metadata.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
interface DataMessageWithMeta<Meta extends Metadata = Metadata> {
    /** Non-standard metadata regarding the element. */
    meta?: Meta;
}

/**
 * Interface used to represent a message without any data.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type InfoMessage = DataMessageWithJsonApi & DataMessageWithLinks & DataMessageWithMeta;

/**
 * Interface used to represent a message with some data.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type DataMessage<
    Resources extends Resource | Resource[] | null = Resource | Resource[] | null,
    IncludedResources extends Resource[] = Resource[]
> = DataMessageWithData<Resources> & DataMessageWithDataWithIncludedData<Resources, IncludedResources> &
    DataMessageWithJsonApi & DataMessageWithLinks & DataMessageWithMeta;

/**
 * Interface used to represent a message with some data having no id.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type DataMessageWithoutId<
      Resources extends ResourceWithoutId | ResourceWithoutId[] | null = ResourceWithoutId | ResourceWithoutId[] | null
> = DataMessageWithDataWithoutId<Resources> & DataMessageWithJsonApi & DataMessageWithMeta;
