/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useMemo, ReactNode } from "react";

// Import the box header.
import BoxHeader, { BoxHeaderProps } from "./header";
// Import the box state helper.
import useBoxState, { OpenBoxProps } from "./open";

// Import the css.
import css from "./index.module.scss";


// Re-export the header props.
export type { BoxHeaderProps, OpenBoxProps };

/**
 * Component used to render a box.
 * Boxes are items with headers and bodies.
 * Boxes can be opened.
 */
export default function Box(props: BoxProps): ReactElement {
    // Load the state of the box.
    const { state, toggle } = useBoxState(props);

    // Render the body of the box.
    const body = useMemo(function renderBoxBody(): ReactElement | null {
        // Compute the class name.
        let className = css["box__body"];
        if (props.bodyClassName) {
            className += " " + props.bodyClassName;
        }

        // Render the body.
        return <main className={className} children={props.children} />;
    }, [props.bodyClassName, props.children]);


    // Render the component.
    return <article className={css["box"]}>
        <BoxHeader
            {...props.headerProps}
            open={state}
            toggle={toggle}
            locked={"isOpen" in props || props.canOpen === false}
        />
        {state ? body : null}
    </article>;
}

/** Props passed down to the {@link Box} component. */
export type BoxProps = OpenBoxProps & BoxOwnProps;

/** Own props of the {@link Box} component. */
interface BoxOwnProps {
    /** Contents of the body of the box. */
    children?: ReactNode;
    /** Props passed down to the header. */
    headerProps?: BoxHeaderProps;

    /** Class name added to the body container. */
    bodyClassName?: string;
}
