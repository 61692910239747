/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the resources.
import { Course, DueDate, Requirement } from "@andromeda/resources";
// Import the store.
import { CourseStore, DueDateStore, getResource, getResources, RequirementStore, store } from "@andromeda/store";

// Import the progress.
import { AnyTrainingProgress, Level, RequiredStep, TargetLevel } from "./progress";
// Import the requirement parser.
import parseRequirement from "./requirement";


/**
 * Helper function used to download the progress of a {@link ZaqTraining}.
 *
 * @param {string} training The training to load.
 * @param {string} user The user to get the progress for.
 * @returns {Promise<AnyTrainingProgress>} A promise that resolves with the loaded progress.
 */
export default async function loadTrainingProgress(
    training: string,
    user: string
): Promise<AnyTrainingProgress | null> {
    // Download the training from the store.
    const resource = await getResource(store, CourseStore, Course.Type, training, true, true);

    // If the course has no steps, stop here.
    if (resource.relationships.steps.data.length <= 0) {
        return null;
    }

    // Get the list of all the step identifiers.
    const steps = resource.relationships.steps.data.map(item => `"${item.id}"`).join(",");

    // Download all the user requirements for each step.
    const requirements = await getResources(
        store,
        RequirementStore,
        Requirement.Type,
        {
            immediate: true,
            searchParams: {
                filter: `step.id $in:${steps};requirement.user $eq:"${user}"`
            }
        },
        state => {
            return state.resources.filter(requirement => {
                // Check if the requirement is for the current user.
                if (requirement.relationships.user.data.id !== user) {
                    return false;
                }

                // Check if the requirement is for one of the steps of the course.
                return resource.relationships.steps.data.some(
                    step => step.id === requirement.relationships.step.data.id
                );
            });
        }
    );

    // If the requirement list is empty, return an empty progress.
    if (requirements.length <= 0) {
        return null;
    }

    // Parse the overall progress.
    let progress = 0, expected = 0, achieved = 0, validated = true;
    for (let i = 0; i < requirements.length; i++){
        // Parse the progress of the step.
        const requirementProgress = parseRequirement(requirements[i]);

        // Apply the level to the element.
        expected += requirementProgress.expected;
        validated &&= requirementProgress.validated;

        // Count progress only for subsequent steps.
        if (progress + requirementProgress.progress >= i + 1) {
            achieved += requirementProgress.achieved;
            progress += requirementProgress.progress;
        }
    }

    // Get the list of required steps.
    const info = requirements.map(function getRequirementInfo(requirement: Requirement): RequiredStep {
        return {
            id: requirement.relationships.step.data.id,
            length: requirement.attributes.detail.length
        };
    });

    // Check if the user has a due date for the training.
    const dueDate = await getResources(
        store,
        DueDateStore,
        DueDate.Type,
        {
            immediate: true,
            searchParams: {
                filter: `course.id $eq:"${training}";due-date.user $eq:"${user}"`
            }
        },
        state => state.resources.filter(date => {
            return date.relationships.user.data.id === user && date.relationships.course.data.id === training;
        })
    );
    let due: Date | null = null;
    if (dueDate.length > 0) {
        due = new Date(dueDate[0].attributes.date);
    }

    return {
        user,
        training,
        progress: progress / requirements.length,
        due,
        expected: Math.round(expected / requirements.length) as TargetLevel,
        achieved: Math.round(achieved / requirements.length) as Level,
        validated: validated ?? false,
        steps: info
    } as AnyTrainingProgress;
}
