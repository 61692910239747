/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { MouseEvent, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
// Import the pdf renderer component.
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
// Import the fontawesome icon renderer.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the debug tool.
import debug from "debug";

// Import the loader.
import { Loader } from "../loader";

// Import the icons.
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./pdf.module.scss";


/** Simple component used to render a pdf. */
export function Pdf(props: Props): ReactElement {
    // Store the state of the renderer.
    const [ page, setPage ] = useState(0);
    const [ ratio, setRatio ] = useState(1.61803);
    const [ pageCount, setPageCount ] = useState<number>();

    // Store a reference to the page container.
    const [ container, setContainer ] = useState<HTMLElement | null>(null);

    // Determine the size of the page.
    const [ pageSize, setPageSize ] = useState<readonly [ height: number, width: number ]>(DEFAULT_SIZE);
    const recomputeSize = useCallback(function computePdfSize(): void {
        if (!container) return;

        // Resize the document to fit in the container.
        const { width } = container.getBoundingClientRect();
        log("Width of the pdf container: %d", width);
        let newSize: [ number, number ] = [ width / ratio, width ];
        log("Computed size of this pdf file: %dx%d", newSize[0], newSize[1]);

        // Check if the size fits in the window.
        if (props.maxHeight && newSize[0] > props.maxHeight) {
            newSize = [ props.maxHeight, props.maxHeight * ratio ];
            log("Fitted to max size: %dx%d", newSize[0], newSize[1]);
        }
        setPageSize(newSize);
    }, [container, props.maxHeight, ratio]);
    useEffect(function attachResizeListeners(): () => void {
        setTimeout(recomputeSize, 0);
        window.addEventListener("resize", recomputeSize, { passive: true });
        return () => window.removeEventListener("resize", recomputeSize);
    }, [recomputeSize]);

    // Compute the class names.
    const pageClassName = useMemo(function buildPageClassName(): string {
        let className = css["pdf__page"];
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }, [props.className]);
    const documentClassName = useMemo(function buildDocumentClassName(): string {
        let className = css["pdf__document"];
        if (props.documentClassName) {
            className += " " + props.documentClassName;
        }
        return className;
    }, [props.documentClassName]);
    const selectorClassName = useMemo(function buildSelectorClassName(): string {
        let className = css["pdf__selector"];
        if (props.fixedSelector) {
            className += " " + css["pdf__selector--fixed"];
        }
        if (props.selectorClassName) {
            className += " " + props.selectorClassName;
        }
        return className;
    }, [props.fixedSelector, props.selectorClassName]);

    // Render the document
    const document = useMemo(function renderDocument(): ReactElement {
        return <Document
            file={props.source}
            renderMode="canvas"
            onLoadSuccess={proxy => setPageCount(proxy.numPages)}
            className={documentClassName}
        >
            <Page
                pageIndex={page}
                className={pageClassName}
                loading={<Loader asBlock text={"Chargement de la page ..."} />}
                renderAnnotationLayer={false}
                renderInteractiveForms={false}
                onClick={props.onClick}
                onLoadSuccess={page => {
                    if (page.rotate === 270 || page.rotate === 90) {
                        setRatio(page.originalHeight / page.originalWidth);
                    } else {
                        setRatio(page.originalWidth / page.originalHeight);
                    }
                }}
                height={pageSize[0]}
                width={pageSize[1]}
                renderMode="canvas"
            />
        </Document>;
    }, [props.source, props.onClick, documentClassName, page, pageClassName, pageSize]);

    // Prepare the next and previous callbacks.
    const next = useCallback(function next(): void {
        if (pageCount && page < pageCount - 1) setPage(page + 1);
    }, [ page, pageCount, setPage ]);
    const previous = useCallback(function next(): void {
        if (pageCount && page > 0) setPage(page - 1);
    }, [ page, pageCount, setPage ]);

    // Render the page selector.
    const pageSelector = useMemo(function renderPageSelector(): ReactElement {
        return <div className={selectorClassName}>
            <button
                disabled={!pageCount || page <= 0}
                onClick={previous}
                className={`${css["pdf__selector__button"]} ${css["pdf__selector__button--previous"]}`}
            >
                <FontAwesomeIcon icon={faChevronLeft} className={css["pdf__selector__button__icon"]} />
            </button>
            <p className={css["pdf__selector__text"]}>Page {page + 1} / {pageCount}</p>
            <button
                disabled={!pageCount || page >= pageCount - 1}
                onClick={next}
                className={`${css["pdf__selector__button"]} ${css["pdf__selector__button--next"]}`}
            >
                <FontAwesomeIcon icon={faChevronRight} className={css["pdf__selector__button__icon"]} />
            </button>
        </div>;
    }, [selectorClassName, pageCount, page, previous, next]);

    // Render the component.
    return <div className={css["pdf"]} ref={setContainer}>
        {pageSelector}
        {document}
        {typeof pageCount === "undefined" ? <Loader text={"Chargement de votre fichier pdf ..."}/> : null}
    </div>;
}

/** Props passed to the {@link Pdf} component. */
interface Props {
    /** URL to the document to render. */
    source: string;
    /** Max height of the document. */
    maxHeight?: number;

    /** Class name to apply to the pdf page. */
    className?: string;
    /** Class name to apply to the pdf document. */
    documentClassName?: string;
    /** Class name to apply to the selector element. */
    selectorClassName?: string;
    /** Event invoked when the pdf is clicked. */
    onClick?: (event: MouseEvent) => void;

    /** If set to true, the page selected will be fixed on the page. */
    fixedSelector?: boolean;
}

// Ratio of a standard A4 sheet.
const RATIO = 1.61803;
// Default size of the pdf files.
const DEFAULT_SIZE: readonly [ height: number, width: number ] = [ 900, 900 / RATIO ];
const log = debug("components:pdf");
