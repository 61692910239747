/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { createContext, Context } from "react";
// Import the context noop tool.
import { makeContextNoop } from "@andromeda/tools";

// Import the notification interface.
import { SimpleNotification, ErrorNotification } from "./notification";


/** Interface used for the notification context. */
export interface NotificationContext {
    /**
     * Pushes a new notification on to the list.
     *
     * @param {Omit<SimpleNotification, "uuid">} notification The notification to add to the list.
     */
    push(notification: Omit<SimpleNotification, "uuid">): void;

    /**
     * Pushes a new error notification on to the list.
     *
     * @param {Omit<ErrorNotification, "uuid">} notification The notification to add to the list.
     */
    push(notification: Omit<ErrorNotification, "uuid">): void;

    /** Pushes a new debug message to the list. */
    debug(title?: string | null, text?: string | null): void;
    /** Pushes a new info message to the list. */
    info(title?: string | null, text?: string | null): void;
    /** Pushes a new warning message to the list. */
    warning(title?: string | null, text?: string | null): void;
    /** Pushes a new error message to the list. */
    error(error: unknown, title?: string | null, text?: string | null): void;
    /** Pushes a new fatal error message to the list. */
    fatal(error: unknown, title?: string | null, text?: string | null): void;
}

/** Augmentation used to check if a context exists above the current provider. */
export interface InternalNotificationContext extends NotificationContext {
    /** Flag that MUST be set on the default value of the context. */
    _isDefault: boolean;
}

/** Default state of the context. */
const DEFAULT_STATE: InternalNotificationContext = {
    push: makeContextNoop("NotificationContext", "push"),
    debug: makeContextNoop("NotificationContext", "debug"),
    info: makeContextNoop("NotificationContext", "info"),
    warning: makeContextNoop("NotificationContext", "warning"),
    error: makeContextNoop("NotificationContext", "error"),
    fatal: makeContextNoop("NotificationContext", "fatal"),
    _isDefault: true
};

/** Context used for the Notification system. */
export const NotificationContext = createContext<InternalNotificationContext>(
    DEFAULT_STATE
) as unknown as Context<NotificationContext>;
