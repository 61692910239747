/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API error class.
import { JsonApiError } from "./json-api";
import { Operators, AnyValue } from "../services";


/**
 * Error thrown if a bad filter was found.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class BadFilterError extends JsonApiError {
    /**
     * Class constructor.
     *
     * @param {string} filter The invalid filter that could not be parsed.
     * @param {Error} inner The inner error that was caught.
     */
    public constructor(filter: string, inner?: Error) {
        super(`Found a bad filter: ${filter}${typeof inner === "undefined" ? "" : `\nInner error: ${String(inner)}`}`);
    }
}


/**
 * Error thrown if an invalid operator was found.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class InvalidOperatorError extends JsonApiError {
    /**
     * Class constructor.
     *
     * @param {string} operator The invalid operator that could not be parsed.
     */
    public constructor(operator: string) {
        super(
            `Found a bad operator: "${operator}". ` +
            `Expected one of [ ${Object.values(Operators).map(op => `"${op}"`).join(", ")} ]`
        );
    }
}

/**
 * Error thrown if an invalid value was found.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class InvalidValueError extends JsonApiError {
    /**
     * Class constructor.
     *
     * @param {AnyValue} value The invalid value that could not be parsed.
     * @param {Operators} [operator=undefined] The operator that the value was invalid for.
     */
    public constructor(value: AnyValue, operator?: Operators) {
        if (Array.isArray(value)) value = `[ ${value.join(", ")} ]`;
        super(`Found a bad value${typeof operator === "string" ? ` for operator ${operator}` : ""}: ${value}.`);
    }
}
