/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement, ReactNode } from "react";
// Import the font-awesome component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the resources.
import type { Step } from "@andromeda/resources";

// Import the delete button.
import DeleteButton from "./delete-button";

// Import the icons.
import { ReactComponent as Checkmark } from "@andromeda/assets/svg/checkmark.svg";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./item.module.scss";


/**
 * Wrapper used by all the specialised item types.
 * Provides an easy way to render all the item properties.
 */
export default function ItemContainer(props: ItemContainerProps): ReactElement {
    // Build the icon.
    let icon: ReactNode;
    if (props.loading) {
        icon = <Loader containerClassName={`${css["icon"]} ${css["icon--loader"]}`} />;
    } else if (props.icon) {
        icon = <img className={css["icon"]} src={props.icon} alt={props.title} />;
    } else {
        icon = <FontAwesomeIcon className={`${css["icon"]} ${css["icon--missing"]}`} icon={faQuestion} />;
    }

    return <section role={props.onClick ? "button" : "none"} className={css["item"]} onClick={props.onClick}>
        <div className={css["icon__container"]}>
            {icon}
            <div className={css["type"]}>
                <span className={css["type__text"]}>{props.kind}</span>
                <Checkmark className={css["type__icon"]} />
            </div>
            <DeleteButton step={props.step} item={props.item} />
        </div>
        <h3 className={css["title"]} children={props.title} />
        {props.children}
    </section>
}

/** Props passed down to the {@link ItemContainer} component. */
export interface ItemContainerProps {
    /** Reference to the item being rendered. */
    item: Step.Item;
    /** Step in which the item is being rendered. */
    step: string;
    /** Kind of item being rendered. Used for the top-left container. */
    kind: string;
    /** Title of the item being rendered. */
    title?: string;
    /** Icon of the item being rendered. */
    icon?: string;
    /** If set, marks the item as loading. */
    loading?: boolean;
    /** Additional items rendered below the name. */
    children?: ReactNode;
    /** Callback invoked when the item is clicked. */
    onClick?(): void;
}
