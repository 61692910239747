/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the CSS class name helper.
import classNames from "classnames";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the icons.
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faImage } from "@fortawesome/free-regular-svg-icons/faImage";
// Import the css.
import css from "./icon.module.scss";


/** Helper component used to render an icon. */
export default function IconRenderer(props: IconRendererProps): ReactElement {
    // Extract the url from the asset.
    const [url, setUrl] = useState<RequestStatus<string | null>>(RequestStatus.uninitialised());
    useEffect(
        function loadUrlFromAsset(): void | VoidFunction {
            // Propagate the error upwards.
            if (props.asset.isError) {
                return;
            }

            // Wait while the asset is loading.
            if (props.asset.isLoading || props.asset.isUninitialised || props.asset.isError) {
                return setUrl(props.asset);
            }

            // Get the asset's source.
            const source = props.asset.data;
            if (source === null) {
                return setUrl(RequestStatus.success(null));
            }

            // If the source is a string, use it as-is.
            if (typeof source === "string") {
                return setUrl(RequestStatus.success(source));
            }

            // Generate the url from the props.asset.
            const url = URL.createObjectURL(source);
            setUrl(RequestStatus.success(url));

            // Return a cleanup for the url.
            return function revokeUrl(): void {
                setUrl(RequestStatus.uninitialised());
                URL.revokeObjectURL(url);
            };
        },
        [
            props.asset,
            props.asset.data,
            props.asset.isError,
            props.asset.isLoading,
            props.asset.isUninitialised
        ]
    );

    // Fits the image to its parent container.
    const observer = useRef<ResizeObserver>();
    const fitToParent = useCallback(function fitImageToParent(image: HTMLImageElement | null): void {
        // Clear the existing observer.
        if (observer.current) {
            observer.current.disconnect();
            delete observer.current;
        }

        // Wait for the image and its parent to be set.
        if (image === null || image.parentElement === null) {
            return;
        }

        // Resize to the parent at least once.
        onImageContainerResized();

        // Create the observer.
        observer.current = new ResizeObserver(onImageContainerResized);
        observer.current.observe(image);

        // Callback provided to the resize observer.
        function onImageContainerResized(): void {
            // Wait for the image and its parent to be set.
            if (image === null || image.parentElement === null) {
                return;
            }

            // Compute the aspect ratio of the image.
            const ratio = image.naturalWidth / image.naturalHeight;

            // Compute the size of the image.
            let { width, height } = image.parentElement.getBoundingClientRect();
            if (width / height > ratio) {
                width = height * ratio;
            } else {
                height = width / ratio;
            }

            // Set the size of the image.
            image.style.width = `${Math.floor(width)}px`;
            image.style.height = `${Math.floor(height)}px`;
        }
    }, []);

    // If the url is loading, return a spinner.
    if (url.isUninitialised || url.isLoading) {
        return <FontAwesomeIcon icon={faSpinner} className={classNames(css["loader"], props.className)} />;
    }

    // If the url is null, or an error arose, return the question mark icon.
    if (url.data === null || url.isError) {
        return <FontAwesomeIcon icon={faImage} className={classNames(css["questionmark"], props.className)} />;
    }

    // Render the icon.
    return <img
        ref={fitToParent}
        className={classNames(css["icon"], props.className)}
        src={url.data}
        alt={props.alt}
    />;
}

/** Props passed down to the {@link IconRenderer} component. */
export interface IconRendererProps {
    /** Rendered asset of the icon. */
    asset: RequestStatus<Blob | string | null>;
    /** Alternative text shown if the image cannot load. */
    alt: string;
    /** Class name added to the <img> element. */
    className?: string;
}
