/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useEffect, useState, useTransition } from "react";
// Import the common tools.
import type { RequestStatus } from "@andromeda/tools";
// Import the custom components.
import { useNotify } from "@andromeda/components";

// Import the database loader.
import { getDatabase } from "./database";


/**
 * Hook used to store data in IndexedDatabase to cache requests made to the server.
 *
 * @template T
 * @param {string | undefined} key The key of the cached data.
 * @param {RequestStatus<T>} data The data to store in cache once it is loaded.
 * @returns {T | null} The data that was found in the cache, or {@link null} if there is no cached data.
 */
export function useCachedData<T>(key: string | undefined, data: RequestStatus<T>): T | null | undefined {
    // Get the notification tool.
    const { error } = useNotify();

    // State used to store the loaded data.
    const [cachedData, setCachedData] = useState<T | null>();

    // Load the cached data from the database when the key is defined.
    const [isLoadingCache, startUpdatingCachedData] = useTransition();
    useEffect(function loadCachedData(): void {
        // Do nothing if the key is undefined.
        if (typeof key === "undefined") {
            return setCachedData(null);
        }

        getDatabase().then(function onDatabaseOpen(database: IDBDatabase): void {
            // Get the data from the database.
            const request = database.transaction("cache", "readonly").objectStore("cache").get(key);

            // Update the state when the data is loaded.
            request.addEventListener("success", function onDataLoaded(): void {
                startUpdatingCachedData(function updateCacheInBackground(): void {
                    if (request.result?.data != null) {
                        setCachedData(request.result.data);
                    } else {
                        setCachedData(null);
                    }
                });
            });

            // Log any error that arose.
            request.addEventListener("error", function onDataLoadingFailed(): void {
                error(request.error, "Impossible de charger les données du cache");
            });
        }).catch(e => error(
            e,
            "Une erreur est survenue pendant le chargement du cache",
            `Impossible de charger la clé ${key}`
        ));
    }, [error, key]);

    // Update the data stored in the cache when the request is complete.
    useEffect(function updateCachedData(): void {
        // Wait for the data to be defined.
        if (!data.isSuccess || typeof key === "undefined") {
            return;
        }

        // Update the data in the database.
        getDatabase().then(function onDatabaseOpen(database: IDBDatabase): void {
            // If the request has no data, ignore.
            if (!("data" in data)) {
                return;
            }

            // Store the data in the database.
            const request = database
                .transaction("cache", "readwrite")
                .objectStore("cache")
                .put({ key, data: data.data });

            // Log any error that arose.
            request.addEventListener("error", function onDataStorageFailed(): void {
                error(request.error, "Impossible stocker les données en cache");
            });
        }).catch(e => error(
            e,
            "Une erreur est survenue pendant le stockage en cache",
            `Impossible de mettre à jour la clé ${key}`
        ));
    }, [data, error, key]);

    // Return the cached data.
    if (isLoadingCache) {
        return undefined;
    }
    return cachedData;
}
