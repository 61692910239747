/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";

// Import the widgets.
import FavouriteWidget from "./widgets/favourites";
import TrainingWidget from "./widgets/training";

// Import the css.
import css from "./widget-area.module.scss";


/** Component used to render the widget area of the home page. */
export default function WidgetArea(): ReactElement {
    // Render all the elements.
    return <div className={css["container"]}>
        <div className={css["personalise"]} />
        <ul className={css["list"]}>
            <FavouriteWidget />
            <TrainingWidget mode="assigned" />
            <TrainingWidget mode="done" />
            <TrainingWidget mode="by-training" />
            <TrainingWidget mode="by-user" />
        </ul>
    </div>;
}
