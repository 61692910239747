/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext, useMemo } from "react";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the store.
import { useTags, useZaqWikis } from "@andromeda/store";
// Import the login module.
import { LoginContext } from "@andromeda/login";

// Import the list component.
import ItemCategorisedList, { Item, ItemCategory } from "./item-categorised-list";

// Import the css.
import css from "./zaq-tuto.module.scss";


/** Component used to add a new ZaqWiki to the current step. */
export default function AddZaqWiki(props: AddZaqWikiProps): ReactElement {
    // Download all the ZaqWiki from the current organisation.
    const organisation = useContext(LoginContext).organisations.current.id;
    const wiki = useZaqWikis(organisation);
    const tags = useTags(organisation);

    // Build the category list from the zaq.
    const categories = useMemo(function buildCategoryList(): ItemCategory[] {
        // Wait for the wikis to load.
        if (!wiki.isSuccess || !tags.isSuccess) {
            return [];
        }

        // Reduce the category list into a map.
        const map = wiki.data.reduce(function reduceToMap(map, wiki) {
            const item: Item = { type: "zaq-wiki", id: wiki.id, name: wiki.attributes.name ?? "" };

            // Add the wiki to all its tags.
            for (const { id } of wiki.relationships.categories.data) {
                const tag = tags.data.find(tag => tag.id === id);
                if (tag) {
                    const list = map.get(tag.attributes.name);
                    if (!list) {
                        map.set(tag.attributes.name, [item]);
                    } else {
                        list.push(item);
                    }
                }
            }

            // Create the special category-less tag.
            if (wiki.relationships.categories.data.length === 0) {
                const list = map.get(undefined);
                if (!list) {
                    map.set(undefined, [item]);
                } else {
                    list.push(item);
                }
            }

            return map;

        }, new Map<string | undefined, Item[]>());
        return Array.from(map.entries());
    }, [tags.data, tags.isSuccess, wiki.data, wiki.isSuccess]);

    // Render the component.
    if (!wiki.isSuccess || !tags.isSuccess) {
        return <Loader containerClassName={css["loader"]} text="Chargement des ZaqWiki ..." />;
    }
    return <ItemCategorisedList
        className={css["list"]}
        categories={categories}
        selected={props.selected}
        select={props.select}
    />;
}

/** Props passed down to the {@link AddZaqWiki} component. */
export interface AddZaqWikiProps {
    /** Currently selected item. */
    selected: ResourceIdentifier | undefined;

    /** Callback invoked to select a new component. */
    select(item: ResourceIdentifier): void;
}
