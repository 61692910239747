/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import Redux.
import type { Store } from "redux";
// Import the JSON:API module.
import { Resource } from "@andromeda/json-api";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the components.
import { createRequestDispatch, RequestReducerKey, ResourceStoreHelper } from "../components";
// Import the CURD action.
import { CRUDThunkAction } from "../components/resource/generator";


type WithThunkDispatch = Store & {
    dispatch(action: CRUDThunkAction<Promise<Resource>, [Resource, never, never]>): Promise<Resource>;
};

/**
 * Helper used to download a resource from the store.
 *
 * @template {Resource} R
 * @param {Store} store The redux store used for the requests.
 * @param {ResourceStoreHelper<[R, never, never]>} resourceStore The store used to query the resource.
 * @param {R["type"]} type The type of the resource.
 * @param {string | undefined} id The id of the requested resource.
 * @param {boolean} cache If true, tries to read the data from the cache.
 * @param {boolean} [immediate=false] If true, loads immediately.
 * @return {RequestStatus<R>} The status of the request.
 */
export function getResource<R extends Resource>(
    store: Store,
    resourceStore: ResourceStoreHelper<[R, never, never]>,
    type: R["type"],
    id: string,
    cache: boolean,
    immediate?: boolean
): Promise<R>;

/** Implementation ! */
export async function getResource(
    store: Store,
    resourceStore: ResourceStoreHelper<[Resource, never, never]>,
    type: string,
    id: string,
    cache: boolean,
    immediate = false
): Promise<Resource> {
    // Build the request id.
    const requestId = `${type}/${id}`;
    const { dispatch: resourceDispatch } = store as WithThunkDispatch;

    // Get the status of the request.
    const request: RequestStatus = store.getState()[RequestReducerKey].requests[requestId]
        ?? RequestStatus.uninitialised();

    // Get the resource from the store.
    const resource = store.getState()[type].resources.find((resource: Resource) => resource.id === id);

    // If the request was completed, return the resource.
    if (request.isSuccess) {
        if (typeof resource === "undefined") {
            throw new Error("Could not find a resource with the provided identifier", { cause: requestId });
        }
        return resource;
    } else if (request.isError) {
        throw request.error;
    }

    // If the request was not started, download the resource.
    if (request.isUninitialised) {
        createRequestDispatch(store, requestId)((dispatch) => {
            // If the resource is already loaded, return it.
            if (typeof resource !== "undefined" && cache) {
                return dispatch(RequestStatus.success());
            }

            dispatch(RequestStatus.loading());

            // Download the resource.
            resourceDispatch(resourceStore.generator.readOne(id, { immediate }))
                .then(() => RequestStatus.success())
                .catch(RequestStatus.error)
                .then(dispatch);
        });
    }

    // Subscribe to the store.
    return new Promise<Resource>((resolve, reject) => {
        const unsubscribe = store.subscribe(function onChange(): void {
            // Get the state of the request.
            const request = store.getState()[RequestReducerKey].requests[requestId];
            if (request.isSuccess) {
                unsubscribe();

                // Get the resource from the store.
                const resource = store.getState()[type].resources.find((resource: Resource) => resource.id === id);
                if (typeof resource === "undefined") {
                    reject(new Error("Could not find a resource with the provided identifier", { cause: requestId }));
                }
                resolve(resource);
            } else if (request.isError) {
                unsubscribe();
                reject(request.error);
            }
        });
    });
}
