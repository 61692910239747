/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json:api interfaces.
import { Resource, Relationship, AnyLinks, Link } from "@andromeda/json-api";

// Import the encryption source resource.
import { EncryptionSource } from "../..";
// Import the global asset resource.
import { Asset } from ".";

/**
 * Base interface used to represent an asset.
 * Assets are stored on a S3 bucket and can be encrypted.
 */
export interface AssetBase extends Resource<AssetBase.Type> {
    /** @inheritDoc */
    attributes: AssetBase.Attributes;
    /** @inheritDoc */
    relationships: AssetBase.Relationships;
    /** @inheritDoc */
    links?: AssetBase.Links;
}

/** Namespace used to augment the {@link AssetBase} interface.*/
export namespace AssetBase {
    export const Type = "asset" as const;
    export type Type = typeof Type;

    export interface Attributes {
        /** MIME type of the asset. */
        contentType: string;
        /** Size of the asset, in bytes. */
        size: number;
    }

    export interface Relationships {
        /** Encryption source used to encode this asset. */
        encryptionSource: Relationship<EncryptionSource | null>;

        /** Original asset from which this asset is derived. */
        source: Relationship<Asset | null>;
        /** List of alternatives that can be used instead of this asset. */
        alternatives: Relationship<Asset[]>;
    }

    export interface Links extends AnyLinks {
        /**
         * Link that can be used to retrieve the asset.
         * NOTE: If {@link Relationships.encryptionSource} is set, the
         * x-amz-server-side-encryption-customer-key and
         * x-amz-server-side-encryption-customer-key-md5 headers
         * MUST be added to the GET request.
         */
        get?: Link;

        /**
         * Link that can be used to update the asset in the store.
         *
         * NOTE: If {@link Relationships.encryptionSource} is set, the
         * x-amz-server-side-encryption-customer-key and
         * x-amz-server-side-encryption-customer-key-md5 headers
         * MUST be added to the PUT request.
         */
        put?: Link;
    }
}
