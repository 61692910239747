/*
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useRef, useState } from "react";
// Import RxJS.
import { Subject } from "rxjs";
// Import the common tools.
import { RequestStatus, SerialisedError } from "@andromeda/tools";

// Import the subcomponents.
import PDFProvider from "./context/pdf/provider";
import PDFContainerProvider, { PDFContainerProviderProps } from "./context/container";
import PDFActivePageProvider from "./context/active-page";
import FullscreenProvider, { FullscreenProviderProps } from "./context/fullscreen";
import PDFRenderModeProvider, { PDFRenderModeProviderProps } from "./context/mode";
import PDFContinuousContainer from "./component/continuous-container";
import PDFDocument, { PDFDocumentStatus } from "./component/document";
import PDFToolbar from "./component/toolbar";
import PDFOneByOneContainer from "./component/one-by-one-container";


/** Component used to render a PDF file on the screen. */
export default function Pdf(props: PdfProps): ReactElement {
    // Store the state of the document.
    const [document, setDocument] = useState<PDFDocumentStatus>(RequestStatus.uninitialised);

    /** Helper used to wrap {@link setDocument} and {@link props.onLoadSuccess}/{@link props.onLoadError} */
    function onLoadUpdate(update: PDFDocumentStatus): void {
        if (update.isSuccess) {
            props.onLoadSuccess?.();
        } else if (update.isError) {
            props.onLoadError?.(update.error);
        }
        setDocument(update);
    }

    // Store a reference to the container component.
    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    // Subject used to transmit page changes to the active context.
    const subject = useRef(new Subject<number>());

    // Render the document.
    return <PDFDocument
        source={props.src}
        onLoadUpdate={onLoadUpdate}
        className={props.className}
        ref={setContainer}
        onItemClick={subject.current.next.bind(subject.current)}
    >
        <FullscreenProvider
            isFullscreen={props.isFullscreen}
            onRequestFullscreen={props.onRequestFullscreen}
            onExitFullscreen={props.onExitFullscreen}
        >
            <PDFProvider document={document}>
                <PDFContainerProvider container={container} fit={props.fit}>
                    <PDFActivePageProvider $pageChange={subject.current}>
                        <PDFRenderModeProvider defaultMode={props.defaultMode} fixedMode={props.fixedMode}>
                            <PDFContinuousContainer />
                            <PDFOneByOneContainer />
                            <PDFToolbar className={props.toolbarClassName} />
                        </PDFRenderModeProvider>
                    </PDFActivePageProvider>
                </PDFContainerProvider>
            </PDFProvider>
        </FullscreenProvider>
    </PDFDocument>;
}

/** Props passed down to the {@link Pdf} component. */
export interface PdfProps extends Pick<PDFContainerProviderProps, "fit">,
    Omit<FullscreenProviderProps, "children">,
    Omit<PDFRenderModeProviderProps, "children"> {
    /** URL at which the pdf file can be loaded. */
    src: string;

    /** Class name appended to the document element. */
    className?: string;
    /** Class name appended to the controller. */
    toolbarClassName?: string;

    /** Callback invoked when the pdf fails to load. */
    onLoadError?(error: SerialisedError): void;

    /** Callback invoked when the pdf was fully loaded. */
    onLoadSuccess?(): void;
}
