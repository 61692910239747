/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useRef, useState } from "react";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the icons.
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faPerson } from "@fortawesome/free-solid-svg-icons/faPerson";
import { faPersonDress } from "@fortawesome/free-solid-svg-icons/faPersonDress";
import { faPersonSwimming } from "@fortawesome/free-solid-svg-icons/faPersonSwimming";
import { faPersonSnowboarding } from "@fortawesome/free-solid-svg-icons/faPersonSnowboarding";
import { faPersonPregnant } from "@fortawesome/free-solid-svg-icons/faPersonPregnant";
import { faPersonSkating } from "@fortawesome/free-solid-svg-icons/faPersonSkating";
import { faPersonSkiing } from "@fortawesome/free-solid-svg-icons/faPersonSkiing";
import { faPersonHiking } from "@fortawesome/free-solid-svg-icons/faPersonHiking";
import { faPersonFalling } from "@fortawesome/free-solid-svg-icons/faPersonFalling";
import { faPersonDrowning } from "@fortawesome/free-solid-svg-icons/faPersonDrowning";
import { faPersonDigging } from "@fortawesome/free-solid-svg-icons/faPersonDigging";
import { faPersonCane } from "@fortawesome/free-solid-svg-icons/faPersonCane";
import { faPersonBiking } from "@fortawesome/free-solid-svg-icons/faPersonBiking";
import { faHotTubPerson } from "@fortawesome/free-solid-svg-icons/faHotTubPerson";
import { faPersonWalkingLuggage } from "@fortawesome/free-solid-svg-icons/faPersonWalkingLuggage";


/**
 * Component used to render the icon of the person next to the username.
 * Includes a small easter-egg where the icon can be changed by triple-clicking it.
 */
export default function PersonIcon(props: PersonIconProps): ReactElement {
    // Stores the currently selected icon.
    const [currentIcon, setCurrentIcon] = useState<IconKey>(getStoredIcon);

    // Handle clicks on the icon to change to a random icon every time it is triple-pressed.
    const timeout = useRef<ReturnType<typeof setTimeout>>();
    const counter = useRef(0);
    const switchOnTripleClick = useCallback(function switchIconOnTripleClick(): void {
        // Increment the switch counter.
        counter.current++;

        // If the counter reaches 3, change the icon.
        if (counter.current >= 3) {
            counter.current = 0;
            setCurrentIcon(getNextIcon);
        }

        // Start a timeout to reset the counter after 2 seconds of inactivitiy.
        if (timeout.current) {
            clearTimeout(timeout.current);
            delete timeout.current;
        }
        timeout.current = setTimeout(function clearClickCounter(): void {
            counter.current = 0;
        }, 2000);
    }, [])

    // Render the icon.
    return <FontAwesomeIcon className={props.className} icon={IconMap[currentIcon]} onClick={switchOnTripleClick} />;
}

/** Props passed down to the {@link PersonIcon} component. */
export interface PersonIconProps {
    /** Class name added to the icon element. */
    className?: string;
}

/** Map of all the icons. */
const IconMap = {
    faUser,
    faPerson,
    faPersonDress,
    faPersonSwimming,
    faPersonSnowboarding,
    faPersonPregnant,
    faPersonSkating,
    faPersonSkiing,
    faPersonHiking,
    faPersonFalling,
    faPersonDrowning,
    faPersonDigging,
    faPersonCane,
    faPersonBiking,
    faHotTubPerson,
    faPersonWalkingLuggage
} as const;

/** Type alias for the keys of {@link IconMap}. */
type IconKey = keyof typeof IconMap;

/**
 * Returns the next icon from the map.
 *
 * @returns {IconKey} The next key from the {@link IconMap}.
 */
function getNextIcon(icon: IconKey): IconKey {
    // Get the index of the current key.
    const keys = Object.keys(IconMap) as IconKey[];
    const iconIndex =  keys.indexOf(icon);

    // Get the next icon in the list.
    const next = keys[(iconIndex + 1) % keys.length];

    // Store the icon in local storage.
    try {
        window.localStorage.setItem("andromeda/navbar/person-icon", next);
    } catch(e: unknown) {
        console.warn("Failed to store the icon to local storage: %O", e);
    }

    return next;
}

/**
 * Retrieves the icon that was stored in local storage by {@link getNextIcon}.
 *
 * @returns {IconKey} The icon that was stored in local storage.
 */
function getStoredIcon(): IconKey {
    // Try to read the icon from local storage.
    const icon = window.localStorage.getItem("andromeda/navbar/person-icon");
    if (icon === null) {
        return "faUser";
    }

    // Check if the icon is valid.
    if (!Object.keys(IconMap).includes(icon)) {
        return "faUser";
    }
    return icon as IconKey;
}
