/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useContext, useMemo } from "react";
// Import the login context.
import { LoginContext, useIsAdmin } from "@andromeda/login";
// Import the legacy ZaqTuto tools.
import { useLegacyZaq } from "@andromeda/legacy-zaq";


/**
 * Hook used to check if the current user can create a new ZaqTuto.
 *
 * @returns {boolean} True if the user is allowed to create new ZaqTuto.
 */
export function useCanCreateZaqTuto(): boolean {
    const auth = useContext(LoginContext);
    const isAdmin = useIsAdmin();
    return useMemo(function checkIfCanCreateTuto(): boolean {
        // If the user is an administrator, they can do anything they want regardless.
        if (isAdmin) {
            return true;
        }

        // Check if the user is an editor.
        return auth.organisations.current.relationships.editors.data.some(editor => editor.id === auth.self.id);
    }, [auth.organisations, auth.self.id, isAdmin]);
}

/**
 * Hook used to check if the current user can edit the given ZaqTuto.
 *
 * @returns {boolean} True if the user is allowed to edit the given ZaqTuto.
 */
export function useCanEditZaqTuto(tuto: string): boolean {
    const item = useLegacyZaq(tuto, false);
    const auth = useContext(LoginContext);
    const isAdmin = useIsAdmin();
    return useMemo(function checkIfCanEditTuto(): boolean {
        // If the user is an administrator, they can do anything they want regardless.
        if (isAdmin) {
            return true;
        }

        // If the user is the owner of the given ZaqTuto.
        if (item.data?.owner === auth.self.id) {
            return true;
        }

        // Check if the user is an editor of the ZaqTuto.
        return !!item.data?.editors.some(editor => editor === auth.self.id);
    }, [auth.self.id, isAdmin, item.data?.editors, item.data?.owner]);
}

/**
 * Hook used to check if the current user can delete the given ZaqTuto.
 *
 * @returns {boolean} True if the user is allowed to delete the given ZaqTuto.
 */
export function useCanDeleteZaqTuto(tuto: string): boolean {
    const item = useLegacyZaq(tuto, false);
    const auth = useContext(LoginContext);
    const isAdmin = useIsAdmin();
    return useMemo(function checkIfCanEditTuto(): boolean {
        // If the user is an administrator, they can do anything they want regardless.
        if (isAdmin) {
            return true;
        }

        // If the user is the owner of the given ZaqTuto.
        if (item.data?.owner === auth.self.id) {
            return true;
        }

        // Check if the user is an editor of the ZaqTuto.
        return !!item.data?.editors.some(editor => editor === auth.self.id);
    }, [auth.self.id, isAdmin, item.data?.editors, item.data?.owner]);
}

/**
 * Hook used to check if the current user can create a new {@link ZaqWiki}.
 *
 * @returns {boolean} True if the user is allowed to create new {@link ZaqWiki}.
 * Always {@code true} for now.
 */
export function useCanCreateZaqWiki(): boolean {
    return true;
}

/**
 * Hook used to check if the current user can edit the given {@link ZaqWiki}.
 *
 * @returns {boolean} True if the user is allowed to delete the given {@link ZaqWiki}.
 * Always {@code true} for now.
 */
export function useCanEditZaqWiki(): boolean {
    return true;
}

/**
 * Hook used to check if the current user can delete agiven {@link ZaqWiki}.
 *
 * @returns {boolean} True if the user is allowed to delete the given {@link ZaqWiki}.
 * Always {@code true} for now.
 */
export function useCanDeleteZaqWiki(): boolean {
    return useIsAdmin();
}

/**
 * Hook used to check if the current user can create a new {@link ZaqTraining}.
 *
 * @returns {boolean} True if the user is allowed to create a new {@link ZaqTraining}.
 */
export function useCanCreateZaqTraining(): boolean {
    return useIsAdmin();
}

/**
 * Hook used to check if the current user can edit the given {@link ZaqTraining}.
 *
 * @returns {boolean} True if the user is allowed to edit the given {@link ZaqTraining}.
 */
export function useCanEditZaqTraining(): boolean {
    return useIsAdmin();
}

/**
 * Hook used to check if the current user can delete a {@link ZaqTraining}.
 *
 * @returns {boolean} True if the user is allowed to delete a {@link ZaqTraining}.
 */
export function useCanDeleteZaqTraining(): boolean {
    return useIsAdmin();
}
