/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the modal component.
import { Modal } from "react-bootstrap";

// Import the context interfaces.
import { ConfirmBodyProps, ConfirmHeaderProps, ConfirmContext, ShowConfirmOptions } from "./context";

// Import the close icon.
import close from "@andromeda/assets/images/close.svg";
// Import the css.
import css from "./default-header.module.scss";


/**
 * Hook used to generate a new confirmation callback.
 * A confirmation callback shows a modal on the screen
 * and returns a promise that resolves once the modal is closed.
 *
 * @template {string} T
 * @param {ConfirmHookOptions<T>} options The options of this callback.
 * @returns {() => Promise<T>} A callback that can be invoked to ask confirmation to the user.
 */
export function useConfirm<T>(
    options: ConfirmHookOptions<T>
): () => Promise<T>;

/**
 * Hook used to generate a new confirmation callback.
 * A confirmation callback shows a modal on the screen
 * and returns a promise that resolves once the modal is closed.
 *
 * @template {string} T
 * @template {object} BodyProps
 * @param {ConfirmHookOptions<T, BodyProps>} options The options of this callback.
 * @returns {() => Promise<T>} A callback that can be invoked to ask confirmation to the user.
 */
export function useConfirm<BodyProps extends ConfirmBodyProps<T>, T>(
    options: ConfirmHookOptions<Parameters<BodyProps["resolve"]>[0], BodyProps>
): (
    props: Omit<BodyProps, keyof ConfirmBodyProps<Parameters<BodyProps["resolve"]>[0]>>
) => Promise<Parameters<BodyProps["resolve"]>[0]>;

/**
 * Hook used to generate a new confirmation callback.
 * A confirmation callback shows a modal on the screen
 * and returns a promise that resolves once the modal is closed.
 *
 * @template {string} T
 * @template {object} BodyProps
 * @template {object} HeaderProps
 * @param {ConfirmHookOptions<T, BodyProps, HeaderProps>} options The options of this callback.
 * @returns {() => Promise<T>} A callback that can be invoked to ask confirmation to the user.
 */
export function useConfirm<BodyProps extends ConfirmBodyProps<T>, HeaderProps extends ConfirmHeaderProps<T>, T>(
    options: ConfirmHookOptions<Parameters<BodyProps["resolve"]>[0], BodyProps, HeaderProps>
): (
    bodyProps: Omit<BodyProps, keyof ConfirmBodyProps<Parameters<BodyProps["resolve"]>[0]>>,
    headerProps: Omit<HeaderProps, keyof ConfirmHeaderProps<Parameters<BodyProps["resolve"]>[0]>>
) => Promise<Parameters<BodyProps["resolve"]>[0]>;

/** Implementation of the confirmation hook. */
export function useConfirm<T, BodyProps extends object, HeaderProps extends object>(
    options: ConfirmHookOptions<T, BodyProps, HeaderProps>
): (
    bodyProps?: Omit<BodyProps, keyof ConfirmBodyProps<T>>,
    headerProps?: Omit<HeaderProps, keyof ConfirmHeaderProps<T>>,
) => Promise<T> {
    // Get the confirmation modal context.
    const context = React.useContext(ConfirmContext);

    // Prepare the header and body renderers.
    const Header = React.useCallback(function headerRenderer(props: ConfirmHeaderProps<T> & HeaderProps): React.ReactElement {
        let Header: React.ComponentType<ConfirmHeaderProps<T> & HeaderProps>;
        if ("header" in options) {
            Header = options.header;
        } else {
            Header = props => <DefaultHeader {...props} title={options.title} cancel={options.cancel} />;
        }

        return <Header {...props} cancel={options.cancel} />;
    }, [options]);
    const Body = React.useCallback(function bodyRenderer(props: ConfirmBodyProps<T> & BodyProps): React.ReactElement {
        const Body = options.body
        return <Body {...props} />;
    }, [options.body]);

    // Return the method used to push the elements to the confirm queue.
    return React.useCallback(function pushToConfirmQueue(
        bodyProps?: Omit<BodyProps, keyof ConfirmBodyProps<T>>,
        headerProps?: Omit<HeaderProps, keyof ConfirmHeaderProps<T>>
    ): Promise<T> {
        return context.show({
            ...options,
            body: innerProps => <Body {...bodyProps as BodyProps} {...innerProps} />,
            header: innerProps => <Header {...headerProps as HeaderProps} {...innerProps} />
        });
    }, [Body, Header, context, options]);
}

/** Base interface for the confirmation hook options. */
interface ConfirmHookOptionsBase<
    T,
    BodyProps extends object
> extends Omit<ShowConfirmOptions<T>, "body" | "header"> {
    /** Component used to render the body of the modal. */
    body: React.ComponentType<ConfirmBodyProps<T> & BodyProps>;
}

/** Options of the confirmation hook used to render the default header. */
interface ConfirmHookOptionsWithDefaultHeader<
    T,
    BodyProps extends object
> extends ConfirmHookOptionsBase<T, BodyProps> {
    /** Title of the rendered modal. */
    title: string;
    /** Value returned if the modal is canceled. */
    cancel?: T;
}

/** Options of the confirmation hook used to render a custom header. */
interface ConfirmHookOptionsWithHeader<
    T,
    BodyProps extends object,
    HeaderProps extends object
> extends ConfirmHookOptionsBase<T, BodyProps> {
    /** Component used to render the header of the modal. */
    header: React.ComponentType<ConfirmHeaderProps<T> & HeaderProps>;
}

/** Union of the confirmation hook options. */
type ConfirmHookOptions<
    T,
    BodyProps extends object = Record<string, never>,
    HeaderProps extends object = Record<string,never>
> = ConfirmHookOptionsWithDefaultHeader<T, BodyProps> | ConfirmHookOptionsWithHeader<T, BodyProps, HeaderProps>;


/** Default header shown when no custom value was provided.  */
function DefaultHeader<T>(props: DefaultHeaderProps<T>): React.ReactElement {
    // Render the cancel button.
    const cancel = React.useMemo(function renderCancelButton(): React.ReactElement | null {
        if (!("cancel" in props)) return null;
        const value = props.cancel;

        // Generate the click resolver.
        const onClick = typeof value === "undefined" ?
            () => (props.resolve as () => void)() :
            () => (props.resolve as (value: T) => void)(value);

        return <button  className={css["default-header__close"]} onClick={onClick}>
            <img src={close} alt="cancel" />
        </button>;
    }, [props]);

    return <Modal.Header className={css["default-header"]}>
        <h1 className={css["default-header__title"]}>{props.title}</h1>
        {cancel}
    </Modal.Header>;
}

/** Props passed down to the {@link DefaultHeader} component. */
interface DefaultHeaderProps<T> extends ConfirmHeaderProps<T>{
    /** Value used for the default cancel button. */
    cancel?: T;

    /** Title of the modal. */
    title: string;
}
