/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API module.
import type { Filter } from "@andromeda/json-api";
// Import the requirement resource.
import { Step, Validation } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the step hook.
import { useSteps } from "./step";

// Import the store.
import {
    ValidationStore,
    ResourceStore,
    useRequestSelector,
    useRequestDispatch,
    useResourceDispatch
} from "../../stores";
// Import the resource hooks.
import useResources from "./util/use-resources";
import compareLists from "./util/compare-lists";
import { useEffect } from "react";


/**
 * Downloads all the validations for the given user.
 *
 * @param {string} user The user to load the validations for.
 * @return {RequestStatus<Validation[]>} The status of the request.
 */
export function useValidations(user: string): RequestStatus<Validation[]> {
    // Prepare the filter used for the request.
    const filter: Filter = { user: { id: { $eq: user } } };

    // Prepare the selector used to load the validations.
    const selector = function selector(state: ResourceStore<Validation>): Validation[] {
        return state.resources.filter(function requirementBelongsToOrg(validation: Validation): boolean {
            return validation.relationships.user.data.id === user;
        });
    };

    // Return the resources.
    return useResources(ValidationStore, Validation.Type, { filter }, selector);
}

/**
 * Downloads all the validations for the given user and course.
 *
 * @param {string} course The course to load the validations for.
 * @param {string} user The user to load the validations for.
 * @return {RequestStatus<Validation[]>} The status of the request.
 */
export function useCourseValidations(course: string, user?: string | string[]): RequestStatus<Validation[]> {
    // Load all the steps from the course.
    const steps = useSteps(course);
    let users: string[] | undefined = undefined;
    if (Array.isArray(user)) {
        users = user;
    } else if (typeof user === "string") {
        users = [user];
    }

    // Prepare the selector used to load the validations.
    const selector = function selector(state: ResourceStore<Validation>): Validation[] {
        return state.resources.filter(function(validation: Validation): boolean {
            if (typeof users === "undefined") {
                return false;
            }

            if (!users.includes(validation.relationships.user.data.id)) {
                return false;
            }

            // Check if the validation is for an item of the course.
            if (!steps.isSuccess) {
                return false;
            }
            return steps.data.some(function stepIncludesValidation(step: Step): boolean {
                return step.relationships.items.data.some(function itemIncludesValidation(item: Step.Item): boolean {
                    // Ignore non-validatable types.
                    if (item.type !== "zaq" && item.type !== "external") {
                        return false;
                    }

                    // Check if the id is the same.
                    if (item.type === "external" && "external" in validation.relationships) {
                        return validation.relationships.external.data.id === item.id;
                    } else if (item.type === "zaq" && "zaq" in validation.relationships) {
                        return validation.relationships.zaq.data.id === item.id;
                    }
                    return false;
                });
            });
        });
    };

    // Get the status of the request.
    const requestId = `${Validation.Type}/${window.btoa(JSON.stringify([course, users]))}`;
    const request = useRequestSelector(requestId);
    const dispatchRequest = useRequestDispatch(requestId);

    // Load the resource from the store.
    const resources = ValidationStore.useSelector(selector, compareLists);
    const dispatchResource = useResourceDispatch();

    // Download the validations from the store.
    useEffect(function downloadResource(): void {
        // Wait for the steps to be loaded.
        if (!steps.isSuccess) {
            return;
        }

        // Run the request.
        dispatchRequest((dispatch, getState) => {
            if (!getState().isUninitialised || typeof users === "undefined") {
                return;
            }

            dispatch(RequestStatus.loading());
            // Loop through all the steps.
            const promises: Promise<unknown>[] = [];
            for (const user of users) {
                for (const step of steps.data) {
                    for (const item of step.relationships.items.data) {
                        // Check if the item is validatable.
                        if (item.type !== "zaq" && item.type !== "external") {
                            continue;
                        }

                        // Read the latest validation.
                        const filter: Filter = {
                            validation: {
                                user: { $eq: user },
                                [item.type]: { $eq: item.id }
                            }
                        };
                        promises.push(
                            dispatchResource(
                                ValidationStore.generator.readMany({
                                    filter,
                                    limit: 1,
                                    sort: ["-date"]
                                })
                            )
                        );
                    }
                }
            }

            // Wait for all the promises to resolve.
            Promise.all(promises)
                .then(() => RequestStatus.success())
                .catch(RequestStatus.error)
                .then(dispatch);
        });
    }, [steps.isSuccess, dispatchRequest, steps.data, dispatchResource ]);

    // Return the state of the request.
    if (request.isSuccess) {
        return RequestStatus.success(resources);
    }
    return request;
}
