/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the asset interface.
import { ConversionAsset } from "./asset";
// Import the list of all the message sizes.
import { Sizes } from "./sizes";


/** Error thrown by {@link encodeAssetMessage} if too many items were encoded. */
export class TooManyAssetsError extends Error {
    constructor() {
        super(
            "Cannot combine more than " +
            Math.pow(2, Sizes["MessageHeader-ObjectCount"]) +
            " items in an asset message."
        );
    }
}

/**
 * Encodes the given assets into a single message buffer.
 *
 * @param {ConversionAsset[]} assets The list of all the assets to encode in this message.
 * TODO: @param {CompressionKind} [compression=CompressionKind.none] The compression applied to the message payload.
 * NOTE: Payload compression is not supported yet.
 * @return {ArrayBuffer} The encoded asset message.
 */
export function encodeAssetMessage(assets: ConversionAsset[]): ArrayBuffer {
    if (assets.length >= 65535) throw new TooManyAssetsError();

    // Stringify the resources.
    const encodedAssets = assets.map(asset => asset.serialize());

    // Prepare the data buffer.
    const headerSize = Sizes["MessageHeaderBytes"] + (assets.length * Sizes["ObjectHeaderBytes"]);
    const totalPayloadLength = encodedAssets.reduce(
        function reduceAssetMessageSize(length: number, current: ConversionAsset.EncodedAsset): number {
            return length + current.metadata.byteLength + current.data.byteLength;
        }, 0
    );
    const buffer = new Uint8Array(headerSize + totalPayloadLength);
    const view = new DataView(buffer.buffer);

    // Write the metadata section.
    // TODO: Add compression support.
    view.setUint8(0, 0x0);
    view.setUint16(2, assets.length, false);

    // Write the header section.
    for (let i = 0; i < assets.length; i++) {
        let pointer = Sizes["MessageHeaderBytes"] + (i * Sizes["ObjectHeaderBytes"]);
        view.setUint32(pointer, encodedAssets[i].metadata.byteLength, false);
        pointer += 4; // Uint32
        view.setUint32(pointer, encodedAssets[i].data.byteLength, false);
        pointer += 4; // Uint32
        // TODO: Add compression support.
        view.setUint8(pointer, 0x0);
    }

    // Write the payload section.
    let pointer = headerSize;
    for (const asset of encodedAssets) {
        buffer.set(new Uint8Array(asset.metadata), pointer);
        pointer += asset.metadata.byteLength;
        buffer.set(new Uint8Array(asset.data), pointer);
        pointer += asset.data.byteLength;
    }

    return buffer.buffer;
}
