/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useEffect, useMemo } from "react";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the JSON:API module.
import type { Resource } from "@andromeda/json-api";
// Import the resource helper module.
import type { ReadOneOptions } from "@andromeda/resource-helper";

// Import the dispatch.
import { useResourceDispatch } from '../../../stores';
// Import the components.
import { ResourceStoreHelper, useRequestDispatch, useRequestSelector } from "../../../components";


/**
 * Hook used to download a resource from the store.
 *
 * @template {Resource} R
 * @param {ResourceStoreHelper<[R, never, never]>} store The store used to query the resource.
 * @param {R["type"]} type The type of the resource.
 * @param {string | undefined} id The id of the requested resource.
 * @param {boolean} cache If true, tries to read the data from the cache.
 * @param {boolean} [immediate=false] If true, loads immediately.
 * @param {ReadOneOptions} [options=undefined] Additional options passed in the request.
 * @return {RequestStatus<R>} The status of the request.
 */
export default function useResource<R extends Resource>(
    store: ResourceStoreHelper<[R, never, never]>,
    type: R["type"],
    id: string | undefined,
    cache: boolean,
    immediate?: boolean,
    options?: ReadOneOptions
): RequestStatus<R>;

/** Implementation ! */
export default function useResource(
    store: ResourceStoreHelper<[Resource, never, never]>,
    type: string,
    id: string | undefined,
    cache: boolean,
    immediate = false,
    options?: ReadOneOptions
): RequestStatus<Resource> {
    // Get the status of the request.
    const requestId = `${type}/${id}/${window.btoa(JSON.stringify(options))}`;
    const request = useRequestSelector(requestId);
    const dispatchRequest = useRequestDispatch(requestId);

    // Load the resource from the store.
    const resource = store.useSelector(state => state.resources.find(resource => resource.id === id));
    const dispatchResource = useResourceDispatch();

    // Download the request from the store.
    useEffect(function downloadResource(): void {
        // Do nothing until an id is provided.
        if (typeof id === "undefined") {
            return;
        }

        // Run the request.
        dispatchRequest((dispatch, getState) => {
            // Ensure that the request is not already running.
            if (!getState().isUninitialised) {
                return;
            }
            // If the resource is already loaded, return it immediately.
            if (resource && cache) {
                return dispatch(RequestStatus.success(resource));
            }

            // Load the resource from the store.
            dispatch(RequestStatus.loading());
            dispatchResource(store.generator.readOne(id, { ...options, immediate }))
                .then(() => RequestStatus.success())
                .catch(RequestStatus.error)
                .then(dispatch);
        });
    }, [dispatchRequest, dispatchResource, id, resource, cache, request.isUninitialised, store.generator]);

    // Return the state of the request.
    return useMemo(() => {
        if (request.isSuccess) {
            if (typeof resource === "undefined") {
                return RequestStatus.loading();
            }
            return RequestStatus.success(resource);
        }
        return request;
    }, [request, resource]);
}
