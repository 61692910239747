/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { MouseEvent, createContext } from "react";
// Import RxJS.
import { Observable } from "rxjs";


/** Interface used to describe the action menu context. */
export interface ActionMenuContext {
    /** Close the action menu. */
    close(): void;

    /** Allows the user to attach to the open event. */
    onOpenClick: Observable<MouseEvent>;
}

/** Context for the action menu. */
export const ActionMenuContext = createContext<ActionMenuContext>({
    close: () => { return; },
    onOpenClick: new Observable<MouseEvent>(),
});
