/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the bootstrap form.
import { Form } from "react-bootstrap";

// Import the creation context.
import { UserCreatorContext } from "../context";
// Import the mail validation hook.
import { useValidateEmail } from "../hooks";

// Import the icons.
import bin from "@andromeda/assets/images/bin.svg";
// Import the css.
import css from "./user.module.scss";


/** Component used to render a user from the staged changes. */
export function User(props: UserProps): React.ReactElement {
    const { changeFocusedIndex } = props;

    // Load the user from the context.
    const context = React.useContext(UserCreatorContext);
    const user = React.useMemo(() => context.staged[props.index], [context.staged, props.index]);
    const validate = useValidateEmail(props.index);
    const updateEmail = React.useCallback(function updateUserEmail(ev: React.ChangeEvent<HTMLInputElement>): void {
        validate(ev.currentTarget);
        // Split the value printed in the input.
        const emails = ev.currentTarget.value.split(/[\r\n,;]/);
        context.updateStaged(props.index, { email: emails[0] });
        for (const newEmail of emails.slice(1)) { context.pushStaged({ email: newEmail }); }
        if (emails.length > 1) {
            changeFocusedIndex(context.staged.length + emails.length - 3);
        }
    }, [validate, context, props.index, changeFocusedIndex]);
    const deleteUser = React.useCallback(function deleteUser(): void {
        context.removeStaged(props.index);
        changeFocusedIndex(props.index - 1);
    }, [context, props.index, changeFocusedIndex]);

    // Allow creation with return key and deletion with backspace.
    const onKeyDown = React.useCallback(function handleKeyEvent(ev: React.KeyboardEvent<HTMLInputElement>): void {
        if (ev.key === "Enter") {
            ev.preventDefault();
            context.pushStaged({ email: "" });
            changeFocusedIndex(context.staged.length - 1);
        } else if (ev.key === "Backspace" && ev.currentTarget.value.length <= 0) {
            ev.preventDefault();
            context.removeStaged(props.index);
            changeFocusedIndex(props.index - 1);
        }
    }, [changeFocusedIndex, context, props.index]);

    // Refresh the validation when the component is rendered.
    const [ control, setControl ] = React.useState<HTMLInputElement | null>(null);
    React.useEffect(function refreshValidation(): void {
        if (control) validate(control)
    }, [control, validate]);


    // Render the component.
    return <li className={css["user"]}>
        <button onClick={deleteUser}>
            <img src={bin} alt="delete" />
        </button>
        <Form.Label className={css["user__label"]}>
            <p>email:</p>
            <span className={css["user__feedback"]}>
                <Form.Control
                    type="email"
                    required
                    autoComplete="off"
                    value={user.email}
                    onChange={updateEmail}
                    onKeyDown={onKeyDown}
                    ref={setControl}
                />
                <Form.Control.Feedback type="invalid">Vous devez spécifier une addresse mail !</Form.Control.Feedback>
            </span>
        </Form.Label>
    </li>;
}

/** Props passed to the {@link User} component. */
interface UserProps {
    /** Index of the user in the staged list. */
    index: number;
    /** Helper used to change the focus in the parent. */
    changeFocusedIndex(index: number): void;
}
