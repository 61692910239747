/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext, useMemo } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the resources.
import type { ShareLink } from "@andromeda/resources";
// Import the login context.
import { LoginContext } from "@andromeda/login";

// Import the subcomponents.
import LinkCopyButton from "./copy";
import LinkQRButton from "./qr";
import LinkDeleteButton from "./delete";

// Import the css.
import css from "./index.module.scss";


/** Component used to render the vertical row of buttons for a link. */
export default function LinkButtons(props: LinkButtonsProps): ReactElement {
    // Get the current organisation's subdomain.
    const subdomain = useContext(LoginContext).organisations.current.attributes.subdomain;

    // Build the link of the current link.
    const link = useMemo(function createLinkURL(): string {
        return `https://${subdomain}.zaqtiv.com/${props.link.id}`;
    }, [props.link.id, subdomain]);

    // Render the list.
    return <div className={classNames(css["buttons"], props.className)}>
        <LinkCopyButton id={props.id} link={link} />
        <LinkQRButton link={link} name={props.link.attributes.name} />
        <LinkDeleteButton id={props.link.id} />
    </div>
}

/** Props passed down to the link {@link LinkButtons}. */
export interface LinkButtonsProps {
    /** The id of the current ZaqWiki. */
    id: string;
    /** Share link that is being rendered. */
    link: ShareLink;
    /** An optional class name added to the <div> element. */
    className?: string;
}
