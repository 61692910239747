/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { createContext, ReactElement, useCallback, useContext, useMemo } from "react";
// Import the login module.
import { LoginContext } from "@andromeda/login";
// Import the resources.
import { Requirement, User } from "@andromeda/resources";
// Import the store.
import { useRequirements, useUsers } from "@andromeda/store";
// Import the storybook components.
import {
    SearchBar,
    SearchBarCategory,
    SearchBarContextProvider,
    SearchBarContext,
    SearchBarItemRendererProps
} from "@andromeda/storybook";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./user-search-bar.module.scss";


/** Component used to render the item search bar. */
export default function UserSearchBar(props: ItemSearchBarProps): ReactElement {
    // Load all the users.
    const users = useUsers(useContext(LoginContext).organisations.current.id);
    const requirements = useRequirements(props.course, users.data?.map(({ id }) => id));

    // Callback used to render the user.
    const itemComponent = useCallback(function itemComponent({ item }: SearchBarItemRendererProps<User>): ReactElement {
        return <span
            role="button"
            className={css["item"]}
            onClick={() => props.select(item.data)}
            children={item.data.attributes.givenName}
        />;
    }, [props]);

    // Filter-out all the users that already have a requirement for the current step.
    const usersWithoutRequirement = useMemo(function filterUsersWithoutRequirements(): User[] {
        if (!users.isSuccess || !requirements.isSuccess) {
            return [];
        }

        return users.data.filter(function shouldIncludeUser(user: User): boolean {
            return !requirements.data.some(function isRequirementForCurrentItem(requirement: Requirement): boolean {
                if (requirement.relationships.step.data.id !== props.step) {
                    return false;
                }
                if (requirement.relationships.user.data.id !== user.id) {
                    return false;
                }
                return requirement.attributes.requiredLevel === props.level;
            });
        });
    }, [props.level, props.step, requirements.data, requirements.isSuccess, users.data, users.isSuccess]);

    // Build the list of search categories.
    const categories = useMemo(function buildSearchCategories(): SearchBarCategory<User>[] {
        // Wait for everything to be loaded.
        if (!users.isSuccess || !requirements.isSuccess) {
            return [];
        }


        // Render all the categories.
        return [
            {
                header: "Utilistateurs",
                items: usersWithoutRequirement.map(user => ({
                    text: user.attributes.givenName,
                    data: user,
                    key: user.id
                })),
                itemComponent
            }
        ];
    }, [itemComponent, requirements.isSuccess, users.isSuccess, usersWithoutRequirement]);

    // Render the component.
    return <>
        <SearchBarContextProvider categories={categories} context={ItemSearchBarContext}>
            <SearchBar context={ItemSearchBarContext} placeholder="Ajouter un membre de l'équipe ..." />
        </SearchBarContextProvider>
        <button className={css["add-button"]} onClick={() => props.select(...usersWithoutRequirement)}>
            <FontAwesomeIcon className={css["add-button__icon"]} icon={faPlus} />
            Ajouter le reste de l'équipe
        </button>
    </>;
}

/** Props passed down to the {@link UserSearchBar} component. */
export interface ItemSearchBarProps {
    /** Identifier of the rendered course. */
    course: string;
    /** Identifier of the rendered step. */
    step: string;
    /** Identifier of the rendered level. */
    level: Requirement.Level;
    /** Callback used to select one or more users. */
    select(...users: User[]): void;
}

// Context used for the item search bar.
const ItemSearchBarContext = createContext<SearchBarContext<User>>(SearchBarContext.DEFAULT_VALUE);
