/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useCallback, useContext, useDeferredValue, useEffect, useMemo, useRef, useState } from "react";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the store.
import { useCourses, useUsers } from "@andromeda/store";
// Import the login context.
import { LoginContext } from "@andromeda/login";

// Import the progress types.
import type { AnyTrainingProgress } from "./progress";
// Import the progress loader.
import loadTrainingProgress from "./loader";


// Re-export the types.
export * from "./progress";


/**
 * Hook used to download all the training progress of the user currently logged in.
 * Generates randomized data for now.
 *
 * @returns {RequestStatus<RequestStatus<TrainingProgress>[]>} A request that resolves once the training are loaded.
 * It will hold inner requests used to load the progress of the individual {@link ZaqTraining}.
 */
export function useTrainingProgression(training?: string, user?: string): useTrainingProgression.Status {
    // Get the current login context.
    const context = useContext(LoginContext);

    // Download the list of all the available training and users.
    const zaqTraining = useCourses(context.organisations.current.id);
    const users = useUsers(context.organisations.current.id);

    // Defer the value of the training and user parameters.
    const deferredTraining = useDeferredValue(training);
    const deferredUser = useDeferredValue(user);

    // Store all the statuses loaded from the store.
    const [statuses, setStatuses] = useState<RequestStatus<AnyTrainingProgress | null>[]>([]);

    // Context identifier used to avoid inserting data from a previous request into the array.
    const contextId = useRef<string>();

    // Callback used to load the progression of a given item.
    const load = useCallback(function loadProgress(user: string, training: string, index: number): void {
        // Get a copy of the current context id.
        const currentContextId = contextId.current;

        // Insert a loading status.
        insertProgressStatus(RequestStatus.loading());

        // Load the progress.
        loadTrainingProgress(training, user)
            .then(progress => RequestStatus.success(progress))
            .catch(error => RequestStatus.error(error))
            .then(insertProgressStatus);

        // Helper function used to inert the progress status after it was loaded.
        function insertProgressStatus(status: RequestStatus<AnyTrainingProgress | null>): void {
            // If the context id does not match, do nothing.
            if (currentContextId !== contextId.current) {
                return;
            }

            // Insert the item.
            setStatuses(function updateStatusList(prevState: typeof statuses): typeof statuses {
                prevState[index] = status;
                return Array.from(prevState);
            });
        }
    }, []);

    // Loads the progress for the current items.
    useEffect(
        function loadProgressForItems(): void | VoidFunction {
            // Clear the status array.
            setStatuses([]);

            // Reset a new context id.
            contextId.current = window.crypto.randomUUID();

            // Check if a user was specified.
            if (typeof deferredTraining !== "undefined") {
                // Wait for the user data to load.
                if (!users.isSuccess) {
                    return;
                }

                // Load all the user's training progress.
                for (let i = 0; i < users.data.length; i++) {
                    load(users.data[i].id, deferredTraining, i);
                }
            } else {
                // Wait for the training to load.
                if (!zaqTraining.isSuccess) {
                    return;
                }

                // Get the identifier of the user to load.
                const user = deferredUser ?? context.self.id;

                // Load all the user's training progress.
                for (let i = 0; i < zaqTraining.data.length; i++) {
                    load(user, zaqTraining.data[i].id, i);
                }
            }
        },
        [
            context.self.id,
            deferredTraining,
            deferredUser,
            load,
            users.data,
            users.isSuccess,
            zaqTraining.data,
            zaqTraining.isSuccess
        ]
    );

    // Memoize the status list to avoid unnecessary re-renders.
    return useMemo(function waitForAllItemsToResolve(): useTrainingProgression.Status {
        // Wait for the courses to load.
        if (!zaqTraining.isSuccess) {
            return RequestStatus.loading("zaq-training");
        }
        // If a training is provided, wait for the users to load.
        if (typeof deferredTraining !== "undefined" && !users.isSuccess) {
            return RequestStatus.loading("zaq-training");
        }

        // Wait for all the items to resolve.
        if (statuses.some(item => !item.isSuccess)) {
            return RequestStatus.loading("progress");
        }

        // Check if the deferred values are being updated.
        if (user !== deferredUser || training !== deferredTraining) {
            return RequestStatus.loading("progress");
        }

        // Return the list.
        return RequestStatus.success(
            statuses
                .map(item => item.data)
                .filter((item): item is AnyTrainingProgress => item != null)
                .sort(compareProgressPriority)
        );
    }, [deferredTraining, deferredUser, statuses, training, user, users.isSuccess, zaqTraining.isSuccess]);
}

export namespace useTrainingProgression {
    export type Status = RequestStatus<ReadonlyArray<AnyTrainingProgress>, useTrainingProgression.LoadingState>;
    export type LoadingState = "zaq-training" | "progress";
}

/**
 * Compares the priority of two progress elements.
 *
 * @param {RequestStatus<AnyTrainingProgress>} a The first element to compare.
 * @param {RequestStatus<AnyTrainingProgress>} b The element to compare the priority to.
 * @returns {number} A value used to sort the items.
 */
function compareProgressPriority(a: AnyTrainingProgress, b: AnyTrainingProgress): number {
    // Check if the elements are validated.
    if (a.validated) {
        return +1;
    }
    if (b.validated) {
        return -1;
    }

    // Compare the due dates.
    if (a.due !== null && b.due !== null) {
        return a.due.valueOf() - b.due.valueOf();
    }
    if (a.due !== null) {
        return -1;
    }
    if (b.due !== null) {
        return +1;
    }
    return b.progress - a.progress;
}
