/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API interfaces.
import { MessageHelper, WritableMessageHelper } from "@andromeda/json-api";


/**
 * Interface used to describe the headers of a request/response.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export interface XhrHeaders { [header: string]: string | string[]; }

/**
 * Interface used to describe the search parameters of a request.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export interface XhrSearchParameters { [parameter: string]: string | number | boolean }

/** Exhaustive list of all the supported HTTP verbs. */
export type XhrMethod = "GET" | "HEAD" | "POST" | "PUT" | "PATCH" | "DELETE" | "OPTIONS";

/** List of all the supported encoded body types. */
export type XhrEncodedBodyType = string | ArrayBuffer | Buffer;
/** Exhaustive list of all the supported body types. */
export type XhrBodyType = XhrEncodedBodyType | MessageHelper;
/** Exhaustive list of all the supported request body types. */
export type XhrRequestBodyType = XhrEncodedBodyType | WritableMessageHelper;
