/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import {
    ForwardedRef,
    forwardRef,
    ReactElement,
    ReactNode,
    useContext,
} from "react";
// Import the React-Winow tool.
import type { ListChildComponentProps } from "react-window";
// Import the React-PDF tool.
import { Page as PdfPage } from "react-pdf";

// Import the loader.
import Loader from "../../loader";
// Import the context.
import { PDFContext } from "../context";
// Import the page size hook.
import useSizeOfPage from "../hooks/use-size-of-page";

// Import the css.
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import css from "./page.module.scss";


/** Component used to render a page of the current PDF document. */
const PDFPage = forwardRef<PdfPage | null, ListChildComponentProps>(
    function PDFPage(props: ListChildComponentProps, ref: ForwardedRef<PdfPage | null>): ReactElement {
        // Load the status of the page.
        const { scale, rotation } = useContext(PDFContext).pages[props.index];
        // Load the size of the page.
        const [width, height] = useSizeOfPage(props.index);

        // Render the loader.
        const loader: ReactNode = <Loader
            containerStyle={{ width: `${width}px`, height: `${height}px` }}
            containerClassName={css["loader"]}
            className={css["loader__icon"]}
            text="Chargement de la page ..."
        />;

        // Render the component.
        return <div style={props.style} className={css["container"]}>
            <PdfPage
                ref={ref}
                className={css["page"]}
                pageIndex={props.index}
                loading={loader}
                renderTextLayer
                renderAnnotationLayer
                renderInteractiveForms
                scale={scale}
                rotate={rotation}
                width={width}
                height={height}
                // NOTE: Force the pixel ratio to 1 to save performances on retina displays.
                devicePixelRatio={1}
            />
        </div>;
    }
);
export default PDFPage;

/** Inject the devicePixelRatio prop into @types/react-pdf. */
declare module "react-pdf/dist/Page" {
    export interface Props {
        /** Pixel ratio used for the canvas. */
        devicePixelRatio?: number;
    }
}
