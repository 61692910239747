/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useState } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the store.
import { ShareLinkStore, useResourceDispatch } from "@andromeda/store";
// Import the custom components.
import { useNotify } from "@andromeda/components";
// Import the resources.
import { CreateShareLink, ShareLinkType } from "@andromeda/resources";

// Import the icons.
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
// Import the css.
import css from "./create.module.scss";


/** Component used to render the button in charge of creating a new {@link ShareLink}. */
export default function CreateShareLinkButton(props: CreateShareLinkProps): ReactElement {
    // Get the notification tool.
    const { error }  = useNotify();

    // State used to check if a link is being created.
    const [creating, setCreating] = useState(false);

    // Callback used to create a new link.
    const dispatch = useResourceDispatch();
    const create = useCallback(async function createNewShareLink(): Promise<void> {
        try {
            const create: CreateShareLink = {
                type: ShareLinkType,
                relationships: {
                    zaq: {
                        data: {
                            type: "zaq",
                            id: props.id
                        }
                    }
                }
            }
            setCreating(true);
            await dispatch(ShareLinkStore.generator.create(create));
            setCreating(false);
        } catch (err: unknown) {
            error(
                err,
                "Impossible de créer un nouveau lien",
                "La création d'un nouveau lien de partage a échoué."
            )
        }
    }, [dispatch, error, props.id]);

    // Render the component.
    return <div className={css["container"]}>
        <button className={css["button"]} disabled={creating} onClick={create}>
            <span className={classNames(css["button__contents"], css["button__contents--enabled"])}>
                <FontAwesomeIcon className={css["button__icon"]} icon={faPlus} />
                Créer un nouveau lien
            </span>
            <span className={classNames(css["button__contents"], css["button__contents--disabled"])}>
                <FontAwesomeIcon className={css["button__icon"]} icon={faSpinner} />
                Création en cours ...
            </span>
        </button>
    </div>;
}

/** Props passed down to the {@link CreateShareLink} component. */
export interface CreateShareLinkProps {
    /** The identifier of the current ZaqWiki. */
    id: string;
}
