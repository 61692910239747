/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";

// Import the auth0 provider.
import { Auth0Provider } from "./auth0";
// Import the context provider.
import { LoginContextProvider } from "./provider";


// Re-export the context.
export * from "./context";

/** Provider used to give access to the login context. */
export function LoginProvider(props: Props): React.ReactElement {
    return <Auth0Provider><LoginContextProvider children={props.children} /></Auth0Provider>;
}

/** Props passed down to the {@link LoginContextProvider} component. */
interface Props { children?: React.ReactNode; }
