/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useState } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the legacy zaq tools.
import type { MinorStep } from "@andromeda/legacy-zaq";
// Import the resources.
import type { Validation } from "@andromeda/resources";
// Import the custom components.
import { AutoresizeTextarea } from "@andromeda/components";

// Import the icons.
import { faPlus, faComment, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Checkmark } from "@andromeda/assets/svg/checkmark.svg";
import { ReactComponent as Crossmark } from "@andromeda/assets/svg/crossmark.svg";
// Import the css.
import css from "./step.module.scss";


/** Helper component used to render a step row. */
export default function Step(props: StepProps): ReactElement {
    // Store the state of the remarks editor.
    const [showRemarks, setShowRemarks] = useState(false);

    // Compute the css class for the "last" row.
    let last = "";
    if (props["is-last"]) {
        last = css["last"];
    }

    // Show the remark editor if needed.
    if (showRemarks) {
        return <div className={`${css["text-editor"]} ${last}`}>
            <AutoresizeTextarea
                className={css["text-editor__textarea"]}
                placeholder="Ajouter une remarque pour cette étape ..."
                autoFocus
                defaultValue={props.detail.remarks}
                onChange={e => props.update({
                    remarks: e.currentTarget.value.length === 0 ? undefined : e.currentTarget.value
                })}
            />
            <button className={css["text-editor__save"]} onClick={() => setShowRemarks(false)}>
                <FontAwesomeIcon icon={faFloppyDisk} className={css["text-editor__save__icon"]} />
            </button>
        </div>;
    }

    // Compute the icon to render in the button.
    let checkedIcon: ReactNode = null;
    if (props.detail.validated === true) {
        checkedIcon = <Checkmark className={`${css["button__icon"]} ${css["button__icon--success"]}`} />;
    } else if (props.detail.validated === false) {
        checkedIcon = <Crossmark className={`${css["button__icon"]} ${css["button__icon--danger"]}`} />;
    }

    // Render the component.
    return <>
        <h5 className={`${css["title"]} ${last}`} children={props.step.name} />
        <button
            className={`${css["button"]} ${last}`}
            onClick={() => props.update({ validated: !props.detail.validated })}
        >
            {checkedIcon}
        </button>
        <button className={`${css["button"]} ${last}`} onClick={() => setShowRemarks(true)}>
            <FontAwesomeIcon
                icon={typeof props.detail.remarks === "undefined" ? faPlus : faComment}
                className={css["button__icon"]}
            />
        </button>
    </>;
}

/** Props passed down to the {@link Step} component. */
export interface StepProps {
    /** Detail of the currently rendered step. */
    detail: Validation.Detail;
    /** Step that is rendered. */
    step: MinorStep;

    /**
     * Callback invoked to update the validation.
     *
     * @param {Partial<Validation.Detail>} detail The updated details.
     */
    update(detail: Partial<Validation.Detail>): void;

    /** Flag that should be set if this is the last step in the current chapter. */
    "is-last": boolean;
}
