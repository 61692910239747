/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";
// Import the video asset types.
import { VideoAssetMetadata, VideoAssetMimeType } from "@andromeda/asset-conversion";

// Import the job interface.
import { Job } from "./job";

// Import the css.
import css from "./index.module.scss";


/** Component used to parse a given video file. */
export function VideoParser(props: VideoParserProps): React.ReactElement {
    // Store the file url.
    const [ url, setURL ] = React.useState<string>();
    React.useEffect(function createVideoURL(): () => void {
        const url = URL.createObjectURL(props.job.file);
        setURL(url); return () => { URL.revokeObjectURL(url); setURL(undefined); };
    }, [props.job.file]);

    // Resolves the job with the file's metadata.
    const resolve = React.useCallback(
        function resolveVideoMetadata(event: React.SyntheticEvent<HTMLVideoElement>): void {
            const video = event.currentTarget;

            // Compute the attributes of the video.
            const attributes: VideoAssetMetadata = {
                contentType: props.job.file.type as VideoAssetMimeType,
                width: video.videoWidth,
                height: video.videoHeight,
                length: isFinite(video.duration) ? video.duration : -1,
                bitrate: -1,
                framerate: 24
            };
            props.job.resolve(attributes);
        },
        [ props.job ]
    );

    // Rejects the job.
    const reject = React.useCallback(function rejectVideoFile(event: React.SyntheticEvent<HTMLVideoElement>): void {
        console.error("Failed to parse a video file");
        props.job.reject(event);
    }, [props.job]);

    // Render the component.
    return <video
        className={css["parser"]} src={url}
        onLoadedMetadata={resolve} onLoadedData={resolve} onError={reject}
    />;
}

/** Props passed down to the {@link VideoParser} component. */
interface VideoParserProps {
    /** The job to run on this parser. */
    job: Job;
}
