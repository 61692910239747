/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

/** Converts a value to the corresponding roman numeral. */
export function toRomanNumeral(value: number): string {
    value = Math.floor(value);
    // Roman numerals are only valid in the interval [1;3999]
    if (value > 3999) return value.toString();
    if (value <= 0) return value.toString();

    let roman = "";
    while (value > 0) {
        if (value >= 1000) { roman +=  "M"; value -= 1000; continue; }
        if (value >=  900) { roman += "CM"; value -=  900; continue; }
        if (value >=  500) { roman +=  "D"; value -=  500; continue; }
        if (value >=  400) { roman += "CD"; value -=  400; continue; }
        if (value >=  100) { roman +=  "C"; value -=  100; continue; }
        if (value >=   90) { roman += "XC"; value -=   90; continue; }
        if (value >=   50) { roman +=  "L"; value -=   50; continue; }
        if (value >=   40) { roman += "XL"; value -=   40; continue; }
        if (value >=   10) { roman +=  "X"; value -=   10; continue; }
        if (value >=    9) { roman += "IX"; value -=    9; continue; }
        if (value >=    5) { roman +=  "V"; value -=    5; continue; }
        if (value >=    4) { roman += "IV"; value -=    4; continue; }
        roman += "I"; value--;
    }
    return roman;
}

