/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the request stringifier.
import { humanReadableRequest } from "../config/url";
// Import the interfaces.
import { XhrBodyType, XhrHeaders, XhrPartialResponse, PreparedXhrRequest } from "../..";
// Import the header proxy.
import { XhrReadonlyHeaderProxyHandler } from "../proxy/header";
// Import the error classes.
import { ResponseParseAbortedError, ResponseParseFailedError } from "../../errors";

// Import the body decoder.
import { decodeBody } from "./index";

// Import the logging tool.
import debug from "debug";


const log = debug("xhr:services:response");


/**
 * Prepares the response object based on the given {@link Response} object.
 *
 * @param {PreparedXhrRequest} request The request that generated this response.
 * @param {Response} message The message to parse.
 * @returns {Promise<XhrPartialResponse>} A promise that resolves with the parsed response.
 * @throws {NoStatusCodeError} the given message has no status code.
 */
export async function prepareResponse(request: PreparedXhrRequest, message: Response): Promise<XhrPartialResponse> {
    log("Parsing the response of %s.", humanReadableRequest(request.options.method, request.url));

    // Parse the response headers.
    const preparedHeaders: XhrHeaders = {};
    message.headers.forEach(function headerParser(value, header): void {
        preparedHeaders[header] = value;
    });
    const headers = XhrReadonlyHeaderProxyHandler.apply(preparedHeaders);

    const abort = new AbortController();
    return {
        request, headers, status: message.status, body: parseBody(request, message, abort.signal),
        abort: abort.abort.bind(abort)
    };
}

/** Helper function used to parse the body of an incoming message. */
async function parseBody(
    request: PreparedXhrRequest,
    message: Response,
    signal: AbortSignal
): Promise<XhrBodyType | undefined> {
    log("Preparing to parse the body of %s", humanReadableRequest(request.options.method, request.url));
    // Return a new promise.
    return new Promise<XhrBodyType | undefined>(function bodyParser(resolve, reject) {
        // Attach the listeners.
        signal.addEventListener("abort", abortListener);

        /** Helper function used to clear all the message listeners. */
        function clearListeners(): void {
            signal.removeEventListener("abort", abortListener);
        }

        /** Helper function used to handle abortions. */
        function abortListener(): void {
            log("Body parsing of %s was aborted.", humanReadableRequest(request.options.method, request.url));
            // Clear all the listeners.
            clearListeners();

            // Reject the promise.
            reject(new ResponseParseAbortedError(request.options.method, request.url));
        }

        /** Helper used to handle the errors. */
        function errorHandler(error: unknown): void {
            reject(new ResponseParseFailedError(request.options.method, request.url, error));
        }

        // Check if the buffer holds any data.
        message.arrayBuffer().then(data => decodeBody(data, message.headers.get("content-type")))
            .then(resolve, errorHandler);
    });
}
