/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

/** List of all the sizes of the message frame. */
export const Sizes = {
    /** Compression applied to the entire payload. */
    "MessageHeader-MessageCompression": 1,
    /** Reserved byte in the message metadata. */
    "MessageHeader-Reserved": 1,
    /** Number of objects in this message. */
    "MessageHeader-ObjectCount": 2,
    /** Size of the metadata for an object. */
    "ObjectHeader-MetadataSize": 4,
    /** Size of the payload for an object. */
    "ObjectHeader-PayloadSize": 4,
    /** Compression applied to the payload of an object. */
    "ObjectHeader-Compression": 1,
    /** Reserved bytes in the object header. */
    "ObjectHeader-Reserved": 3,

    /** Total size of the message metadata. */
    "MessageHeaderBytes": 1 + 1 + 2,
    /** Total size of an object header. */
    "ObjectHeaderBytes": 4 + 4 + 1 + 3,
} as const;
