/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the link interfaces.
import { Link, AnyLinks } from "./links";
// Import the metadata interface.
import { Metadata } from "./meta";
// Import the JSON:API object interface.
import { JsonApi } from "./json-api";


/** Interface used to provide a link to information regarding the error. */
type ErrorLinks = AnyLinks & {
    /** Link to an about page for the error. */
    about?: Link;
}

/** Source of the error. */
interface ErrorSource {
    /** JSON-pointer to the source of the error in the request object. */
    pointer?: string;
    /** Query parameter that caused the error. */
    parameter?: string;
}

/**
 * Interface used to represent a single error object.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export interface Error<Meta extends Metadata = Metadata> {
    /** Unique identifier of this error. */
    id?: string;
    /** Unique error code regarding this error. */
    code?: string;

    /** Information links about the error. */
    links?: ErrorLinks;

    /** HTTP-status returned by the error. */
    status?: string;

    /** Title of the error. */
    title?: string;
    /** Details about the error. */
    detail?: string;

    /** Source of the error. */
    source?: ErrorSource;

    /** Metadata regarding the error. */
    meta?: Meta;
}

/** Interface used to describe a basic error message. */
interface ErrorMessageWithErrors {
    /** The list of errors that occurred while handling the request. */
    errors?: Error[];
}

/** Interface used to provide links for an error message. */
interface ErrorMessageWithLinks {
    /** Own and pagination links about the message. */
    links?: AnyLinks;
}

/** Interface used to provide info about the json-api implementation. */
interface ErrorMessageWithJsonApi {
    /** Property used to provide detail about the JSON:API implementation. */
    jsonapi?: JsonApi;
}

/** Interface used to provide metadata about the error. */
interface ErrorMessageWithMetadata<Meta extends Metadata = Metadata> {
    /** Metadata for this message. */
    meta?: Meta;
}

/**
 * Interface used to describe an error message.
 * Error messages are used to report errors to and from the server.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export type ErrorMessage = ErrorMessageWithErrors & ErrorMessageWithLinks & ErrorMessageWithJsonApi & ErrorMessageWithMetadata;
