/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the store interface.
import { Store } from "redux";
// Import the store hook.
import { useStore } from "react-redux";

// Import the store interface.
import { StoreWithRegistry } from "..";

/** Error thrown by {@link useStoreWithRegistry} if the store has no registry component. */
export class NoRegistryError extends Error {
    /** @inheritDoc */
    public constructor() {
        super("The current store has no registry component !");
    }
}

/**
 * Simple hook used to get a reference to the {@link StoreWithRegistry}.
 *
 * @throws {NoRegistryError} If the store has no registry component.
 */
export function useStoreWithRegistry(): StoreWithRegistry {
    // Get a reference to the store.
    const store = useStore();

    // Cast the store to a store with registry.
    const checkIfStoreHasRegistry = React.useCallback(
        function checkIfStoreHasRegistry(store: Store): store is StoreWithRegistry {
            // Check if the store has the registry.
            if (!store || !("registry" in store)) return false;

            const withRegistryProp = store as Store & { registry: unknown };
            if (
                typeof withRegistryProp.registry !== "object" ||
                withRegistryProp.registry === null
            )
                return false;

            if (!("reducerMap" in withRegistryProp.registry)) return false;
            if (!("middlewareMap" in withRegistryProp.registry)) return false;
            return "eventTarget" in withRegistryProp.registry;
        },
        [store]
    );

    // Check if the store has a registry.
    if (checkIfStoreHasRegistry(store)) return store;
    else throw new NoRegistryError();
}
