/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react";
// Import the font aweseome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the bootstrap components.
import { Container } from "react-bootstrap";
// Import the draggable items.
import { Sortable, SortableStopEvent } from "@shopify/draggable";
// Import the resources.
import { Organisation, Tag, ZaqWiki, ZaqWikiFile } from "@andromeda/resources";
// Import the custom components.
import { Loader, useNotify } from "@andromeda/components";
// Import the stores.
import { TagStore, useResourceDispatch, useTags, ZaqWikiStore } from "@andromeda/store";
// Import the storybook module.
import { ToggleableButton } from "@andromeda/storybook";
// Import the login context.
import { LoginContext } from "@andromeda/login";

// Import the toggleable context.
import { ZaqWikiEditContext } from "../toggleable-context";
// Import the local context.
import { ReaderContext, useContextLoader } from "./context";
// Import the subcomponents.
import { Header } from "./header";
import { Content } from "./content";
import { Generator } from "./generator";
import { File } from "./file";
import { TagSearchBar, TagSearchBarSource } from "./search";
import { DropTarget } from "./file/drop-target";

// Import the edit icon.
import { faEdit } from "@fortawesome/free-regular-svg-icons";
// Import the css.
import headerCss from "./file/header.module.scss";
import fileCss from "./file/file.module.scss";
import css from "./index.module.scss";
import ShareButton from "../components/share";


/** Function-component used to render the wiki editor. */
export default function Editor(props: EditorProps): ReactElement {
    // Get the current organisation.
    const organisation = useContext(LoginContext).organisations.current.id;

    // Get the notification tool.
    const { fatal } = useNotify();
    // Get the dispatch method.
    const dispatch = useResourceDispatch();

    // Load the zaq from the store.
    const context = useContextLoader(organisation, props.id);

    // Load all the available tags.
    const tags = useTags(organisation);
    const availableTags = useMemo(function loadAvailableTags(): TagSearchBarSource[] {
        // If the context is not defined, return an empty object.
        if (!context || !tags.isSuccess) {
            return [];
        }

        // Filter out any tag already assigned to the current wiki.
        return tags.data.filter(function filterAssignedTags(tag: Tag): boolean {
                // Check if the tag exists in the context.
                return !context.categories.some(ctx => tag.id === ctx.id);
            })
            // Map the tags found to the search bar sources.
            .map(function mapTagToSearchBarSource(tag: Tag): TagSearchBarSource {
                return { text: tag.attributes.name, key: tag.id, data: { tag } };
            });
    }, [context, tags]);

    // Adds a tag to the current wiki.
    const addTag = useCallback(
        function addTagToWiki(source: Tag): void {
            if (context === null) {
                return;
            }

            // Prepare the update.
            const update: ZaqWiki.Update = {
                type: ZaqWiki.Type,
                id: context.wiki.id,
                relationships: {
                    categories: {
                        data: [
                            ...context.categories.map(({ id }) => ({ type: Tag.Type, id })),
                            { type: Tag.Type, id: source.id }
                        ]
                    }
                }
            };
            dispatch(ZaqWikiStore.generator.update(update)).catch(fatal);
        },
        [context, fatal, dispatch]
    );
    // Creates a new tag
    const createTag = useCallback(
        function createTag(text: string): void {
            if (context === null) {
                return;
            }
            if (!organisation) {
                return;
            }

            // Prepare the update.
            const create: Tag.Create = {
                type: Tag.Type,
                attributes: { name: text },
                relationships: { owner: { data: { type: Organisation.Type, id: organisation } } }
            };
            dispatch(TagStore.generator.create(create)).then(addTag).catch(fatal);
        },
        [addTag, context, fatal, dispatch, organisation]
    );

    // Render the component.
    if (context === null) {
        return <Loader text="Chargement ..." />;
    }
    return <ReaderContext.Provider value={context}>
        <TagSearchBar.Provider
            source={availableTags}
            onSelected={source => addTag(source.data.tag)}
            creatable
            onCreated={createTag}
            notCreatableTexts={tags.data?.map(tag => tag.attributes.name)}
        >
            <ToggleableButton context={ZaqWikiEditContext} className={css["edit-button"]}>
                <FontAwesomeIcon icon={faEdit} className={css["edit-button__icon"]} />
            </ToggleableButton>
            <ShareButton className={css["share-button"]} id={props.id} />
            <Container className="pb-4">
                <Header />
                <Content />
                <Generator />
                <div className="mt-3" />
                <FileContainer />
            </Container>
        </TagSearchBar.Provider>
    </ReaderContext.Provider>;
}

/** Container used to render all the files of the current zaq. */
function FileContainer(): ReactElement {
    // Get the "notify.fatal" error method.
    const notify = useNotify();
    // Load the files from the context.
    const context = useContext(ReaderContext);

    // Generate the "sortable" object to allow re-ordering.
    const [container, setContainer] = useState<HTMLElement | null>(null);
    const [sortable, setSortable] = useState<Sortable>();

    useEffect(function generateSortable(): VoidFunction | void {
        // Wait for the container to be defined.
        if (!container) {
            return setSortable(undefined);
        }

        // Build the sortable.
        const sortable = new Sortable(container, {
            draggable: "." + fileCss["file"],
            handle: "." + headerCss["header__grab-handle"]
        });
        setSortable(sortable);

        // Return the destroy callback.
        return function destroySortable(): void {
            sortable.destroy();
        };
    }, [container]);

    // Re-order the elements when their position changes.
    const dispatch = useResourceDispatch();
    const reorder = useCallback(
        function reorderFiles(event: SortableStopEvent): void {
            if (context === null) {
                return;
            }

            // Re-order the files.
            const reordered = [...context.files];
            reordered.splice(event.newIndex, 0, reordered.splice(event.oldIndex, 1)[0]);

            // Dispatch the update.
            dispatch(
                ZaqWikiStore.generator.update({
                    type: ZaqWiki.Type,
                    id: context.wiki.id,
                    relationships: {
                        files: { data: reordered.map(file => ({ type: ZaqWikiFile.Type, id: file.id })) }
                    }
                })
            ).catch(notify.fatal);
        },
        [context, dispatch, notify.fatal]
    );

    useEffect(function attach(): VoidFunction | void {
        // Wait for the sortable to be defined.
        if (typeof sortable === "undefined") {
            return;
        }

        // Attach to the sorting events.
        sortable.on("sortable:stop", reorder);
        return () => {
            sortable.off("sortable:stop", reorder);
        };
    }, [reorder, sortable]);

    // Render all the elements.
    const elements = useMemo(
        function renderElements(): ReactElement[] {
            // Find the index of the first non-link type file.
            const firstExpandedIndex = context.files.findIndex(
                file => !ZaqWikiFile.isLinkFile(file) && !ZaqWikiFile.isTutoFile(file)
            );
            return context.files.map((file, index) => (
                <File file={file} key={file.id} defaultExpanded={index === firstExpandedIndex} />
            ));
        },
        [context.files]
    );

    return <>
        <DropTarget />
        <div className={css["files"]} id="file-sortable-source" ref={setContainer} children={elements} />
    </>;
}

/** Props passed down to the {@link Editor} component. */
interface EditorProps {
    /** The identifier of the edited {@link ZaqWiki}. */
    id: string;
}
