/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useEffect, useMemo } from "react";
// Import the store.
import { useShareLinks } from "@andromeda/store";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the resources.
import type { ShareLink } from "@andromeda/resources";
// Import the custom components.
import { useNotify } from "@andromeda/components";

// Import the link renderer.
import Link from "./link";

// Import the css.
import css from "./list.module.scss";


/** Renders the list of all the available share links. */
export default function ShareLinkList(props: ShareLinkListProps): ReactElement {
    // Load all the available links from the store.
    const links = useShareLinks({ type: "zaq-wiki", id: props.id });

    // Propagate loading errors.
    const { error } = useNotify();
    useEffect(function propagateLoadingError(): void {
        // Check if an error occurred.
        if (!links.isError) {
            return;
        }

        // Report the error.
        error(links.error);
    }, [error, links.isError, links.error]);

    // Render the list of links.
    const children = useMemo(function renderLinkList(): ReactNode {
        // Wait for the links to be loaded.
        if (!links.isSuccess) {
            return null;
        }

        return links.data?.map(function renderShareLink(link: ShareLink): ReactNode {
            return <Link key={link.id} id={props.id} link={link} />;
        });
    }, [links.data, links.isSuccess, props.id]);

    // Wait for the links to load.
    if (!links.isSuccess) {
        return <Loader containerClassName={css["loader"]} text="Chargement des liens partagés ..." />;
    }
    return <div className={css["list"]} children={children} />;
}

/** Props passed down to the {@link ShareLinkList} component. */
export interface ShareLinkListProps {
    /** The identifier of the current {@link ZaqWiki}. */
    id: string;
}
