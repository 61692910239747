/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";

// Import the loading image.
import { ReactComponent as Loading } from "@andromeda/assets/images/loading-blue.svg";
// Import the CSS.
import css from "./loader.module.scss";


/** Props passed down to the {@link Loader} function component. */
interface LoaderProps {
    /** Text rendered below the loading icon. */
    text?: string;
    /** If true, renders the loader as a block. */
    asBlock?: boolean;
    /** If true, render without a background. */
    transparent?: boolean;
    /** Additional class names added to the component. */
    className?: string;
}

/** Simple function component used to render a loader on the screen. */
export function Loader(props: LoaderProps): React.ReactElement {
    const className = React.useMemo(function buildClassName(): string {
        let className = css["loader"];
        if (props.asBlock) className += " " + css["loader--block"];
        if (props.transparent) className += " " + css["loader--transparent"];
        if (props.className) className += " " + props.className;
        return className;
    }, [props.asBlock, props.className, props.transparent]);

    // Render the component.
    return <div className={className}>
        <Loading className={css["loader__loader"]} title="loader" />
        {props.text ? <p>{props.text}</p> : null}
    </div>
}
