/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ChangeEvent, ReactElement, ReactNode, useCallback, useMemo } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the store.
import { useRequirements, useSteps } from "@andromeda/store";
// Import the resources.
import type { Step } from "@andromeda/resources";

// Import the subcomponents.
import ItemOption from "./item-option";

// Import the icons.
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./index.module.scss";


/** Component used to render the <select> item that allows to change the current user. */
export default function ItemSelector(props: ItemSelectorProps): ReactElement {
    // Load all the requirements for the current user.
    const steps = useSteps(props.course);
    const requirements = useRequirements(props.course, props.user);

    // Flatten to a list of items.
    const items = useMemo(function flattenStepList(): Step.Item[] {
        // Wait for everything to be loaded.
        if (!steps.isSuccess || !requirements.isSuccess) {
            return [];
        }

        // Flatten the steps.
        return steps.data.reduce<Step.Item[]>(function flattenStep(items: Step.Item[], step: Step): Step.Item[] {
            for (const item of step.relationships.items.data) {
                if (item.type === "zaq" || item.type === "external") {
                    if (!items.some(({ type, id }) => type === item.type && id === item.id)) {
                        items.push(item);
                    }
                }
            }
            return items;
        }, []);
    }, [requirements.isSuccess, steps.data, steps.isSuccess]);

    // Render all the options.
    const options = useMemo(function renderUserOptions(): ReactNode {
        const zaq: ReactElement[] = [], external: ReactElement[] = [];
        for (const item of items) {
            (item.type === "zaq" ? zaq : external).push(<ItemOption key={`${item.type}-${item.id}`} item={item} />);
        }

        return <>
            <optgroup label="ZaqTuto+" children={zaq} />
            <optgroup label="Formation Externe" children={external} />
        </>;
    }, [items]);

    // Callback used to update the item when the item changes.
    const onChange = useCallback(function onChange(event: ChangeEvent<HTMLSelectElement>): void {
        const [type, id] = event.currentTarget.value.split("-");
        if (type !== "zaq" && type !== "external") {
            return;
        }
        if (typeof id === "undefined") {
            return;
        }

        props.setItem({ type, id });
    }, [props]);
    let value: string | undefined = undefined;
    if (props.item) {
        value = `${props.item.type}-${props.item.id}`;
    }

    // Render the selector.
    return <div className={css["selector__container"]}>
        <select value={value} onChange={onChange} children={options} className={css["selector"]} />
        <FontAwesomeIcon icon={faChevronDown} className={css["selector__icon"]} />
    </div>;
}

/** Props passed down to the {@link ItemSelector} component. */
export interface ItemSelectorProps {
    /** Identifier of the current course. */
    course: string;
    /** Identifier of the current user. */
    user: string | undefined;
    /** Currently selected item. */
    item: Step.Item | undefined;

    /**
     * Callback used to change the current item.
     *
     * @param {Step.Item} item The newly selected item.
     */
    setItem(item: Step.Item): void;
}
