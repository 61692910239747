/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { forwardRef, ReactElement, ReactPortal, Ref, useImperativeHandle, useMemo, useState } from "react";
// Import ReactDOM.
import { createPortal } from "react-dom";
// Import the PopperJS tools.
import { usePopper } from "react-popper";

// Import the css.
import css from "./popper.module.scss";


/** @see LinkExpirationPopper */
export default forwardRef(LinkExpirationPopper);

/** Component used to render the popper that will contain the calendar element for the current link. */
function LinkExpirationPopper(props: LinkExpirationPopperProps, ref: Ref<HTMLDivElement | null>): ReactElement|  null {
    // Stores a reference to the popper element.
    const [popper, setPopper] = useState<HTMLDivElement | null>(null);

    // Propagate the popper ref to the parent.
    useImperativeHandle(ref, () => popper, [popper]);

    // Build the popper options.
    const options = useMemo(function buildPopperOptions(): Parameters<typeof usePopper>[2] {
        return {
            placement: "bottom",
            modifiers: [
                { name: "offset", options: { offset: [0, 8] } },
                { name: "preventOverflow", options: { padding: 16 } }
            ]
        };
    }, []);

    // Build the PopperJS tools.
    const { attributes, styles } = usePopper(props.container, popper, options);

    // Create the popper item through a portal.
    return useMemo(function renderPopperThroughPortal(): ReactPortal | null {
        // Render nothing if the modal is not open.
        if (!props.open) {
            return null;
        }

        // Build the portal.
        return createPortal(
            <div
                className={css["popper"]}
                data-link-id={props.id}
                ref={setPopper} {...attributes["popper"]}
                style={styles["popper"]}
            />,
            document.body,
            `link-${props.id}-expiration`
        );
    }, [attributes, props.id, props.open, styles]);
}

/** Props passed down to the {@link LinkExpirationPopper} component. */
export interface LinkExpirationPopperProps {
    /** Identifier of the link being rendered. */
    id: string;
    /** Reference to the container element that the position will be computed relative to. */
    container: HTMLElement | null;
    /** Flag set if the popper element should be rendered. */
    open: boolean;
}
