/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, SyntheticEvent, useCallback, useContext } from "react";
// Import the FontAwesome icon.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the login module.
import { LoginContext } from "@andromeda/login";
// Import the custom components.
import { useDeleteConfirm } from "@andromeda/components";
// Import the store.
import { StepStore, useResourceDispatch } from "@andromeda/store";

// Import the contexts.
import { EditorSwitchContext, ValidatorSwitchContext } from "../../../context";
// Import the custom hooks.
import { useProgress } from "../../../hooks";
// Import the level icon.
import LevelIcon from "../../level-icon";

// Import the icons.
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
// Import the css.
import css from "./step-status.module.scss";
import classNames from "classnames";


/** Component used to render the status of the current step. */
export default function StepStatus(props: StepStatusProps): ReactElement | null {
    // Check if the user is editing or validating the course.
    const { state: editing } = useContext(EditorSwitchContext);
    const { state: validating } = useContext(ValidatorSwitchContext);

    // Load the progress status of the step.
    const progress = useProgress(props.course, useContext(LoginContext).self.id).data?.step(props.step);

    // Callback used to delete the specified step.
    const confirm = useDeleteConfirm("Êtes-vous sûr de vouloir supprimer cette étape ?");
    const dispatch = useResourceDispatch();
    const deleteStep = useCallback(function deleteStep(event: SyntheticEvent): void {
        // Stop the event propagation.
        event.stopPropagation(); event.preventDefault();

        // Ask the user for confirmation.
        confirm().then(function (accepted: boolean) {
            if (!accepted) {
                return;
            }

            // Delete the step.
            return dispatch(StepStore.generator.delete(props.step));
        }).catch(console.error);
    }, [confirm, dispatch, props.step]);

    // Render the component.
    if (editing) {
        return <div className={css["container"]}>
            <button className={css["delete-button"]} onClickCapture={deleteStep}>
                <FontAwesomeIcon className={css["delete-button__icon"]} icon={faTrash} />
            </button>
        </div>
    }
    if (validating) {
        return null;
    }
    const iconClassName = classNames(
        css["checkmark"],
        { [css["checkmark--objective-met"]]: progress?.objectiveMet },
        { [css["checkmark--validated"]]: progress?.validated }
    );
    return <div className={css["container"]}>
        <span className={css["text"]}>niveau requis: </span>
        <LevelIcon className={css["level"]} level={progress?.requiredLevel ?? 0} />
        <FontAwesomeIcon className={iconClassName} icon={faCheck} />
    </div>;
}

/** Props passed down to the {@link StepStatus} component. */
export interface StepStatusProps {
    /** The current course that is rendered on the screen. */
    course: string;
    /** The step to render the status of. */
    step: string;
}
