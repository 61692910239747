/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the configuration helper.
import { getConfig } from "@andromeda/config";

// Import the Xhr config interfaces.
import { XhrTarget, XhrConfig } from "../../interfaces";
// Import the schema.
import * as Schema from "./schema.json";

// Import the logging tool.
import debug from "debug";
const log = debug("xhr:services:config:target");


/**
 * Simple method used to resolve a target from its given name.
 *
 * @param {string} target The name of the target to resolve.
 * @returns {Promise<XhrTarget | null>} A promise that resolves with the found target config,
 * or null if no such target exists.
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export async function resolveTarget(target: string): Promise<[string, XhrTarget] | null> {
    log("Searching for a target named \"%s\"...", target);
    // Get the configuration data.
    const config = await getConfig<XhrConfig>(Schema);
    if (typeof config.xhr === "undefined") return null;

    // Search for an exact target match.
    const targetConfig = config.xhr[target];
    if (targetConfig) return [ target, targetConfig ];

    // Search for the target in the list.
    for (const [ targetName, targetConfig ] of Object.entries(config.xhr)) {
        if (targetConfig.tags?.includes(target)) return [ targetName, targetConfig ];
    }

    for (const [ targetName, targetConfig ] of Object.entries(config.xhr)) {
        if (targetConfig.tags?.some(targetName => {
            const match = target.match(`^${targetName}$`);
            if (match !== null) {
                log("Target \"%s\" matched with \"%s\"", targetName, target);
            }
            return match !== null;
        })) return [targetName, targetConfig];
    }
    return null;
}
