/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the AJV interface.
import { ValidateFunction } from "ajv";
// Import the search parameters interface.
import { XhrSearchParameters, XhrHeaders } from "@andromeda/xhr";
// Import the JSON:API resource interface.
import { Resource, Filter, encodeFilter } from "@andromeda/json-api";

// Import the interfaces.
import {
    IncludeList,
    SparseFieldset,
    SortingList,
    ReadOneOptions,
    ReadManyOptions,
    DeleteOptions,
} from "./interfaces";

/**
 * Helper type used to describe a resource without identifier.
 * Useful for creating a resource in the store.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type CreatableResource<T extends Resource | string = Resource> = Omit<
    T extends string ? Resource<T> : T,
    "id" | "links" | "meta"
>;


/** Replaces all undefined properties to "deletable" versions of the property. */
type Deletable<T> = {
    [K in keyof T]: undefined extends T[K] ? Exclude<T[K], undefined> | "$$REMOVE" :Exclude<T[K], undefined>
}

/**
 * Helper type used to describe a partial resource.
 * Useful for updating a resource in the store.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type PartialResource<T extends Resource> = Omit<
    T,
    "attributes" | "relationships" | "links" | "meta"
> & {
    attributes?: Partial<T["attributes"]>
} & {
    relationships?: Partial<T["relationships"]>;
};

/**
 * Helper type used to describe a resource update.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type UpdatableResource<T extends Resource> = Omit<
    T,
    "attributes" | "relationships" | "links" | "meta"
> & {
    attributes?: Deletable<Partial<T["attributes"]>>
} & {
    relationships?: Partial<T["relationships"]>;
};

/** Tuple used to declare a list of resources for CRUD operations. */
export type CRUDTuple<
    R extends Resource = Resource,
    U extends PartialResource<Resource<R["type"]>> = PartialResource<R>,
    C extends CreatableResource<Resource<R["type"]>> = CreatableResource<R>
> = [resource: R, update: U, create: C];

/**
 * Helper method used to prepare the url for the Xhr request.
 *
 * @param {string} type The type of the resource to manipulate.
 * @param {DeleteOptions | undefined} [options=undefined] The options to parse.
 * @returns {string} The url that can be passed to the Xhr class.
 */
export function extractUrl(type: string, options?: DeleteOptions): string {
    // If the options provide a url override.
    if (typeof options?.url === "string") {
        return new URL(options.url).toString();
    } else {
        // Load the url dynamically.
        return type;
    }
}

/**
 * Helper method used to build the {@link URLSearchParams} from given read options.
 *
 * @param {DeleteOptions | ReadOneOptions | ReadManyOptions | undefined} [options=undefined] The options to parse.
 * @returns {XhrSearchParameters} The generated search parameters.
 */
export function buildParams(
    options?: DeleteOptions | ReadOneOptions | ReadManyOptions
): XhrSearchParameters {
    // If there are no options, do nothing.
    if (typeof options === "undefined") return {};

    // Prepare the output object.
    const searchParameters: XhrSearchParameters = options.searchParams ?? {};

    // Wrap all the helper methods.
    if ("include" in options && typeof options.include !== "undefined")
        buildIncludeParam(searchParameters, options.include);
    if ("fields" in options && typeof options.fields !== "undefined")
        buildFieldsParam(searchParameters, options.fields);
    if ("sort" in options && typeof options.sort !== "undefined")
        buildSortParam(searchParameters, options.sort);
    if ("filter" in options && typeof options.filter !== "undefined")
        buildFilterParam(searchParameters, options.filter);
    if ("limit" in options && typeof options.limit !== "undefined")
        buildLimitParam(searchParameters, options.limit);
    if ("skip" in options && typeof options.skip !== "undefined")
        buildSkipParam(searchParameters, options.skip);

    return searchParameters;
}

/**
 * Helper method used to build the {@link XhrHeaders} from given read options.
 *
 * @param {DeleteOptions | ReadOneOptions | ReadManyOptions | undefined} [options=undefined] The options to parse.
 * @returns {XhrHeaders} The generated headers.
 */
export function buildHeaders(
    options?: DeleteOptions | ReadOneOptions | ReadManyOptions
): XhrHeaders {
    // Inject the content language headers.
    const headers = options?.headers ?? {};
    headers["Content-Language"] = "fr-FR";
    headers["Accept-Language"] = "fr-FR";
    return headers;
}

/**
 * Helper function used to build the search parameters for relationship inclusion.
 * Alters the {@link params} argument directly.
 *
 * @param {XhrSearchParameters} params The params object to alter.
 * @param {IncludeList} list The list of relationships to include.
 */
export function buildIncludeParam(
    params: XhrSearchParameters,
    list: IncludeList
): void {
    params["include"] = list.join(",");
}

/**
 * Helper function used to build the search parameters for the fieldsets.
 * Alters the {@link params} argument directly.
 *
 * @param {XhrSearchParameters} params The params object to alter.
 * @param {SparseFieldset} list The list of fields to retrieve.
 */
export function buildFieldsParam(
    params: XhrSearchParameters,
    list: SparseFieldset
): void {
    // Loop through the list.
    for (const [type, fields] of Object.entries(list)) {
        // Set the parameter.
        params[`fields[${type}]`] = fields.join(",");
    }
}

/**
 * Helper function used to build the search parameters for sorting.
 * Alters the {@link params} argument directly.
 *
 * @param {XhrSearchParameters} params The params object to alter.
 * @param {SortingList} list The list of fields to sort by.
 */
export function buildSortParam(
    params: XhrSearchParameters,
    list: SortingList
): void {
    // Ensures that the members start either by a + or a -.
    function ensurePlusOrMinus(item: string): string {
        if (item.startsWith("+")) return item;
        if (item.startsWith("-")) return item;
        return `+${item}`;
    }
    params["sort"] = list.map(ensurePlusOrMinus).join(",");
}

/**
 * Helper function used to build the filter parameters.
 * Alters the {@link params} argument directly.
 *
 * @param {XhrSearchParameters} params The params object to alter.
 * @param {Filter} filter The filter to apply.
 */
export function buildFilterParam(
    params: XhrSearchParameters,
    filter: Filter | string
): void {
    const encoded = typeof filter !== "string" ? encodeFilter(filter) : filter;
    if (encoded.length > 0) params["filter"] = encoded;
}

/**
 * Helper function used to build the limit parameter.
 * Alters the {@link params} argument directly.
 *
 * @param {XhrSearchParameters} params The params object to alter.
 * @param {number} limit The number of items to limit by in the request.
 */
export function buildLimitParam(
    params: XhrSearchParameters,
    limit: number
): void {
    params["limit"] = limit;
}

/**
 * Helper function used to build the skip parameter.
 * Alters the {@link params} argument directly.
 *
 * @param {XhrSearchParameters} params The params object to alter.
 * @param {number} skip The number of items to skip in the request.
 */
export function buildSkipParam(
    params: XhrSearchParameters,
    skip: number
): void {
    params["skip"] = skip;
}

/** Union of both read option types. */
export type ReadOptions = ReadOneOptions | ReadManyOptions;
// Return type of the validator and options parser.
interface ValidatorAndOptions<R, O extends ReadOptions> {
    /** Parsed validator function. */
    validator?: PromisableValidateFunction<R>;
    /** Parsed read options. */
    options?: O;
}
/** Helper type for the union of a validate function and a promise. */
export type PromisableValidateFunction<R> = ValidateFunction<R> | Promise<ValidateFunction<R>>;
/** Helper type used to describe the validator or options union. */
export type ValidatorOrOptions<R, O extends ReadOptions> = PromisableValidateFunction<R> | O;

// Helper method used to check if the object is a promisable validate function.
function isValidator<R>(
    object?: ValidatorOrOptions<R, ReadOptions>
): object is PromisableValidateFunction<R> {
    if (typeof object === "function") return true;
    return typeof object === "object" && "then" in object;
}

/**
 * Helper function used to parse the validator and the options from two given objects.
 *
 * @template R
 * @template {ReadOptions} O
 * @param {ValidatorOrOptions<R, O>} a The first object to parse.
 * @param {O} b The second object to parse, aka the optional read options if {@link a} is a validator function.
 * @returns {ValidatorAndOptions<R, O>} The parsed elements.
 */
export function parseValidatorAndOptions<R, O extends ReadOptions>(
    a?: ValidatorOrOptions<R, O>,
    b?: O
): ValidatorAndOptions<R, O> {
    let validator: PromisableValidateFunction<R> | undefined = undefined;
    let options: O | undefined = undefined;

    // Check if a is a validator function.
    if (isValidator(a)) {
        validator = a;
        if (typeof b === "object") {
            options = b;
        }
    } else {
        if (typeof a === "object") {
            options = a;
        } else if (typeof b === "object") {
            options = b;
        }
    }

    return { validator, options };
}
