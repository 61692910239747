/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the base class.
import { EventMap, EventHandler } from "./base";


/**
 * Implementation of the {@link EventHandler} that can also be used like a promise.
 *
 * @template R
 */
export class AwaitableEventHandler<R, M extends EventMap<M> = Record<string, unknown>> extends EventHandler<M> implements Promise<R> {
    /** Internal promise being wrapped. */
    private readonly _internalPromise: Promise<R>;

    /**
     * Class constructor.
     * Prepares a new runnable promise internally.
     *
     * @param {PromiseCallback<R>} callback The callback to invoke to run the promise.
     */
    public constructor(callback: PromiseCallback<R, M>) {
        super();
        this._internalPromise = new Promise<R>((resolve, reject) => callback(this, resolve, reject));
    }

    /** @inheritDoc */
    public readonly [Symbol.toStringTag]: string = Object.toString();

    /** @inheritDoc */
    public catch<TResult = never>(
        onrejected?: ((reason: unknown) => (PromiseLike<TResult> | TResult)) | undefined | null
    ): Promise<R | TResult> {
        return this._internalPromise.catch(onrejected);
    }

    /** @inheritDoc */
    public then<TResult1 = R, TResult2 = never>(
        onfulfilled?: ((value: R) => (PromiseLike<TResult1> | TResult1)) | undefined | null,
        onrejected?: ((reason: unknown) => (PromiseLike<TResult2> | TResult2)) | undefined | null
    ): Promise<TResult1 | TResult2> {
        return this._internalPromise.then(onfulfilled, onrejected);
    }

    /** @inheritDoc */
    public finally(onfinally?: (() => void) | undefined | null): Promise<R> {
        return this._internalPromise.finally(onfinally);
    }
}

/** Alias for the promise resolve. */
type Resolver<R> = (value: R | PromiseLike<R>) => void;
/** Alias for the promise reject. */
type Rejecter = (reason?: unknown) => void;
/** Alias for the {@link AwaitableEventHandler} constructor parameter. */
type PromiseCallback<R, M extends EventMap<M> = Record<string, unknown>> =
    (handler: AwaitableEventHandler<R, M>, resolve: Resolver<R>, reject: Rejecter) => void;
