/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the base JSON:API error class.
import { JsonApiError } from "@andromeda/json-api";

/**
 * Error thrown when a validate method is called on a non-compiled factory.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class NotCompiledError extends JsonApiError {
    /** @inheritDoc */
    public override get status() {
        return "500" as const;
    }
    /** @inheritDoc */
    public override get code() {
        return "0x10701" as const;
    }

    /** Class constructor. */
    public constructor() {
        super(
            "A validate method was called before the factory was fully compiled !"
        );
    }
}

/**
 * Error thrown when a request is not authorized.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class UnauthorizedError extends JsonApiError {
    /** @inheritDoc */
    public override get status() {
        return "403" as const;
    }
    /** @inheritDoc */
    public override get code() {
        return "0x10702" as const;
    }

    /** Class constructor. */
    public constructor() {
        super("You cannot do this !");
    }
}
