/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";

// Import the item interface.
import { Item } from ".";

// Import the chevron.
import chevron from "@andromeda/assets/images/chevron-blue.svg";
// Import the css.
import css from "./index.module.scss";


/** Component used to render a {@link Category} element. */
export function Category<T extends Item>(props: CategoryProps<T>): React.ReactElement {
    const [ opened, setOpened ] = React.useState(false);

    // Render the children items.
    const { onSelected } = props;
    const items = React.useMemo(function renderItemList(): React.ReactElement[] {
        return props.category.items.map((item: T, index: number): React.ReactElement =>
            <li
                className={css["list__item"]}
                children={item.element}
                key={index}
                onClick={() => onSelected?.(item)}
            />
        );
    }, [ onSelected, props.category.items ]);

    // Render the component.
    return <li
        className={
            `${css["list__category"]} ` +
            `${opened ? css["list__category--opened"] : css["list__category--closed"]}`
        }
    >
        <button onClick={() => setOpened(!opened)} className={css["list__category__title"]}>
            <img src={chevron} alt="open-close-chevron" />
            {typeof props.category.name === "object" ? props.category.name : <p children={props.category.name} />}
        </button>
        <ul className={css["list"]} children={items} />
    </li>;
}

/** Props passed down to the {@link Category} component. */
interface CategoryProps<T extends Item> {
    /** The category that should be rendered. */
    category: Category<T>;

    /** The callback to invoke when an element is selected. */
    onSelected?(item: T): void;
}

/** Interface used to describe a single category. */
export interface Category<T extends Item = Item> {
    /** The name of the category. */
    name: string | React.ReactNode;
    /** The list of items in this category. */
    items: T[];
}
