/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";

// Import the fullscreen modal.
import { FullscreenModal } from "../../fullscreen-modal";

// Import the css.
import css from "./image.module.scss";


/** Component used to render an image module. */
export default function ImageModule(props: ImageModuleProps): ReactElement {
    // If true, shows the module in full screen.
    const [fullscreen, setFullscreen] = useState(false);

    // Fit the image in its container.
    const imageRef = useRef<HTMLImageElement | null>(null);
    useEffect(function autoResizeImageElement(): () => void {
        window.addEventListener("resize", resizeToFitParent, { passive: true });
        window.addEventListener("fullscreenchange", resizeToFitParent, { passive: true });
        resizeToFitParent();
        return function removeListeners(): void {
            window.removeEventListener("resize", resizeToFitParent);
            window.removeEventListener("fullscreenchange", resizeToFitParent);
        }

        /** Fits the image in its parent. */
        function resizeToFitParent(): void {
            // If the ref is unset, do nothing.
            if (!imageRef.current?.parentElement) {
                return;
            }

            // Get the bounds of the elements.
            const { naturalWidth: width, naturalHeight: height } = imageRef.current;
            const { width: parentWidth, height: parentHeight } = imageRef.current.parentElement.getBoundingClientRect();

            // Fit the image in its parent.
            if (width / height < parentWidth / parentHeight) {
                imageRef.current.style.height = `${parentHeight}px`;
                imageRef.current.style.width = `auto`;
            } else {
                imageRef.current.style.width = `${parentWidth}px`;
                imageRef.current.style.height = `auto`;
            }
        }
    }, []);

    // Render the image.
    const image = useMemo(function renderImageElement(): ReactElement {
        return <img
            ref={imageRef}
            onClick={() => setFullscreen(true)}
            alt="module"
            className={css["image__image"]}
            src={props.src}
        />;
    }, [props.src]);

    // Render the component.
    return <div className={css["image"]}>
        <div className={css["image__image__container"]} children={image} />
        <div className={`${css["image__title"]} ${css["image__title--visible"]}`}>
            <h3 className={css["image__title__text"]} children={props.title} />
        </div>
        <FullscreenModal show={fullscreen} hide={() => setFullscreen(false)} children={image} />
    </div>;
}

/** Props passed down to the {@link ImageModule} component. */
export interface ImageModuleProps {
    /** Title of the image module. */
    title: string;
    /** URL of the image to render. */
    src: string;
}
