/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API interfaces.
import {
    Resource,
    ResourceIdentifier,
    Relationship
} from "@andromeda/json-api";
import { CreatableResource } from "@andromeda/resource-helper";
import type { ValidateFunction } from "ajv";
// Import the json schema compiler.
import { compileSync } from "@andromeda/validation";

// Import the user resource.
import { User } from "../../organisation";
// Import the course resource.
import { Course } from "../course";

// Import the schemas.
import { DueDateSchema } from "./schema";


/**
 * Interface used to represent a user's requirement on a given course step.
 */
export interface DueDate extends Resource<DueDate.Type> {
    /** @inheritDoc */
    attributes: DueDate.Attributes;
    /** @inheritDoc */
    relationships: DueDate.Relationships;
}

/** Augment the {@link DueDate} interface. */
export namespace DueDate {
    export const Type = "due-date" as const;
    export type Type = typeof Type;

    /** Type alias for the types of {@link Recurrence}. */
    export type RecurrenceType = "d" | "w" | "m" | "y";
    /** Type alias for the due-date recurrence. */
    export type Recurrence = `${number}${RecurrenceType}`;

    /** Attributes of a {@link DueDate} */
    export interface Attributes {
        /** The date at which the course is due. */
        date: string;
        /** An optional recurrence for the due-date. */
        recurrence: Recurrence | null;
    }

    /** Relationships of a {@link DueDate} */
    export interface Relationships {
        /** Reference to the course that has the requirement. */
        course: Relationship<Course>;
        /** Reference to the user that has the requirement. */
        user: Relationship<ResourceIdentifier<User.Type>>;
    }

    export const validate: ValidateFunction<DueDate> = compileSync<DueDate>(DueDateSchema.ResourceSchema);


    /** Interface used to update a {@link DueDate}. */
    export interface Update extends Resource<DueDate.Type> {
        /** @inheritDoc */
        attributes?: Update.Attributes;
    }

    /** Augments the {@link Update} interface. */
    export namespace Update {
        export type Attributes = Partial<DueDate.Attributes>;
        export const validate: ValidateFunction<DueDate.Update> =
            compileSync<DueDate.Update>(DueDateSchema.UpdateSchema);
    }


    /** Interface used to create new {@link DueDate}. */
    export interface Create extends CreatableResource<DueDate.Type> {
        /** @inheritDoc */
        attributes: Create.Attributes;
        /** @inheritDoc */
        relationships: DueDate.Relationships;
    }

    /** Augments the {@link Create} interface. */
    export namespace Create {
        export type Attributes = Pick<DueDate.Attributes, "date"> & Pick<Partial<DueDate.Attributes>, "recurrence">;
        export const validate: ValidateFunction<DueDate.Create> =
            compileSync<DueDate.Create>(DueDateSchema.CreateSchema);
    }

    /**
     * Extracts the {@link Date} from a {@link DueDate} object.
     *
     * @param {null} date The date to convert.
     * @return {null} The converted date.
     */
    export function getDate(date: null): null;

    /**
     * Extracts the {@link Date} from a {@link DueDate} object.
     *
     * @param {DueDate} date The date to convert.
     * @return {Date} The converted date.
     */
    export function getDate(date: DueDate): Date;

    /**
     * Extracts the {@link Date} from a {@link DueDate} object.
     *
     * @param {DueDate | null} date The date to convert.
     * @return {Date | null} The converted date.
     */
    export function getDate(date: DueDate | null): Date | null;

    /** Implementation ! */
    export function getDate(date: DueDate | null): Date | null {
        if (date === null) {
            return null;
        }
        return new Date(date.attributes.date);
    }

    /** Extracts the {@link Date} of the last recurrence from a {@link DueDate} object. */
    export function getLastRecurrenceDate(due: Date, recurrence: Recurrence): Date {
        const count = +(recurrence.substring(0, -1));
        switch(recurrence.substring(-1)) {
        case "d":
            due.setDate(due.getDate() - count);
            break;
        case "w":
            due.setDate(due.getDate() - (count * 7));
            break;
        case "m":
            due.setMonth(due.getMonth() - count);
            break;
        case "y":
            due.setFullYear(due.getFullYear() - count);
            break;
        }
        return due;
    }
}
