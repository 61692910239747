/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

/**
 * Interface used to define the client SSL configuration of the given target.
 * Used only in node environments.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
interface XhrClientSslConfig {
    /** Either the path to, or the value of the ssl certificate. */
    certificate: string;
    /** Either the path to, or the value of the ssl key. */
    key: string;
    /** Either the path to, or the value of the ssl key's passphrase. */
    passphrase?: string;
}

/**
 * Interface used to define the SSL configuration of a given target.
 * Used only in node environments.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
interface XhrSslConfig {
    /** Either the path to, or the value of the server ssl certificate. */
    certificate: string;
    /** Optional configuration of the ssl client certificate. */
    client?: XhrClientSslConfig;
}

/**
 * Interface used to describe a target, as provided via the configuration file.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 2
 */
export interface XhrTarget {
    /** Host name of the given target. */
    hostname: string;
    /**
     * Port of the given target.
     * @default 443
     */
    port?: number;

    /**
     * SSL configuration of the target.
     * @default true
     */
    ssl?: boolean | XhrSslConfig;

    /** Root path of the target. */
    root?: string;

    /** List of tags applied to this target. */
    tags?: string[];
}

/**
 * Interface used to describe the configuration of the Xhr package.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 1
 */
export interface XhrConfig { xhr?: { [targetName: string]: XhrTarget; } }
