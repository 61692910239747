/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useContext } from "react";
// Import the admin component.
import { Admin } from "@andromeda/admin";
// Import the login context.
import { LoginContext } from "@andromeda/login";


/** Wrapper component used to check if the user can see the {@link Admin} component. */
export default function AdminWrapper(): ReactElement {
    // Load the login context.
    const { isAdmin } = useContext(LoginContext);

    // Render the component.
    if (!isAdmin) {
        return <h1>vous n'avez pas les droits de voir cet écran !</h1>;
    }
    return <Admin />;
}
