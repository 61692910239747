/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


// Import React.
import { ReactElement } from "react";
// Import React-Bootstrap.
import { Modal } from "react-bootstrap";

// Import the searchbar.
import SearchBar from "../../main-menu/component/searchbar";

// Import the icons.
import { ReactComponent as ZaqTiv } from "@andromeda/assets/images/zaqtiv.svg";
// Import the css.
import css from "./modal.module.scss";


/** Component used to render the search modal. */
export default function SearchModal(props: SearchModalProps): ReactElement {
    return <Modal
        show={props.show}
        backdrop
        backdropClassName={css["backdrop"]}
        className={css["modal"]}
        contentClassName={css["content"]}
        dialogClassName={css["dialog"]}
        onExited={props.onExited}
        onHide={props.onClose}
    >
        <ZaqTiv className={css["logo"]} />
        <SearchBar
            className={css["searchbar"]}
            onSubmit={props.onSubmit}
            placeholder="Rechercher ..."
            size="lg"
        />
    </Modal>;
}

/** Props passed down to the {@link SearchModal} component. */
export interface SearchModalProps {
    /** Flag set if the modal should be visible. */
    show: boolean;

    /**
     * Callback invoked when the user submits a search.
     *
     * @param {string} text The text that was submitted in the search.
     */
    onSubmit(text: string): void;

    /** Callback invoked when the modal is fully closed. */
    onExited(): void;

    /** Callback invoked when the modal requests to be closed. */
    onClose(): void;
}
