/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the css.
import css from "./new-menu.module.scss";
import { Carousel } from "../carousel";
import questionMark from "@andromeda/assets/images/question-mark.svg";
import { Col } from "react-bootstrap";

export function Explorer(): React.ReactElement {
    const items: ExplorerItem[] = React.useMemo(() => [
        {id: "1", name: "Item 1", categories: ["category 1", "category 2"]},
        {id: "2", name: "Item 2", categories: ["category 2"]},
        {id: "3", name: "Item 3", categories: ["category 3"]},
    ], []);

    const itemsByCategory = React.useMemo(()=>{
        const itemsByCategory = new Map<string, ExplorerItem[]>();
        items.forEach(item => {
            if(item.categories) {
                item.categories.forEach(category => {
                    if (!itemsByCategory.has(category)) {
                        itemsByCategory.set(category, []);
                    }
                    itemsByCategory.get(category)?.push(item);
                });
            }
        });
        return itemsByCategory;
    },[items]);

    const carousels = React.useMemo(()=>{
        const carousels: React.ReactElement[] = [];
        for (const [category, items] of itemsByCategory.entries()) {
            carousels.push(<Col xs={10} md={5} xl={3} className={css["carousel"]}>
                <h2 className={css["carousel__category"]}>{category}</h2>
                <Carousel elements={items.map(
                    item => ({
                        element: <img src={questionMark} alt="unknown" />,
                        title: item.name
                    })
                )} showDots />
            </Col>);
        }
        return carousels;
    },[itemsByCategory]);

    return <div className={css["explorer"]}>
        {carousels}
    </div>
}

interface ExplorerItem {
    id: string;
    name: string;
    onSelected?: () => void;
    categories?: string[];
    icon?: string;
}
