/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the modal component.
import { Modal, Button } from "react-bootstrap";
// Import the confirmation hook.
import { ConfirmBodyProps, useConfirm } from "@andromeda/components";
// Import the user store.
import { useResourceDispatch, UserStore } from "@andromeda/store";
// Import the user resource.
import { User } from "@andromeda/resources";

// Import the icons.
import warning from "@andromeda/assets/images/warning.svg";
import bin from "@andromeda/assets/images/bin.svg";
import moon from "@andromeda/assets/images/moon.svg";
import close from "@andromeda/assets/images/close.svg";
// Import the css.
import css from "./delete-user.module.scss";

/** Hook used to show the deletion confirmation modal. */
export function useDeleteUserConfirm(): (
    user: string
) => Promise<DeleteResult> {
    // Get the confirmation callback.
    const confirm = useConfirm({
        body: DeleteModalBody,
        title: "Attention",
        cancel: "cancel",
        centered: true,
        size: "lg",
    });

    // Method used to disable a user.
    const dispatch = useResourceDispatch();
    const disable = React.useCallback(
        function disableUser(user: string): void {
            dispatch(
                UserStore.generator.update({
                    type: User.Type,
                    id: user,
                    meta: { disabled: true },
                })
            ).catch(console.error);
        },
        [dispatch]
    );
    // Method used to delete a user.
    const deleteUser = React.useCallback(
        function deleteUser(user: string): void {
            dispatch(UserStore.generator.delete(user, true)).catch(
                console.error
            );
        },
        [dispatch]
    );

    // Return the method used to render the confirmation modal.
    return React.useCallback(
        function showDeleteConfirmationModal(
            user: string
        ): Promise<DeleteResult> {
            return confirm({ user }).then(result => {
                switch (result) {
                    case "disable":
                        disable(user);
                        break;
                    case "delete":
                        deleteUser(user);
                        break;
                }
                return result;
            });
        },
        [confirm, deleteUser, disable]
    );
}

/** Body of the delete modal. */
function DeleteModalBody(props: DeleteModalBodyProps): React.ReactElement {
    const user = UserStore.useSelector(state =>
        state.resources.find(user => user.id === props.user)
    );
    const isDisabled = user?.meta.disabled === true;

    return (
        <Modal.Body className={css["delete-user"]}>
            <div
                className={`${css["delete-user__row"]} flex-column flex-md-row`}
            >
                <div className={css["delete-user__warning"]}>
                    <img src={warning} alt="warning" />
                </div>
                <div className="d-flex flex-column" style={{ flex: "75 75 0" }}>
                    <p className={css["delete-user__text"]}>
                        Vous vous apprêtez à supprimer ce membre. Ceci
                        entraînera la perte de toutes leur données de
                        progression.
                    </p>
                    <p className={css["delete-user__text"]}>
                        Si vous voulez clotûrer ce compte sans supprimer ses
                        données, vous pouvez simplement le désactiver en le
                        passant en veille.
                    </p>
                </div>
            </div>
            <div className={css["delete-user__row"]}>
                <Button
                    variant="danger"
                    className={`${css["delete-user__button"]} ${css["delete-user__button--delete"]}`}
                    onClick={() => props.resolve("delete")}
                >
                    <img src={bin} alt="bin" />
                    <p>supprimer</p>
                </Button>
                {!isDisabled ? (
                    <Button
                        variant="primary"
                        onClick={() => props.resolve("disable")}
                        className={css["delete-user__button"]}
                    >
                        <img src={moon} alt="moon" />
                        <p className="d-none d-md-inline">mise en veille</p>
                        <p className="d-inline d-md-none">veille</p>
                    </Button>
                ) : null}
                <Button
                    variant="primary"
                    onClick={() => props.resolve("cancel")}
                    className={css["delete-user__button"]}
                >
                    <img src={close} alt="close" />
                    <p>annuler</p>
                </Button>
            </div>
        </Modal.Body>
    );
}

/** Props passed down to the {@link DeleteModalBody} component. */
interface DeleteModalBodyProps extends ConfirmBodyProps<DeleteResult> {
    /** Identifier of the user to delete. */
    user: string;
}

/** Union of all the possible results for the deletion modal. */
type DeleteResult = "cancel" | "disable" | "delete";
