/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useMemo, Context, useContext, useCallback, forwardRef, Ref } from "react";

// Import the button stylings.
import css from "./index.module.scss";

// Import the base button props.
import Button, { ButtonProps } from "../button";
// Import the context.
import { DefaultToggleableButtonContext, ToggleableButtonContext, useIsInToggleableButtonContext } from "./context";
// Import the provider.
import ToggleableButtonContextProvider, { withToggleableButtonContextProvider } from "./provider";


// Re-export the context tools.
export { DefaultToggleableButtonContext, makeToggleableButtonContext, useIsInToggleableButtonContext } from "./context";
export type { ToggleableButtonContext } from "./context";
// Re-export the context provider.
export { ToggleableButtonContextProvider, withToggleableButtonContextProvider };
export type { ToggleableButtonContextProviderProps } from "./provider";

/** Forward the reference to the DOM button. */
export default forwardRef(ToggleableButton);

/**
 * Component used to render a toggleable button.
 * The button will try to link to a {@link ToggleableButtonContext} instance and update it.
 */
function ToggleableButton(props: ToggleableButtonProps, ref: Ref<HTMLButtonElement>): ReactElement {
    // Check if the context was mounted.
    const isMounted = useIsInToggleableButtonContext(props.context);
    // Load the state of the button.
    const { state, toggle } = useContext(props.context ?? DefaultToggleableButtonContext);

    // Load the class name from the provided props.
    const className = useMemo(function getClassName(): string {
        let className = css["button"];
        if (state) {
            className += " " + css["button--active"];
        }
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }, [state, props.className]);

    // Create a custom callback that does not fail if the context is not mounted.
    const onClick = useCallback(function onToggleableButtonClick(): void {
        if (!isMounted) {
            console.warn("Tried to toggle a non-existent context !");
        } else {
            toggle();
        }
    }, [isMounted, toggle])

    // Remove the context from the props.
    const buttonProps = useMemo(function removeUnnecessaryButtonProps(): ButtonProps {
        const copy = { ...props }; delete copy.context; return copy;
    }, [props]);

    // Render the button.
    return <Button {...buttonProps} ref={ref} className={className} onClick={onClick} />;
}

/** Props passed down to the {@link ToggleableButtonProps} component. */
export interface ToggleableButtonProps extends ButtonProps {
    /**
     * Context used for the button.
     *
     * @default DefaultToggleableButtonContext
     */
    context?: Context<ToggleableButtonContext>;
}
