/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */
/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API validators.
import { WritableMessageHelper, jsonSanitiser } from "@andromeda/json-api";

// Import the interfaces.
import { XhrEncodedBodyType, XhrFetchOptions, PreparedXhrRequest } from "../..";
// Import the target resolution helpers.
import { resolveURL } from "../config";
// Import the error classes.
import { TooManyRetriesError } from "../../errors";
// Import the header proxy helper.
import { XhrHeaderProxyHandler } from "../proxy/header";
// Import the auth token helper.
import { injectAuthHeader } from "../auth";

// Import the logging tool.
import debug from "debug";
const log = debug("xhr:services:request");


/**
 * Prepares a new {@link PreparedXhrRequest} object from the given parameters.
 * This object can then be passed on to an implementation-specific request runner to actually run the request.
 *
 * @param {string | URL} target The target of the request.
 * @param {XhrFetchOptions} config The configuration fo the request.
 * @returns {Promise<PreparedXhrRequest>} A promise that resolves with the prepared xhr request.
 * @throws {InvalidTargetError} If the provided target could not be resolved.
 */
export async function prepareRequest(target: string | URL, config: XhrFetchOptions): Promise<PreparedXhrRequest> {
    log("Preparing a new request for target \"%s\"...", target);
    const url = await resolveURL(target, config.path);

    // Apply the search parameters.
    if (typeof config.searchParameters !== "undefined") {
        for (const [ name, value ] of Object.entries(config.searchParameters)) {
            if (typeof value === "string") url.searchParams.set(name, value);
            else if (typeof value === "number") url.searchParams.set(name, value.toString(10));
            else if (value) url.searchParams.set(name, "");
        }
    }

    log("Resolved url is: %s", url.toString());

    // Build the header object.
    const headers = XhrHeaderProxyHandler.apply(config.headers);
    injectAuthHeader(headers, config);

    // Clear any undefined value.
    function cleanup(item: object): object {
        // Loop through the object.
        for (const [ key, value ] of Object.entries(item)) {
            // Cast the item to an object with a [key].
            const castItem = item as { [k: typeof key]: typeof value };
            if (typeof value === "undefined") {
                delete castItem[key];
            } else if (typeof value === "object" && value !== null) {
                // Recurse.
                castItem[key] = cleanup(value);
            }
        }
        return item;
    }
    if (typeof config.body === "object") cleanup(config.body);

    // Convert the body to an encoded format.
    let body: XhrEncodedBodyType | undefined;
    if (config.body instanceof WritableMessageHelper) {
        log("Encoding request body as a JSON:API message.");
        body = JSON.stringify(jsonSanitiser(config.body.message));
        headers["Content-Type"] = "application/vnd.api+json";
    } else {
        body = config.body;
    }

    // Return the partial request object.
    return { url, target: typeof target === "string" ? target : null, options: config, body, headers };
}

/** Helper used to compute an exponential backoff. */
export async function exponentialBackoff<R, A extends unknown[] = unknown[]>(
    step: number, callback: (step: number, ...args: A) => Promise<R>, ...args: A
): Promise<R> {
    if (step >= MAX_RETRIES) throw new TooManyRetriesError(`Request failed after ${step} attempts.`);

    let delay = Math.min(MAX_RETRY_DELAY, Math.pow(2, step));
    delay = delay * (1 - JITTER_PROPORTION) + Math.random() * JITTER_PROPORTION;

    log("Retrying after %s seconds", Math.floor(delay * 100) / 100);
    await new Promise<void>(r => setTimeout(r, delay * 1000));
    return callback(step + 1, ...args);
}

/** Maximum number of retries. */
const MAX_RETRIES = 10;
/** Maximum delay before a retry. */
const MAX_RETRY_DELAY = 10 * 60 * 1000;
/** Amount of jitter applied. */
const JITTER_PROPORTION = 0.25;
