/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the redux interfaces.
import { Reducer, combineReducers, Action, AnyAction } from "redux";
// Import the debug tool.
import debug from "debug";
const log = debug("store:registry:reducers");

// Import the registry interface.
import { Registry } from "./interfaces";

/** Type used to describe a reducer source. */
export type ReducerSource<S = unknown, A extends Action = AnyAction> = Reducer<S, A> | { reducer: () => Reducer<S, A> };

/**
 * Mounts a new reducer in the registry.
 * If the reducer is already mounted, increments its counter.
 *
 * @param {Registry} registry The registry to mount the reducer into.
 * @param {string} name The name of the reducer.
 * @param {ReducerSource} source The source of the reducer.
 */
export function mountReducerInRegistry<S = unknown, A extends Action = AnyAction>(
    registry: Registry,
    name: string,
    source: ReducerSource<S, A>
): void {
    log("Preparing to mount %s", name);
    // Check if the reducer exists already.
    const reference = registry.reducerMap.get(name);
    if (typeof reference !== "undefined") {
        log("Reducer %s was already mounted !", name);
        // Increment the reference.
        reference.count++;
        return;
    }

    // Generate a new instance in the map.
    const instance = typeof source === "object" ? source.reducer() : source;
    registry.reducerMap.set(name, { instance: instance as Reducer, count: 1 });
    log("Mounted reducer %s", name);

    // Invoke the mount event.
    registry.eventTarget.dispatchEvent("before:mount:reducer", name)
        .then(() => registry.eventTarget.dispatchEvent("mount:reducer", name))
        .then(() => registry.eventTarget.dispatchEvent("after:mount:reducer", name));
}

/**
 * Unmounts a reducer from the registry.
 * If all references to the reducer are removed, removes the reducer itself.
 *
 * @param {Registry} registry The registry to mount the reducer into.
 * @param {string} name The name of the reducer.
 */
export function unmountReducerFromRegistry(
    registry: Registry,
    name: string
): void {
    // Check if the reducer exists already.
    const reference = registry.reducerMap.get(name);
    if (typeof reference !== "undefined") {
        // Decrement the reference.
        reference.count--;

        // If the reference reached zero, remove it.
        if (reference.count <= 0) {
            registry.reducerMap.delete(name);

            // Invoke the unmount event.
            registry.eventTarget.dispatchEvent("before:unmount:reducer", name)
                .then(() => registry.eventTarget.dispatchEvent("unmount:reducer", name))
                .then(() => registry.eventTarget.dispatchEvent("after:unmount:reducer", name));
        }
    }
}

/** Helper method used to combine all the reducers of a registry. */
export function combineRegistryReducer(registry: Registry): Reducer {
    log("Rebuilding the registry reducer ...");
    // If the map is empty, return a stub reducer.
    if (registry.reducerMap.size === 0) return () => ({});

    // Map all the reducers into a { name: reducer } map.
    const mapped: Record<string, Reducer> = {};
    const iterator = registry.reducerMap.entries();
    let item: ReturnType<typeof iterator["next"]> = iterator.next();
    while (!item.done) {
        mapped[item.value[0]] = item.value[1].instance;
        item = iterator.next();
    }
    return combineReducers(mapped);
}
