/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useState } from "react";
// Import React-Popper.
import { usePopper } from "react-popper";
// Import the PopperJS types.
import type { Placement } from "@popperjs/core";

// Import the link list component.
import ShareLinkList from "./list";
import CreateShareLinkButton from "./create";

// Import the css.
import css from "./share.module.scss";


/** Component used to render the share menu of the current {@link ZaqWiki}. */
export default function Share(props: ShareProps): ReactElement | null {
    // Stores a reference to the popper element.
    const [popper, setPopper] = useState<HTMLDivElement | null>(null);
    const [arrow, setArrow] = useState<HTMLDivElement | null>(null);

    // Get the properties from popper.
    const { attributes, styles } = usePopper(
        props.container,
        popper,
        {
            modifiers: [
                { name: "arrow", options: { element: arrow } },
                { name: "offset", options: { offset: [-8, 16] }},
                { name: "preventOverflow", options: { padding: { top: 64, right: 8, left: 8 } } }
            ],
            placement: props.placement
        }
    );

    // Render the component.
    if (!props.show) {
        return null;
    }
    return <div className={css["container"]} style={styles["popper"]} {...attributes["popper"]} ref={setPopper}>
        <h1 className={css["title"]}>Liens de partage</h1>
        <ShareLinkList id={props.id} />
        <CreateShareLinkButton id={props.id} />
        <div className={css["arrow"]} style={styles["arrow"]} ref={setArrow} />
    </div>;
}

/** Props passed down to the {@link Share} menu. */
export interface ShareProps {
    /** Reference to the container that this item will be placed relative to. */
    container: HTMLElement | null;
    /** Flag set if the element should be visible. */
    show: boolean;
    /** Identifier of the current {@link ZaqWiki}. */
    id: string;
    /** Placement of the menu relative to its parent. */
    placement?: Placement;
    /** Optional class name added to the popper element. */
    className?: string;

    /** Callback invoked when the share container is clicked outside of. */
    onClose?: () => void;
}
