/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";
// Import the store.
import { useCourses, useUsers } from "@andromeda/store";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";

// Import the selector.
import Select from "../../select";
// Import the widget mode.
import type { TrainingWidgetMode } from "./index";

// Import the css.
import css from "./selector.module.scss";


/** Component used to render the selector of the administration widgets. */
export default function TrainingSelector(props: TrainingSelectorProps): ReactElement | null {
    // Download the list of user and trainings.
    const organisation = useContext(LoginContext).organisations.current.id;
    const training = useCourses(organisation);
    const users = useUsers(organisation);

    // If this is not an admin mode, render nothing.
    if (props.mode === "done" || props.mode === "assigned") {
        return null;
    }

    // Build the training select.
    if (props.mode === "by-training" && training.isSuccess) {
        return <Select
            options={training.data.map(training => ({ name: training.attributes.name, value: training.id }))}
            value={props.training}
            onChange={props.setTraining}
        />;
    }

    // Build the user select.
    if (props.mode === "by-user" && users.isSuccess) {
        return <Select
            options={users.data.map(user => ({ name: user.attributes.givenName, value: user.id }))}
            value={props.user}
            onChange={props.setUser}
        />;
    }

    // Render a loader.
    return <Loader className={css["loader"]} containerClassName={css["loader__container"]} />;
}

/** Props passed down to the {@link TrainingSelector} component. */
export interface TrainingSelectorProps {
    /** The current widget mode. */
    mode: TrainingWidgetMode;

    /** The identifier of the selected training. */
    training: string | undefined;
    /** The identifier of the selected user. */
    user: string | undefined;

    /**
     * Callback used to update the selected training.
     *
     * @param {string} training The newly selected training.
     */
    setTraining(training: string): void;

    /**
     * Callback used to update the selected user.
     *
     * @param {string} user The newly selected user.
     */
    setUser(user: string): void;
}
