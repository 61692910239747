/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the metadata interface.
import { Metadata } from "./meta";


/** Interface used to represent a bare resource identifier. */
export interface ResourceIdentifier<
    Type extends string = string,
    Meta extends Metadata = Metadata
> {
    /** The type of the resource. */
    type: Type;
    /** Identifier of the resource element. */
    id: string;

    /** Optional metadata for the resource identifier. */
    meta?: Meta;
}


/** Internal helper for {@link InferResourceIdentifier}. */
type InferSingleResourceIdentifier<T extends ResourceIdentifier> = Pick<T, "type" | "id" | "meta">;
/** Internal helper for {@link InferResourceIdentifier}. */
type InferNullableResourceIdentifier<T extends ResourceIdentifier | null> =
    T extends ResourceIdentifier ? InferSingleResourceIdentifier<T> : null;

/**
 * Infers the resource identifier for the provided resource object.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type InferResourceIdentifier<T extends ResourceIdentifier[] | ResourceIdentifier | null> =
    T extends ResourceIdentifier[] ? InferSingleResourceIdentifier<T[number]>[] :
    T extends ResourceIdentifier | null ? InferNullableResourceIdentifier<T> : never
