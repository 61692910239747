/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the redux thunk interface.
import { ThunkDispatch } from "redux-thunk";
// Import the CRUD tuple and promisable validate function.
import {
    CRUDTuple,
    PromisableValidateFunction
} from "@andromeda/resource-helper";

// Import the resource store reducer.
import { WithResourceStore, ResourceStore } from "./reducer";
// Import the action generator helper.
import { buildActionGenerator, ActionGenerator } from "./generator";
// Import the CRUD action helper.
import { CRUDAction } from "./actions";
// Import the hook builders.
import {
    Comparator,
    Selector,
    buildResourceReducerHOC,
    buildUseResourceSelector,
    buildUseResourceDispatch
} from "./hooks";


/**
 * Interface used to provide an easy way of manipulating resource stores.
 *
 * @template {CRUDTuple} R
 */
export interface ResourceStoreHelper<R extends CRUDTuple> {
    /**
     * Helper method used to generate a new HOC for the given resource store.
     *
     * @template P
     * @param {React.ComponentType<P>} component The component to wrap.
     * @returns {React.ComponentType<P>} The wrapped component.
     */
    withReducer<P extends object = object>(component: React.ComponentType<P>): React.ComponentType<P>;

    /**
     * Hook used to get a dispatch for the given resource.
     *
     * @returns {ThunkDispatch<WithResourceStore<R[0]>, never, CRUDAction<R>>} The dispatch method.
     */
    useDispatch(): ThunkDispatch<WithResourceStore<R[0]>, never, CRUDAction<R>>;

    /**
     * Hook used to get a selector for the entire store.
     *
     * @returns {ResourceStore<R[0]>} The state of the resource store.
     */
    useSelector(): ResourceStore<R[0]>;

    /**
     * Hook used to get a selector for the given resource.
     *
     * @template R
     * @template Return
     * @param {Selector<R[0], Return>} selector A selector used to select a property from the store.
     * @param {Comparator<Return>} comparator A method used to check if the returned value has changed.
     * @returns {Return} The value returned by {@link selector}.
     */
    useSelector<Return>(selector: Selector<R[0], Return>, comparator?: Comparator<Return>): Return;

    /** The generator that can be used to dispatch CRUD actions to the store. */
    readonly generator: Readonly<ActionGenerator<R>>;
}

/**
 * Helper used to generate the {@link ResourceStoreHelper} instance.
 *
 * @template {CRUDTuple} R
 * @param {R[0]["type"]} name The name of the handled resource.
 * @param {PromisableValidateFunction<R[0]>} validator The function used to validate the resource.
 * @returns {ResourceStoreHelper<R>} A new resource store helper instance.
 */
export function buildResourceStore<R extends CRUDTuple>(
    name: R[0]["type"],
    validator: PromisableValidateFunction<R[0]>
): ResourceStoreHelper<R> {
    return {
        withReducer: buildResourceReducerHOC(name),
        generator: buildActionGenerator<R>(name, validator),
        useSelector: buildUseResourceSelector(name),
        useDispatch: buildUseResourceDispatch()
    };
}

/** Helper used to wrap a component with multiple stores. */
export function withStores<P extends object>(
    component: React.ComponentType<P>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...stores: ResourceStoreHelper<[any, any, any]>[]
): React.ComponentType<P> {
    for (const store of stores) {
        component = store.withReducer(component);
    }
    return component;
}

// Re-export the saving hook.
export { useIsSaving } from "./hooks";
// Re-export the store interface.
export type { ResourceStore, WithResourceStore } from "./reducer";
export type { CRUDAction } from "./actions";
