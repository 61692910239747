/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API interfaces.
import {
    Resource,
    Relationship,
    ResourceIdentifier,
    MessageHelper,
    MultipleMessageHelper,
    SingleMessageHelper,
} from "@andromeda/json-api";

// Import the promisable validate function.
import { PromisableValidateFunction, ReadOptions } from "../crud";
// Import the invalid source error class.
import { ReadOneOptions } from "../crud";
// Import the error class.
import { InvalidRelationshipError } from "../../errors";

// Import the sub methods.
import { readBySelfLink, WithSelfLink } from "./by-self-link";
import { readByIdentifier } from "./by-id";

// Helper type used to cast "readByIdentifier" to its implementation signature.
type ReadByIdInvokable<R extends Resource> = (
    identifier: ResourceIdentifier<R["type"]>,
    validator?: PromisableValidateFunction<R> | ReadOneOptions,
    options?: ReadOneOptions
) => Promise<SingleMessageHelper<R>>;
// Helper type used to cast "readBySelfLink" to its implementation signature.
type ReadByLinkInvokable<R extends Resource> = (
    source: WithSelfLink,
    type?: R["type"] | PromisableValidateFunction<R> | ReadOptions,
    validator?: PromisableValidateFunction<R> | ReadOneOptions,
    options?: ReadOneOptions
) => Promise<MessageHelper<R>>;

/**
 * Method used to read the resources from a given relationship.
 *
 * @param {Relationship} source The source relationship.
 * @param {ReadOptions | undefined} [readOptions=undefined] The options of the read operation.
 * @returns {Promise<SingleMessage<Resource>> | MultipleMessage<Resource>>>}
 * A promise that resolves with the parsed message.
 */
export function readByRelationship(
    source: Relationship,
    readOptions?: ReadOptions
): Promise<MessageHelper>;

/**
 * Method used to read a resource from a given relationship.
 *
 * @template {string} T
 * The type of the resource to read.
 * @param {Relationship} source The source relationship.
 * @param {T} type The type of the read resource.
 * @param {ReadOneOptions | ReadManyOptions | undefined} [readOptions=undefined] The options of the read operation.
 * @returns {Promise<MessageHelper<Resource<T>>>}
 * A promise that resolves with the parsed message.
 */
export function readByRelationship<T extends string>(
    source: Relationship,
    type: T,
    readOptions?: ReadOptions
): Promise<
    SingleMessageHelper<Resource<T>> | MultipleMessageHelper<Resource<T>>
>;

/**
 * Method used to read a resource from a given link.
 * Validates the received resource with the given validator.
 *
 * @template {Resource} R
 * The resource to read.
 * @param {Relationship} source The source relationship.
 * @param {R["type"]} type The type of the read resource.
 * @param {PromisableValidateFunction<R>} validator The validator for the resource.
 * @param {ReadOneOptions | undefined} [readOptions=undefined] The options of the read operation.
 * @returns {Promise<MessageHelper<R>>} A promise that resolves with the parsed message.
 */
export function readByRelationship<R extends Resource>(
    source: Relationship,
    type: R["type"],
    validator: PromisableValidateFunction<R>,
    readOptions?: ReadOptions
): Promise<SingleMessageHelper<R> | MultipleMessageHelper<R>>;

/** Implementation ! */
export async function readByRelationship<R extends Resource>(
    source: Relationship,
    typeOrValidatorOrOptions?:
        | R["type"]
        | PromisableValidateFunction<R>
        | ReadOptions,
    validatorOrOptions?: PromisableValidateFunction<R> | ReadOptions,
    readOptions?: ReadOptions
): Promise<SingleMessageHelper<R> | MultipleMessageHelper<R>> {
    // Check if the relationship has a self link.
    if (
        typeof source.links !== "undefined" &&
        typeof source.links.self !== "undefined"
    ) {
        // Read the links.
        return (readBySelfLink as ReadByLinkInvokable<R>)(
            source as WithSelfLink,
            typeOrValidatorOrOptions,
            validatorOrOptions,
            readOptions
        );
    } else if (typeof source.data !== "undefined" && source.data !== null) {
        const validator =
            typeof typeOrValidatorOrOptions === "string"
                ? validatorOrOptions
                : typeOrValidatorOrOptions;
        const options: ReadOptions | undefined =
            typeof validator !== "undefined"
                ? "then" in validator || typeof validator === "function"
                    ? readOptions
                    : validator
                : undefined;
        // Read the identifier.
        if (Array.isArray(source.data)) {
            // Read all the resources.
            const messages = await Promise.all(
                source.data.map(data =>
                    (readByIdentifier as ReadByIdInvokable<R>)(
                        data,
                        validator,
                        options
                    )
                )
            );
            return new MultipleMessageHelper<R>(
                messages
                    .filter(
                        (
                            message: SingleMessageHelper<R>
                        ): message is SingleMessageHelper<R> & { data: R } =>
                            message.data !== null
                    )
                    .map((message): R => message.data)
            );
        } else {
            return (readByIdentifier as ReadByIdInvokable<R>)(
                source.data,
                validator,
                options
            );
        }
    } else {
        throw new InvalidRelationshipError();
    }
}
