/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the asset mime types.
import { ImageAssetMimeType, VideoAssetMimeType, AudioAssetMimeType } from "@andromeda/asset-conversion";


/** @returns {true} If the given file is an image file. */
export function isImageFile(file: File): file is File & { type: ImageAssetMimeType } {
    return file.type.startsWith("image/");
}
/** @returns {true} If the given file is a video file. */
export function isVideoFile(file: File): file is File & { type: VideoAssetMimeType } {
    return file.type.startsWith("video/");
}
/** @returns {true} If the given file is an audio file. */
export function isAudioFile(file: File): file is File & { type: AudioAssetMimeType } {
    return file.type.startsWith("audio/");
}
