/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
// Import the confirmation props.
import { ConfirmBodyProps } from "@andromeda/components";

// Import the generator hook.
import { useGenerator } from "../../hooks";
// Import the validation component.
import { Validate } from "../validate";
// Import the subcomponents.
import { Preparation } from "./preparation";

import css from "./index.module.scss";


/** Component used to capture the screen of the user. */
export function VideoCapture(props: VideoCaptureProps): ReactElement {
    // Get the generator tool.
    const generate = useGenerator();

    // Store the media recorder.
    const [recorder, setRecorder] = useState<MediaRecorder>();
    const [preview, setPreview] = useState<HTMLVideoElement | null>(null);

    // Start recording.
    useEffect(function startRecording(): void {
        // Wait for the recorder to be loaded.
        if (!recorder) {
            return;
        }

        // Start the recorder.
        recorder.start();
    }, [recorder]);
    const stop = useCallback(function stopRecorder(): void {
        try {
            recorder?.stop();
        } catch (e: unknown) {
            console.error(e);
        }
        try {
            recorder?.stream.getTracks().forEach(track => track.stop());
        } catch (e: unknown) {
            console.error(e);
        }
    }, [recorder]);

    // Generate the callbacks.
    const cancel = useCallback(function cancelRecording(): void {
        stop();
        props.resolve();
    }, [props, stop]);
    const validate = useMemo(function generateValidateCallback(): (() => void) | undefined {
        if (!recorder) {
            return undefined;
        }
        return function validateRecording(): void {
            if (recorder.state !== "recording") {
                return;
            }

            // Attach a stop listener on the recorder.
            const type = recorder.mimeType;
            const data: Blob[] = [];
            recorder.addEventListener("dataavailable", event => {
                data.push(event.data);
            });

            // Generate the video.
            recorder.addEventListener("stop", () => {
                const video = new File(data, "Capture d'écran", { type });
                generate(video);
                props.resolve();
                setRecorder(undefined);
            }, { once: true });
            stop();
        };
    }, [generate, props, recorder, stop]);

    // Attach the preview stream.
    useEffect(function attachPreviewStream(): (() => void) | void {
        if (!recorder || !preview) {
            return;
        }
        preview.srcObject = recorder.stream;
        preview.volume = 0;

        // Fit the preview in its parent.
        if (preview.parentElement) {
            preview.width = preview.parentElement.clientWidth;
            preview.height = preview.parentElement.clientHeight;
        }

        if (validate) {
            recorder.addEventListener("stop", validate);
            recorder.stream.getTracks().forEach(track => track.addEventListener("ended", validate));
        }
        return () => preview.srcObject = null;
    }, [recorder, preview, validate]);


    // Render the component.
    return <>
        <div className={css["container"]}>
            {recorder ? <video ref={setPreview} autoPlay playsInline /> :
                <Preparation source={props.source} start={setRecorder} />}
        </div>
        <Validate cancel={cancel} validate={validate} />
    </>;
}

/** Props passed down to the {@link VideoCapture} component. */
export interface VideoCaptureProps extends ConfirmBodyProps<void> {
    /** The feed that should be captured by the modal. */
    source: "screen" | "video";
}
