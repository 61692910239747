/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


import { ReactElement, ReactNode, useMemo } from "react";
import { Option } from "../index";
import { ReactComponent as Checkmark } from "@andromeda/assets/images/checkmark-colored.svg";
import css from "./list.module.scss";
import { normalise } from "@andromeda/tools";


/** Component used to render the list of options. */
export default function PopperList<T>(props: PopperListProps<T>): ReactElement {
    // Render all the options.
    const options = useMemo(function renderOptionList(): ReactNode {
        // Filter out any element that do not have the filter text.
        const list = props.options.filter(function isFiltered(item: Option<T>): boolean {
            // Check if the filter has any value.
            if (props.filter.length < 1) {
                return true;
            }

            // Check if the name matches the filter.
            return normalise(item.name).indexOf(normalise(props.filter)) >= 0;
        });

        // If the list is empty, return a sad face.
        if (list.length < 1) {
            return <li className={css["no-result"]}>Aucun résultat ...</li>;
        }

        // Sort the list in alphabetical order.
        list.sort(function compare(a: Option<T>, b: Option<T>): number {
            return normalise(a.name).localeCompare(normalise(b.name));
        });


        // Render the list.
        return list.map(function renderOption(option: Option<T>, index: number): ReactNode {
            return <li
                key={index}
                className={css["item"]}
                role="button"
                onClick={() => props.onChange(option.value)}
                aria-disabled={props.value === option.value}
            >
                <Checkmark className={css["item__icon"]} />
                <span className={css["item__text"]} children={option.name} />
            </li>;
        });
    }, [props]);

    return <ul className={css["list"]} children={options} />;
}

export interface PopperListProps<T> {
    options: ReadonlyArray<Option<T>>;
    value: T;
    filter: string;

    onChange(value: T): void;
}
