/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import {
    ChangeEvent,
    ComponentType,
    createContext,
    createElement,
    ReactElement,
    ReactNode, useCallback,
    useEffect, useMemo,
    useState
} from "react";
// Import React-Bootstrap.
import { Form, Modal } from "react-bootstrap";
// Import the stores.
import { CourseStore, DueDateStore, useResourceDispatch } from "@andromeda/store";

// Import the icons.
import { ReactComponent as Close } from "@andromeda/assets/svg/close.svg";
import { ReactComponent as Add } from "@andromeda/assets/svg/add.svg";
// Import the css.
import css from "./due-date-modal.module.scss";
import { useNotify } from "@andromeda/components";
import { Course, DueDate, User } from "@andromeda/resources";


/**
 * Wrapper used to provide the {@link DueDateModal} component above the provided component.
 *
 * @template {ComponentType<P>} T
 * @template {object} P
 * @param {T} component The component to wrap.
 * @returns {ComponentType<P & DueDateModalProps>} The wrapper component.
 */
export default function withDueDateModal<T extends ComponentType<P>, P extends object>(component: T) {
    return function DueDateModalWrapper(props: P & DueDateModalProps): ReactElement {
        return <DueDateModal user={props.user} children={createElement(component, props)} />;
    };
}

/** Component used to render the due-date modal. */
function DueDateModal(props: DueDateModalProps): ReactElement {
    // Load the course that should be rendered.
    const [courseId, setCourseId] = useState<string>();
    const course = CourseStore.useSelector(state => state.resources.find(course => course.id === courseId));
    const dueDate = DueDateStore.useSelector(state =>
        state.resources.find(dueDate =>
            dueDate.relationships.course.data.id === courseId && dueDate.relationships.user.data.id === props.user
        )
    );

    // Compute the date into an input-like date.
    const [dueDateValue, setDueDateValue] = useState<Date>();
    useEffect(() => dueDate && setDueDateValue(new Date(dueDate.attributes.date)), [dueDate]);
    const dueDateInput = useMemo(function toInputDateValue(): ReactElement {
        // If the date is set, render it.
        if (typeof dueDateValue !== "undefined") {
            const year = dueDateValue.getFullYear().toString(10);
            const month = (dueDateValue.getMonth() + 1).toString(10).padStart(2, "0");
            const day = dueDateValue.getDate().toString(10).padStart(2, "0");
            const inputValue = `${year}-${month}-${day}`;
            return <>
                <Form.Control
                    className={css["control"]}
                    type="date"
                    value={inputValue}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (e.currentTarget.valueAsDate !== null) {
                            setDueDateValue(e.currentTarget.valueAsDate);
                        }
                    }}
                />
                <button
                    type="button"
                    className={`${css["clear"]} ${css["clear--due-date"]}`}
                    onClick={() => setDueDateValue(undefined)}
                >
                    <Close className={css["clear__icon"]} />
                </button>
            </>;
        } else {
            return <div className={css["set__container"]}>
                <button className={css["set"]} onClick={() => setDueDateValue(new Date())}>
                    <Add className={css["set__icon"]} />
                </button>
            </div>;
        }
    }, [dueDateValue]);

    // Saves the current edited date.
    const dispatch = useResourceDispatch();
    const { error } = useNotify();
    const save = useCallback(function saveDueDate(): void {
        // Check if the date is different.
        const initialDate = dueDate ? new Date(dueDate.attributes.date) : null;
        initialDate?.setHours(0);
        initialDate?.setMinutes(0);
        initialDate?.setSeconds(0);
        initialDate?.setMilliseconds(0);
        const newDate = dueDateValue ? dueDateValue : null;
        newDate?.setHours(0);
        newDate?.setMinutes(0);
        newDate?.setSeconds(0);
        newDate?.setMilliseconds(0);

        // Dispatch an update.
        if (dueDate && newDate === null) {
            dispatch(DueDateStore.generator.delete(dueDate.id)).catch(error);
        } else if (!dueDate && newDate !== null && courseId) {
            dispatch(DueDateStore.generator.create({
                type: DueDate.Type,
                attributes: { date: newDate.toISOString() },
                relationships: {
                    course: { data: { type: Course.Type, id: courseId } },
                    user: { data: { type: User.Type, id: props.user } }
                }
            })).catch(error);
        } else if (dueDate && newDate !== null) {
            dispatch(DueDateStore.generator.update({
                type: DueDate.Type, id: dueDate.id,
                attributes: { date: newDate.toISOString() }
            })).catch(error);
        }
        setCourseId(undefined);
    }, [courseId, dispatch, dueDate, dueDateValue, error, props.user]);

    // Render the modal.
    return <DueDateModalContext.Provider value={{ show: setCourseId }}>
        {props.children}
        <Modal
            show={Boolean(courseId)}
            className={css["modal"]}
            dialogClassName={css["modal__dialog"]}
            contentClassName={css["modal__content"]}
        >
            <Modal.Header className={css["header"]}>
                <Modal.Title className={css["title"]} children="Gestion de l'échéance" />
                <button className={css["close"]} onClick={save}>
                    <Close className={css["close__icon"]} />
                </button>
            </Modal.Header>
            <Modal.Body className={css["body"]}>
                <Form className={css["form"]}>
                    <Form.Group className={`${css["group"]} ${css["group--course-name"]}`}>
                        <Form.Label className={css["label"]}>ZaqTraining :</Form.Label>
                        <Form.Label
                            className={`${css["label"]} ${css["label--course-name"]}`}
                            children={course?.attributes.name}
                        />
                    </Form.Group>
                    <span className={css["separator"]} />
                    <Form.Group className={`${css["group"]} ${css["group--due-date"]}`}>
                        <Form.Label className={css["label"]}>Échéance :</Form.Label>
                        {dueDateInput}
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    </DueDateModalContext.Provider>;
}

export interface DueDateModalProps {
    /** The children that will have access to this modal. */
    children?: ReactNode;
    /** The user that is being watched. */
    user: string;
}

export interface DueDateModalContext {
    show(course: string): void;
}

const DEFAULT_VALUE: DueDateModalContext = {
    show() {
        console.warn("STUB");
    }
};
export const DueDateModalContext = createContext<DueDateModalContext>(DEFAULT_VALUE);
