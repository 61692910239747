/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useEffect, useMemo, useState } from "react";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the file resource.
import { ZaqWikiFile } from "@andromeda/resources";
// Import the conversion status hooks.
import { ConversionInfo, ConversionStatus, ConversionStatusLoadingIcon } from "@andromeda/asset-manager";

// Import the helper hooks.
import { useAssetConversionSubject, useAssetResource } from "./hooks";

// Import all the icons.
import { ReactComponent as PhotoCamera } from "@andromeda/assets/images/photo-camera-outline-colored.svg";
import { ReactComponent as Sound } from "@andromeda/assets/images/sound-outline-colored.svg";
import { ReactComponent as VideoCamera } from "@andromeda/assets/images/video-camera-outline-colored.svg";
import { ReactComponent as Pdf } from "@andromeda/assets/images/pdf-colored.svg";
import { ReactComponent as Title } from "@andromeda/assets/images/t-colored.svg";
import { ReactComponent as Link } from "@andromeda/assets/images/link-colored.svg";
import { ReactComponent as Tuto } from "@andromeda/assets/images/zaq-notice-outline-colored.svg";
import { ReactComponent as QuestionMark } from "@andromeda/assets/images/question-mark-colored.svg";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons/faAlignLeft";


/** Helper hook used to load the icon of the given file. */
export function useIcon(file: ZaqWikiFile, className?: string, open = false): ReactElement {
    // Load the asset.
    const asset = useAssetResource(file);
    const conversion = useAssetConversionSubject(file);

    // Load the icon from the asset.
    const image = useMemo(
        function loadIcon(): ReactElement {
            if (asset) {
                if (asset.attributes.contentType.startsWith("image/")) {
                    return <PhotoCamera className={className} />;
                }
                if (asset.attributes.contentType.startsWith("audio/")) {
                    return <Sound className={className} />;
                }
                if (asset.attributes.contentType.startsWith("video/")) {
                    return <VideoCamera className={className} />;
                }
                if (asset.attributes.contentType === "application/pdf") {
                    return <Pdf className={className} />;
                }
            }
            if (ZaqWikiFile.isSectionFile(file)) {
                return <Title className={className} />;
            }
            if (ZaqWikiFile.isLinkFile(file)) {
                return <Link className={className} />;
            }
            if (ZaqWikiFile.isTutoFile(file)) {
                return <Tuto className={className} />;
            }
            if (ZaqWikiFile.isTextFile(file)) {
                return <FontAwesomeIcon icon={faAlignLeft} className={className} />;
            }
            return <QuestionMark className={className} />;
        },
        [asset, className, file]
    );

    // Extract the status from the conversion.
    const [ status, setStatus ] = useState<ConversionInfo>({ status: ConversionStatus.uninitialized });
    useEffect((): void | (() => void) => {
        if (conversion) {
            const subscription = conversion.subscribe(setStatus);
            return () => subscription.unsubscribe();
        }
    }, [conversion]);

    if (conversion && !open) {
        return <ConversionStatusLoadingIcon status={status} className={className} />;
    } else {
        return image;
    }
}

