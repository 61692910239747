/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import React, { ReactElement, useEffect, useMemo, useState } from "react";
// Import the QR-code generator.
import { QRCodeToDataURLOptions, toDataURL } from "qrcode";
// Import the font awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the icons.
import { faArrowUpRightFromSquare, faQrcode } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./link.module.scss";


/** Component used to render a link module. */
export default function LinkModule(props: LinkModuleProps): ReactElement {
    // Stores the generated QR-code URI.
    const [qrCode, setQRCode] = useState<ReactElement>(<FontAwesomeIcon icon={faQrcode} className={css["link__qr"]} />);

    // Get the ID from the URL if this is a YouTube link.
    const youTubeId = useMemo(function computeYouTubeId(): string | null {
        const YouTubeRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = props.url.match(YouTubeRegex);
        return (match && match[7].length === 11) ? match[7] : null;
    }, [props.url]);

    // Generate the QR-code.
    useEffect(function renderQrCode(): void {
        // Do not generate QR-Codes for youtube urls.
        if (youTubeId) {
            return;
        }

        toDataURL(props.url, options).then(url => {
            setQRCode(<img src={url} alt="qr-code" className={css["link__qr"]} />);
        });
    }, [props.url, youTubeId]);

    // Render the component.
    if (youTubeId) {
        return <div className={css["link"]} children={<YoutubeLinkModule title={props.title} id={youTubeId} />} />;
    }
    return <div className={css["link"]}>
        {qrCode}
        <div className={css["link__container"]}>
            <h3 className={css["link__title"]} children={props.title} />
            <a href={props.url} className={css["link__link"]} rel="noreferrer" children={props.url} />
            <a href={props.url} target="_blank" rel="noreferrer" className={css["link__button"]}>
                <p className={css["link__button__text"]}>ouvrir dans un nouvel onglet</p>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={css["link__button__icon"]} />
            </a>
        </div>
    </div>;
}

/** Props passed down to the {@link LinkModule}. */
export interface LinkModuleProps {
    /** The title of this module. */
    title: string;
    /** The url that this module will link to. */
    url: string;
}

/** Options provided to the QR code generator. */
const options: Readonly<QRCodeToDataURLOptions> = {
    color: { dark: "#5c5c5c", light: "#00000000" },
    type: "image/png",
    margin: 0,
    width: 512
} as const;

/** Simple component used to render a youtube iframe. */
function YoutubeLinkModule(props: YoutubeLinkModuleProps): ReactElement {
    // Stores the size of the element.
    const [size, setSize] = useState<[number, number]>([200, 200]);

    // Build the source url.
    const src = useMemo(function buildSrcURL(): string {
        return `https://youtube.com/embed/${props.id}?autoplay=1&origin=${window.location.origin}`;
    }, [props.id]);

    // Re-computes the size to fit in the parent.
    const [element, setElement] = useState<HTMLIFrameElement | null>(null);
    useEffect(function fitToParent(): void | (() => void) {
        // Re-fit at least once.
        resize();

        // Re-fit when the size changes.
        window.addEventListener("resize", resize, { passive: true });
        return () => window.removeEventListener("resize", resize);

        // Function used to resize to the size of the parent.
        function resize(): void {
            // If the element has no parent, do nothing.
            const parent = element?.parentElement;
            if (!element || !parent) {
                return;
            }

            // Compute the size of the parent.
            const { width: parentWidth, height: parentHeight } = parent.getBoundingClientRect();
            const parentRatio = parentWidth / parentHeight;
            // Compute the size of the child.
            const childRatio = 16 / 9;

            // Update the size of the element.
            if (parentRatio > childRatio) {
                setSize([parentHeight * childRatio, parentHeight]);
            } else {
                setSize([parentWidth, parentWidth / childRatio]);
            }
        }
    }, [element]);

    // Render the component.
    return <iframe
        src={src}
        loading="lazy"
        ref={setElement}
        title={props.title}
        width={Math.floor(size[0])}
        height={Math.floor(size[1])}
        allow="fullscreen"
        allowFullScreen
    />;
}

/** Props passed down to the {@link YoutubeLinkModule} component. */
interface YoutubeLinkModuleProps {
    /** The identifier of the requested video. */
    id: string;
    /** The name of the module. */
    title: string;
}
