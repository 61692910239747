/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";
// Import the legacy loader.
import { useLegacyZaq } from "@andromeda/legacy-zaq";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the store.
import { useExternal, useZaqWiki } from "@andromeda/store";

// Import the css.
import css from "./common.module.scss";


/** Helper component used to render the title of a given item. */
export default function ItemHeader(props: ItemHeaderProps): ReactElement {
    switch (props.item.type) {
    case "zaq":
    case "zaq-tuto":
        return <ZaqTutoHeader id={props.item.id} />;
    case "zaq-wiki":
        return <ZaqWikiHeader id={props.item.id} />;
    case "external":
        return <ExternalHeader id={props.item.id} />;
    }
}

/** Props passed down to the {@link ItemHeader} component. */
export interface ItemHeaderProps {
    /** Identifier of the item to render. */
    item: ResourceIdentifier<"zaq" | "zaq-tuto" | "zaq-wiki" | "external">;
}

/** Props passed down to the helper components. */
interface HeaderProps {
    /** Identifiero f the requested item. */
    id: string;
}

/** Helper component used to render a ZaqTuto header. */
function ZaqTutoHeader(props: HeaderProps): ReactElement {
    // Load the tuto.
    const tuto = useLegacyZaq(props.id);
    if (!tuto.isSuccess) {
        return <Loader transparent className={css["head"]} />;
    }

    // Render the component.
    return <span className={css["head"]} children={tuto.data.name} />;
}

/** Helper component used to render a ZaqWiki header. */
function ZaqWikiHeader(props: HeaderProps): ReactElement {
    // Load the wiki.
    const wiki = useZaqWiki(props.id);
    if (!wiki.isSuccess) {
        return <Loader transparent className={css["head"]} />;
    }

    // Render the component.
    return <span className={css["head"]} children={wiki.data.attributes.name} />;
}

/** Helper component used to render an External header. */
function ExternalHeader(props: HeaderProps): ReactElement {
    // Load the wiki.
    const external = useExternal(props.id);
    if (!external.isSuccess) {
        return <Loader transparent className={css["head"]} />;
    }

    // Render the component.
    return <span className={css["head"]} children={external.data.attributes.name} />;
}
