/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the metadata schema.
import MetadataSchema from "./meta";
// Import the links schema.
import LinksSchema from "./links";
// Import the resource identifier schema.
import ResourceIdentifier from "./resource-identifier";

/** Schema used to define a single relationship. */
const Schema = {
    title: "Relationship",
    description: "Single member of the \"relationships\" interface.",
    type: "object",
    properties: {
        links: LinksSchema,
        meta: MetadataSchema,
        data: {
            oneOf: [{
                title: "Empty linkage",
                description: "Describes a to-one relationship that is currently empty.",
                type: "null"
            }, {
                ...ResourceIdentifier,
                title: "To-one linkage",
                description: "Describes a to-one relationship.",
            }, {
                title: "To-many linkage",
                description: "Describes a to-many relationship.",
                type: "array",
                items: ResourceIdentifier,
                uniqueItems: true
            }]
        }
    },
    additionalProperties: false,
    required: [ "data" ],
    not: {
        anyOf: [
            { required: [ "id" ] },
            { required: [ "type" ] }
        ]
    }
} as const;

// Export the schema.
export default Schema;
