/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the store.
import { useExternal } from "@andromeda/store";
// Import the legacy loader.
import { useLegacyZaq } from "@andromeda/legacy-zaq";
// Import the resources.
import type { Step } from "@andromeda/resources";


/** Component used to render the <option> of a given item. */
export default function ItemOption(props: ItemOptionProps): ReactElement | null {
    switch(props.item.type) {
    case "external":
        return <ExternalOption id={props.item.id} />;
    case "zaq":
        return <ZaqTutoOption id={props.item.id} />;
    default:
        return null;
    }
}

/** Props passed down to the {@link ItemOption} component. */
export interface ItemOptionProps {
    /** The item to render. */
    item: Step.Item;
}

/** Props passed down to the helper components. */
interface ItemHelperProps {
    /** The identifier of the rendered item. */
    id: string;
}

/** Helper component used to render an external option. */
function ExternalOption(props: ItemHelperProps): ReactElement {
    // Load the external.
    const external = useExternal(props.id);

    // Render the option.
    return <option
        value={`external-${props.id}`}
        children={external.isSuccess ? external.data.attributes.name : "Chargement de la formation externe ..."}
    />;
}

/** Helper component used to render a ZaqTuto option. */
function ZaqTutoOption(props: ItemHelperProps): ReactElement {
    // Load the tuto.
    const zaq = useLegacyZaq(props.id);

    // Render the option.
    return <option
        value={`zaq-${props.id}`}
        children={zaq.isSuccess ? zaq.data.name : "Chargement du ZaqTuto+ ..."}
    />;
}
