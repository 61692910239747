/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useEffect, useMemo, useState } from "react";

// Import the training header/footer.
import { TrainingStatusFooter, TrainingStatusHeader } from "../../../training";
// Import the selector.
import Selector, { Option } from "../selector";
// Import the subcomponents.
import TrainingList from "./list";

// Import the css.
import css from "./index.module.scss";
import { useTrainingSelector, useUserSelector } from "../../../../hooks";
import TrainingSelector from "../../../widgets/training/selector";


/** Page subtypes for the training page.  */
export type TrainingPageType = "assigned" | "done" | "by-user" | "by-training";

/** Component used to render the training page. */
export default function TrainingPage(props: TrainingPageProps): ReactElement {
    // Build all the options for the selector.
    const [page, setPage] = useState<TrainingPageType>("assigned");
    const options = useMemo(function getPageOptions(): Option<TrainingPageType>[] {
        if (props.administrator) {
            return [{ name: "Apprenant", value: "by-user" }, { name: "Formation", value: "by-training" }];
        }
        return [{ name: "En cours", value: "assigned" }, { name: "Validées", value: "done" }];
    }, [props.administrator]);

    // Store the selected training and user.
    const [selectedTraining, setSelectedTraining] = useTrainingSelector(page === "by-training");
    const [selectedUser, setSelectedUser] = useUserSelector(page === "by-user");

    // Reset to the first page when the administrator flag changes.
    useEffect(function resetToInitialPage(): void {
        setPage(options[0].value);
    }, [options, props.administrator]);

    // Get the footer mode.
    let footerMode: "validated" | "current" | "both";
    if (props.administrator) {
        footerMode = "both";
    } else if (page === "assigned") {
        footerMode = "current";
    } else {
        footerMode = "validated";
    }

    // Render the component.
    return <div className={css["training-list"]}>
        <Selector<TrainingPageType> options={options} current={page} onChange={setPage} />
        <div className={css["header"]}>
            <div className={css["selector-container"]}>
                <TrainingSelector
                    mode={page}
                    training={selectedTraining}
                    user={selectedUser}
                    setTraining={setSelectedTraining}
                    setUser={setSelectedUser}
                />
            </div>
            <TrainingStatusHeader showUsername={page === "by-training"} />
        </div>
        <TrainingList mode={page} user={selectedUser} training={selectedTraining} />
        <TrainingStatusFooter className={css["footer"]} mode={footerMode} />
    </div>;
}

/** Props passed down to the {@link TrainingPage} component. */
export interface TrainingPageProps {
    /** If set, renders the administrator page. */
    administrator?: boolean;
}
