/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import Redux.
import type { Store } from "redux";
// Import the JSON:API module.
import { Resource } from "@andromeda/json-api";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the components.
import { createRequestDispatch, RequestReducerKey, ResourceStore, ResourceStoreHelper } from "../components";
// Import the CURD action.
import { CRUDThunkAction } from "../components/resource/generator";


type WithThunkDispatch = Store & {
    dispatch(action: CRUDThunkAction<Promise<Resource[]>, [Resource, never, never]>): Promise<Resource[]>;
};

/**
 * Hook used to download a list of resources from the store.
 *
 * @template {Resource} R
 * @param {Store} store The redux store used for the requests.
 * @param {ResourceStoreHelper<[R, never, never]>} resourceStore The store used to query the resource.
 * @param {R["type"]} type The store used to query the resource.
 * @param {(ReadManyOptions & ActionGeneratorOptions<[R, never, never]>) | undefined} options
 * The options passed to the generator.
 * @param {(state: ResourceStore<R>) => R[]} selector
 * The selector used to retrieve the resources from the store.
 * @return {RequestStatus<R[]>} The status of the request.
 */
export async function getResources<R extends Resource>(
    store: Store,
    resourceStore: ResourceStoreHelper<[R, never, never]>,
    type: R["type"],
    options: Parameters<ResourceStoreHelper<[R, never, never]>["generator"]["readMany"]>[0] | undefined,
    selector: (state: ResourceStore<R>) => R[]
): Promise<R[]>;

/** Implementation ! */
export async function getResources(
    store: Store,
    resourceStore: ResourceStoreHelper<[Resource, never, never]>,
    type: string,
    options: Parameters<ResourceStoreHelper<[Resource, never, never]>["generator"]["readMany"]>[0] | undefined,
    selector: (state: ResourceStore<Resource>) => Resource[]
): Promise<Resource[]> {
    // Build the request id.
    const requestId = `${type}/${window.btoa(JSON.stringify(options))}`;
    const { dispatch: resourceDispatch } = store as WithThunkDispatch;

    // Get the status of the request.
    const request: RequestStatus = store.getState()[RequestReducerKey].requests[requestId]
        ?? RequestStatus.uninitialised();

    // Get the resources from the store.
    const resources = selector(store.getState()[type]);

    // If the request was completed, return the resource.
    if (request.isSuccess) {
        return resources;
    } else if (request.isError) {
        throw request.error;
    }

    // If the request was not started, download the resource.
    if (request.isUninitialised) {
        createRequestDispatch(store, requestId)((dispatch) => {
            dispatch(RequestStatus.loading());

            // Download the resource.
            resourceDispatch(resourceStore.generator.readMany(options))
                .then(() => RequestStatus.success())
                .catch(RequestStatus.error)
                .then(dispatch);
        });
    }

    // Subscribe to the store.
    return new Promise<Resource[]>((resolve, reject) => {
        const unsubscribe = store.subscribe(function onChange(): void {
            // Get the state of the request.
            const request = store.getState()[RequestReducerKey].requests[requestId];
            if (request.isSuccess) {
                unsubscribe();

                // Get the resource from the store.
                resolve(selector(store.getState()[type]));
            } else if (request.isError) {
                unsubscribe();
                reject(request.error);
            }
        });
    });
}
