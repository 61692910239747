/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the modal props.
import { ModalProps } from "react-bootstrap";


/** Props shared by the {@link ShowConfirmOptions.header} and {@link ShowConfirmOptions.body} components. */
interface ConfirmChildrenProps<T> {
    /** Method used to resolve the confirmation modal with the provided value. */
    resolve(...value: T extends void ? [] : [ T ]): void;
    /** Method used to reject the modal. */
    reject(error: unknown): void;
}

/** Props that will be passed to the {@link ShowConfirmOptions.body} component. */
export type ConfirmBodyProps<T> = ConfirmChildrenProps<T>;

/** Props that will be passed to the {@link ShowConfirmOptions.header} component. */
export type ConfirmHeaderProps<T> = ConfirmChildrenProps<T>;

/**
 * Options passed to the {@link ConfirmContext.show} method.
 *
 * @borrows {ModalProps.centered} as centered
 * @borrows {ModalProps.fullscreen} as fullscreen
 * @borrows {ModalProps.size} as size
 */
export interface ShowConfirmOptions<T> {
    /** Component used to render the body of the confirmation modal. */
    body: React.ComponentType<ConfirmBodyProps<T>>;
    /** Component used to render the header of the confirmation modal. */
    header: React.ComponentType<ConfirmHeaderProps<T>>;

    /** If true, centers the modal vertically. */
    centered?: ModalProps["centered"];
    /** If true, shows the modal in full screen. */
    fullscreen?: ModalProps["fullscreen"];
    /** Defines the size of the modal. */
    size?: ModalProps["size"];
    /** If true, does not show any background on the modal container. */
    transparent?: boolean;

    /**
     * Value returned when the confirmation modal is canceled.
     * If not set, disables clicking on the backdrop to close the modal.
     */
    cancel?: T;
}

/** Expected state of the confirm context. */
export interface ConfirmContext {
    /** Shows the provided confirm on the screen. */
    show<T>(options: ShowConfirmOptions<T>): Promise<T>;
}

export const ConfirmContext = React.createContext<ConfirmContext>({
    show(): Promise<never> { return Promise.reject("STUB !"); }
});
