/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";

// Import the loader component.
import Loader from "../../../loader";

// Import the css.
import css from "./common.module.scss";


/** Component used to render the loading of the document. */
export default function PDFDocumentLoaderMessage(props: PDFLoaderMessageProps): ReactElement {
    return <Loader
        text="Chargement du fichier PDF ..."
        value={props.progress}
        transparent={props.transparent !== false}
        containerClassName={css["message"]}
        className={css["message__icon"]}
    />;
}

/** Props passed down to the {@link PDFDocumentLoaderMessage} component. */
export interface PDFLoaderMessageProps {
    /** The current progress of the document loading. */
    progress?: number;

    /**
     * Flag set if the background should be transparent.
     *
     * @default true
     */
    transparent?: boolean;
}
