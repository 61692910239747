/*
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the button.
import { Button } from "../../../../index";
// Import the context.
import { PDFActivePageContext, PDFContext } from "../../context";

// Import the icons.
import { faRotateLeft, faRotateRight } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./rotate.module.scss";


/** Component used to render the page selector element of the controller bar.  */
export default function Rotate(): ReactElement {
    // Load the controller context.
    const { pages } = useContext(PDFContext);
    const { activePageIndex } = useContext(PDFActivePageContext);
    const { rotate } = pages[activePageIndex];

    // Render the component.
    return <div className={css["section"]}>
        <Button className={css["button"]} onClick={() => rotate(true)}>
            <FontAwesomeIcon icon={faRotateLeft} className={css["button__icon"]} />
        </Button>

        <Button className={css["button"]} onClick={() => rotate(false)}>
            <FontAwesomeIcon icon={faRotateRight} className={css["button__icon"]} />
        </Button>
    </div>;
}
