/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useMemo } from "react";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the custom hooks.
import { ItemProgress, StepProgress } from "../../../hooks";
// Import the level icon component.
import LevelIcon from "../../level-icon";

// Import the icons.
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
// Import the css.
import css from "./common.module.scss";
import classNames from "classnames";


/** Component used to render a single item's cell. */
export default function ItemCell(props: ItemCellProps): ReactElement {
    // Compute the contents of the cell.
    const contents = useMemo(function renderCellContents(): ReactNode {
        switch (props.item?.type) {
        case "zaq-wiki":
        case "zaq-tuto": {
            if (!props.item.viewed) {
                return null;
            }
            return <>
                <span />
                <FontAwesomeIcon
                    icon={faCheck}
                    className={classNames(css["checkmark"], css["checkmark--validated"])}
                />
            </>;
        }
        case "zaq": {
            const objectiveMet = props.item.validated || (
                props.item.level !== null
                && props.step
                && props.item.level >= props.step.requiredLevel
            );

            // Build the class name of the icon.
            const iconClassName = classNames(
                css["checkmark"],
                { [css["checkmark--objective-met"]]: objectiveMet },
                { [css["checkmark--validated"]]: props.item.validated }
            );

            return <>
                <LevelIcon className={css["level"]} level={props.item.level} />
                <FontAwesomeIcon icon={faCheck} className={iconClassName} />
            </>;
        }
        case "external": {
            const validated = props.item.level !== null && props.step && props.item.level >= props.step.requiredLevel;

            // Build the class name of the icon.
            const iconClassName = classNames(
                css["checkmark"],
                { [css["checkmark--validated"]]: validated }
            );

            return <>
                <LevelIcon className={css["level"]} level={props.item.level} />
                <FontAwesomeIcon icon={faCheck} className={iconClassName} />
            </>;
        }
        default:
            return null;
        }
    }, [props.item, props.step]);

    // Build the class name.
    let className = css["cell"];
    if (typeof props.item === "undefined") {
        className += ` ${css["cell--not-applicable"]}`;
    }
    switch (props.item?.type) {
    case "zaq":
        if (props.item.validated) {
            className += ` ${css["cell--complete"]}`;
        }
        break;
    case "external":
        if (props.item.level !== null && props.step && props.item.level >= props.step.requiredLevel) {
            className += ` ${css["cell--complete"]}`;
        }
        break;
    case "zaq-wiki":
    case "zaq-tuto":
        if (props.item.viewed) {
            className += ` ${css["cell--complete"]}`;
        }
    }

    return <div className={className}>
        {contents}
    </div>;
}

/** Props passed down to the {@link ItemCell} component. */
export interface ItemCellProps {
    /** Progress of the current step. */
    step?: StepProgress;
    /** Progress of the item to render. */
    item?: ItemProgress;
}
