/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the search bar component.
import { createSearchBar, SearchBarSource } from "@andromeda/components";
// Import the resources.
import { Tag } from "@andromeda/resources";

/** Interface used to describe the data of the wiki search bar. */
interface TagSearchBarData {
    /** Tag being described. */
    tag: Tag;
}

// Alias for the tag-specific search bar.
export type TagSearchBarSource = SearchBarSource<TagSearchBarData>;

// Create the search bar.
export const TagSearchBar = createSearchBar<TagSearchBarData>();
