/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement } from "react";
// Import react markdown.
import ReactMarkdown from "react-markdown";
import { ReactMarkdownProps } from "react-markdown/lib/complex-types";
// Import the remark types.
import type { Node, Parent, Literal } from "unist";
// Import the plugins.
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import type { ElementContent, Text } from "react-markdown/lib/ast-to-react";

// Import the github styles.
import "highlight.js/styles/base16/ocean.css";
// Import the css.
import css from "./index.module.scss";


/** Simple component used to render some markdown text. */
export function Markdown(props: Props): ReactElement {
    // Render the component.
    return <ReactMarkdown
        className={props.className}
        children={props.text}

        remarkPlugins={[remarkBreaks, remarkGfm, remarkKeepEmptyLines]}
        rehypePlugins={[[rehypeHighlight, { stylesheet: "github" }]]}
        components={components}
    />;
}

/** Helper plugin used to preserve all empty lines. */
function remarkKeepEmptyLines() {
    return function keepEmptyLines(tree: Parent<Literal<string> | Parent<Literal<string>>>): Node {
        // Loop through the tree.
        let lastLine = 0;
        for (let i = 0; i < tree.children.length; i++) {
            const { position } = tree.children[i];
            if (!position) {
                continue;
            }

            // Inject skipped lines as empty paragraphs.
            for (let j = lastLine + 1; j < position.start.line; j++, i++) {
                const pos = {
                    start: { line: j, column: 1, offset: (position.start.offset ?? 2) - 1 },
                    end: { line: j, column: 1, offset: (position.start.offset ?? 2) - 1 }
                };
                tree.children.splice(j - 1, 0, {
                    type: "paragraph",
                    children: [{ type: "text", value: "", position: pos }],
                    position: pos
                });
            }
            lastLine = position.end.line;
        }

        return tree;
    };
}

/** Props passed to the {@link Markdown} component. */
interface Props {
    /** Text to render. */
    text: string;

    /** Class name to provide to the markdown element. */
    className?: string;
}

/** List of components renderers. */
const components = {
    a: "a",
    h1(props: ReactMarkdownProps): ReactElement {
        return <p children={props.children} className={css["h1"]} />;
    },
    h2(props: ReactMarkdownProps): ReactElement {
        return <p children={props.children} className={css["h2"]} />;
    },
    h3(props: ReactMarkdownProps): ReactElement {
        return <p children={props.children} className={css["h3"]} />;
    },
    h4(props: ReactMarkdownProps): ReactElement {
        return <p children={props.children} className={css["h4"]} />;
    },
    h5(props: ReactMarkdownProps): ReactElement {
        return <p children={props.children} className={css["h5"]} />;
    },
    h6(props: ReactMarkdownProps): ReactElement {
        return <p children={props.children} className={css["h6"]} />;
    },
    p(props: ReactMarkdownProps): ReactElement {
        return <p children={props.children} className={css["p"]} />;
    },
    pre(props: ReactMarkdownProps): ReactElement {
        return <pre children={props.children} className={css["pre"]} />;
    },
    em(props: ReactMarkdownProps): ReactElement {
        // Check if the node should be bold or italicised.
        const className = props.node.children
            .find((child: ElementContent): child is Text => child.type === "text")?.value?.trim();
        return <em children={props.children} className={className ? css[className] : undefined} />;
    },
    strong(props: ReactMarkdownProps): ReactElement {
        return <strong children={props.children} className={css["underline"]} />;
    }
} as const;
