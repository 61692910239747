/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the redux interfaces.
import { Middleware, compose, Dispatch } from "redux";
// Import the debug tool.
import debug from "debug";
// Import the registry interface.
import { Registry } from "./interfaces";


const log = debug("store:registry:middleware");


/**
 * Mounts a new middleware in the registry.
 * If the middleware is already mounted, increments its counter.
 *
 * @param {Registry} registry The registry to mount the reducer into.
 * @param {string} name The name of the middleware.
 * @param {Middleware} source The middleware to mount.
 */
export function mountMiddlewareInRegistry(
    registry: Registry,
    name: string,
    source: Middleware
): void {
    log("Preparing to mount %s", name);
    // Check if the reducer exists already.
    const reference = registry.middlewareMap.get(name);
    if (typeof reference !== "undefined") {
        log("Middleware %s was already mounted !", name);
        // Increment the reference.
        reference.count++;
        return;
    }

    // Generate a new instance in the map.
    const instance: Middleware = source;
    registry.middlewareMap.set(name, { instance, count: 1 });
    log("Mounted middleware %s", name);

    // Invoke the mount event.
    registry.eventTarget.dispatchEvent("before:mount:middleware", name)
        .then(() => registry.eventTarget.dispatchEvent("mount:middleware", name))
        .then(() => registry.eventTarget.dispatchEvent("after:mount:middleware", name));
}

/**
 * Unmounts a middleware from the registry.
 * If all references to the middleware are removed, removes the middleware itself.
 *
 * @param {Registry} registry The registry to mount the reducer into.
 * @param {string} name The name of the middleware.
 */
export function unmountMiddlewareFromRegistry(
    registry: Registry,
    name: string
): void {
    // Check if the reducer exists already.
    const reference = registry.middlewareMap.get(name);
    if (typeof reference !== "undefined") {
        // Decrement the reference.
        reference.count--;

        // If the reference reached zero, remove it.
        if (reference.count <= 0) {
            registry.reducerMap.delete(name);

            // Invoke the unmount event.
            registry.eventTarget.dispatchEvent("before:unmount:middleware", name)
                .then(() => registry.eventTarget.dispatchEvent("unmount:middleware", name))
                .then(() => registry.eventTarget.dispatchEvent("after:unmount:middleware", name));
        }
    }
}

/** Helper method used to combine all the reducers of a registry. */
export function buildMiddleware(registry: Registry): Middleware {
    return api => next => {
        // Store the current middleware stack.
        let stack = buildStack();

        // Add the event listeners.
        registry.eventTarget.addEventListener("mount:middleware", () => {
            stack = buildStack();
        });
        registry.eventTarget.addEventListener("unmount:middleware", () => {
            stack = buildStack();
        });

        // Return the dispatch.
        return api => stack(api);

        // Rebuilds the middleware stack.
        function buildStack(): Dispatch {
            log("Rebuilding the middleware stack ...");

            const middlewares = Array.from(registry.middlewareMap.values()).map(item => item.instance);

            // Compose the middlewares.
            return compose<Dispatch>(...middlewares.map(middleware => middleware(api)))(next);
        }
    }
}
