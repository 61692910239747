/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the stores.
import { AssetStore, EncryptionSourceStore } from "@andromeda/store";
// Import the resources.
import { EncryptionSource } from "@andromeda/resources";
// Import the JSON:API tools.
import { extractURL } from "@andromeda/json-api";
// Import the Xhr tools.
import { Xhr, XhrHeaders } from "@andromeda/xhr";
// Import the web asset interface.
import { WebAsset } from "@andromeda/converter-lib";

// Import the store helper.
import { getAssetStore} from "./redux-helper";


/**
 * Uploads the provided web asset to the S3 bucket.
 *
 * @param {WebAsset} asset The asset to upload.
 * @return {Promise<void>} A promise that resolves with the uploaded asset.
 */
export async function uploadWebAsset(asset: WebAsset): Promise<void> {
    const store = getAssetStore();

    // Generate the asset on the API.
    const put = await store.dispatch(AssetStore.generator.create(asset.resource)).then(asset => asset.links?.put);
    if (!put) {
        throw new Error("Failed to generate a PUT link for the asset !");
    }

    // Try to get the encryption source from the redux store.
    let encryption: EncryptionSource | undefined = undefined;
    if (asset.resource.relationships.encryptionSource.data) {
        const encryptionId = asset.resource.relationships.encryptionSource.data.id;
        encryption = await store.getState()["encryption-source"].resources.find(source => source.id === encryptionId);
        if (!encryption) {
            // Try to load the source from the API.
            encryption = await store.dispatch(EncryptionSourceStore.generator.readOne(encryptionId));
        }
    }

    // Prepare to upload the data to the S3 storage.
    const url = extractURL(put);
    const headers: XhrHeaders = {};
    if (encryption) {
        headers["X-Amz-Server-Side-Encryption-Customer-Algorithm"] = "AES256";
        headers["X-Amz-Server-Side-Encryption-Customer-Key"] = encryption.attributes.key;
        headers["X-Amz-Server-Side-Encryption-Customer-Key-MD5"] = encryption.attributes.md5;
    }

    // Upload the asset to the S3 bucket.
    await Xhr.put(url, { headers, configuration: { noAuthorization: true }, body: asset.data });
}
