/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { createContext, ReactElement, useContext } from "react";
// Import the bootstrap components.
import { Modal } from "react-bootstrap";

// Import the close icon.
import close from "@andromeda/assets/images/close.svg";
// Import the css.
import css from "./fullscreen-modal.module.scss";



/** Context used by the {@link FullscreenModal} to get the container for the fullscreen mode. */
export const FullscreenModalContext = createContext<{ container: HTMLElement | null }>({ container: null });

/** Modal used to render an element in fullscreen. */
export function FullscreenModal(props: Props): ReactElement {
    const { container } = useContext(FullscreenModalContext);
    return <Modal
        centered
        onHide={props.hide}
        container={container}
        show={props.show}
        dialogClassName={css["fullscreen-modal__dialog"]}
        contentClassName={css["fullscreen-modal"]}
    >
        {props.children}
        <button className={css["fullscreen-modal__close"]} onClick={props.hide}>
            <img src={close} alt="close-modal" />
        </button>
    </Modal>;
}

/** Props passed down to the {@link FullscreenModal} component. */
interface Props {
    /** Children to render within the modal. */
    children: React.ReactNode;

    /** If true, render the modal. */
    show: boolean;
    /** Method used to hide the modal. */
    hide(): void;
}
