/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the reducer interface.
import { Reducer } from "redux";
// Import the JSON:API resource interface.
import { Resource } from "@andromeda/json-api";
// Import the CRUD tuple helper.
import { CRUDTuple } from "@andromeda/resource-helper";

// Import the helpers.
import {
    CRUDAction,
    isCreateSuccessAction,
    isCreateRequestAction,
    isCreateFailureAction,
    isReadOneRequestAction,
    isReadManyRequestAction,
    isReadOneSuccessAction,
    isReadManySuccessAction,
    isReadOneFailureAction,
    isReadManyFailureAction,
    isUpdateRequestAction,
    isUpdateFailureAction,
    isUpdateSuccessAction,
    isDeleteRequestAction,
    isDeleteSuccessAction,
    isDeleteFailureAction
} from "./actions";


/**
 * Interface used to represent the status of the resource stores.
 * These are used to load and manipulate a given resource type.
 */
export interface ResourceStore<R extends Resource> {
    /** Type of the manipulated resource. */
    type: R["type"];
    /** List of resources currently stored. */
    resources: R[];

    /** Number of creation jobs currently running. */
    creating: number;
    /** Number of reading jobs currently running. */
    reading: number;
    /** Number of updating jobs currently running. */
    updating: number;
    /** Number of deleting jobs currently running. */
    deleting: number;

    /** Optional element used to store the last error that arose. */
    lastError?: Error;
}

/** Helper type used to describe the state of the store with the provided resource. */
export type WithResourceStore<R extends Resource> = {
    [K in R["type"]]: ResourceStore<R>;
};

/** Default state of all the resource stores. */
const DEFAULT_STATE: Omit<ResourceStore<never>, "type" | "resources"> = {
    creating: 0,
    reading: 0,
    updating: 0,
    deleting: 0
};

/** Helper function used to build a new resource reducer. */
export function buildResourceReducer<R extends CRUDTuple>(
    name: R[0]["type"]
): Reducer<ResourceStore<R[0]>, CRUDAction<R>> {
    // Return the generated reducer.
    return function resourceReducer(
        currentState: ResourceStore<R[0]> = {
            type: name,
            resources: [],
            ...DEFAULT_STATE
        },
        action: CRUDAction<R>
    ): ResourceStore<R[0]> {
        let newState = currentState;

        // Handle creation requests.
        if (isCreateRequestAction(name, action)) {
            newState = { ...currentState, creating: currentState.creating + 1 };
        }
        if (isCreateSuccessAction(name, action)) {
            // Check if the resource already exist, and replace it if so.
            if (currentState.resources.some(r => r.id === action.payload.id)) {
                currentState.resources = currentState.resources.map(r =>
                    r.id === action.payload.id ? action.payload : r
                );
            } else {
                currentState.resources = [
                    ...currentState.resources,
                    action.payload
                ];
            }
            newState = { ...currentState, creating: Math.max(currentState.creating - 1, 0) };
        }
        if (isCreateFailureAction(name, action)) {
            newState = {
                ...currentState,
                creating: Math.max(currentState.creating - 1, 0),
                lastError: action.payload
            };
        }

        // Handle read requests.
        if (
            isReadOneRequestAction(name, action) ||
            isReadManyRequestAction(name, action)
        ) {
            newState = { ...currentState, reading: currentState.reading + 1 };
        }
        if (
            isReadOneSuccessAction(name, action) ||
            isReadManySuccessAction(name, action)
        ) {
            for (const resource of Array.isArray(action.payload)
                ? action.payload
                : [action.payload]) {
                const existingIndex = currentState.resources.findIndex(
                    r => r.id === resource.id
                );
                if (existingIndex < 0) {
                    currentState.resources.push(resource);
                } else {
                    currentState.resources[existingIndex] = resource;
                }
            }
            newState = {
                ...currentState,
                resources: [...currentState.resources],
                reading: Math.max(currentState.reading - 1, 0)
            };
        }
        if (
            isReadOneFailureAction(name, action) ||
            isReadManyFailureAction(name, action)
        ) {
            newState = {
                ...currentState,
                reading: Math.max(currentState.reading - 1, 0),
                lastError: action.payload
            };
        }

        // Handle update requests.
        if (isUpdateRequestAction(name, action)) {
            newState = { ...currentState, updating: currentState.updating + 1 };
        }
        if (isUpdateSuccessAction(name, action)) {
            if (action.payload === null) {
                newState = { ...currentState, updating: Math.max(currentState.updating - 1, 0) };
            } else {
                const existingIndex = currentState.resources.findIndex(
                    r => action.payload !== null && r.id === action.payload.id
                );
                if (existingIndex < 0) {
                    currentState.resources.push(action.payload);
                } else {
                    currentState.resources[existingIndex] = action.payload;
                }
                newState = {
                    ...currentState,
                    resources: [...currentState.resources],
                    updating: Math.max(currentState.updating - 1, 0)
                };
            }
        }
        if (isUpdateFailureAction(name, action)) {
            newState = {
                ...currentState,
                updating: Math.max(currentState.updating - 1, 0),
                lastError: action.payload
            };
        }

        // Handle delete requests.
        if (isDeleteRequestAction(name, action)) {
            // If the id was provided, remove it immediately.
            if ("payload" in action) {
                currentState.resources = currentState.resources.filter(
                    r => r.id !== action.payload
                );
            }
            newState = { ...currentState, deleting: currentState.deleting + 1 };
        }
        if (isDeleteSuccessAction(name, action)) {
            newState = {
                ...currentState,
                resources: currentState.resources.filter(
                    r => r.id !== action.payload
                ),
                deleting: Math.max(currentState.deleting - 1, 0)
            };
        }
        if (isDeleteFailureAction(name, action)) {
            newState = {
                ...currentState,
                deleting: Math.max(currentState.deleting - 1, 0),
                lastError: action.payload
            };
        }

        return newState;
    };
}
