/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useContext } from "react";

// Import the contexts.
import { PDFContainerContext, PDFContext } from "../context";


/** Type alias for the return type of {@link useSizeOfPage}. */
export type PDFPageSize = [width: number, height: number];

/**
 * Helper hook used to query the size of a single page.
 *
 * @param {number} pageIndex The index of the page.
 * @param {boolean} [scaled=false] If set, scales the size by the scale of the page.
 * @returns {PDFPageSize} The size of the page.
 * @see usePageSizeHelper
 */
export default function useSizeOfPage(pageIndex: number, scaled = false): PDFPageSize {
    return usePageSizeHelper()(pageIndex, scaled);
}

/**
 * Helper hook used to build a page size helper function.
 *
 * @returns {(pageIndex: number, scaled?: boolean) => PDFPageSize} The page size helper function.
 * @see useSizeOfPage
 */
export function usePageSizeHelper(): (pageIndex: number, scaled?: boolean) => PDFPageSize {
    // Get the pdf contexts.
    const { pages } = useContext(PDFContext);
    const { container, fit } = useContext(PDFContainerContext);
    const { width: containerWidth, height: containerHeight } = container;

    // Return the loader function.
    return function getSizeOfPage(pageIndex: number, scaled = false): PDFPageSize {
        // Get the size of the page.
        const { width: pageWidth, height: pageHeight } = pages[pageIndex].viewport;
        const ratio = pageWidth / pageHeight;

        // If the fitting mode is "contain", select the appropriate scaling axis.
        let scaleMode = fit;
        if (scaleMode === "contain") {
            const parentRatio = containerWidth / containerHeight;
            if (ratio > parentRatio) {
                scaleMode = "width";
            } else {
                scaleMode = "height";
            }
        }

        // Recompute the width/height depending on the ratio.
        let size: PDFPageSize;
        if (scaleMode === "width") {
            size = [containerWidth, containerWidth / ratio];
        } else {
            size = [containerHeight * ratio, containerHeight];
        }

        // Apply the scale to the size.
        if (scaled) {
            size[0] *= pages[pageIndex].scale;
            size[1] *= pages[pageIndex].scale;
        }

        // Floor the size and return it.
        return [Math.floor(size[0]), Math.floor(size[1])];
    }
}
