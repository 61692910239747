/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the custom contexts.
import { EditorSwitchContext } from "../../../../context";

// Import the icons.
import { faPlus } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./add-item-icon.module.scss";


/** Component used to render the icon used to add a new item. */
export default function AddItemIcon(props: AddItemIconProps): ReactElement | null {
    const { state: editing } = useContext(EditorSwitchContext);

    // Render the component.
    if (!editing || !props.show) {
        return null;
    }
    return <button className={css["button"]} onClick={props.onClick}>
        <FontAwesomeIcon className={css["button__icon"]} icon={faPlus} />
    </button>;
}

/** Props passed down to the {@link AddItemIcon} component. */
export interface AddItemIconProps {
    /** Flag that is set if the item should be shown. */
    show: boolean;
    /** Callback invoked when the button is clicked. */
    onClick(): void;
}
