/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the validation function.
import { ValidateFunction } from "ajv";
// Import the json:api interfaces.
import { Resource, Relationship } from "@andromeda/json-api";
// Import the schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the creatable resource interface.
import { CreatableResource, PartialResource } from "@andromeda/resource-helper";

// Import the organisation and tag resources.
import { Organisation, Tag, ImageAsset } from "../..";
// Import the file resource.
import { ZaqWikiFile } from "../zaq-wiki-file";
// Import the schema.
import { ZaqWikiSchema } from "./schema";

/** Interface used to represent a Wiki instance. */
export interface ZaqWiki extends Resource<ZaqWiki.Type> {
    /** @inheritDoc */
    attributes: ZaqWiki.Attributes;
    /** @inheritDoc */
    relationships: ZaqWiki.Relationships;
}

export namespace ZaqWiki {
    export const Type = "zaq-wiki" as const;
    export type Type = typeof Type;

    export interface Attributes {
        /** Name of the wiki. */
        name: string | null;
        /** Body of this wiki. */
        body: string | null;
        /** List of tags applied to this wiki. */
        tags: string[];
    }

    export interface Relationships {
        /** Reference to the organisations that this wiki belong to. */
        organisations: Relationship<Organisation[]>;
        /** List of tags applied to this wiki. */
        categories: Relationship<Tag[]>;
        /** List of all the files for this wiki. */
        files: Relationship<ZaqWikiFile[]>;
        /** Icon asset used to represent this wiki. */
        icon: Relationship<ImageAsset | null>;
    }

    export const validate: ValidateFunction<ZaqWiki> = compileSync(ZaqWikiSchema.ResourceSchema);

    export type Update = PartialResource<ZaqWiki>;
    export namespace Update {
        export const validate: ValidateFunction<ZaqWiki.Update> = compileSync(ZaqWikiSchema.UpdateSchema);
    }

    export interface Create extends CreatableResource<ZaqWiki.Type> {
        /** @inheritDoc */
        attributes?: Create.Attributes;
        /** @inheritDoc */
        relationships: Create.Relationships;
    }
    export namespace Create {
        export type Attributes = Partial<ZaqWiki.Attributes>;
        export interface Relationships {
            organisations: ZaqWiki.Relationships["organisations"];
            categories?: ZaqWiki.Relationships["categories"];
            icon?: Relationship<ImageAsset | null>;
        }

        export const validate: ValidateFunction<ZaqWiki.Create> = compileSync(ZaqWikiSchema.CreateSchema);
    }
}
