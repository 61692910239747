/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useContext, useMemo } from "react";
// Import React-Bootstrap.
import { Dropdown } from "react-bootstrap";
// Import the login module.
import { LoginContext } from "@andromeda/login";
// Import the resources.
import { Organisation } from "@andromeda/resources";

// Import the icons.
import { ReactComponent as Checkmark } from "@andromeda/assets/images/checkmark-colored.svg";
// Import the css.
import css from "./organisation-selector.module.scss";


/** Component used to render the organisation selector of the user menu. */
export default function OrganisationSelector(): ReactElement | null {
    // Load all the organisations of the current user.
    const { own: organisations, select, current: currentOrganisation } = useContext(LoginContext).organisations;

    // Render all the items from the list of organisations.
    const organisationItems = useMemo(function renderOrganisationItems(): ReactNode[] {
        return organisations.map(function renderOrganisationItem(organisation: Organisation): ReactNode {
            return <Dropdown.Item
                as="button"
                key={organisation.id}
                className={css["item"]}
                disabled={organisation.id === currentOrganisation.id}
                onClick={() => select(organisation.id)}
            >
                <Checkmark className={css["item__icon"]} />
                <p className={css["item__name"]} children={organisation.attributes.name} />
            </Dropdown.Item>;
        });
    }, [currentOrganisation.id, organisations, select]);

    // Render the component.
    if (organisationItems.length < 2) {
        return null;
    }
    return <>
        <Dropdown.Header className={css["header"]}>Mes organisations</Dropdown.Header>
        {organisationItems}
        <Dropdown.Divider className={css["divider"]} />
    </>;
}
