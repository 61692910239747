/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the icons.
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
// Import the css.
import css from "./search.module.scss";


/** Component used to render the search bar of the popper.*/
export default function PopperSearch(props: PopperSearchProps): ReactElement | null {
    // If the bar should be hidden, render nothing.
    if (!props.show) {
        return null;
    }

    // Render the search bar.
    return <>
        <div className={css["search"]}>
            <FontAwesomeIcon className={css["icon"]} icon={faMagnifyingGlass} />
            <input
                className={css["input"]}
                type="search"
                placeholder="Rechercher ..."
                value={props.value}
                onChange={e => props.onChange(e.currentTarget.value)}
            />
        </div>
        <hr className={css["separator"]} />
    </>;
}

/** Props passed down to the {@link PopperSearch} component. */
export interface PopperSearchProps {
    /** Flag set if the search bar should be visible. */
    show: boolean;
    /** The current value found in the search bar. */
    value: string;

    /**
     * Callback invoked when the text of the search bar changes.
     *
     * @param {string} value The new value found in the search bar.
     */
    onChange(value: string): void;
}
