/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import Redux.
import type { Action } from "redux";
// Import Redux-Thunk.
import type { ThunkAction, ThunkDispatch } from "redux-thunk";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the XHR module.
import { injectAuthHeader } from "@andromeda/xhr";

// Import the legacy interface.
import type { FullLegacyZaq, LegacyZaq } from "../interfaces";
// Import the local tools.
import { getLegacyZaqConfig, parseLegacyZaq } from "../lib";
// Import the reducer.
import { LegacyZaqReducerKey, LegacyZaqReducerStore } from "./reducer";
import { store } from "@andromeda/store";


/** Interface used to dispatch a new RequestStatus update. */
export interface RequestZaqAction extends Action<"zaq/request/update"> {
    /** The identifier of the zaq's request that was updated. */
    id: string;
    /** The new status of the request. */
    payload: RequestStatus<FullLegacyZaq | LegacyZaq>;
}

/** Interface used to dispatch a zaq registration. */
export interface RegisterZaqAction extends Action<"zaq/request/register"> {
    /** The identifier of the organisation that generated the request. */
    id: string;
    /** The new status of the request. */
    payload: RequestStatus<string[]>;
}

/** Interface used to dispatch a zaq deletion. */
export interface ZaqDeletedAction extends Action<"zaq/request/deleted"> {
    /** The identifier of the deleted Zaq. */
    payload: string;
}

/**
 * Helper used to download a specific Zaq from the legacy API.
 *
 * @param {string} id The id of the requested zaq.
 * @param {boolean} withDescriptor If true, loads the descriptor of the Zaq too.
 * @return {ThunkAction<void, LegacyZaqReducerStore, never, RequestZaqAction>}
 * An action that can be dispatched to the store.
 */
export function downloadZaq(
    id: string,
    withDescriptor?: boolean
): ThunkAction<Promise<LegacyZaq>, LegacyZaqReducerStore, never, RequestZaqAction>;

/**
 * Helper used to download a specific Zaq from the legacy API.
 *
 * @param {string} id The id of the requested zaq.
 * @param {boolean} withDescriptor If true, loads the descriptor of the Zaq too.
 * @return {ThunkAction<void, LegacyZaqReducerStore, never, RequestZaqAction>}
 * An action that can be dispatched to the store.
 */
export function downloadZaq(
    id: string,
    withDescriptor: true
): ThunkAction<Promise<FullLegacyZaq>, LegacyZaqReducerStore, never, RequestZaqAction>;

/** Implementation ! */
export function downloadZaq(
    id: string,
    withDescriptor = false
): ThunkAction<Promise<FullLegacyZaq | LegacyZaq>, LegacyZaqReducerStore, never, RequestZaqAction> {
    return async function requestZaq(
        dispatch: ThunkDispatch<LegacyZaqReducerStore, never, RequestZaqAction>,
        getState: () => LegacyZaqReducerStore
    ): Promise<FullLegacyZaq | LegacyZaq> {
        // Check if the request is already running.
        const request = getState()[LegacyZaqReducerKey].resources[id];
        if (typeof request !== "undefined" && !request.isUninitialised) {
            let data: FullLegacyZaq | LegacyZaq;
            if (request.isSuccess) {
                    data = request.data;
            } else {
                // Wait for the resource to be loaded.
                data = await new Promise((resolve) => {
                    const unsubscribe = store.subscribe(function onStoreUpdate(): void {
                        // Check if the resource was stored.
                        const request = getState()[LegacyZaqReducerKey].resources[id];
                        if (request?.isSuccess) {
                            unsubscribe();
                            resolve(request.data);
                        }
                    });
                });
            }

            if (withDescriptor && "majorSteps" in data) {
                return data;
            } else if (!withDescriptor) {
                return data;
            }
        }

        // Dispatch a status update.
        dispatch({ type: "zaq/request/update", id, payload: RequestStatus.loading() });


        // Load the api address.
        const { root, hostname } = await getLegacyZaqConfig();
        let api = `https://${hostname}`;
        if (root) {
            api += `/${root}`;
        }
        api += `/zaqtiv/${id}?descriptor=${String(withDescriptor)}`;
        const headers = { Accept: "application/vnd.api+json" };
        injectAuthHeader(headers);

        // Run the request.
        try {
            const response = await fetch(api, { headers });

            // Parse the response.
            const results: FullLegacyZaq = parseLegacyZaq(id, await response.json());
            if (!withDescriptor) {
                delete (results as { majorSteps?: unknown }).majorSteps;
                delete (results as { assets?: unknown }).assets;
            }
            dispatch({ type: "zaq/request/update", id, payload: RequestStatus.success(results) });
            return results;
        } catch (e: unknown) {
            dispatch({ type: "zaq/request/update", id, payload: RequestStatus.error(e) });
            throw e;
        }
    };
}

/**
 * Helper used to download multiple zaq from the API.
 *
 * @param {string} organisation The id of the organisation that owns all the zaq.
 * @return {ThunkAction<void, LegacyZaqReducerStore, never, RequestZaqAction | RegisterZaqAction>}
 * An action that can be dispatched to the store.
 */
export function downloadMultipleZaq(
    organisation: string
): ThunkAction<Promise<void>, LegacyZaqReducerStore, never, RequestZaqAction | RegisterZaqAction> {
    return async function requestZaq(
        dispatch: ThunkDispatch<LegacyZaqReducerStore, never, RequestZaqAction | RegisterZaqAction>,
        getState: () => LegacyZaqReducerStore
    ): Promise<void> {
        // Check if the request is already running.
        const request = getState()[LegacyZaqReducerKey].organisations[organisation];
        if (typeof request !== "undefined") {
            return;
        }

        // Register the organisation.
        dispatch({ type: "zaq/request/register", id: organisation, payload: RequestStatus.loading() });

        // Load the api address.
        const { root, hostname } = await getLegacyZaqConfig();
        let api = `https://${hostname}`;
        if (root) {
            api += `/${root}`;
        }
        api += `/organisation/${organisation}/zaqtiv`;
        const headers = { Accept: "application/vnd.api+json" };
        injectAuthHeader(headers);

        // Run the request.
        try {
            const response = await fetch(api, { headers });

            // Parse the response.
            const results = parseLegacyZaq(await response.json());
            for (const zaq of results) {
                dispatch({ type: "zaq/request/update", id: zaq.id, payload: RequestStatus.success(zaq) });
            }
            dispatch({
                type: "zaq/request/register", id: organisation,
                payload: RequestStatus.success(results.map(({ id }) => id))
            });
        } catch (e: unknown) {
            console.error(e);
        }
    };
}

/**
 * Deletes a given ZaqTuto.
 *
 * @param {string} id The identifier of the tuto to delete.
 * @returns {ThunkAction<Promise<void>, LegacyZaqReducerStore, never, ZaqDeletedAction>}
 * An action that can be dispatched to the store.
 */
export function deleteZaq(id: string): ThunkAction<Promise<void>, LegacyZaqReducerStore, never, ZaqDeletedAction> {
    return async function deleteZaq(
        dispatch: ThunkDispatch<LegacyZaqReducerStore, never, ZaqDeletedAction>
    ): Promise<void> {
        // Dispatch a status update.
        dispatch({ type: "zaq/request/deleted", payload: id });


        // Load the api address.
        const { root, hostname } = await getLegacyZaqConfig();
        let api = `https://${hostname}`;
        if (root) {
            api += `/${root}`;
        }
        api += `/zaqtiv/${id}`;
        const headers = {};
        injectAuthHeader(headers);

        // Run the request.
        await fetch(api, { headers, method: "DELETE" });
    };
}
