/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the modal component.
import { Modal } from "react-bootstrap";

// Import the context.
import { ShowConfirmOptions, ConfirmContext } from "./context";

// Import the css.
import css from "./provider.module.scss";


/** Provider for the {@link ConfirmContext}. */
export function ConfirmProvider(props: Props): React.ReactElement {
    // Queue of all the confirm elements.
    const [ queue, setQueue ] = React.useState<OptionsWithResolve[]>([]);
    const current = React.useMemo(function getLastConfirmInQueue(): OptionsWithResolve | null {
        if (queue.length > 0) return queue[queue.length - 1];
        return null;
    }, [ queue ]);

    // State of the modal.
    const [ showModal, setShowModal ] = React.useState(false);
    React.useEffect(function showModalOnNewModal(): void { setShowModal(current !== null); }, [ current ]);

    // Callback used to wait for the modal to be closed.
    const [ onModalHide, setOnModalHide ] = React.useState<() => void>()
    const waitForExit = React.useCallback(function waitForModalExit(callback: () => void): void {
        setOnModalHide(() => {
            setQueue(queue => queue.slice(0, queue.length - 1));
            callback();
        });
        setShowModal(false);
    }, []);

    // Get the header and body renderers.
    const { Header, Body } = React.useMemo(function buildModalRenderer() {
        return {
            Header: current?.header ?? (() => null),
            Body: current?.body ?? (() => null),
        };
    }, [ current?.body, current?.header ]);

    // Render the modal.
    const modal = React.useMemo(function renderModal(): React.ReactElement | null {
        return <Modal
            centered={current?.centered}
            size={current?.size}
            fullscreen={current?.fullscreen}
            onHide={() => waitForExit(() => current?.resolve(current?.cancel))}
            onExited={() => onModalHide?.()}
            show={showModal}
            className={css["confirmation-modal"]}
            backdropClassName={css["confirmation-modal__backdrop"]}
            dialogClassName={css["confirmation-modal__dialog"]}
            contentClassName={
                `${css["confirmation-modal__content"]} ` +
                `${current?.transparent ? css["confirmation-modal__content--transparent"] : ""}`
            }
        >
            <Header
                resolve={result => waitForExit(() => current?.resolve(result))}
                reject={error => waitForExit(() => current?.reject(error))}
            />
            <Body
                resolve={result => waitForExit(() => current?.resolve(result))}
                reject={error => waitForExit(() => current?.reject(error))}
            />
        </Modal>;
    }, [Body, Header, current, onModalHide, showModal, waitForExit]);

    // Prepare the value of the context.
    const value = React.useMemo(function prepareContext(): ConfirmContext {
        return {
            show<T>(options: ShowConfirmOptions<T>): Promise<T> {
                return new Promise<unknown>((resolve, reject) => {
                    setQueue(queue => [
                        ...queue,
                        {
                            ...options,
                            resolve(value) { console.log(value); resolve(value); },
                            reject(error) { console.error(error); reject(error); }
                        }
                    ]);
                }) as Promise<T>;
            }
        };
    }, []);

    return <ConfirmContext.Provider value={value}>{props.children}{modal}</ConfirmContext.Provider>;
}

/** Props passed to the {@link ConfirmProvider} component. */
interface Props {
    /** Children of the provider. */
    children?: React.ReactNode;
}

/** Extensions of the {@link ShowConfirmOptions} with a resolve attribute. */
interface OptionsWithResolve extends ShowConfirmOptions<unknown> {
    /** Method used to resolve the queued promise. */
    resolve(value: unknown): void;
    /** Method used to reject the queued promise. */
    reject(error: unknown): void;
}
