/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { Component, ReactElement, useCallback, useContext, useMemo, useState } from "react";
import ReactDOM from "react-dom";
// Import tue UUID generator.
import { v4 } from "uuid";

// Import the context.
import { NotificationContext, InternalNotificationContext } from "./context";
// Import the notification interface.
import { Notification, ErrorNotification, SimpleNotification } from "./notification";
// Import the notification renderer.
import { NotificationRenderer } from "./renderer";
// Import the discord webhook context.
import { DiscordWebhookContext } from "./discord";
// Import the fatal error renderer.
import { FatalErrorRenderer } from "./fatal";
// Import the error boundary component.
import { ErrorBoundary } from "./boundary";


// Re-export the context and the interface.
export { Notification };
export type { NotificationContext };
// Re-export the discord context.
export { DiscordWebhookProvider } from "./discord";

/**
 * Provider component for the notification system.
 * Renders all notifications in a portal attached to the body.
 * Does not add additional providers if a parent already exists.
 */
export function NotificationProvider(props: NotificationProviderProps): ReactElement {
    // Check if a provider already exists above this one.
    const parent = useContext(NotificationContext) as InternalNotificationContext;

    // Render the context value.
    const [ notifications, setNotifications ] = useState<Notification[]>([]);
    const value = useMemo(function renderContextValue(): InternalNotificationContext {
        return {
            _isDefault: false,
            push,
            debug(title?: string | null, text?: string | null): void {
                push({ type: Notification.Type.debug, text: text ?? undefined, title: title ?? undefined });
            },
            error(error: unknown, title?: string | null, text?: string | null): void {
                console.error(error);
                push({ type: Notification.Type.error, error, text: text ?? undefined, title: title ?? undefined });
            },
            fatal(error: unknown, title?: string | null, text?: string | null): void {
                console.error(error);
                push({ type: Notification.Type.fatal, error, text: text ?? undefined, title: title ?? undefined });
            },
            info(title?: string | null, text?: string | null): void {
                push({ type: Notification.Type.info, text: text ?? undefined, title: title ?? undefined });
            },
            warning(title?: string | null, text?: string | null): void {
                push({ type: Notification.Type.warning, text: text ?? undefined, title: title ?? undefined });
            }
        };

        function push(notification: Omit<SimpleNotification, "uuid">): void;
        function push(notification: Omit<ErrorNotification, "uuid">): void;
        function push(notification: Omit<Notification, "uuid">): void {
            const withUuid = { ...notification, uuid: v4() } as Notification;
            setNotifications(notifications => [ ...notifications, withUuid ]);
        }
    }, []);

    // Helper used to clear a given notification.
    const clearNotification = useCallback(function clearNotification(uuid: string): void {
        setNotifications(notifications => {
            // Find the notification.
            const index = notifications.findIndex(notification => notification.uuid === uuid);
            if (index === -1) {
                console.error("Could not find notification \"%s\" in %o", uuid, notifications);
                return notifications;
            }

            // Splice the array.
            return [ ...notifications.slice(0, index), ...notifications.slice(index + 1) ];
        });
    }, []);

    // Render the portal.
    const portal = useMemo(function renderPortal(): ReactElement | null {
        if (!parent._isDefault) return null;

        return ReactDOM.createPortal(
            <NotificationRenderer notifications={notifications} clearNotification={clearNotification} />,
            document.body,
            "notification-root"
        );
    }, [ clearNotification, notifications, parent._isDefault ]);

    // Render the fatal error.
    const fatalErrors = useMemo(function findFatalError(): ErrorNotification[] {
        return notifications.filter((notification: Notification): notification is ErrorNotification => {
            return notification.type === Notification.Type.fatal;
        });
    }, [notifications]);
    const fatalErrorComponent = useMemo(function renderFatalErrorComponent(): ReactElement | null {
        if (fatalErrors.length <= 0) {
            return null;
        }

        return <FatalErrorRenderer notifications={fatalErrors} />;
    }, [fatalErrors]);

    // Render the children elements.
    const children = useMemo(function renderChildrenElements(): ReactElement {
        return fatalErrorComponent ?? props.children;
    }, [fatalErrorComponent, props.children]);

    // Render the component.
    if (parent._isDefault) {
        return <NotificationContext.Provider value={value}>
            <ErrorBoundary>
                {children}
                {portal}
            </ErrorBoundary>
        </NotificationContext.Provider>
    } else {
        return children;
    }
}

/** Props passed down to the {@link NotificationProvider} component. */
interface NotificationProviderProps {
    /** Children that will have access to this context. */
    children: ReactElement;
}

/**
 * Wrapper for {@code useContext(NotificationContext)}.
 * Also, wraps the {@link DiscordWebhookContext} getter.
 */
export function useNotify(): NotificationContext {
    // Load the contexts.
    const discord = useContext(DiscordWebhookContext);
    const notify = useContext(NotificationContext);

    // Create a wrapper for the context's push method.
    const push: NotificationContext["push"] = useCallback(function push(notification): void {
        // This if is redundant, but needed otherwise TypeScript sh*ts itself.
        if ("error" in notification) {
            return notify.push({ ...notification, discordWebhookContext: discord });
        } else {
            return notify.push({ ...notification, discordWebhookContext: discord });
        }
    }, [discord, notify]);

    // Generate a memoized wrapper for the notification context.
    return useMemo(function generateNotificationWrapper(): NotificationContext {
        return {
            push,
            debug(title?: string | null, text?: string | null): void {
                return push({ type: Notification.Type.debug, title, text });
            },
            error(error: unknown, title?: string | null, text?: string | null): void {
                return push({ type: Notification.Type.error, title, text, error });
            },
            fatal(error: unknown, title?: string | null, text?: string | null): void {
                return push({ type: Notification.Type.fatal, title, text, error });
            },
            info(title?: string | null, text?: string | null): void {
                return push({ type: Notification.Type.info, title, text });
            },
            warning(title?: string | null, text?: string | null): void {
                return push({ type: Notification.Type.warning, title, text });
            }
        }
    }, [push]);
}
