/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


// Import React.
import { ReactElement, useDeferredValue, useEffect, useRef, useState } from "react";
// Import React-Router.
import { useNavigate, useSearchParams } from "react-router-dom";

// Import the navbar tools.
import { useHideNavbarSearchButton } from "../../../navbar";
// Import the hooks.
import { SearchFilter, useSearchableItems, useSearchResults } from "../../hooks";
// Import the searchbar component.
import SearchBar from "../searchbar";
// Import the create button.
import Create from "../create";
// Import the subcomponents.
import SearchTabs from "./tab";
import SearchResults from "./results";

// Import the icons.
import { ReactComponent as ZaqTiv } from "@andromeda/assets/images/zaqtiv.svg";
// Import the css.
import css from "./index.module.scss";


/** Component used to render the search results page. */
export default function Search(): ReactElement {
    // Hide the button in the navbar.
    useHideNavbarSearchButton();

    // Get the navigation callback.
    const navigate = useNavigate();

    // Get the search parameter tools.
    const [searchParams, updateSearchParams] = useSearchParams();
    // Store the text currently stored as the query.
    const [query, setQuery] = useState(searchParams.get("query") ?? "");

    // Store the currently selected filter.
    const [filter, setFilter] = useState<SearchFilter>("all");

    // Download all the searchable items from the store.
    const searchableItems = useSearchableItems();
    // Get the list of matching items from the source list.
    const searchResults = useSearchResults(searchableItems, query, filter);
    const deferredResults = useDeferredValue(searchResults);

    // Updates the search parameters with the query.
    const timeout = useRef<ReturnType<typeof setTimeout>>();
    useEffect(function updateQueryParameter(): void {
        // Clear the existing timeout.
        if (timeout.current) {
            clearTimeout(timeout.current);
            delete timeout.current;
        }

        // Register a timeout to stagger the updates to the search parameters.
        timeout.current = setTimeout(function staggeredQueryParameterUpdate(): void {
            updateSearchParams({ query });
        }, 100);
    }, [query, updateSearchParams]);

    // Render the component.
    return <div className={css["container"]}>
        <div id={css["search-area"]}>
            <button className={css["logo"]} onClick={() => navigate("/")}>
                <ZaqTiv className={css["logo__icon"]} />
            </button>
            <SearchBar
                className={css["searchbar"]}
                defaultValue={query}
                onChange={setQuery}
                placeholder="Rechercher ..."
                size="lg"
            />
            <SearchTabs
                className={css["tabs"]}
                currentFilter={filter}
                loading={searchResults !== deferredResults}
                onTabChanged={setFilter}
            />
        </div>
        <SearchResults items={searchableItems} results={deferredResults} />
        <Create />
    </div>;
}
