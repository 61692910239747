/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the discord webhook context.
import { DiscordWebhookContext } from "./discord";


/** Base interface for all the notification types. */
interface NotificationBase<T extends Notification.Type> {
    /** The unique id of this notification. */
    uuid: string;
    /** The type of the notification. */
    type: T;
    /** The human-readable text of the notification. */
    text?: string | null;
    /** An optional title for the notification. */
    title?: string | null;

    /** An override for the discord webhook to use. */
    discordWebhookContext?: DiscordWebhookContext;
}

/** Interface used to describe a notification object. */
export type SimpleNotification = NotificationBase<
    Notification.Type.debug | Notification.Type.info | Notification.Type.warning
>;

/** Interface used to describe an error notification. */
export interface ErrorNotification extends NotificationBase<Notification.Type.error | Notification.Type.fatal> {
    /** The error that arose. */
    error: unknown;
}
/** Union of all the notification types. */
export type Notification = SimpleNotification | ErrorNotification;

/** Augment the {@link Notification} interface. */
export namespace Notification {
    /** List of notification types. */
    export enum Type {
        /** Debug notifications, not rendered in production environments. */
        debug = "debug",
        /** Information notifications */
        info = "info",
        /** Warning notifications */
        warning = "warning",
        /** Non-fatal error notifications. */
        error = "error",
        /** Fatal error notifications. */
        fatal = "fatal"
    }
}
