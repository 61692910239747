/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext, useMemo } from "react";
// Import the login module.
import { LoginContext, useIsAdmin } from "@andromeda/login";
// Import the store.
import { useCourses } from "@andromeda/store";

// Import the hooks.
import { useTrainingSelector, useUserSelector } from "../../../hooks";
// Import the training status header and footer.
import { TrainingStatusFooter, TrainingStatusHeader } from "../../training";
import type { TrainingStatusFooterProps } from "../../training/footer";
// Import the widget component.
import Widget from "../widget";
// Import subcomponents.
import TrainingProgressList from "./list";
import TrainingSelector from "./selector";

// Import the css.
import css from "./index.module.scss";


/** Component used to render all the ZaqTraining that the user is currently assigned to. */
export default function TrainingWidget(props: TrainingWidgetProps): ReactElement | null {
    // Store the selected training and user.
    const [selectedTraining, setSelectedTraining] = useTrainingSelector(props.mode === "by-training");
    const [selectedUser, setSelectedUser] = useUserSelector(props.mode === "by-user");

    // Check if the user is an administrator.
    const isAdmin = useIsAdmin();

    // Load the list of training.
    const training = useCourses(useContext(LoginContext).organisations.current.id);

    // Get the title of the widget.
    const title = useMemo(function getWidgetTitle(): string {
        switch (props.mode) {
        case "assigned":
            return "Mes formations";
        case "done":
            return "Mes formations validées";
        case "by-user":
            return "Suivi apprenant";
        case "by-training":
            return "Suivi formation";
        default:
            return "UNKNOWN_TRAINING_MODE";
        }
    }, [props.mode]);

    // Check if there are trainings to show.
    if (!training.isSuccess || training.data.length < 1) {
        return null;
    }

    // Check if the user is an administrator.
    if (!isAdmin && (props.mode === "by-training" || props.mode === "by-user")) {
        return null;
    }

    // Select the footer mode.
    let mode: TrainingStatusFooterProps["mode"];
    switch (props.mode) {
    case "assigned":
        mode = "current";
        break;
    case "done":
        mode = "validated";
        break;
    default:
        mode = "both";
        break;
    }

    // Render the widget.
    const actions = <TrainingSelector
        mode={props.mode}
        training={selectedTraining}
        user={selectedUser}
        setTraining={setSelectedTraining}
        setUser={setSelectedUser}
    />;
    return <Widget title={title} halfSize scrolling={false} actions={actions}>
        <div className={css["widget"]}>
            <TrainingStatusHeader showUsername={props.mode === "by-training"} />
            <TrainingProgressList mode={props.mode} user={selectedUser} training={selectedTraining} />
            <TrainingStatusFooter mode={mode} />
        </div>
    </Widget>;
}

/** List of all the possible modes for the widget. */
export type TrainingWidgetMode = "assigned" | "done" | "by-user" | "by-training";

/** Props passed down to the {@link TrainingWidget} component. */
export interface TrainingWidgetProps {
    /** The mode that the widget should be rendered in. */
    mode: TrainingWidgetMode;
}
