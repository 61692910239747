/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

/** Schema used to describe non standard metadata. */
const Schema = {
    title: "Metadata",
    description: "Non-standard meta-information that can not be represented as an attribute or relationship.",
    type: "object",
    additionalProperties: true
} as const;

export default Schema;
