/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


/** Interface used to describe an extra. */
export interface Extra {
    /** Type of the rendered extra. */
    readonly type: string;
    /** Parameters that were provided alongside the extra. */
    readonly parameters: object;
}

/** Interface used to describe a timeline. */
export interface Timeline {
    /** Identifier of the converted asset. */
    readonly asset?: string;
    /** Extras rendered on this timeline. */
    readonly extras: Extra[];
}

/** Interface used to describe an action. */
export interface Action {
    /** Type of the rendered action. */
    readonly type: string;
    /** Parameters that were provided alongside the action. */
    readonly parameters: object;
    /** Timeline of this action. */
    readonly timeline: Timeline[];
}

/** Interface used to describe a minor step. */
export interface MinorStep {
    /** The name of the minor step. */
    readonly name: string;
    /** The explanation of the minor step. */
    readonly explanation?: string;
    /** The actions in this minor step. */
    readonly actions: Action[];
}

/** Interface used to describe a major step. */
export interface MajorStep {
    /** The name of the major step. */
    readonly name: string;
    /** The minor steps in this major step. */
    readonly minorSteps: MinorStep[];
}

/** Interface used to describe a legacy zaq asset. */
export interface LegacyZaqAsset {
    /** Unique identifier of the legacy asset. */
    readonly id: string;
    /** MIME type of the asset. */
    readonly mimeType: `image/${string}`;
    /** URL that can be queried to download the asset. */
    readonly url: string;
}

/** Interface used to describe a legacy zaq item. */
export interface LegacyZaq {
    /** The type of the zaq. */
    readonly type: "zaq-tuto";
    /** The identifier of the zaq. */
    readonly id: string;
    /** The name of the zaq. */
    readonly name: string;
    /** The tags applied to the zaq. */
    readonly tags: string[];
    /** The icon used by this zaq. */
    readonly icon: LegacyZaqAsset | undefined;
    /** The organisations that have access to this Zaq. */
    readonly organisations: ReadonlyArray<string>;
    /** List of users that can edit the zaq. */
    readonly editors: string[];
    /** Identifier of the owner of this zaq. */
    readonly owner: string | null;
}

/** Augmented version of the {@link LegacyZaq} with additional metadata. */
export interface FullLegacyZaq extends LegacyZaq {
    /** The major steps of this zaq. */
    readonly majorSteps: MajorStep[];
    /** All the assets included in the zaq. */
    readonly assets: LegacyZaqAsset[];
}

/** Legacy type of the zaq. */
export const LegacyZaqType = "com.zimproov.api.zaqtiv";
/** Legacy type of the zaq. */
export type LegacyZaqType = typeof LegacyZaqType;

/** Legacy type of the zaq translations. */
export const LegacyZaqTranslationType = "com.zimproov.api.zaqtiv.translation";
/** Legacy type of the zaq translations. */
export type LegacyZaqTranslationType = typeof LegacyZaqTranslationType;

/** Legacy type of the zaq assets. */
export const LegacyZaqAssetType = "com.zimproov.api.zaqtiv.asset";
/** Legacy type of the zaq assets. */
export type LegacyZaqAssetType = typeof LegacyZaqAssetType;
