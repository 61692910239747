/*
 * Copyright © 2024 - Zimproov.
 * All rights reserved.
 */


import { Action, Reducer } from "redux";
import { UserV2 } from "@andromeda/resources";
import { Xhr } from "@andromeda/xhr";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ThunkDispatch } from "redux-thunk";
import { MessageHelper } from "@andromeda/json-api";


export interface UserV2LoadingAction extends Action<"user-v2/load"> {
    id: string;
}

export interface UserV2LoadedAction extends Action<"user-v2/loaded"> {
    user: UserV2;
}

export interface UserV2LoadingErrorAction extends Action<"user-v2/error"> {
    error?: unknown;
}

export type UserV2Action = UserV2LoadingAction | UserV2LoadedAction | UserV2LoadingErrorAction;

export const UserV2Reducer: Reducer<UserV2 | null, UserV2Action> = function(currentState = null, action) {
    switch (action.type) {
    case "user-v2/error":
        throw action.error;
    case "user-v2/loaded":
        return action.user;
    default:
        return currentState;
    }
};

export function useUserV2State() {
    return useSelector(function(state: { "user-v2": UserV2 | null }) {
        return state["user-v2"];
    });
}

export function useUserV2Loader(id: string) {
    const dispatch = useDispatch() as ThunkDispatch<{ "user-v2": UserV2 | undefined }, never, UserV2Action>;

    useEffect(function() {
        void dispatch(async function(dispatch, getState) {
            dispatch({ type: "user-v2/load", id });

            const response = await Xhr.get("user-v2", { path: `/user/${id}` });
            if (response.body instanceof MessageHelper) {
                dispatch({ type: "user-v2/loaded", user: response.body.data as UserV2 });
            }
        });
    }, []);
}
