/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useMemo } from "react";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the legacy Zaq tools.
import { MajorStep, useLegacyZaq } from "@andromeda/legacy-zaq";
// Import the resources.
import type { Validation } from "@andromeda/resources";

// Import the custom validation store.
import { useValidationEditor } from "../../../store";

// Import the subcomponents.
import Chapter from "./chapter";

// Import the css.
import css from "./index.module.scss";


/** Component used to render the validation of a given ZaqTuto. */
export default function ZaqTuto(props: ZaqTutoProps): ReactElement {
    // Download the tuto from the store.
    const tuto = useLegacyZaq(props["zaq-tuto"], true);

    // Download the validation status from the store.
    const [validation, updateValidation] = useValidationEditor(props.user, { type: "zaq", id: props["zaq-tuto"] });
    const buildChapterUpdate = useCallback(function buildChapterUpdater(index: number) {
        return function chapterUpdate(detail: Validation.Detail[]): void {
            if (!validation.isSuccess) {
                return;
            }

            validation.data.attributes.detail[index] = detail;
            updateValidation({ detail: [...validation.data.attributes.detail] });
        };
    }, [updateValidation, validation.data?.attributes.detail, validation.isSuccess]);

    // Render all the chapters.
    const chapters = useMemo(
        function renderChapters(): ReactElement[] {
            if (!tuto.isSuccess || !validation.isSuccess) {
                return [];
            }

            return tuto.data.majorSteps.map(function renderChapter(chapter: MajorStep, index: number): ReactElement {
                return <Chapter
                    key={index}
                    details={validation.data.attributes.detail[index]}
                    chapter={chapter}
                    update={buildChapterUpdate(index)}
                />;
            });
        },
        [
            buildChapterUpdate,
            tuto.data?.majorSteps,
            tuto.isSuccess,
            validation.data?.attributes.detail,
            validation.isSuccess
        ]
    );

    // Wait for the tuto to be loaded.
    if (!tuto.isSuccess) {
        return <Loader transparent containerClassName={css["loader"]} text="Chargement du ZaqTuto+ ..." />;
    }
    // Wait for the validation to be loaded.
    if (!validation.isSuccess) {
        return <Loader
            transparent
            containerClassName={css["loader"]}
            text="Chargement de la validation précédente ..."
        />;
    }
    return <div className={css["container"]}>
        <span className={`${css["header"]} ${css["header--first"]}`}>Étape</span>
        <span className={css["header"]}>Validé ?</span>
        <span className={`${css["header"]} ${css["header--last"]}`}>Remarques</span>
        {chapters}
    </div>;
}

/** Props passed down to the {@link ZaqTuto} component. */
export interface ZaqTutoProps {
    /** The identifier of the user that is being validated. */
    user: string;
    /** The identifier of the ZaqTuto that is being validated. */
    "zaq-tuto": string;
}
