/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useEffect, useState } from "react";
// Import React-Bootstrap.
import { Modal } from "react-bootstrap";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";

// Import the favourite tool.
import { subscribeToFavourites } from "../storage";

// Import the icons.
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faWarning } from "@fortawesome/free-solid-svg-icons/faWarning";
// Import the css.
import css from "./favourite-warning.module.scss";


/** Modal used to warn the user that the favourites are not shared across devices. */
export default function FavouriteWarningModal(): ReactElement {
    // Store the visibility state of the modal.
    const [visible, setVisible] = useState(false);
    // Store the cleared state of the modal.
    const [cleared, setCleared] = useState(
        window.localStorage.getItem("andromeda/search/favourite-warning-dispatched") === "true"
    );

    // Listen for changes in the list of favourites.
    useEffect(function listenForFavouritesChange(): void | VoidFunction {
        // If the modal was cleared, stop showing it.
        if (cleared) {
            return;
        }

        // Subscribe to changes in the favourites.
        return subscribeToFavourites(function onFavouriteChange(favourites: ResourceIdentifier[]): void {
            // Check if any favourites were set.
            if (favourites.length <= 0) {
                return;
            }

            // Show the modal.
            setVisible(true);
        });
    }, [cleared]);

    // Callback used to clear the modal.
    const clearModal = useCallback(function clearWarningModal(): void {
        try {
            window.localStorage.setItem("andromeda/search/favourite-warning-dispatched", "true");
        } catch (e: unknown) {
            console.warn("Failed to set warning-dispatched flag: %O", e);
        }
        setCleared(true);
        setVisible(false);
    }, []);

    // Render the modal.
    return <Modal
        show={visible}
        centered
        size="sm"
        className={css["modal"]}
        dialogClassName={css["modal__dialog"]}
        contentClassName={css["modal__content"]}
        backdropClassName={css["modal__backdrop"]}
    >
        <Modal.Header className={css["header"]}>
            <FontAwesomeIcon className={css["icon"]} icon={faWarning} />
            <h1 className={css["title"]}>Attention !</h1>
        </Modal.Header>
        <Modal.Body className={css["body"]}>
            <p className={css["text"]}>
                Les éléments défini comme "favoris" ne sont pas synchronisés entre appareils pour le moment.
            </p>
            <button className={css["button"]} onClick={clearModal}>
                <FontAwesomeIcon className={css["button__icon"]} icon={faCheck} />
                J'ai compris
            </button>
        </Modal.Body>
    </Modal>;
}
