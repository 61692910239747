/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the organisation and user stores.
import { OrganisationStore, UserStore } from "@andromeda/store";
// Import the context.
import { LoginProvider as UnwrappedLoginProvider, LoginContext } from "./context";


// Re-export the hooks.
export * from "./hooks";
// Re-export the password reset handler.
export { PasswordResetHandler } from "./password-reset-handler";

// Wrap the provider.
export const LoginProvider = OrganisationStore.withReducer(UserStore.withReducer(UnwrappedLoginProvider));
export { LoginContext };
