/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Re-export all the interfaces.
export * from "./assets";
export * from "./course";
export * from "./organisation";
export * from "./permissions";
export * from "./zaq-wiki";
export * from "./share-link";
