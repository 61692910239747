/*
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

// Import RxJS.
import type { Observable } from "rxjs";

// Import the serialised error interface.
import { serialiseError, SerialisedError } from "./sanitise-error";


/** Interface used to define the status of an uninitialised asset. */
interface UninitialisedStatus {
    /** Flag set to {@code true} when the request is not initialised. */
    isUninitialised: true;
    /** @borrows {LoadingStatus.isLoading} */
    isLoading: false;
    /** @borrows {SuccessStatusWithData.isSuccess} */
    isSuccess: false;
    /** @borrows {ErrorStatus.isError} */
    isError: false;
    /** @borrows {SuccessStatusWithData.data} */
    data?: undefined;
    /** @borrows {ErrorStatus.error} */
    error?: undefined;
}

/** Interface used to define the status of a runnin request. */
interface LoadingStatus<M> {
    /** @borrows {UninitialisedStatus.isUninitialised} */
    isUninitialised: false;
    /** Flag set to {@code true} when the request is being run. */
    isLoading: true;
    /** Metadata */
    loadingMeta: M;
    /** @borrows {SuccessStatusWithData.isSuccess} */
    isSuccess: false;
    /** @borrows {ErrorStatus.isError} */
    isError: false;
    /** @borrows {SuccessStatusWithData.data} */
    data?: undefined;
    /** @borrows {ErrorStatus.error} */
    error?: undefined;
}

/** Interface used to define the status of a completed request. */
interface SuccessStatusWithData<T> {
    /** @borrows {UninitialisedStatus.isUninitialised} */
    isUninitialised: false;
    /** @borrows {LoadingStatus.isLoading} */
    isLoading: false;
    /** Flag set to {@code true} when the data was retrieved from the server. */
    isSuccess: true;
    /** @borrows {ErrorStatus.isError} */
    isError: false;
    /** Data downloaded from the server. */
    data: T;
    /** @borrows {ErrorStatus.error} */
    error?: undefined;
}

/** Interface used to define the status of a completed request. */
interface SuccessStatusWithoutData {
    /** @borrows {UninitialisedStatus.isUninitialised} */
    isUninitialised: false;
    /** @borrows {LoadingStatus.isLoading} */
    isLoading: false;
    /** @borrows {SuccessStatusWithData.isSuccess} */
    isSuccess: true;
    /** @borrows {ErrorStatus.isError} */
    isError: false;
    /** @borrows {ErrorStatus.error} */
    error?: undefined;
}

/** Interface used to define the status of a failed request. */
interface ErrorStatus {
    /** @borrows {UninitialisedStatus.isUninitialised} */
    isUninitialised: false;
    /** @borrows {LoadingStatus.isLoading} */
    isLoading: false;
    /** @borrows {SuccessStatusWithData.isSuccess} */
    isSuccess: false;
    /** Flag set to {@code true} when the request failed. */
    isError: true;
    /** @borrows {SuccessStatusWithData.data} */
    data?: undefined;
    /** Serialised error that arose when running the request. */
    error: SerialisedError;
}

/** Union of all the status interfaces. */
export type RequestStatus<T = void, M = unknown> =
    | UninitialisedStatus
    | LoadingStatus<M>
    | (undefined extends T ? SuccessStatusWithoutData : SuccessStatusWithData<T>)
    | ErrorStatus;

/** Augment the {@link RequestStatus} interface. */
export namespace RequestStatus {
    /** Builds a new "uninitialised" request status object. */
    export function uninitialised(): UninitialisedStatus {
        return { isUninitialised: true, isLoading: false, isSuccess: false, isError: false };
    }

    /** Builds a new "loading" request status object. */
    export function loading<M extends void>(): LoadingStatus<M>;
    /** Builds a new "loading" request status object. */
    export function loading<M>(meta: M): LoadingStatus<M>;
    /** Builds a new "loading" request status object. */
    export function loading<M = unknown>(...meta: undefined extends M ? [] : [M]): LoadingStatus<M> {
        return { isUninitialised: false, isLoading: true, loadingMeta: meta[0] as M, isSuccess: false, isError: false };
    }

    /** Builds a new "success" request status object. */
    export function success<T>(data: T): SuccessStatusWithData<T>;
    /** Builds a new "success" request status object. */
    export function success(): SuccessStatusWithoutData;
    /** Builds a new "success" request status object. */
    export function success(data?: unknown): SuccessStatusWithoutData | SuccessStatusWithData<unknown> {
        if (typeof data !== "undefined") {
            return { isUninitialised: false, isLoading: false, isSuccess: true, data, isError: false };
        }
        return { isUninitialised: false, isLoading: false, isSuccess: true, isError: false };
    }

    /** Builds a new "error" request status object. */
    export function error(error: unknown): ErrorStatus {
        // Serialise the error.
        return {
            isUninitialised: false,
            isLoading: false,
            isSuccess: false,
            isError: true,
            error: serialiseError(error)
        };
    }

    /**
     * Helper method used to convert a {@link RequestStatus} observable to a promise.
     * The promise will resolve when the status is a success, and reject when it is an error.
     *
     * @template T
     * @param {Observable<RequestStatus<T>>} request The request to wait for.
     * @returns {Promise<T>} A promise that resolves when the underlying request is complete.
     */
    export function wait<T>(request: Observable<RequestStatus<T>>): Promise<T> {
        return new Promise<T>(function waitForRequestResolution(resolve, reject): void {
            const subscription = request.subscribe({
                error(error: unknown): void {
                    subscription.unsubscribe();
                    reject(error);
                },
                next(value: RequestStatus<T>): void {
                    if (value.isSuccess) {
                        subscription.unsubscribe();
                        if ("data" in value) {
                            resolve(value.data);
                        } else {
                            (resolve as () => void)();
                        }
                    } else if (value.isError) {
                        subscription.unsubscribe();
                        reject(value.error);
                    }
                },
                complete(): void {
                    subscription.unsubscribe();
                    reject(new Error("Request was completed without any value."));
                }
            });
        });
    }
}
