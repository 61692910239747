/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the resource interface.
import { Resource } from "@andromeda/json-api";
// Import the schema compiler.
import { compileSync } from "@andromeda/validation";

// Import the rule interface.
import { Rule as BaseRule, ResourceRules, TemplateSource } from "./rule";
// Import the schema.
import * as schema from "./schema.json";
import * as updateSchema from "./update.schema.json";
import * as createSchema from "./create.schema.json";

/**
 * Interface used to describe a permission resource.
 * Permissions are used to define what a given user can or cannot do.
 */
export interface Permissions extends Resource<Permissions.Type> {
    /** @inheritDoc */
    attributes: Permissions.Attributes;
}

/** Namespace used to augment the {@link Permissions} interface. */
export namespace Permissions {
    /** Type of the permissions resource. */
    export const Type = "permissions" as const;
    export type Type = typeof Type;

    /** Attributes of the {@link Permissions} object. */
    export interface Attributes {
        /** List of objects for which a rule can be applied. */
        rules: ResourceRules;
    }

    /** Validate function. */
    export const validator = compileSync<Permissions>(schema);

    // Re-export the rule.
    export type Rule = BaseRule;
}

/** Interface used to describe a permission update resource. */
export interface PermissionsUpdate extends Resource<Permissions.Type> {
    /** Attributes of an update. */
    attributes: PermissionsUpdate.Attributes;
}

/** Namespace used to augment the {@link PermissionsUpdate} interface. */
export namespace PermissionsUpdate {
    /** Attributes of the {@link PermissionsUpdate} object. */
    export interface Attributes {
        /** Authorized party being altered. */
        authorizedParty?: string;
        /** Template object used by the rules. */
        template: TemplateSource;
        /** List of objects for which a rule can be applied. */
        rules: ResourceRules;
    }

    /** Validate function. */
    export const validator = compileSync<PermissionsUpdate>(updateSchema);
}

/** Interface used to describe a permission creation resource. */
export interface PermissionsCreate extends Resource<Permissions.Type> {
    /** Attributes of an update. */
    attributes: PermissionsCreate.Attributes;
}

/** Namespace used to augment the {@link PermissionsCreate} interface. */
export namespace PermissionsCreate {
    /** Attributes of the {@link PermissionsCreate} object. */
    export interface Attributes {
        /** Authorized party being altered. */
        authorizedParty?: string;
        /** Template object used by the rules. */
        template: TemplateSource;
        /** List of objects for which a rule can be applied. */
        rules: ResourceRules;
    }

    /** Validate function. */
    export const validator = compileSync<PermissionsCreate>(createSchema);
}
