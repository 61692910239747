/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the data message interface.
import { DataMessage, InfoMessage, DataMessageWithoutId as DataMessageWithoutIdType } from "./data";
// Import the error message interface.
import { ErrorMessage } from "./error";


// Re-export all the common interfaces.
export * from "./common";
// Re-export all the data interfaces.
export * from "./data";
// Re-export all the error interfaces.
export * from "./error";
// Re-export all the jsonapi interfaces.
export * from "./json-api";
// Re-export all the links interfaces.
export * from "./links";
// Re-export all the metadata interfaces.
export * from "./meta";
// Re-export all the relationship interfaces.
export * from "./relationship";
// Re-export all the resource interfaces.
export * from "./resource";
// Re-export all the resource identifier interfaces.
export * from "./resource-identifier";

/**
 * Aggregate of the message interfaces.
 * This MUST be used for ALL JSON:API communications.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export type Message = InfoMessage & DataMessage & ErrorMessage;

/** Re-export the data message without id. */
export type DataMessageWithoutId = DataMessageWithoutIdType;
