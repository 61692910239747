/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { Context, createContext, useContext, useMemo } from "react";
// Import the noop helper tool.
import { makeContextNoop } from "@andromeda/tools";


/**
 * Interface used to describe the context provided for the toggleable button.
 * Provides an easy way to check the status of the button.
 */
export interface ToggleableButtonContext {
    /** The current state of the context. */
    readonly state: boolean;

    /** Callback to invoke in order to toggle the value of the context. */
    toggle(): void;

    /**
     * Sets the state of the toggled context.
     *
     * @param {boolean} state The new state of the context.
     */
    toggle(state: boolean): void;
}

/**
 * Helper interface used for the context's default value.
 * Detects if the context was provided in the virtual DOM tree.
 */
interface DefaultToggleableButtonContext extends ToggleableButtonContext {
    /** Flag that is set if this is the default context. */
    __isDefault: true;
}

/** Default value for the {@link ToggleableButtonContext} contexts. */
const DEFAULT_VALUE: DefaultToggleableButtonContext = {
    __isDefault: true,
    state: false,
    toggle: makeContextNoop("ToggleableButtonContext", "toggle"),
}

/**
 * Builds a new {@link Context<ToggleableButtonContext>} instance.
 *
 * @returns {Context<ToggleableButtonContext>} The newly generated context.
 */
export function makeToggleableButtonContext(): Context<ToggleableButtonContext> {
    return createContext<ToggleableButtonContext>(DEFAULT_VALUE);
}

/** Default context used for the {@link ToggleableButtonContext}'s consumers. */
export const DefaultToggleableButtonContext = createContext<ToggleableButtonContext>(DEFAULT_VALUE);

/**
 * Simple hook used to check if the given context is mounted in the DOM tree.
 *
 * @param {React.Context<ToggleableButtonContext>} [context=DefaultToggleableButtonContext] The context to check.
 * @return {boolean} True if the context was provided somewhere in the tree.
 */
export function useIsInToggleableButtonContext(context = DefaultToggleableButtonContext): boolean {
    // Load the value of the context.
    const { __isDefault } = useContext(context) as { __isDefault?: unknown };

    // Check if the default flag is set.
    return useMemo(function checkIfMountedUnderProvider(): boolean {
        return !__isDefault;
    }, [__isDefault]);
}
