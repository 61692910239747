/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json:api resource interface.
import { Resource } from "@andromeda/json-api";
// Import the json:schema compiler
import { compileSync } from "@andromeda/validation";

// Import the schema.
import * as Schema from "./schema.json";


/** Interface used to update the status of the asset converter. */
export interface AssetConverterStatus extends Omit<Resource<AssetConverterStatus.Type>, "relationships"> {
    /** @inheritDoc */
    attributes: AssetConverterStatus.Attributes;
}

/** Augment the {@link AssetConverterStatus} interface. */
export namespace AssetConverterStatus {
    /** Type of {@link AssetConverterStatus} resources. */
    export const Type = "asset-conversion_converter-status" as const;
    export type Type = typeof Type;

    /** Enumeration of all the readiness stages for the converter. */
    export enum ReadinessStage {
        /** The server image is beign built. */
        preparing = "preparing",
        /** The server is currently starting up. */
        starting = "starting",
        /** The server is booted up and ready to take requests. */
        ready = "ready",
    }

    export interface Attributes {
        /** Current stage of the converter. */
        stage: ReadinessStage;
    }

    export const validate = compileSync<AssetConverterStatus>(Schema);

    /** Helper used to make new {@link AssetConverterStatus} instances. */
    export function make(id: string, stage: ReadinessStage): AssetConverterStatus {
        return { type: AssetConverterStatus.Type, id, attributes: { stage } };
    }
}
