/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

/** Helper function used to normalise a string. */
export function normalise<T extends string | null | undefined>(value: T): T {
    if (!value) return value;
    return value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase() as T;
}

export function compareText(a: string, b: string): number {
    return normalise(a).replace(/[&/\\#,+()$~%.'":*?<>{}[\]]/g, "")
        .localeCompare(normalise(b).replace(/[&/\\#,+()$~%.'":*?<>{}[\]]/g, ""));
}
