/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";
// Import the unleash client.
import { UnleashClient } from "unleash-proxy-client";

// Import the login config tool.
import { useLoginConfig } from "../config/config";


/** Builds the unleash client for the gitlab feature flags. */
export function useUnleashClient(userId?: string): UnleashClient | undefined {
    // Store the client in the state of the tool.
    const [ client, setClient ] = React.useState<UnleashClient>();

    // Load the component when the hook is mounted.
    const config = useLoginConfig();
    React.useEffect(function buildUnleashClient(): void | (() => void) {
        if (!config || !userId) return;

        const url = new URL(config.unleash.url, window.location.href).toString();
        const client = new UnleashClient({ ...config.unleash, url });
        client.setContextField("userId", userId);
        client.start().then(() => setClient(client), console.error);
        return () => client.stop();
    }, [config, userId]);

    return client;
}
