/*
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { CSSProperties, ReactElement } from "react";
// Import the font-awesome icon.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the progress bar.
import Progress from "./progress";

// Import the icons.
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./index.module.scss";

/** Component used to render a loader on the screen. */
export default function Loader(props: LoaderProps): ReactElement | null {
    // Compute the class names.
    let className = css["loader"];
    if (props.className) {
        className += " " + props.className;
    }
    let containerClassName = css["loader__container"];
    if (props.containerClassName) {
        containerClassName += " " + props.containerClassName;
    }
    if (props.transparent) {
        containerClassName += " " + css["loader__container--transparent"];
    }

    // Render the text.
    let text: ReactElement | null = null;
    if (typeof props.text === "string") {
        text = <p className={css["loader__text"]} children={props.text} />;
    }

    // Render the loader.
    if (props.show === false) {
        return null;
    }
    return <div className={containerClassName} style={props.containerStyle}>
        <FontAwesomeIcon icon={faSpinner} className={className} />
        {text}
        <Progress value={props.value} />
    </div>;
}

/** Props passed down to the {@link Loader} component. */
export interface LoaderProps {
    /** Text that will be rendered under the loader. */
    text?: string;
    /** Current progress value of the loader. MUST be in the interval [0, 1]. */
    value?: number;
    /** Flag set if the loader should be visible. */
    show?: boolean;

    /** Class name added to the loader element. */
    className?: string;
    /** Class name added to the loader's container element. */
    containerClassName?: string;

    /** Style applied to the container. */
    containerStyle?: CSSProperties;

    /** If set, clears the background from the loader. */
    transparent?: boolean;
}
