/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the debug tool.
import debug from "debug";
// Import the event classes.
import { EventHandler, Event } from "@andromeda/events";


/**
 * Class used to handle a data queue.
 * Can be written to and, later read back from.
 * Provides an event-based and a promise-based interface for data retrieval.
 *
 * @template T
 */
export class DataQueue<T> extends EventHandler<EventMap<T>> {
    /** Abort controller used to stop the running listeners in the queue. */
    public readonly signal: AbortController;
    /** Flag set if the queue should be buffering data. */
    public buffering = false;

    /** Class constructor. */
    public constructor() {
        super();
        this.signal = new AbortController();
        this.signal.signal.addEventListener("abort", () => { this.clear(); });
    }

    /** @return {number} The number of objects in the queue. */
    public get bufferedAmount(): number { return this._queue.length; }

    /**
     * Pushes a new data item to the queue.
     *
     * @param {T} data The data to push to the queue.
     */
    public push(data: T): void {
        if (this.signal.signal.aborted) throw new Error("DataQueue is closed !");
        log("Pushing a new data item");

        // Type-cast forced because T could be void and that confuses typescript.
        (this.dispatchEvent as (key: keyof EventMap<T>, data: T) => Promise<Event<T>>)("data", data)
            .then(event => {
                log("Was data consumed ? %o", event.aborted);
                if (!event.aborted && this.buffering) { this._queue.push(data); }
            });
    }

    /**
     * Reads a data item from the queue.
     * If no data was found, waits for the next "push" event.
     *
     * @param {AbortSignal} [signal=undefined] If provided, a signal that rejects the promise.
     * @returns {Promise<T>} A promise that resolves once some data is available.
     */
    public read(signal?: AbortSignal): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const enqueued = this._queue.shift();
            if (enqueued) {
                log("Reading from the buffered data queue.");
                return resolve(enqueued);
            }

            log("Waiting for the next data signal ...");
            if (signal) signal.addEventListener("abort", reject);
            this.signal.signal.addEventListener("abort", reject);
            this.addEventListener("data", event => {
                // Consume the event.
                event.abort();
                resolve(event.value);
            }, { once: true, signal });
        });
    }

    /** @borrows AbortController.abort */
    public abort(): void { this.signal.abort(); }

    /** List of all the data available in the queue. */
    private readonly _queue: T[] = [];
}

/** Event map of the {@link DataQueue} class. */
interface EventMap<T> {
    /** Event fired when a new data item is pushed to the queue. */
    data: T;
}

const log = debug("queue:data");
