/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { Context, ReactElement, ReactNode, useEffect, useMemo, useState } from "react";
// Import RxJS.
import { BehaviorSubject, throttleTime, asyncScheduler } from "rxjs";
// Import the string normaliser.
import { normalise } from "@andromeda/tools";

// Import the search bar context.
import { SearchBarContext } from "./index";
// Import the search bar item interface.
import { SearchBarCategory, SearchResultItem } from "./interface";


/** Component used to render a provider for the {@link SearchBarContext}. */
export function SearchBarContextProvider<T>(props: SearchBarContextProviderProps<T>): ReactElement {
    // Store the text.
    const [text, setText] = useState(props.initialText ?? "");
    const textNorm = useMemo(() => normalise(text), [text]);

    // Observable used to debounce the text value.
    const [text$] = useState(new BehaviorSubject<string>(text));
    const next = useMemo(() => text$.next.bind(text$), [text$]);
    useEffect(function listenToTextUpdates(): () => void {
        const subscription = text$
            .pipe(throttleTime(props.throttleTime ?? 500, asyncScheduler, { trailing: true }))
            .subscribe(setText);

        // Unsubscribe when the component is unmounted.
        return function detachTextUpdateListener(): void {
            subscription.unsubscribe();
        }
    }, [props.throttleTime, text$]);

    // Find all the categories with at least one match.
    const matchedCategories = useMemo(function findMatchingItems(): SearchBarCategory<T>[] {
        return props.categories
            .map(function filterCategoryItems(category: SearchBarCategory<T>): SearchBarCategory<T> {
                return {
                    ...category,
                    items: category.items.filter(function filterItem(item: SearchResultItem): boolean {
                        return normalise(item.text).includes(textNorm);
                    })
                }
            })
            .filter(function filterEmptyCategory(category: SearchBarCategory<T>): boolean {
                return category.items.length > 0;
            })
    }, [textNorm, props.categories]);

    // Flatten the categories list.
    const matches = useMemo(function flattenCategoriesList(): SearchResultItem<T>[] {
        return matchedCategories.reduce(
            function flattenCategory(acc: SearchResultItem<T>[], cur: SearchBarCategory<T>): SearchResultItem<T>[] {
                return [...acc, ...cur.items ];
            },
            []
        );
    }, [matchedCategories]);

    // Flatten the item list.
    const items = useMemo(function listAllItems(): SearchResultItem<T>[] {
        return props.categories.reduce(
            function flattenCategory(acc: SearchResultItem<T>[], cur: SearchBarCategory<T>): SearchResultItem<T>[] {
                return [...acc, ...cur.items ];
            },
            []
        );
    }, [props.categories]);

    // Build the context.
    const context = useMemo(function buildSearchBarContext(): SearchBarContext<T> {
        return { items, text, textNorm, matches, matchedCategories, setText: next };
    }, [items, text, textNorm, matches, matchedCategories, next]);

    // Render the component.
    return <props.context.Provider value={context} children={props.children} />;
}

/** Props passed down to the {@link SearchBarContextProvider} component. */
export interface SearchBarContextProviderProps<T> {
    /** The list of items to provide in the context. */
    categories: SearchBarCategory<T>[];

    /** The search context to provide. */
    context: Context<SearchBarContext<T>>;
    /** The initial value of the text to render in the bar. */
    initialText?: string;
    /**
     * Throttle time applied to avoid changes that are too frequent.
     *
     * @default 500
     */
    throttleTime?: number;

    /** The list of children that will be provided access to the context. */
    children?: ReactNode;
}
