/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the hooks.
import type { Level, TargetLevel } from "../../hooks";
// Import the level icon.
import LevelIcon from "../level-icon";

// Import the css.
import css from "./level.module.scss";


/** Component used to render the level of a {@link ZaqTraining}. */
export default function TrainingLevel(props: TrainingLevelProps): ReactElement {
    return <>
        <LevelIcon
            className={classNames(css["level"], css["level--expected"], props.expectedClassName)}
            level={props.expected}
            time={250}
            delay={0}
        />
        <LevelIcon
            className={classNames(css["level"], css["level--achieved"], props.achievedClassName)}
            level={props.achieved}
            time={250}
            delay={500}
        />
    </>;
}

/** Props passed down to the {@link TrainingLevel} component. */
export interface TrainingLevelProps {
    /** The expected level of the user. */
    expected: TargetLevel;
    /** The achieved level of the user. */
    achieved: Level;
    /** Optional class name added to the expected {@link LevelIcon}. */
    expectedClassName?: string;
    /** Optional class name added to the achieved {@link LevelIcon}. */
    achievedClassName?: string;
}
