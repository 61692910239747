/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import type { ReactElement } from "react";

// Import the confirmation hook
import { useConfirm, ConfirmBodyProps } from "..";

// Import the css.
import css from "./confirm.module.scss";


/**
 * Helper hook used to render a "delete" confirmation.
 *
 * @param {string} text The text to render in the modal.
 * @returns {() => Promise<boolean>} An invocable callback that returns a boolean promise.
 */
export function useDeleteConfirm(text: string): () => Promise<boolean> {
    return useConfirm({
        title: "Suppression",
        centered: true,
        cancel: false,
        body: DeleteConfirmBody
    });

    /** Internal component used to render the body of the confirmation modal. */
    function DeleteConfirmBody(props: ConfirmBodyProps<boolean>): ReactElement {
        return <div className={css["confirm"]}>
            <p className="text-center">
                {text}<br />
                <em>
                    <span role="img" aria-label="warning">⚠️</span>
                    Cette action est IRRÉVERSIBLE
                    <span role="img" aria-label="warning">⚠️</span>
                ️</em>
            </p>
            <div className={css["button__container"]}>
                <button
                    className={`${css["button"]} ${css["button--validate"]}`}
                    onClick={() => props.resolve(true)}
                    children="Valider"
                />
                <button
                    className={`${css["button"]} ${css["button--cancel"]}`}
                    onClick={() => props.resolve(false)}
                    children="Annuler"
                />
            </div>
        </div>;
    }
}
