/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, SyntheticEvent, useCallback, useContext } from "react";
// Import React-router.
import { useNavigate } from "react-router-dom";
// Import the font-awesome icon.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the custom components.
import { useDeleteConfirm, useNotify } from "@andromeda/components";
// Import the store.
import { CourseStore, useResourceDispatch } from "@andromeda/store";
// Import the login module.
import { useIsAdmin } from "@andromeda/login";

// Import the editor switch.
import { EditorSwitchContext } from "../../../context";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./delete.module.scss";


/** Component used to render the delete button for this course. */
export default function DeleteButton(props: DeleteButtonProps): ReactElement | null {
    const { fatal } = useNotify();
    const navigate = useNavigate();
    const dispatch = useResourceDispatch();
    const isAdmin = useIsAdmin();
    const { state: editable } = useContext(EditorSwitchContext);

    // Render the delete button if the user is an admin.
    const deleteConfirm = useDeleteConfirm("Êtes vous sûr de vouloir supprimer ce ZaqTraining ?");
    const deleteCourse = useCallback(function deleteCourse(event: SyntheticEvent): void {
        event.stopPropagation();

        deleteConfirm().then(confirmed => {
            if (!confirmed) return;

            dispatch(CourseStore.generator.delete(props.id))
                .then(() => navigate("/zaq-training"))
                .catch(fatal);
        });
    }, [props.id, deleteConfirm, dispatch, navigate, fatal]);

    // Render the component.
    if (!isAdmin || !editable) return null;
    return <button className={css["delete-button"]} onClickCapture={deleteCourse}>
        <FontAwesomeIcon className={css["delete-button__icon"]} icon={faTrash} />
    </button>;
}

export interface DeleteButtonProps {
    id: string;
}
