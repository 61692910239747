/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the redux store interface.
import { Store } from "redux";
// Import the redux thunk dispatch interface.
import { ThunkDispatch } from "redux-thunk";
// Import the store module.
import { CRUDAction, ResourceStore, store, WithResourceStore } from "@andromeda/store";
// Import the resources.
import { Asset, EncryptionSource } from "@andromeda/resources";
// Import the JSON:API tools.
import { Resource } from "@andromeda/json-api";


// Alias for the asset store's state.
type AssetStoreState = WithResourceStore<Asset> & WithResourceStore<EncryptionSource>;
// Alias for the asset store's CRUD actions.
type AssetCRUDAction = CRUDAction<[Asset, Asset.Update, Asset.Create]>;
// Alias for the asset store's CRUD actions.
type EncryptionSourceCRUDAction = CRUDAction<[EncryptionSource, never, EncryptionSource]>;
// Alias for the asset store's dispatch.
type AssetStoreDispatch = ThunkDispatch<AssetStoreState, never, AssetCRUDAction | EncryptionSourceCRUDAction>;
// Alias for the asset store interface.
type AssetStore = Store<AssetStoreState> & { dispatch: AssetStoreDispatch };

/** Helper function used to cast the {@link store} to the {@link AssetStore} type. */
export function getAssetStore(): AssetStore {
    // Check if the store has the asset and encryption source in its state.
    const state = store.getState();
    checkIfStateIsResourceStore(state, Asset.Type);
    checkIfStateIsResourceStore(state, EncryptionSource.Type);
    return store as unknown as AssetStore;

    /** Checks if the given state is a valid resource store. */
    function checkIfStateIsResourceStore<T extends Resource>(
        state: unknown,
        type: T["type"]
    ): asserts state is ResourceStore<T> {
        // Check the type of the state.
        if (typeof state !== "object" || state === null) {
            throw new InvalidReduxStoreError("The store is not an object !");
        }
        if (!(type in state)) {
            throw new InvalidReduxStoreError("The store has no \"" + type + "\" property !");
        }

        // Validate the object store.
        const objectStore = (state as { [K in T["type"]]: unknown })[type];
        if (typeof objectStore !== "object" || objectStore === null) {
            throw new InvalidReduxStoreError("The store \"" + type + "\" is not an object !");
        }
        if (!("type" in objectStore)) {
            throw new InvalidReduxStoreError("The store \"" + type + "\" has no \"type\" property !");
        }
        if ((objectStore as { type: unknown }).type !== type) {
            throw new InvalidReduxStoreError("The store \"" + type + "\"'s type is not valid !");
        }

        // Validate the resources array.
        if (!("resources" in objectStore)) {
            throw new InvalidReduxStoreError("The store \"" + type + "\" has no \"resources\" property !");
        }
        if (!Array.isArray((objectStore as { resources: unknown }).resources)) {
            throw new InvalidReduxStoreError("The store \"" + type + "\"'s \"resources\" is not an array !");
        }

        // Validate the boolean states.
        if (
            !("creating" in objectStore) ||
            !("reading" in objectStore) ||
            !("updating" in objectStore) ||
            !("deleting" in objectStore) ||
            typeof (objectStore as { creating: unknown }).creating !== "number" ||
            typeof (objectStore as { reading: unknown }).reading !== "number" ||
            typeof (objectStore as { updating: unknown }).updating !== "number" ||
            typeof (objectStore as { deleting: unknown }).deleting !== "number"
        ) {
            throw new InvalidReduxStoreError("The store \"" + type + "\" is missing some CRUD state !");
        }
    }
}

/** Custom error thrown if the redux store is invalid. */
export class InvalidReduxStoreError extends Error {}
