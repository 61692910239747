/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";
// Import the conversion asset class.
import { WebAsset } from "@andromeda/converter-lib";

// Import the media parser context.
import { MediaParserContext } from "../components";
import { Asset } from "@andromeda/resources";
import { v4 } from "uuid";


/** Helper hook used to prepare an asset for a conversion. */
export function useAssetPreparator(): (file: File) => Promise<WebAsset> {
    const { parse } = React.useContext(MediaParserContext);

    // Build the callback.
    return React.useCallback(async function assetPreparator(file: File): Promise<WebAsset> {
        // Check if the asset is a supported media object.
        let metadata = { size: file.size, contentType: file.type };
        if (file.type.startsWith("audio") || file.type.startsWith("image") || file.type.startsWith("video")) {
            metadata = { ...metadata, ...(await parse(file)) };
        }

        return {
            data: await file.arrayBuffer(),
            resource: {
                type: Asset.Type, id: v4(),
                attributes: metadata,
                relationships: {
                    encryptionSource: { data: null },
                    source: { data: null },
                    alternatives: { data: [] }
                }
            } as Asset
        }
    }, [parse]);
}
