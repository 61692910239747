/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useMemo } from "react";
// Import the React router.
import { Link, useInRouterContext } from "react-router-dom";

// Import the icons.
import chevronSecondary from "@andromeda/assets/images/chevron-blue.svg";
import chevronPrimary from "@andromeda/assets/images/chevron-blue.svg";
// Import the css.
import css from "./index.module.scss";


/**
 * Component used to render the title of a section.
 *
 */
export default function Title(props: TitleProps): ReactElement {
    // Render the subheading section.
    const subheading = useMemo(function renderSubheading(): ReactElement | null {
        if (typeof props.subtitle === "undefined") {
            return null;
        }

        return <p role="doc-subtitle" className={css["title__subheading"]} children={props.subtitle} />;
    }, [props.subtitle]);

    // Ensure that this component is mounted in a router.
    const isInRouterContext = useInRouterContext();
    const link = useMemo(function loadLinkComponent(): ReactElement | null {
        // If we are not in a router context, do not render the link.
        if (!isInRouterContext) {
            console.warn("You should mount the Title component under a <Router /> element.");
            return null;
        }

        // Render the link.
        return <Link to="#" className={css["title__link"]}>
            <img src={chevronSecondary} alt="go-back chevron" className={css["title__link__icon"]} />
            <img src={chevronPrimary} alt="go-back chevron highlighted" className={css["title__link__hover-icon"]} />
            <span className={css["title__link__text"]}>Back</span>
        </Link>;
    }, [isInRouterContext]);

    // Render the navigation bar.
    return <nav className={css["title"]}>
        {link}
        <h1 className={css["title__heading"]} children={props.title} />
        {subheading}
    </nav>;
}

/** Props used for the {@link Title} component. */
export interface TitleProps {
    section?: string;
    title: string;
    subtitle?: string;
}
