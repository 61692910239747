/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
// Import the auth0 context helper.
import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
// Import the organisation resource.
import { Organisation } from "@andromeda/resources";
// Import the loader component.
import { Loader, useNotify } from "@andromeda/components";
// Import the xhr class.
import { Xhr } from "@andromeda/xhr";
// Import the store.
import { useResourceDispatch, UserStore, OrganisationStore } from "@andromeda/store";
// Import the resource tools.
import { readOne, updateOne } from "@andromeda/resource-helper";

// Import the unleash client.
import { useUnleashClient } from "./unleash";
// Import the login context.
import { LoginContext, LoginInfo } from "./context";
// Import the login configuration.
import { useLoginConfig } from "../config/config";
import { compile } from "@andromeda/validation";


/** Provider for the {@link LoginContext}. */
export function LoginContextProvider(props: Props): ReactElement {
    // Load the auth0 context.
    const auth0 = useAuth0();
    // Load the login config.
    const config = useLoginConfig();
    // Get the error context.
    const notify = useNotify();

    // Log the user in.
    useEffect(
        function login(): void {
            // Check if an error arose.
            if (auth0.error) {
                return notify.fatal(auth0.error);
            }
            // Check if the user is already authenticated.
            if (auth0.isAuthenticated || auth0.isLoading) {
                return;
            }
            // Wait for the configuration to be loaded.
            if (config === null) {
                return;
            }

            // Build the authentication options.
            const options: RedirectLoginOptions = {
                redirectUri: window.location.origin,
                audience: config.audience,
                scope: "read:any create:any delete:any update:any",
                appState: {
                    returnTo: `${window.location.pathname}${window.location.hash}${window.location.search}`
                },
                prompt: "login"
            };

            auth0.loginWithRedirect(options).catch(notify.fatal);
        },
        [auth0, auth0.isAuthenticated, auth0.isLoading, config, notify]
    );

    // Load the user token.
    const [token, setToken] = useState<string>();
    useEffect(
        function loadToken(): void {
            // Ensure that the user is logged in.
            if (!auth0.isAuthenticated || auth0.isLoading) {
                return;
            }

            // Load the token.
            auth0.getAccessTokenSilently({ scope: "read:any create:any delete:any update:any" }).then(token => {
                Xhr.setAuthToken(token);
                return token;
            }).then(setToken);
        },
        [auth0, auth0.isAuthenticated, auth0.isLoading]
    );

    // Load the user from the api.
    const dispatch = useResourceDispatch();
    const user = UserStore.useSelector(
        state => state.resources.find(user => user.id === auth0.user?.sub),
        (a, b) => a?.id === b?.id
    );
    useEffect(
        function loadUser(): void {
            // Ensure that the user is authenticated.
            if (
                !auth0.isAuthenticated ||
                typeof auth0.user?.sub === "undefined"
            ) {
                return;
            }
            if (!token) {
                return;
            }

            // Load the user from the store.
            dispatch(
                UserStore.generator.readOne(auth0.user.sub, {
                    include: ["organisation"]
                })
            ).catch(notify.fatal);
        },
        [
            auth0.isAuthenticated,
            auth0.user?.sub,
            dispatch,
            notify.fatal,
            token
        ]
    );

    // Load the current organisation from local storage.
    const organisations = OrganisationStore.useSelector(
        state => state.resources
    );

    // Build the organization selector.
    const select = useCallback(function selectOrg(id: string): void {
        // If the config is not loaded, do nothing.
        if (!auth0.user?.sub) {
            return;
        }

        // Search for the organization in the list.
        const org = organisations.find(org => org.id === id);
        if (!org) {
            throw new Error("No such organisation \"" + id + "\"");
        }

        // Store the identifier in local storage.
        window.localStorage.setItem("com.zaqtiv.current-org", org.id);

        // Run the update on the API.
        const resource = {
            type: "user-organization",
            id: auth0.user.sub,
            relationships: { "current-organization": { data: { type: "organization", id: base64FromHex(id) } } }
        };
        updateOne(resource).then(() => window.location.assign("/")).catch(notify.fatal);
    }, [auth0, notify.fatal, organisations]);

    const [currentOrg, setCurrentOrg] = useState<Organisation>();
    useEffect(function loadCurrentOrganisation(): void {
        queryCurrentOrganization().then(setCurrentOrg, notify.fatal);

        /** Helper used to query the current organization from the management API. */
        async function queryCurrentOrganization(): Promise<Organisation | undefined> {
            // Do nothing if there are no organizations.
            if (organisations.length <= 0) {
                return;
            }

            // Wait for the user to be authenticated.
            if (!auth0.user?.sub || !auth0.isAuthenticated) {
                return undefined;
            }

            // Retrieve the current user's organization.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { data: organization } = await readOne<any, any>("user-organization", auth0.user.sub, compile(true));
            let currentOrganizationId = organization?.relationships["current-organization"]?.data?.id;
            if (currentOrganizationId) {
                currentOrganizationId = hexFromBase64(currentOrganizationId);

                // Search for the organization in the list.
                const organization =  organisations.find(({ id }) => id === currentOrganizationId);
                if (organization) {
                    return organization;
                }
            }

            // Read the id from storage.
            currentOrganizationId = localStorage.getItem("com.zaqtiv.current-org");

            // Find the organization from the list.
            let currentOrganization = organisations.find(({ id }) => id === currentOrganizationId);
            currentOrganization ??= organisations[0];

            // Make sure the organization is selected.
            select(currentOrganization.id);

            // Return the selected organization.
            return currentOrganization;
        }
    }, [auth0, config, notify.fatal, organisations, select]);

    // Load the Unleash client.
    const client = useUnleashClient(user?.id);

    // Build the context value.
    const value = useMemo<LoginInfo | null>(
        function loginContextLoader(): LoginInfo | null {
            // Check if the values are properly set.
            if (!auth0.isAuthenticated || typeof user === "undefined") {
                return null;
            }
            if (typeof token === "undefined") {
                return null;
            }
            if (typeof organisations === "undefined") {
                return null;
            }
            if (typeof currentOrg === "undefined") {
                return null;
            }
            if (typeof client === "undefined") {
                return null;
            }

            return {
                isLoggedIn: true,
                isAdmin: currentOrg.relationships.owner.data?.id === user.id
                    || currentOrg.relationships.administrators.data.some(usr => usr.id === user.id),
                self: user,
                token,
                name: user.attributes.givenName,
                organisations: {
                    own: organisations,
                    current: currentOrg,
                    select
                },
                logout() {
                    return auth0.logout({ returnTo: window.location.origin });
                },
                unleash: client
            };
        },
        [auth0, user, token, organisations, currentOrg, client, select]
    );

    // Render the component.
    if (value === null) {
        return <Loader text={"Chargement des informations utilisateur ..."} />;
    }
    return <LoginContext.Provider value={value} children={props.children} />;
}

/** Props passed down to the {@link LoginContextProvider} component. */
interface Props {
    children?: ReactNode;
}


/**
 * Helper used to map an organization id from hex to base64Url.
 *
 * @param {string} id The identifier to convert.
 * @returns {string} The converted identifier.
 */
function base64FromHex(id: string) {
    // Create a new buffer from the string data.
    const buffer = new Array<number>(id.length / 2);
    for (let index = 0; index < id.length; index += 2) {
        buffer[index / 2] = parseInt(id.slice(index, index + 2), 16);
    }

    // Convert the buffer to a binary string.
    const binaryString = buffer.map(byte => String.fromCharCode(byte)).join("");

    // Encode the binary string to base64url.
    return window.btoa(binaryString).replaceAll("+", "-").replaceAll("/", "_");
}

/**
 * Helper used to map an organization id from base64url to hex.
 *
 * @param {string} id The identifier to convert.
 * @returns {string} The converted identifier.
 */
function hexFromBase64(id: string) {
    // Decode the binary string from base64url.
    const binaryString = window.atob(id.replaceAll("-", "+").replaceAll("_", "/"));

    // Re-encode the binary string as hexadecimal.
    return Array.from(binaryString).map(char => char.charCodeAt(0).toString(16).padStart(2, "0")).join("");
}
