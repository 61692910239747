/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { createElement, useEffect, ComponentType, ReactNode, ReactElement } from "react";

// Import the registry interfaces.
import {
    ReducerSource,
    mountReducerInRegistry,
    unmountReducerFromRegistry
} from "../store/enhancers/registry";
// Import the registry hooks.
import { useStoreWithRegistry, useReducerIsMounted } from "../hooks";
import { Action, AnyAction } from "redux";


/**
 * Generates a new HOC that will mount the given reducer into the store.
 *
 * @template {object} P=object
 * @param {ComponentType<P>} component The component to wrap.
 * @param {string} name The name of the requested
 * @param {ReducerSource} source
 * @param {ReactNode} suspense
 * @returns {FunctionComponent<P>}
 */
export function withReducer<P extends object, S = unknown, A extends Action = AnyAction>(
    component: ComponentType<P>,
    name: string,
    source: ReducerSource<S, A>,
    suspense?: ReactNode
): ComponentType<P> {
    // Return the children.
    return function ReducerHOC(props: P): ReactElement | null {
        // Get a reference to the store.
        const store = useStoreWithRegistry();

        // Get the state of the reducer.
        const isMounted = useReducerIsMounted(name);

        // Mount the reducer with the component.
        useEffect(function mountReducer(): () => void {
            mountReducerInRegistry(store.registry, name, source);
            return () => unmountReducerFromRegistry(store.registry, name);
        }, []);

        // Render the component.
        if (isMounted) {
            return createElement(component, props);
        } else {
            return typeof suspense === "undefined" ? null : <>{suspense}</>;
        }
    };
}
