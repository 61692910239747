/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { CSSProperties, ReactElement } from "react";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the css.
import css from "./flag.module.scss";


/** Simple SVG component used to render the flags on top of the progress bar. */
export default function ProgressFlag(props: ProgressFlagProps): ReactElement {
    // Build the offset style.
    const style = {
        "--offset": `${(props.offset * 100).toFixed()}%`,
        "--delay": `${props.index / 2}s`
    } as CSSProperties;

    // Render the element.
    return <svg className={classNames(css["flag"], props.className)} viewBox="0 0 16 32" style={style}>
        <path strokeWidth={3} d="M2, 28 L2, 4 L12, 8 L2, 12" />
    </svg>
}

/** Props passed down to the {@link ProgressFlag} component. */
export interface ProgressFlagProps {
    /** The offset of the flag from the start of the bar. */
    offset: number;
    /** Index of the flag in the parent step. */
    index: number;
    /** Optional class name added to the root <svg> element. */
    className?: string;
}
