/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useContext } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the store.
import { StepStore, useResourceDispatch } from "@andromeda/store";
// Import the resources.
import { Course, Step } from "@andromeda/resources";
// Import the custom components.
import { useNotify } from "@andromeda/components";

// Import the custom contexts.
import { EditorSwitchContext } from "../../../context";

// Import the icons.
import { faPlus } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./add-step-icon.module.scss";


/** Component used to render the icon used to add a new step. */
export default function AddStepIcon(props: AddItemIconProps): ReactElement | null {
    const { state: editing } = useContext(EditorSwitchContext);

    // Callback used to create a new step.
    const { error } = useNotify();
    const dispatch = useResourceDispatch();
    const create = useCallback(function createStep(): void {
        dispatch(
            StepStore.generator.create(
                {
                    type: Step.Type,
                    attributes: {
                        name: ""
                    },
                    relationships: {
                        course: {
                            data: {
                                type: Course.Type,
                                id: props.course
                            }
                        }
                    }
                },
                { include: [Course.Type]}
            )
        ).catch(error);
    }, [dispatch, error, props.course]);

    // Render the component.
    if (!editing) {
        return null;
    }
    return <button className={css["button"]} onClick={create}>
        <FontAwesomeIcon className={css["button__icon"]} icon={faPlus} />
    </button>;
}

/** Props passed down to the {@link AddStepIcon} component. */
export interface AddItemIconProps {
    /** Identifier of the current course. */
    course: string;
}
