/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useEffect, useState } from "react";
// Import React-PDF.
import type PDFJS from "react-pdf/dist/pdfjs-dist";

// Import the document status.
import type { PDFDocumentStatus } from "../../component/document";
// Import the context.
import type { PDFContext } from "./index";
// Import the page status.
import type PDFPage from "./page";


/**
 * Helper hook used to derive the state of the {@link PDFContext} from a given document.
 *
 * @param {PDFDocumentStatus} status The status of the requested document.
 * @returns {PDFContext | null} The state of the context, or null if the context is not ready.
 */
export function useContextState(status: PDFDocumentStatus): PDFContext | null {
    // Store the state of the context.
    const [pageProxies, setPageProxies] = useState<PDFJS.PDFPageProxy[]>();
    const [pageStates, setPageStates] = useState<Pick<PDFPage, "rotation" | "scale">[]>([]);

    // Re-generate the page states when the document is ready.
    useEffect(function reloadPageStatesOnDocumentReady(): void {
        // If the document is not ready, clear the list.
        if (!status.isSuccess) {
            return setPageProxies(undefined);
        }

        // Build the list of all the pages.
        const proxies = new Array<Promise<PDFJS.PDFPageProxy>>(status.data.numPages);
        const states = new Array<Pick<PDFPage, "rotation" | "scale">>(status.data.numPages);
        for (let pageIndex = 0; pageIndex < status.data.numPages; pageIndex++) {
            proxies[pageIndex] = status.data.getPage(pageIndex + 1);
            states[pageIndex] = { rotation: 0, scale: 1 };
        }
        Promise.all(proxies).then(setPageProxies);
        setPageStates(states);
    }, [status.isSuccess, status.data]);

    // If the context is not ready yet, return a null.
    if (!status.isSuccess || typeof pageProxies === "undefined") {
        return null;
    }

    // Build all the page states from the proxies.
    const pages = new Array<PDFPage>(pageProxies.length);
    for (let pageIndex = 0; pageIndex < pageProxies.length; pageIndex++) {
        const pageViewport = pageProxies[pageIndex].getViewport({ scale: 1, rotation: pageStates[pageIndex].rotation });
        pages[pageIndex] = {
            rotation: pageStates[pageIndex].rotation,
            scale: pageStates[pageIndex].scale,
            viewport: { width: pageViewport.width, height: pageViewport.height },
            scaleBy(by: number) {
                const scale = Math.min(Math.max(pageStates[pageIndex].scale * by, 0.25), 4);
                pageStates.splice(pageIndex, 1, { ...pageStates[pageIndex], scale });
                setPageStates([...pageStates]);
            },
            rotate(clockwise = false) {
                let rotation: number = pageStates[pageIndex].rotation;
                if (clockwise) {
                    rotation -= 90;
                    if (rotation < 0) {
                        rotation = 270;
                    }
                } else {
                    rotation = (rotation + 90) % 360;
                }
                pageStates.splice(
                    pageIndex,
                    1,
                    { ...pageStates[pageIndex], rotation: rotation as PDFPage["rotation"] }
                );
                setPageStates([...pageStates]);
            }
        };
    }

    // Return the value of the context.
    return { pageCount: status.data.numPages, pages };
}
