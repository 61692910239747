/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the redux action interface.
import { Action, AnyAction } from "redux";
// Import the useDispatch base hook.
import { useDispatch } from "react-redux";
// Import the thunk dispatch interface.
import { ThunkDispatch } from "redux-thunk";

/**
 * Helper method used to generate a custom {@link useDispatch} hook.
 * Compatible with redux thunk.
 */
export function buildCustomUseDispatch<
    N extends string,
    S extends object = object,
    A extends Action = AnyAction,
    E = never
>(): () => ThunkDispatch<{ [k in N]: S }, E, A> {
    return function customUseDispatch(): ThunkDispatch<{ [k in N]: S }, E, A> {
        return useDispatch() as ThunkDispatch<{ [k in N]: S }, E, A>;
    };
}
