/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from "react";
// Import React-Bootstrap.
import { Modal } from "react-bootstrap";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the store.
import { ExternalStore, useAsset, useExternal, useResourceDispatch } from "@andromeda/store";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the custom components.
import { useNotify } from "@andromeda/components";
// Import the JSON:API module.
import { extractURL } from "@andromeda/json-api";
// Import the resources..
import { External } from "@andromeda/resources";

// Import the image helper hook.
import useImageConverter from "./use-image-converter";
// Import the subcomponents.
import ExternalForm from "./form";

// Import the icons.
import { faQuestion, faWarning, faXmark } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./modal.module.scss";


/** Component used to render the external form modal. */
export default function ExternalModal(props: ExternalModalProps): ReactElement {
    // Store the name and image of the external.
    const [name, setName] = useState<string>("");
    const [image, setImage] = useState<ReactNode>();

    // Download the external from the API.
    const external = useExternal(props.external);
    const icon = useAsset(external.data?.relationships.asset.data?.id);
    useEffect(function reloadNameOnLoad(): void {
        if (!external.isSuccess) {
            return;
        }
        setName(external.data.attributes.name);
    }, [external.data?.attributes.name, external.isSuccess]);
    useEffect(function reloadImageOnLoad(): void {
            if (!external.isSuccess) {
                return;
            }
            if (external.data.relationships.asset.data === null) {
                setImage(<FontAwesomeIcon icon={faQuestion} />);
            } else if (!icon.isSuccess) {
                setImage(<Loader />);
            } else if (typeof icon.data.links?.get !== "undefined") {
                setImage(<img src={extractURL(icon.data.links.get).toString()} alt="external-icon" />);
            } else {
                setImage(<FontAwesomeIcon icon={faWarning} />);
            }
        },
        [
            external.data?.attributes.name,
            external.data?.relationships.asset.data,
            external.isSuccess,
            icon.data?.links?.get,
            icon.isSuccess
        ]
    );

    // Callback used to update the image.
    const canvas = useRef<HTMLCanvasElement | null>(null);
    const updateImage = useImageConverter(props.external, canvas, setImage);

    // Saves the changes to the current external.
    const dispatch = useResourceDispatch();
    const { error } = useNotify();
    const save = useCallback(function saveChanges(): void {
        // Close the modal.
        props.hide();

        // Check if the external is set.
        if (typeof props.external === "undefined") {
            return;
        }

        // Save the changes to the external.
        const update: External.Update = {
            type: External.Type, id: props.external,
            attributes: { name }
        };
        dispatch(ExternalStore.generator.update(update)).catch(error);
    }, [dispatch, error, name, props]);


    // Render the component.
    return <Modal
        animation keyboard centered enforceFocus
        show={typeof props.external !== "undefined"}
        onHide={save}
        contentClassName={css["modal__content"]}
    >
        <Modal.Header className={css["header"]}>
            <h1 className={css["title"]}>Modifier une formation externe</h1>
            <button className={css["button"]} onClick={save}>
                <FontAwesomeIcon className={css["button__icon"]} icon={faXmark} />
            </button>
        </Modal.Header>
        <Modal.Body className={css["body"]}>
            <ExternalForm
                name={name}
                setName={setName}
                image={image}
                setImage={updateImage}
                hide={save}
            />
            <canvas width={256} height={256} className={css["canvas"]} ref={canvas} />
        </Modal.Body>
    </Modal>;
}

/** Props passed down to the {@link ExternalModal} component. */
export interface ExternalModalProps {
    /** Identifier of the rendered external. */
    external: string | undefined;

    /** Callback used to close the modal. */
    hide(): void;
}
