/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useContext, useState } from "react";
// Import the resources.
import { Requirement, Step, User } from "@andromeda/resources";

// Import the subcomponents.
import LevelSelect from "./level-select";
import UserList from "./user-list";
import UserSearchBar from "./user-search-bar";

// Import the css.
import css from "./index.module.scss";
import { RequirementStore, useRequirements, useResourceDispatch, useUsers } from "@andromeda/store";
import { LoginContext } from "@andromeda/login";
import { useNotify } from "@andromeda/components";


/** Component used to assign the requirements to the users. */
export default function AssignRequirement(props: AssignRequirementProps): ReactElement | null {
    // Load the first level to render in the list.
    const [level, setLevel] = useState<Requirement.Level>(Requirement.Level.novice);
    const users = useUsers(useContext(LoginContext).organisations.current.id);
    const requirements = useRequirements(props.course, users.data?.map(({ id }) => id));

    const { error } = useNotify();
    const dispatch = useResourceDispatch();

    // Callback used to add a new requirement to the list.
    const addRequirement = useCallback(function addRequirement(...users: User[]): void {
        // Dispatch all the updates.
        for (const user of users) {
            // Check if the user already has a requirement.
            const existingRequirement = requirements.data?.find(
                function isRequirementForUser(requirement: Requirement): boolean {
                    return requirement.relationships.user.data.id === user.id
                        && requirement.relationships.step.data.id === props.step;
                }
            );

            // Dispatch the action.
            if (existingRequirement) {
                dispatch(
                    RequirementStore.generator.update(
                        {
                            type: Requirement.Type,
                            id: existingRequirement.id,
                            attributes: {
                                requiredLevel: level
                            }
                        }
                    )
                ).catch(error);
            } else {
                dispatch(
                    RequirementStore.generator.create(
                        {
                            type: Requirement.Type,
                            attributes: { requiredLevel: level },
                            relationships: {
                                user: { data: { type: User.Type, id: user.id }},
                                step: { data: { type: Step.Type, id: props.step }}
                            }
                        }
                    )
                ).catch(error);
            }
        }
    }, [dispatch, error, level, props.step, requirements.data]);

    if (!props.show) {
        return null;
    }
    return <>
        <LevelSelect level={level} setLevel={setLevel} />
        <div className={css["body"]}>
            <UserList course={props.course} step={props.step} level={level} />
            <UserSearchBar course={props.course} step={props.step} level={level} select={addRequirement} />
        </div>
    </>;
}

export interface AssignRequirementProps {
    show: boolean;
    course: string;
    step: string;
}
