/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API module.
import type { Filter } from "@andromeda/json-api";
// Import the step resource.
import { Step } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the store.
import { StepStore, ResourceStore } from "../../stores";
// Import the resource hooks.
import useResources from "./util/use-resources";
import useResource from "./util/use-resource";


/**
 * Hook used to download a step from the store.
 *
 * @param {string | undefined} id The identifier of the requested step.
 * @param {boolean} [cache=true] Try to read from the cache if possible.
 * @return {RequestStatus<Step>} The status of the request.
 */
export function useStep(id: string | undefined, cache = true): RequestStatus<Step> {
    return useResource(StepStore, Step.Type, id, cache);
}

/**
 * Downloads all the step of the specified course.
 *
 * @param {string} course The course to load the steps for.
 * @return {RequestStatus<Step[]>} The status of the request.
 */
export function useSteps(course: string | undefined): RequestStatus<Step[]> {
    // Prepare the filter used for the request.
    let filter: Filter | undefined = undefined;
    if (course) {
        filter = { course: { id: { $eq: course }}};
    }

    // Prepare the selector used to load the steps.
    const selector = function selector(state: ResourceStore<Step>): Step[] {
        return state.resources.filter(function stepBelongsToOrg(step: Step): boolean {
            return step.relationships.course.data.id === course;
        });
    };

    // Return the resources.
    return useResources(StepStore, Step.Type, { filter }, selector);
}
