/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useMemo } from "react";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";
// Import the ShareLink resource.
import { ShareLink, ShareLinkType } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the store.
import { ShareLinkStore, ResourceStore } from "../../stores";
// Import the resource hooks.
import useResources from "./util/use-resources";
import useResource from "./util/use-resource";


/**
 * Hook used to download a ShareLink from the store.
 *
 * @param {string} id The identifier of the ShareLink.
 * @param {boolean} [cache=true] Try to read from the cache if possible.
 * @return {RequestStatus<ShareLink>} The status of the request.
 */
export function useShareLink(id: string, cache = true): RequestStatus<ShareLink> {
    return useResource(ShareLinkStore, ShareLinkType, id, cache);
}

/**
 * Downloads all the ShareLinks of the specified organisation.
 *
 * @param {ResourceIdentifier} item The item to load the share links for.
 * @return {RequestStatus<ShareLink[]>} The status of the request.
 */
export function useShareLinks(item: ResourceIdentifier): RequestStatus<ShareLink[]> {
    // Prepare the filter used for the request.
    const searchParams = useMemo(function buildSearchParams(): Record<string, string> {
        return { "filter[zaq]": item.id };
    }, []);

    // Prepare the selector used to load the share links.
    const selector = function selector(state: ResourceStore<ShareLink>): ShareLink[] {
        return state.resources.filter(function ShareLinkBelongsToOrg(shareLink: ShareLink): boolean {
            return shareLink.relationships.zaq.data.id === item.id;
        });
    };

    // Return the resources.
    return useResources(ShareLinkStore, ShareLinkType, { searchParams }, selector);
}
