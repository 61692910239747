/*
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */


/** Converts an error to a human-readable string. */
export function sanitiseError(error: Error): string {
    let message = error.message;

    // Add the source url to the error.
    if ("sourceURL" in error) {
        const { sourceURL } = error as unknown as { sourceURL: unknown };
        if (typeof sourceURL === "string") {
            message += `\n\nSource URL: ${sourceURL}`;
            if ("line" in error) {
                const { line } = error as unknown as { line: unknown };
                if (typeof line === "number" || typeof line === "string") {
                    message += ":" + line;
                    if ("column" in error) {
                        const { column } = error as unknown as { column: unknown };
                        if (typeof column === "number" || typeof column === "string") {
                            message += ":" + column;
                        }
                    }
                }
            }
        }
    }

    // Add the stack trace to the error.
    if (error.stack) {
        message += `\nStack trace:\n→ ${error.stack.split("\n").join("\n↳ ")}`;
    }

    return message;
}

/** Error that was serialised and sanitised for RTK. */
export interface SerialisedError {
    /** Additional keys that result from the error. */
    [key: string]: unknown;
    /** The message of the error. */
    message: string;
}

/***
 * Helper class used to serialise an error object.
 *
 * @param e The error to serialise.
 * @return {SerialisedError} The serialised error.
 */
export function serialiseError(e: unknown): SerialisedError {
    // If the error was already serialized, return it as-is.
    if (typeof e === "object" && e) {
        if ("message" in e && typeof e.message === "string") {
            return e as SerialisedError;
        }
    }

    // Cast the error to an Error object.
    let error: Error;
    if (e instanceof Error) {
        error = e;
    } else {
        error = new Error("Unhandled error type.", { cause: e as Error });
    }

    // Serialise the error.
    return { message: error.message, error: sanitiseError(error) };
}
