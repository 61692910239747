/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { MouseEvent, ReactElement, useState, useCallback, useRef, useEffect } from "react";
// Import the font awesome component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the fullscreen polyfill.
import "fullscreen-polyfill";

// Import the video's hms method.
import { toHoursMinutesAndSeconds } from "./audio";

// Import the icons.
import { faPause, faPlay, faRotateLeft, faRotateRight, faExpand } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./video.module.scss";


/** Component used to render a video module. */
export default function VideoModule(props: VideoModuleProps): ReactElement {
    // Store the duration of the video playback.
    const [duration, setDuration] = useState<number>(0);
    // Store the current icon.
    const [icon, setIcon] = useState(faPlay);
    // Determines if the overlay should be visible or not.
    const [showOverlay, setShowOverlay] = useState(true);

    // Reference to the <video> component.
    const video = useRef<HTMLVideoElement | null>(null);

    // References to the progress bar value and text.
    const progressValue = useRef<HTMLDivElement | null>(null);
    const progressText = useRef<HTMLSpanElement | null>(null);

    // Callback used to toggle the video component.
    const toggle = useCallback(function playingAudio() : void {
        // If the video ref is not set, do nothing.
        if (!video.current) {
            return;
        }

        // If the video was paused, play it.
        if (video.current.paused) {
            video.current.play().then(() => setShowOverlay(false));
        } else {
            video.current.pause();
        }
    },[])

    // Infers the duration of the video file from the current element.
    const inferVideoDuration = useCallback(function inferAudioDuration(): void {
        setDuration(video.current?.duration ?? 0)
    },[]);

    // Handler used to update the timing refs.
    const updateTimeIndicator = useCallback(function updateIndicator(video: HTMLVideoElement): void {
        // Update the current time indicator.
        if (progressText.current) {
            progressText.current.innerText = toHoursMinutesAndSeconds(video.currentTime);
        }

        // Update the progress bar.
        if (progressValue.current) {
            const progress = video.currentTime / duration;
            progressValue.current.style.width = `${Math.floor(progress * 100000) / 1000}%`;
        }
    }, [duration]);
    useEffect(function mountTimeUpdateInterval(): void | (() => void) {
        // If the reference to the video is unset, do nothing.
        if (!video.current) {
            return;
        }

        // Attach the interval to the video when it starts playing.
        let interval: number = Number.NaN;
        video.current.addEventListener("play", () => (interval = setInterval(updateTimeIndicator, 33, video.current)));
        video.current.addEventListener("pause", clearVideoListener);
        return clearVideoListener;

        // Method used to clear the interval.
        function clearVideoListener(): void {
            clearInterval(interval);
        }
    }, [updateTimeIndicator]);

    // Handler used to update the time of the video playback when the progressbar is clicked.
    const seek = useCallback(function seek(event: MouseEvent<HTMLElement>): void {
        // If the video ref is not set, do nothing.
        if(!video.current) {
            return;
        }

        // Compute the position of the click on the progress bar.
        const rect = event.currentTarget.getBoundingClientRect();
        const progress = (event.clientX - rect.left) / rect.width;

        // Update the video element.
        video.current.currentTime =  progress * duration;
    }, [duration]);

    // Render the component.
    return <div className={css["video"]}>
        {/* Render the video element. */}
        <video
            onLoadedMetadata={inferVideoDuration}
            onPlaying={() => setIcon(faPause)}
            onPause={() => setIcon(faPlay)}
            onSeeked={e => updateTimeIndicator(e.currentTarget)}
            onEnded={() => setShowOverlay(true)}
            ref={video}
            playsInline
            src={props.src}
            className={css["video__video"]}
        />

        {/* Render the player. */}
        <div
            className={`${css["video__overlay"]} ${css[`video__overlay--${showOverlay ? "visible" : "hidden"}`]}`}
            onClick={e => setShowOverlay(!showOverlay || (e.currentTarget !== e.target))}
        >
            <div className={css["video__title__container"]}>
                <h3 className={css["video__title"]} children={props.title} />
            </div>

            {/* Render the controls. */}
            <div className={css["video__controls"]}>
                <button
                    className={`${css["video__controls__button"]} ${css["video__controls__button--back"]}`}
                    onClick={() => video.current && (video.current.currentTime -= 10) }
                >
                    <FontAwesomeIcon icon={faRotateLeft} className={css["video__controls__button__icon"]} />
                    {/*<span className={css["video__controls__button__time"]}>10</span>*/}
                </button>

                <button
                    className={`${css["video__controls__button"]} ${css["video__controls__button--play"]}`}
                    onClick={toggle}
                >
                    <FontAwesomeIcon icon={icon} className={css["video__controls__button__icon"]} />
                </button>

                <button
                    className={`${css["video__controls__button"]} ${css["video__controls__button--forward"]}`}
                    onClick={() => video.current && (video.current.currentTime += 10) }
                >
                    <FontAwesomeIcon icon={faRotateRight} className={css["video__controls__button__icon"]} />
                    {/*<span className={css["video__controls__button__time"]}>10</span>*/}
                </button>
            </div>

            {/* Render the progress bar. */}
            <div className={css["video__progressbar__container"]}>
                <div className={css["video__progressbar__bar-container"]}>
                    <div className={css["video__progressbar"]} onClick={seek}>
                        <div className={css["video__progressbar__value"]} ref={progressValue} />

                    </div>
                    <button
                        className={css["video__progressbar__fs"]}
                        onClick={() => video.current?.requestFullscreen()}
                    >
                        <FontAwesomeIcon icon={faExpand} className={css["video__progressbar__fs__icon"]} />
                    </button>
                </div>

                <div className={css["video__progressbar__text"]}>
                    <span ref={progressText} children="0:00" />
                    <span children={toHoursMinutesAndSeconds(duration)} />
                </div>
            </div>
        </div>
    </div>;

}

/** Props passed down to the {@link VideoModule} component. */
export interface VideoModuleProps {
    /** Title of the module. */
    title: string;
    /** URL of the video file to play. */
    src: string;
}
