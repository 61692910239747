/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import lodash.
import * as _ from "lodash";

// Import the interfaces.
import { Resource, JsonValue } from "../../interfaces";

// Import the interfaces.
import { Operators, AnyValue, Filter, PropertyFilter, AnyOperator, CombinedFilter } from "./interface";


/**
 * Method used to check if a resource fits the given filter.
 *
 * @template {Resource} R
 * @param {R} item The item to filter.
 * @param {Filter} filter The filter to apply.
 * @returns {boolean} True if the item fits the provided filter.
 */
export function compare<R extends Resource>(item: R, filter: Filter): boolean {
    // If the item's attributes are undefined, do nothing.
    if (typeof item.attributes === "undefined") return true;

    // If the item is not filtered, do nothing.
    const filteredResources: (keyof typeof filter)[] = Object.keys(filter);
    if (!filteredResources.includes(item.type)) return true;

    // Compare the filter with the attributes.
    return compareObject(item.attributes, filter[item.type]);
}

// Recursive method used to deeply compare objects.
function compareObject(item: JsonValue | undefined, filter: PropertyFilter | CombinedFilter | AnyOperator): boolean {
    // Loop through the properties of the filter.
    const filteredProperties = Object.keys(filter);
    for (const filteredProperty of filteredProperties) {
        // Check if the property exists on the item.
        if (Object.values(Operators).includes(filteredProperty as Operators)) {
            if (!compareValue(filteredProperty as Operators, filter[filteredProperty as keyof typeof filter], item))
                return false;
        } else if (typeof item !== "object" || item === null || !(filteredProperty in item)) {
            return false;
        // If the property is an operator, use it for comparison.
        } else {
            // Recurse.
            if (!compareObject(
                item[filteredProperty as keyof typeof item],
                filter[filteredProperty as keyof typeof filter]
            )) {
                return false;
            }
        }
    }
    return true;
}

// Helper used to compare two values.
function compareValue(operator: Operators, comparator: AnyValue, value?: JsonValue): boolean {
    // Check the type of the operator.
    switch (operator) {
    case Operators.eq: return _.isEqual(value, comparator);
    case Operators.ne: return !_.isEqual(value, comparator);
    case Operators.gt: return _.gt(value, comparator);
    case Operators.gte: return _.gte(value, comparator);
    case Operators.lte: return _.lte(value, comparator);
    case Operators.lt: return _.lt(value, comparator);
    case Operators.in: return Array.isArray(comparator) ? _.includes(comparator, value) : false;
    case Operators.nin: return Array.isArray(comparator) ? !_.includes(comparator, value) : false;
    case Operators.match: return Array.isArray(value) ? _.includes(value, comparator) : false;
    default: return false;
    }
}
