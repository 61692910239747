/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

/// <reference types="webpack-env" />

// Import the schema interface.
import { SchemaObject } from "ajv";
// Import the compilation and validation helpers.
import { compile, validate } from "@andromeda/validation";

// Import the configuration url.
import * as config from "config";

// Re-export the environment services.
export * from "./services/environment";

/** Implementation ! */
export async function getConfig<T extends object = object>(schema?: string | SchemaObject): Promise<T> {
    // Return the cache if it was already loaded.
    if (configCache !== null) {
        if (typeof schema !== "undefined") {
            validate(await configCache, await compile<T>(schema), true);
        }
        return configCache as T;
    }

    // Load the configuration.
    return configCache = fetch((config as { default: string }).default).then(async configResponse => {
        // Check if the configuration was loaded.
        if (configResponse.status !== 200) {
            throw new Error(`Failed to load the configuration.`);
        }

        // Get the contents of the response as json.
        return configResponse.json()
        // Validate the data.
            .then(async data => {
                if (typeof schema !== "undefined") {
                    try {
                        validate(data, await compile<T>(schema), true);
                    } catch (e: unknown) {
                        // Log the error.
                        throw new Error(`Failed to validate the configuration data.\nInner error: ${String(e)}`);
                    }
                }
                return configCache = data;
            });
    });
}

// Cache used to avoir re-loading the configuration.
let configCache: object | Promise<object> | null = null;

// Clear the config cache if the config file changes.
if (module.hot) module.hot.accept("config", () => configCache = null);
