/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { PropsWithChildren, ReactElement, ReactNode } from "react";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the widget header.
import WidgetHeader from "./header";

// Import the CSS.
import css from "./index.module.scss";


/** Component used to render the widgets. */
export default function Widget(props: WidgetProps): ReactElement {
    // Build the widget class name.
    const className = classNames(
        css["widget"],
        {
            [css["widget--half-size"]]: props.halfSize === true,
            [css["widget--scrolling"]]: props.scrolling !== false
        },
        props.className
    );

    // Render the component.
    return <article role="listitem" className={className}>
        <WidgetHeader title={props.title} children={props.actions} />
        <main className={css["body"]} children={props.children} />
    </article>;
}

/** Props passed down to the {@link Widget} component. */
export interface WidgetProps extends PropsWithChildren {
    /** The title of the widget. */
    title: string;
    /** The actions of the widget. */
    actions?: ReactNode;

    /** Optional class name added to the <article> element. */
    className?: string;
    /** Flag set if the widget should be half sized. */
    halfSize?: boolean;
    /** Flag set if the widget should scroll. */
    scrolling?: boolean;
}
