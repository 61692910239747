/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the asset interface.
import { ConversionAsset } from "./asset";
// Import the compression enumeration.
import { CompressionKind, parseCompression } from "./compression";
// Import the list of all the message sizes.
import { Sizes } from "./sizes";


/**
 * Decodes the provided asset message into its asset components.
 *
 * @param {ArrayBuffer} message The message to decode.
 * @return {ConversionAsset[]} The list all the assets found in the message.
 */
export function decodeAssetMessage(message: ArrayBuffer): ConversionAsset[] {
    const source = new DataView(message);

    // Read the compression byte.
    const compression = parseCompression(source.getUint8(0));
    if (compression !== CompressionKind.none) throw new Error("Compression is not supported yet.");

    // Read the asset headers.
    const objectCount = source.getUint16(2, false);
    const info: ObjectHeader[] = new Array<ObjectHeader>(objectCount);

    let pointer = Sizes["MessageHeaderBytes"];
    for (let i = 0; i < objectCount; i++) {
        const metadataLength = source.getUint32(pointer, false);
        pointer += 4; // Uint32
        const dataLength = source.getUint32(pointer, false);
        pointer += 4; // Uint32
        const compression = parseCompression(source.getUint8(pointer));
        pointer += 1; // Uint8
        pointer += 3; // Reserved 24 bits.

        if (compression !== CompressionKind.none) throw new Error("Compression is not supported yet.");
        info[i] = { metadataLength, dataLength, compression };
    }

    // Read the asset data.
    const assets: ConversionAsset[] = [];
    for (let i = 0; i < objectCount; i++) {
        const metadata = message.slice(pointer, pointer + info[i].metadataLength);
        pointer += info[i].metadataLength;
        const data = message.slice(pointer, pointer + info[i].dataLength);
        pointer += info[i].dataLength;
        assets.push(ConversionAsset.deserialize({ metadata, data }));
    }
    return assets;
}

/** Interface used to describe a deserialized object header. */
interface ObjectHeader {
    metadataLength: number;
    dataLength: number;
    compression: CompressionKind
}
