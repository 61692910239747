/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ComponentType, useCallback, useEffect } from "react";
// Import Redux.
import type { ThunkDispatch } from "redux-thunk";
// Import React-Redux.
import { useDispatch, useSelector } from "react-redux";
// Import the resources.
import type { Validation } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";
// Import the custom components.
import { useNotify } from "@andromeda/components";
// Import the legacy zaq tools.
import type { LegacyZaqReducerStore } from "@andromeda/legacy-zaq";
// Import the store.
import { withReducer, WithResourceStore } from "@andromeda/store";

// Import the reducer.
import { VALIDATION_REDUCER_KEY, validationReducer, ValidationReducerStore } from "./reducer";
// Import the actions.
import { ValidationActions, downloadValidation, saveValidations } from "./actions";


/** Type alias for the result of the {@link useValidationEditor} hook. */
export type ValidationEditor<T extends Validation.Create> = [
    RequestStatus<T>,
    (update: T["attributes"]) => void
];

/**
 * Helper hook used to get a validation and a callback to edit it.
 *
 * @param {string} user The user to load the validation for.
 * @param {ResourceIdentifier<"external">} item The item to load the validation for.
 * @return {ValidationEditor<Validation.Create.CreateExternal>} The validation and a callback used to update it.
 */
export function useValidationEditor(
    user: string,
    item: ResourceIdentifier<"external">
): ValidationEditor<Validation.Create.CreateExternal>;

/**
 * Helper hook used to get a validation and a callback to edit it.
 *
 * @param {string} user The user to load the validation for.
 * @param {ResourceIdentifier<"zaq">} item The item to load the validation for.
 * @return {ValidationEditor<Validation.Create.CreateZaq>} The validation and a callback used to update it.
 */
export function useValidationEditor(
    user: string,
    item: ResourceIdentifier<"zaq">
): ValidationEditor<Validation.Create.CreateZaq>;

/** Implementation ! */
export function useValidationEditor(
    user: string,
    item: ResourceIdentifier<"external"> | ResourceIdentifier<"zaq">
): ValidationEditor<Validation.Create.CreateExternal> | ValidationEditor<Validation.Create.CreateZaq> {
    // Download the validation from the store.
    const validation = useSelector<ValidationReducerStore, RequestStatus<Validation.Create> | undefined>(
        state => state[VALIDATION_REDUCER_KEY].validations.find(
            ([id]) => id.item.type === item.type && id.item.id === item.id && id.user === user
        )?.[1]
    );

    // Download the validation from the API.
    const { error } = useNotify();
    const dispatch = useDispatch<
        ThunkDispatch<
            ValidationReducerStore & WithResourceStore<Validation> & LegacyZaqReducerStore,
            never,
            ValidationActions
        >
    >();
    useEffect(function downloadValidationOnMount(): void {
        dispatch(downloadValidation(user, item)).catch(error);
    }, [dispatch, error, item, user]);

    // Build the update callback.
    const update = useCallback(function updateValidation(update: Validation.Create["attributes"]): void {
        // Wait for the validation to be loaded.
        if (!validation?.isSuccess) {
            return;
        }

        // Update the validation details.
        validation.data.attributes = { ...update };
        dispatch({ type: "validation/update", user, item, payload: validation.data });
    }, [dispatch, item, user, validation?.data, validation?.isSuccess]);

    // Return the validation to the user.
    if (typeof validation === "undefined") {
        return [RequestStatus.uninitialised(), update];
    }
    return [
        validation,
        update
    ] as ValidationEditor<Validation.Create.CreateExternal> | ValidationEditor<Validation.Create.CreateZaq>;
}

/**
 * Helper function used to wrap a component with the {@link validationReducer}.
 * Uses the {@link withReducer} method internally.
 *
 * @template {object} P
 * @param {ComponentType<P>} component The component to wrap.
 * @return {ComponentType<P>} The wrapped component.
 */
export function withValidationReducer<P extends object>(component: ComponentType<P>): ComponentType<P> {
    return withReducer(component, VALIDATION_REDUCER_KEY, validationReducer);
}

/**
 * Helper hook used to get a callback to save the validations.
 *
 * @return {() => void} The callback that can be invoked to save the validations.
 */
export function useSave() {
    const { error } = useNotify();
    const dispatch = useDispatch<
        ThunkDispatch<
            ValidationReducerStore & WithResourceStore<Validation> & LegacyZaqReducerStore,
            never,
            ValidationActions
        >
    >();
    return function save(): void {
        dispatch(saveValidations()).catch(error);
    }
}

/**
 * Helper hook used to check if the validations are currently being saved.
 *
 * @return {boolean} True if the validations are saving.
 */
export function useIsSaving(): boolean {
    return useSelector<ValidationReducerStore, boolean>(state => !!state[VALIDATION_REDUCER_KEY].saving?.isLoading);
}

/**
 * Helper hook used to check if the validations have been modified.
 *
 * @return {boolean} True if the validations have been modified.
 */
export function useWasModified(): boolean {
    return useSelector<ValidationReducerStore, boolean>(state => state[VALIDATION_REDUCER_KEY].modified);
}
