/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the redux store.
import { compose, applyMiddleware, createStore } from "redux";
// Import the dev tools.
import * as ReduxDevTools from "@redux-devtools/extension";
// Import the thunk middleware.
import ReduxThunk from "redux-thunk";
// Import the environment tool.
import { Environment } from "@andromeda/config";

// Import the registry tool.
import { applyRegistry } from "./enhancers/registry";


// Build the store.
let customCompose: typeof compose;
if (Environment.current === Environment.development) {
    customCompose = ReduxDevTools.composeWithDevTools({
        name: "andromeda",
        trace: true
    }) as typeof compose;
} else {
    customCompose = compose;
}

/** Store that will be used across the application. */
export const store = createStore(
    () => ({ empty: true }),
    customCompose(applyMiddleware(ReduxThunk), applyRegistry())
);
