/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext, useEffect, useMemo } from "react";
// Import the card component.
import { Card, CardContainerContext, Loader } from "@andromeda/storybook";
// Import the module resource.
import { ZaqWikiFile } from "@andromeda/resources";
// Import the custom components.
import { useNotify } from "@andromeda/components";
// Import the url loading tool.
import { extractURL } from "@andromeda/json-api";

// Import the asset loader hook.
import { useAsset } from "../../hooks";
// Import all the modules.
import ImageModule from "./image";
import AudioModule from "./audio";
import LinkModule from "./link";
import TextModule from "./text";
import PdfModule from "./pdf";
import VideoModule from "./video";
import TutoModule from "./tuto";
import TitleModule from "./title";

// Import the css.
import css from "./index.module.scss";


/** Component used to render a given module with a {@link Card} component. */
export default function Module(props: ModuleProps): ReactElement | null {
    // Load the notification tool.
    const { warning } = useNotify();
    // Load the card container context.
    const { current } = useContext(CardContainerContext);

    // Extract the file name from the module.
    const filename = useMemo(function getFilenameFromModule(): string {
        if (!ZaqWikiFile.isTextFile(props.module) || !ZaqWikiFile.isAssetFile(props.module)) {
            return "";
        }

        return props.module.attributes.name.replace(/[/.?<>\\:*|]/g, "");
    }, [props.module]);

    // Load the web-asset for the file.
    const asset = useAsset(props.module);
    const { updateDownloadable } = props;
    useEffect(function updateParentDownloadable(): void | VoidFunction {
        // Ignore if this is not the current element.
        if (current - 1 === props.index) {
            if (ZaqWikiFile.isTextFile(props.module)) {
                updateDownloadable(new File([props.module.attributes.contents], filename, { type: "text/plain" }));
            } else if (asset.isSuccess && ZaqWikiFile.isAssetFile(props.module)) {
                updateDownloadable(asset.data.file);
            } else {
                updateDownloadable(null);
            }
        }

        // Clear the downloadable when the module is unmounted.
        return () => updateDownloadable(null);
    }, [asset, current, filename, props.index, props.module, updateDownloadable]);

    // Render the module.
    const contents = useMemo(function renderZaqWikiModule(): ReactElement | null {
        // Render the asset.
        if (ZaqWikiFile.isSectionFile(props.module)) {
            return <TitleModule title={props.module.attributes.title} />;
        } else if (ZaqWikiFile.isAssetFile(props.module)) {
            if (!asset.isSuccess) {
                return <Loader text="Chargement du module ..." containerClassName={css["loader"]} />;
            }

            if (asset.data.file.type.startsWith("image")) {
                return <ImageModule title={props.module.attributes.name} src={asset.data.url} />;
            } else if (asset.data.file.type.startsWith("video")) {
                return <VideoModule title={props.module.attributes.name} src={asset.data.url} />;
            } else if (asset.data.file.type.startsWith("audio")) {
                return <AudioModule title={props.module.attributes.name} src={asset.data.url} />;
            } else if (asset.data.file.type.startsWith("application/pdf")) {
                return <PdfModule title={props.module.attributes.name} src={asset.data.url} />;
            } else {
                warning("Usupported asset type error", `Cannot handle ${asset.data.file.type} objects`);
                return null;
            }
        } else if (ZaqWikiFile.isTutoFile(props.module)) {
            return <TutoModule title={props.module.attributes.name} id={props.module.attributes.zaqTutoId} />;
        } else if (ZaqWikiFile.isTextFile(props.module)) {
            return <TextModule title={props.module.attributes.name} text={props.module.attributes.contents} />;
        } else if (ZaqWikiFile.isLinkFile(props.module)) {
            return <LinkModule
                title={props.module.attributes.name}
                url={extractURL(props.module.links.url).toString()}
            />;
        } else {
            console.log(props.module);
            warning("Unknown module type", `An unknown module type was requested`);
            return null;
        }
    }, [asset.data?.file.type, asset.data?.url, asset.isSuccess, props.module, warning]);

    // Render the card.
    return <Card withNavigation children={contents} />;
}

/** Props passed down to the {@link Module} component. */
export interface ModuleProps {
    /** The module that should be used for this instance. */
    module: ZaqWikiFile;
    /** The index of the rendered module. */
    index: number;

    /** Callback invoked to update the downloadable file. */
    updateDownloadable(file: File | null): void;
}
