/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";

// Import the login context.
import { LoginContext } from "../context";


/** Function used to check if the user is the admin or owner of the current organisation. */
export function useIsAdmin(): boolean {
    const { self } = React.useContext(LoginContext);
    return useAdminCheck()(self.id);
}

/** Helper hook used to generate a callback to check if the given user is an admin of the current organisation. */
export function useAdminCheck(): (userId: string) => boolean {
    // Load the current organisation.
    const organisation = React.useContext(LoginContext).organisations.current;

    return React.useCallback(function checkIfUserIsAdmin(userId: string): boolean {
        if (organisation.relationships.owner.data?.id === userId) return true;
        return organisation.relationships.administrators.data.some(admin => admin.id === userId);
    }, [organisation.relationships.administrators.data, organisation.relationships.owner.data?.id]);
}
