/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { Context, ReactElement, useCallback, useContext, useMemo, useRef, useState } from "react";
// Import react-bootstrap.
import { Overlay } from "react-bootstrap";
// Import the custom components.
import { useOutsideClick } from "@andromeda/components";

// Import the search bar context.
import { SearchBarContext, SearchBarContextProvider } from "../../context";
// Import the search bar context provider props.
import { SearchBarContextProviderProps } from "../../context/search-bar/provider";
// Import the subcomponent.
import { SearchBarList, SearchBarListProps, SearchBarListPropsPrefixed } from "./list";

// Import the magnifying glass icon.
import * as magnifyingGlass from "@andromeda/assets/images/glass-blue.svg";
// Import the css.
import css from "./input.module.scss";


/** Wrapper for the {@link SearchBarImpl}. */
export default function SearchBar<T = void>(props: SearchBarProps<T>): ReactElement {
    // Renderer used for the input.
    const input = useMemo(function buildInputRenderer() {
        return function inputRenderer(context: Context<SearchBarContext<T>>): ReactElement {
            return <SearchBarImpl placeholder={props.placeholder} context={context} {...props} />;
        };
    }, [props]);

    // Use the provided context.
    if ("context" in props) {
        return input(props.context);
    }

    // Render the local provider.
    const localContext = SearchBarContext.DefaultSearchBarContext as Context<SearchBarContext<T>>;
    return <SearchBarContextProvider
        initialText={props.initialText}
        categories={props.categories}
        throttleTime={props.throttleTime}
        context={localContext}
        children={input(localContext)}
    />;
}

/**
 * Component used to render a search bar.
 * Search bar elements can use a customisable {@link SearchBarContext}
 * to provide information about the search to the virtual DOM tree.
 */
function SearchBarImpl<T>(props: SearchBarPropsWithContext<T> & SearchBarImplProps<T>): ReactElement {
    const context = useContext(props.context);
    const [showList, setShowList] = useState(false);
    const ref = useOutsideClick<HTMLUListElement>(useCallback(() => setShowList(false), []), true);
    const input = useRef<HTMLInputElement | null>(null);

    // Generate the list of props for the list.
    const listProps = useMemo(function extractListProps(): SearchBarListProps<T> {
        return Object.entries(props).reduce<SearchBarListProps<T>>(
            (acc: SearchBarListProps<T>, [key, value]): SearchBarListProps<T> => {
                if (key.startsWith("list")) {
                    const k =
                        `${key.slice("list".length, "list".length + 1).toLowerCase()}` +
                        `${key.slice("list".length + 1)}`;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (acc as any)[k] = value;
                }
                return acc;
            },
            {} as SearchBarListProps<T>
        );
    }, [props]);

    const className = useMemo(() => {
        let className = css["searchbar-input"];
        if (props.className) {
            className += ` ${props.className}`;
        }
        return className;
    }, [props.className]);

    // Render the search bar.
    return <span className={className}>
        <magnifyingGlass.ReactComponent className={css["searchbar-input__icon"]} />
        <input
            className={css["searchbar-input__input"]}
            type="text"
            defaultValue={context.text}
            onChange={e => context.setText(e.currentTarget.value)}
            onFocus={() => setShowList(true)}
            placeholder={props.placeholder}
            ref={input}
        />
        <Overlay target={input.current} show={showList} placement="bottom" offset={[0, 5]}>
            {(overlayProps => {
                return <SearchBarList
                    {...listProps}
                    overlayProps={overlayProps}
                    show={showList}
                    context={props.context}
                    hide={() => setShowList(false)}
                    width={input.current ? `${input.current.clientWidth}px` : undefined}
                    ref={ref}
                />;
            })}
        </Overlay>
    </span>;
}

/** Union of props for the {@link SearchBar} component. */
type SearchBarProps<T> = (SearchBarPropsWithItems<T> | SearchBarPropsWithContext<T>) & SearchBarImplProps<T>;

/** Props passed down to the {@link SearchBarImpl} component. */
interface SearchBarImplProps<T> extends SearchBarListPropsPrefixed<T> {
    /** Class name to apply to the implementation. */
    className?: string;
    /** Placeholder text for the input. */
    placeholder?: string;
}

/** Interface used to describe the search bar props. */
type SearchBarPropsWithItems<T> = Omit<SearchBarContextProviderProps<T>, "context" | "children">;

/** Interface used to describe the search bar props. */
interface SearchBarPropsWithContext<T> {
    /** The context to use for this search bar. */
    context: Context<SearchBarContext<T>>;
}
