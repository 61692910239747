/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { createContext, ReactElement, useCallback, useContext, useMemo } from "react";
// Import the legacy helper.
import { useLegacyZaqFromOrganisation } from "@andromeda/legacy-zaq";
// Import the login module.
import { LoginContext } from "@andromeda/login";
// Import the store.
import { useExternals, useZaqWikis } from "@andromeda/store";
// Import the storybook components.
import {
    SearchBar,
    SearchBarCategory,
    SearchBarContextProvider,
    SearchBarContext,
    SearchBarItemRendererProps
} from "@andromeda/storybook";

// Import the item type.
import type { Item } from "./item-list";

// Import the css.
import css from "./search-bar.module.scss";
import { Step } from "@andromeda/resources";


/** Component used to render the item search bar. */
export default function ItemSearchBar(props: ItemSearchBarProps): ReactElement {
    // Load all the zaq, wiki and externals.
    const organisation = useContext(LoginContext).organisations.current.id;
    const tuto = useLegacyZaqFromOrganisation(organisation);
    const wiki = useZaqWikis(organisation);
    const external = useExternals(organisation);

    // Callback used to render the categories.
    const itemComponent = useCallback(function itemComponent({ item }: SearchBarItemRendererProps<Item>): ReactElement {
        return <span
            role="button"
            className={css["item"]}
            onClick={() => props.select(item.data)}
            children={item.data.name}
        />;
    }, [props]);

    // Build the list of search categories.
    const categories = useMemo(function buildSearchCategories(): SearchBarCategory<Item>[] {
        // Wait for everything to be loaded.
        if (!tuto.isSuccess || !wiki.isSuccess || !external.isSuccess) {
            return [];
        }

        // Render all the categories.
        return [
            {
                header: "ZaqTuto",
                items: tuto.data.map(tuto => ({
                    text: tuto.name,
                    data: { type: "zaq-tuto", id: tuto.id, name: tuto.name },
                    key: tuto.id
                })),
                itemComponent
            }, {
                header: "ZaqWiki",
                items: wiki.data.map(wiki => ({
                    text: wiki.attributes.name ?? "",
                    data: { type: "zaq-wiki", id: wiki.id, name: wiki.attributes.name ?? "" },
                    key: wiki.id
                })),
                itemComponent
            }, {
                header: "Formations Externes",
                items: external.data.map(external => ({
                    text: external.attributes.name,
                    data: { type: "external", id: external.id, name: external.attributes.name },
                    key: external.id
                })),
                itemComponent
            }
        ];
    }, [external.data, external.isSuccess, itemComponent, tuto.data, tuto.isSuccess, wiki.data, wiki.isSuccess]);

    return <SearchBarContextProvider categories={categories} context={ItemSearchBarContext}>
        <SearchBar context={ItemSearchBarContext} />
    </SearchBarContextProvider>;
}

/** Props passed down to the {@link ItemSearchBar} component. */
export interface ItemSearchBarProps {
    select(item: Step.Item): void;
}

// Context used for the item search bar.
const ItemSearchBarContext = createContext<SearchBarContext<Item>>(SearchBarContext.DEFAULT_VALUE);
