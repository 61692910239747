/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useContext, useMemo, useState } from "react";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the custom components.
import { Switch } from "@andromeda/components";
// Import the legacy loader.
import { LegacyZaq, useLegacyZaqFromOrganisation } from "@andromeda/legacy-zaq";
// Import the login module.
import { LoginContext } from "@andromeda/login";

// Import the list component.
import ItemCategorisedList, { Item, ItemCategory } from "./item-categorised-list";

// Import the css.
import css from "./zaq-tuto.module.scss";
import { useStep } from "@andromeda/store";


/** Component used to add a new ZaqTuto to the current step. */
export default function AddZaqTuto(props: AddZaqTutoProps): ReactElement {
    // Capture and update the selection props.
    const [plusMode, setPlusMode] = useState(false);
    const swapPlusMode = useCallback(function swapPlusMode(state: boolean): void {
        setPlusMode(state);

        // Switch the component type if appropriate.
        if (state && props.selected?.type === "zaq-tuto") {
            props.select({ type: "zaq", id: props.selected.id });
        } else if (!state && props.selected?.type === "zaq") {
            props.select({ type: "zaq-tuto", id: props.selected.id });
        }
    }, [props]);
    const selected = useMemo(function interceptSelected(): ResourceIdentifier | undefined {
        if (props.selected?.type === "zaq") {
            return { type: "zaq-tuto", id: props.selected.id };
        }
        return props.selected;
    }, [props.selected]);
    const select = useCallback(function interceptSelection(item: ResourceIdentifier): void {
        props.select({ type: plusMode ? "zaq" : "zaq-tuto", id: item.id });
    }, [plusMode, props]);

    // Download all the zaq from the current organisation.
    const zaq = useLegacyZaqFromOrganisation(useContext(LoginContext).organisations.current.id);

    // Filter out all the zaq already in the current step.
    const step = useStep(props.step);
    const availableZaq = useMemo(function filterAvailableZaq(): LegacyZaq[] {
        if (!zaq.isSuccess || !step.isSuccess) {
            return [];
        }

        return zaq.data.filter(function removeDuplicates(zaq: LegacyZaq): boolean {
            return !step.data.relationships.items.data.some(item => {
                return (item.type === "zaq" || item.type === "zaq-tuto") && item.id === zaq.id;
            });
        });
    }, [step.data?.relationships.items.data, step.isSuccess, zaq.data, zaq.isSuccess]);

    // Build the category list from the zaq.
    const zaqCategories = useMemo(function buildCategoryList(): ItemCategory[] {
        // Reduce the category list into a map.
        const map = availableZaq.reduce(function reduceToMap(map, zaq) {
            const item: Item = { type: "zaq-tuto", id: zaq.id, name: zaq.name };

            // Add the zaq to all its tags.
            for (const tag of zaq.tags) {
                const list = map.get(tag);
                if (!list) {
                    map.set(tag, [item]);
                } else {
                    list.push(item);
                }
            }

            // Create the special category-less tag.
            if (zaq.tags.length === 0) {
                const list = map.get(undefined);
                if (!list) {
                    map.set(undefined, [item]);
                } else {
                    list.push(item);
                }
            }

            return map;

        }, new Map<string | undefined, Item[]>());
        return Array.from(map.entries());
    }, [availableZaq]);

    // Render the component.
    if (!step.isSuccess || !zaq.isSuccess) {
        return <Loader containerClassName={css["loader"]} text="Chargement des ZaqTuto ..." />;
    }
    return <>
        <ItemCategorisedList className={css["list"]} categories={zaqCategories} selected={selected} select={select} />
        <Switch name="ZaqTuto+" onInternalStateChange={swapPlusMode} small />
    </>;
}

/** Props passed down to the {@link AddZaqTuto} component. */
export interface AddZaqTutoProps {
    /** Identifier of the current step. */
    step: string;

    /** Currently selected item. */
    selected: ResourceIdentifier | undefined;

    /** Callback invoked to select a new component. */
    select(item: ResourceIdentifier): void;
}
