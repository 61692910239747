/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */


/** Helper used to extract the string keys from an object. */
type JustStringKeys<T extends object> = { [K in keyof T]: K extends string ? K : never }[keyof T];

/** Adds a prefix to all the keys of the provided interface. */
export type WithPrefix<Type extends object, Prefix extends string> = {
    [Key in JustStringKeys<Type> as `${Prefix}${Capitalize<Key>}`]: Type[Key];
}

/**
 * Helper method used to remove the prefixes of a prefixed object.
 *
 * @template {object} T
 * @template {string} P
 * @param {WithPrefix<T, P>} object The object to remove the prefix from.
 * @param {P} prefix The prefix to remove from the object keys.
 * @return {T} The un-prefixed object.
 */
export function removePrefix<T extends object, P extends string>(object: WithPrefix<T, P>, prefix: P): T {
    return Object.entries(object).reduce(reduceObjectKeys, {} as T);

    // Function used to
    function reduceObjectKeys(output: T, [key, value]: [string, unknown]): T {
        // If the key was prefixed.
        if (key.startsWith(prefix)) {
            // Compute the key value.
            const originalKey = (key.charAt(prefix.length).toLowerCase() + key.slice(prefix.length + 1)) as keyof T;
            output[originalKey] = value as T[typeof originalKey];
        }
        return output;
    }
}
