/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, FocusEvent, FormEvent, useCallback, useContext, useMemo, useState, ChangeEvent } from "react";
// Import the bootstrap components.
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
// Import the custom components.
import { normalise, SpeechRecognitionButton, useNotify } from "@andromeda/components";
// Import the resources.
import { ZaqWiki } from "@andromeda/resources";
// Import the store.
import { useResourceDispatch, ZaqWikiStore } from "@andromeda/store";

// Import the reader context.
import { ReaderContext } from "../context";

// Import the icons.
import close from "@andromeda/assets/images/close.svg";
import addGreen from "@andromeda/assets/images/add-green.svg";
// Import the css.
import css from "./tag.module.scss";
import classNames from "classnames";


/** Component used to render and edit the current wiki's tags. */
export function Tags(): ReactElement {
    // Read the wiki.
    const { wiki } = useContext(ReaderContext);
    // Get the edit mode context.
    const notify = useNotify();

    // Removes the given tag from the wiki.
    const dispatch = useResourceDispatch();
    const removeTag = useCallback(
        function removeTag(remove: string): void {
            // Remove the file from the zaq.
            const update: ZaqWiki.Update = {
                type: ZaqWiki.Type,
                id: wiki.id,
                attributes: { tags: wiki.attributes.tags.filter(tag => tag !== remove) }
            };

            // Remove the tag from the zaq.
            dispatch(ZaqWikiStore.generator.update(update)).catch(notify.fatal);
        },
        [notify.fatal, dispatch, wiki.id, wiki.attributes.tags]
    );

    // Pre-render all the tags.
    const renderedTags = useMemo(
        function renderTags(): (ReactElement | null)[] {
            // Render all the tag elements.
            const rendered = wiki.attributes.tags.map(function renderTag(tag: string, index: number): ReactElement {
                return <div
                    key={index}
                    className={classNames(css["tag"], css["tag--editable"], css["tag__remove"])}
                    onClick={() => removeTag(tag)}
                >
                    <img src={close} alt={"remove-tag"} />
                    <p><em>#</em>{tag}</p>
                </div>;
            });

            return [...rendered];
        },
        [wiki.attributes.tags, removeTag]
    );

    // Render the component.
    return <Row className="d-flex flex-column flex-md-row flex-nowrap align-items-center">
        <Col className="d-flex flex-row justify-content-start align-items-start">
            <h3 className={css["tag-container__title"]}>tags: </h3>
            <div className={css["tag-container"]}>
                {renderedTags}
                <AddTag />
            </div>
        </Col>
    </Row>;
}

/** Simple component used to render the tag addition bar. */
function AddTag(): ReactElement {
    const notify = useNotify();

    // Store the text that is being added.
    const [text, setText] = useState("");

    // Build the callback invoked when the user submits the tag.
    const wiki = useContext(ReaderContext).wiki;
    const dispatch = useResourceDispatch();
    const submit = useCallback(
        function addNewTag(event: FormEvent<HTMLFormElement> | FocusEvent<HTMLInputElement>): void {
            event.preventDefault();
            if (!event.currentTarget.checkValidity()) {
                return;
            }

            // Get the tag name.
            let newTag: string;
            if (event.currentTarget instanceof HTMLFormElement) {
                newTag = event.currentTarget["tag"].value;
                event.currentTarget["tag"].value = "";
            } else {
                newTag = event.currentTarget.value;
                event.currentTarget.value = "";
            }
            if (!newTag) {
                return;
            }
            setText("");
            setValidity("ok");

            // Create the tag.
            const update: ZaqWiki.Update = {
                type: ZaqWiki.Type, id: wiki.id,
                attributes: { tags: [...wiki.attributes.tags, newTag] }
            };
            dispatch(ZaqWikiStore.generator.update(update)).catch(notify.error);
        },
        [dispatch, notify.error, wiki.attributes.tags, wiki.id]
    );

    // Check if the input is valid.
    const [validity, setValidity] = useState<"ok" | "duplicate">("ok");
    const check = useCallback(function checkInput(event: ChangeEvent<HTMLInputElement>): void {
        setText(event.currentTarget.value);

        // Check if the input is filled.
        if (!event.currentTarget.value) {
            event.currentTarget.setCustomValidity("");
            return;
        } else if (wiki.attributes.tags.some(tag => normalise(tag) === normalise(event.currentTarget.value))) {
            event.currentTarget.setCustomValidity("Duplicate tag");
            setValidity("duplicate");
        } else {
            event.currentTarget.setCustomValidity("");
            setValidity("ok");
        }
    }, [wiki.attributes.tags]);

    // Render the popover.
    const popover = useMemo(function renderPopover(): ReactElement {
        let message: string;
        switch (validity) {
        case "ok":
            message = "";
            break;
        case "duplicate":
            message = "Ce tag existe déjà sur cet élément";
            break;
        }

        return <Popover className={css["tag-add__invalid"]} children={message} />;
    }, [validity]);

    // noinspection RequiredAttributes
    return <form className={css["tag-add__form"]} onSubmit={submit}>
        #
        <OverlayTrigger show={validity !== "ok"} trigger={[]} overlay={popover} placement="top">
            <input
                className={css["tag-add__input"]}
                name="tag"
                value={text}
                onChange={check}
                placeholder="Ajouter un tag ..."
            />
        </OverlayTrigger>
        <SpeechRecognitionButton
            interimResults
            stopWithUser
            onText={setText}
        />
        <button className={css["tag-add__checkmark"]}>
            <img src={addGreen} alt="validate" />
        </button>
    </form>;
}
