/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API module.
import type { Filter } from "@andromeda/json-api";
// Import the due-date resource.
import { DueDate } from "@andromeda/resources";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the store.
import { DueDateStore, ResourceStore } from "../../stores";
// Import the resource hooks.
import useResources from "./util/use-resources";
import useResource from "./util/use-resource";


/**
 * Hook used to download a due-date from the store.
 *
 * @param {string | undefined} course The identifier of the due-date's course.
 * @param {string | undefined} user The identifier of the due-date's user.
 * @param {boolean} [cache=true] Try to read from the cache if possible.
 * @return {RequestStatus<DueDate>} The status of the request.
 */
export function useDueDate(
    course: string | undefined,
    user: string | undefined,
    cache = true
): RequestStatus<DueDate | null> {
    const id = typeof course !== "undefined" && typeof user !== "undefined" ? `${course}-${user}` : undefined;
    const status = useResource(DueDateStore, DueDate.Type, id, cache);
    if (status.isError) {
        return RequestStatus.success(null);
    }
    return status;
}

/**
 * Downloads all the due-date of the specified course.
 *
 * @param {string} course The course to load the due-dates for.
 * @param {string[]} [user] The list of users to load the due-dates for.
 * @return {RequestStatus<DueDate[]>} The status of the request.
 */
export function useDueDates(course: string, user?: string[]): RequestStatus<DueDate[]> {
    // Prepare the filter used for the request.
    const filter: Filter = { course: { id: { $eq: course } } };
    if (typeof user !== "undefined") {
        // filter["user"] = { id: { $in: user }};
    }

    // Prepare the selector used to load the due-dates.
    const selector = function selector(state: ResourceStore<DueDate>): DueDate[] {
        return state.resources.filter(function dueDateMatches(dueDate: DueDate): boolean {
            return dueDate.relationships.course.data.id === course;
        });
    };

    // Return the resources.
    return useResources(DueDateStore, DueDate.Type, { filter }, selector);
}
