/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json:api resource interface.
import { Resource } from "@andromeda/json-api";
// Import the json schema compiler.
import { compileSync } from "@andromeda/validation";

// Import the metadata types.
import { ExtractMetadata } from "./metadata";
// Import the schema.
import * as Schema from "./schema.json";


// Re-export the asset request.
export * from "./request";
// Re-export the metadata interfaces.
export * from "./metadata";

/** Interface used to describe an asset's metadata. */
export interface AssetMetadata<T extends string = string> extends Omit<Resource<AssetMetadata.Type>, "relationships"> {
    /** @inheritDoc */
    attributes: ExtractMetadata<T>;
}

/** Augment the {@link AssetMetadata} interface. */
export namespace AssetMetadata {
    /** Type of {@link AssetMetadata} resources. */
    export const Type = "asset-conversion_asset-metadata" as const;
    export type Type = typeof Type;

    export const validate = compileSync<AssetMetadata>(Schema);
}
