/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
// Import react.
import { ReactElement, useContext, useEffect, useMemo } from "react";
// Import the react-router hooks.
import { useParams } from "react-router-dom";
// Import Zod.
import z from "zod";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the custom components.
import { useFitToParent } from "@andromeda/components";

// Import the css.
import css from "./player.module.scss";


/** Simple component used to render the Zaq/ZaqTuto player. */
export default function Player(props: { gameId?: string }): ReactElement | null {
    // Load the id from the parameters.
    const { gameId, wiki } = useParams<{ courseId: string; gameId: string, wiki: string }>();
    const zaqId = useMemo(function getGameIdFromPropsIfNeeded(): string {
        return gameId ?? props.gameId ?? "__NO_ID__";
    }, [gameId, props.gameId]);

    // Check if the zaq should be started as a ZaqTuto.
    const { isLoggedIn } = useContext(LoginContext);
    const isTuto = useMemo(function checkIfForcedTutoMode(): boolean {
        const searchParams = new URLSearchParams(window.location.search);
        return !isLoggedIn
            || searchParams.get("lite") !== null
            || searchParams.get("tuto") !== null
            || wiki !== undefined;
    }, [isLoggedIn, wiki]);

    // Attach the css class to the body when the iframe is displayed.
    // NOTE: This is because a scrollbar appears when loaded from a wiki.
    useEffect(function attachOverflowClassToBody(): () => void {
        document.body.classList.add(css["overflow-none"]);
        return () => document.body.classList.remove(css["overflow-none"]);
    }, []);

    // Propagate player message to the parent frame if any.
    useEffect(function propagateMessagesToParent(): void | VoidFunction {
        // Check if we are running in an iframe.
        if (window.parent === window) {
            return;
        }

        // Add the listener to the window.
        window.addEventListener("message", onWindowMessage);
        // Clear the listener.
        return function removeMessageListener(): void {
            window.removeEventListener("message", onWindowMessage);
        }

        // Listener used to propagate messages to the parent frame.
        function onWindowMessage(event: MessageEvent): void {
            // Ensure that the message comes from a valid source.
            if (event.origin !== window.location.origin) {
                return;
            }

            // Validate the source of the message.
            const message = z.object({
                data: z.object({
                    scope: z.literal("remote-player"),
                    type: z.string()
                }).and(z.record(z.string(), z.unknown()))
            }).safeParse(event.data);
            if (message.success) {
                // Propagate the message to the parent frame.
                window.parent.postMessage(message.data, window.location.origin);
            }
        }
    }, []);

    // Attach an iframe resizer.
    const fit = useFitToParent<HTMLIFrameElement>();
    // useEffect(function resizeIframeToWindowContents(): () => void {
    //     window.addEventListener("resize", resize, { passive: true });
    //     resize();
    //
    //     return function removeResizeListener(): void {
    //         window.removeEventListener("resize", resize);
    //     };
    //
    //     // Resize the iframe to fit the window..
    //     function resize(): void {
    //         setTimeout(function resizeHandler(): void {
    //             setTimeout(function resizeHandlerBis(): void {
    //                 if (!iframe.current) {
    //                     return;
    //                 }
    //                 iframe.current.width = window.innerWidth + "px";
    //                 iframe.current.height = window.innerHeight + "px";
    //             }, 0);
    //         }, 100);
    //     }
    // }, []);

    // Disable overflow on the root.
    useEffect(function disableOverflowOnRoot(): VoidFunction {
        (document.getElementById("root") as HTMLElement).style.overflow = "hidden";

        return function enableOverflowOnRoot(): void {
            (document.getElementById("root") as HTMLElement).style.overflow = "";
        }
    }, []);

    // Build the url of the player.
    const url = useMemo(function buildPlayerURL(): string {
        const url = new URL(`${window.location.protocol}//${window.location.host}/`);
        url.pathname = ["player", zaqId].join("/");
        url.searchParams.set("mode", isTuto ? "zaq-tuto" : "zaq-tuto-plus");
        return url.href;
    }, [isTuto, zaqId]);

    // Return the iframe.
    return <iframe
        title="zaq-player"
        className={css["iframe-container"]}
        ref={fit}
        allowFullScreen
        src={url}
        allow={"fullscreen"}
    />;
}
