/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useMemo } from "react";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";

// Import the custom hooks.
import { AnyTrainingProgress, useTrainingProgression } from "../../../hooks";
// Import the status component.
import TrainingStatus from "../../training";
// Import the widget mode type.
import type { TrainingWidgetMode } from "./index";

// Import the icons.
import { faFrown } from "@fortawesome/free-solid-svg-icons/faFrown";
import { faBan } from "@fortawesome/free-solid-svg-icons/faBan";
// Import the css.
import css from "./list.module.scss";


/** Component used to render the list of all the {@link ZaqTraining} progress for the current user. */
export default function TrainingProgressList(props: TrainingProgressListProps): ReactElement {
    // Download the progress status from the store.
    const progress = useTrainingProgression(props.training, props.user);

    // Render all the progress items.
    const items = useMemo(function renderProgressItems(): ReactNode {
        // Wait for the training to be loaded.
        if (!progress.isSuccess) {
            return null;
        }

        // Filter out any unnecessary items.
        const filteredItems = progress.data.filter(
            function shouldTrainingBeIncluded(progress: AnyTrainingProgress): boolean {
                // Check the current rendering mode.
                switch (props.mode) {
                case "assigned":
                    return !progress.validated;
                case "done":
                    return progress.validated;
                case "by-user":
                case "by-training":
                    return true;
                default:
                    return false;
                }
            }
        );

        // If the list is empty, return a simple message.
        if (filteredItems.length < 1) {
            return <li className={css["empty"]}>
                <FontAwesomeIcon className={css["empty__icon"]} icon={faBan} />
                Aucune formation ...
            </li>
        }

        // Render all the items.
        return filteredItems.map(function renderProgressItem(item: AnyTrainingProgress, index: number): ReactNode {
            return <li key={index} className={css["item"]}>
                <TrainingStatus progress={item} showUserName={props.mode === "by-training"} />
            </li>;
        });
    }, [progress.data, progress.isSuccess, props.mode]);

    // Wait for the progress to load.
    if (progress.isUninitialised || progress.isLoading) {
        return <Loader transparent text="Chargement ..." />;
    }
    if (progress.isError) {
        return <div className={css["error"]}>
            <FontAwesomeIcon className={css["error__icon"]} icon={faFrown} />
            <span className={css["error__title"]}>Une erreur est survenue durant le chargement des ZaqTraining:</span>
            <code className={css["error__detail"]} children={String(progress.error)} />
        </div>;
    }
    return <ul className={css["list"]} children={items} />;
}

/** Props passed down to the {@link TrainingProgressList} component. */
export interface TrainingProgressListProps {
    /** The mode that the widget should be rendered in. */
    mode: TrainingWidgetMode;

    /** The user that is currently selected. */
    user: string | undefined;
    /** The training that is currently selected. */
    training: string | undefined;
}
