/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { createContext, ReactElement, ReactNode, useState } from "react";
// Import the common tools.
import { makeContextNoop } from "@andromeda/tools";


/** List of all the possible PDF rendering modes. */
export type PDFRenderMode = "continuous" | "one-by-one";

/** Object used to manipulate the PDF display mode. */
export interface PDFRenderModeContext {
    /** Current mode of PDF rendering. */
    mode: PDFRenderMode;
    /** Flag set if the mode is fixed and cannot be changed by the user. */
    fixed: boolean;

    /**
     * Changes to the specified render mode.
     * Fails silently if {@link fixed} is {@code false}.
     *
     * @param {PDFRenderMode} mode The new mode to set the rendering to.
     */
    change(mode: PDFRenderMode): void;
}

/** Context used to manipulate the fullscreen state of a PDF document. */
export const PDFRenderModeContext = createContext<PDFRenderModeContext>({
    mode: "continuous",
    fixed: true,
    change: makeContextNoop("PDFRenderModeContext", "change")
});
PDFRenderModeContext.displayName = "PDFRenderModeContext";

/** Component used to provide the {@link PDFRenderModeContext} to the tree. */
export default function PDFRenderModeProvider(props: PDFRenderModeProviderProps): ReactElement {
    // Compute the value of the context.
    const [mode, setMode] = useState<PDFRenderMode>(props.defaultMode ?? "continuous");
    const value: PDFRenderModeContext = { mode, fixed: Boolean(props.fixedMode), change: setMode };

    return <PDFRenderModeContext.Provider value={value} children={props.children} />;
}

/** Props passed down to the {@link PDFRenderModeProvider} component. */
export interface PDFRenderModeProviderProps {
    /** Children that will have access to the context. */
    children?: ReactNode;

    /**
     * Default rendering mode of the pdf.
     *
     * @default "continuous"
     */
    defaultMode?: PDFRenderMode;
    /**
     * If set, the rendering mode will be fixed and cannot be changed by the user.
     *
     * @default false
     */
    fixedMode?: boolean;
}
