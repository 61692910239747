/*
 * Copyright © 2024 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { Component, ErrorInfo, PropsWithChildren } from "react";

// Import the context.
import { NotificationContext } from "./context";


/** Simple component used to catch errors. */
export class ErrorBoundary extends Component<PropsWithChildren, never> {
    /** The context used by the boundary. */
    static override contextType = NotificationContext;
    /** The context used by the boundary. */
    public override context!: NotificationContext;

    /** @inheritDoc */
    public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Propagate the error.
        this.context.fatal(error);
    }

    /** @inheritDoc */
    public override render() {
        return this.props.children;
    }
}
