/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { createElement, useEffect, ComponentType, ReactNode, ReactElement } from "react";
// Import the redux middleware interface.
import { Middleware } from "redux";

// Import the registry interfaces.
import { unmountMiddlewareFromRegistry, mountMiddlewareInRegistry } from "../store/enhancers/registry";
// Import the hooks.
import { useStoreWithRegistry, useMiddlewareIsMounted } from "../hooks";


/**
 * Generates a new HOC that will mount the given middleware into the store.
 *
 * @template {object} P=object
 * @param {ComponentType<P>} component The component to wrap.
 * @param {string} name The name of the requested middleware.
 * @param {Middleware} source The middleware to mount.
 * @param {ReactNode} [suspense=undefined] The component to mount when the middleware is mounting.
 * @returns {ComponentType<P>} The wrapped component.
 */
export function withMiddleware<P extends object>(
    component: ComponentType<P>,
    name: string,
    source: Middleware,
    suspense?: ReactNode
): ComponentType<P> {
    // Return the children.
    return function MiddlewareHOC(props: P): ReactElement | null {
        // Get a reference to the store.
        const store = useStoreWithRegistry();

        // Get the state of the reducer.
        const isMounted = useMiddlewareIsMounted(name);

        // Mount the reducer with the component.
        useEffect(function mountReducer(): () => void {
            mountMiddlewareInRegistry(store.registry, name, source);
            return () => unmountMiddlewareFromRegistry(store.registry, name);
        }, []);

        // Render the component.
        if (isMounted) {
            return createElement(component, props);
        } else {
            return typeof suspense === "undefined" ? null : <>{suspense}</>;
        }
    };
}
