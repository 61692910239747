/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ChangeEvent, ReactElement, useCallback, useContext, useEffect, useState } from "react";
// Import react bootstrap.
import { Modal } from "react-bootstrap";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the switch context.
import { SpeechRecognitionButton, useNotify } from "@andromeda/components";
// Import the wiki resource.
import { ZaqWiki } from "@andromeda/resources";
// Import the asset store.
import { ZaqWikiStore, useResourceDispatch } from "@andromeda/store";
// Import the asset loader.
import { useAsset } from "@andromeda/asset-manager";

// Import the reader context.
import { ReaderContext } from "../context";
// Import the icon generator hook.
import { useIconGenerator } from "./hooks";

import { faClose } from "@fortawesome/free-solid-svg-icons/faClose";
import { faImage } from "@fortawesome/free-regular-svg-icons/faImage";
// Import the css.
import css from "./header.module.scss";


/** Renders the name of the current wiki object. */
export function Header(): ReactElement {
    // Load the name of the wiki object.
    const wiki = useContext(ReaderContext).wiki;
    // Load the notify.fatal error handler.
    const notify = useNotify();

    // Stores the text found in the title input.
    const [title, setTitle] = useState(wiki.attributes.name ?? "");

    // Store the state of the icon body.
    const [showIconModal, setShowIconModal] = useState(false);
    // Load the current wiki's icon.
    const icon = useAsset(wiki.relationships.icon.data?.id);
    const [iconUrl, setIconUrl] = useState<string>();
    useEffect(function generateAssetURL(): void | (() => void) {
        if (!icon) {
            return;
        }

        const url = URL.createObjectURL(new Blob([icon.data], { type: icon.resource.attributes.contentType }));
        setIconUrl(url);
        return function(): void {
            setIconUrl(undefined);
            URL.revokeObjectURL(url);
        };
    }, [icon]);

    // Generator for the new icon.
    const generator = useIconGenerator();
    const iconUpdate = useCallback(function generateNewIcon(event: ChangeEvent<HTMLInputElement>): void {
        const file = event.currentTarget.files?.[0];
        if (!file) {
            return;
        }

        generator(file);
    }, [generator]);

    // Callback used to change the name of the wiki.
    const id = useContext(ReaderContext).wiki.id;
    const dispatch = useResourceDispatch();
    const updateName = useCallback(function updateBody(): void {
        // Build the update object.
        const update: ZaqWiki.Update = { type: ZaqWiki.Type, id, attributes: { name: title } };

        // Build the update.
        dispatch(ZaqWikiStore.generator.update(update)).catch(notify.fatal);
    }, [id, title, dispatch, notify.fatal]);

    // Render the component.
    return <div className={css["header"]}>
        <input
            className={css["input"]}
            placeholder={"Donner un nom à ce ZaqWiki"}
            value={title}
            onChange={e => setTitle(e.currentTarget.value)}
            onBlur={updateName}
        />
        <SpeechRecognitionButton
            onText={setTitle}
            onEnd={updateName}
            interimResults
            stopWithUser
        />
        <button
            className={classNames(css["edit"], { [css["edit--active"]]: showIconModal })}
            onClick={() => setShowIconModal(true)}
        >
            <FontAwesomeIcon className={css["edit__icon"]} icon={faImage} />
        </button>
        <Modal
            show={showIconModal}
            centered
            backdrop
            onExit={() => setShowIconModal(false)}
            onBackdropClick={() => setShowIconModal(false)}
            className={css["icon-modal"]}
            dialogClassName={css["icon-modal__dialog"]}
            contentClassName={css["icon-modal__content"]}
            backdropClassName={css["icon-modal__backdrop"]}
        >
            <Modal.Header className={css["icon-modal__header"]}>
                <h1 className={css["icon-modal__title"]}>Éditer l'icône de ce ZaqWiki</h1>
                <button className={css["icon-modal__close"]} onClick={() => setShowIconModal(false)}>
                    <FontAwesomeIcon className={css["icon-modal__close__icon"]} icon={faClose} />
                </button>
            </Modal.Header>
            <Modal.Body className={css["icon-modal__body"]}>
                <label htmlFor="zaq-wiki/editor/icon-modal/input" className={css["icon-modal__container"]}>
                    <img src={iconUrl} className={css["icon-modal__icon"]} alt="Icône de ce ZaqWiki" />
                </label>
                <label role="button" className={css["icon-modal__button"]}>
                    <p className={css["icon-modal__button__text"]}>Changer l'icône</p>
                    <input
                        className={css["icon-modal__button__input"]}
                        type="file"
                        accept="image/*"
                        multiple={false}
                        onChange={iconUpdate}
                        id="zaq-wiki/editor/icon-modal/input"
                    />
                </label>
            </Modal.Body>
        </Modal>
    </div>;
}
