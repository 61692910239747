/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the custom components.
import { createSearchBar } from "@andromeda/components";
// Import the resources.
import { User } from "@andromeda/resources";


/** Search bar used to filter the users. */
const UserSearchBar = createSearchBar<User>();
export default UserSearchBar;
