/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the unleash client.
import { UnleashClient } from "unleash-proxy-client";
// Import the user resource.
import { Organisation, User } from "@andromeda/resources";
// Import the noop helpers.
import { makeContextNoop, makeContextInvalidObject } from "@andromeda/tools";


/** Interface used to describe the state of the login context. */
export interface LoginInfo {
    /** Flag that is set if the user is logged in. */
    isLoggedIn: boolean;
    /** Flag that is set if the user is an admin for the {@link organisations.current} organisation. */
    isAdmin: boolean;
    /** Reference to the user resource being wrapped. */
    self: User;

    /** Display name of the logged-in user. */
    name: string;
    /** Token used to communicate with the API. */
    token: string;

    /** Information regarding the user's organisations. */
    organisations: OrganisationInfo;

    /** Method used to log-out of the app. */
    logout(): void;
    /** Unleash client used for feature flags. */
    unleash: UnleashClient;
}

/** Interface used to describe all the organisations of the current user. */
export interface OrganisationInfo {
    /** List of all the organisations that this user belongs to. */
    own: Organisation[];
    /** Currently selected organisation. */
    current: Organisation;
    /** Method used to select the current organisation. */
    select(id: string): void;
}

// Default state of the login context.
const DEFAULT_STATE: LoginInfo = {
    isLoggedIn: false,
    isAdmin: false,
    self: makeContextInvalidObject("LoginContext", "self"),
    name: makeContextInvalidObject("LoginContext", "name"),
    token: makeContextInvalidObject("LoginContext", "token"),
    organisations: {
        own: [],
        current: makeContextInvalidObject("LoginContext", "organisations.current"),
        select: makeContextNoop("LoginContext", "organisations.select"),
    },
    logout: makeContextNoop("LoginContext", "logout"),
    unleash: makeContextInvalidObject("LoginContext", "unleash")
};

/** Context used to get info about the currently logged-in user. */
export const LoginContext = React.createContext<LoginInfo>(DEFAULT_STATE);
