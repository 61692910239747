/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement } from "react";
// Import the card component.
import { Card } from "@andromeda/storybook";

// Import the zaqtiv logo.
import zaqtiv from "@andromeda/assets/images/zaqtiv.svg"
// Import the css.
import css from "./title.module.scss";


/** Component used to render the title of the ZaqWiki. */
export default function ZaqWikiTitle(props: ZaqWikiTitleProps): ReactElement {
    // Render the component.
    return <Card withNavigation>
        <div className={css["title"]}>
            <div className={css["title__zaq-wiki"]}>
                <span className={css["title__zaq-wiki__text"]}>ZaqWiki</span>
            </div>
            <div className={css["title__title__container"]}>
                <h2 className={css["title__title"]} children={props.title} />
                <p className={css["title__description"]} children={props.description} />
            </div>
            <div className={css["title__icon__container"]}>
                <img src={props.icon} alt="organisation icon" className={css["title__icon"]} />
                <a
                    href="https://zaqtiv.com/"
                    target="_blank"
                    rel="noreferrer"
                    className={css["title__powered-by"]}
                >
                    <p className={css["title__powered-by__text"]}>powered by</p>
                    <img className={css["title__powered-by__icon"]} alt="zaqtiv-logo" src={zaqtiv} />
                </a>
            </div>
        </div>
    </Card>
}

/** Props passed down to the {@link ZaqWikiTitleProps}. */
export interface ZaqWikiTitleProps {
    /** The title of the wiki. */
    title: string;
    /** The description of the wiki. */
    description: string;
    /** The icon of the current organisation. */
    icon: string;
}
