/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the common interfaces.
import type { XhrTarget, XhrMethod  } from "../../interfaces";
// Import the errors.
import { InvalidTargetError } from "../../errors";
// Import the resolver.
import { resolveTarget } from "./target";

// Import the logging tool.
import debug from "debug";
const log = debug("xhr:services:config:url");


/**
 * Helper method used to build a new URL for the given target.
 *
 * @param {XhrTarget | string | URL} target The target to resolve.
 * @param {string} [path="/"] The path component of the URL.
 * @returns {Promise<URL>} A promise that resolves with the correct URL for the target.
 */
export async function resolveURL(target: XhrTarget | string | URL, path = "/"): Promise<URL> {
    // If the target is a URL, return it as-is.
    if (target instanceof URL) {
        return target;
    }

    // Resolve the target.
    if (typeof target === "string") {
        const resolved = await resolveTarget(target);
        // If the target could not be resolved, return it as-is.
        if (resolved === null) {
            try {
                return new URL(target);
            } catch {
                throw new InvalidTargetError(target);
            }
        }
        target = resolved[1];
    }

    log("Preparing a new url for target \"%s\"...", target.hostname);
    // Prepare the url object.
    const url = new URL(`https://${target.hostname}`);
    if (typeof target.port === "number") url.port = target.port.toString(10);
    if (target.ssl === false) url.protocol = "http:";
    let root = target.root ?? "/";
    if (!root.startsWith("/")) { root = "/" + root; }
    if (!root.endsWith("/"))   { root = root + "/"; }

    // Strip the first slash from the path.
    while (path.startsWith("/")) path = path.slice(1);
    url.pathname = root + path;
    return url;
}

/**
 * Converts a method and url into a human-readable http request line.
 *
 * @param {XhrMethod} method The method of the request.
 * @param {URL} url The url of the request.
 * @returns {string} A human-readable version of the request.
 */
export function humanReadableRequest(method: XhrMethod, url: URL): string {
    return `[${method} ${decodeURIComponent(url.toString())}]`;
}
