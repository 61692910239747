/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the json-compatible interfaces.
import { JsonValue, JsonObject } from "../../interfaces";
// Import the error classes.
import { NotSanitisableError } from "../../errors";


/**
 * Helper method used to ensure that a given object is valid JSON.
 * Any circular object is simply removed from the message.
 *
 * @param item The item to sanitise.
 * @returns {JsonValue} The sanitised item.
 * @throws {NotSanitisableError} If the item cannot be sanitised.
 */
export function jsonSanitiser(item: unknown): JsonValue {
    // Call the sanitiser
    const sanitised = sanitiseValue(item);

    // Handle all the non-sanitisable types.
    if (typeof sanitised === "undefined") throw new NotSanitisableError(typeof item);
    return sanitised;
}

/** Helper method used to sanitise an object. */
function sanitiseObject(object: object, hierarchy: object[]): JsonObject {
    // Loop through the properties of the object.
    const copy = Array.isArray(object) ? [...object] : { ...object };
    for (const [ key, value ] of Object.entries(copy)) {
        const castObject = copy as { [k: typeof key]: typeof value };

        // Check for circular dependencies.
        if (typeof value === "object" && value !== null && hierarchy.includes(value)) {
            delete castObject[key];
        } else {
            // Sanitise the value.
            castObject[key] = sanitiseValue(value, hierarchy);
            // Delete any undefined value.
            if (typeof castObject[key] === "undefined") delete castObject[key];
        }
    }
    return copy;
}

/** Helper method used to sanitise any value. */
function sanitiseValue(value: unknown, hierarchy: object[] = []): JsonValue | undefined {
    try {
        // Handle all the non-sanitisable types.
        if (typeof value === "undefined") return undefined;
        if (typeof value === "function") return undefined;
        if (typeof value === "symbol") return undefined;
        if (typeof value === "bigint") return undefined;

        // Handle the primitive types.
        if (value === null) return null;
        if (typeof value === "string") return value;
        if (typeof value === "boolean") return value;
        if (typeof value === "number") return value;

        // Handle the object types.
        if (typeof value === "object") return sanitiseObject(value, [ ...hierarchy, value ]);
    } catch (e: unknown) {
        console.error("Failed to convert a value: %s", e);
    }

    return undefined;
}
