/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */


/** MIME type of audio assets. */
export type AudioAssetMimeType = `audio/${string}`;
/** Metadata for an audio asset. */
export interface AudioAssetMetadata {
    /** MIME type of the converted asset. */
    contentType: AudioAssetMimeType;
    /** Bitrate of the converted asset. (in bits/second) */
    bitrate: number;
    /** Length of the converted asset. (in seconds) */
    length: number;
}

/** MIME type of image assets. */
export type ImageAssetMimeType = `image/${string}`;
/** Metadata for an image asset. */
export interface ImageAssetMetadata {
    /** MIME type of the converted asset. */
    contentType: ImageAssetMimeType;
    /** Width of the converted asset. (in pixels) */
    width: number;
    /** Height of the converted asset. (in pixels) */
    height: number;
}

/** MIME type of video assets. */
export type VideoAssetMimeType = `video/${string}`;
/** Metadata for a video asset. */
export interface VideoAssetMetadata {
    /** MIME type of the converted asset. */
    contentType: VideoAssetMimeType;
    /** Width of the converted asset. (in pixels) */
    width: number;
    /** Height of the converted asset. (in pixels) */
    height: number;
    /** Bitrate of the converted asset. (in bits/second) */
    bitrate: number;
    /** Length of the converted asset. (in seconds) */
    length: number;
    /** Framerate of the converted asset. (in images/second) */
    framerate: number;
}

/** Union of all the conversion results. */
export type AnyAssetMetadata = AudioAssetMetadata | ImageAssetMetadata | VideoAssetMetadata;

/** Helper used to extract the metadata from a given string. */
export type ExtractMetadata<T extends string> =
    T extends `image/${string}` ? ImageAssetMetadata :
    T extends `video/${string}` ? VideoAssetMetadata :
    T extends `audio/${string}` ? AudioAssetMetadata : AnyAssetMetadata;
