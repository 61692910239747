/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the selector hook.
import { useSelector as reduxUseSelector } from "react-redux";
// Import the logger.
import debug from "debug";


const log = debug("store:hooks:reducers");
// Import the event class.
import { Event } from "@andromeda/events";

// Import the store hook.
import { useStoreWithRegistry } from "./store";


/** Error thrown by {@link useSelector} if the reducer is not mounted. */
export class NotMountedError extends Error {
    /** @inheritDoc */
    public constructor(name: string) {
        super(`The requested reducer ${name} is not mounted !`);
    }
}

/** Simple hook used to check if a given reducer is mounted. */
export function useReducerIsMounted(name: string): boolean {
    // Get the store.
    const store = useStoreWithRegistry();
    // Store the state of the hook.
    React.useDebugValue(name);
    const [mounted, setIsMounted] = React.useState<boolean>(store.registry.reducerMap.has(name));

    // Attach the mounting listeners.
    React.useEffect(
        function attachMountListener(): (() => void) | void {
            log("Attaching listener for the %s mount events ...", name);
            // Attach the listeners.
            store.registry.eventTarget.addEventListener(
                "after:mount:reducer",
                onMounted
            );
            store.registry.eventTarget.addEventListener(
                "before:unmount:reducer",
                onUnmounted
            );

            // Clear the event listeners when the hook is unmounted.
            return () => {
                store.registry.eventTarget.removeEventListener(
                    "after:mount:reducer",
                    onMounted
                );
                store.registry.eventTarget.removeEventListener(
                    "before:unmount:reducer",
                    onUnmounted
                );
            };

            function onMounted(reducer: Event<string>): void {
                if (reducer.value === name) {
                    setIsMounted(true);
                }
            }

            function onUnmounted(reducer: Event<string>): void {
                if (reducer.value === name) {
                    setIsMounted(false);
                }
            }
        },
        [store, name, setIsMounted]
    );

    return mounted;
}

/** Simple wrapper for {@link useSelector} that returns the state of an entire reducer. */
export function useSelector<S = unknown>(name: string): S;

/**
 * Wrapper for the core {@link useSelector} hook.
 *
 * @template S=object
 * @template R=unknown
 * @param {string} name The name of the requested reducer.
 * @param {(state: S) => R} selector The selector to invoke.
 * @param {(a: R, b: R) => boolean} comparator An optional comparator to see if the state has changed.
 * @returns {R} The resulting value.
 * @throws {NotMountedError} The reducer is not mounted.
 */
export function useSelector<S = object, R = unknown>(
    name: string,
    selector: (state: S, store: unknown) => R,
    comparator?: (a: R, b: R) => boolean
): R;

/** Implementation ! */
export function useSelector<S extends object = object, R = unknown>(
    name: string,
    selector?: (state: S, store: unknown) => R,
    comparator?: (a: S | R, b: S | R) => boolean
): S | R {
    // Check if the reducer is mounted.
    return reduxUseSelector<S, S | R>(
        function getReducerFromState(
            state
        ): S | R {
            // Ensure that the reducer is mounted in the store.
            if (!(name in state)) {
                console.error(`Could not find ${name} in [${Object.keys(state).join(", ")}]`);
                console.error(state);
                throw new NotMountedError(name);
            } else {
                const reducerState = (state as { [k: typeof name]: S })[name];
                if (typeof selector === "function") {
                    return selector(reducerState, state);
                } else {
                    return reducerState;
                }
            }
        },
        comparator
    );
}

/** Helper for {@link buildCustomUseSelector}. */
interface SelectorHook<S extends object> {
    /** Default call signature. Simply returns the state of the reducer. */
    (): S;

    /** Selector signature, returns a sub-section of the reducer. */<R = unknown>(
        selector: (state: S) => R,
        comparator?: (a: R, b: R) => boolean
    ): R;
}

/**
 * Helper function used to build a custom {@link useSelector} hook.
 *
 * @template {object} S
 * @param {string} name The name of the wrapped reducer.
 * @returns {SelectorHook<S>} The selector hook.
 */
export function buildCustomUseSelector<S extends object>(
    name: string
): SelectorHook<S> {
    return function useCustomSelector<R>(
        selector?: (state: S) => R,
        comparator?: (a: R, b: R) => boolean
    ): S | R {
        if (typeof selector === "function") {
            return useSelector(name, selector, comparator);
        } else {
            return useSelector(name);
        }
    };
}
