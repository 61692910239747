/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactNode, MutableRefObject } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the UUID library.
import { v4 } from "uuid";
// Import the resources.
import { Asset, External } from "@andromeda/resources";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the store.
import { AssetStore, ExternalStore, useResourceDispatch } from "@andromeda/store";
// Import the JSON:API module.
import { extractURL } from "@andromeda/json-api";
// Import the custom components.
import { useNotify } from "@andromeda/components";

// Import the icons.
import { faQuestion } from "@fortawesome/free-solid-svg-icons";


/**
 * Helper hook used to convert an image and update the given external with the provided icon.
 *
 * @param {string | undefined} external The external that should be updated.
 * @param {React.MutableRefObject<HTMLCanvasElement | null>} canvas A ref to the canvas used for the image conversion.
 * @param {(image: React.ReactNode) => void} setImage A callback to invoke to update the rendered icon.
 * @return {(image: (File | null | undefined)) => Promise<void>} A callback that can be invoked to update the icon.
 */
export default function useImageConverter(
    external: string | undefined,
    canvas: MutableRefObject<HTMLCanvasElement | null>,
    setImage: (image: ReactNode) => void
) {
    // Helper used to draw the image on the canvas.
    function drawOnCanvas(
        canvas: HTMLCanvasElement,
        image: HTMLImageElement,
        target: { x: number, y: number, w: number, h: number }
    ): void {
        // Get the canvas context.
        const context = canvas.getContext("2d");
        if (!context) {
            throw new ReferenceError("Could not initialise the rendering context");
        }

        // Draw the image on the canvas.
        context.drawImage(image, target.x, target.y, target.w, target.h);
    }

    /** Creates a new asset from the given blob. */
    const dispatch = useResourceDispatch();

    function blobToAsset(blob: Blob, width: number, height: number): Promise<Asset> {
        // Create the asset.
        const asset: Asset.Create = {
            type: Asset.Type,
            id: v4(),
            attributes: {
                contentType: "image/png",
                size: blob.size,
                width, height
            },
            relationships: {
                alternatives: { data: [] },
                source: { data: null },
                encryptionSource: { data: null }
            }
        };
        return dispatch(AssetStore.generator.create(asset))
            .then(async function uploadImageToBucket(asset: Asset): Promise<Asset> {
                if (typeof asset.links?.put === "undefined") {
                    throw new Error("Cannot find a URL to store the asset data");
                }
                const target = extractURL(asset.links.put);

                // Upload the image to the bucket.
                await fetch(
                    target,
                    {
                        method: "PUT",
                        headers: { "Content-Type": "image.png" },
                        cache: "no-cache",
                        body: blob
                    }
                );

                // Return the asset.
                return asset;
            });
    }

    // Helper function used to attach the asset to the current external.
    async function attachImageToExternal(asset: string, external: string): Promise<void> {
        const update: External.Update = {
            type: External.Type, id: external,
            relationships: { asset: { data: { type: Asset.Type, id: asset }}}
        };
        await dispatch(ExternalStore.generator.update(update));
    }

    // Return the converter.
    const { error } = useNotify();
    return async function imageConverter(image: File | null | undefined): Promise<void> {
        // If the image is not set, do nothing.
        if (!image || !external) {
            return;
        }

        // Draw the image to the canvas.
        const data = new Image();
        const url = URL.createObjectURL(image);
        data.src = url;
        data.decode()
            .then(async function() {
                // Ensure that the canvas is set.
                if (!canvas.current) {
                    throw new Error("No canvas target !");
                }

                // Compute the position and size of the image.
                const target = { x: 0, y: 0, w: canvas.current.width, h: canvas.current.height };
                const naturalRatio = data.naturalWidth / data.naturalHeight;
                if (naturalRatio > 1) {
                    target.h = Math.floor(target.h / naturalRatio);
                    target.y = Math.floor((canvas.current.height - target.h) / 2);
                } else {
                    target.w = Math.floor(target.w * naturalRatio);
                    target.x = Math.floor((canvas.current.width - target.w) / 2);
                }

                // Draw the image to the canvas.
                drawOnCanvas(canvas.current, data, target);

                // Convert the canvas to a blob.
                setImage(<Loader />);
                const blob = await new Promise<Blob>((resolve, reject) => {
                    canvas.current?.toBlob(function onBlob(blob: Blob | null): void {
                        if (!blob) {
                            reject(new Error("Failed to convert the canvas to a blob."));
                        } else {
                            resolve(blob);
                        }
                    }, "image/png");
                });

                // Store the asset.
                const asset = await blobToAsset(blob, target.w, target.h);

                // Update the image.
                await attachImageToExternal(asset.id, external);

                // Update the image.
                if (typeof asset.links?.get !== "undefined") {
                    setImage(<img src={extractURL(asset.links.get).toString()} alt="external-icon" />);
                } else {
                    setImage(<FontAwesomeIcon icon={faQuestion} />);
                }
            })
            .catch(error)
            // Revoke the url.
            .finally(() => URL.revokeObjectURL(url));
    };
}
