/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import React-Bootstrap.
import { Modal } from "react-bootstrap";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the store.
import { UserStore, withStores } from "@andromeda/store";

// Import the subcomponents.
import UserSearchBar from "./search-bar";
import UserList from "./user-list";

// Import the icons.
import { faClose } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./modal.module.scss";


/** Component used to render the list of user and alter their permissions. */
const AdministrationModal = withStores(
    function AdministrationModal(props: AdministrationModalProps): ReactElement {
        // Render the modal.
        return <Modal
            show={props.show}
            onHide={props.onHide}
            centered backdrop
            className={css["admin"]}
            dialogClassName={css["admin__dialog"]}
            contentClassName={css["admin__content"]}
        >
            <Modal.Header className={css["header"]}>
                <Modal.Title className={css["title"]}>Attribution des permissions</Modal.Title>
                <button className={css["close-button"]} onClick={props.onHide}>
                    <FontAwesomeIcon icon={faClose} className={css["close-button__icon"]} />
                </button>
            </Modal.Header>
            <Modal.Body className={css["body"]}>
                <UserList course={props.course} />
                <UserSearchBar.Input
                    withoutList
                    className={css["search-bar"]}
                    placeholder="Chercher un utilisateur ..."
                />
            </Modal.Body>
        </Modal>;
    },
    UserStore
);
export default AdministrationModal;

/** Props passed down to the {@link AdministrationModal} component. */
export interface AdministrationModalProps {
    /** Identifier of the course being rendered. */
    course: string;
    /** If {@link true}, shows the modal on screen. */
    show: boolean;
    /** Callback invoked to hide the modal. */
    onHide(): void;
}
