/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { useMemo, useState, ReactElement, ReactNode } from "react";

// Import the subcomponents.
import { TableHeader } from "./header";
import { TableRow } from "./row";

// Import the css.
import css from "./index.module.scss";


/** Import the table component. */
export function Table(props: TableProps): ReactElement | null {
    // Store the index of the rendered page.
    const [currentPage, setCurrentPage] = useState(0);

    // Render the table class name.
    const tableClassName = useMemo(function renderTableClassName(): string {
        let className = css["table"];
        if (props.className) {
            className += ` ${props.className}`;
        }
        return className;
    }, [props.className]);

    // Pre-render all the pages.
    const pages = useMemo(
        function preRenderPages(): ReactElement[] {
            const pageNames = props.pages.map(page => page.name);
            return props.pages.map(function renderPage(page: TablePage, index: number): ReactElement {
                // Extract the rows from the page.
                const rows = page.headerColumn.map((head: ReactNode, index: number) =>
                    <TableRow
                        key={index}
                        header={head}
                        cells={page.cells[index]}
                        className={props.tbodyRowClassName}
                        thClassName={props.tbodyHeadClassName}
                        tdClassName={props.tbodyDataClassName}
                    />
                );

                return <table className={tableClassName} key={index}>
                    <TableHeader
                        cells={page.headerRow}
                        currentPage={currentPage}
                        pageNames={pageNames}
                        updatePageNumber={setCurrentPage}
                        className={props.theadClassName}
                        rowClassName={props.theadRowClassName}
                    />
                    <tbody className={props.tbodyClassName}>{rows}</tbody>
                </table>;
            });
        },
        [
            currentPage,
            props.pages,
            props.tbodyClassName,
            props.tbodyDataClassName,
            props.tbodyHeadClassName,
            props.tbodyRowClassName,
            props.theadClassName,
            props.theadRowClassName,
            tableClassName
        ]
    );

    // Render the component.
    if (props.pages.length < 1) {
        return null;
    }
    return pages[currentPage];
}

/** Props that will be passed to the {@link Table} component. */
interface TableProps {
    /** List of all the pages to render on this table. */
    pages: TablePage[];
    /** Class name that will be added to the table. */
    className?: string;
    /** Class name added to the <thead> section of the table. */
    theadClassName?: string;
    /** Class name added to the <thead>'s <tr> element. */
    theadRowClassName?: string;
    /** Class name added to the <tbody> section of the table. */
    tbodyClassName?: string;
    /** Class name added to the <tbody>'s <tr> element. */
    tbodyRowClassName?: string;
    /** Class name added to the <tbody>'s <th> element. */
    tbodyHeadClassName?: string;
    /** Class name added to the <tbody>'s <td> element. */
    tbodyDataClassName?: string;
}

/** Individual "page" to render on a table. */
export interface TablePage {
    /** Name of the page. */
    name: string;
    /** List of cells to render in the header row. */
    headerRow: ReactNode[];
    /** List of cells to render in the header column. */
    headerColumn: ReactNode[];
    /** 2D table of the data to render. */
    cells: ReactNode[][];
}
