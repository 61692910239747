/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the JSON:API interfaces.
import { Resource, Relationship } from "@andromeda/json-api";
// Import the json:schema compiler.
import { compileSync } from "@andromeda/validation";
// Import the resource helpers.
import { CreatableResource, PartialResource } from "@andromeda/resource-helper";

// Import the other resources.
import { Organisation, Asset } from "../..";

// Import the schemas.
import { ExternalSchema } from "./schema";


/** Interface used to represent an external item for courses. */
export interface External extends Resource<External.Type> {
    /** @inheritDoc */
    attributes: External.Attributes;
    /** @inheritDoc */
    relationships: External.Relationships;
}

/** Augment the {@link External} interface. */
export namespace External {
    export const Type = "external" as const;
    export type Type = typeof Type;

    /** Attributes of an {@link External} */
    export interface Attributes {
        /** Localised name of the element. */
        name: string;
    }

    /** Relationships of a {@link External} */
    export interface Relationships {
        /** Organisation in which the external is declared. */
        organisation: Relationship<Organisation>;
        /** Asset to use as the icon of this external. */
        asset: Relationship<Asset | null>;
    }

    export const validate = compileSync<External>(ExternalSchema.ResourceSchema);


    /** Interface used to update a {@link External}. */
    export interface Update extends Omit<PartialResource<External>, "relationships"> {
        relationships?: Update.Relationships;
    }

    /** Augments the {@link Update} interface. */
    export namespace Update {
        export type Relationships = Partial<Pick<External.Relationships, "asset">>;
        export const validate = compileSync<External.Update>(ExternalSchema.UpdateSchema);
    }


    /** Interface used to create new {@link External}. */
    export type Create = CreatableResource<External>;

    /** Augments the {@link Create} interface. */
    export namespace Create {
        export const validate = compileSync<External.Create>(ExternalSchema.CreateSchema);
    }
}
