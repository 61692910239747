/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the resource interface.
import { Resource } from "@andromeda/json-api";
// Import the resource helper interfaces.
import {
    CreatableResource,
    CRUDTuple,
    PartialResource,
} from "@andromeda/resource-helper";

// Import the api actions.
import {
    RequestActionWithPayload,
    RequestActionWithoutPayload,
    SuccessActionWithPayload,
    FailureActionWithPayload,
} from "./api";
// Import the error classes.
import {
    CreateFailedError,
    ReadFailedError,
    UpdateFailedError,
    DeleteFailedError,
} from "./error";

export type CreateRequestAction<
    R extends Resource,
    C extends CreatableResource<Resource>
> = RequestActionWithPayload<`${R["type"]}/create`, C>;
export type CreateSuccessAction<R extends Resource> = SuccessActionWithPayload<
    `${R["type"]}/create`,
    R
>;
export type CreateFailureAction<R extends Resource> = FailureActionWithPayload<
    `${R["type"]}/create`,
    CreateFailedError
>;

export type ReadOneRequestAction<R extends Resource> =
    RequestActionWithoutPayload<`${R["type"]}/read-one`>;
export type ReadOneSuccessAction<R extends Resource> = SuccessActionWithPayload<
    `${R["type"]}/read-one`,
    R
>;
export type ReadOneFailureAction<R extends Resource> = FailureActionWithPayload<
    `${R["type"]}/read-one`,
    ReadFailedError
>;

export type ReadManyRequestAction<R extends Resource> =
    RequestActionWithoutPayload<`${R["type"]}/read-many`>;
export type ReadManySuccessAction<R extends Resource> =
    SuccessActionWithPayload<`${R["type"]}/read-many`, R[]>;
export type ReadManyFailureAction<R extends Resource> =
    FailureActionWithPayload<`${R["type"]}/read-many`, ReadFailedError>;

export type UpdateRequestAction<
    R extends Resource,
    U extends PartialResource<Resource>
> = RequestActionWithPayload<`${R["type"]}/update`, U>;
export type UpdateSuccessAction<R extends Resource> = SuccessActionWithPayload<
    `${R["type"]}/update`,
    R | null
>;
export type UpdateFailureAction<R extends Resource> = FailureActionWithPayload<
    `${R["type"]}/update`,
    UpdateFailedError
>;

export type DeleteRequestAction<R extends Resource> =
    | RequestActionWithoutPayload<`${R["type"]}/delete`>
    | RequestActionWithPayload<`${R["type"]}/delete`, string>;
export type DeleteSuccessAction<R extends Resource> = SuccessActionWithPayload<
    `${R["type"]}/delete`,
    string
>;
export type DeleteFailureAction<R extends Resource> = FailureActionWithPayload<
    `${R["type"]}/delete`,
    DeleteFailedError
>;

export type CRUDAction<R extends CRUDTuple> =
    | CreateRequestAction<R[0], R[2]>
    | CreateSuccessAction<R[0]>
    | CreateFailureAction<R[0]>
    | ReadOneRequestAction<R[0]>
    | ReadOneSuccessAction<R[0]>
    | ReadOneFailureAction<R[0]>
    | ReadManyRequestAction<R[0]>
    | ReadManySuccessAction<R[0]>
    | ReadManyFailureAction<R[0]>
    | UpdateRequestAction<R[0], R[1]>
    | UpdateSuccessAction<R[0]>
    | UpdateFailureAction<R[0]>
    | DeleteRequestAction<R[0]>
    | DeleteSuccessAction<R[0]>
    | DeleteFailureAction<R[0]>;

/** Type guard for the {@link CreateRequestAction} interface. */
export function isCreateRequestAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is CreateRequestAction<R[0], R[2]> {
    return action.type === `${name}/create/request`;
}
/** Type guard for the {@link CreateSuccessAction} interface. */
export function isCreateSuccessAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is CreateSuccessAction<R[0]> {
    return action.type === `${name}/create/success`;
}
/** Type guard for the {@link CreateFailureAction} interface. */
export function isCreateFailureAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is CreateFailureAction<R[0]> {
    return action.type === `${name}/create/failure`;
}

/** Type guard for the {@link ReadOneRequestAction} interface. */
export function isReadOneRequestAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is ReadOneRequestAction<R[0]> {
    return action.type === `${name}/read-one/request`;
}
/** Type guard for the {@link ReadOneSuccessAction} interface. */
export function isReadOneSuccessAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is ReadOneSuccessAction<R[0]> {
    return action.type === `${name}/read-one/success`;
}
/** Type guard for the {@link ReadOneFailureAction} interface. */
export function isReadOneFailureAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is ReadOneFailureAction<R[0]> {
    return action.type === `${name}/read-one/failure`;
}

/** Type guard for the {@link ReadManyRequestAction} interface. */
export function isReadManyRequestAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is ReadManyRequestAction<R[0]> {
    return action.type === `${name}/read-many/request`;
}
/** Type guard for the {@link ReadManySuccessAction} interface. */
export function isReadManySuccessAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is ReadManySuccessAction<R[0]> {
    return action.type === `${name}/read-many/success`;
}
/** Type guard for the {@link ReadManyFailureAction} interface. */
export function isReadManyFailureAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is ReadManyFailureAction<R[0]> {
    return action.type === `${name}/read-many/failure`;
}

/** Type guard for the {@link UpdateRequestAction} interface. */
export function isUpdateRequestAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is UpdateRequestAction<R[0], R[1]> {
    return action.type === `${name}/update/request`;
}
/** Type guard for the {@link UpdateSuccessAction} interface. */
export function isUpdateSuccessAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is UpdateSuccessAction<R[0]> {
    return action.type === `${name}/update/success`;
}
/** Type guard for the {@link UpdateFailureAction} interface. */
export function isUpdateFailureAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is UpdateFailureAction<R[0]> {
    return action.type === `${name}/update/failure`;
}

/** Type guard for the {@link DeleteRequestAction} interface. */
export function isDeleteRequestAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is DeleteRequestAction<R[0]> {
    return action.type === `${name}/delete/request`;
}
/** Type guard for the {@link DeleteSuccessAction} interface. */
export function isDeleteSuccessAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is DeleteSuccessAction<R[0]> {
    return action.type === `${name}/delete/success`;
}
/** Type guard for the {@link DeleteFailureAction} interface. */
export function isDeleteFailureAction<R extends CRUDTuple>(
    name: R[0]["type"],
    action: CRUDAction<R>
): action is DeleteFailureAction<R[0]> {
    return action.type === `${name}/delete/failure`;
}
