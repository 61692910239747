/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */


// Import React.
import { ReactElement } from "react";
// Import the store.
import { useV2Asset } from "@andromeda/store";

// Import the wrapped icon component.
import IconRenderer from "./icon";


/** Component used to render the icon of a Zaq. */
export default function ZaqIcon(props: ZaqIconProps): ReactElement | null {
    return <IconRenderer asset={useV2Asset(props.icon)} alt="Icône de ce Zaq" />;
}

/** Props provided to the {@link ZaqIcon} component. */
export interface ZaqIconProps {
    /** Identifier of the loaded icon. */
    icon?: string;
    /** Class name added to the <img> element. */
    className?: string;
}
