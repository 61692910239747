/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the list of supported methods.
import { XhrMethod } from "../interfaces";


/**
 * Error thrown when a provided target is invalid.
 *
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class InvalidTargetError extends Error {
    /**
     * @param {string | URL} target The unsupported target.
     */
    public constructor(target: string | URL) {
        super(
            `The provided target "${target}" is invalid.\n` +
            `No target with the provided name was found in the config, and it is not a valid url.`
        );
    }
}

/** Error thrown when a request failed. */
export class RequestFailedError extends Error {
    /**
     * @param {XhrMethod} method The method of the request.
     * @param {URL} url The requested url.
     * @param {unknown} inner The inner error.
     */
    public constructor(method: XhrMethod, url: URL, inner: unknown) {
        super(
            `"${method}" request to "${url}" failed.\n` +
            `Inner error: ${String(inner)}`
        );
    }
}

/** Error thrown if a request was retried too many times. */
export class TooManyRetriesError extends Error {}
