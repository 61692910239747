/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

/** Interface used to specify a simple scaling. */
export interface ScaledDimensions { scale: number; }
/** Interface used to provide specific output dimensions. */
export interface SpecificDimensions { width: number; height: number; }
/** Interface used to transform into a specific height and a ratio. */
export interface HeightAndRatioDimensions { height: number; ratio: number; }
/** Interface used to transform into a specific width and a ratio. */
export interface WidthAndRatioDimensions { width: number; ratio: number; }

/** Union of all the target dimensions. */
export type Dimensions = ScaledDimensions | SpecificDimensions | HeightAndRatioDimensions | WidthAndRatioDimensions;

/**
 * Interface used to specify the section of the media that should be kept.
 * Values are stored in a UV mapping-like format. Where 0 is the top-left of the image and 1 its bottom right.
 */
export interface SourceRect {
    x: number; y: number;
    width: number; height: number;
}
