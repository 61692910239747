/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";
// Import the audio asset types.
import { AudioAssetMimeType, AudioAssetMetadata } from "@andromeda/asset-conversion";

// Import the job interface.
import { Job } from "./job";

// Import the css.
import css from "./index.module.scss";


/** Component used to parse a given audio file. */
export function AudioParser(props: AudioParserProps): React.ReactElement {
    // Store the file url.
    const [ url, setURL ] = React.useState<string>();
    React.useEffect(function createAudioURL(): () => void {
        const url = URL.createObjectURL(props.job.file);
        setURL(url); return () => { URL.revokeObjectURL(url); setURL(undefined); };
    }, [props.job.file]);

    // Resolves the job with the file's metadata.
    const resolve = React.useCallback(
        function resolveAudioMetadata(event: React.SyntheticEvent<HTMLAudioElement>): void {
            const audio = event.currentTarget;

            // Compute the attributes of the video.
            const attributes: AudioAssetMetadata = {
                contentType: props.job.file.type as AudioAssetMimeType,
                length: isFinite(audio.duration) ? audio.duration : -1,
                bitrate: -1
            };
            props.job.resolve(attributes);
        },
        [ props.job ]
    );

    // Rejects the job.
    const reject = React.useCallback(function rejectVideoFile(event: React.SyntheticEvent<HTMLAudioElement>): void {
        props.job.reject(event);
    }, [props.job]);

    // Render the component.
    return <audio
        className={css["parser"]} src={url}
        onLoadedMetadata={resolve} onLoadedData={resolve} onError={reject}
    />;
}

/** Props passed down to the {@link AudioParser} component. */
interface AudioParserProps {
    /** The job to run on this parser. */
    job: Job;
}
