/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import {
    Context,
    createElement, ForwardedRef,
    forwardRef,
    ReactElement, Ref,
    useCallback,
    useContext,
    useEffect, useImperativeHandle,
    useMemo, useRef,
    useState
} from "react";
// Import react-bootstrap.
import type { OverlayInjectedProps } from "react-bootstrap/Overlay";
// Import the text comparator.
import { compareText } from "@andromeda/tools";

// Import the search bar context.
import { SearchBarContext, SearchBarCategory, SearchResultItem, SearchBarItemRendererProps } from "../../context";

// Import the css.
import css from "./list.module.scss";


type SearchBarList = <T>(props: SearchBarListProps<T> & { ref: Ref<HTMLUListElement | null> }) => ReactElement;
/** Component used to render the list of search results. */
export const SearchBarList = forwardRef(function SearchBarList<T>(
    props: SearchBarListProps<T>,
    ref: ForwardedRef<HTMLUListElement | null>
): ReactElement | null {
    const ulRef = useRef<HTMLUListElement | null>(null);
    useImperativeHandle<HTMLUListElement | null, HTMLUListElement | null>(
        ref,
        function(): HTMLUListElement | null {
            props.overlayProps.ref(ulRef.current);
            return ulRef.current;
        },
        [props.overlayProps]
    );
    const context = useContext(props.context);

    // Render all the categories.
    const categories = useMemo(function renderCategories(): ReactElement[] {
        return context.matchedCategories.map((_: SearchBarCategory<T>, index: number): ReactElement =>
            <Category context={props.context} index={index} key={index} hide={props.hide} />
        );
    }, [context.matchedCategories, props.context, props.hide]);

    // Render the list.
    if (!props.show || categories.length <= 0) {
        return null;
    }
    return <ul
        className={css["searchbar-list"]}
        children={categories}
        ref={ulRef}
        style={{ ...props.overlayProps.style, width: props.width }}
    />;
}) as SearchBarList;

/** Props passed down to the {@link SearchBarList} component. */
export interface SearchBarListProps<T> {
    /** The context to load the categories from. */
    context: Context<SearchBarContext<T>>;
    /** If true, shows the list. */
    show: boolean;

    /** Hides the list. */
    hide(): void;

    /** Props provided by the <Overlay> component. */
    overlayProps: OverlayInjectedProps;
    /** Width of the reference input. */
    width?: string;
}

/** List of all the exported props. */
type ExportedSearchBarListProps<T> = Omit<SearchBarListProps<T>, "context" | "show" | "hide">;

/** Helper type that aliases all the keys of {@link SearchBarListProps} to a "list"-prefixed version. */
export type SearchBarListPropsPrefixed<T> = {
    [K in keyof ExportedSearchBarListProps<T> & string as `list${Capitalize<K>}`]?: ExportedSearchBarListProps<T>[K]
}

/** Default renderer for the search bar items. */
function DefaultItemRenderer<T>(props: SearchBarItemRendererProps<T>): ReactElement {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{props.item.text}</>;
}

/** Number of results shown by default, before the "show all" button is pressed. */
const DEFAULT_RESULT_COUNT = 3;

/** Component used to render a single category. */
function Category<T>(props: CategoryProps<T>): ReactElement {
    // Load the category.
    const context = useContext(props.context);
    const category = useMemo(() => context.matchedCategories[props.index], [context.matchedCategories, props.index]);

    // Define whether all items should be rendered.
    const [showAll, setShowAll] = useState(false);
    useEffect(() => {
        !context.text && setShowAll(false);
    }, [context.text]);

    // Extract the item renderer from the category.
    const itemRenderer = useMemo(() => category.itemComponent ?? DefaultItemRenderer, [category.itemComponent]);
    // Render the category header and footer.
    const header = useMemo(() => category.header, [category.header]);
    const footer = useMemo(() => category.footer, [category.footer]);

    /** Helper used to render a single item. */
    const { hide } = props;
    const renderItem = useCallback(function renderItem(item: SearchResultItem<T>, index: number): ReactElement {
        return <li key={item.key ?? index} className={css["searchbar-list__item"]}>
            <button
                className={css["searchbar-list__item__button"]}
                children={createElement(itemRenderer, { item })}
                onClick={() => {
                    item.onSelect?.();
                    hide();
                }}
                onMouseOver={item.onFocus}
            />
        </li>;
    }, [itemRenderer, hide]);

    const compareItems = useCallback(function compareItems(a: SearchResultItem<T>, b: SearchResultItem<T>): number {
        return compareText(a.text, b.text);
    }, []);

    // Render the list.
    const children = useMemo(() => {
        let children = category.items.sort(compareItems).map(renderItem);
        if (!showAll && children.length > DEFAULT_RESULT_COUNT) {
            children = children.slice(0, DEFAULT_RESULT_COUNT);
            children.push(<button
                key="show-all"
                className={css["searchbar-list__show-all"]}
                children="Afficher tous les résultats ..."
                onClick={() => setShowAll(true)}
            />);
        }
        return children;
    }, [category.items, compareItems, renderItem, showAll]);

    return <section className={css["searchbar-list__category"]}>
        <header children={header} />
        <ul className={css["searchbar-list__category__list"]} children={children} />
        <footer children={footer} />
    </section>;
}

/** Props passed down to the {@link Category} component. */
interface CategoryProps<T> {
    /** The search bar context to use. */
    context: Context<SearchBarContext<T>>;
    /** The index of the renderer category. */
    index: number;

    /** Hides the list. */
    hide(): void;
}
