/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import the logger.
import debug from "debug";
const log = debug("store:hooks:middleware");
// Import the event class.
import { Event } from "@andromeda/events";

// Import the store hook.
import { useStoreWithRegistry } from "./store";


/** Error thrown by {@link useSelector} if the middleware is not mounted. */
export class MiddlewareNotMountedError extends Error {
    /** @inheritDoc */
    public constructor(name: string) {
        super(`The requested middleware ${name} is not mounted !`);
    }
}

/** Simple hook used to check if a given middleware is mounted. */
export function useMiddlewareIsMounted(name: string): boolean {
    // Get the store.
    const store = useStoreWithRegistry();
    // Store the state of the hook.
    const [ mounted, setIsMounted ] = React.useState<boolean>(
        store.registry.middlewareMap.has(name)
    );

    // Attach the mounting listeners.
    React.useEffect(
        function attachMountListener(): (() => void) | void {
            log("Attaching listener for the %s mount events ...", name);
            // Attach the listeners.
            store.registry.eventTarget.addEventListener(
                "after:mount:middleware",
                onMounted
            );
            store.registry.eventTarget.addEventListener(
                "before:unmount:middleware",
                onUnmounted
            );

            // Clear the event listeners when the hook is unmounted.
            return () => {
                store.registry.eventTarget.removeEventListener(
                    "after:mount:middleware",
                    onMounted
                );
                store.registry.eventTarget.removeEventListener(
                    "before:unmount:middleware",
                    onUnmounted
                );
            };

            function onMounted(middleware: Event<string>): void {
                console.log(middleware, name);
                if (middleware.value === name) setIsMounted(true);
            }
            function onUnmounted(middleware: Event<string>): void {
                console.log(middleware, name);
                if (middleware.value === name) setIsMounted(false);
            }
        },
        [store, name, setIsMounted]
    );

    return mounted;
}
