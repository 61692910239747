/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { useEffect, useState, useContext, useCallback, ReactElement } from "react";
// Import the device detector.
import { CustomView, isChrome, isDesktop, isMobile, MobileOnlyView } from "react-device-detect";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the custom components.
import { useNotify } from "@andromeda/components";
// Import the ZaqWiki resources.
import { ZaqWiki, ZaqWikiFile } from "@andromeda/resources";
// Import the store.
import { ZaqWikiFileStore, useResourceDispatch } from "@andromeda/store";

// Import the reader context.
import { ReaderContext } from "../context";
//
import { LoginContext} from "@andromeda/login";
// Import the menu context.
import { ActionMenuContext } from "./context";
// Import the custom hooks.
import { useInputGenerator, useClickForwarder, useGenerator } from "./hooks";
// Import the modals.
import { useLinkConfirm } from "./link-popup";
import { useTutoConfirm } from "./notice-popup";
import { useCaptureModal } from "./capture";

// Import the icons.
import notice from "@andromeda/assets/images/zaq-notice-outline.svg";
import link from "@andromeda/assets/images/link.svg";
import photoCamera from "@andromeda/assets/images/photo-camera-outline.svg";
import sound from "@andromeda/assets/images/sound-outline.svg";
import videoCamera from "@andromeda/assets/images/video-camera-outline.svg";
import pdf from "@andromeda/assets/images/pdf.svg";
import title from "@andromeda/assets/images/t.svg";
import screenCapture from "@andromeda/assets/images/screen-capture.svg";
import paste from "@andromeda/assets/images/paste.svg";
import download from "@andromeda/assets/images/download.svg";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons/faAlignLeft";
// Import the css.
import css from "./icons.module.scss";


/** Component used to render the icon that is used to add a new notice. */
export function NoticeIcon(): ReactElement | null {
    const noticeModal = useTutoConfirm();
    const notify = useNotify();
    const actionMenuContext = useContext(ActionMenuContext);
    const orgTutoPermission = useContext(LoginContext).organisations.current.attributes.permissions["zaq-tuto"];

    // Render the component.
    return orgTutoPermission ? <button
        className={css["icon"]}
        onClick={() => { actionMenuContext.close(); noticeModal().catch(notify.fatal);
    }}>
        <SourceIcon name="zaq notice" src={notice} small={true} />
    </button> : null;
}

/** Component used to render the icon that is used to add a new link. */
export function LinkIcon(): ReactElement {
    const linkModal = useLinkConfirm();
    const notify = useNotify();
    const actionMenuContext = useContext(ActionMenuContext);

    // Render the component.
    return <button
        className={css["icon"]}
        onClick={() => { actionMenuContext.close(); linkModal().catch(notify.fatal); }}
    >
        <SourceIcon name="lien" src={link} />
    </button>;
}

/** Component used to render the icon that is used to add a new image. */
export function ImageIcon(): ReactElement {
    // Load the generator.
    const generator = useInputGenerator();
    const actionMenuContext = useContext(ActionMenuContext);
    const forwardClick = useClickForwarder(actionMenuContext.close);

    // Render the component.
    return <button className={css["icon"]} onClick={forwardClick}>
        <SourceIcon name="image" src={photoCamera} />
        <input type="file" accept="image/*" capture="environment" multiple onChange={generator} />
    </button>;
}

/** Component used to render the icon that is used to add a new audio file. */
export function SoundIcon(): ReactElement {
    // Load the generator.
    const capture = useCaptureModal();
    const actionMenuContext = useContext(ActionMenuContext);

    // Render the component.
    return <button
        className={css["icon"]}
        onClick={() => { actionMenuContext.close(); capture({ mode: "audio" }); }}
    >
        <SourceIcon name="audio" src={sound} />
    </button>;
}

/** Component used to render the icon that is used to add a new video file on desktop. */
export function DesktopVideoIcon(): ReactElement {
    // Load the generator.
    const generator = useInputGenerator();
    const actionMenuContext = useContext(ActionMenuContext);
    const forwardClick = useClickForwarder(actionMenuContext.close);

    // Render the component.
    return <CustomView condition={!isMobile} renderWithFragment>
        <button className={css["icon"]} onClick={forwardClick}>
            <SourceIcon name="image" src={videoCamera} />
            <input type="file" multiple onChange={generator} />
        </button>
    </CustomView>;
}

/** Component used to render the icon that is used to add a new video file. */
export function MobileVideoIcon(): ReactElement {
    // Load the generator.
    const capture = useCaptureModal();
    const actionMenuContext = useContext(ActionMenuContext);

    // Render the component.
    return <CustomView condition={isMobile} renderWithFragment>
        <button className={css["icon"]} onClick={() => { actionMenuContext.close(); capture({ mode: "video" }); }}>
            <SourceIcon name="vidéo" src={videoCamera} />
        </button>
    </CustomView>;
}

/** Component used to render the icon that is used to add a new file on mobile. */
export function FileIcon(): ReactElement {
    // Load the generator.
    const generator = useInputGenerator();
    const actionMenuContext = useContext(ActionMenuContext);
    const forwardClick = useClickForwarder(actionMenuContext.close);

    // Render the component.
    return <CustomView renderWithFragment condition={!isDesktop}>
        <button className={css["icon"]} onClick={forwardClick}>
            <SourceIcon name="fichier" src={download} />
            <input type="file" accept="video/*,image/*,audio/*" multiple onChange={generator} />
        </button>
    </CustomView>;
}

/** Component used to render the icon that is used to add a new pdf file. */
export function PdfIcon(): ReactElement {
    // Load the generator.
    const generator = useInputGenerator();
    const actionMenuContext = useContext(ActionMenuContext);
    const forwardClick = useClickForwarder(actionMenuContext.close);

    // Render the component.
    return <button className={css["icon"]} onClick={forwardClick}>
        <SourceIcon name="pdf" src={pdf} />
        <input
            type="file"
            accept="application/pdf"
            multiple
            onChange={generator}
        />
    </button>;
}

/** Component used to render the icon that is used to add a new text file. */
export function TextIcon(): ReactElement {
    // Method used to generate a new, empty text file.
    const id = useContext(ReaderContext).wiki.id;
    const dispatch = useResourceDispatch();
    const actionMenuContext = useContext(ActionMenuContext);
    const create = useCallback(
        function createNewFile(): void {
            actionMenuContext.close();
            dispatch(
                ZaqWikiFileStore.generator.create(
                    {
                        type: ZaqWikiFile.Type,
                        attributes: { name: "", contents: "", },
                        relationships: { wiki: { data: { type: ZaqWiki.Type, id } }, },
                    },
                    { include: [ZaqWiki.Type], }
                )
            );
        },
        [actionMenuContext, dispatch, id]
    );

    // Render the component.
    return <button className={css["icon"]} onClick={create}>
        <FontAwesomeIcon icon={faAlignLeft} className={`${css["icon__icon"]} ${css["icon__icon--no-filter"]}`} />
    </button>;
}

/** Component used to render the icon that is used to add a new section. */
export function SectionIcon(): ReactElement {
    // Method used to generate a new, empty text file.
    const id = useContext(ReaderContext).wiki.id;
    const dispatch = useResourceDispatch();
    const actionMenuContext = useContext(ActionMenuContext);
    const create = useCallback(
        function createNewFile(): void {
            actionMenuContext.close();
            dispatch(
                ZaqWikiFileStore.generator.create(
                    {
                        type: ZaqWikiFile.Type,
                        attributes: { title: "" },
                        relationships: { wiki: { data: { type: ZaqWiki.Type, id } }, },
                    },
                    { include: [ZaqWiki.Type], }
                )
            );
        },
        [actionMenuContext, dispatch, id]
    );

    // Render the component.
    return <button className={css["icon"]} onClick={create}>
        <SourceIcon name="texte" src={title} />
    </button>;
}

/** Component used to render the icon that is used to take a screen capture. */
export function CaptureIcon(): ReactElement {
    // Hook used to render the capture modal.
    const capture = useCaptureModal();
    const actionMenuContext = useContext(ActionMenuContext);

    // Render the component.
    return <CustomView condition={isDesktop} renderWithFragment>
        <button
            className={css["icon"]}
            onClick={() => { actionMenuContext.close();capture({ mode: "screen" }); }}
        >
            <SourceIcon name="capture d'écran" src={screenCapture} />
        </button>
    </CustomView>;
}

/** Component used to render the icon that is used to paste the contents of the clipboard. */
export function ClipboardIcon(): ReactElement {
    const actionMenuContext = useContext(ActionMenuContext);
    const generator = useGenerator();

    // Attach a listener to the paste event.
    useEffect(function attachPasteListener(): () => void {
        document.addEventListener("paste", pasteListener);
        return function detachPasteListener(): void {
            document.removeEventListener("paste", pasteListener);
        }

        // Listen for paste events.
        function pasteListener(event: ClipboardEvent): void {
            if (event.clipboardData?.files) {
                for (let i = 0; i < event.clipboardData.files.length; i++) {
                    generator(event.clipboardData.files[i]);
                }
            }
        }
    }, [generator]);

    // Check if there is a valid file in the clipboard.
    const [ files, setFiles ] = useState<File[]>([]);
    useEffect(function listenToClipboard(): () => void {
        // Attach the listener.
        const listener = actionMenuContext.onOpenClick.subscribe(readClipboard);
        return function detachClipboardListeners(): void { listener.unsubscribe(); }

        // Reads the contents of the clipboard.
        function readClipboard(): void {
            // Ensure that the clipboard method is available.
            if (!isChrome) return;
            if (typeof navigator.clipboard?.read !== "function") return;

            // Read the contents of the clipboard.
            navigator.clipboard.read().then(async items => {
                const files: File[] = [];
                // Decode the type of the items.

                for (let i = 0; i < items.length; i++) {
                    for (const type of items[i].types) {
                        if (
                            type.startsWith("image/") ||
                            type.startsWith("video/") ||
                            type.startsWith("audio/") ||
                            type.startsWith("application/pdf")
                        ) {
                            // Add the item to the list.
                            files.push(new File(
                                [ await items[i].getType(type) ],
                                `Élément collé #${i + 1}`,
                                { type }
                            ));
                        }
                    }
                }

                setFiles(files);
            }).catch(console.warn);
        }
    }, [actionMenuContext.onOpenClick]);

    // Render the component.
    return <CustomView condition={files.length > 0} renderWithFragment>
        <button
            className={css["icon"]}
            onClick={() => { actionMenuContext.close(); files.forEach(generator); }}
        >
            <SourceIcon name="coller" src={paste} />
        </button>
    </CustomView>;
}

/** Basic component used to render a source's icon. */
function SourceIcon(props: SourceIconProps): ReactElement {
    // Render the components of the icon.
    return <img
        src={props.src}
        alt={props.name}
        className={props.small ? css["icon__icon__small"] : css["icon__icon"]}
    />;
}

/** Props passed down to the {@link SourceIcon} component.*/
interface SourceIconProps {
    /** Rendered name of the source. */
    name: string;
    /** Rendered icon of the source. */
    src: string;
    /** Optional flag set to hide the "add" element. */
    hideAdd?: boolean;
    small?: boolean;
}
