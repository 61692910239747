/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useCallback, useRef } from "react";


/**
 * Helper hook used to resize a {@link HTMLElement} to the size of its parent whenever it is updated.
 *
 * @template {HTMLElement} T
 * @param {number} [aspectRatio] If provided, forces the child to have the given aspect ratio.
 * @returns {RefCallback<T | null>} A ref callback used to set the resized element.
 */
export function useFitToParent<T extends HTMLElement>(aspectRatio?: number) {
    // Store a reference to the existing observer instance.
    const observer = useRef<ResizeObserver>();

    // Callback invoked to create a new observer and listen to its events.
    return useCallback(function attachResizeObserver(target: T | null, ratio = aspectRatio): void {
        // Check if there was an observer before.
        if (typeof observer.current !== "undefined") {
            observer.current.disconnect();
            delete observer.current;
        }

        // Get the parent from the target element.
        const parent = target?.parentElement;
        if (target == null || parent == null) {
            return;
        }

        // Watch the parent for resize events.
        observer.current = new ResizeObserver(function onResize(entries: ResizeObserverEntry[]): void {
            // Get the last entry from the list as a basis for the child's size.
            if (entries.length <= 0) {
                return;
            }
            let { width, height } = entries[entries.length - 1].contentRect;

            // Check if an aspect ratio was provided.
            if (typeof ratio === "number") {
                const parentAspectRatio = width / height;
                if (ratio > parentAspectRatio) {
                    height = width / ratio;
                } else {
                    width = height * ratio;
                }
            }

            // Update the size of the child element.
            target.style.width = `${Math.floor(width)}px`;
            target.style.height = `${Math.floor(height)}px`;

            // If the target is a canvas, apply the size to it too.
            if (target instanceof HTMLCanvasElement) {
                target.width = Math.floor(width) * window.devicePixelRatio;
                target.height = Math.floor(height) * window.devicePixelRatio;
            }
        });
        observer.current.observe(parent, { box: "content-box" });
    }, [aspectRatio]);
}
