/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { createContext, ReactElement, ReactNode } from "react";
// Import the common tools.
import { makeContextNoop } from "@andromeda/tools";


/** Object used to manipulate fullscreen info of a PDF document. */
export interface FullscreenContext {
    /** Flag set if fullscreen display mode is allowed. */
    allowed: boolean;
    /** Flag set if the document is already in fullscreen. */
    isFullscreen: boolean;

    /** Method used to request going into fullscreen mode. */
    request(): void;
    /** Method used to request exiting fullscreen mode. */
    exit(): void;
}

/** Context used to manipulate the fullscreen state of a PDF document. */
export const FullscreenContext = createContext<FullscreenContext>({
    allowed: false, isFullscreen: false,
    request: makeContextNoop("FullscreenContext", "request"),
    exit: makeContextNoop("FullscreenContext", "exit"),
});
FullscreenContext.displayName = "FullscreenContext";

/** Component used to provide the {@link FullscreenContext} to the tree. */
export default function FullscreenProvider(props: FullscreenProviderProps): ReactElement {
    // Build the value of the context.
    const value: FullscreenContext = {
        isFullscreen: Boolean(props.isFullscreen),
        allowed: typeof props.onRequestFullscreen !== "undefined",
        request(): void {
            props.onRequestFullscreen?.();
        },
        exit(): void {
            (props.onExitFullscreen ?? document.exitFullscreen)?.();
        }
    };

    return <FullscreenContext.Provider value={value} children={props.children} />;
}

/** Props passed down to the {@link FullscreenProvider} component. */
export interface FullscreenProviderProps {
    /** Children that will have access to the context. */
    children?: ReactNode;
    /** Flag set if the pdf is in fullscreen mode. */
    isFullscreen?: boolean;

    /** Callback invoked when the PDF requests to go into fullscreen mode. */
    onRequestFullscreen?(): void;
    /** Callback invoked when the PDF requests to exit fullscreen mode. */
    onExitFullscreen?(): void;
}
