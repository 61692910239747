/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Re-export the common interfaces.
export * from "./common";
export * from "./interfaces";
// Re-export all the methods.
export * from "./create-one";
export * from "./create-many";
export * from "./read-one";
export * from "./read-many";
export * from "./update-one";
export * from "./update-many";
export * from "./delete-one";
