import * as React from "react";
import { Explorer } from "./explorer";
import { CreateElement } from "./create-element";
import { ZaqWikiStore } from "@andromeda/store";

export const NewMenu = ZaqWikiStore.withReducer(UnwrappedNewMenu);
function UnwrappedNewMenu(): React.ReactElement {
    return <>
        <Explorer/>
        <CreateElement/>
    </>
}
