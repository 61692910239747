/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useCallback, useContext } from "react";
// Import the bootstrap components.
import { Row, Col } from "react-bootstrap";
// Import the link component.
import { useNavigate } from "react-router-dom";
// Import the custom components.
import { useDeleteConfirm, useNotify } from "@andromeda/components";
// Import the wiki store.
import { useResourceDispatch, ZaqWikiStore } from "@andromeda/store";

// Import the reader context.
import { ReaderContext } from "../context";

// Import the icons.
import chevron from "@andromeda/assets/images/chevron.svg";
import bin from "@andromeda/assets/images/bin.svg";
// Import the css.
import css from "./index.module.scss";


/**
 * Function-component used to render the header of the wiki reader.
 * TODO: Add CASL permissions support.
 */
export function Header(): ReactElement {
    // Check if the wiki is in edit mode.
    const notify = useNotify();
    const { wiki } = useContext(ReaderContext);
    const navigate = useNavigate();
    const deleteConfig = useDeleteConfirm("Vous êtes sur le point de supprimer ce ZaqWiki");

    // Memoise the delete button.
    const dispatch = useResourceDispatch();
    const deleteWiki = useCallback(function deleteWiki(): void {
        deleteConfig().then(
                async function deleteIfConsented(consent: boolean): Promise<void> {
                    // Check if the user consented to the deletion.
                    if (!consent) {
                        return;
                    }

                    // Delete the wiki.
                    return dispatch(ZaqWikiStore.generator.delete(wiki.id)).then(() => navigate(-1));
                })
            .catch(notify.fatal);
    }, [deleteConfig, notify.fatal, dispatch, wiki.id, navigate]);

    // Render the component.
    return <Row className="mt-4">
        <Col as={"nav"} xs={12} md={6} className="d-flex flex-row">
            <button onClick={() => navigate(-1)} className={css["header__link"]}>
                <img
                    src={chevron}
                    alt={"back-icon"}
                    className={css["header__link__chevron"]}
                />
                <h1 className={css["header__link__text"]}>
                    ZaqWiki
                </h1>
            </button>
            <button className={css["header__bin"]} onClick={deleteWiki}>
                <img
                    src={bin}
                    alt="delete-zaq"
                    className={css["header__bin__icon"]}
                />
            </button>
        </Col>
    </Row>;
}
