/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactPortal, useMemo, useState } from "react";
// Import ReactDOM.
import { createPortal } from "react-dom";
// Import popper.
import { usePopper } from "react-popper";

// Import the option type.
import type { Option } from "../index";
// Import the subcomponents.
import PopperSearch from "./search";
import PopperList from "./list";

// Import the css.
import css from "./index.module.scss";


/** Popper component used to render the list of items below the select button. */
export default function SelectPopper<T>(props: SelectPopperProps<T>): ReactElement | null {
    // Store a reference to the popper element.
    const [popper, setPopper] = useState<HTMLDivElement | null>(null);

    // Build the popper attributes.
    const { attributes, styles } = usePopper(
        props.container,
        popper,
        {
            placement: "bottom-start",
            modifiers: [{
                name: "offset",
                options: { offset: [0, 8] }
            }, {
                name: "preventOverflow",
                options: { padding: 8 }
            }]
        }
    );

    // Store the filter text found in the search bar.
    const [filter, setFilter] = useState<string>("");

    // Render the popper in a portal to the body.
    return useMemo(function renderInPortal(): ReactPortal | null {
        // Render nothing if the popper is closed.
        if (!props.open) {
            return null;
        }

        // Render through a portal to the body.
        return createPortal(
            <div className={css["popper"]} {...attributes["popper"]} style={styles["popper"]} ref={setPopper}>
                <PopperSearch show={!props.noSearch} value={filter} onChange={setFilter} />
                <PopperList filter={filter} options={props.options} value={props.value} onChange={props.onChange} />
            </div>,
            document.body
        );
    }, [attributes, filter, props, styles]);
}

export interface SelectPopperProps<T> {
    options: ReadonlyArray<Option<T>>;
    value: T;
    container: HTMLElement | null;
    open: boolean;
    noSearch: boolean | undefined;

    onChange(value: T): void;
}
