/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useContext, useMemo, useState } from "react";
// Import the store.
import { useRequirements, useSteps, useUsers } from "@andromeda/store";
// Import the login module.
import { LoginContext } from "@andromeda/login";
// Import the resources.
import type { Step, User } from "@andromeda/resources";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";

// Import the custom hooks.
import { CourseProgress, useMultipleProgress } from "../../../../hooks";
// Import the table component.
import Table from "../../../table/table";
// Import the subcomponents.
import ItemHeader from "../../../table/helpers/item-header";
import StepSelector from "./step-selector";
import Username from "../../../table/helpers/username";
import StepCell from "../../../table/helpers/step-cell";
import ItemCell from "../../../table/helpers/item-cell";
import DueDateCell from "../../../table/helpers/due-date-cell";

// Import the css.
import css from "./index.module.scss";


/** Component used to render the progress of the entire team. */
export default function TeamProgression(props: TeamProgressionProps): ReactElement {
    // Download the data from the API.
    const users = useUsers(useContext(LoginContext).organisations.current.id);
    const steps = useSteps(props.course);
    const requirements = useRequirements(props.course, users.data?.map(({ id }) => id));
    const progress = useMultipleProgress(props.course, users.data?.map(({ id }) => id));

    // Store the step that is currently being rendered.
    const [stepIndex, setStepIndex] = useState<number>();
    const currentStep = useMemo(function selectCurrentStep(): Step | null {
        if (typeof stepIndex === "undefined" || !steps.isSuccess) {
            return null;
        }

        return steps.data[stepIndex];
    }, [steps.data, steps.isSuccess, stepIndex]);

    // Render the header row.
    const headerRow = useMemo(function renderHeaderRow(): ReactNode[] {
        // Ignore if the steps are not loaded.
        if (!steps.isSuccess) {
            return [];
        }

        // Check if a step was provided.
        if (currentStep) {
            return currentStep.relationships.items.data.map(
                item => <ItemHeader key={`${item.type}.${item.id}`} item={item} />
            ).concat(<div key="last-validation" className={css["head"]}>Dernière validation</div>);
        }

        return steps.data.map(
            step => <div key={step.id} className={css["head"]} children={step.attributes.name} />
        ).concat(
            <div key="due-date" className={css["head"]}>Échéance</div>,
            <div key="last-validation" className={css["head"]}>Dernière validation</div>
        );
    }, [currentStep, steps.data, steps.isSuccess]);

    // Render all the user progressions.
    const userRows = useMemo(
        function renderUserRows(): null | ReactNode[][] {
            // Wait for the data to get loaded.
            if (!users.isSuccess || !steps.isSuccess || !progress.isSuccess || !requirements.isSuccess) {
                return null;
            }

            // Filter out the users with no requirements.
            const requiredUsers = users.data.filter(function shouldIncludeUser(user: User): boolean {
                return requirements.data.some(requirement => requirement.relationships.user.data.id === user.id);
            });

            // Render all the users.
            return requiredUsers.map(function renderUserRow(user: User): ReactNode[] {
                const userProgress = progress.data.find(progress => progress.user === user.id);
                const cells = [
                    <Username id={user.id} key="username" />,
                    ...renderUserProgress(
                        currentStep ?? steps.data,
                        userProgress
                    )
                ];

                // Add the due-date cell in global mode.
                if (typeof stepIndex === "undefined") {
                    cells.push(<DueDateCell key="due-date" course={props.course} user={user.id} />);
                }

                // Add the date of the last validation.
                let lastValidation: Date | undefined;
                if (currentStep === null) {
                    lastValidation = userProgress?.lastValidation;
                } else {
                    lastValidation = userProgress?.step(currentStep.id)?.lastValidation;
                }
                cells.push(
                    <div
                        key="last-validation"
                        children={lastValidation ? lastValidation.toLocaleDateString() : "N/A"}
                    />
                );

                return cells;
            });
        },
        [
            currentStep,
            progress.data,
            progress.isSuccess,
            props.course,
            requirements.data,
            requirements.isSuccess,
            stepIndex,
            steps.data,
            steps.isSuccess,
            users.data,
            users.isSuccess
        ]
    );

    // Concatenate all the cells.
    const cells = useMemo(function concatenateRows(): ReactNode[][] | null {
        if (!userRows) {
            return null;
        }
        return [
            [<StepSelector course={props.course} current={stepIndex} onChange={setStepIndex} />, ...headerRow],
            ...userRows
        ];
    }, [headerRow, props.course, stepIndex, userRows]);

    // Render the component.
    if (cells === null) {
        return <Loader text="Chargement de la progression de votre équipe ..." transparent />;
    }
    if (userRows && userRows.length <= 0) {
        return <span className={css["not-applicable-message"]}>
            Aucun membre de l'équipe n'est attribué à ce ZaqTraining
        </span>;
    }
    return <Table source={cells} />;
}

/** Props passed down to the {@link TeamProgression} component. */
export interface TeamProgressionProps {
    /** The identifier of the rendered course. */
    course: string;
}

/** Helper used to map a list of steps for a user's progress. */
function renderUserProgress(steps: Step | Step[], progress?: CourseProgress): ReactNode[] {
    if (Array.isArray(steps)) {
        return steps.map(step => <StepCell key={step.id} progress={progress?.step(step.id)} />);
    }
    return steps.relationships.items.data.map(
        item => <ItemCell key={item.id} item={progress?.step(steps.id)?.item(item)} step={progress?.step(steps.id)} />
    );
}
