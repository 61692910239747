/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";
import ReactDOM from "react-dom";
// Import the debug module.
import debug from "debug";
// Import the metadata interfaces.
import { AnyAssetMetadata } from "@andromeda/asset-conversion";

// Import the context.
import { MediaParserContext } from "./context";
// Import the job queue.
import { useJobQueue } from "./job";

// Import the subcomponents.
import { VideoParser } from "./video";
import { ImageParser } from "./image";
import { AudioParser } from "./audio";
// Import the file type helper.
import { isAudioFile, isVideoFile, isImageFile } from "./checker";


// Re-export the file type helpers.
export { isAudioFile, isVideoFile, isImageFile };

/**
 * Component used to provide the media parser context to all its children.
 *
 * @see MediaParserContext
 */
export function MediaParserProvider(props: MediaParserProps): React.ReactElement {
    // Load the job queue.
    const { current, push } = useJobQueue();
    const hasOneJobRunAtLeast = React.useRef(false);
    React.useEffect(function logNewJob(): void {
        if (!current) {
            if (!hasOneJobRunAtLeast.current) return;
            log("Reached the end of the job queue !");
        } else {
            log("Grabbing a new job");
            hasOneJobRunAtLeast.current = true;
        }
    }, [current]);

    // Render the parser.
    const parser = React.useMemo(function renderMediaParserPortal(): React.ReactElement | null {
        if (!current) return null;

        if (isVideoFile(current.file)) {
            return ReactDOM.createPortal(<VideoParser job={current} />, document.body, "video-parser");
        } else if (isImageFile(current.file)) {
            return ReactDOM.createPortal(<ImageParser job={current} />, document.body, "image-parser");
        } else if (isAudioFile(current.file)) {
            return ReactDOM.createPortal(<AudioParser job={current} />, document.body, "audio-parser");
        }
        current.reject(new Error("Unsupported file type."));
        return null;
    }, [ current ]);

    // Generate all the callbacks.
    const parse = React.useCallback((file: File): Promise<AnyAssetMetadata> => {
        log("Pushing a new image parsing to the queue.");
        return new Promise<AnyAssetMetadata>((resolve, reject) =>
            push({ file, resolve, reject })
        );
    }, [push]);

    // Render the context.
    return <MediaParserContext.Provider value={{ parse: parse as MediaParserContext["parse"] }}>
        {props.children}{parser}
    </MediaParserContext.Provider>;
}

/** Props passed down to the {@link MediaParserProvider} component. */
interface MediaParserProps {
    /** Children that will have access to the {@link MediaParserContext}. */
    children?: React.ReactNode;
}

// Re-export the context.
export { MediaParserContext };

const log = debug("asset-manager:parser");
