/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import React from "react";

// Import the category component.
import { Category } from "./category";

// Import the css.
import css from "./index.module.scss";


// Re-export the category interface.
export type { Category };

/** Simple component used to render a list of items. */
export function ItemList<T extends Item>(props: ItemListProps<T>): React.ReactElement {
    // Render the list of children.
    const children = React.useMemo(function renderItemListChildren(): React.ReactElement[] {
        if ("items" in props) {
            // Render the items directly.
            return props.items.map(function renderItem(item: T, index: number): React.ReactElement {
                let className = css["list__item"];
                if (item.className) className += " " + item.className;

                return <li
                    className={className}
                    key={index}
                    children={item.element}
                    onClick={() => props.onSelected?.(item)}
                />;
            });
        } else {
            // Render the categories.
            return props.categories.map(
                function renderCategory(category: Category<T>, index: number): React.ReactElement {
                    return <Category key={index} category={category} onSelected={props.onSelected} />;
                }
            );
        }
    }, [ props ]);

    // Compute the class name.
    const className = React.useMemo(function renderClassName(): string {
        let className = css["list"];
        if (props.className) className += " " + props.className;
        if (props.onSelected) className += " list--selectable";
        return className;
    }, [props.className, props.onSelected]);

    // Render the component.
    return <ul className={className} children={children} />;
}

/** Base interface that MUST be shared by all items of the {@link ItemList} component. */
export interface Item {
    /** The element to render in the list. */
    element: React.ReactNode;
    /** An optional class name to apply to the list item. */
    className?: string;
}

/** Base props used for the {@link ItemList} component. */
interface BaseItemListProps<T extends Item> {
    /** Optional callback that makes the items selectable. */
    onSelected?(item: T): void;
    /** The class name to apply to the component. */
    className?: string;
}

/** Interface used to describe a list of items. */
interface SimpleItemList<T extends Item> extends BaseItemListProps<T> {
    /** The list of items to render. */
    items: T[];
}

/** Interface used to describe a list of items with categories. */
interface CategoryList<T extends Item> extends BaseItemListProps<T> {
    /** The list of categories to render. */
    categories: Category<T>[];
}

/** Props passed down to the {@link ItemList} component. */
type ItemListProps<T extends Item> = SimpleItemList<T> | CategoryList<T>;
