/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, ReactNode, useState } from "react";

// Import the modal context.
import { ExternalModalContext } from "./context";

// Import the subcomponents.
import ExternalModal from "./modal";


// Re-export the context.
export { ExternalModalContext } from "./context";

/** Provider component used to render the {@link ExternalModal}. */
export function ExternalModalProvider(props: ExternalModalProviderProps): ReactElement {
    // Store the currently opened external.
    const [external, setExternal] = useState<string>();

    // Render the component.
    return <ExternalModalContext.Provider value={{ show: setExternal }}>
        {props.children}
        <ExternalModal external={external} hide={() => setExternal(undefined)} />
    </ExternalModalContext.Provider>
}

/** Props passed down to the {@link ExternalModalProvider} component. */
export interface ExternalModalProviderProps {
    /** Elements that will be provided the {@link ExternalModalContext}. */
    children?: ReactNode;
}
