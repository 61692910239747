/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Re-export the schemas.
import * as ResourceSchema from "./resource.json";
export const PasswordResetSchema = {
    ResourceSchema,
};
