/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { useMemo } from "react";
// Import the asset store.
import { AssetStore } from "@andromeda/store";

// Import the asset loader.
import { useAsset } from "./use-asset";


/** Helper hook used to load a {@link WebAsset}. */
export function useAssetExistsOnRemote(id?: string): boolean | undefined {
    // Load the asset.
    const asset = useAsset(id);

    // Load the asset from the store.
    const storedAsset = AssetStore.useSelector(state => state.resources.find(asset => asset.id === id));

    // Check if the asset exists in the redux store.
    return useMemo(() => {
        if (typeof asset === "undefined") return undefined;
        if (typeof asset === null) return false;
        return typeof storedAsset !== "undefined";
    }, [asset, storedAsset]);
}
