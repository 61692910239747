/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useMemo, useState } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the icons.
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
// Import the css.
import css from "./index.module.scss";
import SelectPopper from "./popper";


/** Component used to render a dropdown selector. */
export default function Select<T>(props: SelectProps<T>): ReactElement {
    // Store a reference to the button for PopperJS.
    const [button, setButton] = useState<HTMLButtonElement | null>(null);

    // State of the menu.
    const [open, setOpen] = useState(false);

    // Callback used to close the menu when something was selected.
    const selectAndClose = useCallback(function closeOnSelect(value: T): void {
        // Propagate the change.
        props.onChange(value);

        // Close the menu.
        setOpen(false);
    }, [props]);

    // Find the selected option.
    const current = useMemo(function findSelectedOption(): Option<T> | undefined {
        return props.options.find(function isCurrentOption(option: Option<T>): boolean {
            return option.value === props.value;
        });
    }, [props.options, props.value]);

    // Render the component.
    const className = classNames(css["button"], { [css["button--open"]]: open });
    return <>
        <button className={className} ref={setButton} onClick={() => setOpen(state => !state)}>
            <span className={css["button__text"]} children={current?.name} />
            <FontAwesomeIcon className={css["button__icon"]} icon={faChevronDown} />
        </button>
        <SelectPopper
            options={props.options}
            value={props.value}
            onChange={selectAndClose}
            container={button}
            open={open}
            noSearch={props.noSearch}
        />
    </>;
}

/** Object used to define an option available for the {@link Select} component. */
export interface Option<T> {
    /** The displayed name of the option. */
    readonly name: string;
    /** The value of the option. */
    readonly value: T;
}

/**
 * Props passed down to the {@link Select} component.
 *
 * @template T
 */
export interface SelectProps<T> {
    /** Array of all the options available to the user. */
    options: ReadonlyArray<Option<T>>;
    /** Currently selected value. */
    value: T;

    /** Optional flag used to disable the search feature in the list. */
    noSearch?: boolean;

    /**
     * Callback invoked when an option was selected by the user.
     *
     * @param {T} value The value selected by the user.
     */
    onChange(value: T): void;
}
