/*
 * Copyright © 2021 - Zimproov.
 * All rights reserved.
 */

// Import the logger.
import debug from "debug";
const log = debug("config:services:parser:hierarchy");


/** Enumeration of all the available environment types. */
export enum Target { Node, Browser, ServiceWorker }

/** Namespace holding all the target methods. */
export namespace Target {
    /** Stores the currently active target. */
    export const current: Target = getTarget();
}

/**
 * @param {Target} [target=current] The target to convert to a string.
 * @returns {string} The name of the specified target.
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export function stringifyTarget(target: Target = Target.current): string {
    switch (target) {
    case Target.Browser         : return "browser";
    case Target.Node            : return "node";
    case Target.ServiceWorker   : return "service-worker";
    }
}

/**
 * Computes the currently active target.
 *
 * @returns {Target} The current target of the application.
 * @author Caillaud Jean-Baptiste
 * @since 0.1.0
 * @version 1
 */
function getTarget(): Target {
    // Check if this is the node environment.
    const isBrowser = new Function("try { return this === window; } catch(_) { return false; }");
    const isServiceWorker = new Function("try { return typeof self.registration !== \"undefined\" } catch (_) { return false; }");

    let target: Target;
    if (isBrowser()) {
        target = Target.Browser;
    } else if (isServiceWorker()) {
        target = Target.ServiceWorker;
    } else {
        target = Target.Node;
    }
    log(`Current target: ${stringifyTarget(target)}`);
    return target;
}
