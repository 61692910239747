/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

/** Base class for all the CRUD errors. */
class CRUDError extends Error {
    /** Inner exception that was caught. */
    public readonly inner?: unknown;

    /**
     * Creates a new {@link CRUDError} from an inner exception.
     *
     * @param {string} message The primary message of the error.
     * @param inner The error that was caught.
     */
    public constructor(message: string, inner: unknown);

    /**
     * Creates a new {@link CRUDError} from a detailed message.
     *
     * @param {string} message The primary message of the error.
     * @param {string} details The details of the error.
     */
    public constructor(message: string, details: string);

    /** Implementation ! */
    public constructor(message: string, details: unknown) {
        if (typeof details === "string") {
            super(`${message}\nDetails: ${details}`);
        } else {
            super(`${message}`);
            this.inner = details;
        }
    }
}

/** Error thrown if a creation request failed. */
export class CreateFailedError extends CRUDError {
    /** @inheritDoc */
    public constructor(details: string | unknown) { super("Failed to create a resource !", details); }
}

/** Error thrown if a read request failed. */
export class ReadFailedError extends CRUDError {
    /** @inheritDoc */
    public constructor(details: string | unknown) { super("Failed to read a resource !", details); }
}

/** Error thrown if an update request failed. */
export class UpdateFailedError extends CRUDError {
    /** @inheritDoc */
    public constructor(details: string | unknown) { super("Failed to update a resource !", details); }
}

/** Error thrown if a delete request failed. */
export class DeleteFailedError extends CRUDError {
    /** @inheritDoc */
    public constructor(details: string | unknown) { super("Failed to delete a resource !", details); }
}
