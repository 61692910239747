/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the resources.
import { DueDate, Requirement, Step, Validation } from "@andromeda/resources";

// Import the custom interfaces.
import { CourseProgress, StepProgress } from "./interfaces";
// Import the step parser.
import parseStepProgress from "./parse-step-progress";


/**
 * Parses the provided course's progress.
 *
 * @param {string} user The identifier of the parsed user.
 * @param {Step[]} steps The steps to parse.
 * @param {Requirement[]} requirements The requirements of the course.
 * @param {DueDate | null} [due=null] The date at which the course is due.
 * @param {Validation[]} [validations=[]] All the validations of the user for the current course.
 * @return {CourseProgress} The progress of the course.
 */
export default function parseCourseProgress(
    user: string,
    steps: Step[],
    requirements: Requirement[],
    due: DueDate | null = null,
    validations: Validation[] = []
): CourseProgress {
    // Prepare the progress object.
    const map = new Map<string, StepProgress>();
    let complete = true, objectiveMet: boolean | undefined = undefined, validated: boolean | undefined = undefined;
    let lastValidation: Date | undefined = undefined;

    // Loop through the steps.
    let locked = false;
    for (const step of steps) {
        // If the step has no requirement, skip it.
        const requirement = requirements.find(requirement => requirement.relationships.step.data.id === step.id);
        if (!requirement) {
            continue;
        }

        // Parse the step.
        const progress = parseStepProgress(step, requirement, locked, validations);
        complete &&= progress.complete;
        if (typeof progress.objectiveMet === "boolean") {
            objectiveMet = (objectiveMet ?? true) && progress.objectiveMet;
        }
        if (typeof progress.validated === "boolean") {
            validated = (validated ?? true) && progress.objectiveMet;
        }

        // Check if the step was validated.
        if ("lastValidation" in progress && progress.lastValidation) {
            if (lastValidation && progress.lastValidation > lastValidation) {
                lastValidation = progress.lastValidation;
            } else {
                lastValidation = progress.lastValidation;
            }
        }

        map.set(step.id, progress);

        // Lock the next steps if necessary.
        if (!locked && !progress.complete) {
            locked = true;
        }
    }

    // Return the progress.
    return {
        user, complete, objectiveMet, validated, lastValidation,
        [Symbol.iterator](): Iterator<StepProgress> {
            return map.values();
        },
        step(id: string): StepProgress | undefined {
            return map.get(id);
        }
    }
}
