/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import * as React from "react";
// Import lodash.
import { isEqual } from "lodash";
// Import all the resource definitions.
import { Tag, ZaqWiki, ZaqWikiFile, Session, User } from "@andromeda/resources";
// Import the stores.
import { TagStore, ZaqWikiStore, ZaqWikiFileStore, SessionStore, useResourceDispatch } from "@andromeda/store";
// Import the login context.
import { LoginContext } from "@andromeda/login";
// Import the context noop tool.
import { makeContextNoop } from "@andromeda/tools";
import { useIsConverting } from "@andromeda/asset-manager";


/** Interface used to define the state of the current reader instance. */
export interface ReaderContext {
    /** Zaq currently being read. */
    wiki: ZaqWiki;

    /** List of categories applied to the zaq. */
    categories: Tag[];
    /** List of files for this zaq. */
    files: ZaqWikiFile[];
    /** The current wiki session. */
    session: Session.ZaqWikiSession;

    /** Identifier of the current organisation, if applicable. */
    organisation?: string;

    /** Flag set if there is one or more conversion(s) running. */
    isConverting: boolean;

    /** Declare a new conversion promise. */
    pushConversionPromise(promise: Promise<unknown>): Promise<void>;

    /** Updates the state of a given file. */
    updateFile(file: string, opened: boolean, seen: boolean): void;
}

// Default state of the context.
const DEFAULT_STATE: ReaderContext = {
    wiki: {
        id: "__invalid",
        type: ZaqWiki.Type,
        attributes: { name: "__invalid", body: "__invalid", tags: [] },
        relationships: {
            organisations: { data: [] },
            categories: { data: [] },
            files: { data: [] },
            icon: { data: null }
        },
        links: {}
    },
    session: {
        type: Session.Type, id: "__invalid",
        attributes: { start: new Date(0).toISOString(), end: new Date(0).toISOString(), files: [] },
        relationships: {
            user: { data: { type: User.Type, id: "__invalid " } },
            wiki: { data: { type: ZaqWiki.Type, id: "__invalid" } }
        }
    },
    categories: [],
    files: [],
    isConverting: false,
    pushConversionPromise: makeContextNoop("ReaderContext", "pushConversionPromise"),
    updateFile: makeContextNoop("ReaderContext", "updateFile")
};

/** Context used to provide easy access to the wiki for the entire reader object. */
export const ReaderContext = React.createContext<ReaderContext>(DEFAULT_STATE);

/** Helper hook used to load the full context from the stores. */
export function useContextLoader(organisation: string | undefined, id?: string): ReaderContext | null {
    // Load the  data from the store.
    const wiki = ZaqWikiStore.useSelector(function loadWiki(
        store
    ): ZaqWiki | undefined {
        if (typeof id === "undefined") {
            return undefined;
        }
        return store.resources.find(instance => instance.id === id);
    });
    const categories = TagStore.useSelector(function loadTags(store): Tag[] {
        if (typeof wiki === "undefined") {
            return [];
        }
        return wiki.relationships.categories.data?.map(ref => {
            return store.resources.find(category => category.id === ref.id);
        }).filter((tag: Tag | undefined): tag is Tag => typeof tag !== "undefined") ?? [];
    });
    const files = ZaqWikiFileStore.useSelector(
        function loadFiles(store): ZaqWikiFile[] {
            if (typeof wiki === "undefined") {
                return [];
            }
            return wiki.relationships.files.data
                .map(file => store.resources.find(instance => instance.id === file.id))
                .filter((file: ZaqWikiFile | undefined): file is ZaqWikiFile => typeof file !== "undefined");
        },
        function compareFiles(a: ZaqWikiFile[], b: ZaqWikiFile[]): boolean {
            return (
                a.length === b.length &&
                a.every((value, index) => {
                    return isEqual(value, b[index]);
                })
            );
        }
    );
    const wikiIsLoading = ZaqWikiStore.useSelector(state => state.reading);
    const filesAreLoading = ZaqWikiFileStore.useSelector(state => state.reading);

    // Store the list of all the conversion promises.
    const isConverting = useIsConverting();


    const { isLoggedIn } = React.useContext(LoginContext);
    const dispatch = useResourceDispatch();
    // Store the state of the current session.
    const [session, setSession] = React.useState<Session.ZaqWikiSession>();
    const sessionWasCreated = React.useRef(false);
    React.useEffect(function startWikiSession(): void {
        if (!id || !wiki || sessionWasCreated.current || !isLoggedIn) {
            return;
        }
        if (wikiIsLoading || filesAreLoading) {
            return;
        }
        sessionWasCreated.current = true;

        // Load the latest session from the current user.
        dispatch(SessionStore.generator.readMany({
            filter: { [Session.Type]: { wiki: { $eq: id } } },
            sort: ["-end"], limit: 1
        }))
            .then(data => data.find((item: Session): item is Session.ZaqWikiSession => Session.isZaqWikiSession(item)))
            .then(latest => {
                // Generate the file list from the latest session.
                const details: Session.WikiFileDetail[] = files.map((file, index) => {
                    let progress = latest?.attributes.files.find(existing => existing.id === file.id);
                    if (!progress) {
                        progress = { id: file.id, opened: false, seen: false };
                    }

                    // Check if the file should be seen.
                    const isLink = ZaqWikiFile.isTutoFile(file) || ZaqWikiFile.isLinkFile(file);
                    const isSeen = isLink || index === 0;
                    if (isLink || isSeen) {
                        progress.opened = true;
                        progress.seen = true;
                    }

                    return progress;
                });

                // Create a new session.
                const create: Session.Create = {
                    type: Session.Type, attributes: { files: details },
                    relationships: { wiki: { data: { type: ZaqWiki.Type, id } } }
                };
                return dispatch(SessionStore.generator.create(create)) as Promise<Session.ZaqWikiSession>;
            })
            .then(setSession);
    }, [dispatch, files, filesAreLoading, id, isLoggedIn, wiki, wikiIsLoading]);

    // Update the session files when the list changes.
    React.useEffect(function updateSessionFileList(): void {
        if (!session) {
            return;
        }
        files.forEach(function updateSessionFile(file: ZaqWikiFile): void {
            if (!session.attributes.files.find(progress => progress.id === file.id)) {
                session.attributes.files.push({ id: file.id, opened: false, seen: false });
            }
        });

        dispatch(SessionStore.generator.update({
            type: Session.Type, id: session.id, attributes: { files: session.attributes.files }
        }));
    }, [files, dispatch, session]);
    const updateFile = React.useCallback(
        function updateFileInfo(file: string, opened: boolean, seen: boolean): void {
            if (!session) {
                return;
            }
            const progress = session.attributes.files.find(progress => progress.id === file);
            if (progress) {
                progress.opened = opened;
                progress.seen = seen;
            }

            dispatch(SessionStore.generator.update({
                type: Session.Type, id: session.id, attributes: { files: session.attributes.files }
            }));
        },
        [dispatch, session]
    );

    // If the wiki is undefined, return a null.
    if (typeof wiki === "undefined") {
        return null;
    }
    return {
        wiki,
        categories,
        files,
        organisation,
        isConverting,
        pushConversionPromise: () => Promise.resolve(),
        updateFile,
        session: session ?? DEFAULT_STATE.session
    };
}
