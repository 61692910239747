/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { MouseEvent, ReactElement, useCallback } from "react";
// Import the font-awesome icon.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the delete button.
import DeleteButton from "./buttons/delete";
// Import the administration button.
import AdministrationButton from "./buttons/administration";

// Import the icons.
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./index.module.scss";


/** Component used to render the link that returns to the list of courses. */
export default function Back(props: BackProps): ReactElement {
    // Callback used to navigate to the previous screen, if there is one.
    const navigateBack = useCallback(function navigateBackIfPossible(event: MouseEvent): void {
        // Propagate the even upwards.
        props.onClick?.(event);

        // Check if there is a history to go back to.
        if (!event.defaultPrevented && window.history.length > 1) {
            window.history.back();
        } else {
            window.location.assign("/");
        }
    }, [props]);

    // Render the component.
    return <header role="navigation" className={css["nav"]}>
        <button role="link" className={css["link"]} onClick={navigateBack}>
            <FontAwesomeIcon icon={faChevronLeft} className={css["link__chevron"]} />
            <h1 className={css["link__text"]}>ZaqTraining</h1>
        </button>
        <DeleteButton id={props.course} />
        <AdministrationButton course={props.course} />
    </header>;
}

/** Props passed down to the {@link Back} component. */
export interface BackProps {
    /** Identifier of the course being rendered. */
    course: string;

    /**
     * Callback that will be attached to the {@code onClick} event of the link.
     *
     * @param {React.MouseEvent} event The event that was triggered.
     */
    onClick?(event: MouseEvent): void;
}
