/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useMemo, useState } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";

// Import the item list component.
import ItemList, { Item } from "./item-list";

// Import the icons.
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./item-categorised-list.module.scss";



// Re-export the item interface.
export type { Item };

/** Component used to render a categorised {@link ItemList}. */
export default function ItemCategorisedList(props: ItemCategorisedListProps): ReactElement {
    // Render all the categories of the list.
    const categories = useMemo(function renderCategories(): ReactElement[] {
        return props.categories
            .sort(function compareCategories(
                [a = "Sans catégorie"]: ItemCategory,
                [b = "Sans catégorie"]: ItemCategory
            ): number {
                return a.localeCompare(b);
            })
            .map(function renderCategory(category: ItemCategory, index: number): ReactElement {
                return <ItemCategoryRenderer
                    key={index}
                    category={category}
                    select={props.select}
                    selected={props.selected}
                />;
            });
    }, [props.categories, props.select, props.selected]);

    // Compute the class name for the list.
    let className = css["list"];
    if (props.className) {
        className += ` ${props.className}`;
    }

    return <ul className={className} children={categories} />;
}

/** Type used to describe a single item category. */
export type ItemCategory = [name: string | undefined, items: Item[]];

/** Props passed down to the {@link ItemCategorisedList} component. */
export interface ItemCategorisedListProps {
    /** Currently selected item. */
    selected: ResourceIdentifier | undefined;

    /** Callback invoked to select a new component. */
    select(item: ResourceIdentifier): void;

    /** The list of categories to render. */
    categories: ItemCategory[];
    /** A class name added to the root <ul> component. */
    className?: string;
}

/** Helper component used to render a category of the {@link ItemCategorisedList}. */
function ItemCategoryRenderer(props: ItemCategoryRendererProps): ReactElement {
    // Store the "open" state of the category.
    const [open, setOpen] = useState(false);
    const isForcedOpen = useMemo(function isForcedOpen(): boolean {
        return props.category[1].some(item => item.type === props.selected?.type && item.id === props.selected?.id);
    }, [props.category, props.selected?.id, props.selected?.type]);
    const isOpen = open || isForcedOpen;

    let className = css["category"];
    if (isOpen) {
        className = ` ${css["category--open"]}`;
    }

    // Render the category.
    return <li className={className}>
        <span role="button" className={css["category__name"]} onClick={() => setOpen(!open || isForcedOpen)}>
            <FontAwesomeIcon className={css["category__chevron"]} icon={faChevronRight} />
            {props.category[0] ?? "Sans catégorie"}
        </span>
        {isOpen ? <ItemList items={props.category[1]} selected={props.selected} select={props.select} /> : null}
    </li>;
}

/** Props passed down to the {@link ItemCategorisedList} component. */
interface ItemCategoryRendererProps {
    /** Currently selected item. */
    selected: ResourceIdentifier | undefined;

    /** Callback invoked to select a new component. */
    select(item: ResourceIdentifier): void;

    /** The category to render. */
    category: ItemCategory;
}
