/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import {
    ChangeEvent,
    forwardRef,
    HTMLProps,
    ReactElement,
    Ref,
    useCallback,
    useImperativeHandle,
    useRef,
    useState
} from "react";
// Import the CSS classname helper.
import classNames from "classnames";

// Import the css.
import css from "./index.module.scss";
import { useEffect } from "react";


/** Simple text-area element that auto-resizes itself. */
export const AutoresizeTextarea = forwardRef(AutoresizeTextareaImpl);

/** @see AutoresizeTextarea */
function AutoresizeTextareaImpl(
    props: HTMLProps<HTMLTextAreaElement>,
    ref: Ref<HTMLTextAreaElement | null>
): ReactElement {
    // Get a ref to the text area object.
    const [textArea, setTextArea] = useState<HTMLTextAreaElement | null>(null);

    // Propagate the reference to the parent.
    useImperativeHandle<HTMLTextAreaElement | null, HTMLTextAreaElement | null>(
        ref,
        function propagateRefToParent(): HTMLTextAreaElement | null {
            // If the text area is set, resize it to its parent.
            if (textArea !== null) {
                textArea.style.height = "auto";
                textArea.style.height = `${textArea.scrollHeight.toFixed(0)}px`;
            }

            return textArea;
        },
        [textArea]
    );

    // Callback wrapper used to update the size of the text area on a value change.
    const { onChange: onChangeCallback } = props;
    const onChange = useCallback(function onTextareaChange(event: ChangeEvent<HTMLTextAreaElement>): void {
        // Resize the element.
        event.currentTarget.style.height = "auto";
        event.currentTarget.style.height = event.currentTarget.scrollHeight + "px";

        // Propagate the event.
        onChangeCallback?.(event);
    }, [onChangeCallback]);

    // Effect used to resize the element when its value changes.
    useEffect(function resizeOnValueChange(): void | VoidFunction {
        // Wait for the text area to be valid.
        if (textArea === null) {
            return;
        }

        // Update the size after the next animation frame.
        const frame = window.requestAnimationFrame(function reloadSizeOnNextAnimationFrame(): void {
            textArea.style.height = "auto";
            textArea.style.height = `${textArea.scrollHeight.toFixed(0)}px`;
        });

        // Cancel the animation frame if cleared before it is invoked.
        return function clearAnimationFrame(): void {
            window.cancelAnimationFrame(frame);
        };
    }, [props.value, props.defaultValue, textArea]);

    return <textarea
        {...props}
        rows={1}
        className={classNames(css["autoresize-textarea"], props.className)}
        onChange={onChange}
        ref={setTextArea}
    />;
}
