/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement } from "react";
// Import the markdown renderer.
import { Markdown } from "@andromeda/components";

// Import the css.
import css from "./text.module.scss";


/** Component used to render a text module. */
export default function TextModule(props: TextModuleProps): ReactElement {
    // Render the component.
    return <div className={css["text"]}>
        <h3 className={css["text__title"]} children={props.title} />
        <Markdown className={css["text__text"]} text={props.text} />
    </div>;
}

/** Props passed down to the {@link TextModule}. */
export interface TextModuleProps {
    /** The title of the current module. */
    title: string;
    /** The text of the current module. */
    text: string;
}
