/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the resource and message interfaces.
import { Resource, Message, ResourceWithoutId, DataMessageWithoutId } from "../../interfaces";
// Import the base class.
import { MessageHelper } from "./message";


/**
 * Message class used to create a message that can be sent to the server.
 * Extends the base {@link Message} class.
 *
 * @template {ResourceWithoutId[] | ResourceWithoutId | null} R=Resource[] | Resource | null
 * The type of the primary data in the message.
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class WritableMessageHelper<
    R extends ResourceWithoutId[] | ResourceWithoutId | null = ResourceWithoutId[] | ResourceWithoutId | null
> extends MessageHelper<R> {
    /** @inheritDoc */
    public override data: R;
    /** @inheritDoc */
    public override included: Resource[];
    /** Generates the JSON:API message object. */
    public get message(): Message | DataMessageWithoutId {
        const data = this.data;
        const included = this.included.length > 0 ? this.included : undefined;

        // Generate the message.
        return { data, included };
    }

    /** @inheritDoc */
    public constructor(primary: R, included: Resource[] = []) {
        super(primary, included); this.data = primary; this.included = included;
    }
}

/**
 * Helper class used to represent a writable message with a single primary data item.
 *
 * @template {Resource} R=Resource The resource being stored in this message.
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class WritableSingleMessageHelper<R extends ResourceWithoutId> extends WritableMessageHelper<R | null> {}

/**
 * Helper class used to represent a writable message with multiple primary data items.
 *
 * @template {Resource} R=Resource The resource being stored in this message.
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class WritableMultipleMessageHelper<R extends ResourceWithoutId> extends WritableMessageHelper<R[]> {}
