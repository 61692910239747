/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the resources.
import { Course, User } from "@andromeda/resources";
// Import the store.
import { CourseStore, useResourceDispatch, useCourse } from "@andromeda/store";
// Import the custom components.
import { useNotify } from "@andromeda/components";


/** Hook used to make a new permission update builder object. */
export default function usePermissionUpdateBuilder(courseId: string, userId: string) {
    const dispatch = useResourceDispatch();
    const { fatal } = useNotify();

    // Get the course from the store.
    const course = useCourse(courseId);

    // Return the builder.
    return function(newPermission: "editor" | "validator") {
        return function(state: boolean) {
            // Make sure that the course is ready.
            if (!course.isSuccess) {
                return;
            }

            // Prepare the update.
            const validators = course.data.relationships.validators.data.filter(validator => validator.id !== userId);
            const editors = course.data.relationships.editors.data.filter(editor => editor.id !== userId);
            const update = {
                type: Course.Type,
                id: courseId,
                relationships: {
                    validators: { data: validators },
                    editors: { data: editors }
                }
            };

            // Add the user to the respective list if appropriate.
            if (state) {
                switch (newPermission) {
                case "editor":
                    update.relationships.editors.data.push({ type: User.Type, id: userId });
                    break;
                case "validator":
                    update.relationships.validators.data.push({ type: User.Type, id: userId });
                    break;
                }
            }

            // Dispatch the update.
            dispatch(CourseStore.generator.update(update)).catch(fatal);
        };
    };
}
