/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the store.
import { useCourse } from "@andromeda/store";
// Import the login module.
import { useAdminCheck } from "@andromeda/login";


/**
 * Helper hook used to check if a given user is an editor of the specified course.
 *
 * @param {string} courseId The course to check.
 * @return {(userId: string) => boolean} A callback that can be used to check a given user.
 */
export function useIsEditor(courseId: string): (userId: string) => boolean {
    const course = useCourse(courseId);
    const isAdmin = useAdminCheck();

    return function isEditor(userId: string): boolean {
        if (!course.isSuccess) {
            return false;
        }
        if (isAdmin(userId)) {
            return true;
        }
        return course.data.relationships.editors.data.some(editor => editor.id === userId);
    }
}

/**
 * Helper hook used to check if a given user is a validator of the specified course.
 *
 * @param {string} courseId The course to check.
 * @return {(userId: string) => boolean} A callback that can be used to check a given user.
 */
export function useIsValidator(courseId: string): (userId: string) => boolean {
    const course = useCourse(courseId);
    const isAdmin = useAdminCheck();
    return function isValidator(userId: string): boolean {
        if (!course.isSuccess) {
            return false;
        }
        if (isAdmin(userId)) {
            return true;
        }
        return course.data.relationships.validators.data.some(editor => editor.id === userId);
    }
}
