/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement } from "react";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the icons.
import { faStar } from "@fortawesome/free-regular-svg-icons/faStar";
// Import the css.
import css from "./empty.module.scss";


/** Component rendered when the user has no favourite items. */
export default function Empty(): ReactElement {
    return <div className={css["container"]}>
        <span className={css["text"]}>
            Ne perdez plus vos éléments&nbsp;favoris <br />
            Ajoutez-les ici avec un clic sur le bouton
            &nbsp;<FontAwesomeIcon className={css["icon"]} icon={faStar} />&nbsp;
            dans les résultats de votre recherche
        </span>
    </div>
}
