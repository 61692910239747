/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useContext, useEffect, useMemo } from "react";
// Import the react router module.
import { Routes, Route, useNavigate, Link, useParams } from "react-router-dom";
// Import the bootstrap components.
import { Container, Row } from "react-bootstrap";
// Import the resources.
import { User } from "@andromeda/resources";
// Import the resource stores.
import { DueDateStore, useResourceDispatch, UserStore } from "@andromeda/store";
// Import the custom components.
import { createSearchBar, SaveIndicator, SearchBarSource, useNotify } from "@andromeda/components";
// Import the login context.
import { LoginContext } from "@andromeda/login";

// Import the info component.
import { UserInfoMonitoring } from "./user-info-monitoring";

// Import the back arrow.
import arrow from "@andromeda/assets/images/arrow-blue-dark.svg";
// Import the css.
import css from "./index.module.scss";


/** @borrows UnwrappedMonitoring. */
export const Monitoring = UserStore.withReducer(DueDateStore.withReducer(UnwrappedMonitoring));

/**
 * Component used to render the monitoring interface for an organisation's administrators.
 */
function UnwrappedMonitoring(): ReactElement {
    const dispatch = useResourceDispatch();
    const currentOrgId = useContext(LoginContext).organisations.current.id;
    const notify = useNotify();
    const navigate = useNavigate();

    // Load all the organisation's users from the store.
    const users = UserStore.useSelector(function findOrgUsers(state) {
        return state.resources.filter(function checkIfUserBelongsToOrg(user: User): boolean {
            return user.relationships.organisations.data.some((organisation) => organisation.id === currentOrgId);
        });
    });

    // Prepare the sources for the search bar.
    const userSearchBarSource = useMemo(function prepareUserSources(): SearchBarSource<User>[] {
        return users.map(user => ({ data: user, key: user.id, text: user.attributes.givenName }));
    }, [users]);

    // Load the save state of the due-date store.
    const isDueDateSaving = DueDateStore.useSelector(
        state => Boolean(state.creating + state.reading + state.updating + state.deleting)
    );

    // Load all the users from the api.
    useEffect(() =>{
        const filter = { user: { organisations: { $eq: currentOrgId }}};
        dispatch(UserStore.generator.readMany({ filter })).catch(notify.error);
    }, [currentOrgId, dispatch, notify.error]);

    // Render the component.
    return <Container>
        <Row className="mt-2">
            <h1 className={css["zaq-training-progress__title"]}>Tableau de suivi</h1>
        </Row>
        <Row className="mt-3">
            <UserListSearchBar.Provider onSelected={user => navigate(user.data.id)} source={userSearchBarSource}>
                <div className={css["zaq-training-progress__body"]}>
                    <Routes>
                        <Route path=":user" element={<UserInfoTable />} />
                        <Route path="*" element={<UserInfoList />} />
                    </Routes>
                </div>
            </UserListSearchBar.Provider>
        </Row>
        <SaveIndicator show={isDueDateSaving} />
    </Container>;
}

/** Create the search bar used to search in the user list. */
const UserListSearchBar = createSearchBar<User>();

/** Wrapper component user to render the user list. */
function UserInfoList(): ReactElement | null {
    // Load the organisation id.
    const currentOrgId = useContext(LoginContext).organisations.current.id;

    // Load all the organisation's users from the store.
    const users = UserStore.useSelector(function findOrgUsers(state) {
        return state.resources.filter(function checkIfUserBelongsToOrg(user: User): boolean {
            return user.relationships.organisations.data.some((organisation) => organisation.id === currentOrgId);
        });
    });

    // Render the user list.
    const userList = useMemo(function renderUserList(): ReactElement[] {
        return users.map(function renderUser(user: User): ReactElement {
            return <li key={user.id} className={css["zaq-training-progress__body__list__item"]}>
                <Link to={user.id} children={user.attributes.givenName} />
            </li>
        });
    }, [users]);

    // Render the table.
    return <>
        <div className={css["zaq-training-progress__body__title"]}>
            <p className={css["zaq-training-progress__body__title__text"]}>membre</p>
        </div>
        <UserListSearchBar.Input className={css["zaq-training-progress__body__search-bar"]} />
        <ul className={css["zaq-training-progress__body__list"]} children={userList} />
    </>
}

/** Wrapper component used to render the user info table. */
function UserInfoTable(): ReactElement | null {
    // Get the navigation callback.
    const navigate = useNavigate();

    // Load the user id from the path.
    const { user } = useParams();
    useEffect(function redirectIfUserIsMissing(): void {
        if (!user) {
            navigate("..");
        }
    }, [navigate, user]);

    // Render the table.
    if (!user) {
        return null;
    }
    return <>
        <div className={css["zaq-training-progress__body__title"]}>
            <button
                className={css["zaq-training-progress__body__title__item"]}
                onClick={() => navigate("..")}
            >
                <img
                    src={arrow}
                    alt="back-arrow"
                    className={css["zaq-training-progress__body__title__item__arrow"]}
                />
                <p className={css["zaq-training-progress__body__title__text"]}>retour</p>
            </button>
        </div>
        <UserInfoMonitoring user={user} />
    </>
}
