/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import react.
import { ReactElement, useCallback, useMemo, useState, useEffect } from "react";

// Import the file generator.
import { useGenerator } from "../generator/hooks";

// Import the add icon.
import add from "@andromeda/assets/images/add.svg";
// Import the css.
import css from "./drop-target.module.scss";


/**
 * Component used to allow the user to drop anything on the screen.
 * If the file is of a supported type, generates a new file object.
 */
export function DropTarget(): ReactElement {
    // Compute the class name.
    const [ hidden, setHidden ] = useState(true);
    const className = useMemo(() => {
        let className = css["drop-target"];
        if (hidden) className += ` ${css["drop-target--hidden"]}`;
        return className;
    }, [hidden]);

    // Prepare the generator.
    const generator = useGenerator();

    // Define the drag handlers.
    const onDragEnter = useCallback(function onDragEnter(event: DragEvent): void {
        // Prevent the file from opening on its own.
        event.preventDefault();

        // Check if there are items being dropped.
        if (event.dataTransfer?.items) {
            // Allow the transition.
            event.dataTransfer.effectAllowed = "copy";
            event.dataTransfer.dropEffect = "copy";
            setHidden(false);
        }
    }, []);
    const onDragOver = useCallback(function onDragOver(event: DragEvent): void {
        // Prevent the file from opening on its own.
        event.preventDefault();

        // Show the element.
        setHidden(false);
    }, []);
    const onDragLeave = useCallback(function onDragLeave(event: DragEvent): void {
        // Prevent the file from opening on its own.
        event.preventDefault();

        // Hide the element again.
        setHidden(true);
    }, []);
    const onDrop = useCallback(function onDrop(event: DragEvent): void {
        // Prevent the file from opening on its own.
        event.preventDefault();

        // Check if there are items being dropped.
        if (event.dataTransfer?.items) {
            // Convert all the items.
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                const file = event.dataTransfer.items[i].getAsFile();
                if (!file) continue;
                generator(file);
            }
        }

        // Hide the element again.
        setHidden(true);
    }, [generator]);

    // Attach the listeners to the window.
    useEffect(function attachDragListeners(): () => void {
        window.addEventListener("dragenter", onDragEnter);
        window.addEventListener("dragleave", onDragLeave);
        window.addEventListener("dragover", onDragOver);
        window.addEventListener("drop", onDrop);

        return function detachDragListeners(): void {
            window.removeEventListener("dragenter", onDragEnter);
            window.removeEventListener("dragleave", onDragLeave);
            window.removeEventListener("dragover", onDragOver);
            window.removeEventListener("drop", onDrop);
        }
    }, [onDragEnter, onDragLeave, onDragOver, onDrop]);

    return <div className={className}>
        <span className={css["drop-target__box"]}>
            <img src={add} alt="add-new-file" className={css["drop-target__add"]} />
        </span>
    </div>;
}
