/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import type { ReactElement, ReactNode } from "react";

// Import the document status type alias.
import type { PDFDocumentStatus } from "../../component/document";
// Import the context.
import { PDFContext } from "./index";
// Import the context state loader.
import { useContextState } from "./use-context-state";


/** Component used to provide the {@link PDFContext} to the tree. */
export default function PDFProvider(props: PDFProviderProps): ReactElement | null {
    // Load the value of the context.
    const contextValue = useContextState(props.document);

    // Render nothing if the document is not loaded.
    if (contextValue === null) {
        return null;
    }
    return <PDFContext.Provider value={contextValue} children={props.children} />;
}

/** Props passed down to the {@link PDFProvider} component. */
export interface PDFProviderProps {
    /** The status of the document loading. */
    document: PDFDocumentStatus;
    /** The children that will have access to the context. */
    children?: ReactNode;
}
