/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useCallback, useState } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the QR-Code generator.
import { QRCodeRenderersOptions, toCanvas } from "qrcode";
// Import the custom components.
import { useNotify } from "@andromeda/components";

// Import the icons.
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faQrcode } from "@fortawesome/free-solid-svg-icons/faQrcode";
// Import the css.
import css from "./qr.module.scss";


/** Component used to copy a QR-Code version of the given link. */
export default function LinkQRButton(props: LinkQRButtonProps): ReactElement {
    // Get the notification tools.
    const { error } = useNotify();

    // Stores the generated QR-Code file.
    const [qrCodeImage, setQRCodeImage] = useState<File>();

    // Generate the QR-Code when the component is mounted.
    const generateQRCode = useCallback(function createQRCodeFile(canvas: HTMLCanvasElement | null): void {
        // Wait for the canvas reference to be set.
        if (!canvas) {
            return setQRCodeImage(undefined);
        }

        // Write the QR-Code to the canvas.
        toCanvas(canvas, props.link, QR_RENDERER_OPTIONS)
            .then(() => getBlobFromCanvas(canvas, "image/png"))
            .then(blob => new File([blob], props.name, { type: blob.type }))
            .then(setQRCodeImage)
            .catch(e => error(e, "Impossible de créer un QR-Code", "La création d'un QR-Code a échoué"));
    }, [error, props.link, props.name]);

    // Callback used to copy the QR-Code.
    const copy = useCallback(async function copyQRCode(): Promise<void> {
        // Wait for the QR-Code to be available.
        if (typeof qrCodeImage === "undefined") {
            return;
        }

        // Check if the clipboard API is available.
        if (typeof navigator.clipboard?.write === "function") {
            try {
                const item = new ClipboardItem({ "image/png": qrCodeImage });
                await navigator.clipboard.write([item]);
                return window.alert("Le QR-Code a été copié dans votre presse-papiers !");
            } catch (e: unknown) {
                /* Ignore */
            }
        }

        // Check if the share API is available.
        if (typeof navigator.share === "function") {
            try {
                return await navigator.share({ title: props.name, files: [qrCodeImage] });
            } catch {
                /* Ignore */
            }
        }

        // Download the QR-Code to the disk.
        const link = document.createElement("a");
        link.href = URL.createObjectURL(qrCodeImage);
        link.download = props.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    }, [props.name, qrCodeImage]);

    // Build the class name of the button.
    const className = classNames(css["button"], props.className);

    // Render the component.
    return <button className={className} disabled={typeof qrCodeImage === "undefined"} onClick={copy}>
        <FontAwesomeIcon className={css["button__icon"]} icon={qrCodeImage ? faQrcode : faSpinner} />
        <canvas
            className={css["canvas"]}
            ref={generateQRCode}
            width={QR_RENDERER_OPTIONS.width}
            height={QR_RENDERER_OPTIONS.width}
        />
    </button>;
}

export interface LinkQRButtonProps {
    link: string;
    name: string;
    className?: string;
}

/**
 * Promisified version of the {@link HTMLCanvasElement.toBlob} method.
 * Fails if the method returns `null`.
 *
 * @param {HTMLCanvasElement} canvas The canvas to convert to a {@link Blob}.
 * @param {string} [type="image/png"] The media type of the generated image.
 * @param {number} [quality] The quality of the image, if the type can be compressed.
 * @returns {Promise<Blob>} A promise that resolves with the generated {@link Blob}.
 * @throws {TypeError} The {@link HTMLCanvasElement.toBlob} method returned a `null`.
 */
function getBlobFromCanvas(canvas: HTMLCanvasElement, type = "image/png", quality?: number): Promise<Blob> {
    // Promisify the canvas.toBlob function.
    return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(function onBlobReady(blob: Blob | null): void {
            // Check if the blob conversion failed.
            if (blob === null) {
                return reject(new TypeError("Failed to convert the canvas' data to a Blob."));
            }

            resolve(blob);
        }, type, quality);
    });
}

/** Options used to generate the QR-Code. */
const QR_RENDERER_OPTIONS: QRCodeRenderersOptions = {
    margin: 2,
    color: {
        light: "#FFFFFF00",
        dark: "#006FABFF"
    },
    width: 512,
    errorCorrectionLevel: "medium",
    maskPattern: 2
};
