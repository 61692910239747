/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the validator function type.
import { ValidateFunction } from "ajv";
// Import the validator.
import { validate } from "@andromeda/validation";

// Import the resource interface.
import { Resource, ResourceWithoutId } from "../../interfaces";


/**
 * Class used to represent a message that was received as a response from the server.
 *
 * @template {ResourceWithoutId[] | ResourceWithoutId | null} R=Resource[] | Resource | null
 * The type of the primary data expected in the message.
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class MessageHelper<
    R extends ResourceWithoutId[] | ResourceWithoutId | null = ResourceWithoutId[] | ResourceWithoutId | null
> {
    /** Data that was decoded from the message. */
    public readonly data: R;
    /** Additional data included in the message. */
    public readonly included: Resource[];

    /**
     * Class constructor.
     *
     * @param {R} primary The primary data of the message.
     * @param {Resource[]} [included=[]] Additional data included into the message.
     */
    public constructor(primary: R, included: Resource[] = []) {
        this.data = primary; this.included = included;
    }

    /**
     * Searches for the resources with the provided type in the primary data.
     * Does NOT return any resource without an identifier !
     *
     * @param {string} type The type of the requested resource.
     * @returns {Resource[]} An array of all the resources found with the given type.
     */
    public findMany(type: string): Resource[];

    /**
     * Searches for the resources with the provided type in the primary data.
     * Validates the resources with the given validator.
     * Does NOT return any resource without an identifier !
     *
     * @template {Resource} R
     * The type of the seeked resource.
     * @param {R["type"]} type The type of the requested resource.
     * @param {ValidateFunction<R>} validator A validator for the found resources.
     * @returns {R[]} An array of all the resources found with the given type.
     */
    public findMany<R extends ResourceWithoutId>(type: R["type"], validator: ValidateFunction<R>): R[];

    /** Implementation of the {@link findMany} method. */
    public findMany<R extends ResourceWithoutId = ResourceWithoutId>(
        type: R["type"], validator?: ValidateFunction<R>
    ): R[] {
        return MessageHelper.findFrom(this.data, type, validator);
    }

    /**
     * Searches for the requested resource in the primary data.
     *
     * @param {string} type The type of the requested resource.
     * @param {string} id The identifier of the requested resource.
     * @returns {Resource | null} The resource found in the primary data.
     */
    public findOne<T extends string>(type: T, id: string): Resource<T> | null;

    /**
     * Searches for the requested resource in the primary data.
     * Validates the resource with the given validator.
     *
     * @template {Resource} R
     * The type of the seeked resource.
     * @param {R["type"]} type The type of the requested resource.
     * @param {ValidateFunction<R>} validator A validator for the found resources.
     * @param {string} id The identifier of the requested resource.
     * @returns {R | null} The resource found in the primary data.
     */
    public findOne<R extends Resource>(type: R["type"], id: string, validator: ValidateFunction<R>): R | null;

    /** Implementation of the {@link findOne} method. */
    public findOne<R extends Resource = Resource>(
        type: R["type"],
        id: string,
        validator?: ValidateFunction<R>
    ): R | null {
        return MessageHelper.findFrom(this.data, type, validator).find(resource => resource.id === id) ?? null;
    }

    /**
     * Searches for the resources with the provided type in the included data.
     *
     * @param {string} type The type of the requested resource.
     * @returns {Resource[]} An array of all the resources found with the given type.
     */
    public findManyIncluded<T extends string>(type: T): Resource<T>[];

    /**
     * Searches for the resources with the provided type in the included data.
     * Validates the resources with the given validator.
     *
     * @template {Resource} R
     * The type of the seeked resource.
     * @param {R["type"]} type The type of the requested resource.
     * @param {ValidateFunction<R>} validator A validator for the found resources.
     * @returns {R[]} An array of all the resources found with the given type.
     */
    public findManyIncluded<R extends ResourceWithoutId>(type: R["type"], validator: ValidateFunction<R>): R[];

    /** Implementation of the {@link findMany} method. */
    public findManyIncluded<R extends ResourceWithoutId = ResourceWithoutId>(
        type: R["type"], validator?: ValidateFunction<R>
    ): R[] {
        return MessageHelper.findFrom(this.included, type, validator);
    }

    /**
     * Searches for the requested resource in the included data.
     *
     * @param {string} type The type of the requested resource.
     * @param {string} id The identifier of the requested resource.
     * @returns {Resource | null} The resource found in the primary data.
     */
    public findOneIncluded(type: string, id: string): Resource | null;

    /**
     * Searches for the requested resource in the included data.
     * Validates the resource with the given validator.
     *
     * @template {Resource} R
     * The type of the seeked resource.
     * @param {R["type"]} type The type of the requested resource.
     * @param {ValidateFunction<R>} validator A validator for the found resources.
     * @param {string} id The identifier of the requested resource.
     * @returns {R | null} The resource found in the primary data.
     */
    public findOneIncluded<R extends Resource>(type: R["type"], id: string, validator: ValidateFunction<R>): R | null;

    /** Implementation of the {@link findOne} method. */
    public findOneIncluded<R extends Resource = Resource>(
        type: R["type"],
        id: string,
        validator?: ValidateFunction<R>
    ): R | null {
        return MessageHelper.findFrom(this.data, type, validator).find(resource => resource.id === id) ?? null;
    }

    /**
     * Helper methods used to find a resource from a given source list.
     *
     * @template {Resource} R=Resource
     * @param {Resource[] | Resource | null} from The list to find the resources in.
     * @param {R["type"]} type The type of the requested resources.
     * @param {ValidateFunction<R> | undefined} [validator=undefined]
     * A validator function used to ensure the type of the returned resources.
     * @returns {R[]} A list of all the resources that match the parameters.
     */
    public static findFrom<R extends ResourceWithoutId>(
        from: ResourceWithoutId[] | ResourceWithoutId | null,
        type: R["type"],
        validator?: ValidateFunction<R>
    ): R[] {
        // Check the type of the primary data.
        let resources: ResourceWithoutId<typeof type>[] = [];
        if (Array.isArray(from)) {
            resources = from.filter(resource => resource.type === type);
        } else if (from !== null && from.type === type) {
            resources = [ from ];
        }

        // Validate the elements.
        return resources.filter((resource: ResourceWithoutId<typeof type>): resource is R => {
            return typeof validator === "function" ? validate(resource, validator) : true;
        });
    }
}

/**
 * Helper class used to represent a message with a single primary data item.
 *
 * @template {Resource} R=Resource The resource being stored in this message.
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class SingleMessageHelper<R extends Resource> extends MessageHelper<R | null> {}

/**
 * Helper class used to represent a message with multiple primary data items.
 *
 * @template {Resource} R=Resource The resource being stored in this message.
 * @author Caillaud Jean-Baptiste
 * @since 0.2.0
 * @version 1
 */
export class MultipleMessageHelper<R extends Resource> extends MessageHelper<R[]> {}
