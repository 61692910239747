/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import the context generator.
import { makeToggleableButtonContext } from "@andromeda/storybook";


/** Context used for the summary toggle button. */
export const SummaryContext = makeToggleableButtonContext();
