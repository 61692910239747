/*
 * Copyright © 2021-2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext } from "react";
// Import the FontAwesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the button.
import { Button } from "../../../../index";
// Import the context.
import { PDFContext, PDFActivePageContext } from "../../context";

// Import the icons.
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
// Import the css.
import css from "./scale.module.scss";


/** Component used to render the scaling buttons for the controller bar.  */
export default function Scale(): ReactElement {
    // Load the controller context.
    const { pages } = useContext(PDFContext);
    const { activePageIndex } = useContext(PDFActivePageContext);
    const { scale, scaleBy } = pages[activePageIndex];

    // Render the component.
    return <div className={css["section"]}>
        <Button className={css["button"]} disabled={scale <= 0.25} onClick={() => scaleBy(0.5)}>
            <FontAwesomeIcon icon={faMinus} className={css["button__icon"]} />
        </Button>

        <div className={css["text"]}>
            <span>{scale}x</span>
        </div>

        <Button className={css["button"]} disabled={scale >= 4} onClick={() => scaleBy(2)}>
            <FontAwesomeIcon icon={faPlus} className={css["button__icon"]} />
        </Button>
    </div>;
}
