/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ChangeEvent, ReactElement, useCallback, useRef } from "react";
// Import the css class name helper.
import classNames from "classnames";

// Import the css.
import css from "./title.module.scss";


/** Component used to render the title of a given link. */
export default function LinkTitle(props: LinkTitleProps): ReactElement {
    // Callback used to propagate updates after a debounce timeout.
    const debounceTimeout = useRef<ReturnType<typeof setTimeout>>();
    const update = useCallback(function updateLinkTitleAfterDebounce(event: ChangeEvent<HTMLInputElement>): void {
        // Clear the existing timeout if there is one.
        if (typeof debounceTimeout.current !== "undefined") {
            clearTimeout(debounceTimeout.current);
            delete debounceTimeout.current;
        }

        // Start a new debounce timeout.
        const value = event.currentTarget.value;
        debounceTimeout.current = setTimeout(function updateTitleAfterTimeout(): void {
            props.onChange(value);
        }, 500);
    }, [props]);

    // Render the title input.
    return <input
        type="text"
        name="link-name"
        placeholder="Donner un nom à ce lien ..."
        defaultValue={props.defaultValue}
        onChange={update}
        maxLength={32}
        className={classNames(css["title"], props.className)}
    />;
}

/** Props passed down to the {@link LinkTitle} component. */
export interface LinkTitleProps {
    /** The default value of the input. Aka. the initial name of the shared link. */
    defaultValue: string;
    /** An optional class name added to the <input> element. */
    className?: string;

    /**
     * Callback invoked when the name of the link was changed.
     * This callback is debounced and waits 500ms after the last change before being invoked.
     *
     * @param {string} value The new name of the link.
     */
    onChange(value: string): void;
}
