/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import the custom interfaces.
import type { FullLegacyZaq, LegacyZaq } from "../interfaces";
// Import the common tools.
import { RequestStatus } from "@andromeda/tools";

// Import the action type.
import type { RegisterZaqAction, RequestZaqAction, ZaqDeletedAction } from "./actions";


/** Interface used to describe the state of the {@link legacyZaqReducer} reducer. */
export interface LegacyZaqReducerState {
    /** Map of all the resources loaded from the API. */
    resources: Record<string, RequestStatus<FullLegacyZaq | LegacyZaq> | undefined>;
    /** Map of all the zaq objects by organisation. */
    organisations: Record<string, RequestStatus<string[]> | undefined>;
}

/** Key used when mounting the {@link legacyZaqReducer}. */
export const LegacyZaqReducerKey = "legacy-zaq";

/** Interface used to describe a store with a mounted {@link legacyZaqReducer}. */
export interface LegacyZaqReducerStore {
    [LegacyZaqReducerKey]: LegacyZaqReducerState;
}

/** Default state of the {@link legacyZaqReducer}. */
const DEFAULT_STATE: LegacyZaqReducerState = { resources: {}, organisations: {} };

/**
 * Reducer used to manipulate a list of {@link LegacyZaq} resources.
 *
 * @param {LegacyZaqReducerState} [currentState=DEFAULT_STATE] The current state of the reducer.
 * @param {RequestZaqAction} action The action that was just dispatched to the store.
 * @return {LegacyZaqReducerState} The state of the reducer.
 */
export function legacyZaqReducer(
    currentState = DEFAULT_STATE,
    action: RequestZaqAction | RegisterZaqAction | ZaqDeletedAction
): LegacyZaqReducerState {
    switch (action.type) {
    case "zaq/request/update":
        return { ...currentState, resources: { ...currentState.resources, [action.id]: action.payload } };
    case "zaq/request/register":
        return {
            ...currentState,
            organisations: {
                ...currentState.organisations,
                [action.id]: action.payload
            }
        };
    case "zaq/request/deleted":
        // Remove the ZaqTuto from the store.
        delete currentState.resources[action.payload];
        for (const [organisation, tuto] of Object.entries(currentState.organisations)) {
            if (tuto?.isSuccess) {
                currentState.organisations[organisation] = RequestStatus.success(
                    tuto.data.filter(item => item !== action.payload)
                );
            }
        }

        return {
            ...currentState,
            organisations: { ...currentState.organisations },
            resources: { ...currentState.resources }
        };
    default:
        return currentState;
    }
}
