/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useContext, useMemo } from "react";
// Import the resources.
import type { User } from "@andromeda/resources";
// Import the storybook components.
import { Loader } from "@andromeda/storybook";
// Import the common tools.
import { normalise } from "@andromeda/tools";
// Import the login module.
import { LoginContext, useAdminCheck } from "@andromeda/login";
// Import the store.
import { useCourse, useUsers } from "@andromeda/store";

// Import the helper hooks.
import { useIsEditor, useIsValidator } from "../../../../helpers";
// Import the search bar.
import UserSearchBar from "./search-bar";
// Import the user permissions component.
import UserPermissions from "./user-permissions";

// Import the css.
import css from "./user-list.module.scss";


/** Shows a list of all the users of this organisation, and their permissions for the specified course. */
export default function UserList(props: UserListProps): ReactElement {
    // Load the course.
    const course = useCourse(props.course);
    // Load all the users.
    const users = useUsers(useContext(LoginContext).organisations.current.id);
    // Load the text in the search bar.
    const filter = normalise(useContext(UserSearchBar.context).text);

    // Sort all the users.
    const isAdmin = useAdminCheck(), isEditor = useIsEditor(props.course), isValidator = useIsValidator(props.course);
    const sortedUsers = useMemo(function sortUserList(): User[] {
        // If the values are not loaded yet, return an empty list.
        if (!course.isSuccess || !users.isSuccess) {
            return [];
        }

        // Filter out the users that do not match the filter.
        let sorted: User[] = Array.from(users.data);
        if (filter.length > 0) {
            sorted = sorted.filter(user => normalise(user.attributes.givenName).includes(filter));
        }

        // Sort all the users.
        sorted.sort(function compareUsers(a: User, b: User): number {
            // Get the
            const aIsAdmin = isAdmin(a.id), bIsAdmin = isAdmin(b.id);
            const aIsEditor = isEditor(a.id), bIsEditor = isEditor(b.id);
            const aIsValidator = isValidator(a.id), bIsValidator = isValidator(b.id);

            // Compare the users.
            if (aIsAdmin && !bIsAdmin) {
                return -1;
            }
            if (!aIsAdmin && bIsAdmin) {
                return 1;
            }
            if (aIsEditor && !bIsEditor) {
                return -1;
            }
            if (!aIsEditor && bIsEditor) {
                return 1;
            }
            if (aIsValidator && !bIsValidator) {
                return -1;
            }
            if (!aIsValidator && bIsValidator) {
                return 1;
            }

            // Compare the filtering.
            const aFilterMatch = normalise(a.attributes.givenName).indexOf(filter);
            const bFilterMatch = normalise(b.attributes.givenName).indexOf(filter);
            if (aFilterMatch < bFilterMatch) {
                return -1;
            }
            if (bFilterMatch < aFilterMatch) {
                return 1;
            }

            // Compare the names of the users.
            return normalise(a.attributes.givenName).localeCompare(normalise(b.attributes.givenName));
        });

        return sorted;
    }, [course.isSuccess, filter, isAdmin, isEditor, isValidator, users.data, users.isSuccess]);

    // Render the component.
    if (!course.isSuccess || !users.isSuccess) {
        return <Loader text="Chargement de la liste des utilisateurs ..." />;
    }
    return <div className={css["list"]}>
        {sortedUsers.map(user => <UserPermissions user={user.id} course={props.course} key={user.id} />)}
    </div>;
}

/** Props passed down to the {@link UserList} component. */
export interface UserListProps {
    /** The identifier of the rendered course. */
    course: string;
}
