/*
 * Copyright © 2022 - Zimproov.
 * All rights reserved.
 */

// Import React.
import {
    ReactElement,
    createContext,
    ReactNode,
    useState,
    createElement,
    Fragment,
    useEffect,
    Context,
    useContext
} from "react";


/** List of all the available themes for the application. */
export type Theme = "light" | "dark";

/** Interface of the {@link ThemeContext} value. */
export interface ThemeContext {
    /** The theme that is currently selected. */
    theme: Theme;

    /** Selects a new theme to render the application with. */
    select(theme: Theme): void;
}

/** Context used to manipulate the theme that is currently displayed on the page. */
const ThemeContext: Context<ThemeContext> = createContext<ThemeContext>({
    theme: "light",
    select() {
        throw new Error("STUB: Import the noop generator instead !");
    }
});

/**
 * Provides the {@link ThemeContext} in the DOM tree.
 *
 * @param {ThemeContextProps} props
 * @return {React.ReactElement}
 * @constructor
 */
export function ThemeContextProvider(props: ThemeContextProps): ReactElement {
    // Check if this is the root context.
    const hasRootContext = (useContext(ThemeContext) as { _isRoot?: boolean })._isRoot;

    // Store the currently stored theme.
    const [theme, select] = useState<Theme>("light");

    // Update the body theme when the value changes.
    useEffect(function updateBodyTheme(): void {
        // Delete any theme classes on the body.
        for (const cssClass of Array.from(document.body.classList)) {
            if (cssClass.startsWith("theme--")) {
                document.body.classList.remove(cssClass);
            }
        }

        // Add the current theme class.
        document.body.classList.add(`theme--${theme}`);
    }, [theme]);

    // If a root context already exists, render the children directly.
    if (hasRootContext) {
        return createElement(Fragment, { children: props.children });
    }

    // Return the context.
    return <ThemeContext.Provider value={{ theme, select, _isRoot: true } as ThemeContext} children={props.children} />;
}

/** Props provided to the {@link ThemeContextProvider} component. */
interface ThemeContextProps {
    /** List of children to give access to the context to. */
    children: ReactNode;
}
